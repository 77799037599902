define("copilot/components/submit-button/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "button",

    classNames: ["submit-button", "button--secondary"],

    classNameBindings: ["isProcessing"],

    duration: 400,

    submit: (0, _emberConcurrency.task)(function* (submit) {
      this.set("isProcessing", true);
      try {
        yield Ember.RSVP.all([submit(), (0, _emberConcurrency.timeout)(this.duration * 2)]);
      } catch (e) {
        this.set("isProcessing", false);
      } finally {
        this.set("isProcessing", false);
      }
    }),

    click: function click(evt) {
      evt.preventDefault();
      if (this.isProcessing) {
        return false;
      }

      this.submit.perform(this.onsubmit);
      return false;
    }
  });
});