define("copilot/validations/brands/vf/newsletter", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var newsletterValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.NewsletterValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["photosTout"])).andAlso(object({
    channel: (0, _copilotValidators.channel)().on("publish")
  })).andAlso((0, _copilotValidators.subchannel)().on("publish"));

  exports.default = newsletterValidationBuilder;
});