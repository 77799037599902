define("copilot/brands/lci/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    contentTypes: {
      advertiser: "{count, plural, =1 {Advertiser} other {Advertisers}}",
      contentreference: "{count, plural, =1 {Content Reference} other {Content References}}"
    },
    fieldsetLegends: {
      adv: "Advertisement",
      external: "External references",
      brandmag: "Brandmag",
      language: "Language",
      highlight: "Highlight",
      venues: "Venues"
    },
    fieldNames: {
      photosTout: "Archives Image",
      photosLede: "Main Image",
      videosLede: "Head Gallery / Video",
      showInHomepage: "Show in homepage",
      showInArchive: "Show in archive",
      showInRelated: "Show in related",
      advertiser: "Advertiser",
      customAdvertisementZone: "Advertisement custom zone",
      brandmag: "Brandmag",
      identifier: "Identifier",
      provider: "Provider",
      language: "Language",
      alternateLang: "Alternate languages",
      region: "Region",
      regionPlaceholder: "Region placeholder",
      occasion: "Occasion",
      season: "Season",
      meal: "Meal type",
      dishes: "Dishes",
      videoId: "Video ID",
      videoOrigin: "Video origin",
      slideRelatedUrl: "Slide Related URL",
      slideBody: "Slide Body"
    },
    validationMessage: {
      "taxonomies-allowed-minimum": "You must choose 1 value for the taxonomy",
      "taxonomies-allowed-maximum": "You must choose only 1 value for the taxonomy"
    },
    components: {
      "collection-with-create": {
        create: {
          alternateLang: "Create alternate language"
        }
      },
      "category-selector": {
        selectCategoriesType: "Select Type",
        selectCategoriesLayout: "Select Layout",
        selectCategoriesLabel: "Select Label",
        selectCategoriesIngredient: "Select Ingredients",
        selectCategoriesRegion: "Select Regions",
        selectCategoriesOccasion: "Select Occasions",
        selectCategoriesSeason: "Select Seasons",
        selectCategoriesMeal: "Select Meal Types",
        selectCategoriesDishes: "Select Dish Type"
      }
    }
  };
});