define("copilot/routes/livestories/index/livestoryupdates/create", ["exports", "ic-ajax", "ember-concurrency", "copilot/routes/authenticated-route", "moment"], function (exports, _icAjax, _emberConcurrency, _authenticatedRoute, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var DEBOUNCE_MS = Ember.testing || window.testing ? 1 : 10000;

  exports.default = _authenticatedRoute.default.extend({
    createItem: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    config: Ember.inject.service(),
    logger: Ember.inject.service(),
    search: Ember.inject.service(),
    saveService: Ember.inject.service("save"),
    controllerName: 'livestories.livestoryupdates.index',
    currentUser: Ember.computed.reads("user.user"),
    pageLimit: 200,

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (!this.brandConfig.configService.userService.user.permissions.livestoryupdates.actions.update) {
        transition.abort();
        var brand = transition.params.brand.brand_code;
        var livestory_id = Ember.get(transition.params, "livestories.index").livestory_id;
        window.location = "/" + brand + "/livestories/" + livestory_id;
      }
    },
    model: function model() {
      this._super.apply(this, arguments);
      return this.createItem.initModel('livestoryupdate');
    },
    setupController: function () {
      var _ref = _asyncToGenerator(function* (controller, model) {
        this._super.apply(this, arguments);
        var livestoryModel = this.modelFor('livestories.index');
        model.set('liveStoryId', livestoryModel.id);
        model._dirtyAttributes.clear();
        var livestoryUpdatePage = yield this.fetchFirstPageFromRels.perform(model.liveStoryId);
        var updates = livestoryUpdatePage.updates.length ? livestoryUpdatePage.updates.reverse() : [];
        var updateModels = this.generateUpdateModels(updates);
        controller.setProperties({
          updates: updateModels,
          pageLimit: this.pageLimit,
          livestorySearchTitle: livestoryModel.hed,
          remainingLivestoryupdateCount: livestoryUpdatePage.totalCount
        });

        this.pollForNewLivestoryupdates.perform(model.liveStoryId);
      });

      function setupController(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return setupController;
    }(),
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.pollForNewLivestoryupdates.cancelAll();
    },
    generateUpdateModels: function generateUpdateModels(updates) {
      var _this = this;

      return updates.map(function (update) {
        var updateModel = _this.store.modelFor("livestoryupdate").create(update);
        var owner = Ember.getOwner(_this);
        Ember.setOwner(updateModel, owner);
        return updateModel;
      });
    },
    buildQueryString: function buildQueryString(query) {
      return Object.entries(query).map(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            key = _ref3[0],
            value = _ref3[1];

        return key + "=" + encodeURIComponent(value);
      }).join("&");
    },
    queryUpdatesRel: function () {
      var _ref4 = _asyncToGenerator(function* (livestoryId, query) {
        var baseUrl = "/api/livestories/" + livestoryId + "/rels/updates";
        var fullUrl = baseUrl + "?" + this.buildQueryString(query);
        try {
          var data = yield (0, _icAjax.default)(fullUrl, {
            type: "GET",
            contentType: "application/json"
          });
          return data;
        } catch (error) {
          this.logger.error("Querying updates rel", error);
          return;
        }
      });

      function queryUpdatesRel(_x3, _x4) {
        return _ref4.apply(this, arguments);
      }

      return queryUpdatesRel;
    }(),


    fetchFirstPageFromRels: (0, _emberConcurrency.task)(function* (livestoryId) {
      var livestoryUpdatePage = yield this.queryUpdatesRel(livestoryId, { limit: this.pageLimit });
      if (livestoryUpdatePage.hasMore) {
        var lastPage = Math.ceil(livestoryUpdatePage.totalCount / this.pageLimit);
        livestoryUpdatePage = yield this.queryUpdatesRel(livestoryId, { page: lastPage, limit: this.pageLimit });
      }

      return livestoryUpdatePage;
    }).restartable(),

    pollForNewLivestoryupdates: (0, _emberConcurrency.task)(function* (liveStoryId) {
      while (true) {
        var query = {};
        if (this.controller.latestUpdateCreatedAtDate) {
          query.createdAtFrom = (0, _moment.default)(this.controller.latestUpdateCreatedAtDate).add(100, 'milliseconds').toISOString();
        }
        var pollData = yield this.queryUpdatesRel(liveStoryId, query);
        if (pollData && pollData.updates && pollData.updates.length) {
          var newUpdates = pollData.updates.reverse();
          var newUpdateModels = this.generateUpdateModels(newUpdates);
          var existingUpdates = this.controller.updates || [];
          this.controller.set('updates', [].concat(_toConsumableArray(newUpdateModels), _toConsumableArray(existingUpdates)));
        }

        if (Ember.testing) {
          return;
        }
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      }
    }).restartable(),

    fetchFromSearch: (0, _emberConcurrency.task)(function* () {
      var searchParams = {
        livestoryid: this.controller.livestoryId,
        sort: "createddate desc",
        limit: this.pageLimit,
        view: "edit",
        showInFeed: true,
        createddate_to: (0, _moment.default)(this.controller.earliestUpdateCreatedAtDate).subtract(100, 'milliseconds').toISOString()
      };
      try {
        var data = yield this.search.execute(searchParams);
        if (data && data.hits && data.hits.length) {
          var updateModels = this.generateUpdateModels(data.hits);
          this.controller.setProperties({
            updates: [].concat(_toConsumableArray(this.controller.updates), _toConsumableArray(updateModels)),
            remainingLivestoryupdateCount: data.totalHits
          });
        }
      } catch (error) {
        this.logger.error("Searching for livestory updates", error);
        return;
      }
    }).restartable(),

    actions: {
      createLivestoryupdate: function () {
        var _ref5 = _asyncToGenerator(function* (livestoryupdate) {
          yield livestoryupdate.setInlines();
          var isValid = yield livestoryupdate.validate("save");
          if (!isValid) {
            return livestoryupdate;
          }

          yield this.saveService.save(livestoryupdate);

          var postBody = {
            id: livestoryupdate.id,
            meta: {
              collectionName: "livestoryupdates",
              modelName: "livestoryupdate"
            }
          };
          yield (0, _icAjax.default)("/api/livestories/" + livestoryupdate.liveStoryId + "/rels/updates", {
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(postBody)
          });
          this.refresh();
        });

        function createLivestoryupdate(_x5) {
          return _ref5.apply(this, arguments);
        }

        return createLivestoryupdate;
      }(),
      removeFromFeed: function () {
        var _ref6 = _asyncToGenerator(function* (livestoryupdateToRemoveModel) {
          livestoryupdateToRemoveModel.set('showInFeed', false);
          livestoryupdateToRemoveModel.set('isNew', false);
          yield this.saveService.save(livestoryupdateToRemoveModel);
        });

        function removeFromFeed(_x6) {
          return _ref6.apply(this, arguments);
        }

        return removeFromFeed;
      }(),
      getLivestoryupdates: function () {
        var _ref7 = _asyncToGenerator(function* (livestoryId, query) {
          var data = yield this.queryUpdatesRel(livestoryId, query);
          return data;
        });

        function getLivestoryupdates(_x7, _x8) {
          return _ref7.apply(this, arguments);
        }

        return getLivestoryupdates;
      }(),
      fetchNextPage: function () {
        var _ref8 = _asyncToGenerator(function* () {
          yield this.fetchFromSearch.perform();
        });

        function fetchNextPage() {
          return _ref8.apply(this, arguments);
        }

        return fetchNextPage;
      }()
    }
  });
});