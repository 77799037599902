define("copilot/lib/brand-resolver", ["exports", "ember-resolver", "ember-inflector", "copilot/lib/brand-list"], function (exports, _emberResolver, _emberInflector, _brandList) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getResolverCompany = getResolverCompany;
  exports.getResolverParentBrand = getResolverParentBrand;
  function getResolverCompany(activeBrand) {
    for (var company in _brandList.resolverConfig) {
      if (_brandList.resolverConfig.hasOwnProperty(company) && _brandList.resolverConfig[company][activeBrand]) {
        return _brandList.resolverConfig[company][activeBrand].resolverCompany || company;
      }
    }
  }

  function getResolverParentBrand(activeBrand) {
    for (var company in _brandList.resolverConfig) {
      if (_brandList.resolverConfig.hasOwnProperty(company) && _brandList.resolverConfig[company][activeBrand]) {
        return _brandList.resolverConfig[company][activeBrand].resolverBrand || activeBrand;
      }
    }
  }

  exports.default = _emberResolver.default.extend({
    moduleNameLookupPatterns: Ember.computed(function () {
      return Ember.A([this.brandPodBasedComponentsInSubDir, this.brandBasedModuleName, this.companyPodBasedComponentsInSubDir, this.companyBasedModuleName, this.podBasedModuleName, this.podBasedComponentsInSubdir, this.mainModuleName, this.defaultModuleName]);
    }),

    brandBasedLookupWithPrefix: function brandBasedLookupWithPrefix(brandPrefix, parsedName) {
      var fullNameWithoutType = parsedName.fullNameWithoutType;
      var pluralizeType = (0, _emberInflector.pluralize)(parsedName.type);
      var modulePrefix = this.namespace.modulePrefix;
      var moduleName = modulePrefix + "/brands/" + brandPrefix + "/" + pluralizeType + "/" + fullNameWithoutType;
      return moduleName;
    },
    brandPodBasedLookupWithPrefix: function brandPodBasedLookupWithPrefix(brandPrefix, parsedName) {
      if (parsedName.type !== "component" && !parsedName.fullNameWithoutType.match(/^components/)) {
        return;
      }

      var podPrefix = this.namespace.podModulePrefix || this.namespace.modulePrefix;
      var fullNameWithoutType = parsedName.fullNameWithoutType;
      var type = parsedName.type;

      if (parsedName.type === "template") {
        fullNameWithoutType = fullNameWithoutType.replace(/^components\//, "");
      }

      return podPrefix + "/brands/" + brandPrefix + "/components/" + fullNameWithoutType + "/" + type;
    },
    brandPodBasedComponentsInSubDir: function brandPodBasedComponentsInSubDir(parsedName) {
      var activeBrand = this.get("brandService.activeBrand");
      var brandPrefix = getResolverParentBrand(activeBrand);
      return this.brandPodBasedLookupWithPrefix(brandPrefix, parsedName);
    },
    brandBasedModuleName: function brandBasedModuleName(parsedName) {
      var activeBrand = this.get("brandService.activeBrand");
      var brandPrefix = getResolverParentBrand(activeBrand);
      return this.brandBasedLookupWithPrefix(brandPrefix, parsedName);
    },
    companyPodBasedComponentsInSubDir: function companyPodBasedComponentsInSubDir(parsedName) {
      var brandPrefix = this.get("brandService.activeBrand");
      var companyPrefix = getResolverCompany(brandPrefix);
      return this.brandPodBasedLookupWithPrefix(companyPrefix, parsedName);
    },
    companyBasedModuleName: function companyBasedModuleName(parsedName) {
      var brandPrefix = this.get("brandService.activeBrand");
      var companyPrefix = getResolverCompany(brandPrefix);
      return this.brandBasedLookupWithPrefix(companyPrefix, parsedName);
    }
  });
});