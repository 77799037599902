define("copilot/routes/galleries/index/child", ["exports", "copilot/routes/authenticated-route", "ember-inflector", "copilot/mixins/gallery-child-route"], function (exports, _authenticatedRoute, _emberInflector, _galleryChildRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_galleryChildRoute.default, {
    controllerName: "galleries/child",

    // Allow collection-with-create behaviour for branded galleries
    // The gallery's advertiser should be edited via the catch-all
    // route, not this child route (advertiser is not a gallery item)
    beforeModel: function beforeModel(transition) {
      var childParams = transition.params["galleries.index.child"];
      if (childParams && childParams.child_type === "advertisers") {
        transition.abort();
        this.transitionTo("catch-all", {});
      }
    },
    model: function model(params) {
      return this.store.find((0, _emberInflector.singularize)(params.child_type), params.child_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var selection = model.runwaymoment ? model.runwaymoment : model;

      controller.set("galleryController.currentSelection", selection);
      controller.galleryController.showCarousel();
    }
  });
});