define("copilot/services/environment", ["exports", "copilot/lib/cookie"], function (exports, _cookie) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    deployEnvironment: Ember.computed(function () {
      return _cookie.default.get("env");
    }),

    isDevelopmentBuild: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration("config:environment").environment === "development";
    }),
    isProduction: Ember.computed.equal("deployEnvironment", "production"),
    isNotProduction: Ember.computed.not("isProduction"),
    locales: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration("config:environment").locales;
    })
  });
});