define("copilot/validations/brands/lci/curatedsearch", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Allows to append new rules to core curatedsearch rules
  var curatedsearchValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.CuratedsearchValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["photosTout"])); // Catch errors from core rules and silence any body errors

  // Returns the validation descriptors
  exports.default = curatedsearchValidationBuilder;
});