define("copilot/services/syndicate", ["exports", "ic-ajax", "ember-inflector"], function (exports, _icAjax, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getSyndicateURL(model) {
    return "/api/" + (0, _emberInflector.pluralize)(model.get("type")) + "/" + model.get("id") + "/syndicate";
  }

  /**
   * @function runBeforeSaveHooks
   * @param {EmberObject} model
   * @return {Promise}
   * @private
   */
  function runBeforeSaveHooks(model) {
    model.get("beforeSaveHooks").forEach(function (func) {
      func.call(model);
    });

    return Promise.resolve();
  }

  exports.default = Ember.Service.extend({
    assign: Ember.inject.service(),
    config: Ember.inject.service(),
    brand: Ember.inject.service(),
    user: Ember.inject.service(),
    analytics: Ember.inject.service(),

    /**
     * Syndicate an object by hitting the object's syndicateURL
     * @param {EmberObject} model
     * @param {Object} data
     * @returns {Promise}
     */
    syndicate: function syndicate(model, data) {
      var _this = this;

      return model.setInlines().then(function () {
        return _this.assign.assignCollaborators(model, true);
      }).then(function () {
        return runBeforeSaveHooks(model);
      }).then(function () {
        return _this.transformSyndicationModel(model, data);
      }).then(this.analytics.track("content", "syndicated", {
        contentType: model.get("type")
      })).then(function (augmentedModel) {
        return (0, _icAjax.default)(getSyndicateURL(model), {
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify(augmentedModel)
        });
      });
    },


    /**
     * set syndication fields on model
     * @param data
     * @returns {Promise}
     * @private
     */
    transformSyndicationModel: function transformSyndicationModel(model, data) {
      var _this2 = this;

      return new Promise(function (resolve) {
        var configService = _this2.config;
        var activeBrand = _this2.get("brand.activeBrand");
        var userFullName = _this2.get("user.user.fullName");

        // TODO: We should not explicitly mention 'article' here.
        var serializedModel = model.get("store").adapterFor("article").serializer.serialize(model);
        Ember.set(serializedModel, "syndicationAuthor", userFullName);
        Ember.set(serializedModel, "sourceBrand", configService.getBrandServiceName(activeBrand));
        Ember.set(serializedModel, "targetBrand", Ember.get(data, "targetBrand"));
        resolve(serializedModel);
      });
    }
  });
});