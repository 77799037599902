define("copilot/brands/vf/locales/en-us/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      notificationMessage: "Notification Message"
    },
    fieldsetLegends: {
      appleNewsPushNotification: "Apple News Push Notification"
    }
  };
});