define("copilot/components/tab-control/component", ["exports", "lodash-es"], function (exports, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["tab-control"],

    init: function init() {
      this._super.apply(this, arguments);
      var tabs = this.tabs;
      if (!_lodashEs.default.find(tabs, "isActive")) {
        Ember.set(tabs[0], "isActive", true);
      }
    },


    actions: {
      changeTab: function changeTab(tab) {
        var tabs = this.tabs;

        Ember.run(function () {
          tabs.forEach(function (tab) {
            if (tab.isActive) {
              Ember.set(tab, "isActive", false);
            }
          });

          Ember.set(tab, "isActive", true);
        });
      }
    }
  });
});