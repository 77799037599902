define("copilot/mixins/route-locking", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    participants: Ember.inject.service(),
    user: Ember.inject.service(),
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    features: Ember.inject.service(),

    afterModel: function afterModel(model) {
      if (model) {
        var user = Ember.get(this, "user");
        var participantArray = Ember.get(this, "participants").join({
          id: Ember.get(user, "user.id"),
          uid: Ember.get(user, "user.uid"),
          fullName: Ember.get(user, "user.fullName"),
          slackUserId: Ember.get(user, "user.slackUserId"),
          space: {
            id: Ember.get(model, "id"),
            brand: Ember.get(this, "activeBrand")
          }
        });

        // Only return thenable (delaying route transition) if
        // contentLocking feature is enabled
        if (Ember.get(this, "features.contentLocking")) {
          return participantArray;
        }
        return;
      }
    },


    actions: {
      takeOver: function takeOver() {
        var _this = this;

        var model = Ember.get(this.controller, "model");
        return model.reload().then(function (model) {
          Ember.set(_this.controller, "model", model);
          Ember.get(_this, "participants").lock();
        });
      },
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        var targetName = transition.targetName;
        if (targetName.indexOf(this.routeName) === -1) {
          Ember.get(this, "participants").leave();
        }
      }
    }
  });
});