define("copilot/components/contextual-help/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: "span",
    icon: "info",

    _popperTarget: Ember.computed(function () {
      var id = this.get("id");
      return document.querySelector("#" + id);
    }),

    onOutsideClick: function onOutsideClick() {
      this.set("active", false);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var active = this.get("active");
      var $element = this.$();
      var $target = Ember.$(event.target);

      if (active && !$target.closest($element).length) {
        this.onOutsideClick();
      }
    },
    didRender: function didRender() {
      this.set("clickHandler", Ember.run.bind(this, this.handleOutsideClick));
      Ember.$(document).click(this.get("clickHandler"));
    },
    willDestroyElement: function willDestroyElement() {
      this.set("active", false);
      Ember.$(document).off("click", this.get("clickHandler"));
    },


    actions: {
      close: function close() {
        this.set("active", false);
      },
      toggleActive: function toggleActive() {
        var id = this.get("id");
        var route = this.router.currentRouteName;
        if (!id) {
          throw new Error("contextualHelp id not found");
        }
        if (!route) {
          throw new Error("contextualHelp route name not found");
        }
        var active = this.get("active");
        // if active is currently false or undefined, toggling will open the tooltip
        // let's track that
        var willOpen = !active;
        if (willOpen) {
          this.analytics.track("contextualHelp", "opened", { id: id, route: route });
        }
        this.set("active", !active);
      }
    }
  });
});