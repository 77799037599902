define("copilot/components/search/pagination-controls/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["pagination-mini", "pagination"],

    nextDisabled: Ember.computed("currentPage", "totalPages", function () {
      return this.currentPage >= this.totalPages;
    }),

    previousDisabled: Ember.computed("currentPage", function () {
      return this.currentPage === 1;
    }),

    hasPages: Ember.computed("totalPages", function () {
      return this.totalPages > 0;
    }),

    actions: {
      next: function next() {
        this.setPage(this.currentPage + 1);
      },
      previous: function previous() {
        this.setPage(this.currentPage - 1);
      }
    }
  });
});