define("copilot/brands/cnt/models/photo", ["exports", "copilot/models/attr", "ember-model", "copilot/models/photo"], function (exports, _attr, _emberModel, _photo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var geo = _attr.default.geo;
  exports.default = _photo.default.extend({
    geo: (0, _emberModel.attr)(geo),

    dirtyObserver: Ember.observer("tags.[]", "geo.{lat,lng}", function (obj, prop) {
      var dirtyProp = prop.split(".")[0];
      var dirtyAttributes = this._dirtyAttributes;

      if (!dirtyAttributes.includes(dirtyProp)) {
        dirtyAttributes.pushObject(dirtyProp);
      }
    })
  });
});