define("copilot/controllers/curatedlists/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    showCurationTypeChangeDialog: false,
    previousCurationType: "",

    actions: {
      curationTypeChange: function curationTypeChange() {
        if (Ember.isEmpty(this.previousCurationType)) {
          this.set("previousCurationType", this.model.curationSubType);
        }
        this.toggleProperty("showCurationTypeChangeDialog");
      },
      curationTypeChangeConfirm: function curationTypeChangeConfirm() {
        this.toggleProperty("showCurationTypeChangeDialog");
        this.set("previousCurationType", this.model.curationSubType);
      },
      curationTypeChangeDismiss: function curationTypeChangeDismiss() {
        this.toggleProperty("showCurationTypeChangeDialog");
        this.set("model.curationSubType", this.previousCurationType);
      }
    }
  });
});