define("copilot/schema-helpers/autogen-current-date", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze([]),

    // Runs in context of model
    compute: function compute() {
      return (0, _moment.default)().format("YYYY-MM-DD");
    }
  });
});