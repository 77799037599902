define("copilot/brands/tny/models/cartoon", ["exports", "copilot/models/photo"], function (exports, _photo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  exports.default = _photo.default.extend({
    // Move these back to schemas once the ISODate type is available
    contestSubmissionEndDate: attr(),
    announceFinalistsDate: attr(),
    announceFinalistsIssueDate: attr(),
    votingEndDate: attr(),
    announceWinnerDate: attr()
  });
});