define("copilot/validations/brands/vogue/validators/event-date", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasEventDate = exports.EventDateValidator = undefined;
  exports.default = eventDate;
  var object = _crossCheckDsl.validators.object,
      isString = _crossCheckDsl.validators.isString,
      isBoolean = _crossCheckDsl.validators.isBoolean;

  /**
   * A @condenast/cross-check-dsl validator that checks that an event date is set
   * if the following field are present: address or livestream
   * @module copilot/brands/vogue/validations/validators/event-date
   * @augments Ember.Service
   */
  class EventDateValidator extends _crossCheckDsl.BasicValidator {
    validate(value) {
      var address = this.env.get(value, "address");
      var livestream = this.env.get(value, "livestream");
      var eventDate = this.env.get(value, "eventDate");
      var errors = [];

      if (livestream && !eventDate) {
        errors.push({
          path: ["eventDate"],
          message: {
            name: "eventDate",
            details: { field: "livestream" }
          }
        });
      }

      if (address && !eventDate) {
        errors.push({
          path: ["eventDate"],
          message: {
            name: "eventDate",
            details: { field: "address" }
          }
        });
      }

      return errors;
    }
  }

  exports.EventDateValidator = EventDateValidator;
  var hasEventDate = exports.hasEventDate = (0, _crossCheckDsl.builderFor)(EventDateValidator);

  function eventDate() {
    return object({
      address: (0, _copilotValidators.optional)(isString()),
      livestream: (0, _copilotValidators.optional)(isBoolean()),
      eventDate: (0, _copilotValidators.optional)((0, _copilotValidators.date)())
    }).andThen(hasEventDate());
  }
});