define("copilot/components/category-picker/category/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["category"],
    classNameBindings: ["loading:spinner-medium:", "category.isHighlighted:highlight:"],

    selectable: Ember.computed.alias("category.config.selectable"),
    isChecked: Ember.computed("selected.[]", "category", function () {
      if (!this.selected) {
        return false;
      }
      var categoryId = this.get("category.id");
      var selectedIds = this.selected.map(function (category) {
        return category.get("id");
      });
      return selectedIds.includes(categoryId);
    }),
    isRequired: Ember.computed.gt("category.config.minSelected", 0),

    actions: {
      fetch: function fetch(category) {
        var _this = this;

        if (this.navigatable) {
          this.set("children", "loading");
          this.fetch(category).then(function (children) {
            return _this.set("children", children);
          });
        }
      },
      select: function select(category) {
        this.send("fetch", category);
        if (this.selectable) {
          this.selectCategory(category);
        }
      }
    }
  });
});