define("copilot/routes/livestories/index/livestoryupdates/index", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route"], function (exports, _unsavedWarning, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, {
    saveService: Ember.inject.service("save"),
    brand: Ember.inject.service(),
    controllerName: "livestories.livestoryupdates.index",
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    model: function model(params) {
      return this.store.find("livestoryupdate", params.livestoryupdate_id);
    },

    actions: {
      saveLivestoryupdate: function () {
        var _ref = _asyncToGenerator(function* (livestoryupdate) {
          var transition = Ember.get(this, "transition");
          yield livestoryupdate.setInlines();
          var isValid = yield livestoryupdate.validate("save");
          if (!isValid) {
            return livestoryupdate;
          }
          yield this.saveService.save(livestoryupdate);
          if (this.controller.showUnsavedDialog && transition) {
            this.controller.set("showUnsavedDialog", false);
            transition.retry();
          } else {
            this.transitionTo("/" + this.activeBrand + "/livestories/" + livestoryupdate.liveStoryId + "/livestoryupdates/create");
          }
        });

        function saveLivestoryupdate(_x) {
          return _ref.apply(this, arguments);
        }

        return saveLivestoryupdate;
      }()
    }
  });
});