define("copilot/components/thumbnail-upload/component", ["exports", "copilot-components/mixins/form-inputs", "ember-service-methods/inject", "ember-inflector"], function (exports, _formInputs, _inject, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
    A component for selecting content items to add as a relationship onto a given model
  
    The `{{thumbnail-upload}}` component allows the user to manage a rel for
    a given attribute on a content item. For example, managing the photosTout
    related to the current content model.
  
    A `{{thumbnail-upload}}` is a component designed to be used within `{{copilot-form}}`.
    This component can be used by itself, with a small template:
    ```htmlbars
    {{thumbnail-upload 'photosTout' model=article}}
    ```
    The invocation above will update the value of `article.photosTout` directly using
    two-way bindings.
    In a `{{copilot-form}}`, this thumbnail-upload will be rendered similarly:
    ```htmlbars
    {{#copilot-form model=article as |f|}}
      {{f.thumbnail-upload 'photosTout'}}
    {{/copilot-form}}
    ```
    The `{{thumbnail-upload}}` allows for the ability to pass a block into its invocation in order to
    overwrite the default header.
  
    The model is stored in the form, so we don't need to pass it to the field.
  
    @module copilot/components/thumbnail-upload/component
    @augments Ember.Component
    @param {String} field The field name of the model to use as the value of the textarea
    @param {Object} model The object to fetch the field from.
    @param {String} [customLabel] The label of the field, if the guessed field name is wrong.
   */

  var ThumbnailUpload = Ember.Component.extend(_formInputs.default, {
    classNames: ["form-group", "thumb-upload"],
    classNameBindings: ["labelClass", "field", "hasError"],
    isOpen: false,
    brand: Ember.inject.service(),
    photoService: Ember.inject.service("photo"),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    variantCount: Ember.computed.alias("relModel.variantCount"),
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var field = this.field;
      Ember.defineProperty(this, "fieldValue", Ember.computed.alias("model." + field));
    },


    uploadAsset: (0, _inject.default)(),

    modelName: Ember.computed("model", function () {
      return Ember.get(this, "model.meta.modelName") || Ember.get(this, "model.type");
    }),

    modelId: Ember.computed("model.id", function () {
      return Ember.get(this, "model.id") || "new";
    }),

    canRemove: Ember.computed.notEmpty("fieldValue"),

    media: Ember.computed("fieldValue.firstObject", function () {
      var media = this.fieldValue;
      if (Ember.isArray(media)) {
        return media.firstObject;
      }
      return media;
    }),

    mediaType: Ember.computed("media", function () {
      return Ember.get(this, "media.meta.modelName") || Ember.get(this, "media.type");
    }),

    labelClass: Ember.computed("label", function () {
      return (this.label || "").toLowerCase();
    }),

    hasEditRoute: Ember.computed("router", "mediaType", function () {
      return this.router._router.hasRoute((0, _emberInflector.pluralize)(this.mediaType) + ".index");
    }),

    canEditVariants: Ember.computed("field", "features.variants", function () {
      var fieldsAllowed = ["photosTout", "photosLede"].includes(this.field);
      return fieldsAllowed && this.get("features.variants");
    }),

    hasError: Ember.computed("model.validationErrors.[]", function () {
      var validationField = this.field;
      return Ember.isPresent(this.get("model.validationErrors." + validationField + ".errors"));
    }),

    relModel: Ember.computed("media", "media.embedded", function () {
      return this.get("media.embedded") || this.media;
    }),

    image: Ember.computed("relModel.{type,imageUrl,meta.modelName,_embedded.photosTout.firstObject.fields}", "photo", function () {
      var isNotClip = this.get("relModel.type") !== "clip" && this.get("relModel.meta.modelName") !== "clip";
      if (isNotClip) {
        return this.relModel;
      }
      return this.get("relModel._embedded.photosTout.firstObject.fields") || this.photo;
    }),

    disabled: false,

    actions: {
      upload: function upload(file) {
        var _this = this;

        file.set("uploadLocation", this.get("model.uploadLocation"));
        return this.uploadAsset(file).then(function (asset) {
          Ember.set(_this, "errors", null);
          var field = _this.field;
          if (field.slice(0, 7) === "variant") {
            var model = _this.model;
            model.set(field, [asset]);
          } else {
            if (Ember.isArray(_this.fieldValue)) {
              Ember.get(_this, "fieldValue").clear();
              Ember.get(_this, "fieldValue").pushObject(asset);
            } else {
              Ember.set(_this.model, _this.field, asset);
            }
          }
        }, function (errors) {
          Ember.set(_this, "errors", errors.mapBy("string"));
        });
      },
      findAsset: function findAsset() {
        this.analytics.storyEvent("Photo Card Asset Finder Open");
        this.setProperties({
          isSelectingAsset: true,
          errors: false
        });
      },
      assignItem: function assignItem(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            item = _ref2[0];

        this.analytics.storyEvent("Photo Card Asset Rendered", {
          renderedPhoto: item
        });
        if (Ember.isArray(this.fieldValue)) {
          this.fieldValue.setObjects([item]);
        } else {
          Ember.set(this.model, this.field, item);
        }
        this.setProperties({
          isSelectingAsset: false,
          errors: false
        });
      },
      remove: function remove() {
        var model = this.model;
        var field = this.field;
        if (Ember.isArray(this.fieldValue)) {
          Ember.set(model, field, []);
        } else {
          Ember.set(model, field, null);
        }
      },
      closeDropdown: function closeDropdown() {
        if (!this.isDestroyed) {
          this.set("isOpen", false);
        }
      },
      toggleOpen: function toggleOpen() {
        var _this2 = this;

        if (!this.isOpen) {
          Ember.$(document).trigger("click");
        }

        this.toggleProperty("isOpen");

        if (this.isOpen) {
          Ember.$(document).one("click", function () {
            Ember.run(function () {
              if (_this2.isDestroyed) {
                return;
              }
              _this2.send("closeDropdown");
            });
          });
        }
      }
    }
  });

  ThumbnailUpload.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = ThumbnailUpload;
});