define("copilot/serializers/recipe", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      var normalized = this._super.apply(this, arguments);
      normalized.recipeType = hash.type;
      return normalized;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.type = json.recipeType;
      delete json["recipeType"];
      return json;
    }
  });
});