define("copilot/utils/string", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.titleize = titleize;


  // Currently, the only use case for this is English, but in the future we can make it
  // so the list of short words is language based.
  var shortWords = ["of", "a", "the", "and", "an", "or", "nor", "but", "is", "if", "then", "else", "when", "at", "from", "by", "on", "off", "for", "in", "out", "over", "to", "into", "with"];

  function capitalizeLongWords(string) {
    return shortWords.includes(string) ? string : Ember.String.capitalize(string);
  }

  function titleize(string) {
    return string.underscore().split("_").map(capitalizeLongWords).join(" ");
  }
});