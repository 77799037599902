define("copilot/brands/epi/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      active: "Active",
      "address.street": "Street Name",
      "address.city": "City",
      "address.neighborhood": "Neighborhood",
      "address.state": "State",
      "address.postalCode": "Postal Code",
      "address.country": "Country",
      amgUserId: "Member ID",
      attribution: "Attribution",
      author: "Author",
      byline: "Byline",
      categories: "Categories",
      category: "Category",
      contributorsAuthor: "Authors",
      cookbooks: "Cookbooks",
      copyrightDisplay: "Copyright Display",
      copyrightYear: "Copyright Year",
      courseDescription: "Course Description",
      courseName: "Course Name",
      eCommLink: "E Comm Link",
      "geo.lat": "Latitude",
      "geo.lng": "Longitude",
      imageSocial: "Social Image",
      ingredientProducts: "Ingredient Products",
      issueMonth: "Issue Month",
      issueYear: "Issue Year",
      menuType: "Menu Type",
      organization: "Organization",
      paymentsAccepted: "Payments Accepted",
      phoneNumber: "Phone Number",
      photosBadge: "Badge",
      photosPromoTout: "Promo Tout",
      primaryChannel: "Primary Channel",
      products: "Products",
      public: "Public",
      publisher: "Publisher",
      quote: "Quote",
      recipes: "Recipes",
      recipeType: "Recipe Type",
      relatedVideo: "Related Video",
      reservationLink: "Reservation Link",
      restaurant: "Restaurant",
      rotdDate: "Recipe of the Day",
      taxonomies: "Taxonomies",
      shortBio: "Short Bio",
      socialShortUrl: "Short URL",
      subType: "Sub Type",
      taxonomy: "Taxonomy",
      venue: "Venue",
      videosTout: "Promo Video"
    },
    fieldsetLegends: {
      address: "Address",
      contactInformation: "Contact Information",
      courses: "Courses",
      highlight: "Highlight",
      highlightsGallery: "Highlights Gallery",
      mainTout: "Promo Image",
      meta: "Meta",
      paymentInformation: "Payment Information",
      readOnlyInformation: "Read-only Information",
      section1: "Section 1",
      section2: "Section 2",
      section3: "Section 3",
      section4: "Section 4",
      section5: "Section 5",
      targetReference: "Target Reference"
    },
    placeholder: {
      packages: {
        hed: "New Package"
      },
      taxonomies: {
        name: "New Taxonomy"
      },
      cookbooks: {
        hed: "New Cookbook",
        eCommLink: "http://"
      },
      menus: {
        hed: "New Menu"
      }
    }
  };
});