define("copilot/components/collection-with-create/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    openInNewTab: true,
    active: false,

    onOutsideClick: function onOutsideClick() {
      this.set("active", false);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var active = this.get("active");

      var button = document.querySelector("button.inline-create");
      var $button = Ember.$(button);

      var popper = document.querySelector(".inline-create.popper");
      var $popper = Ember.$(popper);

      var $target = Ember.$(event.target);

      if (active && !$target.closest($popper).length && !$target.closest($button).length) {
        this.onOutsideClick();
      }
    },
    didRender: function didRender() {
      this.set("clickHandler", Ember.run.bind(this, this.handleOutsideClick));
      Ember.$(document).click(this.get("clickHandler"));
    },
    willDestroyElement: function willDestroyElement() {
      this.set("active", false);
      Ember.$(document).off("click", this.get("clickHandler"));
    },


    actions: {
      toggleActive: function toggleActive() {
        var active = this.get("active");
        this.set("active", !active);
      }
    }
  }).reopenClass({
    positionalParams: ["field"]
  });
});