define("copilot/components/page-layout/component", ["exports", "ember-service-methods/inject", "ember-concurrency", "@sentry/browser"], function (exports, _inject, _emberConcurrency, _browser) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  class NoCategoriesError extends Error {
    constructor() {
      super("None of the configured verso header settings categories were found under verso-settings/article-settings/ContentHeader/variation");
    }
  }

  class EmptyConfigurationError extends Error {
    constructor() {
      super("Brand has not enabled any Verso header settings.");
    }
  }

  class DefaultCategoryError extends Error {
    constructor(defaultCategorySlug) {
      super("The configured default page layout category slug, " + defaultCategorySlug + ", does not match any existing category.");
    }
  }

  class SelectedCategoryError extends Error {
    constructor(category) {
      super("This brand does not support the Verso header setting " + category.slug + ".");

      this.category = category;
    }
  }

  class MissingToggleConfigurationError extends Error {
    constructor(category) {
      super("There is no category-toggle-mapping configured for the selected layout " + category.slug + ".");

      this.category = category;
    }
  }

  class DuplicateToggleConfigurationError extends Error {
    constructor(category1, category2) {
      super("Two categories, " + category1 + " and " + category2 + ", have the same toggle settings in the category-toggle-mapping config.");

      this.conflict = {
        path: [],
        categories: [category1, category2]
      };
    }

    addPathSegment(key) {
      this.conflict.path = [key].concat(_toConsumableArray(this.conflict.path));
    }
  }

  var TRIE_RESULT_KEY = Symbol("category");

  function createTrie(hierarchy, configuredLayoutCategories) {
    var trie = {};

    var _loop = function _loop(category, currentConfig) {
      var trieKeyArray = hierarchy.map(function (level) {
        return currentConfig[level];
      });
      setInTrie(trie, trieKeyArray, category);
    };

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = configuredLayoutCategories[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var category = _ref2[0];
        var currentConfig = _ref2[1];

        _loop(category, currentConfig);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return trie;
  }

  function setInTrie(trie, _ref3, value) {
    var _ref4 = _toArray(_ref3),
        nextKey = _ref4[0],
        restKeys = _ref4.slice(1);

    var nextKeyNoNull = nextKey === null ? "--noDisplay" : nextKey;
    if (nextKeyNoNull !== undefined) {
      if (!trie[nextKeyNoNull]) {
        trie[nextKeyNoNull] = {};
      }

      try {
        setInTrie(trie[nextKeyNoNull], restKeys, value);
      } catch (duplicateError) {
        if (duplicateError.constructor === DuplicateToggleConfigurationError) {
          duplicateError.addPathSegment(nextKeyNoNull);
        }
        throw duplicateError;
      }
    } else {
      if (trie[TRIE_RESULT_KEY]) {
        throw new DuplicateToggleConfigurationError(trie[TRIE_RESULT_KEY], value);
      }
      trie[TRIE_RESULT_KEY] = value;
    }
  }

  function getInTrie(trie, _ref5) {
    var _ref6 = _toArray(_ref5),
        nextKey = _ref6[0],
        restKeys = _ref6.slice(1);

    var nextKeyNoNull = nextKey === null ? "--noDisplay" : nextKey;
    if (nextKeyNoNull !== undefined) {
      if (!trie[nextKeyNoNull]) {
        var defaultKey = Object.keys(trie)[0];
        return getInTrie(trie[defaultKey], restKeys);
      }
      return getInTrie(trie[nextKeyNoNull], restKeys);
    }
    return trie[TRIE_RESULT_KEY];
  }

  exports.default = Ember.Component.extend({
    logger: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    config: Ember.computed.reads("configService.brandConfig.pageLayout"),
    features: Ember.inject.service(),
    hierarchy: Ember.computed.reads("config.toggle-level-hierarchy"),
    toggleMapping: Ember.computed.reads("config.category-toggle-mapping"),
    search: (0, _inject.default)(),
    brand: Ember.computed.reads("brandConfig.activeBrand"),
    availableLayoutCategories: null,
    toggleDecisionTree: null,
    errorState: null,
    iframeLoaded: false,
    copilotV2Url: "",

    init: function init() {
      this._super.apply(this, arguments);

      this.set("articleConfig", this.configService.getContentTypeConfig({ type: "article" }));

      try {
        this.set("toggleDecisionTree", createTrie(this.hierarchy, this.configuredLayoutCategories));
      } catch (err) {
        this.error(err);
      }

      this.set("getInTrie", getInTrie);
      this.set("copilotV2Url", this.configService.getCopilotV2Url());
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.errorState) {
        // none of our error states are recoverable without reloading the page
        return;
      }

      if (this.currentCategory && !this.currentToggleSelection) {
        this.error(new MissingToggleConfigurationError(this.currentCategory));
      }

      if (!this.availableLayoutCategories || !this.defaultCategory || !this.currentCategory) {
        this.fetchAvailableLayoutCategories.perform().then(function (availableLayoutCategories) {
          _this.set("availableLayoutCategories", availableLayoutCategories);

          if (availableLayoutCategories.filter(function (category) {
            return _this.configuredLayoutCategories.map(function (_ref7) {
              var _ref8 = _slicedToArray(_ref7, 1),
                  slug = _ref8[0];

              return slug;
            }).includes(category.slug);
          }).length === 0) {
            throw new NoCategoriesError();
          }

          var defaultSlug = _this.get("articleConfig.defaultVersoHeaderLayout");
          var defaultCategory = availableLayoutCategories.findBy("slug", defaultSlug);
          _this.set("defaultCategory", defaultCategory);

          if (!defaultCategory) {
            throw new DefaultCategoryError(defaultSlug);
          }

          // set the verso header setting to default if none is set on the model
          if (!_this.currentCategory) {
            _this.onLayoutCategoryChange(defaultCategory);
          }
        }).catch(this.error.bind(this));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.handleMessage = function (event) {
        var origin = event.origin,
            data = event.data;

        if (origin !== _this2.get("copilotV2Url")) {
          return;
        }
        if (data.action === "settings_update") {
          var newCategoryObject = _this2.availableLayoutCategories.find(function (setting) {
            return setting.slug === data.content.slug;
          });

          _this2.onLayoutCategoryChange(newCategoryObject);
        }

        if (data.action === "reset_default") {
          _this2.actions.resetToDefault.call(_this2);
        }

        if (data.action === "iframe_loaded") {
          _this2.set("iframeLoaded", true);
          window.iFrameResize({ log: false }, "#page-layout-iframe");

          //TODO: This is a temp fix. Remove once unnecessary setting_uptate call is fixed.
          _this2.postStateUpdate.call(_this2);
        }

        if (data.action === "media_overrides_update") {
          var photosLede = data.content.find(function (_ref9) {
            var relName = _ref9.relName;
            return relName === "photosLede";
          });
          if (photosLede) {
            _this2.updatePhotosLedeModel(photosLede.overrides);
          }
        }
      };
      if (this.get("features.enableAspectRatioSelection") && typeof window !== "undefined") {
        window.addEventListener("message", this.handleMessage, false);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get("features.enableAspectRatioSelection")) {
        this.postStateUpdate();
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener("message", this.handleMessage);
    },
    updatePhotosLedeModel: function updatePhotosLedeModel(overridesInput) {
      var overrides = this.transformToOverridesObject(overridesInput);
      if (this.model.mediaOverrides === null || this.model.mediaOverrides === undefined) {
        this.model.set("mediaOverrides", []);
      }
      if (overrides.length > 0) {
        var photosLede = this.model.mediaOverrides.findBy("relName", "photosLede");
        if (!photosLede) {
          var updatedMediaOverrides = this.store.createRecord("media-overrides", {
            relName: "photosLede"
          });
          updatedMediaOverrides.get("overrides").pushObjects(overrides);
          this.model.mediaOverrides.pushObject(updatedMediaOverrides);
        } else {
          photosLede.set("overrides", overrides);
        }
        this.postStateUpdate();
      } else {
        this.resetMediaOverrides();
      }
    },
    resetMediaOverrides: function resetMediaOverrides() {
      if (!Ember.isEmpty(this.model.mediaOverrides)) {
        var updatedMediaOverrides = this.model.mediaOverrides.rejectBy("relName", "photosLede");
        this.model.set("mediaOverrides", updatedMediaOverrides);
      }
    },
    transformToOverridesObject: function transformToOverridesObject(overridesInput) {
      var self = this;
      var overrides = overridesInput.map(function (override) {
        return self.store.createRecord("media-override", {
          breakpoint: override.breakpoint,
          aspectRatio: override.aspectRatio
        });
      });
      return overrides;
    },
    postStateUpdate: function postStateUpdate() {
      var iFrame = document.getElementById("page-layout-iframe");
      if (!iFrame) {
        return;
      }

      var versoSettings = this.versoSettings ? this.versoSettings.map(function (setting) {
        var settingObject = JSON.parse(JSON.stringify(setting));
        var hierarchyString = setting.get("hierarchyString");
        return Object.assign({}, settingObject, {
          hierarchyString: hierarchyString
        });
      }) : [];

      var photosLede = {};
      var data = this.model.get("photosLede.firstObject._data");
      if (data) {
        var photoType = data.meta.collectionName;
        if (photoType === "photos") {
          var klass = this.store.modelFor("photos");
          photosLede = this.store.adapterFor("photo").get("serializer").normalize(klass, data);
          photosLede.aspectRatios = photosLede.aspectRatios.map(function (_ref10) {
            var key = _ref10.key,
                rest = _objectWithoutProperties(_ref10, ["key"]);

            return Object.assign({
              name: key
            }, rest);
          });
        }
      } else {
        this.resetMediaOverrides();
      }
      iFrame.contentWindow.postMessage({
        action: "state_update",
        content: JSON.parse(JSON.stringify({
          versoSettings: versoSettings,
          photosLede: photosLede,
          mediaOverrides: this.model.mediaOverrides
        }))
      }, this.get("copilotV2Url"));
    },


    photosLedeChanged: Ember.observer("photosLede.firstObject", function () {
      if (this.get("features.enableAspectRatioSelection")) {
        this.resetMediaOverrides();
        this.postStateUpdate();
      }
    }),

    getCategoryFromToggles: function getCategoryFromToggles(toggles) {
      var togglesArray = this.hierarchy.map(function (level) {
        return toggles[level];
      });
      return getInTrie(this.toggleDecisionTree, togglesArray);
    },


    currentCategory: Ember.computed("versoSettings", function () {
      if (!this.versoSettings) {
        return;
      }

      var category = void 0;
      try {
        category = this.versoSettings.find(function (setting) {
          return setting.hierarchyString.includes("article-settings/ContentHeader/variation");
        });

        if (!category) {
          return;
        }

        if (!this.get("articleConfig.versoHeaderLayouts").includes(category.slug)) {
          throw new SelectedCategoryError(category);
        }

        if (!this.get("toggleMapping." + category.slug)) {
          throw new MissingToggleConfigurationError(category);
        }
      } catch (err) {
        this.error(err);
        return;
      }

      return category;
    }),

    currentToggleSelection: Ember.computed("currentCategory", function () {
      // computed property will re-evaluate once fetchAvailableLayoutCategories fetch has completed
      if (!this.currentCategory) {
        return;
      }

      return this.toggleMapping[this.currentCategory.slug];
    }),

    configuredLayoutCategories: Ember.computed("toggleMapping", "articleConfig", function () {
      var _this3 = this;

      if (!this.get("articleConfig.versoHeaderLayouts.length")) {
        throw new EmptyConfigurationError();
      }

      var configuredLayoutCategories = Object.entries(this.toggleMapping).filter(function (_ref11) {
        var _ref12 = _slicedToArray(_ref11, 1),
            slug = _ref12[0];

        return _this3.get("articleConfig.versoHeaderLayouts").includes(slug);
      });

      if (!configuredLayoutCategories.length) {
        throw new NoCategoriesError();
      }

      return configuredLayoutCategories;
    }),

    fetchAvailableLayoutCategories: (0, _emberConcurrency.task)(function* () {
      var categories = yield this.search({
        types: "category",
        hierarchy: "verso-settings/article-settings/ContentHeader/variation",
        limit: 1000 // this is an arbitrary "large" number so that the results don't get cut off
      });

      // don't include `variation` category itself, only its children
      var availableLayoutCategories = categories.hits.filter(function (category) {
        return category.hierarchyString !== "verso-settings/article-settings/ContentHeader/variation";
      });

      return availableLayoutCategories;
    }).drop(),

    error: function error(err) {
      if ((0, _emberConcurrency.didCancel)(err)) {
        return; // error was just a task cancellation
      }
      this.set("errorState", true);
      _browser.captureException(err);
      this.logger.error(err.message, err);
      // eslint-disable-next-line no-console
      console.error(err);
    },


    actions: {
      doToggleSelection: function doToggleSelection(level, toggleOption) {
        if (!this._recordingStarted && window.hasOwnProperty("hj")) {
          hj("trigger", "verso_header_selection");
          this._recordingStarted = true;
        }
        var newToggleSelection = Object.assign({}, this.currentToggleSelection, _defineProperty({}, level, toggleOption));
        var newCategory = this.getCategoryFromToggles(newToggleSelection);
        var newCategoryObject = this.availableLayoutCategories.find(function (setting) {
          return setting.slug === newCategory;
        });

        this.onLayoutCategoryChange(newCategoryObject);
      },
      resetToDefault: function resetToDefault() {
        this.onLayoutCategoryChange(this.defaultCategory);
      }
    }
  });
});