define("copilot/lib/analytics/segment-adapter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    identify: function identify(userId, payload) {
      window.analytics.identify(userId, Object.assign({}, payload, { source_type: "Copilot" }));
    },
    page: function page() {
      window.analytics.page();
    },
    track: function track(objectName, action, properties, options) {
      /**
          Sadly we can't simply send one track event to all integrations due
          to each integration needing it's own format of properties. Sending all
          properties to all integrations would cause extraneous data
          */
      var eventName = Ember.String.capitalize(objectName) + " " + action;
      window.analytics.track(eventName, properties, {
        integrations: {
          "Google Analytics": false
        }
      });

      properties.category = objectName;
      if (options.label && properties[options.label]) {
        properties.label = properties[options.label];
      }
      if (options.value && properties[options.value]) {
        properties.value = properties[options.value];
      }
      options.integrations = {
        All: false,
        "Google Analytics": true
      };

      window.analytics.track(action, properties, options);
    }
  };
});