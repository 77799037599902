define("copilot/components/bundle-side-bar/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    tagName: "nav",
    classNames: ["bundle-side-bar"],
    classNameBindings: ["isSortable:sortable"],
    actions: {
      showConfirmationModal: function showConfirmationModal(bundleContainer) {
        this.showConfirmationModal(bundleContainer);
      }
    }
  });
});