define("copilot/components/locale-switcher/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    locale: Ember.computed.reads("intl.locale"),

    locales: Ember.computed.reads("intl.locales"),

    actions: {
      switchLocale: function switchLocale(locale) {
        window.localStorage.setItem("locale", locale);
        this.changeLocale(locale);
      }
    }
  });
});