define("copilot/components/main-search/recent-search-result/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ICONS = {
    advertiser: "16px-content-type_advertiser",
    airline: "16px-content-type_airline",
    airport: "16px-content-type_airport",
    article: "16px-content-type_article",
    audiofile: "16px-content-type_audiofile",
    bar: "16px-content-type_bar",
    brand: "16px-content-type_brand",
    bundle: "16px-content-type_bundle",
    cartoon: "16px-content-type_cartoon",
    clip: "16px-content-type_clip",
    contributor: "16px-profile",
    curatedlist: "16px-unordered-list",
    curatedsearch: "search",
    destination: "16px-content-type_destination",
    event: "16px-content-type_event",
    external: "16px-redirect",
    externallink: "16px-redirect",
    fashiongallery: "16px-content-type_gallery",
    fashionshow: "16px-content-type_fashionshow",
    gallery: "16px-content-type_gallery",
    hotel: "16px-content-type_hotel",
    hotlist: "16px-content-type_hotlist",
    house: "home",
    list: "16px-unordered-list",
    menu: "16px-content-type_menu",
    musicalbum: "16px-content-type_musicalbum",
    musicrecording: "16px-content-type_musicrecording",
    nativearticle: "16px-content-type_article",
    newsletter: "16px-content-type_newsletter",
    person: "16px-profile",
    photo: "16px-content-type_photo",
    product: "16px-content-type_product",
    rcalist: "16px-content-type_rcalist",
    recipe: "16px-content-type_recipe",
    restaurant: "16px-content-type_restaurant",
    review: "16px-content-type_review",
    runwaymoment: "16px-content-type_runwaymoment",
    season: "16px-content-type_event",
    ship: "16px-content-type_ship",
    shop: "16px-content-type_shop",
    skiresort: "16px-content-type_skiresort",
    spa: "16px-content-type_spa",
    taxonomy: "16px-content-type_taxonomy",
    venue: "16px-content-type_venue"
  };

  var DEFAULT_ICON = "16px-content-type_article";

  exports.default = Ember.Component.extend({
    icon: Ember.computed("item.type", function () {
      return ICONS[this.item.type] || DEFAULT_ICON;
    })
  });
});