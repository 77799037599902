define("copilot/routes/galleries/create/edit", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    controllerName: "galleries/index",

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.hideCarousel();
      controller.set("currentSelection", null);
    }
  });
});