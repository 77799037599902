define("copilot/components/publish-history/component", ["exports", "copilot/lib/copilot-computeds", "moment", "lodash-es"], function (exports, _copilotComputeds, _moment, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var equalProperties = _copilotComputeds.default.equalProperties;


  var HistoryItem = Ember.Object.extend({
    status: Ember.computed("model", "currentlyEditing", "expired", function () {
      var currentlyPublishedRevision = this.get("model.currentlyPublishedRecord.revision");
      var revision = this.revision;
      if (revision === currentlyPublishedRevision) {
        return "published";
      } else if (Ember.get(this, "expired")) {
        return "unpublished";
      } else if ((0, _moment.default)(this.pubDate).isAfter((0, _moment.default)(new Date()))) {
        return "scheduled";
      } else if (this.currentlyEditing) {
        return "editing";
      } else {
        return "previous";
      }
    }),

    currentlyEditing: equalProperties("model.revision", "revision"),

    isActive: Ember.computed("status", function () {
      return ["syndicated", "editing", "published", "scheduled"].indexOf(Ember.get(this, "status")) !== -1;
    })
  });

  exports.default = Ember.Component.extend({
    tagName: "fieldset",
    classNames: ["publish-history"],
    brand: Ember.inject.service(),
    env: Ember.computed.alias("brandConfig.envUrl"),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    sortedHistory: Ember.computed.sort("history", "sortHistoryBy"),
    selectedRecord: null,
    publish: Ember.inject.service(),
    features: Ember.inject.service(),

    sortHistoryBy: Ember.computed("sortBy", "direction", function () {
      var sorters = [Ember.get(this, "sortBy") + ":" + Ember.get(this, "direction")];
      if (Ember.get(this, "sortBy") !== "createdAt") {
        sorters.push("createdAt:" + Ember.get(this, "direction"));
      }
      return sorters;
    }),

    sortBy: "createdAt",
    direction: "desc",

    canEditRevisions: Ember.computed.reads("model.constructor.metadata.canEditRevisions"),

    showDiffViewer: Ember.computed("model.revision", function () {
      var isArticle = this.get("model.type") === "article";
      var hasRevisions = this.get("model.revision") > 0;
      var isFeatureEnabled = this.get("features.diffViewer");
      return isFeatureEnabled && isArticle && hasRevisions;
    }),

    transformToPublishedRecord: function transformToPublishedRecord(syndication, record) {
      var data = {
        revisionAuthor: Ember.get(record, "syndicationAuthor"),
        createdAt: Ember.get(record, "createdAt"),
        uri: Ember.get(syndication, "canonicalUrl")
      };

      if (this.model.isSyndicator(this.activeBrand)) {
        data.brand = Ember.get(record, "brand");
        data.status = "syndicatedOut";
      } else {
        data.brand = Ember.get(syndication, "source");
        data.status = "syndicatedIn";
      }
      return data;
    },
    getSyndicationHistory: function getSyndicationHistory() {
      var _this = this;

      var syndication = this.get("model.syndication");
      var brand = this.activeBrand;
      var syndicationRecords = void 0;

      if (syndication && this.model.isSyndicator(brand)) {
        syndicationRecords = Ember.get(syndication, "targets");
      } else {
        syndicationRecords = _lodashEs.default.compact([this.model.syndicateeRecord(brand)]);
      }

      return syndicationRecords.map(function (record) {
        return _this.transformToPublishedRecord(syndication, record);
      });
    },


    history: Ember.computed("model.publishHistory.data.[]", function () {
      var model = this.model;
      var publishHistory = Ember.get(this, "model.publishHistory.data") || [];
      return _lodashEs.default.compact([].concat(_toConsumableArray(publishHistory), _toConsumableArray(this.getSyndicationHistory()))).map(function (item) {
        var historyItem = HistoryItem.create(item);
        historyItem.model = model;
        return historyItem;
      });
    }),

    publishedSlug: Ember.computed.reads("model.currentOrQueuedPublishRecord.uri"),

    actions: {
      editRevision: function editRevision(history, evt) {
        this.action(history);

        evt.preventDefault();
        evt.stopPropagation();
        return false;
      },
      sortBy: function sortBy(sort, evt) {
        var direction = Ember.get(this, "direction");
        if (sort === Ember.get(this, "sortBy")) {
          if (direction === "desc") {
            direction = "asc";
          } else {
            direction = "desc";
          }
        } else {
          direction = "desc";
        }
        Ember.set(this, "sortBy", sort);
        Ember.set(this, "direction", direction);

        // Prevent event bubbling
        evt.preventDefault();
        evt.stopPropagation();
        return false;
      },
      unpublishRevision: function unpublishRevision(record) {
        var _this2 = this;

        var model = this.model;
        this.publish.unpublish(model, record).then(function () {
          _this2.set("selectedRecord", null);
        });
      }
    }
  });
});