define("copilot/services/create-slide", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    execute: function execute(gallery, asset, _ref) {
      var _ref$addTo = _ref.addTo,
          addTo = _ref$addTo === undefined ? "end" : _ref$addTo,
          _ref$type = _ref.type,
          type = _ref$type === undefined ? "gallery-item" : _ref$type;

      var slides = Ember.get(gallery, "items");

      if (slides.findBy("id", Ember.get(asset, "id"))) {
        var name = "" + (Ember.get(asset, "hed") || Ember.get(asset, "title"));
        return Ember.RSVP.reject({
          message: this.intl.t("components.gallery-create-media.alreadyExistsError", { name: name })
        });
      }

      var Model = this.store.modelFor(type);
      var owner = Ember.getOwner(this);
      var slide = Model.create(owner.ownerInjection());
      slide.setProperties({
        embedded: asset,
        isActive: true
      });

      switch (addTo) {
        case "beginning":
          slides.unshiftObject(slide);
          break;
        case "end":
          slides.pushObject(slide);
          break;
      }

      return Ember.RSVP.resolve(slide);
    }
  });
});