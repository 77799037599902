define("copilot/helpers/published-uri", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    configService: Ember.inject.service("config"),
    brandConfig: Ember.inject.service(),

    mountPoint: function mountPoint(model) {
      var contentTypeConfig = this.configService.getContentTypeConfig(model);
      var mountPoint = contentTypeConfig.publish.mountPoint;
      return mountPoint ? mountPoint + "/" : "";
    },
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          model = _ref2[0];

      if (!Ember.get(model, "publishable")) {
        return;
      }

      var publishUri = Ember.get(model, "currentOrQueuedPublishRecord.uri");
      var protocol = "http://";

      var displayBrand = Ember.get(model, "displayBrand");
      if (displayBrand) {
        var configService = this.configService;
        return "" + protocol + configService.getConsumerHostnameByBrandCode(displayBrand) + "/" + publishUri;
      } else {
        var mountPoint = this.mountPoint(model);
        return "" + protocol + this.get("brandConfig.envUrl") + "/" + mountPoint + publishUri;
      }
    }
  });
});