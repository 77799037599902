define("copilot/schema-helpers/default-country", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    configService: Ember.inject.service("config"),

    compute: function compute() {
      return this.get("configService.marketCode") ? this.get("configService.marketCode").toUpperCase() : "US";
    }
  });
});