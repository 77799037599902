define("copilot/routes/category-manager/category/create", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    categoryService: Ember.inject.service("category"),
    model: function model() {
      var parent = this.modelFor("category-manager.category");
      if (parent.id) {
        return this.get("categoryService").createChild(parent);
      } else {
        return this.get("categoryService").createChild(null);
      }
    }
  });
});