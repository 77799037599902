define("copilot/components/mini-chart/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "span",
    classNames: ["mini-chart"],

    didInsertElement: function didInsertElement() {
      var currentMonth = new Date().getMonth();
      Ember.$(this.element).peity("bar", {
        fill: function fill(_, index) {
          return index === currentMonth ? "#FF0000" : "#7bb4ce";
        }
      });
    }
  });
});