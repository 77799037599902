define("copilot/validations/brands/cnt/gallery", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var galleryValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.GalleryValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["channel"])).catch((0, _crossCheckDsl.mutePath)(["photosTout"]));

  exports.default = galleryValidationBuilder;
});