define("copilot/components/card-list/card-item-header/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["card-item-header"],
    isOpen: false,
    isShowingModal: false,
    contextualEditItem: null,

    /**
     * Returns current index position of model
     * @returns {String}
     */
    position: Ember.computed("index", "model.id", "activeIds.[]", function () {
      var limit = this.limit;
      if (limit > 1 || limit === undefined) {
        return this.activeIds ? this.activeIds.indexOf(this.get("model.id")) + 1 : this.index + 1;
      }
    }),

    showContextual: Ember.computed("model.embedded", "isSpecialList", function () {
      return this.get("model.embedded") && !this.isSpecialList;
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(document).off("click." + this.elementId);
    },


    actions: {
      closeDropdown: function closeDropdown() {
        if (!this.isDestroyed) {
          this.set("isOpen", false);
        }
      },
      toggleOpen: function toggleOpen() {
        var _this = this;

        if (!this.isOpen) {
          Ember.$(document).trigger("click");
        }

        this.toggleProperty("isOpen");

        if (this.isOpen) {
          Ember.$(document).one("click." + this.elementId, function () {
            Ember.run(function () {
              if (_this.isDestroyed) {
                return;
              }
              _this.send("closeDropdown");
            });
          });
        }
      },
      remove: function remove() {
        var model = this.model;
        this.removeItem(model);
      },
      edit: function edit() {
        var model = this.model;
        var itemModel = void 0;
        if (Ember.isEmpty(Ember.get(model, "type"))) {
          var itemFactory = Ember.get(this, "store").modelFor(Ember.get(this, "itemModel"));
          itemModel = itemFactory.create(model);
        } else {
          itemModel = model;
        }

        if ((itemModel.embedded.provider === "commerce-tools" || itemModel.embedded.provider === "commerce-tools-collection") && itemModel.embedded.meta.collectionName === "contentreferences") {
          itemModel.set("hed", "");
        }

        this.set("contextualEditItem", [itemModel]);
        this.set("isShowingModal", true);
      },
      submitEdits: function submitEdits(newItem) {
        var model = this.model;
        Ember.setProperties(model, newItem);
      },
      closeModal: function closeModal() {
        this.set("isShowingModal", false);
      }
    }
  });
});