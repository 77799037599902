define("copilot/controllers/livestories/livestoryupdates/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    livestoryId: Ember.computed.reads("model.liveStoryId"),

    latestUpdateCreatedAtDate: Ember.computed('updates.[]', function () {
      if (this.updates && this.updates.length) {
        return this.updates[0].createdAt;
      }
    }),

    earliestUpdateCreatedAtDate: Ember.computed('updates.[]', function () {
      if (this.updates && this.updates.length) {
        return this.updates[this.updates.length - 1].createdAt;
      }
    }),

    visibleUpdates: Ember.computed('updates.@each.showInFeed', function () {
      return Array.isArray(this.updates) && this.updates.filter(function (update) {
        return update.showInFeed;
      });
    }),

    hasMore: Ember.computed('updates.[]', 'remainingLivestoryupdateCount', function () {
      if (!this.remainingLivestoryupdateCount || !this.updates) {
        return;
      }

      return this.pageLimit < this.remainingLivestoryupdateCount;
    }),
    actions: {
      save: function save() {
        this.send('saveLivestoryupdate', this.model);
      }
    }

  });
});