define("copilot/components/search/search-filters/custom/component", ["exports", "copilot/components/search/search-filters/base/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    type: "custom",
    queryKey: "display",
    features: Ember.inject.service(),

    options: Ember.computed(function () {
      var _this = this;

      var options = ["all", "archived"];
      if (this.get("features").isEnabled("enableSyndication")) {
        options.push("issyndicatee");
      }
      return options.map(function (option) {
        return {
          name: _this.intl.t("components.search.filters.custom." + option),
          value: option
        };
      });
    })
  });
});