define("copilot/components/duration-field/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function lpad(string, count, filler) {
    if (string.length < count) {
      while (string.length < count) {
        string = "" + filler + string;
      }
    }
    return string;
  }

  function formatDuration(format, milliseconds) {
    if (milliseconds == null || milliseconds === "") {
      return "";
    }
    var hours = Math.min(Math.floor(milliseconds / 3600000), 23);
    var minutes = Math.min(Math.floor((milliseconds - hours * 3600000) / 60000), 59);
    var seconds = Math.min(Math.floor((milliseconds - hours * 3600000 - minutes * 60000) / 1000), 59);
    return format
    /*eslint-disable prefer-template */
    .replace("hh", lpad(hours + "", 2, "0")).replace("mm", lpad(minutes + "", 2, "0")).replace("ss", lpad(seconds + "", 2, "0"));
  }

  function parseDuration(format, string) {
    var parts = (string || "").split(":").map(function (part) {
      return parseInt(part, 10);
    });

    var units = format.split(":");
    var duration = 0;

    for (var i = 0, len = units.length; i < len; i++) {
      var unit = units[i];
      var value = parts[i];

      if (unit === "hh") {
        if (!isNaN(value) && value <= 23) {
          duration += 3600000 * value;
        } else {
          return false;
        }
      } else if (unit === "mm") {
        if (!isNaN(value) && value <= 59) {
          duration += 60000 * value;
        } else {
          return false;
        }
      } else if (unit === "ss") {
        if (!isNaN(value) && value <= 59) {
          duration += 1000 * value;
        } else {
          return false;
        }
      }
    }
    return duration;
  }

  var DurationField = Ember.Component.extend({
    classNames: ["duration-field"],

    /**
      The `name` property of the `input` element.
       @property name
      @type String
      @default null
     */
    name: null,

    /**
      The `format` that the duration should be displayed in.
       @property format
      @type String
      @default 'hh:mm:ss'
     */
    format: "hh:mm:ss",

    /**
      Whether or not the field is disabled.
       @property disabled
      @type Boolean
      @default false
     */
    disabled: false,

    didRender: function didRender() {
      this._updateDisplayValue(this._getValue());
    },
    _getValue: function _getValue() {
      if (this.isFocused) {
        var input = this.element.querySelector("input");
        return input.value;
      } else {
        return formatDuration(this.format, this.value);
      }
    },
    _setValue: function _setValue(value) {
      var duration = parseDuration(this.format, value);
      if (Ember.isBlank(value) || value == null || duration === 0) {
        this.onchange(null);
      } else if (duration !== false) {
        this.onchange(duration);
      }
      this._updateDisplayValue(value);
    },
    _updateDisplayValue: function _updateDisplayValue(displayValue) {
      var input = this.element.querySelector("input");
      var selectionStart = input.selectionStart;
      var selectionEnd = input.selectionEnd;
      var length = displayValue.length;

      // insert : after the first two number, or after hh:mm
      if ((displayValue.match(/^\d{2}$/) || displayValue.match(/^\d{1,2}:\d{2}$/)) && selectionStart === selectionEnd && selectionStart === length && this.intent !== "delete" && displayValue.split(":").length < 3) {
        displayValue += ":";
        selectionStart = selectionEnd = selectionStart + 1;
      }

      Ember.set(this, "displayValue", displayValue || "");
      input.value = displayValue || "";
      if (this.isFocused) {
        input.selectionStart = selectionStart;
        input.selectionEnd = selectionEnd;
      }
    },


    actions: {
      handleArrowKeys: function handleArrowKeys(evt) {
        var _this = this;

        this.set("intent", evt.key === "Backspace" ? "delete" : "insert");

        if (evt.key === "ArrowUp" || evt.key === "ArrowDown") {
          var input = this.element.querySelector("input");
          var cursor = input.selectionStart;

          var direction = evt.key === "ArrowUp" ? 1 : -1;
          var parts = this.format.split(":").map(function (part) {
            return {
              format: part,
              start: _this.format.indexOf(part),
              end: _this.format.indexOf(part) + part.length + 1
            };
          });

          var unit = parts.find(function (part) {
            return cursor >= part.start && cursor < part.end;
          });

          var duration = 0;
          if (unit.format === "hh") {
            duration += 3600000;
          } else if (unit.format === "mm") {
            duration += 60000;
          } else if (unit.format === "ss") {
            duration += 1000;
          }

          this._setValue(formatDuration(this.format, Math.max(this.value + duration * direction, 0)));
          return false;
        }
      },
      restrict: function restrict(evt) {
        var isNumber = /^[0-9]$/i.test(evt.key);

        if (evt.key === " ") {
          return false;
        }

        if (isNumber || evt.key === ":" || evt.metaKey) {
          return true;
        }

        return false;
      },
      reformat: function reformat() {
        var input = this.element.querySelector("input");
        this._setValue(input.value);
      },
      focus: function focus() {
        Ember.set(this, "isFocused", true);
      },
      blur: function blur() {
        Ember.set(this, "isFocused", false);
        this._updateDisplayValue(this._getValue());
        Ember.tryInvoke(this, "onblur");
      }
    }
  });

  exports.default = DurationField;
});