define("copilot/components/xml-importer/dialog/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["xml-importer-dialog"],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.xmlImporter.toMarkdown.perform().then(function (markdown) {
        _this.set("markdown", markdown);
      }).catch(function (err) {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },


    isRunning: Ember.computed.reads("xmlImporter.toMarkdown.isRunning"),

    actions: {
      import: function _import() {
        var oldBody = this.model.get("body");
        var newBody = this.markdown + "\n\n\n" + oldBody;
        this.model.set("body", newBody);
        this.dismiss();
      }
    }
  });
});