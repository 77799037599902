define("copilot/components/slide-carousel/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      reads = _Ember$computed.reads;
  exports.default = Ember.Component.extend({
    classNames: ["slide-carousel"],

    slides: reads("gallery.items"),
    onFirstPage: equal("currentPage", 0),

    didInsertElement: function didInsertElement() {
      this._resize = Ember.run.bind(this, "notifyPropertyChange", "carouselSize");
      Ember.$(window).on("resize", this._resize);
      this.notifyPropertyChange("carouselSize");
      this.notifyPropertyChange("slideSize");
      this.notifyPropertyChange("currentPage");
      this.scrollSlides();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off("resize", this._resize);
    },


    currentPage: Ember.computed("sildes.content.[]", "selected.id", {
      get: function get() {
        var _this = this;

        Ember.run.schedule("afterRender", this, function () {
          _this.scrollSlides();
        });
        var index = this.slides.mapBy("id").indexOf(this.get("selected.id"));
        return index < 0 ? 0 : Math.floor(index / this.slidesPerPage);
      },
      set: function set(_, page) {
        var _this2 = this;

        Ember.run.schedule("afterRender", this, function () {
          _this2.scrollSlides();
        });
        return page;
      }
    }),

    slideSize: Ember.computed(function () {
      return this.$(".carousel-slide").outerWidth();
    }),

    carouselSize: Ember.computed(function () {
      return this.$(".slide-wrapper").outerWidth();
    }),

    slidesPerPage: Ember.computed("slideSize", "carouselSize", function () {
      return Math.floor(this.carouselSize / this.slideSize);
    }),

    pageSize: Ember.computed("slideSize", "slidesPerPage", function () {
      return this.slideSize * this.slidesPerPage;
    }),

    onLastPage: Ember.computed("currentPage", function () {
      return Ember.isEqual(this.currentPage, this.lastPage);
    }),

    previousPage: Ember.computed("currentPage", "lastPage", function () {
      return Math.min(Math.max(this.currentPage - 1, 0), this.lastPage);
    }),

    nextPage: Ember.computed("currentPage", "lastPage", function () {
      return Math.min(this.currentPage, this.lastPage);
    }),

    lastPage: Ember.computed("slides.length", "slidesPerPage", function () {
      return Math.ceil(this.get("slides.length") / this.slidesPerPage) - 1;
    }),

    scrollSlides: function scrollSlides() {
      var offset = this.currentPage * -1 * this.pageSize;

      this.$(".slide-wrapper ul").css({
        transform: "translate3d(" + offset + "px, 0, 0)"
      });
    },


    actions: {
      first: function first() {
        this.set("currentPage", 0);
      },
      previous: function previous() {
        this.decrementProperty("currentPage");
      },
      next: function next() {
        this.incrementProperty("currentPage");
      },
      last: function last() {
        this.set("currentPage", this.lastPage);
      }
    }
  });
});