define("copilot/helpers/is-field-rich-for-brand", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isBodyField = isBodyField;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var TravellerVenues = ["activity", "airline", "airport", "bar", "destination", "hotel", "restaurant", "ship", "shop", "skiresort", "spa"];

  function isBodyField(model, fieldName) {
    return fieldName === "body" || model.type === "step" || fieldName === "copyrightDisplay" || TravellerVenues.indexOf(model.type) !== -1 && fieldName === "description";
  }

  exports.default = Ember.Helper.extend({
    features: Ember.inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          model = _ref2[0],
          fieldName = _ref2[1];

      if (isBodyField(model, fieldName)) {
        return this.features.get("ckeditorDefaultForBody");
      }
      return this.features.get("ckeditorDefaultForNonBody");
    }
  });
});