define("copilot/brands/cni/serializers/article", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (!json.lang) {
        delete json.lang;
      }
      return json;
    }
  });
});