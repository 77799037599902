define("copilot/routes/articles/index/syndicate", ["exports", "copilot/routes/authenticated-route", "copilot/lib/xml-import"], function (exports, _authenticatedRoute, _xmlImport) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    controllerName: "articles/syndicate",
    features: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.features.get("enableSyndication")) {
        this.transitionTo("not-found");
      }
    },


    actions: {
      importPrism: function importPrism(file) {
        this.set("controller.xmlImporter", _xmlImport.default.create({ file: file }));
      }
    }
  });
});