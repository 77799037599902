define("copilot/utils/bundleRedirect", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getBundleTypeFeatureFlag = getBundleTypeFeatureFlag;
  exports.isV2Bundle = isV2Bundle;
  exports.redirectBundle = redirectBundle;
  function getBundleTypeFeatureFlag(bundleType) {
    var bundleTypeFeatureFlagSegment = Ember.String.capitalize(Ember.String.camelize(bundleType));
    return "v2" + bundleTypeFeatureFlagSegment;
  }

  function isV2Bundle(features, bundleType) {
    var params = new URLSearchParams(window.location.search);
    var bundleTypeFeatureFlag = getBundleTypeFeatureFlag(bundleType);
    return features.get(bundleTypeFeatureFlag) && params.get("noRedirect") === null;
  }

  function redirectBundle(model, v2Url, currentBrand) {
    var bundleTypeRouteSegment = Ember.String.dasherize(model.bundleType);
    var idOrActionSegment = model.id || "create";
    var redirectTarget = new URL(v2Url + "/" + currentBrand + "/pages/" + bundleTypeRouteSegment + "/" + idOrActionSegment);
    window.location.replace(redirectTarget.toString());
  }
});