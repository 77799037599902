define("copilot/controllers/curatedsearches/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    contributorsLoaded: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.contributors = [];
      this.noids = Ember.A();
      this.sortFields = Ember.A();
    },


    actions: {
      remove: function remove(field) {
        this.sortFields.removeObject(field);
      }
    }
  });
});