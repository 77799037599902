define("copilot/initializers/forms", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.registerOptionsForType("form", { instantiate: false });
    application.registerOptionsForType("schema", { instantiate: false });
  }

  exports.default = {
    name: "forms",
    initialize: initialize,
    after: "service"
  };
});