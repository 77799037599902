define("copilot/app", ["exports", "ember-load-initializers", "copilot/config/environment", "copilot/lib/brand-resolver", "copilot/lib/cookie", "copilot/lib/log-error", "copilot/lib/store-extensions", "ember-inflector", "ember-model", "@sentry/browser", "@sentry/integrations", "@sentry/tracing"], function (exports, _emberLoadInitializers, _environment, _brandResolver, _cookie, _logError, _storeExtensions, _emberInflector, _emberModel, _browser, _integrations, _tracing) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Override Ember model's `Store.find` in order to ensure that it
  // fetches a fully hydrated API request by default. Necessary now that
  // we are not declaring all models transient, i.e. we are using the
  // identity map in Ember Model.
  // It seems Ember.Model has no public API to force a fetch with `find`. This
  // uses private and public API to achieve that but we may want to add
  // support upstream. Needed to ensure presence of backreferences.
  _emberModel.Store.reopen(_storeExtensions.default);

  // Disallow singularizing *-media for now
  _emberInflector.default.inflector.uncountable("social-media");
  _emberInflector.default.inflector.uncountable("third-party-media");

  // Turn on for easier debugging (check docs/debugging.md for more information)
  // Ember.run.backburner.DEBUG = true

  if (_environment.default.environment === "production") {
    _browser.init({
      dsn: "https://29be5333e4a84dd3bb4a8a2e1f5031aa@sentry.io/199396",
      release: _environment.default.APP.version,
      environment: _cookie.default.get("env"),
      integrations: [new _integrations.Ember(), new _tracing.BrowserTracing({
        beforeNavigate: function beforeNavigate(context) {
          return Object.assign({}, context, {
            name: location.pathname.replace(/\/[a-zA-Z0-9]+\//, "/<brand>/").replace(/\/[a-zA-Z]+\//, "/<contenttype>/").replace(/\/[a-f\d]{24}\/?/, "/<id>/").replace(/\/[a-zA-Z]+\//, "/<contenttype>/").replace(/\/[a-f\d]{24}/, "/<id>")
          });
        }
      })],
      ignoreErrors: ["TransitionAborted"],
      tracesSampleRate: 0.4
    });
  }

  function handleError(error) {
    if (Ember.testing === true) {
      throw error;
    }

    var jqXHRStatus = error.jqXHR && error.jqXHR.status;
    var errorStatus = error.status || jqXHRStatus;

    // Handle unauthenticated access
    if (errorStatus === 401) {
      Ember.run.throttle({
        error: error,
        pathname: window.location.pathname
      }, _logError.default, 2000);
      var message = Ember.String.htmlSafe("It looks like you've been logged out.\n        <a target=\"_blank\"\n        href=\"/auth/login\">Click here</a> to login in a new tab. Your work will be ready to post when you come back!");
      window.flashMessages.danger(message, {
        sticky: true
      });
      return;
    }
    Ember.run.throttle({
      error: error,
      pathname: window.location.pathname
    }, _logError.default, 2000);
  }

  Ember.onerror = handleError;

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _brandResolver.default,
    engines: {
      featureFlags: {
        dependencies: {
          externalRoutes: { index: "index" },
          services: ["analytics", "config", "brand-config", "ember-elsewhere", "event-bus", "experiments", "features", "intl", "keyboard", "router", "user", "-document"]
        }
      },
      sitesettings: {
        dependencies: {
          services: ["analytics", "brand", "config", "brand-config", "ember-elsewhere", "event-bus", "experiments", "features", "intl", "locale-mapper", "keyboard", "participants", "publish", "router", "save", "store", "user", "users", "-document", "schemaHelpers", "validation"],
          components: ["validations-messages"]
        }
      },
      users: {
        dependencies: {
          services: ["analytics", "brand-config", "config", "ember-elsewhere", "experiments", "flash-messages", "intl", "keyboard", "keyValueStorage", "logger", "schemaHelpers", "router", "user", "validation", "-document"]
        }
      }
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  Ember.LinkComponent.reopen({
    attributeBindings: ["data-result-number"]
  });

  exports.default = App;
});