define("copilot/components/category-picker/list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["panel"],
    classNameBindings: ["isLoading:spinner-medium:", "categoriesAreEmpty:empty:"],
    categoriesAreEmpty: Ember.computed.empty("categories"),
    isLoading: Ember.computed.equal("categories", "loading"),

    actions: {
      fetch: function fetch(category) {
        this.categories.forEach(function (category) {
          category.set("isHighlighted", false);
        });
        category.set("isHighlighted", true);
        return this.fetch(category);
      }
    }
  });
});