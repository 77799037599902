define("copilot/components/diff-viewer/component", ["exports", "copilot/components/diff-viewer/template", "ic-ajax", "ember-concurrency", "ember-inflector", "diff-match-patch"], function (exports, _template, _icAjax, _emberConcurrency, _emberInflector, _diffMatchPatch) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var dmp = new _diffMatchPatch.default();
  dmp.Diff_Timeout = 5; // seconds, 0 means infinity

  exports.default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    layout: _template.default,

    didReceiveAttrs: function didReceiveAttrs() {
      var range = this.startRange;
      this.send("change", range);
    },


    range: Ember.computed("model.revision", function () {
      return {
        min: 0,
        max: this.get("model.revision")
      };
    }),

    startRange: Ember.computed("model.revision", function () {
      var end = this.get("model.revision");
      var start = end - 1;
      return [start, end];
    }),

    format: Ember.computed(function () {
      return {
        to: Math.round,
        from: Math.round
      };
    }),

    getRevisions: (0, _emberConcurrency.task)(function* (revisions) {
      var _this = this;

      var revisionReqs = revisions.map(function (rev) {
        return _this.fetchRevision.perform(rev);
      });

      return yield (0, _emberConcurrency.all)(revisionReqs);
    }).restartable(),

    fetchRevision: (0, _emberConcurrency.task)(function* (revision) {
      return yield (0, _icAjax.default)("/api/" + (0, _emberInflector.pluralize)(this.get("model.type")) + "/" + this.get("model.id") + "/revisions/" + revision);
    }).enqueue().maxConcurrency(2),

    getDiff: function getDiff(oldRev, newRev, key) {
      var diffEntries = dmp.diff_main(oldRev[key], newRev[key]);
      dmp.diff_cleanupSemantic(diffEntries);

      return diffEntries.map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            type = _ref2[0],
            value = _ref2[1];

        return {
          value: value.replace(/\n/g, "<br>").htmlSafe(),
          className: type === _diffMatchPatch.default.DIFF_DELETE ? "removed" : type === _diffMatchPatch.default.DIFF_INSERT ? "added" : null
        };
      });
    },


    actions: {
      change: function change(revisions) {
        var _this2 = this;

        var revisionFetchStartTime = new Date();
        this.getRevisions.perform(revisions).then(function (revs) {
          var diffStartTime = new Date();
          var props = ["hed", "dek", "body"];
          var oldRev = revs[0];
          var newRev = revs[1];
          var diffs = props.map(function (prop) {
            return {
              diff: _this2.getDiff(oldRev, newRev, prop),
              prop: prop
            };
          });

          _this2.analytics.track("revision viewer", "diff generated", {
            "Content ID": _this2.model.id,
            "Content Type": _this2.model.type,
            "Old Revision": oldRev.revision,
            "New Revision": newRev.revision,
            "Milliseconds Spent Fetching Revisions": diffStartTime - revisionFetchStartTime,
            "Milliseconds Spent Generating Diff": new Date() - diffStartTime,
            "Current Time Limit on the Diff Algorithm": dmp.Diff_Timeout * 1000
          });

          _this2.set("diffs", diffs);
          _this2.set("oldRev", oldRev);
          _this2.set("newRev", newRev);
        });
      }
    }
  });
});