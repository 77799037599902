define("copilot/components/image-editor/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    classNames: ["image-editor"],

    errors: null,

    aspectRatios: null,

    selected: null,

    mainCropper: null,

    restrictCropping: false,

    loading: true,

    configService: Ember.inject.service("config"),

    imageEditorService: Ember.inject.service("image-editor"),

    photoService: Ember.inject.service("photo"),

    workingPhoto: Ember.computed("restrictCropping", "selected", function () {
      var aspectRatios = this.aspectRatios || [];
      if (this.restrictCropping || this.selected == null) {
        return aspectRatios.findBy("key", "master");
      } else {
        return this.selected;
      }
    }),

    filteredAspectRatios: Ember.computed("aspectRatios.[]", "configService.config.aspectRatios.[]", function () {
      var aspectRatios = this.aspectRatios;
      var configAspectRatios = this.get("configService.config.aspectRatios");
      if (!configAspectRatios) {
        return aspectRatios;
      }
      return aspectRatios.filter(function (ratio) {
        if (Ember.isEmpty(configAspectRatios)) {
          return aspectRatios;
        } else {
          return configAspectRatios.includes(Ember.get(ratio, "key"));
        }
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("errors", []);
    },


    // Translates coordinates/width/height from one image coord system to another
    // image since the rendered size may or may not be the actual image size.
    getRelativeCropBoxPosition: function getRelativeCropBoxPosition(sourceCropBox, targetImageData, sourceImageData, offset) {
      var offsetObj = offset || {
        top: 0,
        left: 0
      };
      var pos = {
        top: sourceCropBox.top * (targetImageData.height / sourceImageData.height) + offsetObj.top,
        left: sourceCropBox.left * (targetImageData.width / sourceImageData.width) + offsetObj.left,
        width: sourceCropBox.width * (targetImageData.width / sourceImageData.width),
        height: sourceCropBox.height * (targetImageData.height / sourceImageData.height)
      };
      return pos;
    },
    getCropBoxDataForCropper: function getCropBoxDataForCropper(cropper, cropData) {
      var targetImageData = cropper.cropper("getImageData");
      var targetCanvasData = cropper.cropper("getCanvasData");
      var cropBoxData = void 0;

      cropBoxData = this.getRelativeCropBoxPosition(cropData, targetImageData, {
        width: targetImageData.naturalWidth,
        height: targetImageData.naturalHeight
      });

      // sometimes, rounding errors will make the calculated width/height larger than the
      // by tenths. If this is the case, then it is safe to choose the selected image width
      cropBoxData.width = Math.min(cropBoxData.width, targetImageData.width);
      cropBoxData.height = Math.min(cropBoxData.height, targetImageData.height);
      cropBoxData.top += targetCanvasData.top;
      cropBoxData.left += targetCanvasData.left;

      return cropBoxData;
    },
    getCropBoxDataFromCropper: function getCropBoxDataFromCropper(cropper) {
      var cropBoxData = cropper.cropper("getCropBoxData");
      var canvasData = cropper.cropper("getCanvasData");
      var imageData = cropper.cropper("getImageData");

      cropBoxData.top -= canvasData.top;
      cropBoxData.left -= canvasData.left;

      cropBoxData = this.getRelativeCropBoxPosition(cropBoxData, {
        width: imageData.naturalWidth,
        height: imageData.naturalHeight
      }, imageData);

      return cropBoxData;
    },


    copyURL: Ember.computed("workingPhoto.{override,url,modifications,width,height}", function () {
      var isOverridden = this.get("workingPhoto.override");
      var options = {
        aspectRatio: isOverridden ? this.get("workingPhoto.key") : "master",
        id: this.get("entityId"),
        filename: this.get("filename")
      };

      if (isOverridden) {
        Object.assign(options, {
          options: ["pass"]
        });
      } else {
        options = Object.assign({}, options, this.get("workingPhoto.modifications.crop"), {
          width: this.get("workingPhoto.width"),
          height: this.get("workingPhoto.height")
        });
      }
      var imageURL = this.photoService.getImageURL(options);
      var urlForOriginalContent = imageURL + "?format=original";
      return urlForOriginalContent;
    }),

    actions: {
      dragStart: function dragStart() {
        // To see how often crops are adjusted, and which ones
        this.analytics.track("image", "re-cropped", {
          ratio: this.get("workingPhoto.ratioInText")
        }, { label: "ratio" });

        this.mainCropper.get("img").cropper("enable");
      },
      dragMove: function dragMove() {
        var mainCropper = this.get("mainCropper.img");

        // every single time the crop box is dragged in the cropper, we need to get the
        // coordinates of the crop box with respect to the actual size, not the rendered size.
        // the `getCropBoxData` method takes care of calculating that.
        var cropBoxData = this.getCropBoxDataFromCropper(mainCropper);
        cropBoxData = Object.keys(cropBoxData).reduce(function (data, key) {
          var value = cropBoxData[key];
          data[key] = value < 0 ? 0 : Math.floor(value);
          return data;
        }, {});

        cropBoxData = {
          x: cropBoxData.left,
          y: cropBoxData.top,
          height: cropBoxData.height,
          width: cropBoxData.width
        };

        this.modified({
          key: this.selected.key,
          modification: cropBoxData,
          type: "crop"
        });
      },
      select: function select(aspectRatio) {
        this.set("selected", this.aspectRatios.findBy("key", aspectRatio));
        var mainCropper = this.get("mainCropper.img");
        var cropBoxData = void 0;

        mainCropper.cropper("enable");

        var isOverridden = this.selected.override;
        var options = {
          aspectRatio: isOverridden ? aspectRatio : "master",
          id: this.entityId,
          filename: this.filename,
          options: ["pass"]
        };

        var resolvedUrl = this.photoService.getImageURL(options);

        resolvedUrl = isOverridden ? resolvedUrl + "?timestamp=" + new Date().getTime() : resolvedUrl;

        this.updateMainCropperSrc(resolvedUrl);

        this.updateMainCropperAspectRatio(this.selected.ratio, this.selected.override);

        if (this.selected.get("override")) {
          mainCropper.cropper("disable");
        } else {
          mainCropper.cropper("enable");
          cropBoxData = this.getCropBoxDataForCropper(mainCropper, {
            width: this.selected.modifications.crop.width,
            height: this.selected.modifications.crop.height,
            left: this.selected.modifications.crop.x,
            top: this.selected.modifications.crop.y
          });
          mainCropper.cropper("setCropBoxData", Object.keys(cropBoxData).reduce(function (data, key) {
            var value = cropBoxData[key];
            data[key] = Math.floor(value * 100) / 100;
            return data;
          }, {}));
        }
      },
      toggleImageEditor: function toggleImageEditor() {
        if (this.restrictCropping) {
          this.updateMainCropperSrc(this.masterURL);
          this.updateMainCropperAspectRatio();
        } else {
          this.send("select", this.aspectRatios.get("firstObject.key"));
        }
      },
      download: function download() {
        // Track if editors are downloading crops of the image, and which ratio
        this.analytics.track("image", "cropped-image-downloaded", {
          ratio: this.get("workingPhoto.ratioInText")
        }, { label: "ratio" });
      },


      // this method is called every time we switched src of the image since the addon gets rebuilt
      mainCropperBuilt: function mainCropperBuilt() {
        if (!this.selected) {
          this.send("select", this.filteredAspectRatios.firstObject.key);
        }

        var mainCropper = this.get("mainCropper.img");
        if (mainCropper && (this.restrictCropping || this.selected.override)) {
          mainCropper.cropper("disable");
        }

        this.set("loading", false);
      }
    },

    updateMainCropperAspectRatio: function updateMainCropperAspectRatio(aspectRatio, override) {
      var $img = this.get("mainCropper.img");
      if (aspectRatio && !override) {
        $img.cropper("setAspectRatio", aspectRatio);
      } else {
        $img.cropper("setAspectRatio", null);
        $img.cropper("disable");
      }
    },
    updateMainCropperSrc: function updateMainCropperSrc(src) {
      if (this.get("mainCropper.src") !== src) {
        this.set("loading", true);
        this.get("mainCropper.img").cropper("enable");
        this.set("mainCropper.src", src);
      }
    },


    restrictChecked: Ember.observer("restrictCropping", function () {
      this.send("toggleImageEditor");
    }),

    originalOptions: Ember.computed("aspectRatios", function () {
      return {
        zoomable: false,
        autoCropArea: 1,
        built: "action:mainCropperBuilt",
        dragstart: "action:dragStart",
        dragmove: "action:dragMove"
      };
    }),

    master: Ember.computed("aspectRatios", function () {
      return this.aspectRatios.findBy("key", "master");
    }),

    masterURL: Ember.computed("images", function () {
      return this.photoService.getImageURL({
        aspectRatio: "master",
        id: this.entityId,
        filename: this.filename,
        options: ["pass"]
      });
    }),

    // kludgy, but will fix this soon
    cropperURL: Ember.computed("images", function () {
      return this.photoService.getImageURL({
        aspectRatio: "master",
        id: this.entityId,
        filename: this.filename,
        options: ["pass"]
      });
    })
  });
});