define("copilot/components/search/search-filters/language/component", ["exports", "copilot/components/search/search-filters/base/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    type: "language",
    queryKey: "lang",
    configService: Ember.inject.service("config"),
    replace: true,

    options: Ember.computed("configService.config.language.available.[]", function () {
      var _this = this;

      return this.get("configService.config.language.available").map(function (language) {
        return {
          name: _this.intl.t("languages." + language),
          value: language
        };
      });
    })
  });
});