define("copilot/brands/vogue/routes/fashiongalleries/index/edit", ["exports", "copilot/routes/galleries/index/edit", "ember-service-methods/inject"], function (exports, _edit, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _edit.default.extend({
    controllerName: "fashiongalleries/index",

    saveService: Ember.inject.service("save"),

    createSlide: (0, _inject.default)(),

    uploadAsset: (0, _inject.default)(),

    createRunwayMomentFromAsset: function () {
      var _ref2 = _asyncToGenerator(function* (gallery, asset, _ref) {
        var addTo = _ref.addTo;

        var moment = this.store.createRecord("runwaymoment");
        Ember.set(moment, "runwayMomentType", "look");
        Ember.get(moment, "photosTout").pushObject(asset);

        var functionalTags = gallery.fashionshow.categories["functional-tags"];
        if (functionalTags) {
          var filterIsSponsored = functionalTags.filterBy("slug", "issponsored");
          var isSponsored = filterIsSponsored && filterIsSponsored.firstObject;
          if (isSponsored) {
            moment.get("isSponsored").pushObject(isSponsored);
          }
        }
        var brands = gallery.fashionshow.brand;
        var brand = brands.firstObject;
        if (brand) {
          moment.get("brand").pushObject(brand);
        }

        var cities = gallery.fashionshow.city;
        var city = cities.firstObject;
        if (city) {
          moment.get("city").pushObject(city);
        }

        var designers = gallery.fashionshow.designer;
        var designer = designers.firstObject;
        if (designer) {
          moment.get("designer").pushObject(designer);
        }

        var seasons = gallery.fashionshow.season;
        var season = seasons.firstObject;
        if (season) {
          moment.get("season").pushObject(season);
        }

        yield this.saveService.save(moment);

        return this.createSlide(gallery, moment, {
          addTo: addTo,
          type: "fashion-gallery-item"
        });
      });

      function createRunwayMomentFromAsset(_x, _x2, _x3) {
        return _ref2.apply(this, arguments);
      }

      return createRunwayMomentFromAsset;
    }(),


    actions: {
      createSlideFromAsset: function createSlideFromAsset(gallery, addTo, asset) {
        if (Ember.get(gallery, "hasRunwayMoments")) {
          return this.createRunwayMomentFromAsset(gallery, asset, { addTo: addTo });
        } else {
          return this.createSlide(gallery, asset, {
            addTo: addTo,
            type: "fashion-gallery-item"
          });
        }
      },
      createSlideFromFile: function createSlideFromFile(gallery, addTo, file) {
        var _this = this;

        return this.uploadAsset(file).then(function (asset) {
          if (Ember.get(gallery, "hasRunwayMoments")) {
            return _this.createRunwayMomentFromAsset(gallery, asset, { addTo: addTo });
          } else {
            return _this.createSlide(gallery, asset, {
              addTo: addTo,
              type: "fashion-gallery-item"
            });
          }
        });
      }
    }
  });
});