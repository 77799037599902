define("copilot/components/nested-list/list-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    isOpened: false,
    hasError: false,
    isEmpty: Ember.computed("hasChildren", "nestedList", function () {
      return Array.isArray(this.nestedList) && !this.get("nestedList.length");
    }),
    open: function open(item, event) {
      var _this = this;

      event.preventDefault();
      if (this.nestedList) {
        return this.toggleProperty("isOpened");
      }
      Ember.run(this, function () {
        this.set("isLoading", true);
      });
      Ember.RSVP.resolve(this.fetchChildren(item)).then(function (list) {
        if (Array.isArray(list)) {
          _this.set("nestedList", list);
          _this.toggleProperty("isOpened");
        } else {
          _this.set("hasError", true);
        }
      }).catch(function () {
        _this.set("hasError", true);
      }).finally(function () {
        _this.set("isLoading", false);
      });
    },
    close: function close() {
      if (this.nestedList || this.hasError) {
        this.set("hasError", false);
        this.toggleProperty("isOpened");
      }
    },

    actions: {
      toggleOpen: function toggleOpen(item, event) {
        if (this.isOpened) {
          return this.close();
        }

        return this.open(item, event);
      }
    }
  });
});