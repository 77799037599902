define("copilot/forms/brands/tny/bundle/list", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/tny/bundle/list"], function (exports, _list) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _list.default;
    }
  });
});