define("copilot/services/app-updater", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    logger: Ember.inject.service(),

    intervalId: null,
    seconds: 900,
    appUpdated: false,
    currentVersion: null,

    fetchInitialVersion: function fetchInitialVersion() {
      var _this = this;

      (0, _icAjax.default)("/version", {
        cache: false
      }).then(function (val) {
        _this.set("currentVersion", val);
      }).catch(function (error) {
        _this.logger.error("App-updater polling error", error);
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      Ember.run(function () {
        return _this2.fetchInitialVersion();
      });
      this.set("intervalId", this.interval());
    },
    interval: function interval() {
      var _this3 = this;

      return window.setInterval(function () {
        (0, _icAjax.default)("/version", {
          cache: false
        }).then(function (val) {
          var currentVersion = _this3.currentVersion;
          if (currentVersion && currentVersion !== val) {
            _this3.set("appUpdated", true);
          }
        }).catch(function (error) {
          _this3.logger.error("App-updater polling error", error);
        });
      }, this.seconds * 1000);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      window.clearInterval(this.intervalId);
    }
  });
});