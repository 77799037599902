define("copilot/validations/brands/cnt/airline", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var validationKeyLabels = {
    "metadata.publishData.uri": "Publish URL"
  };

  var mapPaths = _copilotCore.mapErrorPaths.bind(null, validationKeyLabels);

  var airlineValidationBuilder = object({
    description: (0, _copilotValidators.optional)((0, _copilotValidators.markdownLinks)()),
    region: (0, _copilotValidators.required)().on("publish"),
    url: (0, _copilotValidators.array)((0, _copilotValidators.optional)((0, _copilotValidators.url)())),
    email: (0, _copilotValidators.array)((0, _copilotValidators.optional)((0, _copilotValidators.email)())),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 }).on("publish"))
  }).andAlso((0, _copilotValidators.inlineAllPresent)()).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish"))).catch(mapPaths);

  exports.default = airlineValidationBuilder;
});