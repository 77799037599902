define("copilot/components/crop-preview/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CROP_PREVIEW = "crop-preview";

  exports.default = Ember.Component.extend({
    configService: Ember.inject.service("config"),

    imageEditorService: Ember.inject.service("image-editor"),

    photoService: Ember.inject.service("photo"),

    img: null,

    entityId: null,

    filename: null,

    isSelected: Ember.computed("selected", "model.key", function () {
      return this.get("model.key") === this.get("selected");
    }),

    loading: true,

    masterURL: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.get("imageEditorService.previews").pushObject(this);
    },


    overridden: Ember.computed("model.override", function () {
      return this.get("model.override");
    }),

    aspectRatioKey: Ember.computed("model.key", function () {
      return this.get("model.key").replace(":", "_");
    }),

    src: Ember.computed("model.{override,url}", function () {
      var isOverridden = this.get("model.override");
      var aspectRatio = isOverridden ? this.get("model.key") : "master";
      var url = this.photoService.getImageURL({
        aspectRatio: aspectRatio,
        id: this.entityId,
        filename: this.filename,
        options: ["pass"]
      });

      // need to add a timestamp when the aspect is replaced as the vulcan URL does not change, but the S3/Manta does
      url = isOverridden ? url + "?timestamp=" + new Date().getTime() : url;
      return url;
    }),

    actions: {
      replaceAspectRatio: function replaceAspectRatio(file) {
        var _this = this;

        this.set("loading", true);
        this.set("errors", []);
        return this.onreplace(file).then(function () {
          _this.$("." + CROP_PREVIEW).css({
            width: "",
            height: ""
          });
          _this.onSelect(_this.model.key);
        }, function (errors) {
          _this.errors.pushObject({
            message: errors.join(".")
          });
        }).finally(function () {
          _this.setLoadingFalse();
        });
      }
    },

    updatePreview: Ember.observer("model.modifications.crop", function () {
      var $imgWrapper = this.$("." + CROP_PREVIEW);
      if ($imgWrapper) {
        var $img = $imgWrapper.find("img");
        if (!this.get("model.override")) {
          $img.css({
            width: $img.prop("naturalWidth") / this.get("model.modifications.crop.width") * $imgWrapper.outerWidth(),
            height: $img.prop("naturalHeight") / this.get("model.modifications.crop.height") * $imgWrapper.outerHeight()
          });
          $img.css({
            marginLeft: -1 * this.get("model.modifications.crop.x") / ($img.prop("naturalWidth") / $img.outerWidth()),
            marginTop: -1 * this.get("model.modifications.crop.y") / ($img.prop("naturalHeight") / $img.outerHeight())
          });
        } else {
          $img.css({
            width: "",
            height: "",
            margin: "0 auto"
          });
        }
      }
    }),

    updatePreviewBoundingBox: Ember.observer("model.override", "model.url", function () {
      var $imgWrapper = this.$("." + CROP_PREVIEW);
      if ($imgWrapper) {
        var dims = this.imageEditorService.getDefaultCropDimensions({
          width: $imgWrapper.outerWidth(),
          height: $imgWrapper.outerHeight()
        }, this.get("model.key"));

        $imgWrapper.css({
          width: this.get("model.override") ? "" : dims.width,
          height: this.get("model.override") ? "" : dims.height
        });
      }
    }),

    setLoadingFalse: function setLoadingFalse() {
      if (!this.isDestroyed) {
        this.set("loading", false);
      }
    },
    didInsertElement: function didInsertElement() {
      var updatePreview = function updatePreview() {
        this.updatePreview();
        Ember.run.debounce(this, this.setLoadingFalse, 500);
      };
      var $img = this.$(".crop-preview > img");
      this._super.apply(this, arguments);

      this.updatePreviewBoundingBox();
      this.set("loading", true);

      if ($img[0] && $img[0].complete) {
        updatePreview.call(this);
      } else {
        $img.load(Ember.run.bind(this, updatePreview));
      }
    },
    willDestroy: function willDestroy() {
      this.get("imageEditorService.previews").removeObject(this);
    }
  });
});