define("copilot/schema-helpers/autogen-provisional-title", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["embedded.name", "embedded.title", "embedded.hed", "embedded.promoHed"]),

    // Runs in context of model
    compute: function compute(model) {
      var embedded = model.embedded;
      return embedded.name || embedded.title || embedded.promoHed || embedded.hed || "";
    }
  });
});