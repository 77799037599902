define("copilot/validations/brands/bon/venue", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var venueValidators = object({
    hed: (0, _copilotValidators.required)(),
    body: (0, _copilotValidators.required)().on("publish"),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 }).on("publish")),
    channel: (0, _copilotValidators.channel)().on("publish"),
    contributorsAuthor: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    email: (0, _copilotValidators.array)((0, _copilotValidators.email)()),
    geo: (0, _copilotValidators.geo)(),
    seoTitle: (0, _copilotValidators.required)().on("publish"),
    socialTitle: (0, _copilotValidators.required)().on("publish"),
    socialDescription: (0, _copilotValidators.required)().on("publish"),
    seoDescription: (0, _copilotValidators.required)().on("publish"),
    url: (0, _copilotValidators.array)((0, _copilotValidators.url)("absolute", "relative"))
  }).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = (0, _crossCheckDsl.default)(venueValidators);
});