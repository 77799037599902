define("copilot/helpers/add", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    return params.reduce(function (sum, x) {
      return sum + x;
    });
  });
});