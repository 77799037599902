define("copilot/lib/xml-import", ["exports", "@atjson/offset-annotations", "@atjson/source-prism", "@atjson/renderer-commonmark", "ember-concurrency"], function (exports, _offsetAnnotations, _sourcePrism, _rendererCommonmark, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    toMarkdown: (0, _emberConcurrency.task)(function* () {
      var rawText = yield this.file.readAsText();

      var prismDoc = _sourcePrism.default.fromRaw(rawText).convertTo(_offsetAnnotations.default);
      prismDoc.where({ type: "-atjson-unknown" }).remove();
      return _rendererCommonmark.default.render(prismDoc).trim();
    })
  });
});