define("copilot/brands/vogue/components/action-bar-slot/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    init: function init() {
      this._super.apply(this, arguments);
      this.set("actionBar", document.getElementById("action-bar"));
    }
  });
});