define("copilot/brands/cnt/models/venue", ["exports", "copilot/models/base", "copilot/models/attr", "ember-model"], function (exports, _base, _attr, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var address = _attr.default.address,
      str = _attr.default.str,
      array = _attr.default.array,
      geo = _attr.default.geo;
  exports.default = _base.default.extend({
    name: (0, _emberModel.attr)(str),
    address: (0, _emberModel.attr)(address),
    body: (0, _emberModel.attr)(str),
    contributorsAuthor: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    contributorsPhotographer: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    description: (0, _emberModel.attr)(str),
    email: (0, _emberModel.attr)(array),
    foursquarePlacesId: (0, _emberModel.attr)(str),
    geo: (0, _emberModel.attr)(geo),
    geoTags: (0, _emberModel.attr)(array),
    paymentsAccepted: (0, _emberModel.attr)(array),
    phone: (0, _emberModel.attr)(array),
    price: (0, _emberModel.attr)(Number),
    promoDek: (0, _emberModel.attr)(str),
    promoHed: (0, _emberModel.attr)(str),
    region: (0, _emberModel.attr)(str),
    shortDescription: (0, _emberModel.attr)(),
    tags: (0, _emberModel.attr)(array),
    url: (0, _emberModel.attr)(array),
    photos: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    related: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    seoTitle: (0, _emberModel.attr)(),
    seoDescription: (0, _emberModel.attr)(),
    socialName: (0, _emberModel.attr)(),
    socialDescription: (0, _emberModel.attr)(),
    photosSocial: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("name"),
    embeddableFields: ["body", "description"],

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      geo: "@autogenGeo",
      seoTitle: "name",
      seoDescription: "description",
      socialName: "name",
      socialDescription: "description"
    },
    offers: (0, _emberModel.hasMany)("venue-offer", {
      embedded: "always"
    })
  });
});