define("copilot/mixins/select-assets", ["exports", "ember-inflector"], function (exports, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  /** Represents settings that the cnePlayer defaults to if query param isnt passed */
  var cnePlayerDefaults = {
    autoplay: true,
    muted: false
  };

  function constructCneVideoEmbed(assetEmbed) {
    var embedUrl = Ember.get(assetEmbed, "asset.scriptEmbedUrl");
    var baseUrl = embedUrl.match(/\/\/player\.cnevids\.com\/(.+)/)[1];
    var autoplay = Ember.get(assetEmbed, "autoplay");
    var muted = Ember.get(assetEmbed, "muted");
    var params = { autoplay: autoplay, muted: muted };

    var queryParamsString = Object.keys(params).reduce(function (accum, key) {
      if (params[key] !== cnePlayerDefaults[key]) {
        var paramVal = Number(params[key]);
        accum.push(key + "=" + paramVal);
      }
      return accum;
    }, []).join("&");

    var urlParts = [baseUrl];
    if (Ember.isPresent(queryParamsString)) {
      urlParts.push(queryParamsString);
    }
    var url = urlParts.join("?");

    var caption = Ember.get(assetEmbed, "caption") ? "\n" + Ember.get(assetEmbed, "caption") + "\n" : "";

    return "[#cneembed: " + url + "]|||" + caption + "|||";
  }

  function constructGiphyEmbed(embedItem) {
    var url = Ember.get(embedItem, "embed_url").replace(/https?:/, "");
    var width = Ember.get(embedItem, "images.original.width");
    var height = Ember.get(embedItem, "images.original.height");
    return "[#iframe: " + url + "](" + width + "x" + height + ")||||||";
  }

  // TODO: We already do _all_ of this in atjson;
  // this code is duplicate, and possibly has bugs.
  // We should remove this code ASAP and use our annotation
  // definitions for this.
  // - @tim-evans
  function constructEmbed(embedItem) {
    if (Ember.get(embedItem, "resultType") === "giphy") {
      return constructGiphyEmbed(embedItem);
    }
    var props = ["type", "caption", "url", "width", "height"];

    var _Ember$getProperties = Ember.getProperties(embedItem, props),
        type = _Ember$getProperties.type,
        caption = _Ember$getProperties.caption,
        url = _Ember$getProperties.url,
        width = _Ember$getProperties.width,
        height = _Ember$getProperties.height;

    type = type === "video-embed" ? "video" : type;
    type = type === "social" ? Ember.get(embedItem, "service") : type;
    type = type === "audio-embed" ? "iframe" : type;

    var attrs = [];
    if (type === "ceros") {
      if (embedItem.mobileWidth) {
        attrs.push("mobileWidth:" + embedItem.mobileWidth);
      }
      if (embedItem.mobileHeight) {
        attrs.push("mobileHeight:" + embedItem.mobileHeight);
      }
    }

    var embedStr = "[#" + type + ": " + url + (attrs.length ? " " + attrs.join(" ") : "") + "]";

    if (Ember.isPresent(width)) {
      embedStr += "(" + width;
      if (Ember.isPresent(height)) {
        embedStr += "x" + height;
      }
      embedStr += ")";
    }
    if (Ember.isPresent(caption)) {
      embedStr += "|||\n" + caption + "\n|||";
    }
    return embedStr;
  }

  exports.default = Ember.Mixin.create({
    analytics: Ember.inject.service(),
    features: Ember.inject.service(),
    isSelectingAssets: false,
    isContextualEditing: false,
    importExternalAsset: Ember.inject.service(),
    isEditingInCK: Ember.computed.equal("currentEditor", "ckeditor"),
    isWritingInRichText: Ember.computed.readOnly("isEditingInCK"),
    isShowingModal: Ember.computed.or("isSelectingAssets", "isContextualEditing"),
    modalTitle: Ember.computed("isSelectingAssets", function () {
      return this.isSelectingAssets ? "Find Asset" : "Edit Contextual Data";
    }),

    assetCallback: null,
    selectedAsset: null,
    assetTypeList: null,

    _constructAssetEmbed: function _constructAssetEmbed(assetEmbed) {
      var id = Ember.get(assetEmbed, "asset.id");
      var caption = Ember.get(assetEmbed, "caption");
      var type = Ember.get(assetEmbed, "type");
      if (type === "cnevideo") {
        return constructCneVideoEmbed.call(this, assetEmbed);
      }

      var apiType = type === "photo" ? "image" : type;

      var embedStr = "[#" + apiType + ": /" + (0, _emberInflector.pluralize)(type) + "/" + id + "]|||";
      if (caption) {
        embedStr += "\n" + caption + "\n";
      }
      embedStr += "|||";
      return embedStr;
    },


    actions: {
      selectAsset: function () {
        var _ref2 = _asyncToGenerator(function* (_ref, resultType) {
          var _ref3 = _slicedToArray(_ref, 1),
              asset = _ref3[0];

          if (Ember.get(asset, "resultType") === "giphy") {
            this.analytics.track("External Asset selected", "select-asset", {
              externalAsset: Ember.get(asset, "resultType")
            });
            this.send("submitEmbed", asset, resultType);
            this.set("isSelectingAssets", false);
          } else {
            if (asset.type === "getty") {
              this.analytics.track("External Asset selected", "select-asset", {
                externalAsset: asset.type
              });
              var externalAsset = yield this.importExternalAsset.importAsset(asset);
              asset = externalAsset;
            }
            this.set("selectedAsset", asset);
            this.setProperties({
              isUploadingToBody: false,
              isSelectingAssets: false,
              isContextualEditing: true
            });
          }
        });

        function selectAsset(_x, _x2) {
          return _ref2.apply(this, arguments);
        }

        return selectAsset;
      }(),
      selectAssets: function () {
        var _ref4 = _asyncToGenerator(function* (assets, resultType) {
          var _this = this;

          var areAllGiphyEmbeds = assets.isEvery("resultType", "giphy");
          var promises = assets.map(function (asset) {
            return asset.type === "getty" ? _this.importExternalAsset.importAsset(asset) : asset;
          });
          var selectedAssets = yield Promise.all(promises);
          if (this.isEditingInCK) {
            this.send("submitEmbed", selectedAssets);
            this.send("closeModal");
          } else if (areAllGiphyEmbeds) {
            assets.forEach(function (asset) {
              _this.send("submitEmbed", asset, resultType);
            });
            this.set("isSelectingAssets", false);
          } else {
            this.set("selectedAssets", selectedAssets);
            this.setProperties({
              isUploadingToBody: false,
              isSelectingAssets: false,
              isContextualEditing: true
            });
          }
        });

        function selectAssets(_x3, _x4) {
          return _ref4.apply(this, arguments);
        }

        return selectAssets;
      }(),
      closeModal: function closeModal() {
        this.setProperties({
          selectedAssets: [],
          isUploadingToBody: false,
          isSelectingAssets: false,
          isContextualEditing: false,
          isEmbedding: false,
          hyperlinkData: null,
          isAddingCallout: false,
          assetTypeList: null
        });
      },
      submitContextualEdits: function submitContextualEdits(assetEmbed) {
        var assetCallback = this.assetCallback;
        if (this.isWritingInRichText) {
          assetCallback(assetEmbed);
        } else {
          var assetEmbedMarkdown = this._constructAssetEmbed(assetEmbed);
          assetCallback(assetEmbedMarkdown);
        }
        this.setProperties({
          isUploadingToBody: false,
          isContextualEditing: false
        });
      },
      submitOverrides: function submitOverrides(assets) {
        var _this2 = this;

        var assetCallback = this.assetCallback;
        var markdown = assets.map(function (asset) {
          return _this2._constructAssetEmbed(asset);
        }).join("\n\n");
        assetCallback(markdown);

        this.setProperties({
          selectedAssets: [],
          isUploadingToBody: false,
          isContextualEditing: false
        });
      },
      submitCallout: function submitCallout(value) {
        this.set("isAddingCallout", false);
        var calloutCallback = this.calloutCallback;
        calloutCallback(value);
      },
      submitEmbed: function submitEmbed(embedItem) {
        var callback = this.embedCallback || this.assetCallback;

        if (this.isWritingInRichText) {
          callback(embedItem);
        } else {
          var embedMarkdown = constructEmbed(embedItem);
          callback(embedMarkdown);
        }
        this.set("isEmbedding", false);
      }
    }
  });
});