define("copilot/brands/p4k/models/embed-editor-content", ["exports", "copilot/models/embed-editor-content"], function (exports, _embedEditorContent) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _embedEditorContent.default.extend({
    spotifyUrlFixer: Ember.observer("url", function (sender, key) {
      var rawUrl = this.get(key);
      var urlParts = rawUrl.split("?");

      // Let's make this hyperfocused for this specific case for now so as to
      // not potentially cause issues with other embeds.
      if (rawUrl.indexOf("spotify.com") === -1 || urlParts.length !== 2) {
        return;
      }

      // Replace the colons with their URL-encoded counterpart
      urlParts[1] = urlParts[1].replace(/:/g, "%3A");
      this.set(key, urlParts.join("?"));
    })
  });
});