define("copilot/components/syndication-bar/component", ["exports", "lodash-es"], function (exports, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  exports.default = Ember.Component.extend({
    tagName: "",

    configService: Ember.inject.service("config"),
    userService: Ember.inject.service("user"),
    eventBus: Ember.inject.service("event-bus"),
    environment: Ember.inject.service("environment"),

    inProgress: reads("eventBus.inAsyncProgress"),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("actionBar", document.getElementById("action-bar"));
    },


    targetBrand: null,

    brands: Ember.computed("configService.config.companyId", function () {
      var _this = this;

      return this.configService.getAllBrandCodes().filter(function (code) {
        var brand = _this.configService.findBrand.byCopilotCode(code);
        return brand && brand.companyId === _this.configService.config.companyId;
      });
    }),

    syndicatedBrandNames: Ember.computed("model.syndication.targets.[]", function () {
      var _this2 = this;

      var targets = this.get("model.syndication.targets") || [];
      return targets.map(function (target) {
        return _this2.configService.getBrandName(target.brand);
      });
    }),

    brandOptions: Ember.computed("brands", "activeBrand", "syndicatedBrandNames.[]", function () {
      var _this3 = this;

      var options = _lodashEs.default.map(_lodashEs.default.reject(this.brands, function (brand) {
        var brandName = _this3.configService.getBrandName(brand);
        var isActiveBrand = brand === _this3.activeBrand;
        var isSyndicatedToBrand = _this3.syndicatedBrandNames.includes(brandName);
        var reject = isActiveBrand || isSyndicatedToBrand;
        return reject;
      }), function (brand) {
        return {
          label: _this3.configService.getBrandName(brand),
          value: brand
        };
      }).reject(function (brand) {
        return Ember.isEmpty(brand.label);
      });
      return options.sortBy("label");
    }),

    actions: {
      click: function click() {
        var targetBrand = this.targetBrand;
        this.confirm(targetBrand);
      }
    }
  });
});