define("copilot/mixins/saveable", ["exports", "lodash-es", "ember-inflector"], function (exports, _lodashEs, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    eventBus: Ember.inject.service(),
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    userService: Ember.inject.service("user"),

    pluralizedType: Ember.computed("model.type", function () {
      var type = (0, _emberInflector.pluralize)(this.get("model.type"));

      if (this.get("model.isNative")) {
        type = 'native';
      }

      return type;
    }),

    canSaveType: function canSaveType(pluralizedType) {
      return !!this.get("userService.user.permissions." + pluralizedType + ".actions.update");
    },


    canSave: Ember.computed("pluralizedType", function () {
      return this.canSaveType(this.pluralizedType);
    }),

    triggerSuccess: function triggerSuccess(data) {
      this.eventBus.trigger("success", data);
    },
    triggerProgress: function triggerProgress(data) {
      this.eventBus.trigger("progress", data);
    },
    triggerError: function triggerError(data) {
      this.eventBus.trigger("error", data);
    },
    triggerValidationError: function triggerValidationError(data) {
      this.eventBus.trigger("validationError", data);
      this.scrollToValidationErrors();
    },
    scrollToValidationErrors: function scrollToValidationErrors() {
      Ember.run.later(function () {
        Ember.$("main .master-detail .master-detail .detail").animate({ scrollTop: "0px" }, 300);
      });
    },
    saveSuccess: function saveSuccess(controller) {
      return function (status) {
        var isNew = void 0;

        if (_lodashEs.default.isArray(status)) {
          isNew = status.every(function (s) {
            return s === "new";
          });
        } else {
          isNew = status === "new";
        }

        if (!controller.isChildController && isNew) {
          var model = controller.model;
          var type = model.get("type");
          var activeBrand = this.activeBrand;
          var route = (0, _emberInflector.pluralize)(type);
          controller.replaceRoute(route, activeBrand, model);
          Ember.run.schedule("afterRender", controller, controller.triggerSuccess);
        } else {
          controller.triggerSuccess();
        }
      }.bind(this);
    },
    saveFailure: function saveFailure(response) {
      var status = response.status || response.statusCode;
      var statusText = response.statusText || "Server Error";

      if (status && status.toString().match(/401/)) {
        throw response;
      }

      if (response.responseJSON || response.jqXHR && response.jqXHR.responseJSON) {
        response = response.responseJSON || response.jqXHR.responseJSON;
      }

      var message = response.message || "An internal server error occurred";
      var errorType = response.errorType || "server-error";

      this.get("model.validationErrors").add("", errorType, {
        error: statusText,
        message: message,
        status: status
      });

      this.triggerError();
    }
  });
});