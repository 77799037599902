define("copilot/controllers/galleries/index", ["exports", "copilot/controllers/base", "ic-ajax", "lodash-es", "copilot/mixins/seo"], function (exports, _base, _icAjax, _lodashEs, _seo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend(_seo.default, {
    modelsToSave: ["model"],
    mediaToSave: [],
    batchCreditValue: "",
    itemClassName: "gallery-item",
    itemModelName: "items",
    carouselShowing: false,
    currentSelection: null,
    isAppend: true,
    isBatching: false,
    selectedControllers: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.modelsToSave = ["model"];
      this.mediaToSave = [];
    },


    analytics: Ember.inject.service(),
    galleryModel: Ember.computed.alias("model"),
    hasItems: Ember.computed.notEmpty("model.items"),

    activeIds: Ember.computed("activeItems.[]", function () {
      return this.activeItems.mapBy("id");
    }),

    activeItems: Ember.computed("model.items.@each.isActive", function () {
      return this.get("model.items").filterBy("isActive", true);
    }),

    allIds: Ember.computed("model.items.[]", function () {
      return this.get("model.items").mapBy("id");
    }),

    areAllItemsMedia: Ember.computed("model.items.[]", function () {
      return this.get("model.items").every(function (item) {
        var modelName = item.get("embedded.meta.modelName");
        return ["photo", "clip", "cnevideo", "runwaymoment"].indexOf(modelName) !== -1;
      });
    }),

    disableBatching: Ember.computed("isBatching", "model.isNew", "hasItems", "areAllItemsMedia", function () {
      return this.isBatching || this.get("model.isNew") || !this.hasItems || !this.areAllItemsMedia;
    }),

    displayTitle: Ember.computed("model.title", function () {
      var title = this.get("model.title");
      return title && title.split("-")[0];
    }),

    showBatchEdit: Ember.computed("selectedControllers.[]", function () {
      return this.get("selectedControllers.length");
    }),

    sortedByFilename: Ember.computed("model.items.[]", function () {
      var currentOrder = this.get("model.items").mapBy("filename");
      var sortedOrder = this.get("model.items").sortBy("filename").mapBy("filename");
      return _lodashEs.default.isEqual(currentOrder, sortedOrder);
    }),

    photos: Ember.computed("model.{isRunwayMoment,items.@each.embedded}", function () {
      if (!this.get("model.items")) {
        return [];
      }

      if (this.get("model.hasRunwayMoments")) {
        return this.get("model.items").mapBy("embedded.photosTout.firstObject");
      } else {
        return this.get("model.items").mapBy("embedded");
      }
    }),

    isEnteringCredit: false,

    patchPhotos: function patchPhotos(photos, patch) {
      var _this = this;

      if (Ember.get(photos, "length") === 0) {
        return;
      }

      this.triggerProgress();
      this.set("isBatching", true);

      var revisionAuthor = this.get("userService.fullName");
      var json = photos.map(function (photo) {
        return Ember.assign({
          id: Ember.get(photo, "id"),
          meta: Ember.get(photo, "meta"),
          _links: Ember.get(photo, "_links"),
          revisionAuthor: revisionAuthor
        }, patch);
      });

      return (0, _icAjax.default)({
        url: "/api/batch",
        type: "PUT",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(json)
      }).then(function () {
        _this.triggerSuccess();
      }, function () {
        _this.triggerError();
      }).finally(function () {
        _this.set("isBatching", false);
      });
    },
    hideCarousel: function hideCarousel() {
      this.set("carouselShowing", false);
    },
    showCarousel: function showCarousel() {
      this.set("carouselShowing", true);
    },


    actions: {
      applyCredit: function applyCredit(photos, credit) {
        this.analytics.track("galleries", "batch-update-credit", {
          photoCount: photos.length
        });

        return this.patchPhotos(photos, credit);
      },
      remove: function remove(obj) {
        this.get("model.items").removeObject(obj);
      },
      sortByFilename: function sortByFilename() {
        this.analytics.track("galleries", "sort", {
          comparator: "filename"
        }, {
          label: "comparator"
        });
        var items = this.get("model.items").sortBy("filename").toArray();
        this.get("model.items").setObjects(items);
      },
      sortItems: function sortItems(oldIndex, newIndex) {
        var items = this.get("model.items");
        var item = items.objectAt(oldIndex);
        items.removeAt(oldIndex);
        items.insertAt(newIndex, item);

        // To see if slides are being drag and drop reordered and how often
        this.analytics.track("gallery", "reordered");
      },
      toggleActive: function toggleActive(model) {
        model.toggleProperty("isActive");
      }
    }
  });
});