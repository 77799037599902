define("copilot/brands/vogue/models/slim-model", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function _processResponse(responseJSON, attribute) {
    this.set("content", responseJSON);
    this.inflight.removeObject(attribute);
    var boolVal = this.get(attribute);
    this.trigger("success", attribute, boolVal);
  }

  function _rePublish(attribute, rePublish, responseJSON) {
    if (rePublish) {
      var url = this.baseURI + this.get("content.id") + "/publish";
      var data = {};
      data.uri = responseJSON._embedded.publishHistory.uri;
      data.pubDate = responseJSON._embedded.publishHistory.pubDate;
      return Ember.$.ajax(url, {
        url: url,
        data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        type: "POST",
        tag: "vogue.models.slim-model._rePublish"
      }).then(function (response) {
        _processResponse.bind(this)(response, attribute);
      }.bind(this));
    } else {
      return new Ember.RSVP.Promise(function (resolve) {
        _processResponse.bind(this)(responseJSON, attribute);
        resolve();
      }.bind(this));
    }
  }

  exports.default = Ember.ObjectProxy.extend(Ember.Evented, {
    content: null,

    toggleAndSave: function toggleAndSave(attribute, rePublish) {
      var currentValue = this.get("content." + attribute);
      this.set("content." + attribute, !currentValue);
      this.inflight.pushObject(attribute);
      var requiredAttrs = this.requiredAttrs;
      var attrs = requiredAttrs.slice(0);
      attrs.push(attribute);
      var data = this.getProperties(attrs);
      return this.save(data).then(this.afterSave.bind(this)).then(_rePublish.bind(this, attribute, rePublish));
    },
    save: function save(data) {
      var url = this.baseURI + this.get("content.id");
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax(url, {
          url: url,
          data: JSON.stringify(data),
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          type: "PUT",
          tag: "vogue.models.slim-model.save"
        }).then(function (responseJSON) {
          resolve(responseJSON);
        });
      });
    },


    // This hook may be overriden in any sub-classes in order to add other
    // behaviour upon save. Must return a promise that resolves with the
    // responseJSON of save method.
    afterSave: function afterSave(responseJSON) {
      var promise = new Ember.RSVP.Promise(function (resolve) {
        resolve(responseJSON);
      }.bind(this));
      return promise;
    }
  });
});