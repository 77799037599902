define("copilot/forms/brands/epi/package", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/epi/package"], function (exports, _package) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _package.default;
    }
  });
});