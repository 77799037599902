define("copilot/components/create-dropdown/component", ["exports", "ember-inflector", "ember-service-methods"], function (exports, _emberInflector, _emberServiceMethods) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var CONTENT_TYPE_TO_GROUP_MAP = {
    bundle: "containers",
    curatedlist: "containers",
    package: "containers",
    tagpage: "containers",
    photo: "assets",
    clip: "assets"
  };

  var GROUPS = ["assets", "content", "containers"];

  var CATCH_ALL_GROUP = "content";

  exports.default = Ember.Component.extend({
    search: (0, _emberServiceMethods.inject)(),
    router: Ember.inject.service(),
    brand: Ember.inject.service(),
    features: Ember.inject.service(),
    intl: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    v2Url: Ember.computed.reads("configService.config.copilotV2Url"),
    isOpen: false,

    showCreateArticleModal: false,
    activeBrand: Ember.computed.alias("brand.activeBrand"),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("groups", GROUPS);
    },


    hasPresets: Ember.computed(function () {
      if (!this.features.isEnabled("presets")) {
        return false;
      }

      return this.search({
        category: "copilot/preset",
        limit: 1,
        nottypes: "category",
        notcategory: ""
      }).then(function (presetsList) {
        return !!presetsList.hits.length;
      });
    }),

    contentTypeMap: Ember.computed("configService.config.contentTypes.[]", "brandConfig.hiddenContentTypes.[]", function () {
      var _this = this;

      var contentTypes = this.get("configService.config.contentTypes");
      var hiddenContentTypes = this.get("brandConfig.hiddenContentTypes");
      var router = this.router._router;
      return contentTypes.map(function (contentType) {
        var value = contentType.value;
        var pluralizedResource = (0, _emberInflector.pluralize)(value);
        var translatedLabel = _this.intl.t("contentTypes." + value, {
          count: 1
        });
        var createRoute = pluralizedResource + ".create";
        createRoute = router.hasRoute(createRoute) ? createRoute : "contentEdit.create";
        return {
          createRoute: createRoute,
          linkClass: "create-" + value,
          label: (translatedLabel || contentType.label).toString(),
          pluralizedResource: pluralizedResource,
          singularResource: value
        };
      }).filter(function (contentType) {
        var notHidden = void 0;
        if (hiddenContentTypes) {
          notHidden = hiddenContentTypes.indexOf(contentType.singularResource) === -1;
        } else {
          notHidden = true;
        }
        return notHidden && router.hasRoute(contentType.createRoute);
      }).sortBy("label");
    }),

    groupedContentTypes: Ember.computed("contentTypeMap.[]", function () {
      var contentTypes = this.contentTypeMap.reduce(function (groupedContentTypes, contentType) {
        var contentTypeGroup = CONTENT_TYPE_TO_GROUP_MAP[contentType.singularResource];
        if (contentTypeGroup) {
          groupedContentTypes[contentTypeGroup] ? groupedContentTypes[contentTypeGroup].push(contentType) : groupedContentTypes[contentTypeGroup] = [contentType];
        } else {
          groupedContentTypes[CATCH_ALL_GROUP].push(contentType);
        }
        return groupedContentTypes;
      }, _defineProperty({}, CATCH_ALL_GROUP, []));

      //if commerceproduct already exists in the content types then update the createRoute and return the contentTypes
      for (var i = 0; i < contentTypes.content.length; i++) {
        if (contentTypes.content[i].singularResource === "commerceproduct") {
          contentTypes.content[i].createRoute = this.get("configService.config.commerceProductApi");
          return contentTypes;
        }
      }

      //if not then check if commerceproduct was originally there in the contentTypeMap
      var commerceProductContentType = this.contentTypeMap.filter(function (contentType) {
        return contentType.singularResource === "commerceproduct";
      });

      //if yes then get that in a new varible and update the createRoute else create new content type object and add the same in the content types
      if (commerceProductContentType.length) {
        commerceProductContentType[0].createRoute = this.get("configService.config.commerceProductApi");
        contentTypes.content.push(commerceProductContentType);
      } else {
        var commerceContentType = {
          createRoute: "contentEdit.create",
          label: "Commerce Product",
          linkClass: "create-commerceproduct",
          pluralizedResource: "commerceproducts",
          singularResource: "commerceproduct"
        };
        commerceContentType.createRoute = this.get("configService.config.commerceProductApi");
        contentTypes.content.push(commerceContentType);
      }
      contentTypes.content.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return contentTypes;
    }),

    willDestroyElement: function willDestroyElement() {
      if (this._clickOutside) {
        document.removeEventListener("click", this._clickOutside);
      }
    },
    open: function open() {
      var _this2 = this;

      this._clickOutside = function (evt) {
        if (_this2.element === evt.target || _this2.element.contains(evt.target)) {
          return;
        }
        _this2.close();
      };
      document.addEventListener("click", this._clickOutside);
      this.set("isOpen", true);
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    },


    actions: {
      toggleCreateArticleModal: function toggleCreateArticleModal() {
        this.toggleProperty("showCreateArticleModal");
        this.close();
      }
    }
  });
});