define("copilot/components/category-group/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["category-group"],
    actions: {
      updateSortOrder: function updateSortOrder(oldIndex, newIndex) {
        var items = this.model;
        var item = items.objectAt(oldIndex);
        var root = item.rootSlug;
        items.removeAt(oldIndex);
        items.insertAt(newIndex, item);
        this.selections[root] = items;
        this.onupdate(this.selections);
      }
    }
  });
});