define("copilot/routes/dashboard", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _authenticatedRoute.default.extend({
    v2Redirect: Ember.inject.service(),

    beforeModel: function () {
      var _ref = _asyncToGenerator(function* (transition) {
        var shouldContinue = yield this.v2Redirect.maybeRedirectTransition(transition);
        if (!shouldContinue) {
          transition.abort();
        }
      });

      function beforeModel(_x) {
        return _ref.apply(this, arguments);
      }

      return beforeModel;
    }(),
    model: function model() {
      return new Ember.Object({
        photo: this.store.modelFactoryFor("photo").create()
      });
    }
  });
});