define("copilot/validations/brands/cnt/ship", ["exports", "@condenast/cross-check-dsl", "copilot/validations/brands/cnt/venue", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _venue, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var shipValidators = (0, _crossCheckDsl.extend)(_venue.default).andAlso(object({
    cruiseLine: (0, _copilotValidators.required)().on("publish"),
    passengers: (0, _copilotValidators.optional)((0, _copilotValidators.integer)()),
    cabins: (0, _copilotValidators.optional)((0, _copilotValidators.integer)()),
    cabinsWithBalconies: (0, _copilotValidators.optional)((0, _copilotValidators.integer)()),
    smallestCabin: (0, _copilotValidators.optional)((0, _copilotValidators.integer)()),
    swimmingPools: (0, _copilotValidators.optional)((0, _copilotValidators.integer)()),
    restaurants: (0, _copilotValidators.optional)((0, _copilotValidators.integer)())
  })).catch((0, _crossCheckDsl.mutePath)(["subtype"])).catch((0, _crossCheckDsl.mutePath)(["region"])).catch((0, _crossCheckDsl.mutePath)(["address", "city"]));

  exports.default = (0, _crossCheckDsl.default)(shipValidators);
});