define("copilot/routes/category-manager/category", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ChildrenProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

  exports.default = _authenticatedRoute.default.extend({
    categoryService: Ember.inject.service("category"),
    search: Ember.inject.service("search"),
    user: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (!this.get("user.user.permissions.tagmanager.actions.create")) {
        transition.abort();
        this.transitionToExternal("notfound");
      }
    },
    model: function model(params) {
      var isSuperAdmin = this.get("user.isSuperAdmin");
      if (params.category_id === "roots" && isSuperAdmin) {
        var model = this.get("categoryService.roots");
        return model;
      } else if (params.category_id === "roots") {
        var _model = this.get("categoryService.roots").findBy("slug", "tags");
        // user doesn't have permission to see all roots, redirect to tags root
        Ember.set(_model, "shouldRedirectToTags", true);
        return _model;
      } else {
        var _model2 = this.get("categoryService").find(params.category_id);
        return _model2;
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set("children", this.childrenFor(model, controller.get("filter")));
    },
    redirect: function redirect(model) {
      if (model.shouldRedirectToTags) {
        this.transitionTo("category-manager.category", model.id);
      }
    },
    getInsertionIndex: function getInsertionIndex(newChild, children, filter) {
      var _this = this;

      if (children.get("length") === 0) {
        return 0;
      }
      if (filter.start > 0 && this.compare(children.objectAt(0), newChild) > 0) {
        return null;
      }

      var index = void 0;
      return children.find(function (child, i) {
        index = i;
        return _this.compare(child, newChild) > 0;
      }) ? index : children.get("length");
    },
    compare: function compare(category1, category2) {
      return Ember.compare(category1.get("name").toLowerCase(), category2.get("name").toLowerCase());
    },
    childrenFor: function childrenFor(parent, filter) {
      var _this2 = this;

      return ChildrenProxy.create({
        promise: this.get("categoryService").getChildren(parent, { paginationMetadata: filter }).then(function (categoryData) {
          if (categoryData.pagination) {
            _this2.controller.set("total", categoryData.pagination.total);
          }
          return categoryData.categories;
        })
      });
    },

    actions: {
      transitionToCategory: function transitionToCategory(category) {
        if (category) {
          this.transitionTo("category-manager.category", category);
        }
      },
      fetchChildren: function fetchChildren(key, value) {
        this.controller.set(key, value);
        this.controller.set("children", this.childrenFor(this.currentModel, this.controller.get("filter")));
      },
      saveCategory: function saveCategory(category, changeset) {
        var _this3 = this;

        var children = this.controller.get("children");
        var filter = this.controller.get("filter");
        var total = this.controller.get("total");
        var shouldInsert = category.get("isNew");

        return this.get("categoryService").save(category, changeset).then(function (child) {
          if (!shouldInsert) {
            return;
          }
          var insertionIndex = _this3.getInsertionIndex(child, children, filter);
          if (insertionIndex !== null) {
            children.insertAt(insertionIndex, child);
            if (children.get("length") > filter.limit) {
              children.popObject();
            }
            _this3.controller.set("total", total + 1);
          } else {
            _this3.controller.set("children", _this3.childrenFor(_this3.currentModel, _this3.controller.get("filter")));
          }
        });
      },
      returnToParent: function returnToParent() {
        this.replaceWith("category-manager.category");
      },
      countUsage: function countUsage(query) {
        return this.get("search").count(Object.assign({
          limit: 0,
          view: "edit",
          display: "all",
          nottypes: "event,runwaymoment,review,cnevideo,category"
        }, query));
      }
    }
  });
});