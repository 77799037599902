define("copilot/brands/epi/models/taxonomy", ["exports", "copilot/models/base", "copilot/models/attr", "ember-model"], function (exports, _base, _attr, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;


  var Taxonomy = _base.default.extend({
    type: "taxonomy",
    category: (0, _emberModel.attr)(str),
    name: (0, _emberModel.attr)(),
    hed: (0, _emberModel.attr)(),
    dek: (0, _emberModel.attr)(str),
    imageSocial: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    photosBadge: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    promoDek: (0, _emberModel.attr)(),
    promoHed: (0, _emberModel.attr)(),
    related: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    seoTitle: (0, _emberModel.attr)(),
    seoDescription: (0, _emberModel.attr)(),
    socialTitle: (0, _emberModel.attr)(str),
    socialDescription: (0, _emberModel.attr)(str),
    searchTitle: Ember.computed.reads("name"),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      promoHed: { source: "hed", sanitize: false },
      promoDek: { source: "dek", sanitize: false },
      socialTitle: "name",
      socialDescription: "dek",
      seoDescription: "dek",
      imageSocial: "photosTout.firstObject",
      seoTitle: "name"
    }
  });

  Taxonomy.reopenClass({
    url: "/api/taxonomies"
  });

  exports.default = Taxonomy;
});