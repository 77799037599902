define("copilot/lib/analytics/intercom-adapter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    identify: function identify(id, payload) {
      var _this = this;

      if (!window.intercomSettings.app_id) {
        setTimeout(function () {
          return _this.identify(id, payload);
        }, 1000);
      } else {
        window.Intercom("boot", {
          email: payload.email,
          user_id: id,
          created_at: payload.createdAt,
          name: payload.name,
          company: {
            company_id: payload.company.id,
            name: payload.company.name
          },
          username: payload.username,
          role: payload.role,
          app_id: window.intercomSettings.app_id
        });
      }
    },

    page: function page() {
      window.Intercom("update");
    },
    track: function track() {}
  };
});