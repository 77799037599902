define("copilot/services/event-bus", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    progressing: false,
    asyncCount: 0,
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    init: function init() {
      this._super.apply(this, arguments);
      this.on("progress", this, "progress");
      this.on("success", this, "success");
      this.on("error", this, "error");
      this.on("validationError", this, "error");
      this.ajaxSetup();
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
      delete Ember.$.ajaxSettings.beforeSend;
      delete Ember.$.ajaxSettings.error;
    },
    ajaxSetup: function ajaxSetup(brand) {
      var _this2 = this;

      var _this = this;
      brand = brand || this.activeBrand;

      Ember.$.ajaxSetup({
        beforeSend: function beforeSend(xhr, request) {
          xhr.setRequestHeader("copilot-brand", brand);
          if (request.type !== "GET" && request.url.indexOf("concurrent") === -1 && request.url.indexOf("/log") === -1) {
            if (_this2.isDestroyed) {
              return;
            }

            Ember.run(_this2, "incrementProperty", "asyncCount");
            xhr.always(function () {
              if (_this2.isDestroyed) {
                return;
              }

              Ember.run(_this2, "decrementProperty", "asyncCount");
            });
          }
        },

        error: function error() {
          if (this.type !== "GET") {
            _this.set("asyncCount", -1);
          }
        }
      });
    },


    inAsyncProgress: Ember.computed("asyncCount", function () {
      return this.asyncCount > 0;
    }),

    progress: function progress() {
      if (this.isDestroyed) {
        return;
      }
      this.set("progressing", true);
    },
    success: function success() {
      if (this.isDestroyed) {
        return;
      }
      this.set("progressing", false);
    },
    error: function error() {
      if (this.isDestroyed) {
        return;
      }
      this.set("progressing", false);
    }
  });
});