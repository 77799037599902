define("copilot/models/socketio-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.socket = null;
    },

    logger: Ember.inject.service(),
    /*
     * Returns a Socket IO object
     */
    connect: function connect() {
      var _this = this;

      if (this.socket && this.socket.disconnected) {
        this.socket.connect();
      } else {
        var _window;

        this.socket = (_window = window).io.apply(_window, arguments);
      }
      this.socket.on("connect", function () {
        _this.set("isConnected", true);
      });
      this.socket.on("close", function () {
        _this.set("isConnected", false);
      });
      this.socket.on("connect_error", function (error) {
        _this.logger.error("socket service connect error", error);
      });
      this.socket.on("reconnect_error", function (error) {
        _this.logger.error("socket service reconnect error", error);
      });
      this.socket.on("reconnect_failed", function () {
        _this.logger.error("socket service reconnect failed");
      });
    },
    disconnect: function disconnect() {
      this.socket.removeAllListeners();
      this.socket.disconnect();
    },
    on: function on(event, callback) {
      this.socket.on(event, Ember.run.bind(this, callback));
    },
    off: function off(event) {
      this.socket.removeEventListener(event);
    },
    emit: function emit(type, data) {
      this.socket.emit(type, data);
    }
  });
});