define("copilot/lib/copilot-computeds", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = {
    /**
     * A computed property macro for looking up an Array value based on a
     * dependent key path, and reducing it to a javascript Object. For each
     * element in the array, a `key` property is pulled from the element to use
     * as the key for each memeber, with the member itself as the value.
     *
     * E.g. given the following:
     * ```js
     *  export default Ember.Object.extend({
     *    init () {
     *      this._super(...arguments);
     *      this.cats = [{
     *        id: 1, name: 'Leo',
     *        id: 2, name: 'Sasha'
     *      }];
     *    },
     *    catsAsHash: arrayToObject('cats', 'id')
     *  });
     *  ```
     *  In this example, calling `.get('catsAsHash')` on an instance would
     *  return the following:
     *  ```js
     *  {
     *    1: {id: 1, name: 'Leo'},
     *    2: {id: 2, name: 'Sasha'}
     *  }
     *  ```
     *
     * @param {String} arrayPath = the path on the instance to look up the Array
     * value
     * @param {String} key - the name of the property of each element in the
     * array to use as the key for each key/value pair in the returned Object
     * @type Object
     *
     */
    arrayToObject: function arrayToObject(arrayPath, key) {
      return Ember.computed(arrayPath + ".[]", key, function () {
        var array = this.getWithDefault(arrayPath, []);
        return array.reduce(function (accum, item) {
          accum[item[key]] = item;
          return accum;
        }, {});
      });
    },
    equalProperties: function equalProperties(firstProp, secondProp) {
      return Ember.computed(firstProp, secondProp, function () {
        return this.get(firstProp) === this.get(secondProp);
      });
    },
    firstMatch: function firstMatch() {
      var _Ember;

      for (var _len = arguments.length, keys = Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(keys).concat([function () {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var key = _step.value;

            var prop = this.get(key);
            if (prop) {
              return prop;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }]));
    },
    eitherOr: function eitherOr(firstProp, secondProp) {
      return Ember.computed(firstProp, secondProp, function () {
        return this.get(firstProp) || this.get(secondProp);
      });
    },
    everyBy: function everyBy(collectionName, propertyName, value) {
      return Ember.computed(collectionName + ".@each." + propertyName, function () {
        return this.get(collectionName).isEvery(propertyName, value);
      });
    },
    isIncluded: function isIncluded(path, arr) {
      return Ember.computed(path, function () {
        var value = this.get(path);
        return arr.includes(value);
      });
    },
    findByProp: function findByProp(array, key, value) {
      return Ember.computed(array, function () {
        return this.get(array).findBy(key, value);
      });
    },


    // Used by Epi menu and recipe models
    stringByline: function stringByline() {
      return Ember.computed("contributorsAuthor.[]", function () {
        var authorsString = void 0;

        var authorNames = this.contributorsAuthor.map(function (author) {
          return Ember.get(author, "name");
        });

        if (authorNames.length) {
          var lastAuthor = authorNames.pop();

          if (Ember.isPresent(authorNames)) {
            authorsString = authorNames.join(", ") + " and " + lastAuthor;
          } else {
            authorsString = lastAuthor;
          }

          return _copilotMarkdown.default.sanitize("By " + authorsString);
        }
      });
    }
  };
});