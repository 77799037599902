define("copilot/validations/brands/vogue/validators/published", ["exports", "@condenast/cross-check-dsl"], function (exports, _crossCheckDsl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PublishedValidator = undefined;


  /**
   * A @condenast/cross-check-dsl validator that checks that the relationship is published
   * @module copilot/brands/vogue/validations/validators/published
   * @augments Ember.Service
   */
  class PublishedValidator extends _crossCheckDsl.ValueValidator {
    validate(value) {
      var publishURI = value.get("firstObject._embedded.publishHistory.uri");
      if (!publishURI) {
        return { name: "published" };
      }
    }
  }

  exports.PublishedValidator = PublishedValidator;
  exports.default = (0, _crossCheckDsl.builderFor)(PublishedValidator);
});