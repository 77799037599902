define("copilot/brands/vogue/models/review", ["exports", "copilot/models/base", "copilot/brands/vogue/mixins/vogue-slug"], function (exports, _base, _vogueSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Review = _base.default.extend(_vogueSlug.default);

  exports.default = Review;
});