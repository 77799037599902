define("copilot/validations/brands/lci/gallery", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  // Allows to append new rules to core article rules
  var galleryValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.GalleryValidationsDescriptor).andAlso(object({
    categoriesType: (0, _copilotValidators.length)({ min: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "taxonomies-allowed-minimum" }
      }];
    }).andThen((0, _copilotValidators.length)({ max: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "taxonomies-allowed-maximum" }
      }];
    })).on("publish")
  })).catch((0, _crossCheckDsl.mutePath)(["categoriesChannels"])); // Catch errors from core rules and silence any body errors

  // Returns the validation descriptors
  exports.default = galleryValidationBuilder;
});