define("copilot/serializers/sitesetting", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CONFIG_KEYS = ["channels", "contentTypes", "hostnames", "commerce", "language"];

  exports.default = Ember.JSONSerializer.extend({
    serialize: function serialize(record) {
      CONFIG_KEYS.forEach(function (config) {
        if (!record.get("_dirtyAttributes").includes(config)) {
          record.get("_dirtyAttributes").pushObject(config);
        }
      });

      return record.toPartialJSON();
    }
  });
});