define("copilot/lib/log-error", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    console.error(this.error);
    var pathname = this.pathname;
    var stack = this.error && this.error.stack;
    var truncatedStack = stack && stack.substring(0, 200);
    (0, _icAjax.default)("/log", {
      type: "POST",
      data: {
        level: "error",
        msg: "Ember onerror caught",
        pathname: pathname,
        caught: truncatedStack
      },
      tag: "lib.log-error"
    }).then(function (response) {
      console.log("Successfully logged error");
      console.log(response);
    }, function (error) {
      console.log("Encountered an error attempting to log error");
      console.log(error);
    });
  };
});