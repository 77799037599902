define("copilot/validations/brands/vogue/person", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var PersonValidationBuilder = object({
    //name: required(),
    roles: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  });

  exports.default = PersonValidationBuilder;
});