define("copilot/services/v2-redirect", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function getStandardRoutesForCollection(collectionName) {
    return [collectionName + ".index", collectionName + ".index.edit", collectionName + ".create", collectionName + ".create.edit", collectionName + ".create.index"];
  }

  var FEATURE_FLAGS_TO_REDIRECTED_ROUTES = {
    v2Activities: getStandardRoutesForCollection("activities"),
    v2Articles: getStandardRoutesForCollection("articles"),
    v2AudioFiles: getStandardRoutesForCollection("audiofiles"),
    v2Bars: getStandardRoutesForCollection("bars"),
    v2Brands: getStandardRoutesForCollection("brands"),
    v2Businesses: getStandardRoutesForCollection("businesses"),
    v2Cartoons: getStandardRoutesForCollection("cartoons"),
    v2Cities: getStandardRoutesForCollection("cities"),
    v2Clips: ["clips.index", "clips.index.edit"],
    v2Contributors: getStandardRoutesForCollection("contributors"),
    v2Cookbooks: getStandardRoutesForCollection("cookbooks"),
    v2CuratedLists: getStandardRoutesForCollection("curatedlists"),
    v2CuratedSearches: getStandardRoutesForCollection("curatedsearches"),
    v2Events: getStandardRoutesForCollection("events"),
    v2ExternalLinks: getStandardRoutesForCollection("externallinks"),
    v2ExternalReferences: getStandardRoutesForCollection("externals"),
    v2Galleries: [].concat(_toConsumableArray(getStandardRoutesForCollection("galleries")), ["galleries.index.child"]),
    v2Hotels: getStandardRoutesForCollection("hotels"),
    v2InfoPages: getStandardRoutesForCollection("infopages"),
    v2LiveStories: getStandardRoutesForCollection("livestories"),
    v2Menus: getStandardRoutesForCollection("menus"),
    v2MusicAlbums: getStandardRoutesForCollection("musicalbums"),
    v2MusicGroups: getStandardRoutesForCollection("musicgroups"),
    v2MusicRecordings: getStandardRoutesForCollection("musicrecordings"),
    v2MusicReleases: getStandardRoutesForCollection("musicreleases"),
    v2NativeAdvertisers: getStandardRoutesForCollection("advertisers"),
    v2NativeArticles: getStandardRoutesForCollection("nativearticles"),
    v2NativeGalleries: [].concat(_toConsumableArray(getStandardRoutesForCollection("nativegalleries")), ["nativegalleries.index.child"]),
    v2NativeRecipes: getStandardRoutesForCollection("nativerecipes"),
    v2NativeVideos: getStandardRoutesForCollection("nativevideos"),
    v2People: getStandardRoutesForCollection("people"),
    v2Photos: getStandardRoutesForCollection("photos"),
    v2Products: getStandardRoutesForCollection("products"),
    v2Recipes: getStandardRoutesForCollection("recipes"),
    v2Redirects: getStandardRoutesForCollection("redirects"),
    v2Restaurants: getStandardRoutesForCollection("restaurants"),
    v2Reviews: getStandardRoutesForCollection("reviews"),
    v2Ships: getStandardRoutesForCollection("ships"),
    v2Shops: getStandardRoutesForCollection("shops"),
    v2SkiResorts: getStandardRoutesForCollection("skiresorts"),
    v2Spas: getStandardRoutesForCollection("spas"),
    v2TagPages: getStandardRoutesForCollection("tagpages"),
    v2Taxonomies: getStandardRoutesForCollection("taxonomies"),
    v2Venues: getStandardRoutesForCollection("venues"),
    v2RcaLists: getStandardRoutesForCollection("rcalists"),
    v2SearchPage: ["search.index"],
    v2Dashboard: ["brand.index", "dashboard"]
  };

  var REDIRECT_CONTROL_PARAM = "noRedirect";

  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    features: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    v2Url: Ember.computed.reads("configService.config.copilotV2Url"),
    configSetup: Ember.computed.reads("configService.settingUp"),

    _urlForTransition: function _urlForTransition(transition) {
      if (Ember.get(transition, "intent.url")) {
        // the incoming transition may already have a computed url
        return transition.intent.url;
      } else {
        var _router;

        // it may also have an array of route arguments, which can be used to generate the url
        // this may fail for various reasons, but we don't have anything useful to try if it doesn't work
        return (_router = this.router).urlFor.apply(_router, [transition.targetName].concat(_toConsumableArray(transition.intent.contexts)));
      }
    },
    _shouldRedirect: function _shouldRedirect(transition) {
      var params = new URLSearchParams(window.location.search);
      if (params.get(REDIRECT_CONTROL_PARAM) != null) {
        return false;
      }

      var contentEditContentType = transition.params && transition.params.contentEdit && transition.params.contentEdit.content_type;
      var routeName = contentEditContentType ? transition.targetName.replace("contentEdit", contentEditContentType) : transition.targetName;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(FEATURE_FLAGS_TO_REDIRECTED_ROUTES)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var flag = _ref2[0];
          var routes = _ref2[1];

          var featureFlag = this.get("features." + flag);
          if (featureFlag && routes.includes(routeName)) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },


    _redirectTransition: (0, _emberConcurrency.task)(function* (transition) {
      // wait until config service has run `setup` at least once, and is not currently running it
      yield (0, _emberConcurrency.waitForProperty)(this.configSetup, "performCount", function (n) {
        return n > 0;
      });
      yield (0, _emberConcurrency.waitForProperty)(this.configSetup, "isIdle");

      var transitionIsValid = transition && transition.targetName;
      if (!transitionIsValid || !this.v2Url || !this._shouldRedirect(transition)) {
        return true;
      }
      try {
        var path = this._urlForTransition(transition);

        if (path) {
          var redirectTarget = new URL(this.v2Url + path);
          window.location.replace(redirectTarget.toString());
        }
      } catch (e) {
        throw new Error("Failed to redirect " + transition.targetName + " to Copilot v2", { cause: e });
      }
    }).keepLatest(),

    maybeRedirectTransition: function maybeRedirectTransition(transition) {
      return this._redirectTransition.perform(transition);
    }
  });
});