define("copilot/forms/brands/gq/nativerecipe", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/gq/nativerecipe"], function (exports, _nativerecipe) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _nativerecipe.default;
    }
  });
});