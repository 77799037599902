define("copilot/components/article-dialog/box/component", ["exports", "ember-service-methods", "ic-ajax", "copilot/helpers/strip-markdown", "ember-concurrency"], function (exports, _emberServiceMethods, _icAjax, _stripMarkdown, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var BLANK_ARTICLE = "blank-article";
  var ARTICLE_FROM_PRESETS = "article-from-presets";

  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    search: (0, _emberServiceMethods.inject)(),
    saveService: Ember.inject.service("save"),
    analytics: Ember.inject.service(),
    // default to creating a blank article
    createFromPreset: false,
    noPresets: Ember.A(),

    didInsertElement: function didInsertElement() {
      this.getPresets.perform();
    },


    getPresets: (0, _emberConcurrency.task)(function* () {
      var presetsList = yield this.search({
        hierarchy: "copilot/preset",
        nottypes: "category",
        notcategory: "",
        limit: 100
      });

      var unsortedPresets = presetsList.hits;
      var sortedPresets = unsortedPresets.map(function (preset) {
        var formattedHed = (0, _stripMarkdown.stripMarkdown)([preset.hed]);
        preset.formattedHed = formattedHed;

        return preset;
      }).sortBy("formattedHed");

      return {
        sortedPresets: sortedPresets
      };
    }),

    createArticleFromPreset: function () {
      var _ref = _asyncToGenerator(function* (presetId) {
        var basePreset = yield this.store.find("article", presetId);
        // This has the revision id as well and is used for duplication
        var href = Ember.get(basePreset, "_links.self.href");
        var clonedPresetData = yield (0, _icAjax.default)("/api/duplicate", {
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            href: href
          })
        });
        var newArticle = yield this.store.find("article", clonedPresetData.id);

        // change the title of the preset article to remove 'COPY'
        var newHed = newArticle.hed.replace(/\[COPY.*]/g, "");
        newArticle.set("hed", newHed);

        var removePresetCategory = function removePresetCategory(possiblePresetCategory) {
          return possiblePresetCategory.slug !== "preset";
        };
        var nonPresetCategories = (Ember.get(newArticle, "categories.copilot") || []).filter(removePresetCategory);
        newArticle.categories.copilot = nonPresetCategories;
        newArticle.metadata.publishData = basePreset.metadata.publishData;
        newArticle.metadata.seo = basePreset.metadata.seo;

        yield this.saveService.save(newArticle);
        var analyticsData = {
          sourcePresetId: presetId,
          targetArticleId: newArticle.id
        };

        this.analytics.track("content", "create article from preset", analyticsData);

        return newArticle;
      });

      function createArticleFromPreset(_x) {
        return _ref.apply(this, arguments);
      }

      return createArticleFromPreset;
    }(),


    actions: {
      create: function () {
        var _ref2 = _asyncToGenerator(function* () {
          var selectedPresetId = this.preset;
          var router = this.get("router");
          this.set("showCreateArticleModal", false);
          if (!this.createFromPreset) {
            router.transitionTo("articles.create", this.activeBrand);
          } else if (selectedPresetId) {
            var newArticle = yield this.createArticleFromPreset(selectedPresetId);
            router.transitionTo("articles.index.edit", newArticle.id);
          }
        });

        function create() {
          return _ref2.apply(this, arguments);
        }

        return create;
      }(),
      dismiss: function dismiss() {
        this.set("showCreateArticleModal", false);
      },
      handleRadioChange: function handleRadioChange(e) {
        if (e.target.value === BLANK_ARTICLE) {
          this.set("createFromPreset", false);
        }
        if (e.target.value === ARTICLE_FROM_PRESETS) {
          this.set("createFromPreset", true);
        }
      },
      handleDropdownChange: function handleDropdownChange() {
        this.set("createFromPreset", true);
      }
    }
  });
});