define("copilot/routes/nativegalleries/index/child", ["exports", "copilot/routes/galleries/index/child"], function (exports, _child) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _child.default.extend({
    templateName: "galleries/index/child",
    controllerName: "galleries/child"
  });
});