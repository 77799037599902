define("copilot/routes/brand", ["exports", "copilot/routes/authenticated-route", "copilot/lib/brand-list"], function (exports, _authenticatedRoute, _brandList) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FORMER_TOP_LEVEL_ROUTES = ["dashboard", "search", "sitesettings", "feature-flags", "users", "find"];

  exports.default = _authenticatedRoute.default.extend({
    beforeModel: function beforeModel(transition) {
      var brand = transition.params.brand.brand_code;
      if (_brandList.default.indexOf(brand) === -1) {
        // TODO: 2018-02-13: Remove this redirect for bookmarked URLs after
        // some reasonable amount of time has elapsed.
        if (FORMER_TOP_LEVEL_ROUTES.includes(brand)) {
          return this.transitionTo("index");
        }
        throw new Error("Brand does not exist");
      }
    },


    // noop
    model: function model() {},


    actions: {
      error: function error(err) {
        var errorMessage = Ember.get(err, "message");
        if (errorMessage && errorMessage.match(/Brand does not exist/)) {
          this.intermediateTransitionTo("/not-found", "not-found");
        }
        return true;
      }
    }
  });
});