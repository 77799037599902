define("copilot/services/photo", ["exports", "ember-inflector", "lodash-es"], function (exports, _emberInflector, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    brand: Ember.inject.service(),
    environment: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    configService: Ember.inject.service("config"),
    imageEditorService: Ember.inject.service("image-editor"),

    vulcanUrl: Ember.computed("activeBrand", function () {
      var config = this.configService.findBrand.byCopilotCode(this.activeBrand);
      return config.vulcanUrl;
    }),

    getMasterURL: function getMasterURL(mediaModel) {
      var type = mediaModel.type;
      var aspectRatio = type === "clip" ? "original" : "master";
      return this.getImageURL({
        aspectRatio: aspectRatio,
        id: mediaModel.id,
        filename: mediaModel.filename,
        options: ["pass"],
        type: type
      });
    },
    getImageURL: function getImageURL(config) {
      var width = config.width ? "w_" + config.width : null;
      var height = config.height ? "h_" + config.height : null;
      var x = !Ember.isEmpty(config.x) ? "x_" + config.x : null;
      var y = !Ember.isEmpty(config.y) ? "y_" + config.y : null;
      var aspectRatio = config.aspectRatio;
      var filename = config.filename;
      var type = config.type ? (0, _emberInflector.pluralize)(config.type) : "photos";
      var id = config.id;
      var options = _lodashEs.default.compact([width, height, x, y, "c_limit"].concat(config.options));

      var pass = options.some(function (value) {
        return value === "pass";
      });

      options = pass ? "pass" : options.join(",");

      return window.location.protocol + "//" + this.vulcanUrl + "/" + type + "/" + id + "/" + aspectRatio + "/" + options + "/" + encodeURIComponent(filename);
    }
  });
});