define("copilot/mixins/seo", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    seoService: Ember.inject.service("seo"),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.afterValidationHooks) {
        this.afterValidationHooks.pushObject("runSeoScoring");
      }
    },
    getJSON: function getJSON(model) {
      var json = model.toJSON();
      var seo = {
        keyword: model.get("seo.keyword"),
        category: model.get("seo.category")
      };
      var brandCode = this.get("configService.activeCnBrandCode");
      var type = model.type;
      var seoLocale = this.get("configService.config.seo.semRushLocale");
      Ember.set(json.metadata, "seo", seo);

      var _configService$findBr = this.configService.findBrand.byCopilotCode(this.configService.activeBrand),
          url = _configService$findBr.url,
          copilotApi = _configService$findBr.copilotApi;

      json.copilotApi = copilotApi;
      json.brandHostUrl = url;
      json.brand = brandCode;
      json.type = type;
      json.lang = json.lang || seoLocale;

      return json;
    },
    runSeoScoring: function runSeoScoring(model) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var brandRules = _this.get("configService.config.seo.rulesets");
        var json = _this.getJSON(model || _this.model);
        var seoField = _this.get("model.seo");
        _this.seoService.run(json, brandRules).then(function (results) {
          if (results && !_this.isDestroyed) {
            _this.set("model.seoScore", results.actualTotal);
            if (seoField) {
              _this.set("model.seo.score", results.actualTotal);
            }
          }
          resolve();
        }).catch(function () {
          resolve();
        });
      });
    }
  });
});