define("copilot/components/clip-processor/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEBOUNCE_MS = Ember.testing || window.testing ? 1 : 1000;

  /**
   * Displays a placeholder image for clips and polls the API until clip's photosTout is populated
   *
   * The clip-processing component is meant to wrap around the `image-on-demand`
   * component where it needs to show the processing placeholder image. For example,
   * the clip edit page should show that the clip is still processing from the API:
   *
   * ```hbs
   * {{#clip-processor model=model}}
   *   {{image-on-demand model=image width="600" height="600" aspectRatio="master"}}
   * {{/clip-processor}}
   * ```
   *
   * @module copilot/components/clip-processor/component
   * @augments Ember.Component
   * @param {(Object|Ember.Model)} model - The model whose 'field' attribute the item lives on
   * @property {object} relModel.get - Returns the model or the embedded relationship
   * @property {object} relModel.set - Replace the model with the response polled from API
   *
   */
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.pollRenditions.perform();
    },


    tagName: "",

    /**
     * There are two models that can get passed into this component
     *
     * @type {module:app/models/gallery-item}
     * @type {module:app/models/clip}
     */
    relModel: Ember.computed("model", {
      get: function get() {
        var model = this.model;
        // Need to use `get` here because model may be a changeset
        // that doesn't yet support ES5 getters
        if (Ember.get(model, "type") === "gallery-item") {
          return model.get("embedded");
        } else {
          return model;
        }
      },
      set: function set(_, model) {
        if (this.get("model.type") === "gallery-item") {
          this.set("model.embedded", model);
          return model;
        } else {
          Ember.setProperties(this.model, Ember.getProperties(model, ["renditions", "_embedded.photosTout", "_embedded.thumbnails"]));
          return this.model;
        }
      }
    }),

    pollRenditions: (0, _emberConcurrency.task)(function* () {
      var relModel = this.relModel;
      var type = Ember.get(relModel, "meta.modelName");
      while (type === "clip" && (Ember.get(this.relModel, "_embedded.photosTout") || []).length === 0) {
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
        relModel = yield this.store.fetchTransient("clip", Ember.get(relModel, "id"));
        if (Ember.get(relModel, "_embedded.photosTout.length") > 0) {
          this.set("relModel", relModel);
        }
      }
    })
  });
});