define("copilot/brands/vogue/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      active: "Active",
      address: "Address",
      agencies: "Agencies",
      annualSeason: "Annual Season",
      brands: "Brands",
      city: "City",
      code: "Code",
      contributorsAuthor: "Authors",
      endYear: "End Year",
      "event.livestream": "Livestream Available",
      "fashionshow.promoTitle": "Promo Title",
      "fashionshow.brand": "Brand",
      "fashionshow.designer": "Designer",
      "fashionshow.season": "Season",
      "fashionshow.livestream": "Livestream URL",
      "fashionshow.coverage": "Coverage",
      "fashionshow.modaLink": "Commerce URL",
      "fashionshow.vogueReview": "Review URL",
      "fashionshow.vogueReviewLinkText": "Link Text",
      "fashionshow.instantShow": "Instant",
      "fashionshow.highlighted": "Promoted",
      "fashionshow.showInFeed": "Feed",
      "fashionshow.presale": "Presale",
      "fashionshow.showLivestream": "Show Livestream",
      featured: "Featured",
      gender: "Gender",
      people: "People",
      "photo.caption": "Photo Caption",
      "photo.credit": "Photo Credit",
      "photo.people": "Photo People",
      "photo.tags": "Photo Keywords",
      roles: "Roles",
      season: "Season",
      newSeason: "New Seasons",
      newCity: "New City",
      showCalendar: "Show Calendar",
      runwayMomentType: "Runwaymoment Type",
      "runwaymoment.moveItVideo": "Runwaymoment MoveIt Video",
      "runwaymoment.moveItClip": "Runwaymoment MoveIt Clip",
      "runwaymoment.modaLink": "Runwaymoment Ecommerce Text",
      startYear: "Start Year",
      options: "Options",
      type: "Type",
      video: "Video",
      year: "Year"
    },
    fieldsetLegends: {
      designers: "Designers",
      eventInfo: "Event Info",
      galleries: "Galleries",
      mainContent: "Main Content",
      options: "Options",
      previousDesigners: "Previous Designers",
      vogueReview: "Vogue Review"
    },
    validationMessage: {
      eventDate: "is required if @{field} is present",
      published: "must be published"
    },
    components: {
      "published-modified-summary": {
        liveOnAt: "Live on {date} at {time}",
        liveToday: "Live Today at {time}",
        modifiedOnAt: "Modified on {date} at {time}",
        modifiedToday: "Modified Today at {time}"
      },
      "details-selector": {
        header: "Add Details",
        submit: "{count, plural, =1 {Add 1 detail} other {add {count} details}}"
      }
    },
    placeholder: {
      houses: {
        name: "New House"
      },
      agencies: {
        name: "New Agency"
      }
    }
  };
});