define("copilot/lib/copilot-markdown", ["exports", "@condenast/copilot-markdown-shim", "@condenast/copilot-markdown-shim-sanitize"], function (exports, _copilotMarkdownShim, _copilotMarkdownShimSanitize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _copilotMarkdownShim.default.use(_copilotMarkdownShimSanitize.default);

  exports.default = _copilotMarkdownShim.default;
});