define("copilot/serializers/list", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CONTEXTUAL_FIELDS = ["hed", "dek", "rubric"];

  // This should not be necessary. Will hopefully be obviated wit
  // upcoming schema changes on the API side. Replaces mutation that
  // formerly occured on in the contextual-editor component
  function scrubBlankContextualData(item) {
    CONTEXTUAL_FIELDS.forEach(function (field) {
      if (Ember.isEmpty(item[field])) {
        delete item[field];
      }
    });
    return item;
  }

  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.items = json.items.map(function (item) {
        item.relId = item.embedded.id;
        item = scrubBlankContextualData(item);
        return item;
      });
      return json;
    }
  });
});