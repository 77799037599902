define("copilot/transitions/window-shade", ["exports", "liquid-fire"], function (exports, _liquidFire) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = windowShade;
  function windowShade(_ref) {
    var _this = this;

    var duration = _ref.duration,
        easing = _ref.easing;

    var firstStep = void 0;
    if ((0, _liquidFire.isAnimating)(this.oldElement, "moving-in")) {
      firstStep = (0, _liquidFire.finish)(this.oldElement, "moving-in");
    } else {
      (0, _liquidFire.stop)(this.oldElement);
      firstStep = Ember.RSVP.resolve();
    }

    var width = biggestSize(this, "width");
    if (this.oldElement) {
      this.oldElement.css("marginLeft", (width - this.oldElement.width()) / 2);
    }
    if (this.newElement) {
      this.newElement.css("marginLeft", (width - this.newElement.width()) / 2);
      this.newElement.css("visibility", "hidden");
    }

    return firstStep.then(function () {
      var offset = biggestSize(_this, "height") + 40;
      return (0, _liquidFire.animate)(_this.oldElement, { top: offset * -1 }, { duration: duration, easing: easing }).then(function () {
        var offset = biggestSize(_this, "height") + 40;
        if (_this.newElement) {
          _this.newElement.css("top", offset * -1);
        }
        return (0, _liquidFire.animate)(_this.newElement, { top: [0, offset * -1] }, { duration: duration, easing: easing }, "moving-in");
      });
    });
  }

  function biggestSize(context, dimension) {
    var sizes = [];
    [context.newElement, context.oldElement].forEach(function (element) {
      if (element) {
        sizes.push(parseInt(element.css(dimension), 10));
        sizes.push(parseInt(element.parent().css(dimension), 10));
      }
    });
    return Math.max.apply(null, sizes);
  }
});