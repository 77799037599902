define("copilot/brands/vogue/controllers/fashionshows/index", ["exports", "copilot/controllers/base", "ember-inflector", "copilot/brands/vogue/mixins/multiple-model-controller", "copilot/brands/vogue/mixins/polaroid"], function (exports, _base, _emberInflector, _multipleModelController, _polaroid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _base.default.extend(_multipleModelController.default, _polaroid.default, {
    isPublishable: Ember.computed.alias("model.fashionshow.meta.publishable"),

    editRoute: "fashionshows.index.edit",

    mainModelPath: "model.fashionshow",
    mainModelType: "fashionshow",

    review: Ember.computed.reads("model.fashionshow.review.firstObject"),
    season: Ember.computed.reads("model.fashionshow.season.firstObject"),
    brand: Ember.computed.reads("model.fashionshow.brand.firstObject"),
    _previewModel: Ember.computed.reads("model.fashionshow"),

    concurrentEditId: Ember.computed.reads("model.fashionshow.id"),

    isSaved: Ember.computed("model.fashionshow.id", function () {
      return !Ember.isNone(this.get("model.fashionshow.id"));
    }),

    pluralizedType: Ember.computed("model.fashionshow.type", function () {
      return (0, _emberInflector.pluralize)(this.get("model.fashionshow.type"));
    }),

    latestPublishedRevision: Ember.computed("model.fashionshow._data._embedded.{publishHistory,queued}", function () {
      return this.get("model.fashionshow._data._embedded.publishHistory") || this.get("model.fashionshow._data._embedded.queued") || this._super();
    }),

    allValidationErrors: Ember.computed("model.fashionshow.validationErrors.allErrors", "model.event.validationErrors.allErrors", function () {
      var fashionshowErrors = this.get("model.fashionshow.validationErrors.allErrors");
      var eventErrors = this.get("model.event.validationErrors.allErrors");
      return fashionshowErrors.concat(eventErrors);
    }),

    setTitle: function setTitle() {
      if (this.get("model.fashionshow.isNew")) {
        var brand = this.get("brand.name");
        var season = this.get("season.name");
        this.set("model.fashionshow.title", brand + " " + season);
        this.set("season_id", undefined);
      }
    },
    copyAttrsToEvent: function copyAttrsToEvent() {
      var eventModel = this.get("model.event");
      eventModel.setProperties({
        instantShow: this.get("model.fashionshow.instantShow")
      });
      eventModel.get("city").setObjects(this.get("model.fashionshow.city").toArray());
    },
    saveEvent: function () {
      var _ref = _asyncToGenerator(function* () {
        if (this.get("model.event.eventDate")) {
          yield this.saveService.save(this.get("model.event"));
          var eventModel = this.get("model.event");
          this.get("model.fashionshow.event").setObjects([eventModel]);
        } else {
          if (Ember.isPresent(this.get("model.fashionshow.event"))) {
            yield this.removeEventModel();
          }
        }
      });

      function saveEvent() {
        return _ref.apply(this, arguments);
      }

      return saveEvent;
    }(),
    removeEventModel: function removeEventModel() {
      return new Ember.RSVP.Promise(function (resolve) {
        this.get("model.fashionshow.event").clear();
        var id = this.get("model.event.id");
        if (id) {
          var url = "/api/events/" + id + "/archive";
          var archive = Ember.$.ajax(url, {
            type: "POST",
            data: {},
            tag: "vogue.controllers.fashionshows.index"
          });
          return archive.then(function () {
            resolve();
          });
        }
        resolve();
      }.bind(this));
    },
    saveModels: function () {
      var _ref2 = _asyncToGenerator(function* () {
        this.setTitle();
        this.copyAttrsToEvent();
        yield this.saveEvent();
        yield this.get("model.fashionshow").setInlines();
        yield this.saveService.save(this.get("model.fashionshow"));
      });

      function saveModels() {
        return _ref2.apply(this, arguments);
      }

      return saveModels;
    }(),
    validateForSave: function validateForSave() {
      var validationService = this.validation;
      var fashionshowCheck = validationService.validate(this.get("model.fashionshow"), "save");
      var eventCheck = validationService.validate(this.get("model.event"), "save");
      return Ember.RSVP.Promise.all([fashionshowCheck, eventCheck]).then(function (results) {
        return results.every(function (result) {
          return result;
        });
      });
    },


    actions: {
      createGallery: function createGallery(type) {
        var gallery = this.store.createRecord("fashiongallery");
        gallery.set("galleryType", type);
        gallery.set("title", this.get("model.fashionshow.title") + " - " + type);
        this.saveService.save(gallery).then(function () {
          this.get("model.fashionshow." + type).setObjects([gallery]);
          this.send("save");
        }.bind(this));
      },
      removeGallery: function removeGallery(type) {
        this.get("model.fashionshow." + type).clear();
        this.send("save");
      }
    }
  });
});