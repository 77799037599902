define("copilot/validations/brands/cnt/specialreport", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var specialReportValidators = object({
    hed: (0, _copilotValidators.required)(),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 }).on("publish"))
  }).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = (0, _crossCheckDsl.default)(specialReportValidators);
});