define("copilot/controllers/galleries/child", ["exports", "ember-inflector", "lodash-es", "copilot/controllers/base"], function (exports, _emberInflector, _lodashEs, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or,
      reads = _Ember$computed.reads;
  exports.default = _base.default.extend({
    mainModelPath: "model",
    modelsToSave: Ember.computed("shouldUpdateModel", {
      get: function get() {
        return this.shouldUpdateModel ? ["galleryModel", "model"] : ["galleryModel"];
      },
      set: function set(_, value) {
        return value;
      }
    }),
    modelsAreDirty: or("galleryModel.isDirty", "model.isDirty"),
    shouldUpdateModel: Ember.computed("model", function () {
      return ["clip", "photo"].includes(this.get("model.type"));
    }),

    /**
     * @override BaseController.canSave
     */
    canSave: Ember.computed("modelsToSave", function () {
      var _this = this;

      return _lodashEs.default.every(this.modelsToSave, function (modelName) {
        var pluralizedType = (0, _emberInflector.pluralize)(_this.get(modelName + ".type"));
        return _this.canSaveType(pluralizedType);
      });
    }),

    isChildController: true,
    applicationController: Ember.inject.controller("application"),
    galleryController: Ember.inject.controller("galleries/index"),
    galleryModel: reads("galleryController.model"),
    concurrentEditId: reads("galleryModel.id"),
    activeIds: reads("galleryController.activeIds"),
    selected: reads("galleryController.currentSelection"),
    mediaToSave: alias("galleryController.mediaToSave"),
    id: reads("model.id"),
    childId: reads("id"),
    modelType: reads("model.meta.modelName"),
    item: reads("model"),
    items: reads("galleryController.model.items"),
    isActive: reads("galleryItem.isActive"),
    onFirstPage: equal("currentPage", 0),
    _previewModel: reads("galleryController.model"),

    save: function save(done) {
      var _this2 = this;

      var validationService = this.validation;
      var model = this.mainModelPath ? this.get(this.mainModelPath) : this.model;
      return validationService.validate(model, "save").then(function (isValid) {
        if (isValid) {
          model._forceSave = true;
          _this2.triggerProgress();
          return _this2.saveModels().then(_this2.saveSuccess(_this2)).then(done).catch(_this2.saveFailure.bind(_this2)).finally(function () {
            return model._forceSave = false;
          });
        } else {
          _this2.triggerValidationError();
        }
      });
    },
    saveModels: function saveModels() {
      var _this3 = this;

      var promises = this.modelsToSave.map(function (modelName) {
        return _this3.get(modelName).setInlines().then(function () {
          return _this3.saveService.save(_this3.get(modelName));
        });
      });
      return Ember.RSVP.all(promises);
    },


    /**
     * Returns gallery item at current index
     * @returns {Model}
     */
    galleryItem: Ember.computed("items.[]", "currentPage", function () {
      return this.items.objectAt(this.currentPage);
    }),

    galleryChildPath: Ember.computed("router", function () {
      return this.router.currentRouteName;
    }),

    galleryPath: Ember.computed("router", function () {
      return this.router.currentRouteName.slice(0, -6);
    }),

    /**
     * Returns index of current item
     * @returns {Number}
     */
    currentPage: Ember.computed("items.[]", "item", function () {
      return this.items.mapBy("id").indexOf(this.get("item.id"));
    }),

    /**
     * Returns index of last item in items array
     * @returns {Number}
     */
    lastPage: Ember.computed("items.[]", function () {
      return this.get("items.content").length - 1;
    }),

    /**
     * Returns true if current index is equal to last page index
     * @returns {Boolean}
     */
    onLastPage: Ember.computed("currentPage", "lastPage", function () {
      return Ember.isEqual(this.currentPage, this.lastPage);
    }),

    /**
     * Increment current page
     * Update selected to item at new current page index
     * @function nextItem
     */
    nextItem: function nextItem() {
      var destinationPage = this.currentPage + 1;
      var item = this.items.objectAt(destinationPage);
      this.transitionToItem(item);
    },


    /**
     * Decrement current page,
     * Update selected to item at new current page index
     * @function previousItem
     */
    previousItem: function previousItem() {
      var destinationPage = this.currentPage - 1;
      var item = this.items.objectAt(destinationPage);
      this.transitionToItem(item);
    },


    /**
     * Tranistion application to new item route
     * @function transitionToItem
     */
    transitionToItem: function transitionToItem(item) {
      this.transitionToRoute(this.galleryChildPath, item.get("embedded.meta.modelName"), item.get("embedded.id"));
    },


    actions: {
      toggleActive: function toggleActive() {
        var item = this.galleryItem;
        this.galleryController.send("toggleActive", item);
      },
      openChildObject: function openChildObject() {
        var collection = this.get("model.meta.collectionName");
        var id = this.id;
        var activeBrand = this.activeBrand;
        var url = "/" + activeBrand + "/" + collection + "/" + id;
        window.open(url);
      },
      next: function next() {
        this.nextItem();
        this.applicationController.notifyPropertyChange("currentPath");
      },
      previous: function previous() {
        this.previousItem();
        this.applicationController.notifyPropertyChange("currentPath");
      }
    }
  });
});