define("copilot/brands/vogue/routes/fashiongalleries/index/child", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/gallery-child-route"], function (exports, _authenticatedRoute, _galleryChildRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_galleryChildRoute.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var selection = model.runwaymoment ? model.runwaymoment : model;

      controller.set("fashiongallery.currentSelection", selection);
      controller.fashiongallery.showCarousel();
    }
  });
});