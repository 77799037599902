define("copilot/routes/find", ["exports", "copilot/routes/authenticated-route", "ic-ajax"], function (exports, _authenticatedRoute, _icAjax) {
  /* eslint-env node */
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _authenticatedRoute.default.extend({
    analytics: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    v2Redirect: Ember.inject.service(),

    beforeModel: function () {
      var _ref = _asyncToGenerator(function* (transition) {
        var uri = transition.queryParams.uri;
        this.analytics.track("extension_button", "clicked", {
          link: uri
        });

        var data = yield (0, _icAjax.default)("/api/search?view=edit&display=all&uri=" + uri);

        if (data.hits.total) {
          var hit = data.hits.hits[0];
          var collectionName = hit._source.meta.collectionName;
          var brandCode = this.brandConfig.brand.activeBrand;

          var copilotPath = "/" + brandCode + "/" + collectionName + "/" + hit._id;

          var router = this._router._routerMicrolib;
          var handlers = router.recognizer.recognize(copilotPath);
          var leafRoute = handlers[handlers.length - 1];
          var content_type = { content_type: collectionName };

          // attempt v2 redirect (async) and ember redirect simultaneously
          // This is close enough to a real Transition object for this service
          this.v2Redirect.maybeRedirectTransition({
            targetName: leafRoute.handler,
            intent: { url: copilotPath },
            params: { contentEdit: content_type }
          });

          this.replaceWith(copilotPath);
        } else {
          this.replaceWith("search", {
            queryParams: { query: encodeURIComponent(uri) }
          });
        }
      });

      function beforeModel(_x) {
        return _ref.apply(this, arguments);
      }

      return beforeModel;
    }()
  });
});