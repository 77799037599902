define("copilot/components/copy-count/component", ["exports", "copilot/components/copy-count/template", "@condenast/copilot-markdown-shim", "@condenast/copilot-markdown-shim-sanitize"], function (exports, _template, _copilotMarkdownShim, _copilotMarkdownShimSanitize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _copilotMarkdownShim.default.use(_copilotMarkdownShimSanitize.default);

  var sanitize = _copilotMarkdownShim.default.sanitize;
  exports.default = Ember.Component.extend({
    classNames: ["copy-count"],
    layout: _template.default,

    characterCounter: Ember.computed("text", function () {
      var text = this.get("text");
      text = sanitize(text);
      return text.length;
    }),

    wordCounter: Ember.computed("text", function () {
      var text = this.get("text");
      text = sanitize(text, {
        stripEmbeds: true
      });

      return text.trim().split(" ").filter(function (word) {
        return word && word.length > 0;
      }).length;
    })
  });
});