define("copilot/components/md-textarea-field/component", ["exports", "@atjson/offset-annotations", "@atjson/source-gdocs-paste", "@atjson/renderer-commonmark", "string", "copilot/helpers/copilot-icon", "@condenast/md-editor", "copilot-components/mixins/form-inputs", "copilot/mixins/select-assets", "ember-autoresize/mixins/autoresize"], function (exports, _offsetAnnotations, _sourceGdocsPaste, _rendererCommonmark, _string, _copilotIcon, _mdEditor, _formInputs, _selectAssets, _autoresize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var GDOCS_FORMAT = "application/x-vnd.google-docs-document-slice-clip+wrapped";
  var PLAINTEXT_FORMAT = "text/plain";

  /**
   * An `md-textarea-field` component exposes a rich input field whose characters may be edited via
   * markdown. Markdown input added to the component may be previewed in its template mode. An optional
   * character or word count can be displayed to the user.
   *
   * This component can be rendered within the `copilot-form` component:
   *
   * ```hbs
   * {{#copilot-form model=model as |f|}}
   *   {{f.md-editor "mediaCaption" customLabel="Global Caption"
   *     placeholder="Enter a caption to live at the photo level"
   *     wordCount=true buttonConfig="caption" afmConfig="galleries.caption"}}
   * {{/copilot-form}}
   * ```
   *
   * This component can be used by itself, with the following template format:
   *
   * ```hbs
   * {{md-textarea-field "mediaCredit" model=model
   *   customLabel="Credit" buttonConfig="credit"}}
   * ```
   *
   * The invocation above will update the value of the model's `mediaCredit` property directly using
   * two-way bindings.
   *
   * @module copilot/app/components/md-textarea-field/component
   * @augments Ember.Component
   * @mixes copilot/mixins/copilot-form-inputs
   * @mixes copilot/app/mixins/select-assets
   * @param {String} field - The attribute on the model that represents the
   * text value. This is a positional param.
   * @param {(Object|Ember.Model)} model - The object to fetch the field from. Required when the
   * component is not invoked as a `copilot-form` contextual component.
   * @param {String} afmConfig - An optional parameter that functions as a key lookup for an array of content-types selectable
   * via content-selector. Refer to `https://github.com/CondeNast-Copilot/copilot/blob/main/docs/copilot-configurations.md`
   * for a more in-depth overview of copilot configurations.
   * @param {String} buttonConfig - An optional parameter that functions as a key lookup for an array of button actions
   * which should be available for this particular field. Defaults to the field's inner most nested key. In the cases when no configuration
   * is set for a particular (Eg: field = body) all buttons will be displayed.
   * Refer to `https://github.com/CondeNast-Copilot/copilot/blob/main/docs/copilot-configurations.md` for a more in-depth overview
   * of copilot configurations.
   * @param {Boolean} characterCount - An optional parameter to display a count of characters below the component's input field
   * @param {String} customLabel - An optional parameter to display custom title text at the top of the component's template
   * @param {string} placeholder - An optional parameter to display custom placeholder text within an empty unselected input field
   * @param {Boolean} wordCount - An optional parameter to display a count of words below the component's input field
   * @param {string} contextualHelpKey - An optional translation key to (translate and) render within a contextual-help tooltip
   */

  var MdEditor = Ember.Component.extend(_formInputs.default, _selectAssets.default, _autoresize.default, {
    classNames: ["form-group", "md-field-textarea"],
    analytics: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    features: Ember.inject.service(),
    intl: Ember.inject.service(),
    logger: Ember.inject.service(),
    mainModel: Ember.inject.service(),
    buttonsSet: false,
    isFocused: false,
    insertCallback: null,
    autoresize: true,
    shouldResizeHeight: true,
    inputId: null,
    hyperlinkData: null,
    buttonConfig: null,
    isDataConvertibleToRichText: false,
    hasStickyButtonBar: Ember.computed("currentPath", function () {
      var currentPath = Ember.getOwner(this).lookup("router:main").get("currentRouteName");
      return currentPath.indexOf("brand.livestories.index.livestoryupdates") > -1;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var id = "wmd-input" + this.elementId;
      this.set("inputId", id);
    },
    didRender: function didRender() {
      var _this2 = this;

      var field = this.field;

      var fieldValue = Ember.get(this, "model." + field);
      if (Ember.isNone(fieldValue)) {
        fieldValue = "";
      }
      this.set("autoResizeText", fieldValue + "@");
      Ember.run.next(function () {
        if (_this2.isDestroyed) {
          return;
        }
        _this2.measureSize();
      });
    },


    isBody: Ember.computed.equal("field", "body"),
    companyId: Ember.computed("configService.config.companyId", function () {
      return this.get("configService.config.companyId") || "cn";
    }),

    activeModel: Ember.computed.reads("mainModel.activeModel"),

    richTextStateKey: Ember.computed("model", "field", function () {
      if (!this.model.id) {
        return null; // the localstorage-get helper will always return null if the key is null;
      }

      return "copilot.ckeditorOnFor." + this.model.id + "." + this.field;
    }),

    explicitButtons: Ember.computed("field", "buttonConfig", function () {
      var field = this.buttonConfig || (this.field || "").split(".").get("lastObject");
      var type = this.get("model.type") || this.get("model.meta.modelName");
      var typeSpecificConfig = this.get("brandConfig.md.buttons." + type + "." + field);
      var buttonsConfig = this.get("brandConfig.md.buttons");
      var fieldConfig = buttonsConfig && buttonsConfig[field];
      var explicitButtons = typeSpecificConfig || fieldConfig;

      if (!Ember.isEmpty(explicitButtons)) {
        return explicitButtons.split(",");
      }
      return Ember.A();
    }),

    onPreviewRefresh: function onPreviewRefresh() {
      var _this3 = this;

      if (!this.isDestroyed) {
        var editorElt = this.$("#" + this.inputId);
        var value = Ember.$(editorElt).val();
        value = (0, _string.default)(value).stripTags().s;
        Ember.run(function () {
          var field = _this3.field;
          var modelProp = "model." + field;

          // Only set model prop if either model prop or val is not empty
          if (Ember.isPresent(value) || Ember.isPresent(_this3.get(modelProp))) {
            _this3.set(modelProp, value);
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this4 = this;

      var localeTranslations = this.intl.translationsFor(this.intl.locale);
      var editorTranslations = Ember.get(localeTranslations, "components.md-editor") || {};
      var markdownParser = Ember.getOwner(this).lookup("parser:markdown-preview");

      Ember.set(this, "autoresizeElement", this.$("textarea")[0]);

      this.editor = new _mdEditor.default.Editor({
        makeHtml: function makeHtml(text) {
          return markdownParser.parse(text);
        }
      }, this.elementId, {
        strings: editorTranslations
      });

      // Schedule during after render because setEditorHooks mutates the 'value' attr
      // of the textarea element, which should not occur during render.
      Ember.run.scheduleOnce("afterRender", this, function () {
        _this4.setEditorHooks();
      });

      // Unfortunately we need to ensure that render was successful prior to
      // running events agains the DOM element. There are error states that call
      // the didInsertElementhook but do not in fact have associated DOM nodes
      if (document.getElementById(this.elementId)) {
        this.editor.run();

        this.setButtons();

        if (this.isBody) {
          //TODO: ckung can i do this in a requestAnimiationFrame?
          this._stickyScroll = Ember.run.bind(this, function () {
            Ember.run.debounce(_this4, _this4._positionSticky, 5);
          });
          var scrollContainer = this.$().parents(".detail");
          scrollContainer.on("scroll", this._stickyScroll);
          Ember.$(window).on("resize mousewheel", this._stickyScroll);
          Ember.$(window).on("resize", function () {
            return _this4.scheduleMeasurement();
          });
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this5 = this;

      if (this._stickyScroll) {
        var scrollContainer = this.$().parents(".detail");
        scrollContainer.off("scroll", this._stickyScroll);
        Ember.$(window).off("resize mousewheel", this._stickyScroll);
        Ember.$(window).off("resize", function () {
          return _this5.scheduleMeasurement();
        });
      }
    },


    buttons: Ember.computed("explicitButtons", function () {
      var _this6 = this;

      /**
       * Required fields for a button configuration:
       *  - name: used for analytics (todo: research)
       *  - ckeditorPlugin: Class name of the plugin that provides this in ckeditor
       *  - ckeditorButton: name for this button in ckeditor toolbar config (uses the value of name if this is undefined)
       *  - id:   must correspond to a button in node_modules/@condenast/md-editor/Markdown.Editor.js
       *  - icon: must correspond to the filename of an asset in node_modules/@condenast/formation-styleguide/assets/icons
       * optional fields:
       *  - notInUse: if this is truthy, the button will be removed from all instances of this component
       */
      var btns = [{
        name: "bold",
        ckeditorPlugin: "Bold",
        id: "wmd-bold-button",
        icon: "bold"
      }, {
        name: "italic",
        ckeditorPlugin: "Italic",
        id: "wmd-italic-button",
        icon: "italic"
      }, {
        name: "underline",
        id: "wmd-underline-button",
        ckeditorPlugin: "Underline",
        icon: "underline"
      }, {
        name: "small-caps",
        id: "wmd-smallcaps-button",
        ckeditorPlugin: "SmallCaps",
        ckeditorButton: "smallcaps",
        icon: "smallcaps"
      }, {
        name: "strike",
        ckeditorPlugin: "Strikethrough",
        ckeditorButton: "strikethrough",
        id: "wmd-strike-button",
        icon: "strikethrough"
      }, {
        name: "subscript",
        ckeditorPlugin: "Subscript",
        id: "wmd-subscript-button",
        icon: "subscript"
      }, {
        name: "superscript",
        ckeditorPlugin: "Superscript",
        id: "wmd-superscript-button",
        icon: "superscript"
      }, {
        name: "link",
        ckeditorPlugin: "Link",
        id: "wmd-link-button",
        icon: "link"
      }, {
        name: "embed",
        id: "wmd-embed-button",
        icon: "embed",
        ckeditorPlugin: "Embed",
        ckeditorButton: "embed"
      }, {
        name: "upload",
        id: "wmd-upload-button",
        icon: "upload",
        ckeditorPlugin: "FileUpload",
        ckeditorButton: "fileUpload"
      }, {
        name: "pullquote",
        id: "wmd-pullquote-button",
        icon: "pullquote",
        ckeditorPlugin: "Pullquote",
        ckeditorButton: "pullquote",
        notInUse: !this.features.get("verso") && !this.features.get("rocket") && !this.features.get("ckeditorPullquotes")
      }, {
        name: "quote",
        ckeditorPlugin: "BlockQuote",
        ckeditorButton: "blockquote",
        id: "wmd-quote-button",
        icon: "blockquote"
      }, {
        name: "code",
        ckeditorPlugin: "Code",
        id: "wmd-code-button",
        icon: "code",
        notInUse: true
      }, {
        name: "image",
        id: "wmd-image-button",
        icon: "picture",
        notInUse: true
      }, {
        name: "olist",
        ckeditorPlugin: "List",
        ckeditorButton: "numberedList",
        id: "wmd-olist-button",
        icon: "orderedlist"
      }, {
        name: "ulist",
        ckeditorPlugin: "List",
        ckeditorButton: "bulletedList",
        id: "wmd-ulist-button",
        icon: "unorderedlist"
      }, {
        name: "heading",
        ckeditorPlugin: "Heading",
        id: "wmd-heading-button",
        icon: "heading"
      }, {
        name: "hr",
        id: "wmd-hr-button",
        icon: "hr",
        ckeditorPlugin: "HorizontalLine",
        ckeditorButton: "horizontalLine"
      }, {
        name: "undo",
        ckeditorPlugin: "Undo",
        id: "wmd-undo-button",
        icon: "undo",
        notInUse: true
      }, {
        name: "redo",
        id: "wmd-redo-button",
        icon: "share-alt",
        notInUse: true
      }, {
        name: "asset-meta",
        ckeditorPlugin: "ContentSelector",
        ckeditorButton: "contentSelector",
        id: "wmd-asset-button",
        icon: "afm"
      }, {
        name: "callout",
        id: "wmd-callout-button",
        icon: "callout"
      }, {
        name: "section",
        id: "wmd-section-button",
        icon: "section",
        ckeditorPlugin: "SectionBreak",
        ckeditorButton: "sectionBreak"
      }, {
        name: "newline",
        ckeditorPlugin: "ShiftEnter",
        id: "wmd-newline-button",
        icon: "newline"
      }, {
        name: "group",
        ckeditorPlugin: "Group",
        notInUse: false,
        ckeditorOnly: true
      }, {
        name: "slideshow",
        ckeditorPlugin: "Slideshow",
        ckeditorButton: "slideshow",
        notInUse: !this.features.get("ckeditorSlideshow"),
        ckeditorOnly: true
      }, {
        name: "dropCap",
        ckeditorPlugin: "ParagraphStyles",
        ckeditorButton: "dropCap",
        notInUse: !this.features.get("ckeditorDropCap"),
        ckeditorOnly: true
      }, {
        name: "leadIn",
        ckeditorPlugin: "ParagraphStyles",
        ckeditorButton: "leadIn",
        notInUse: !this.features.get("ckeditorLeadIn"),
        ckeditorOnly: true
      }, {
        name: "alignment:left",
        ckeditorPlugin: "Alignment",
        ckeditorButton: "alignment:left",
        notInUse: !this.features.get("ckeditorTextAlignment"),
        ckeditorOnly: true
      }, {
        name: "alignment:center",
        ckeditorPlugin: "Alignment",
        ckeditorButton: "alignment:center",
        notInUse: !this.features.get("ckeditorTextAlignment"),
        ckeditorOnly: true
      }, {
        name: "alignment:right",
        ckeditorPlugin: "Alignment",
        ckeditorButton: "alignment:right",
        notInUse: !this.features.get("ckeditorTextAlignment"),
        ckeditorOnly: true
      }, {
        name: "sidebar",
        ckeditorPlugin: "Sidebar",
        notInUse: !this.features.get("ckeditorSidebar"),
        ckeditorOnly: true
      }, {
        name: "anchor",
        ckeditorPlugin: "Anchor",
        notInUse: false,
        ckeditorOnly: true
      }, {
        name: "button",
        ckeditorPlugin: "Button",
        notInUse: false,
        ckeditorOnly: true
      }, {
        name: "internallink",
        ckeditorPlugin: "InternalLink",
        ckeditorOnly: true,
        notInUse: !this.features.get("enableLinkStack")
      }, {
        name: "bodystyle",
        ckeditorPlugin: "BodyStyle",
        ckeditorOnly: true
      }].map(function (config) {
        var explicitBtns = _this6.explicitButtons;
        if (explicitBtns.length) {
          config.notInUse = config.notInUse || !explicitBtns.any(function (x) {
            return x === config.name;
          });
        }

        return config;
      });

      return btns;
    }),

    setButtons: function setButtons() {
      if (!this.buttonsSet) {
        var buttonRow = this.$().find("#wmd-button-row" + this.elementId);
        var _this = this;
        buttonRow.addClass("markdown-buttonbar").find("li").css("left", 0).find("span").hide();

        var addButton = function addButton(row, item, button) {
          var $button = row.find("#" + item.id + _this.elementId).append(button.toString());
          if (_this.get("companyId") !== "cni") {
            $button.click(function () {
              _this.get("analytics").track("field " + _this.get("field"), "formatted", {
                formatType: item.name
              }, {
                label: "formatType"
              });
            });
          }
        };

        this.buttons.forEach(function (item) {
          if (!item.notInUse && !item.ckeditorOnly) {
            addButton(buttonRow, item, (0, _copilotIcon.copilotIcon)(item.icon));
          } else {
            buttonRow.find("#" + item.id + _this.elementId).remove();
          }
        });

        buttonRow.find(".wmd-spacer").next(".wmd-spacer").remove();
        this.set("buttonsSet", true);
      }
    },


    buttonBarId: Ember.computed("elementId", function () {
      return "wmd-button-bar" + this.elementId;
    }),

    previewId: Ember.computed("elementId", function () {
      return "wmd-preview" + this.elementId;
    }),

    _positionSticky: function _positionSticky() {
      if (this.isDestroyed || !this.hasStickyButtonBar) {
        return;
      }

      var $buttonBar = this.$(".button-bar");
      var $component = this.$();

      //Get the height of header
      var nav = Ember.$(".new-top-nav");
      var navHeight = nav ? nav.outerHeight() : 0;
      var envBanner = Ember.$(".environment");
      var envBannerHeight = envBanner ? envBanner.outerHeight() : 0;
      var actionBarHeight = Ember.$("#action-bar").outerHeight();
      var topOffset = navHeight + envBannerHeight + actionBarHeight;

      var bottomLimit = $buttonBar.outerHeight() + topOffset;
      var top = $component.offset().top;
      var bottom = $component.outerHeight() + top;

      var shouldStickNow = top < topOffset && bottom > bottomLimit && this.$(".wmd-textarea").length > 0;

      if (shouldStickNow === this.sticky) {
        return;
      }

      this.set("sticky", shouldStickNow);

      if (!shouldStickNow) {
        this.$(".button-bar").removeClass("fixed").removeAttr("style");
        this.$(".md-input-area").removeAttr("style");
      }

      if (shouldStickNow) {
        this.$(".button-bar").addClass("fixed").css({
          top: topOffset,
          width: $component.width()
        });
      }
    },
    gdocsToMarkdown: function gdocsToMarkdown(gdocsText) {
      var raw = JSON.parse(gdocsText);
      var data = raw.data && JSON.parse(raw.data);

      if (!data) {
        return;
      }

      var gdocs = _sourceGdocsPaste.default.fromRaw(data).convertTo(_offsetAnnotations.default);
      return _rendererCommonmark.default.render(gdocs);
    },
    setEditorHooks: function setEditorHooks() {
      var _this7 = this;

      // Custom insert link dialog
      this.editor.hooks.set("insertLinkDialog", function (callback) {
        var highlighted = _this7.editor.getTextState().selection;
        var data = {
          linkText: highlighted,
          linkUrl: "",
          callback: callback
        };
        _this7.set("hyperlinkData", data);
        return true;
      });

      this.editor.hooks.set("insertAssetDialog", function (callback) {
        _this7.set("isSelectingAssets", true);
        _this7.set("assetCallback", callback);
        return true;
      });

      this.editor.hooks.set("insertUploadToBodyDialog", function (callback) {
        _this7.set("isUploadingToBody", true);
        _this7.set("assetCallback", callback);
        return true;
      });

      this.editor.hooks.set("insertEmbedDialog", function (callback) {
        Ember.run(function () {
          _this7.set("isEmbedding", true);
          _this7.set("embedCallback", callback);
        });
        return true;
      });

      // Custom insert callout dialog
      this.editor.hooks.set("insertCalloutDialog", function (callback) {
        _this7.set("isAddingCallout", true);
        _this7.set("calloutCallback", callback);
        return true;
      });

      this.editor.hooks.set("onPreviewRefresh", this.onPreviewRefresh.bind(this));

      // Custom paste behavior
      this.editor.hooks.set("getPasteText", function (event) {
        var pasteEvent = event && event.originalEvent || event;
        var text = void 0;
        var gdocsText = pasteEvent.clipboardData.getData(GDOCS_FORMAT);
        if (gdocsText) {
          text = _this7.gdocsToMarkdown(gdocsText);
        } else {
          text = pasteEvent.clipboardData.getData(PLAINTEXT_FORMAT);
        }
        return text;
      });
    },
    setPreviewHeight: function setPreviewHeight() {
      var textarea = this.$("#wmd-input" + this.elementId);
      var preview = this.$("#wmd-preview" + this.elementId);

      preview.height(textarea.height() + 6);
    },
    setLinksTargetBlank: function setLinksTargetBlank() {
      this.$(".md-preview").find("a").attr("target", "_blank");
    },
    trackViewmode: function trackViewmode(previewing) {
      var modeName = previewing ? "preview" : "markdown";
      this.analytics.track("field " + this.field, "viewmode chosen", {
        mode: modeName
      }, {
        label: "mode"
      });
    },


    actions: {
      inputChange: function inputChange(value, modelGuid) {
        var _this8 = this;

        // allows callers to verify that the model they were trying to change
        // is the same model we will *actually* change
        if (modelGuid && Ember.guidFor(this.model) !== modelGuid) {
          return;
        }

        Ember.run(function () {
          var field = _this8.field;
          _this8.set("model." + field, value);
        });
      },
      setUnsavedChanges: function setUnsavedChanges() {
        var dirtyAttrs = this.model._dirtyAttributes;
        if (dirtyAttrs.includes(this.field) === false) {
          dirtyAttrs.push(this.field);
        }
      },
      updateTrackingData: function updateTrackingData(editor) {
        var textInputTrackingData = void 0;

        if (this.isFocused) {
          textInputTrackingData = {
            textInput: {
              field: this.field,
              type: editor
            }
          };
        } else {
          textInputTrackingData = {
            textInput: null
          };
        }

        Ember.tryInvoke(this, "updateTrackingData", [textInputTrackingData]);
      },
      showBar: function showBar() {
        this.set("showButtons", true);
      },
      hideBar: function hideBar() {
        this.set("showButtons", false);
      },
      edit: function edit() {
        var track = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        this.set("autoresize", true);
        this.set("autoResizeText", this.get("model." + this.field));
        this.set("showPreview", false);
        if (track) {
          this.trackViewmode(false);
        }
      },
      preview: function preview() {
        this.set("autoresize", false);
        this.set("showPreview", true);
        this.setPreviewHeight();
        this.editor.refreshPreview();
        this.setLinksTargetBlank();
        this.trackViewmode(true);
      },
      submitHyperlink: function submitHyperlink(data) {
        var linkUrl = data.linkUrl,
            linkText = data.linkText,
            attributes = data.attributes,
            callback = data.callback;

        callback(linkUrl, linkText, attributes);
        this.set("hyperlinkData", null);
      },
      setEditorType: function setEditorType(currentEditor) {
        this.set("currentEditor", currentEditor);
      },
      smartFill: function smartFill(value) {
        var _this9 = this;

        this.analytics.track("content", "generated", {
          contentType: this.model.type,
          field: this.field
        }, {
          label: "field"
        });

        Ember.run(function () {
          var field = _this9.field;
          _this9.set("model." + field, value);
        });
      }
    }
  });

  MdEditor.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = MdEditor;
});