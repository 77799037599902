define("copilot/routes/category-manager/category/edit", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    model: function model() {
      return this.modelFor("category-manager.category");
    }
  });
});