define("copilot/components/category-manager/child-usage/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["usage"],
    didReceiveAttrs: function didReceiveAttrs() {
      this.get("getUsageCount").perform();
    },

    query: Ember.computed("model", function () {
      return {
        q: "relid:" + this.get("model.id")
      };
    }),
    searchTerm: Ember.computed.reads("query.q"),
    getUsageCount: (0, _emberConcurrency.task)(function* () {
      try {
        this.set("hasError", false);
        var count = yield this.get("countUsage")(this.get("query"));
        this.set("count", count);
      } catch (err) {
        this.set("hasError", true);
      }
    })
  });
});