define("copilot/services/publish", ["exports", "ember-inflector", "ic-ajax"], function (exports, _emberInflector, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    consoleLogger: Ember.inject.service(),
    saveService: Ember.inject.service("save"),

    getDefaultDistributionChannel: function getDefaultDistributionChannel() {
      return {
        name: "web-owned-and-operated",
        metadata: {
          searchable: true,
          recommendable: true
        }
      };
    },


    /**
     * Publish this revision with options.
     * @param {Object} pubData properties: distributionChannels, uri, pubDate, revisionAuthor, searchable, slug, template
     * @returns {Promise}
     */
    publish: function publish(model, pubData) {
      var _this = this;

      var recommendable = pubData.recommendable,
          data = _objectWithoutProperties(pubData, ["recommendable"]);

      if (Ember.isEmpty(data.distributionChannels)) {
        var searchable = !(data.searchable === false);
        recommendable = !(recommendable === false);
        data.distributionChannels = [Object.assign({}, this.getDefaultDistributionChannel(), {
          metadata: {
            searchable: searchable,
            recommendable: recommendable
          }
        })];
      }

      var _model$getProperties = model.getProperties(["id", "type"]),
          id = _model$getProperties.id,
          type = _model$getProperties.type;

      var url = "/api/" + (0, _emberInflector.pluralize)(type) + "/" + id + "/publish";
      var publishing = (0, _icAjax.default)(url, {
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json"
      });
      publishing.then(function (data) {
        var serializer = _this.get("store").serializerFor(type);
        var normalizedData = serializer.normalize(model.constructor, data);
        model.load(normalizedData.id, normalizedData);
        _this._trackAnalytics("published", { contentType: type });
      }, function (error) {
        var response = error.jqXHR.responseJSON;
        _this.get("consoleLogger").warn("Error publishing: " + response.statusCode + ": " + response.message);
      });
      return publishing;
    },


    /**
     * If a record param is passed in, expire the specific record's revision, otherwise,
     * expire the entire content and update the model with the new data
     */
    unpublish: function () {
      var _ref = _asyncToGenerator(function* (model, record) {
        var _this2 = this;

        var _model$getProperties2 = model.getProperties(["id", "type", "isGlobalDraft"]),
            id = _model$getProperties2.id,
            type = _model$getProperties2.type,
            isGlobalDraft = _model$getProperties2.isGlobalDraft;

        var pluralizedType = (0, _emberInflector.pluralize)(type);
        var revisionSlug = record ? "/revisions/" + record.revision : "";
        var url = "/api/" + pluralizedType + "/" + id + revisionSlug + "/expire";
        if (isGlobalDraft && !revisionSlug) {
          model.set("isGlobalDraft", false);
          yield this.saveService.save(model);
        }
        var expiring = (0, _icAjax.default)(url, {
          type: "POST",
          data: {}
        });
        var analyticsProperties = { contentType: type };
        if (record) {
          analyticsProperties.revision = record.revision;
        }
        return expiring.then(function (data) {
          var serializer = _this2.get("store").serializerFor(type);
          var normalizedData = serializer.normalize(model.constructor, data);
          model.load(normalizedData.id, normalizedData);
          _this2._trackAnalytics("unpublished", analyticsProperties);
        }).catch(function (err) {
          var response = err.jqXHR.responseJSON;
          _this2.get("consoleLogger").warn("Error unpublishing " + revisionSlug + ": " + response.statusCode + ": " + response.message);
        });
      });

      function unpublish(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return unpublish;
    }(),
    _trackAnalytics: function _trackAnalytics(action, props) {
      this.get("analytics").track("content", action, props);
    }
  });
});