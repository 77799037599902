define("copilot/components/promo-experiment/experiment-sidebar/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    displayTerminateConfirmationDialog: false,

    actions: {
      enableExperimentToggleChanged: function enableExperimentToggleChanged() {
        if (this.shouldShowTerminateConfirmationDialog) {
          this.set("displayTerminateConfirmationDialog", true);
        } else {
          this.toggleEnableExperiment();
        }
      },
      terminateConfirmationDialogSubmit: function terminateConfirmationDialogSubmit() {
        this.set("displayTerminateConfirmationDialog", false);
        this.submitTerminateConfirmationDialog();
      },
      terminateConfirmationDialogCancel: function terminateConfirmationDialogCancel() {
        this.set("displayTerminateConfirmationDialog", false);
      }
    }
  });
});