define("copilot/brands/wrdit/serializers/review", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.itemsReviewed = json.itemsReviewed.map(function (item) {
        item.relId = item.embedded.id;
        return item;
      });
      return json;
    }
  });
});