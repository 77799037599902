define("copilot/schema-helpers/autogen-ingredient-description", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["unitQuantity", "unitSize", "unit", "ingredientSize", "name", "treatment", "note"]),
    compute: function compute(model) {
      if (model.name) {
        var tail = [model.name, model.treatment, model.note].filter(function (attr) {
          return !!attr;
        }).join(", ");
        var description = [model.unitQuantity, model.unitSize, model.unit, model.ingredientSize, tail].filter(function (attr) {
          return !!attr;
        }).join(" ");
        return _copilotMarkdown.default.sanitize(description);
      }
    }
  });
});