define("copilot/validations/brands/vogue/agency", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var agencyValidators = object({
    city: (0, _copilotValidators.length)({ min: 1 })
  });

  exports.default = (0, _crossCheckDsl.default)(agencyValidators);
});