define("copilot/brands/vogue/components/fashiongallery-item-header/component", ["exports", "copilot/components/card-list/card-item-header/component", "lodash-es"], function (exports, _component, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ["fashiongallery-item-header"],
    classNameBindings: ["inactive"],
    photo: Ember.computed("model", "model.embeddePhoto", function () {
      return this.get("model.embeddedPhoto") || this.model;
    }),
    isActive: Ember.computed.reads("model.isActive"),

    position: Ember.computed("index", "model.id", "activeIds.[]", function () {
      return this.activeIds ? this.activeIds.indexOf(this.get("model.id")) + 1 : this.index + 1;
    }),

    inactive: Ember.computed("isActive", function () {
      if (_lodashEs.default.isFunction(this.toggleActive)) {
        return !this.isActive;
      }
      return false;
    }),

    toggleActiveTitle: Ember.computed("inactive", function () {
      return this.inactive ? "Un-Hide" : "Hide";
    }),

    actions: {
      toggleActive: function toggleActive() {
        var item = this.model;
        this.toggleActive(item);
      }
    }
  });
});