define("copilot/brands/vogue/mixins/vogue-slug", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    slugFor: function slugFor(prop) {
      var objectPath = "fashionshow." + prop + ".firstObject._embedded.publishHistory.uri";
      return this.get(objectPath);
    },


    seasonSlug: Ember.computed("fashionshow", function () {
      var extendedSlug = this.slugFor("season");
      return extendedSlug && extendedSlug.replace("fashion-shows/", "");
    }),

    brandSlug: Ember.computed("fashionshow", function () {
      var extendedSlug = this.slugFor("brand");
      return extendedSlug && extendedSlug.replace("brands/", "");
    })
  });
});