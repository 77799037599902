define("copilot/components/category-selector/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    classNames: ["category-selector"],
    categoryService: Ember.inject.service("category"),

    filterCategories: function filterCategories(categories, notHierarchies) {
      return categories.filter(function (cat) {
        return notHierarchies.every(function (hierarchy) {
          var notHierarchyString = hierarchy.endsWith("/") ? hierarchy : hierarchy + "/";
          return !(cat.hierarchyString.startsWith(notHierarchyString) || (cat.hierarchyString + "/").startsWith(notHierarchyString));
        });
      });
    },


    shouldShowSelf: Ember.computed("roots", function () {
      // Nothing to search if no root configs so don't render anything
      var atleastOneRoot = this.categoryService.configuredRoots.length > 0;
      // The component can over ride what category and its children to display.
      // Thus only hide this component if the component was never asked to check specific roots
      var definedRootsPassedIn = !!this.roots;
      var shouldDisplay = atleastOneRoot || definedRootsPassedIn;

      return shouldDisplay;
    }),

    errorMessages: Ember.computed("validationMessages", function () {
      return (this.validationMessages || []).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            message = _ref2[1];

        return message;
      });
    })
  });
});