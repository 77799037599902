define("copilot/models/validation-error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    name: null,

    /**
     Message format options.
       For example:
       error = ValidationError.create({
            messageFormat: { 'length': '8' }
          });
      error.get('message') // 'should have at least 8 characters'
      *@property {Object}
      */
    messageFormat: null
  });
});