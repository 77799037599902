define("copilot/brands/cnt/models/bar", ["exports", "copilot/brands/cnt/models/venue", "copilot/models/attr"], function (exports, _venue, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  var str = _attr.default.str;


  var Bar = _venue.default.extend({
    editorsPick: attr(Boolean),
    hours: attr(),
    subtype: attr(str),
    type: "bar"
  });

  Bar.reopenClass({
    url: "/api/bars"
  });

  exports.default = Bar;
});