define("copilot/routes/presets/index", ["exports", "copilot/routes/authenticated-route", "ember-service-methods", "copilot/mixins/secure-preset"], function (exports, _authenticatedRoute, _emberServiceMethods, _securePreset) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_securePreset.default, {
    search: (0, _emberServiceMethods.inject)(),
    user: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var requiredPermissions = [_securePreset.presetPermissionActions.read];
      this.redirectIfUserCantUsePresets(transition, requiredPermissions);
    },


    actions: {
      navigateToCreatePreset: function navigateToCreatePreset() {
        var currentBrand = this.brandConfig.brand.activeBrand;

        this.transitionTo("presets.create", currentBrand);
      }
    }
  });
});