define("copilot/serializers/categories/map-to-array", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    /**
     * Transform map of roots into a flat array of categories
     * @param {Object} output The form is a hierarchy of category root names with arrays of category objects.
     * @returns {Array}
     */
    serialize: function serialize(output) {
      var roots = Object.keys(output);
      return roots.reduce(function (acc, rootName) {
        return acc.concat(output[rootName]);
      }, []);
    },


    /**
     * Transform array of categories coming from a model to a map by roots
     * @param {Array} input Array of categories
     * @returns {Promise} resolves to Object in the form of {channels: [], tags: [], ... }
     */
    deserialize: function deserialize() {
      var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      return input.reduce(function (output, category) {
        var rootSlug = category.get("rootSlug");
        if (output[rootSlug]) {
          output[rootSlug].push(category);
        } else {
          output[rootSlug] = [category];
        }
        return output;
      }, {});
    }
  });
});