define("copilot/components/card-list/component", ["exports", "copilot-components/mixins/form-inputs"], function (exports, _formInputs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function containsItem(item) {
    var field = this.field;
    var id = Ember.get(item, "id") || Ember.get(item, "embedded.id");
    var exists = this.get("model." + field).isAny("id", id) || this.get("model." + field).any(function (item) {
      return Ember.get(item, "embedded.id") === id;
    });
    return exists;
  }

  /**
   * A component for selecting content items to add to a collection
   *
   * The card-list component allows the user to manage a collection of rels for
   * a given collection attribute on a content item. For example, managing items
   * of content related to the current content item, as the `related` property of
   * that model.
   *
   * ```hbs
   * {{card-list "related" model=model
   *   afmConfigType="articles.related"
   *   limit=1
   *   afterSelection=(action "selectedItem")}}
   * ```
   *
   * @module copilot/components/card-list/component
   * @augments Ember.Component
   * @mixes copilot/mixins/copilot-form-inputs
   * @param {String} field - The attribute that represents the collection on the model. This is a positional param.
   * @param {(Object|Ember.Model)} model - The model whose 'field' attribute the collection lives on
   * @param {String} afmConfigType - The path under which the correct configuration for the content selector resides in the brand config
   * @param {Number} limit - An optional limit to the number of items that may be added to the collection
   * @param {Function} afterSelection - An optional closure action to be called after a selection has been made
   * @param {Boolean} disableSorting - An optional flag to disable sorting of the collection. Defaults to false
   * @param {(Ember.Model|Ember.Object)} itemModel - An optional model class that all members of the collection should be instances of
   */
  var CardList = Ember.Component.extend(_formInputs.default, {
    /**
     * Injected userService
     *
     * @type {module:app/services/user}
     */
    brand: Ember.inject.service(),
    intl: Ember.inject.service(),

    classNameBindings: ["limitClass:single-item"],

    /** @type {String[]} */
    classNames: ["form-group", "card-list"],

    /**
     * Whether the sortable behaviour of the component should be disabled
     *
     * @type {Boolean}
     * @default false
     */
    disableSorting: false,

    openInNewTab: true,

    activeBrand: Ember.computed.reads("brand.activeBrand"),
    limitClass: Ember.computed.equal("limit", 1),

    isSpecialList: Ember.computed("itemModel", function () {
      return this.itemModel === "nocontext-list-item";
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.warnings = [];
      var field = this.field;
      Ember.defineProperty(this, "hideAddItem", Ember.computed("limit", "model." + field + ".length", function () {
        return this.limit <= this.get("model." + field + ".length");
      }));
      Ember.defineProperty(this, "excludeIds", Ember.computed("model." + field + ".[]", function () {
        var field = this.field;
        var collection = this.get("model." + field);
        return collection && collection.toArray().map(function (item) {
          return Ember.get(item, "embedded.id");
        });
      }));
    },
    appendItems: function appendItems(items) {
      Ember.run(this, function () {
        var _this = this;

        var field = this.field;
        if (this.limit === 1) {
          if (this.get("model." + field + ".removeObject")) {
            this.get("model." + field).clear();
            this.get("model." + field).pushObject(items[0]);
          } else {
            this.model.set(field, items[0]);
          }
        } else {
          if (Array.isArray(items) && items.some(function (item) {
            return containsItem.call(_this, item);
          })) {
            this.warnings.pushObject("Duplicate items cannot be added.");
          } else {
            Array.isArray(items) && this.get("model." + field).pushObjects([].concat(_toConsumableArray(items)));
          }
        }
      });
    },


    // Currently these actions all mutate the passed in model data, and therefore
    // don't adhere to a Data Down Actions Up Pattern. However it seems that the
    // cost of implementing these actions across all the contexts where the
    // card-list is invoked would be too high, at least for now. Probably need
    // some kind of stand-alone Data service or Flux-like store in order for that
    // to be feasible.
    actions: {
      sortItems: function sortItems(oldIndex, newIndex) {
        var field = this.field;
        var items = this.get("model." + field);
        var item = items.objectAt(oldIndex);
        items.removeAt(oldIndex);
        items.insertAt(newIndex, item);
      },
      removeItem: function removeItem(item) {
        this.warnings.clear();
        var field = this.field;
        if (this.get("model." + field).removeObject) {
          this.get("model." + field).removeObject(item);
        } else {
          this.model.set(field, undefined);
        }
      },
      submitEdits: function submitEdits(newItem) {
        var model = this.model;
        Ember.setProperties(model, newItem);
      },
      addItems: function addItems(items) {
        this.warnings.clear();
        this.appendItems(items);
        if (this.afterSelection) {
          this.afterSelection();
        }
      }
    }
  });

  CardList.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = CardList;
});