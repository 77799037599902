define("copilot/brands/p4k/models/musicgroup", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var array = _attr.default.array,
      str = _attr.default.str;


  var MusicGroup = _base.default.extend({
    type: "musicgroup",

    // model fields
    name: (0, _emberModel.attr)(str),
    disambiguatingDescription: (0, _emberModel.attr)(str),
    description: (0, _emberModel.attr)(str),

    related: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),

    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),

    socialMedia: (0, _emberModel.hasMany)("social-media", {
      embedded: "always"
    }),

    tags: (0, _emberModel.attr)(array),

    thirdPartyMedia: (0, _emberModel.hasMany)("third-party-media", {
      embedded: "always"
    }),
    searchTitle: Ember.computed.reads("name")
  });

  MusicGroup.reopenClass({
    url: "/api/musicgroups"
  });

  exports.default = MusicGroup;
});