define("copilot/helpers/language-flags", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 2),
          model = _ref2[0],
          translations = _ref2[1];

      // Get brand default language
      var defaultLanguage = model.configService.config.language.default;

      // Get content language
      var contentLanguage = model.lang;

      // Show language flags only if translations are available
      if (translations !== undefined && translations.length > 0) {
        // Add current model language (if different from the brand default)
        var translated = contentLanguage !== defaultLanguage ? this.intl.t("languages." + contentLanguage) : '';

        // Loop over translations and add models language (if different from the brand default)
        translations.forEach(function (translation) {
          if (translation.fields.lang !== defaultLanguage) {
            var language = _this.intl.t("languages." + translation.fields.lang);
            translated = translated + " " + language;
          }
        });
        return translated;
      }

      return '';
    }
  });
});