define("copilot/lib/copilot-deprecate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = copilotDeprecate;
  function copilotDeprecate(caller) {
    if (!caller.isCopilotForm) {
      var parts = caller.toString().split("@");
      var componentName = parts[1].split(":")[1];
      Ember.deprecate("The component " + componentName + " is deprecated. Please use the copilot-form implementation of this component instead", false, {
        id: "components." + componentName,
        until: "3.0.0"
      });
    }
  }
});