define("copilot/validations/brands/epi/curatedlist", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var curatedlistValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.CuratedlistValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["channel"])).catch((0, _crossCheckDsl.mutePath)(["photosTout"]));

  exports.default = curatedlistValidationBuilder;
});