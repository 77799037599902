define("copilot/brands/vogue/controllers/fashiongalleries/runwaymoment", ["exports", "copilot/controllers/galleries/child", "ember-inflector", "copilot/brands/vogue/mixins/vogue-gallery-child", "copilot/brands/vogue/mixins/multiple-model-controller"], function (exports, _child, _emberInflector, _vogueGalleryChild, _multipleModelController) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _child.default.extend(_vogueGalleryChild.default, _multipleModelController.default, {
    isSelectingDetails: false,
    mainModelPath: "model.runwaymoment",
    mainModelType: "runwaymoment",
    filename: Ember.computed.reads("model.photo.filename"),
    id: Ember.computed.reads("model.runwaymoment.id"),
    photoId: Ember.computed.reads("model.photo.id"),
    photoModel: Ember.computed.reads("model.photo"),
    selected: Ember.computed.reads("fashiongallery.currentSelection"),
    modelsAreDirty: Ember.computed.or("model.runwaymoment.isDirty", "model.photo.isDirty"),

    modelsToSave: ["model.runwaymoment", "model.photo"],

    detailIds: Ember.computed("model.runwaymoment.details.[]", function () {
      var details = this.get("model.runwaymoment.details");
      return details && details.mapBy("id");
    }),

    // Re-implement pluralized type to because the model actually contains
    // an object with two models: runwaymoment and photo (runwaymoment is the
    // main model)
    pluralizedType: Ember.computed("model.runwaymoment.type", function () {
      return (0, _emberInflector.pluralize)(this.get("model.runwaymoment.type"));
    }),

    actions: {
      sortDetails: function sortDetails(oldIndex, newIndex) {
        var items = this.get("model.runwaymoment.details");
        var item = items.objectAt(oldIndex);
        items.removeAt(oldIndex);
        items.insertAt(newIndex, item);
      },
      removeDetail: function removeDetail(obj) {
        this.get("model.runwaymoment.details").removeObject(obj);
      },
      addDetails: function addDetails(details) {
        this.set("isSelectingDetails", false);
        this.get("model.runwaymoment.details").addObjects(details);
      }
    },

    validateForSave: function validateForSave() {
      var validationService = this.validation;
      return validationService.validate(this.get("model.runwaymoment"), "save");
    }
  });
});