define("copilot/components/csv-upload/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    windowOrigin: Ember.computed(function () {
      return window.location.protocol + "//" + window.location.host;
    }),

    isValidating: Ember.computed.reads("validationTask.isRunning"),

    isSaving: Ember.computed("saveTasks.@each.isRunning", function () {
      return (this.saveTasks || []).some(function (task) {
        return task.isRunning;
      });
    }),

    isFinished: Ember.computed("isValidating", "isSaving", function () {
      return !(this.isValidating || this.isSaving);
    }),

    isError: Ember.computed("validationErrors", "saveErrors", function () {
      return Ember.isPresent(this.validationErrors) || Ember.isPresent(this.saveErrors);
    }),

    isSuccess: Ember.computed("isFinished", "isError", function () {
      return this.isFinished && !this.isError;
    }),

    isPublished: Ember.computed("isFinished", "isError", "isPublished", function () {
      return this.isFinished && !this.isError && this.isPublished;
    }),

    isPublishFailed: Ember.computed("isFinished", "isPublishFailed", "isPublished", function () {
      return this.isFinished && !this.isPublished && this.isPublishFailed;
    }),

    saveErrors: Ember.computed.filterBy("saveTasks", "isError", true),

    saveSuccesses: Ember.computed.filterBy("saveTasks", "isSuccessful", true),

    doneWithPublish: Ember.computed("saveTasks.@each.publishingCompleted", function () {
      return (this.saveTasks || []).some(function (task) {
        return task.publishingCompleted;
      });
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set("validationErrors", []);
      this.set("validationTask", this.file.validate.perform());

      Ember.run(function () {
        _this.validationTask.then(function () {
          _this.set("saveTasks", _this.file.save());
        }).catch(function (validationErrors) {
          _this.set("validationErrors", validationErrors);
        });
      });
    }
  });
});