define("copilot/validations/sitesetting", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  function subtypeNotEmpty() {
    return object({
      subtypes: (0, _copilotValidators.array)(object({
        type: (0, _copilotValidators.required)().catch(function () {
          return [{ path: [], message: { name: "save-empty-type" } }];
        })
      }))
    });
  }

  function containerNotEmpty() {
    return object({
      containers: (0, _copilotValidators.array)(object({
        value: (0, _copilotValidators.required)(),
        type: (0, _copilotValidators.required)()
      }).catch(function () {
        return [{ path: [], message: { name: "save-empty-container" } }];
      }))
    });
  }

  function subtypeUnique() {
    return object({
      subtypes: (0, _copilotValidators.uniqueBy)("type").catch(function () {
        return [{ path: [], message: { name: "save-duplicate-type" } }];
      })
    });
  }

  function containerUnique() {
    return object({
      containers: (0, _copilotValidators.uniqueBy)("type").andAlso((0, _copilotValidators.uniqueBy)("value")).catch(function () {
        return [{ path: [], message: { name: "save-duplicate-container" } }];
      })
    });
  }

  function blank(path) {
    return {
      path: [path],
      message: {
        name: "blank",
        details: null
      }
    };
  }

  class PublishConfigurationValidator extends _crossCheckDsl.BasicValidator {
    validate(value) {
      var get = this.env.get;

      var errors = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = value[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var contentType = _step.value;

          var publish = get(contentType, "publish");
          if (publish != null) {
            if (publish.uriMap == null) {
              errors.push(blank("publish.uriMap"));
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return errors;
    }
  }

  PublishConfigurationValidator.validatorName = "publish-config-valid";

  var isPublishConfigValid = (0, _crossCheckDsl.builderFor)(PublishConfigurationValidator);

  var sitesettingValidators = object({
    hostnames: object({
      preview: (0, _copilotValidators.required)().andThen((0, _copilotValidators.url)("relative")),
      consumer: (0, _copilotValidators.required)().andThen((0, _copilotValidators.url)("relative"))
    }),
    bundles: (0, _copilotValidators.optional)(subtypeNotEmpty().andAlso(containerNotEmpty()).andAlso(subtypeUnique()).andAlso(containerUnique())),
    channels: (0, _copilotValidators.uniqueBy)("label").catch(function () {
      return [{ path: [], message: { name: "save-duplicate-channels" } }];
    }).andAlso((0, _copilotValidators.array)(object({
      subchannels: (0, _copilotValidators.optional)((0, _copilotValidators.uniqueBy)("label").catch(function () {
        return [{ path: [], message: { name: "save-duplicate-subchannels" } }];
      }))
    }))),
    contentTypes: (0, _copilotValidators.array)(object({
      label: (0, _copilotValidators.required)(),
      value: (0, _copilotValidators.required)(),
      templates: (0, _copilotValidators.optional)((0, _copilotValidators.unique)().catch(function () {
        return [{ path: [], message: { name: "save-duplicate-templates" } }];
      }))
    })).andAlso(isPublishConfigValid())
  });
  exports.default = (0, _crossCheckDsl.default)(sitesettingValidators);
});