define("copilot/components/app-update/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appUpdater: Ember.inject.service(),

    appUpdated: Ember.computed.reads("appUpdater.appUpdated"),

    actions: {
      refresh: function refresh() {
        window.location.reload();
      }
    }
  });
});