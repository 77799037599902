define("copilot/components/contextual-editor/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    validation: Ember.inject.service(),

    classNames: ["item-edit"],
    isAutopilotBrand: Ember.computed.not("brandConfig.nonAutopilotBrand"),
    isNewsletterItem: Ember.computed.equal("model.type", "newsletter-item"),
    isBundleCuration: Ember.computed.equal("model.type", "bundle-curation"),
    title: null,
    /**
      Stub out `{{take-over}}`'s dismiss API.
      @private
     */
    dismiss: function dismiss() {},


    itemsComponent: Ember.computed("model.type", function () {
      var type = void 0;
      if (this.model && this.model.get("type")) {
        type = this.model.get("type");
      }
      if (this.model && this.model[0] && this.model[0].get("type")) {
        type = this.model[0].get("type");
      }
      switch (type) {
        case "designer":
          return "designer-item";
        case "previous-designer":
          return "previous-designer-item";
        case "newsletter-item":
          return "newsletter-item";
        case "bundle-curation":
          return "bundle-curation";
        default:
          return "default-item";
      }
    }),

    actions: {
      submit: function submit() {
        var _this = this;

        var model = this.model;
        var validationService = this.validation;
        validationService.validate(model, "save").then(function (isValid) {
          if (isValid) {
            _this.submitItem(model);
            _this.dismiss();
          }
        });
      }
    }
  });
});