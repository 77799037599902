define("copilot/services/location", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var MAPBOX_BASE_URL = "https://api.mapbox.com";
  var MAPBOX_STATIC_MAP_STYLE = "cn-copilot/cjua0jlqw32fe1fm4vddc1bgp";
  var EMPTY_LOCATION = { lat: null, lng: null };

  function toMapboxLookup(_ref) {
    var street = _ref.street,
        streetExtended = _ref.streetExtended,
        locality = _ref.locality,
        city = _ref.city,
        region = _ref.region,
        country = _ref.country,
        postalCode = _ref.postalCode;

    return [street, streetExtended, locality, city, region, postalCode, country].filter(Boolean).map(function (term) {
      return encodeURIComponent(term.trim());
    }).join(",");
  }

  function parseResponse(_ref2) {
    var features = _ref2.features;

    if (!features || !features.firstObject) {
      return EMPTY_LOCATION;
    }

    var _features$firstObject = _slicedToArray(features.firstObject.center, 2),
        lng = _features$firstObject[0],
        lat = _features$firstObject[1];

    return { lat: lat, lng: lng };
  }

  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    config: Ember.inject.service(),
    logger: Ember.inject.service(),
    mapboxPublicToken: Ember.computed.reads("config.config.mapboxPublicToken"),
    mapboxStaticMapUrlPattern: Ember.computed("mapboxPublicToken", function () {
      return MAPBOX_BASE_URL + "/styles/v1/" + MAPBOX_STATIC_MAP_STYLE + "/static/pin-s({long},{lat})/{long},{lat},{zoom},0,0/{height}x{width}?access_token=" + this.mapboxPublicToken;
    }),
    emptyLocation: EMPTY_LOCATION,

    geocode: function geocode(_ref3) {
      var _this = this,
          _arguments = arguments;

      var street = _ref3.street,
          streetExtended = _ref3.streetExtended,
          locality = _ref3.locality,
          city = _ref3.city,
          region = _ref3.region,
          country = _ref3.country,
          postalCode = _ref3.postalCode;

      var lookup = toMapboxLookup({
        street: street,
        streetExtended: streetExtended,
        locality: locality,
        city: city,
        region: region,
        country: country,
        postalCode: postalCode
      });
      if (!lookup) {
        return Promise.resolve(this.emptyLocation);
      }

      return this.ajax.request(MAPBOX_BASE_URL + "/geocoding/v5/mapbox.places/" + lookup + ".json?access_token=" + this.mapboxPublicToken + "&types=address").then(function (response) {
        return parseResponse(response);
      }, function (error) {
        _this.logger.warn("Error calling mapbox api", [error].concat(Array.prototype.slice.call(_arguments)));
        return _this.emptyLocation;
      });
    }
  });
});