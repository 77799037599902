define("copilot/controllers/search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    searchIndexController: Ember.inject.controller("search.index"),
    query: Ember.computed.alias("searchIndexController.query"),
    searchFilters: Ember.computed.alias("searchIndexController.searchFilters"),
    typeFilters: Ember.computed.alias("searchIndexController.typeFilters"),
    selectedFilters: Ember.computed.alias("searchIndexController.selectedFilters"),
    inputQuery: Ember.computed.alias("searchIndexController.inputQuery"),
    resultsMetaData: Ember.computed.alias("searchIndexController.resultsMetaData"),
    page: Ember.computed.alias("searchIndexController.page"),
    dates: Ember.computed.alias("searchIndexController.dates"),
    isSelectingDateRange: Ember.computed.alias("searchIndexController.isSelectingDateRange"),
    collaboratorIds: Ember.computed.alias("searchIndexController.collaboratorIds"),
    contributorIds: Ember.computed.alias("searchIndexController.contributorIds"),
    activeItem: Ember.computed.alias("searchIndexController.activeItem"),
    selections: Ember.computed.alias("searchIndexController.selections"),

    actions: {
      setQuery: function setQuery() {
        var _searchIndexControlle;

        (_searchIndexControlle = this.searchIndexController).send.apply(_searchIndexControlle, ["setQuery"].concat(Array.prototype.slice.call(arguments)));
      },
      setPage: function setPage() {
        var _searchIndexControlle2;

        (_searchIndexControlle2 = this.searchIndexController).send.apply(_searchIndexControlle2, ["setPage"].concat(Array.prototype.slice.call(arguments)));
      },
      closeModal: function closeModal() {
        this.set("isSelectingDateRange", false);
      },
      applyDateRange: function applyDateRange() {
        var _searchIndexControlle3;

        (_searchIndexControlle3 = this.searchIndexController).send.apply(_searchIndexControlle3, ["applyDateRange"].concat(Array.prototype.slice.call(arguments)));
      },
      applyOption: function applyOption() {
        var _searchIndexControlle4;

        (_searchIndexControlle4 = this.searchIndexController).send.apply(_searchIndexControlle4, ["applyOption"].concat(Array.prototype.slice.call(arguments)));
      },
      registerFilterOptions: function registerFilterOptions() {
        var _searchIndexControlle5;

        (_searchIndexControlle5 = this.searchIndexController).send.apply(_searchIndexControlle5, ["registerFilterOptions"].concat(Array.prototype.slice.call(arguments)));
      },
      removeOption: function removeOption() {
        var _searchIndexControlle6;

        (_searchIndexControlle6 = this.searchIndexController).send.apply(_searchIndexControlle6, ["removeOption"].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});