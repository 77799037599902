define("copilot/routes/lists/index", ["exports", "copilot/routes/curatedlists/index"], function (exports, _index) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    controllerName: "curatedlists/index",

    renderTemplate: function renderTemplate() {
      this.render("curatedlists/index");
    }
  });
});