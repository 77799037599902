define("copilot/components/non-production-banner/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    environment: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    deployEnvironment: Ember.computed.reads("environment.deployEnvironment"),
    env: Ember.computed.reads("environment.deployEnvironment"),
    currentBranch: "main",
    branches: null,

    showBranchSwitcher: Ember.computed(function () {
      return !this.get("environment.isDevelopmentBuild");
    }),

    //Set the last built branches from the `copilot-label` meta tag.
    init: function init() {
      this._super.apply(this, arguments);
      try {
        var content = document.querySelector('meta[name="copilot-labels"]').getAttribute("content");
        var parsed = JSON.parse(decodeURIComponent(content));
        var branch = Ember.get(parsed, "current");
        var deployEnvironment = this.deployEnvironment;

        // Since `main` is not a label in redis we assign it to `branch`
        // when the current label refers to a non-branch label (the deploy env)
        if (branch === deployEnvironment) {
          branch = "main";
        }
        this.set("branches", Ember.get(parsed, "labels"));
        this.set("currentBranch", branch);
      } catch (e) {
        this.set("branches", []);
      }
    },


    actions: {
      selectBranch: function selectBranch(value) {
        window.location.href = value;
      }
    }
  });
});