define("copilot/components/resource-link-to/component", ["exports", "ember-inflector"], function (exports, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    id: Ember.computed.reads("resource.id"),
    tagName: "span",
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    pluralizedType: Ember.computed("resource.type", function () {
      return (0, _emberInflector.pluralize)(this.get("resource.type"));
    }),

    route: Ember.computed("resource.type", "activeBrand", function () {
      var pluralizedType = this.pluralizedType;
      var editRoute = pluralizedType + ".edit";
      if (this.router.hasRoute(editRoute)) {
        return editRoute;
      } else if (this.router.hasRoute(pluralizedType)) {
        return pluralizedType;
      } else {
        return "contentEdit";
      }
    }),

    hasRoute: Ember.computed("route", function () {
      var route = this.route;
      if (this.router.hasRoute(route)) {
        return true;
      } else {
        Ember.Logger.warn("Route could not be loaded for " + route + ". Did you create the content type?");
        return false;
      }
    })
  }).reopenClass({
    positionalParams: ["resource"]
  });
});