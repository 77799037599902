define("copilot/routes/bundles/create", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    controllerName: "bundles.index",

    model: function model() {
      return this.store.createRecord("bundle");
    }
  });
});