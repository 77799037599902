define("copilot/components/curation-type-select/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ["size"],
    features: Ember.inject.service(),
    curationTypes: Ember.computed(function () {
      var enableLinkStack = this.features.get("enableLinkStack");
      var enableLinkBanner = this.features.get("enableLinkBanner");
      var enableVisualLinkBanner = this.features.get("enableVisualLinkBanner");
      var options = this.get("brandConfig.curationTypeOptions");
      // Filter curationTypeOptions based on Link Banner and Link Stack feature flags.
      return options.reduce(function (filteredOptions, option) {
        var value = option.value;

        var skipOption = value === "LinkList" && !enableLinkStack || ["LinkBannerMultipleLinks", "LinkBannerMarquee"].includes(value) && !enableLinkBanner || value === "LinkBannerVisual" && !enableVisualLinkBanner;
        if (skipOption) {
          return filteredOptions;
        }

        return filteredOptions.concat(option);
      }, []);
    })
  });
});