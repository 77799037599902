define("copilot/brands/cnt/models/destination", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Destination = _base.default.extend({
    type: "destination",
    name: (0, _emberModel.attr)(),
    description: (0, _emberModel.attr)(_attr.default.str),
    country: (0, _emberModel.attr)(_attr.default.str),
    city: (0, _emberModel.attr)(_attr.default.str),
    geo: (0, _emberModel.attr)(_attr.default.geo),
    geoTags: (0, _emberModel.attr)(_attr.default.array),
    primaryLanguage: (0, _emberModel.attr)(_attr.default.str),
    timezone: (0, _emberModel.attr)(_attr.default.str),
    currency: (0, _emberModel.attr)(_attr.default.str),
    dek: (0, _emberModel.attr)(),
    seoName: (0, _emberModel.attr)(),
    seoDescription: (0, _emberModel.attr)(),
    socialName: (0, _emberModel.attr)(),
    socialDescription: (0, _emberModel.attr)(),
    tags: (0, _emberModel.attr)(_attr.default.array),
    contentSource: (0, _emberModel.attr)(_attr.default.str),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    photosSocial: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    endorsements: (0, _emberModel.hasMany)("review", {
      embedded: true
    }),
    cheatSheet: (0, _emberModel.hasMany)("curatedlist", {
      embedded: true
    }),
    pinterest: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    miniGuides: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    stay: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    featuredStays: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    featuredEats: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    featuredPlays: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    featuredShops: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),

    contributorsAuthor: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    contributorsPhotographer: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    related: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("name"),

    embeddableFields: ["description"],

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      seoName: "name",
      seoDescription: "description",
      socialName: "name",
      socialDescription: "description"
    }
  }); /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/


  Destination.reopenClass({
    url: "/api/destinations"
  });

  exports.default = Destination;
});