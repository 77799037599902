define("copilot/helpers/un-snake", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.unSnake = unSnake;
  function unSnake(params) {
    var content = params[0].toString() || "";
    return content.split("_").join(" ");
  }

  exports.default = Ember.Helper.helper(unSnake);
});