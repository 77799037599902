define("copilot/brands/epi/models/package", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;


  var Package = _base.default.extend({
    type: "package",
    hed: (0, _emberModel.attr)(),
    dek: (0, _emberModel.attr)(),
    section1Hed: (0, _emberModel.attr)(str),
    section1Dek: (0, _emberModel.attr)(),
    section2Hed: (0, _emberModel.attr)(),
    section2Dek: (0, _emberModel.attr)(),
    section3Hed: (0, _emberModel.attr)(),
    section3Dek: (0, _emberModel.attr)(),
    section4Hed: (0, _emberModel.attr)(),
    section4Dek: (0, _emberModel.attr)(),
    section5Hed: (0, _emberModel.attr)(),
    section5Dek: (0, _emberModel.attr)(),
    primaryChannel: (0, _emberModel.attr)(str),
    seoTitle: (0, _emberModel.attr)(str),
    seoDescription: (0, _emberModel.attr)(str),
    socialTitle: (0, _emberModel.attr)(str),
    socialDescription: (0, _emberModel.attr)(str),
    imageSocial: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    mainTout: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    highlight: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    taxonomy: (0, _emberModel.hasMany)("taxonomy", {
      embedded: true
    }),
    section1List: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    section2List: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    section3List: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    section4List: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    section5List: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("hed"),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      socialTitle: "hed",
      socialDescription: "dek",
      seoDescription: "dek",
      photosTout: "imageSocial.firstObject",
      seoTitle: "hed"
    },

    toJSON: function toJSON(_) {
      var recursionDepth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;

      return this._super(_, recursionDepth);
    }
  });

  Package.reopenClass({
    url: "/api/packages"
  });

  exports.default = Package;
});