define("copilot/brands/vogue/routes/fashionshows/index/reviews/create", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _authenticatedRoute.default.extend({
    createItem: Ember.inject.service(),
    saveService: Ember.inject.service("save"),

    model: function () {
      var _ref = _asyncToGenerator(function* () {
        var parentFashionshow = this.modelFor("fashionshows.index").fashionshow;
        var fashionshowId = parentFashionshow.id;
        var freshFashionshow = yield this.store.find("fashionshow", fashionshowId);

        var review = yield freshFashionshow.review.firstObject || this.createItem.execute("review");

        parentFashionshow.review.setObjects([review]);

        yield this.saveService.save(parentFashionshow);

        return review;
      });

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),
    afterModel: function afterModel(model) {
      this.replaceWith("fashionshows.index.reviews.index", model);
    }
  });
});