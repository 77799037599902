define("copilot/helpers/extract-domain-name", ["exports", "psl"], function (exports, _psl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var isValidUrl = function isValidUrl(url) {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  };

  /**
    `{{extract-domain-name}}` Returns domain name from purchase uri
  
    @augments Ember.Helper
    @param {String} purchaseUri
   */

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        purchaseUri = _ref2[0];

    if (isValidUrl) {
      var _ref3 = new URL(purchaseUri),
          hostname = _ref3.hostname;

      var _getSecondLevelDomain = (0, _psl.parse)(hostname),
          sld = _getSecondLevelDomain.sld;

      return "" + sld.charAt(0).toUpperCase() + sld.substring(1);
    }
  });
});