define("copilot/components/settings-menu/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    configService: Ember.inject.service("config"),
    features: Ember.inject.service(),
    isOpen: false,
    userService: Ember.inject.service("user"),
    isSuperAdmin: Ember.computed.alias("userService.isSuperAdmin"),

    activeBrand: Ember.computed.reads("configService.activeBrand"),

    isVogue: Ember.computed(function () {
      return !!this.configService.config.contentTypes.find(function (type) {
        return type.value === "fashionshow";
      });
    }),

    beforeModel: function beforeModel() {
      if (!this.get("userService.isSuperAdmin")) {
        this.replaceWithExternal("index");
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this._clickOutside) {
        document.removeEventListener("click", this._clickOutside);
      }
    },
    open: function open() {
      var _this = this;

      this.set("isOpen", true);

      Ember.run.next(function () {
        _this._clickOutside = function () {
          _this.close();
        };
        document.addEventListener("click", _this._clickOutside);
      });
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    },


    actions: {
      stopPropagation: function stopPropagation(evt) {
        evt.stopPropagation();
      }
    }
  });
});