define("copilot/brands/vf/models/external", ["exports", "copilot/models/base", "copilot/models/attr"], function (exports, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var External = _base.default.extend({
    type: "external",
    hed: Ember.attr(),
    dek: Ember.attr(),
    url: Ember.attr(),
    tags: Ember.attr(_attr.default.array),
    source: Ember.attr(),
    photosTout: Ember.hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("hed")
  });

  External.reopenClass({
    duplicatable: true,
    url: "/api/externals"
  });

  exports.default = External;
});