define("copilot/helpers/split-capitalize", ["exports", "copilot-components/helpers/split-capitalize"], function (exports, _splitCapitalize) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _splitCapitalize.default;
    }
  });
});