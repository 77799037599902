define("copilot/parsers/markdown-hal", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function returnEmpty() {
    return "";
  }

  exports.default = Ember.Object.extend({
    parse: function parse(text) {
      try {
        if (Ember.isEmpty(text)) {
          return "";
        }
        return _copilotMarkdown.default.parseConde(text, {
          activity: returnEmpty,
          article: returnEmpty,
          bar: returnEmpty,
          brand: returnEmpty,
          callout: function callout(meta) {
            var html = "";
            if (!Ember.isNone(meta.body)) {
              html += meta.body;
            }
            return html;
          },

          cartoon: returnEmpty,
          clip: returnEmpty,
          cneembed: returnEmpty,
          cnevideo: returnEmpty,
          curatedlist: returnEmpty,
          destination: returnEmpty,
          external: returnEmpty,
          externallink: returnEmpty,
          facebook: returnEmpty,
          gallery: returnEmpty,
          golfball: returnEmpty,
          golfclub: returnEmpty,
          hotel: returnEmpty,
          iframe: returnEmpty,
          image: returnEmpty,
          instagram: returnEmpty,
          menu: returnEmpty,
          package: returnEmpty,
          recipe: returnEmpty,
          restaurant: returnEmpty,
          section: function section(meta) {
            var html = "<section>" + meta.body + "</section>";
            return html;
          },

          ship: returnEmpty,
          shop: returnEmpty,
          twitter: returnEmpty,
          video: returnEmpty,
          videoPlain: returnEmpty
        }, []);
      } catch (e) {
        this.consoleLogger.error("Error in parseConde when trying to input " + text, e);
      }
    }
  });
});