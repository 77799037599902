define("copilot/lib/csv-upload", ["exports", "ember-concurrency", "papaparse", "copilot/lib/csv-maps", "moment", "ember-file-upload/file", "lodash", "ember-retry/retry"], function (exports, _emberConcurrency, _papaparse, _csvMaps, _moment, _file, _lodash, _retry) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var VALID_ID_REGEX = /^[A-Za-z0-9]{24}$/;
  var MAX_IDS_PER_REQUEST = 100;
  var MAX_ITEMS_ALLOWED = 100;
  var MAX_RETRY_ASSET_SEARCH = 10;
  var TIMEOUT_ASSET_SEARCH = 2000;
  var MAX_URLS_PER_REQUEST = 10;
  var VALID_FILENAME_REGEX = /^[\w-\s()]+\.(jpe?g|png|gif|bmp)$/;

  function setQuickDate(pubDateOptions) {
    var _pubDateOptions$filte = pubDateOptions.filterBy("status", "now"),
        _pubDateOptions$filte2 = _slicedToArray(_pubDateOptions$filte, 1),
        currentDate = _pubDateOptions$filte2[0];

    Ember.set(this, "quickDate", currentDate.label);
  }

  exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set("csvMap", _csvMaps.default[this.type]);
      this.set("brandConfig", this.owner.lookup("service:brand-config"));
      this.set("store", this.owner.lookup("service:store"));
      this.set("search", this.owner.lookup("service:search"));
      this.set("user", this.owner.lookup("service:user"));
      this.set("intl", this.owner.lookup("service:intl"));
      this.set("schemaHelpers", this.owner.lookup("service:schemaHelpers"));
      this.set("publishService", this.owner.lookup("service:publish"));
      this.set("publishDataFactory", this.owner.factoryFor("model:publish-data"));
      this.set("userService", this.owner.lookup("service:user"));
      this.set("offerProducts", this.owner.lookup("service:offerProducts"));
      this.set("configService", this.owner.lookup("service:config"));
      this.set("consoleLogger", this.owner.lookup("service:consoleLogger"));
      this.set("uploadAsset", this.owner.lookup("service:uploadAsset"));
      this.set("features", this.owner.lookup("service:features"));
      this.set("categoryService", this.owner.lookup("service:category"));
      this.setPublishModel();
      setQuickDate.call(this, Ember.get(this, "pubDateOptions"));
    },


    pubDateOptions: Ember.computed(function () {
      var formattedOptions = [];
      var now = this.dateToSelectOption((0, _moment.default)(new Date()).toISOString(), "now");
      formattedOptions.push(now);
      return [].concat(formattedOptions);
    }),

    localPublishDate: Ember.computed("publishModel.publishDate", {
      get: function get() {
        return Ember.get(this, "publishModel.publishDate");
      },
      set: function set(key, date) {
        date = date && date.fromQuickDate ? date.value : date;
        date = (0, _moment.default)(date).toISOString();
        Ember.set(this, "publishModel.publishDate", date);
        Ember.set(this, "quickDate", null);
        this.analytics.storyEvent("Publish Date Changed", { pubDate: date });
        return date;
      }
    }),

    dateToSelectOption: function dateToSelectOption(date, status) {
      var intl = Ember.get(this, "intl");
      var roundedDate = (0, _moment.default)(date).seconds() > 0 ? (0, _moment.default)(date).seconds(0).add(1, "m") : date;
      var momentDate = (0, _moment.default)(roundedDate).format("MM/DD/YYYY @ h:mma");
      var timeZone = _moment.default.tz.zone(_moment.default.tz.guess()).abbr((0, _moment.default)(date).format("x"));
      var label = intl.t("helperText.contentPublisher." + status, {
        date: momentDate,
        timeZone: timeZone
      }).string;
      return { date: date, label: label, status: status, value: label };
    },
    setPublishModel: function setPublishModel() {
      var publishData = this.publishDataFactory.create({});
      Ember.set(this, "publishModel", publishData);
    },
    stripWhiteSpace: function stripWhiteSpace(itemsFromCsv) {
      return itemsFromCsv.map(function (item) {
        var strippedItem = Object.keys(item).reduce(function (acc, key) {
          var normalizedKey = key.replaceAll(String.fromCharCode(160), " ");
          acc[normalizedKey] = item[key].trim();
          return acc;
        }, {});
        return strippedItem;
      });
    },
    isValidFilename: function isValidFilename(input) {
      return VALID_FILENAME_REGEX.exec(input);
    },
    createSearchRequests: function createSearchRequests(inputSet, searchContentType) {
      var _this = this;

      var field = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "id";

      var searchResult = searchContentType.map(function (contentType) {
        var start = 0;
        var promises = [];
        while (start < inputSet.length) {
          var currentInputBatch = inputSet.slice(start, start + MAX_IDS_PER_REQUEST);
          var searchParams = {
            limit: MAX_IDS_PER_REQUEST,
            types: contentType
          };
          if (field === "id") {
            searchParams[field] = currentInputBatch.join(",");
          } else {
            searchParams.query = field + ".keyword:\"" + currentInputBatch.join('","') + "\"";
          }
          promises.push(_this.search.execute(searchParams));
          start += MAX_IDS_PER_REQUEST;
        }
        return promises;
      });
      return searchResult;
    },
    getId: function getId(url) {
      var hasTrailingSlash = url[url.length - 1] === "/";
      if (hasTrailingSlash) {
        url = url.substring(0, url.length - 1);
      }
      var urlParts = url.split("/");
      var id = urlParts.pop();
      var match = VALID_ID_REGEX.exec(id);
      if (!match) {
        throw {
          message: this.get("intl").t("validationMessage.invalidId", { id: id })
        };
      }
      return id;
    },
    _parseCsv: function () {
      var _ref = _asyncToGenerator(function* () {
        var csvData = yield this.file.readAsText();
        return _papaparse.default.parse(csvData, { header: true }).data;
      });

      function _parseCsv() {
        return _ref.apply(this, arguments);
      }

      return _parseCsv;
    }(),
    _hydrate: function () {
      var _ref2 = _asyncToGenerator(function* (strippedJson) {
        var _this2 = this;

        var fieldsToHydrate = this.csvMap.fieldsToHydrate;
        var itemsToHydrate = [];
        var ids = [];
        var filenames = [];

        fieldsToHydrate.forEach(function (field) {
          strippedJson.forEach(function (item, row) {
            var url = item[field];
            if (url) {
              try {
                var itemToHydrate = { field: field, row: row };
                if (_this2.isValidFilename(url)) {
                  itemToHydrate.filename = url;
                  filenames.push(url);
                } else {
                  var id = _this2.getId(url);
                  itemToHydrate.id = id;
                  ids.push(id);
                }
                itemsToHydrate.push(itemToHydrate);
              } catch (error) {
                throw [{
                  field: field,
                  row: row,
                  message: error.message
                }];
              }
            }
          });
        });

        var searchContentType = ["photo", "brand"];
        var idsSet = [].concat(_toConsumableArray(new Set(ids)));
        var filenamesSet = [].concat(_toConsumableArray(new Set(filenames)));
        var promises = [];
        if (idsSet.length) {
          promises = [].concat(_toConsumableArray(this.createSearchRequests(idsSet, searchContentType)));
        }
        if (filenamesSet.length) {
          promises = [].concat(_toConsumableArray(promises), _toConsumableArray(this.createSearchRequests(filenamesSet, searchContentType, 'filename')));
        }
        var searchBatchResults = (yield Promise.all(promises.map(function () {
          var _ref3 = _asyncToGenerator(function* (promise) {
            return yield Promise.all(promise);
          });

          return function (_x3) {
            return _ref3.apply(this, arguments);
          };
        }()))).flat();
        var hydratedModels = searchBatchResults.reduce(function (acc, searchBatch) {
          return acc.concat(searchBatch.hits);
        }, []);
        if (hydratedModels.length !== idsSet.length + filenamesSet.length) {
          var missingItems = itemsToHydrate.filter(function (item) {
            return !hydratedModels.some(function (model) {
              return item.filename ? model.filename === item.filename : model.id === item.id;
            });
          });
          var errors = missingItems.map(function (item) {
            return {
              field: item.field,
              row: item.row,
              message: _this2.get("intl").t("validationMessage.missingId", { id: item.filename || item.id })
            };
          });
          throw errors;
        }
        return hydratedModels;
      });

      function _hydrate(_x2) {
        return _ref2.apply(this, arguments);
      }

      return _hydrate;
    }(),
    getDefaultValues: function getDefaultValues() {
      var _this3 = this;

      var modelClass = this.store.modelFor(this.type);
      var defaultAttributes = {};
      if (modelClass.metadata && modelClass.metadata.defaultValues) {
        Object.entries(modelClass.metadata.defaultValues).forEach(function (_ref4) {
          var _ref5 = _slicedToArray(_ref4, 2),
              key = _ref5[0],
              value = _ref5[1];

          if (typeof value === "string" && value.charAt(0) === "@") {
            value = _this3.schemaHelpers.lookup(value.slice(1)).compute();
          }
          defaultAttributes[key] = value;
        });
      }
      return defaultAttributes;
    },
    _createModels: function () {
      var _ref6 = _asyncToGenerator(function* (hydratedModels, strippedJson) {
        var store = this.store,
            type = this.type;

        var models = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = strippedJson[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;

            var topLevelInlineFields = this.csvMap.fields;
            var mappedInlineProps = this._mapInlineProperties(topLevelInlineFields, item);
            var defaultValues = this.getDefaultValues();
            var modelProperties = Object.assign({}, defaultValues, mappedInlineProps);
            var partialModelInstance = store.createRecord(type, modelProperties);
            var model = this._addRels(partialModelInstance, item, hydratedModels);
            model.set("metadata.author", this.user.fullName);
            model.entityMetadata.brand = this.user.brand.activeBrand;
            model.entityMetadata.users.push(this.user.user.id);
            model.toBepublished = item.Publish && item.Publish.toUpperCase() === "TRUE" ? true : false;
            if (modelProperties.categories && modelProperties.categories.length > 0) {
              // add functional tags
              var result = yield this.categoryService.find(model.categories);
              model.set("categories", { "functional-tags": [result] });
            }
            models.push(model);
            Ember.set(this, "models", models);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return models;
      });

      function _createModels(_x4, _x5) {
        return _ref6.apply(this, arguments);
      }

      return _createModels;
    }(),
    _mapInlineProperties: function _mapInlineProperties(inlineFields, item) {
      return inlineFields.reduce(function (acc, value) {
        var field = value.field,
            columnHeading = value.columnHeading,
            transform = value.transform,
            optional = value.optional;

        if (!item[columnHeading] && optional) {
          return acc;
        }

        if (transform) {
          acc[field] = transform(item[columnHeading]);
        } else {
          acc[field] = item[columnHeading];
        }
        return acc;
      }, {});
    },
    _mapOfferProperties: function _mapOfferProperties(inlineFields, item, count) {
      var _this4 = this;

      var acc = [];

      var _loop = function _loop(i) {
        var tempOffer = {};
        inlineFields.map(function (value) {
          var field = value.field,
              columnHeading = value.columnHeading,
              transform = value.transform,
              optional = value.optional,
              offerCount = value.offerCount,
              defaultVal = value.defaultVal;

          if (offerCount === i) {
            if (field === "countryCode" && !item[columnHeading]) {
              tempOffer[field] = _this4.configService.marketCode.toUpperCase();
              return tempOffer;
            }
            if (!item[columnHeading] && optional && !defaultVal) {
              return tempOffer;
            }
            if (transform) {
              tempOffer[field] = transform(item[columnHeading]);
            } else {
              tempOffer[field] = item[columnHeading];
              if (field === "currency" || field === "offerType") {
                tempOffer[field] = item[columnHeading] && item[columnHeading].length ? item[columnHeading] : defaultVal;
              }
            }
          }
          return tempOffer;
        });

        if (!item["currency"] && _this4.features.get("enableLocalisedOffers") && _this4.brandConfig.countryCurrencyMapping) {
          tempOffer["currency"] = _this4.brandConfig.countryCurrencyMapping[tempOffer["countryCode"].toUpperCase()];
        }
        acc.push(tempOffer);
      };

      for (var i = 0; i < count; i++) {
        _loop(i);
      }
      return acc;
    },
    _addRels: function _addRels(model, itemJson, hydratedModels) {
      var _this5 = this;

      var relatedFields = this.csvMap.relationships;
      var store = this.store;
      relatedFields.forEach(function (relMapConfig) {
        var relationshipInlineFields = relMapConfig.fields;
        var relModel = void 0;

        if (relationshipInlineFields && relationshipInlineFields.length) {
          var offerCount = 0;
          var itemJsonKeys = Object.keys(itemJson);
          itemJsonKeys.forEach(function (i) {
            return i.includes('Offer URL') ? offerCount++ : offerCount;
          });
          var mappedRelProps = _this5._mapOfferProperties(relationshipInlineFields, itemJson, offerCount);
          if (mappedRelProps.length > 1) {
            mappedRelProps = mappedRelProps.filter(function (mappedRelProp) {
              return mappedRelProp.purchaseUri;
            });
          }
          /**
          * a rel field can be configured with `optional: true`, in which case
          * we will only add it if the data for it is present in the CSV. If the complete
          * data is not present, the optional rel will be quietly ignored. This may be the case
          * even if the incomplete data would otherwise represent a perfectly acceptable value.
          */
          var relIsComplete = relationshipInlineFields.reduce(function (completeSoFar, _ref7) {
            var field = _ref7.field;

            return completeSoFar && mappedRelProps.every(function (mappedRelProp) {
              return mappedRelProp[field] !== undefined && mappedRelProp[field] !== "";
            });
          }, true);

          if (relMapConfig.optional && !relIsComplete) {
            return;
          }
          mappedRelProps.forEach(function (mappedRelProp) {
            relModel = store.createRecord(relMapConfig.type, mappedRelProp);
            model[relMapConfig.field].pushObject(relModel);
          });
        } else {
          var url = itemJson[relMapConfig.columnHeading];

          if (!url) {
            return;
          }
          var fieldToMap = 'filename';
          var field = url;
          if (!_this5.isValidFilename(url)) {
            field = _this5.getId(url);
            fieldToMap = 'id';
          }
          relModel = hydratedModels.find(function (model) {
            return model[fieldToMap] === field;
          });
          model[relMapConfig.field].pushObject(relModel);
        }
      });

      return model;
    },
    _validateModels: function () {
      var _ref8 = _asyncToGenerator(function* (modelsToValidate) {
        var validationPromises = [];
        modelsToValidate.forEach(function (model) {
          return validationPromises.push(model.validate("save"));
        });
        return Promise.all(validationPromises);
      });

      function _validateModels(_x6) {
        return _ref8.apply(this, arguments);
      }

      return _validateModels;
    }(),


    // Check if the url belongs to Amazon, sephora or nordstorm
    isAutofillOfferURL: function isAutofillOfferURL(offerUrl) {
      var regex = RegExp("(http(s)?)://(www.)?(?:amazon.com|amzn.to)/([\\w-%]+/)?(dp|gp/product)/(\\w+/)?(\\w{10})|(http(s)?)://(www.)?(?:amazon.com|amzn.to)/(\\w)");
      return offerUrl.match(regex);
    },


    // Upload image to Copilot
    uploadImage: function uploadImage(url) {
      var _this6 = this;

      var fileName = /[^/]*$/.exec(url)[0];
      if (url.includes("getproductimage")) {
        var imageUrlPartials = url.split("/image/obj/");
        fileName = imageUrlPartials.pop().split(";").shift() || fileName;
      }
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";
        var that = _this6;
        xhr.onload = function (err) {
          if (this.status === 200) {
            var arrayBuffer = this.response;
            if (arrayBuffer) {
              var byteArray = new Uint8Array(arrayBuffer);
              var file = new File([byteArray], fileName, { type: "image/jpeg" });
              // Service to upload asset
              that.uploadAsset.execute(_file.default.fromBlob(file, fileName)).then(function (asset) {
                var configService = that.configService;
                resolve(configService.config.copilotUrl + "/" + configService.activeBrand + "/photos/" + asset.id);
              });
            }
          } else {
            Ember.set(this, "errors", err);
            reject(err);
          }
        };
        xhr.send();
      });
    },


    // Check if the Offer URL is autofill offer URL and get  product details
    getProductDetails: function () {
      var _ref9 = _asyncToGenerator(function* (itemsFromCsv) {
        var _this7 = this;

        var offerItems = [];
        var nonofferProducts = itemsFromCsv.map(function (item) {
          if (item["Fetch Fields"].toUpperCase() === "TRUE" && _this7.isAutofillOfferURL(item["Offer URL"])) {
            offerItems.push(item);
          } else {
            return item;
          }
        }).filter(Boolean);
        return new Ember.RSVP.Promise(function () {
          var _ref10 = _asyncToGenerator(function* (resolve, reject) {
            if (offerItems.length === 0) {
              resolve(nonofferProducts);
            } else {
              // Once offer autofill images are uploaded, it takes some delay to reflect in search API
              // Below code is waiting for the items to update in search API
              var offerProducts = yield _this7.getOfferProductDetails(offerItems);
              var idList = [],
                  queryList = [];

              offerProducts.forEach(function (d) {
                var promoImage = d["Promo Image"];
                if (_this7.isValidFilename(promoImage)) {
                  queryList.push(promoImage);
                } else {
                  var urlParts = promoImage.split("/");
                  var imageId = urlParts[urlParts.length - 1];
                  if (imageId) {
                    idList.push(imageId);
                  }
                }
              });

              // Retry for search API
              return (0, _retry.default)(_this7.searchPhoto({
                ids: idList.filter(Boolean),
                queries: queryList.filter(Boolean)
              }), MAX_RETRY_ASSET_SEARCH, TIMEOUT_ASSET_SEARCH).then(function () {
                return resolve([].concat(_toConsumableArray(offerProducts), _toConsumableArray(nonofferProducts)));
              }).catch(function (err) {
                Ember.set(_this7, "errors", err);
                reject(err);
              });
            }
          });

          return function (_x8, _x9) {
            return _ref10.apply(this, arguments);
          };
        }());
      });

      function getProductDetails(_x7) {
        return _ref9.apply(this, arguments);
      }

      return getProductDetails;
    }(),


    // Search photo by id and filename
    searchPhoto: function searchPhoto(options) {
      var that = this;
      var executeParams = {
        limit: MAX_IDS_PER_REQUEST,
        types: "photo"
      };
      return function () {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var searchQueries = [];
          var _options$ids = options.ids,
              ids = _options$ids === undefined ? [] : _options$ids,
              _options$queries = options.queries,
              queries = _options$queries === undefined ? [] : _options$queries;

          if (ids.length) {
            searchQueries.push(that.search.execute(Object.assign({}, executeParams, { id: "" + ids.join(",") })));
          }
          if (queries.length) {
            searchQueries.push(that.search.execute(Object.assign({}, executeParams, {
              query: "filename.keyword:\"" + queries.join('","') + "\""
            })));
          }
          return Promise.all(searchQueries).then(function (data) {
            var totalRecords = data.reduce(function (acc, searchResult) {
              return acc + searchResult.totalHits;
            }, 0);
            if (totalRecords !== ids.length + queries.length) {
              return reject(new Error({ error: "Error on searching item: " + data }));
            }
            resolve();
          });
        });
      };
    },

    // Searches Brand Id and replaces brand name with ID
    getBrandData: function () {
      var _ref11 = _asyncToGenerator(function* (item) {
        var _this8 = this;

        var brandData = yield Promise.all(item.map(function () {
          var _ref12 = _asyncToGenerator(function* (data) {
            if (data.Brand) {
              var field = "name";
              var searchParams = {
                types: "brand"
              };
              var trimmedBrand = data.Brand.trim();
              searchParams.query = field + ".keyword:\"" + trimmedBrand + "\"";
              var _brandData = yield _this8.search.execute(searchParams);
              if (_brandData.hits.length > 0) {
                data.Brand = _brandData.hits[0]._data.id;
              } else {
                _this8.get("consoleLogger").error("Invalid Brand Name : " + data.Brand);
              }
            }
            return data;
          });

          return function (_x11) {
            return _ref12.apply(this, arguments);
          };
        }()));
        return brandData;
      });

      function getBrandData(_x10) {
        return _ref11.apply(this, arguments);
      }

      return getBrandData;
    }(),
    getOfferProductInfo: function getOfferProductInfo(offerUrl, offerItems) {
      var _this9 = this;

      var productInfo = void 0;

      // Added check for productId and offer items
      if (offerUrl && offerItems.length > 0) {
        productInfo = offerItems.find(function (item) {
          if (item["Offer URL"].normalize() === offerUrl.normalize()) {
            return { "Offer URL": item["Offer URL"], Publish: item["Publish"], "Fetch Fields": item["Fetch Fields"], "Country Code": item["Country Code"] || _this9.brandConfig.countryCodeDefault };
          }
        });
      }
      return productInfo;
    },


    // Fetch product details from Amazon,nordstorm and sephora
    getOfferProductDetails: function () {
      var _ref13 = _asyncToGenerator(function* (offerItems) {
        var _this10 = this;

        var brandCode = this.configService.activeCnBrandCode;
        return new Promise(function () {
          var _ref14 = _asyncToGenerator(function* (resolve, reject) {
            try {
              var urls = offerItems.map(function (url) {
                return url["Offer URL"];
              });
              // Calling API in batches
              var offerProductDetails = yield Promise.all((0, _lodash.chunk)(urls, MAX_URLS_PER_REQUEST).map(function (batch) {
                return _this10.offerProducts.getProductDetails(brandCode, batch);
              }));
              offerProductDetails = offerProductDetails.flat();

              if (offerProductDetails && Object.getOwnPropertyNames(offerProductDetails).length > 0) {
                var offerProducts = yield Ember.RSVP.Promise.all(offerProductDetails.map(function () {
                  var _ref15 = _asyncToGenerator(function* (offerProductDetail) {
                    // Get image url
                    var imageUrl = yield _this10.uploadImage(offerProductDetail["Site Image"]);
                    // Get product information
                    var productInfo = _this10.getOfferProductInfo(offerProductDetail["Request URL"], offerItems);
                    return Object.assign({}, productInfo, {
                      Name: offerProductDetail.Name,
                      Description: Array.isArray(offerProductDetail.Description) ? offerProductDetail.Description.join(" ") : offerProductDetail.Description,
                      "Retailer Name": offerProductDetail["Retailer Name"],
                      "Offer Type": "purchase",
                      Price: offerProductDetail.Price && offerProductDetail.Price.toString(),
                      "Sale Price": offerProductDetail["Sale Price"].toString(),
                      "Promo Image": imageUrl
                    });
                  });

                  return function (_x15) {
                    return _ref15.apply(this, arguments);
                  };
                }()));
                resolve(offerProducts);
              }
            } catch (error) {
              reject(error);
            }
          });

          return function (_x13, _x14) {
            return _ref14.apply(this, arguments);
          };
        }());
      });

      function getOfferProductDetails(_x12) {
        return _ref13.apply(this, arguments);
      }

      return getOfferProductDetails;
    }(),
    _validate: function () {
      var _ref16 = _asyncToGenerator(function* () {
        var _this11 = this;

        var itemsFromCsv = yield this._parseCsv();

        if (itemsFromCsv.length > MAX_ITEMS_ALLOWED) {
          var error = {
            message: this.get("intl").t("validationMessage.tooManyItems", { maxItems: MAX_ITEMS_ALLOWED })
          };
          return Promise.reject([error]);
        }

        var itemsAfterModification = yield this.getProductDetails(itemsFromCsv);
        var itemsWithBrandId = yield this.getBrandData(itemsAfterModification);
        var strippedJson = this.stripWhiteSpace(itemsWithBrandId);
        var hydratedModels = yield this._hydrate(strippedJson);
        var modelsToValidate = yield this._createModels(hydratedModels, strippedJson);
        var validationResults = yield this._validateModels(modelsToValidate);

        var allModelsValid = validationResults.every(function (result) {
          return result;
        });

        if (allModelsValid) {
          this.set("validModelsToSave", modelsToValidate);
          return true;
        }

        var errors = modelsToValidate.reduce(function (acc, model, index) {
          if (!model.isValid) {
            model.validationErrors.allErrors.forEach(function (error) {
              var _error = _slicedToArray(error, 2),
                  field = _error[0],
                  info = _error[1];

              field = _this11.intl.exists("fieldNames." + field) ? _this11.intl.t("fieldNames." + field) : Ember.String.capitalize(Ember.String.decamelize(field).split("_").join(" "));
              var message = info.customMessage || "can't be " + info.name;
              acc.push({ row: index, field: field, message: message });
            });
          }

          return acc;
        }, []);
        return Promise.reject(errors);
      });

      function _validate() {
        return _ref16.apply(this, arguments);
      }

      return _validate;
    }(),


    _save: (0, _emberConcurrency.task)(function* (model) {
      return yield model.save();
    }).enqueue().maxConcurrency(10),

    validate: (0, _emberConcurrency.task)(function* () {
      return yield this._validate();
    }),

    save: function save() {
      var _this12 = this;

      var saveTasks = this.validModelsToSave.reduce(function (acc, model) {
        acc.push(_this12._save.perform(model));
        return acc;
      }, []);
      var publishCount = this.models.filter(function (model) {
        return model.toBepublished === true;
      }).length;

      var _loop2 = function _loop2(index, draftedItem) {
        if (_this12.models[index].toBepublished) {
          draftedItem._promise.then(function (result) {
            Ember.set(draftedItem, "isPublishFailed", false);
            Ember.set(draftedItem, "isPublished", false);
            var user = _this12.get("userService.user");
            Ember.set(_this12.models[index], "id", result.id);
            var pubData = {
              uri: draftedItem.owner.type + "/" + result.id,
              pubDate: Ember.get(_this12, "localPublishDate"),
              revisionAuthor: user.get("firstName") + " " + user.get("lastName"),
              searchable: true,
              recommendable: true
            };
            _this12.publishService.publish(_this12.models[index], pubData).then(function (publishResult) {
              if (publishResult.publishHistory.data.length > 0) {
                Ember.set(draftedItem, "isPublished", true);
              }
            }).catch(function (error) {
              var response = error.jqXHR.responseJSON;
              _this12.get("consoleLogger").warn("Error publishing: " + response.statusCode + ": " + response.message);
              Ember.set(draftedItem, "isPublishFailed", true);
            }).finally(function () {
              publishCount--;
              if (publishCount < 1) {
                Ember.set(draftedItem, "publishingCompleted", true);
              }
            });
          }).catch(function (error) {
            _this12.get("consoleLogger").warn("Error publishing : " + error);
          });
        }
      };

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = saveTasks.entries()[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _ref17 = _step2.value;

          var _ref18 = _slicedToArray(_ref17, 2);

          var index = _ref18[0];
          var draftedItem = _ref18[1];

          _loop2(index, draftedItem);
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return saveTasks;
    }
  });
});