define("copilot/brands/lci/models/base", ["exports", "copilot/models/base", "copilot/models/attr"], function (exports, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  var categories = _attr.default.categories;


  _base.default.reopen({
    // Add brand custom taxonomies as property of the base model
    categoriesType: attr(categories),
    categoriesLayout: attr(categories),
    categoriesLabel: attr(categories),
    categoriesIngredient: attr(categories),
    categoriesRegion: attr(categories),
    categoriesOccasion: attr(categories),
    categoriesSeason: attr(categories),
    categoriesMeal: attr(categories),
    categoriesDishes: attr(categories),
    categoriesBrandmag: attr(categories)
  });

  exports.default = _base.default;
});