define("copilot/components/cni-redirect-overlay/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    environment: Ember.inject.service("environment"),
    isOldHostname: window.location.hostname.endsWith("cni.digital"),
    redirectUrl: Ember.computed("environment", function () {
      return this.get("environment.isProduction") ? "https://eu-copilot.condenast.io" : "https://eu-stg-copilot.condenast.io";
    })
  });
});