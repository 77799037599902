define("copilot/lib/lookup-container-config-property", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (container, arrangedBundleContainers, prop) {
    var currentType = Ember.get(container, "curationContainerType");
    var containerConfig = Ember.get(arrangedBundleContainers, currentType);
    if (containerConfig) {
      return Ember.get(containerConfig, prop);
    }
  };
});