define("copilot/brands/cnt/models/restaurant", ["exports", "copilot/brands/cnt/models/venue", "copilot/models/attr"], function (exports, _venue, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  var str = _attr.default.str;


  var Restaurant = _venue.default.extend({
    cuisine: attr(),
    editorsPick: attr(Boolean),
    hours: attr(),
    reservations: attr(),
    subtype: attr(str),
    type: "restaurant"
  });

  Restaurant.reopenClass({
    url: "/api/restaurants"
  });

  exports.default = Restaurant;
});