define("copilot/components/helper-alert/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "aside",
    classNames: ["helper-alert"],
    classNameBindings: ["gridItemClass"]
  });
});