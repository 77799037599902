define("copilot/models/validation-errors", ["exports", "copilot/models/validation-error"], function (exports, _validationError) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * An object to represent the collection of validation errors.
   *
   * @module copilot/models/validation-errors
   * @augments Ember.Object
   */
  var ValidationErrors = Ember.Object.extend({
    _nestedErrors: null,
    content: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._resetErrors();
    },

    /**
     * The array which contains each error paths and names.
     * A simple example: [['name', 'hasWrongLength'], ['address', 'cantBeBlank'], ['address.zipCode', 'isNaN'], ['address.city', 'cantBeBlank']]
     *
     * @property {Ember.Array}
     */
    allNames: Ember.computed("length", function () {
      return this._allErrorsData("names");
    }),

    /**
     * The array which contains each error paths and error object. It works exactly like `allKeys` property.
     * A simple example: [['name', {name: length, messageFormat: {min: 2, max: 10}], ['address', {name: blank}, ['address.zipCode', {name: 'number'}], ['address.city', {name: blank'}]
     *
     * @property {Ember.Array}
     */
    allContent: Ember.computed("length", function () {
      return this._allErrorsData();
    }),

    allErrors: Ember.computed.reads("allContent"),
    errors: Ember.computed.reads("content"),

    /**
     * The array which contains each direct error name.
     * A simple example: ['cantBeBlank', 'hasWrongLength', 'isNaN']
     * @property
     */
    names: Ember.computed("length", function () {
      var content = Ember.get(this, "content") || [];
      return content.map(function (error) {
        return error.get("name");
      });
    }),

    /**
     * The array which contains each direct error messages.
     * A simple example: ["can not be blank", "should have between 2 and 10 characters"]
     *
     * @property
     */
    messages: Ember.computed("length", function () {
      var content = Ember.get(this, "content");
      var messages = [];
      content.forEach(function (error) {
        messages.push(error.get("message"));
      });
      return messages;
    }),

    /**
     * The error count, including nested errors.
     * @property
     */
    length: Ember.computed(function () {
      var length = 0;
      var topLevelErrors = Ember.get(this, "content");
      var nestedErrors = Ember.get(this, "_nestedErrors");

      length += topLevelErrors.length;
      nestedErrors.forEach(function (nestedErrors) {
        length += Ember.get(nestedErrors, "length");
      });
      return length;
    }),

    /** This magical property is called when attempting to `get` an
     * undefined property on this object
     * @private */
    unknownProperty: function unknownProperty(key) {
      var errors = Ember.get(this, "_nestedErrors");
      return errors.get(key);
    },

    /**
     * Add an error.
     * @function add
     * @param {String} attributePath
     * @param {String} key
     * @param {Object} format contains message format values
     * @param {Object} customMessage to replace default message
     */
    add: function add(attributePath, name, format, customMessage) {
      if (!attributePath) {
        var error = _validationError.default.create({
          name: name,
          customMessage: customMessage,
          messageFormat: format
        });
        Ember.get(this, "content").pushObject(error);
      } else {
        var attrPaths = this._pathsForAttribute(attributePath);
        var errors = this._getOrCreateNestedErrors(attrPaths["path"]);
        errors.add(attrPaths["nestedPath"], name, format, customMessage);
      }

      this.notifyPropertyChange("length");
    },

    remove: function remove(attributePath) {
      var nestedErrors = Ember.get(this, "_nestedErrors");
      var attrPaths = this._pathsForAttribute(attributePath);
      var errors = nestedErrors.get(attrPaths["path"]);
      if (errors) {
        errors.remove(attrPaths["nestedPath"]);
      }

      this.notifyPropertyChange("length");
    },
    clear: function clear() {
      this._resetErrors();
      this.notifyPropertyChange("length");
    },


    /** @private */
    _getOrCreateNestedErrors: function _getOrCreateNestedErrors(path) {
      var nestedErrors = Ember.get(this, "_nestedErrors");
      var errors = nestedErrors.get(path);
      if (!errors) {
        errors = ValidationErrors.create();
        nestedErrors.set(path, errors);
      }
      return errors;
    },

    /** @private */
    _pathsForAttribute: function _pathsForAttribute(attributePath) {
      var splittedPath = attributePath.split(".") || [];
      return {
        path: splittedPath[0],
        nestedPath: splittedPath.removeAt(0).join(".")
      };
    },

    /** @private */
    _resetErrors: function _resetErrors() {
      Ember.set(this, "content", []);
      Ember.set(this, "_nestedErrors", Ember.Map.create());
    },

    _allErrorsData: function _allErrorsData() {
      var dataName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "content";

      var directData = Ember.get(this, dataName);
      var data = [];
      directData.forEach(function (singleData) {
        data.push(["", singleData]);
      });

      var nestedErrors = Ember.get(this, "_nestedErrors");
      nestedErrors.forEach(function (errors, path) {
        var allErrorsDataPath = "all" + dataName[0].toUpperCase() + dataName.slice(1);
        var allErrorsData = errors.get(allErrorsDataPath);

        allErrorsData.forEach(function (error) {
          var errorPath = path;
          if (error[0] !== "") {
            errorPath += "." + error[0];
          }
          data.push([errorPath, error[1]]);
        });
      });

      return data;
    }
  });

  exports.default = ValidationErrors;
});