define("copilot/brands/vogue/routes/fashionshows/index", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    controllerName: "fashionshows.index",

    _createEvent: function _createEvent() {
      return new Ember.RSVP.Promise(function (resolve) {
        var eventModel = this.store.createRecord("fashionshowevent");
        resolve(eventModel);
      }.bind(this));
    },


    // Associate the already existing event, or a newly created one if it doesn't
    _associateEvent: function _associateEvent(model) {
      var eventId = model.get("fashionshow.event.firstObject.id");
      var promise = void 0;
      if (eventId) {
        promise = this.store._find("fashionshowevent", eventId, true);
      } else {
        promise = this._createEvent.apply(this);
      }
      return promise.then(function (eventModel) {
        model.event = eventModel;
        return model;
      });
    },
    model: function model(params) {
      return this.store.find("fashionshow", params.fashionshow_id).then(function (fashionshow) {
        var model = Ember.Object.create({
          fashionshow: fashionshow
        });
        return this._associateEvent(model).then(function (model) {
          return model;
        });
      }.bind(this));
    },
    serialize: function serialize(model) {
      return {
        fashionshow_id: Ember.get(model, "fashionshow.id")
      };
    },


    actions: {
      forceReload: function forceReload() {
        this.refresh();
      }
    }
  });
});