define("copilot/controllers/bundles/index/section", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  exports.default = _base.default.extend({
    bundleController: Ember.inject.controller("bundles.index"),
    bundleModel: reads("bundleController.model"),
    limit: Ember.computed("contentTypeConfig", function () {
      return this.get("contentTypeConfig.items.itemLimit");
    }),
    layoutOptions: Ember.computed.alias("contentTypeConfig.layoutOptions"),
    contentTypeConfig: Ember.computed("model.curationContainerType", function () {
      var contentType = this.get("configService.config.contentTypes").findBy("value", "bundle");
      var curationContainerType = this.get("model.curationContainerType");
      var contentTypeConfig = {};

      if (!Ember.isEmpty(contentType.containers)) {
        var containers = contentType.containers;
        contentTypeConfig = containers.findBy("value", curationContainerType);
      }
      return contentTypeConfig;
    }),
    layoutPresetOptions: Ember.computed("model.curationContainerType", function () {
      var _this = this;

      var curationContainerType = this.get("model.curationContainerType");
      var layoutPresets = this.get("configService.config.layout.presets");

      var key = "bundle." + curationContainerType;
      var options = [];

      if (!Ember.isEmpty(layoutPresets)) {
        var bundlePresets = Ember.get(layoutPresets, key);
        if (!Ember.isEmpty(bundlePresets)) {
          options = bundlePresets;
        }
      }

      var updatedOptions = options.map(function (option) {
        var translatedLabel = _this.intl.t("componentPresets.options." + option.label).toString();

        return {
          label: translatedLabel.includes("componentPresets.options." + option.label) ? option.label : translatedLabel,
          value: option.value
        };
      });

      return updatedOptions;
    })
  });
});