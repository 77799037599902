define("copilot/models/brand", ["exports", "copilot/models/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Brand = _base.default.extend({
    thumbnail: Ember.computed.reads("_embedded.photosLogo.firstObject.fields")
  }); /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
  exports.default = Brand;
});