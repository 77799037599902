define("copilot/controllers/base", ["exports", "copilot/mixins/previewable", "copilot/mixins/concurrent-editable", "copilot/mixins/saveable", "ember-inflector", "copilot/helpers/relative-href-to", "ember-concurrency", "@condenast/copilot-slug", "moment", "@condenast/atjson-renderer-copilot-markdown", "@condenast/atjson-renderer-autopilot", "@condenast/atjson-source-ckeditor-html", "@condenast/atjson-source-verso", "copilot/lib/copilot-markdown"], function (exports, _previewable, _concurrentEditable, _saveable, _emberInflector, _relativeHrefTo, _emberConcurrency, _copilotSlug, _moment, _atjsonRendererCopilotMarkdown, _atjsonRendererAutopilot, _atjsonSourceCkeditorHtml, _atjsonSourceVerso, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var DEBOUNCE_MS = Ember.testing || window.testing ? 0 : 200;

  exports.default = Ember.Controller.extend(_previewable.default, _saveable.default, _concurrentEditable.default, {
    analytics: Ember.inject.service(),
    applicationController: Ember.inject.controller("application"),
    createItem: Ember.inject.service(),
    experimentsService: Ember.inject.service("experiments"),
    location: Ember.inject.service(),
    publish: Ember.inject.service(),
    features: Ember.inject.service(),
    saveService: Ember.inject.service("save"),
    search: Ember.inject.service(),
    schemaHelpers: Ember.inject.service(),
    validation: Ember.inject.service(),
    mainModelPath: "model",
    isPublishing: false,
    isUnpublishing: false,
    channels: Ember.computed.reads("configService.config.channels"),
    contentTypes: Ember.computed.reads("configService.config.contentTypes"),
    concurrentEditId: Ember.computed.reads("model.id"),

    companyId: Ember.computed.reads("configService.config.companyId"),
    publishConfig: Ember.computed.alias("contentTypeConfig.publish"),
    publishUriMap: Ember.computed.alias("publishConfig.uriMap"),
    urlPrefix: Ember.computed.alias("publishConfig.urlPrefix"),
    softSlug: Ember.computed.alias("publishConfig.softSlug"),
    hasNoSlug: Ember.computed.alias("publishConfig.hasNoSlug"),
    anchor: Ember.computed.alias("publishConfig.anchor"),
    configuredTemplates: Ember.computed.alias("contentTypeConfig.templates"),
    currentOrQueuedPublishRecord: Ember.computed.alias("mainModel.currentOrQueuedPublishRecord"),
    validationMessages: Ember.computed.alias("mainModel.validationErrors.allErrors"),
    publishData: Ember.computed.alias("mainModel.metadata.publishData"),
    slugifyOptions: Ember.computed("features.unicodeSlugs", function () {
      if (this.features.get("unicodeSlugs")) {
        return { allowAllLetterCharacters: true };
      }
      return {};
    }),

    formatSlug: function formatSlug(slug) {
      var _this = this;

      if (this.softSlug || this.currentOrQueuedPublishRecord) {
        var slugParts = slug.split("/").map(function (slugPart) {
          return (0, _copilotSlug.default)(slugPart, _this.slugifyOptions);
        });
        return slugParts.filter(function (slugPart) {
          return !!slugPart;
        }).join("/");
      }
      return (0, _copilotSlug.default)(slug, this.slugifyOptions);
    },
    updatePublishData: function updatePublishData(nonEditablePart, slug) {
      var updatedUri = nonEditablePart.replace(":slug", slug);
      if (updatedUri[updatedUri.length - 1] === "/") {
        updatedUri = updatedUri.slice(0, updatedUri.length - 1);
      }
      if (this.mainModel.metadata.publishData.slug !== slug) {
        this.set("publishData.slug", slug);
      }
      if (this.mainModel.metadata.publishData.uri !== updatedUri) {
        this.set("publishData.uri", updatedUri);
      }
      if (this.mainModel.metadata.publishData.uri) {
        Ember.run.debounce(this, this.searchByUri, DEBOUNCE_MS);
      }
    },
    searchByUri: function () {
      var _ref = _asyncToGenerator(function* () {
        if (!(this.previouslyCheckedUri && this.previouslyCheckedUri === this.publishData.uri)) {
          var searchByUriParams = {
            notid: this.mainModel.id,
            view: "edit",
            status: "published",
            uri: this.publishData.uri
          };
          var publishedContent = yield this.search.execute(searchByUriParams);
          this.set("contentPublishedToUri", publishedContent.hits[0]);
        }
        this.set("previouslyCheckedUri", this.publishData.uri);
      });

      function searchByUri() {
        return _ref.apply(this, arguments);
      }

      return searchByUri;
    }(),
    buildPublishPathPattern: function buildPublishPathPattern() {
      var _this2 = this;

      var initialUriParts = this.urlPrefix ? [this.urlPrefix] : [];
      var uriParts = this.publishUriKeys.reduce(function (publishUriParts, key) {
        if ((typeof key === "undefined" ? "undefined" : _typeof(key)) === "object") {
          if (key.pubDate) {
            var currentDate = new Date().toISOString();
            publishUriParts.push((0, _moment.default)(currentDate).format(key.pubDate));
          } else if (key.string) {
            publishUriParts.push(key.string);
          }
        } else if (typeof key === "string") {
          var value = _this2.getUriMapKeyValue(key);
          if (value) {
            publishUriParts.push(value);
          }
        }
        return publishUriParts;
      }, initialUriParts);
      if (!this.hasNoSlug) {
        uriParts.push(":slug");
      }
      var publishUri = uriParts.join("/");
      return this.anchor ? publishUri + "#" + this.anchor : publishUri;
    },


    defaultTemplate: Ember.computed("contentTypeConfig.defaultTemplate", "templates", function () {
      var _this3 = this;

      var configuredDefaultTemplate = this.templates && this.templates.find(function (templateConfig) {
        return templateConfig.label === _this3.contentTypeConfig.defaultTemplate;
      });
      if (configuredDefaultTemplate) {
        return configuredDefaultTemplate.value;
      }
      return !Ember.isBlank(this.configuredTemplates) && this.configuredTemplates[0];
    }),

    mainModel: Ember.computed("mainModelPath", "model.alternateLang.[]", function () {
      var path = this.mainModelPath;
      return this.get(path);
    }),

    hasValidationError: Ember.computed("mainModel.validationErrors.[]", function () {
      return !!this.get("model.validationErrors.length");
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.beforeValidationHooks = [];
      this.afterValidationHooks = [];
    },


    contentTypeConfig: Ember.computed("mainModel", function () {
      var contentTypeConfig = this.configService.getContentTypeConfig(this.mainModel);
      if (this.mainModel.displayBrand) {
        var _configService$getCon = this.configService.getContentTypeConfig(this.mainModel, this.mainModel.displayBrand),
            templates = _configService$getCon.templates,
            defaultTemplate = _configService$getCon.defaultTemplate;

        return Object.assign({}, contentTypeConfig, {
          templates: templates,
          defaultTemplate: defaultTemplate
        });
      }
      return contentTypeConfig;
    }),

    publishEnv: Ember.computed("mainModel", function () {
      return this.mainModel.displayBrand ? this.configService.getConsumerHostnameByBrandCode(this.mainModel.displayBrand) : this.configService.brandConfig.envUrl;
    }),

    publishUriKeys: Ember.computed("publishConfig", "hasNoSlug", function () {
      if (!this.publishConfig) {
        return [];
      }
      return this.hasNoSlug ? this.publishUriMap : this.publishUriMap.slice(0, this.publishUriMap.length - 1);
    }),

    autogenPublishSlugValue: Ember.computed("mainModel.hed", "mainModel.name", "mainModel.question", "mainModel.title", "mainModel.section1Hed", "mainModel.displayName", function () {
      if (!Ember.isBlank(this.publishUriMap)) {
        var autogenKey = this.publishUriMap[this.publishUriMap.length - 1];
        return this.getUriMapKeyValue(autogenKey);
      }
    }),

    getSchemaHelperValue: function getSchemaHelperValue(uriPartKey) {
      var _this4 = this;

      var schemaHelper = this.schemaHelpers.lookup(uriPartKey.dasherize());
      var schemaHelperValue = schemaHelper && schemaHelper.compute(this.mainModel);
      if (schemaHelperValue) {
        return schemaHelperValue.split("/").map(function (value) {
          return (0, _copilotSlug.default)(value, _this4.slugifyOptions);
        }).filter(function (x) {
          return !!x;
        }).join("/");
      }
    },
    getUriMapKeyValue: function getUriMapKeyValue(uriPartKey) {
      var modelValue = this.get("mainModel." + uriPartKey);
      var schemaHelperValue = this.getSchemaHelperValue(uriPartKey);
      var isKeyBlank = Ember.isBlank(modelValue) && Ember.isBlank(schemaHelperValue);

      // If the uriPartKey ends in "slug", we _do not_ want
      // to slugify it again.
      //
      // This is here to fix Vogue Runway galleries that
      // have brand slugs that have trailing dashes in them,
      // which were double slugified.
      //
      // The correct solution here is to prevent running the
      // slugify code on the value multiple times since it's
      // _not_ idempotent. This could be done by having a
      // `SlugifySafe` wrapper like `HTMLSafe`, which would
      // flag the string as not needing to run the slugify code.
      // @tim-evans
      var needsSlugification = modelValue != null || uriPartKey.match(/slug$/i);
      if (!isKeyBlank) {
        if (needsSlugification) {
          if (modelValue.isSafeSlug) {
            return (0, _copilotSlug.default)(modelValue, this.slugifyOptions).value;
          }
          return (0, _copilotSlug.default)(_copilotMarkdown.default.sanitize(modelValue), this.slugifyOptions);
        }
        return schemaHelperValue;
      }
    },
    nonEditablePathLength: function nonEditablePathLength() {
      var _this5 = this;

      var initialLength = this.urlPrefix ? this.urlPrefix.split("/").length : 0;

      return this.publishUriMap.slice(0, this.publishUriMap.length - 1).reduce(function (accumulatedLength, uriPartKey) {
        if (typeof uriPartKey === "string") {
          if (_this5.getUriMapKeyValue(uriPartKey)) {
            return accumulatedLength += _this5.getUriMapKeyValue(uriPartKey).split("/").length;
          }
          return accumulatedLength;
        } else if ((typeof uriPartKey === "undefined" ? "undefined" : _typeof(uriPartKey)) === "object") {
          var objectPublishConfig = uriPartKey.pubDate || uriPartKey.string;
          return accumulatedLength += objectPublishConfig.split("/").length;
        }
      }, initialLength);
    },


    slug: Ember.computed("currentURI", {
      get: function get() {
        if (!this.get("publishConfig") || !this.get("features.publishData")) {
          return;
        }
        var uri = this.currentURI;

        var slug = uri ? uri.split("/").slice(this.nonEditablePathLength()).join("/") : "";
        return this.formatSlug(slug);
      },
      set: function set(key, value) {
        var slug = this.formatSlug(value);
        this.updatePublishData(this.publishPathPattern, slug);
        return slug;
      }
    }),

    // Unfortunately there is not a single source of truth for publish URIs.
    // In Copilot, publish URIs can be set without publishing, and these are
    // stored in Copilot-specific metadata on the model. Conversely, when stories
    // are are actually published, their URI will be set in the publish record.
    // Since these could be set independently from one another, when determining
    // which publish URI to show the editor we are merely picking the most
    // recently created one. The next time the story is saved, a new metadata
    // entry will be created which should be up-to-date with the latest value
    currentURI: Ember.computed("mainModel.metadata.{createdAt,publishData}", "currentOrQueuedPublishRecord", function () {
      var wasPublishedOutsideApp = this.currentOrQueuedPublishRecord && (0, _moment.default)(this.currentOrQueuedPublishRecord.createdAt).isAfter(this.mainModel.metadata.createdAt);

      var copilotMetadataURI = this.get("mainModel.metadata.publishData.uri");
      if (copilotMetadataURI && !wasPublishedOutsideApp) {
        return copilotMetadataURI;
      } else if (this.currentOrQueuedPublishRecord) {
        return this.currentOrQueuedPublishRecord.uri;
      }
      return;
    }),

    currentPublishPath: Ember.computed("currentOrQueuedPublishRecord", "hasNoSlug", function () {
      if (this.currentOrQueuedPublishRecord) {
        if (this.hasNoSlug) {
          return this.currentOrQueuedPublishRecord.uri;
        } else {
          var uriMeta = this.currentOrQueuedPublishRecord.uri.split("/");
          var nonEditablePath = uriMeta.slice(0, this.nonEditablePathLength()).join("/");
          return nonEditablePath.length ? nonEditablePath + "/:slug" : ":slug";
        }
      }
      return;
    }),

    publishPathPattern: Ember.computed("slug", "currentPublishPath", "mainModel.hed", "mainModel.id", "mainModel.name", "mainModel.seasonSlug", "mainModel.brandSlug", "mainModel.bundle", "mainModel.package", "mainModel.market", "mainModel.publishUriHook", "mainModel.slideshowString", "mainModel.mappedGalleryType", "mainModel.displayName", "mainModel.title", "mainModel.categories.sections.firstObject.slug", "mainModel.categoriesChannels.firstObject.slug", "mainModel.categoriesChannels.firstObject.parent.slug", "mainModel.productType", "mainModel.category", "mainModel.primaryChannel", "mainModel.primaryCategory", "mainModel.section1Hed", "mainModel.address.city", "mainModel.cruiseLine", "mainModel.region", "mainModel.displayBrand", "mainModel.type", "mainModel.question", function () {
      if (!this.get("publishConfig") || !this.get("features.publishData")) {
        return;
      }

      if (this.currentPublishPath) {
        return this.currentPublishPath;
      } else {
        return this.buildPublishPathPattern();
      }
    }),

    collaborators: Ember.computed("model.entityMetadata.users", function () {
      if (Ember.isBlank(this.get("model.id"))) {
        this.get("model.entityMetadata.users").pushObject(this.get("userService.user.id"));
      }
      return this.get("model.entityMetadata.users");
    }),

    wasPublished: Ember.computed("model.publishHistory.data.length", function () {
      return this.get("model.publishHistory.data.length") > 0;
    }),

    // TODO: Remove this once all contentSource select fields do
    // contentSourceOptions lookup on brandConfig themselves, after all forms
    // migrated to content-form
    contentSourceOptions: Ember.computed(function () {
      return this.get("brandConfig.contentSourceOptions");
    }),

    defaultDistributionChannels: Ember.computed(function () {
      return this.get("contentTypeConfig.publish.distributionChannels.default") || [this.publish.getDefaultDistributionChannel()];
    }),

    distributionChannels: Ember.computed("mainModel.currentOrQueuedPublishRecord.distributionChannels.[]", "defaultDistributionChannels.[]", function () {
      var currentDistributionChannels = this.mainModel.get("currentOrQueuedPublishRecord.distributionChannels");
      if (currentDistributionChannels) {
        return currentDistributionChannels.map(function (_ref2) {
          var name = _ref2.name,
              metadata = _ref2.metadata;

          return { name: name, metadata: metadata };
        });
      }

      return this.defaultDistributionChannels;
    }),

    mapUrlPattern: Ember.computed.reads("location.mapboxStaticMapUrlPattern"),

    // Use this hook to implement any behaviour that may need to be run prior
    // to appearance of the publish modal
    prePublishTask: function prePublishTask() {
      return new Ember.RSVP.Promise(function (resolve) {
        resolve();
      });
    },
    completePublish: function completePublish(pubData) {
      var _this6 = this;

      var model = this.mainModel;
      return this.publish.publish(model, pubData).then(function (response) {
        if (!Ember.isBlank(response.statusCode) && !/^2/.test(response.statusCode)) {
          if (/^409/.test(response.statusCode)) {
            var contentTypeConfig = _this6.configService.getContentTypeConfig(model);
            var mountPoint = contentTypeConfig.publish.mountPoint;
            if (mountPoint) {
              response.message = window.location.protocol + "//" + _this6.get("brandConfig.envUrl") + "/" + mountPoint + "/" + pubData.uri;
            } else {
              response.message = window.location.protocol + "//" + _this6.get("brandConfig.envUrl") + "/" + pubData.uri;
            }
            response.errorType = "server-content-conflict";
          }
          return response;
        }

        // Handle any experiments related errors that could have been generated during the publish
        _this6.experimentsService.logExperimentsErrors(model);

        // Publish succeeded, no error response to return
        return null;
      }, function (response) {
        response = response.jqXHR;
        if (response.responseJSON.statusCode === 409) {
          response.responseJSON.errorType = "uri-exists";
        }
        return response;
      });
    },
    _runLifeCycleHooks: function _runLifeCycleHooks(stage) {
      var _this7 = this;

      // The hooks array are initialized in init and possible populated via mixins
      // or child components ex. the copilot editor
      var hookArrayToRun = this.get(stage + "Hooks");
      var hooksThatExistOnController = hookArrayToRun.filter(function (name) {
        return _this7.get(name);
      });
      var executeAllHooks = hooksThatExistOnController.map(function (name) {
        return _this7.get(name).call(_this7);
      });

      return Ember.RSVP.Promise.all(executeAllHooks);
    },
    transitionToRelativeRoute: function transitionToRelativeRoute() {
      var router = Ember.getOwner(this).lookup("router:main");
      var routerMicrolib = router._routerMicrolib || router.router;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var url = (0, _relativeHrefTo.relativeHrefTo)(this, args);

      this.transitionToRoute(url);
      routerMicrolib.updateURL(url);
    },


    inlineCreate: (0, _emberConcurrency.task)(function* (model, relName, inlineModelName) {
      var brand = this.activeBrand;
      var editRouteName = (0, _emberInflector.pluralize)(inlineModelName);
      var inlineModel = void 0;
      try {
        inlineModel = yield this.createItem.execute(inlineModelName);
        // Support both hasMany and belongsTo relationships
        if (Ember.get(model, relName + ".pushObject")) {
          Ember.get(model, relName).pushObject(inlineModel);
        } else {
          model.set(relName, inlineModel);
        }
        yield this.saveService.save(this.mainModel);
        if (this.router.hasRoute(editRouteName)) {
          this.transitionToRelativeRoute(editRouteName, brand, inlineModel);
        } else {
          this.transitionToRelativeRoute("contentEdit", brand, editRouteName, inlineModel);
        }
      } catch (err) {
        this.saveFailure(err);
      }
    }).drop(),

    forceSave: function () {
      var _ref3 = _asyncToGenerator(function* () {
        var model = this.model;
        try {
          model._forceSave = true;
          yield this.save();
          this.analytics.track("contentOverwriteDialog", "force-save", {
            contentType: model.type,
            revision: model.revision,
            brand: this.brand.activeBrand
          });
          model._forceSave = false;
        } catch (error) {
          model._forceSave = false;
          throw error;
        }
      });

      function forceSave() {
        return _ref3.apply(this, arguments);
      }

      return forceSave;
    }(),
    save: function () {
      var _ref4 = _asyncToGenerator(function* () {
        // Write publish URL to metadata
        if (this.publishPathPattern) {
          this.updatePublishData(this.publishPathPattern, this.slug);
        }
        if (this.ckBodyEditorInstance) {
          var ckEditorBodyValue = this.ckBodyEditorInstance.getData();
          var Renderer = this.features.get("verso") ? _atjsonRendererCopilotMarkdown.default : _atjsonRendererAutopilot.default;
          var mdBodyValue = Renderer.render(_atjsonSourceCkeditorHtml.default.fromRaw(ckEditorBodyValue).convertTo(_atjsonSourceVerso.default).setMeta({ brand: this.activeBrand, fieldName: "body" })).trim();
          this.model.set("body", mdBodyValue);
        }
        try {
          var saveStartTime = Date.now();
          yield this._runLifeCycleHooks("beforeValidation");
          var validationService = this.validation;
          var model = this.model;
          var analyticsData = {
            user: this.userService.user,
            url: this.target.currentURL
          };
          yield model.setInlines();
          var isValid = yield validationService.validate(model, "save");
          if (isValid) {
            this.triggerProgress();
            yield this._runLifeCycleHooks("afterValidation");
            try {
              yield this.saveService.save(model);
              this.experimentsService.logExperimentsErrors(model);
              this.triggerSuccess();
              this.analytics.track("content", "saved", {
                millisecondsToSave: Date.now() - saveStartTime,
                contentType: this.type
              });
            } catch (error) {
              if (error.statusCode === 412) {
                this.set("showContentOverwriteDialog", true);
                this.analytics.track("contentOverwriteDialog", "overwrite-prevented", analyticsData);
              } else {
                this.saveFailure(error);
                throw error;
              }
            }
          } else {
            this.analytics.track("error", "save", {});
            this.triggerValidationError();
          }
        } catch (error) {
          this.consoleLogger.error(error);
          this.triggerError();
          throw error;
        }
      });

      function save() {
        return _ref4.apply(this, arguments);
      }

      return save;
    }(),

    actions: {
      setEditorForBody: function setEditorForBody(ckBodyEditorInstance) {
        this.set("ckBodyEditorInstance", ckBodyEditorInstance);
      },
      loadRevision: function loadRevision(history) {
        var url = "/api/" + this.pluralizedType + "/" + history.entityId + "/revisions/" + history.revision;

        Ember.$.ajax(url, {
          tag: "controllers.base"
        }).then(function (data) {
          var modelName = data.meta.modelName;
          var model = this.store.createRecord(modelName);
          var normalizedData = this.store.adapterFor(modelName).get("serializer").normalize(model.constructor, data);
          model.load(data.id, normalizedData);
          this.set("model", model);
        }.bind(this), function (error) {
          this.consoleLogger.error("Edit revision error" + error);
        }.bind(this));
      },
      save: function save(done) {
        this.save().then(done);
      },
      forceSave: function forceSave(done) {
        this.forceSave().then(done);
      },
      unpublish: function unpublish() {
        this.set("isUnpublishing", true);
      },
      preview: function preview() {
        // implementation in previewable mixin
        this.openPreview();
      },
      publish: function () {
        var _ref5 = _asyncToGenerator(function* () {
          // save before attempt to publish
          yield this.save();

          // if save overwrite dialog is showing this will prevent pulishing from continuing
          if (this.showContentOverwriteDialog) {
            return;
          }

          yield this.prePublishTask();
          var mainModelPath = this.mainModelPath;
          var model = this.get(mainModelPath);
          var validationService = this.validation;

          var isValid = yield validationService.validate(model, "publish");
          if (isValid) {
            this.set("isPublishing", true);
          } else {
            this.analytics.track("error", "publish", {});
            this.triggerValidationError();
          }
        });

        function publish() {
          return _ref5.apply(this, arguments);
        }

        return publish;
      }(),
      toggleSidebar: function toggleSidebar() {
        var state = this.toggleProperty("showSidebar");
        var eventLabel = state ? "open" : "close";
        this.analytics.track("seo-sidebar", "toggled", {
          isOpen: eventLabel,
          contentType: Ember.get(this, "model.type")
        }, {
          label: "isOpen"
        });
      },
      onchange: function onchange(key, value) {
        this.mainModel.set(key, value);
      }
    }
  });
});