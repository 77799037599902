define("copilot/helpers/is-active", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    init: function init() {
      this._super();
      this.get("router").addObserver("currentURL", this, "recompute");
    },
    destroy: function destroy() {
      this._super();
      this.get("router").removeObserver("currentURL", this, "recompute");
    },
    compute: function compute(params, hash) {
      var _get;

      if (hash.params) {
        params = hash.params;
      }
      var possibleQueryParams = params[params.length - 1];
      if (possibleQueryParams && possibleQueryParams.isQueryParams) {
        // ensure to handle potentially frozen arrays
        params = params.slice();

        params[params.length - 1] = {
          queryParams: possibleQueryParams.values
        };
      }
      return (_get = this.get("router")).isActive.apply(_get, _toConsumableArray(params));
    }
  });
});