define("copilot/lib/autocomplete/default-search-adapter", ["exports", "lodash-es", "copilot/lib/autocomplete/base-adapter"], function (exports, _lodashEs, _baseAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function modelToObjectTransform(model) {
    return {
      id: model.get("id"),
      text: model.get("searchTitle"),
      data: model
    };
  }

  exports.default = _lodashEs.default.extend({}, _baseAdapter.default, {
    transformInitialValue: function transformInitialValue(value) {
      return Ember.RSVP.resolve(value.map(modelToObjectTransform));
    },
    getInitialValue: function getInitialValue(data) {
      return data.mapBy("id").join(",");
    },
    getValue: function getValue(data) {
      return data.choice.data;
    },
    find: function find(data, value) {
      return _lodashEs.default.find(data, {
        id: value
      });
    },
    transformResults: function transformResults(data) {
      if (data) {
        if (!Ember.isArray(data.hits)) {
          return [];
        }
        return data.hits.map(modelToObjectTransform);
      }
    }
  });
});