define("copilot/validations/brands/cni/gallery", ["exports", "@condenast/copilot-core", "copilot/validations/brands/cni/validators/top-level-category", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _copilotCore, _topLevelCategory, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var galleryValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.GalleryValidationsDescriptor).andAlso(object({
    categories: (0, _topLevelCategory.default)().andThen((0, _copilotValidators.categoriesMinimum)()).on("publish"),
    items: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    promoHed: (0, _copilotValidators.required)().on("publish")
  })).catch((0, _crossCheckDsl.mutePath)(["channel"])).catch((0, _crossCheckDsl.mutePath)(["body"]));

  exports.default = galleryValidationBuilder;
});