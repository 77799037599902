define("copilot/brands/vogue/models/agency", ["exports", "copilot/models/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Agency = _base.default.extend({
    searchTitle: Ember.computed("name", "_embedded.city.firstObject.fields.name", function () {
      var cityName = this.get("_embedded.city.firstObject.fields.name");
      return this.name + " " + cityName;
    })
  });

  exports.default = Agency;
});