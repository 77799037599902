define("copilot/components/search-slat/ui/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    isPublishedToWeb: Ember.computed("result.currentOrQueuedPublishRecord.distributionChannels", function () {
      var publishItem = this.result.currentOrQueuedPublishRecord;
      if (publishItem) {
        if (publishItem.distributionChannels) {
          return publishItem.distributionChannels.findBy("name", "web-owned-and-operated");
        }
        return true;
      }
      return false;
    })
  });
});