define("copilot/helpers/has-attribute", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasAttribute = hasAttribute;
  function hasAttribute(params /*, hash*/) {
    var model = params[0];
    var attributeName = params[1];
    var relationships = Array.from(model.constructor.relationships.keys());
    var attributes = Array.from(model.constructor.attributes.keys());
    var allAttributes = relationships.concat(attributes);
    return allAttributes.indexOf(attributeName) > -1;
  }

  exports.default = Ember.Helper.helper(hasAttribute);
});