define("copilot/adapters/unknown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.RESTAdapter.extend({
    logger: Ember.inject.service(),

    toParams: function toParams() {
      var record = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return {
        id: record.id,
        type: record.type,
        requestedType: record.requestedType
      };
    },
    find: function () {
      var _ref = _asyncToGenerator(function* (record, id) {
        this.logger.warn("Tried to call find on unknown adapter", {
          id: id,
          record: this.toParams(record)
        });
        return { id: id };
      });

      function find(_x2, _x3) {
        return _ref.apply(this, arguments);
      }

      return find;
    }(),
    findAll: function () {
      var _ref2 = _asyncToGenerator(function* () {
        this.logger.warn("Tried to call findAll on unknown adapter");
        return [];
      });

      function findAll() {
        return _ref2.apply(this, arguments);
      }

      return findAll;
    }(),
    findMany: function () {
      var _ref3 = _asyncToGenerator(function* () {
        this.logger.warn("Tried to call findMany on unknown adapter");
        return [];
      });

      function findMany() {
        return _ref3.apply(this, arguments);
      }

      return findMany;
    }(),
    findQuery: function () {
      var _ref4 = _asyncToGenerator(function* () {
        this.logger.warn("Tried to call findQuery on unknown adapter");
        return [];
      });

      function findQuery() {
        return _ref4.apply(this, arguments);
      }

      return findQuery;
    }(),
    createRecord: function () {
      var _ref5 = _asyncToGenerator(function* (record) {
        this.logger.warn("Tried to call createRecord on unknown adapter", {
          record: this.toParams(record)
        });
        return record;
      });

      function createRecord(_x4) {
        return _ref5.apply(this, arguments);
      }

      return createRecord;
    }(),
    saveRecord: function () {
      var _ref6 = _asyncToGenerator(function* (record) {
        this.logger.warn("Tried to call saveRecord on unknown adapter", {
          record: this.toParams(record)
        });
        return record;
      });

      function saveRecord(_x5) {
        return _ref6.apply(this, arguments);
      }

      return saveRecord;
    }(),
    deleteRecord: function () {
      var _ref7 = _asyncToGenerator(function* (record) {
        this.logger.warn("Tried to call deleteRecord on unknown adapter", {
          record: this.toParams(record)
        });
        return record;
      });

      function deleteRecord(_x6) {
        return _ref7.apply(this, arguments);
      }

      return deleteRecord;
    }()
  });
});