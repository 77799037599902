define("copilot/models/additional-property", ["exports", "copilot/models/embedded"], function (exports, _embedded) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _embedded.default.extend({
    title: Ember.computed("name", "value", function () {
      var name = this.name;
      var value = this.value;
      if (Ember.isPresent(name) || Ember.isPresent(value)) {
        return name + " - " + value;
      }
    })
  });
});