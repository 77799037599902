define("copilot/brands/cnt/components/subtype-field/component", ["exports", "copilot/brands/cnt/components/subtype-field/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ["validation.messages:has-error:"],
    classNames: ["grid-container", "cols-2"],

    /**
     * Returns options for the secondary type field
     * @returns {array}
     */
    secondaryTypeOptions: Ember.computed("model.subtype", function () {
      if (Ember.isPresent(this.get("model.subtype"))) {
        var subtype = this.get("model.subtype");
        var options = this.get("brandConfig.activity.subtypes").filter(function (elem) {
          return elem.value === subtype;
        });
        return options.length ? options[0].secondaryTypeOptions : [];
      } else {
        return [];
      }
    })
  });
});