define("copilot/brands/cnt/models/curatedsearch", ["exports", "copilot/models/curatedsearch", "copilot/models/attr"], function (exports, _curatedsearch, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  var query = _attr.default.query;
  exports.default = _curatedsearch.default.extend({
    query: attr(query({
      tags: [],
      geoTags: [],
      types: [],
      channel: [],
      subchannel: [],
      contributor: [],
      nottags: [],
      notid: [],
      categoryIds: []
    }))
  });
});