define("copilot/components/search/search-filters/status/component", ["exports", "copilot/components/search/search-filters/base/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var STATUSES = ["published", "draft", "scheduled"];

  exports.default = _component.default.extend({
    type: "status",
    queryKey: "status",
    intl: Ember.inject.service(),

    options: Ember.computed(function () {
      var _this = this;

      return STATUSES.map(function (option) {
        return {
          name: _this.intl.t("components.search.filters.status." + option),
          value: option
        };
      });
    }),
    scheduledDateRangeOption: Ember.computed(function () {
      return {
        name: this.intl.t("components.search.filters.date-range.from-now"),
        value: "from-now"
      };
    }),

    actions: {
      applyOption: function applyOption(type, option) {
        if (option.value === "scheduled") {
          this.onApply(type, this.options.findBy("value", "published"));
          this.onApply("date-range", this.scheduledDateRangeOption);
        } else {
          this.onApply.apply(this, arguments);
        }
      }
    }
  });
});