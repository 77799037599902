define("copilot/schema-helpers/default-lang", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    config: Ember.inject.service(),

    compute: function compute() {
      var languageConfig = this.config.get("config.language");
      return languageConfig ? languageConfig.default : "en-GB";
    }
  });
});