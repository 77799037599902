define("copilot/components/livestoryupdate-footer/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var ANIMATION_DURATION = 2000;

  exports.default = Ember.Component.extend({
    classNames: ["livestoryupdate-footer"],
    currentURL: Ember.computed(function () {
      return window.location.href;
    }),
    toggleCopyAnimation: function toggleCopyAnimation(toggleKey) {
      this.set(toggleKey, true);
      Ember.run.later(this, function () {
        if (this.isDestroyed) {
          return;
        }
        this.set(toggleKey, false);
      }, ANIMATION_DURATION);
    },


    actions: {
      copySuccess: function copySuccess() {
        this.toggleCopyAnimation('copied');
      },
      onClick: function () {
        var _ref = _asyncToGenerator(function* () {
          this.set("savingInProgress", true);
          this.set('validationErrorMessages', []);
          try {
            yield this.submitHandler(this.get("model"));
            if (this.model.validationErrors.allErrors.length) {
              this.set('validationErrorMessages', this.model.validationErrors.allErrors);
            } else {
              this.toggleCopyAnimation('saveSucces');
            }
          } catch (err) {
            this.set("savingInProgress", false);
            if (this.model.validationErrors.allErrors.length) {
              this.set('validationErrorMessages', this.model.validationErrors.allErrors);
            } else if (err.status >= 400) {
              this.set("hasNetworkError", true);
            } else if (this.model.validationErrors.length) {
              this.set("hasValidationError", true);
            }
          }
          this.set("savingInProgress", false);
        });

        function onClick() {
          return _ref.apply(this, arguments);
        }

        return onClick;
      }()
    }
  });
});