define("copilot/lib/brands/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var brands = [];

  function setBrands(brandsToBeSet) {
    brands = brandsToBeSet;
  }

  function getBrands() {
    return brands;
  }

  /*
   * @desc Given a `key` returns a function that searches the brands array
   * for a given `value`.
   */
  function searchBy(key) {
    return function _search(value) {
      return getBrands().find(function (brand) {
        return brand[key] === value;
      });
    };
  }

  exports.default = {
    setBrands: setBrands,
    getBrands: getBrands,
    searchBy: searchBy,
    byHumanName: searchBy("human"),
    bySlug: searchBy("slug"),
    byCode: searchBy("code"),
    byCopilotName: searchBy("copilotName"),
    byCopilotCode: searchBy("copilotCode")
  };
});