define("copilot/models/sitesetting", ["exports", "copilot/models/attr", "copilot/models/base", "ember-inflector"], function (exports, _attr, _base, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;

  var CUSTOM_FIELDS = ["channels", "subchannels", "contentTypes"];

  var Sitesetting = _base.default.extend({
    validation: Ember.inject.service(),
    type: "sitesetting",
    id: attr(_attr.default.str),
    channels: attr(_attr.default.array),
    contentTypes: attr(_attr.default.array),
    hostnames: attr(_attr.default.obj),
    commerce: attr(_attr.default.obj),
    language: attr(_attr.default.obj),
    bundles: Ember.computed("contentTypes.[]", function () {
      return this.get("contentTypes").findBy("value", "bundle");
    }),

    validateProperty: function validateProperty(property, collection, newValue, oldValue) {
      var _this = this;

      var errors = this.get("validationErrors");
      errors.clear();

      return this.validate("save").then(function (valid) {
        if (newValue || oldValue) {
          var _getCustomValidationV = _this._getCustomValidationValueObject(property, newValue, oldValue, collection),
              value = _getCustomValidationV.value,
              previousValue = _getCustomValidationV.previousValue,
              flatCollection = _getCustomValidationV.flatCollection;

          valid = valid && _this._uniqueValidator(flatCollection, value, previousValue);
          if (!valid) {
            errors.add(property, "duplicate-" + property, {
              property: (0, _emberInflector.singularize)(property),
              value: value
            });
          }
        }
        _this.notifyPropertyChange("validationErrors");
        return valid;
      });
    },
    _getCustomValidationValueObject: function _getCustomValidationValueObject(property, newValue, oldValue, collection) {
      if (CUSTOM_FIELDS.indexOf(property) >= 0) {
        return {
          flatCollection: collection.mapBy("value"),
          value: Ember.get(newValue, "value"),
          previousValue: oldValue ? Ember.get(oldValue, "value") : null
        };
      }
      return {
        flatCollection: collection,
        value: newValue,
        previousValue: oldValue
      };
    },
    _uniqueValidator: function _uniqueValidator(collection, newValue, oldValue) {
      var valid = void 0;
      valid = collection.every(function (item) {
        return item.toLowerCase() !== newValue.toLowerCase();
      });

      // this means no change, so still valid
      if (!valid && oldValue) {
        valid = oldValue === newValue;
      }

      return valid;
    },
    toString: function toString() {
      return "Site settings Model";
    }
  });

  Sitesetting.reopenClass({
    url: "/api/configs"
  });

  exports.default = Sitesetting;
});