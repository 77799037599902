define("copilot/helpers/publish-status", ["exports", "copilot/models/publish-status"], function (exports, _publishStatus) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.publishStatus = publishStatus;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Provide a publish-status model that wraps a content api model or search
   * result. This can be used in template context, yield be e.g. the `with`
   * helper's block param
   *
   * @module helpers/publish-status
   * @param {Ember.Model|Object} a model or pojo that represents a response for the content
   * api or a search result item
   */

  function publishStatus(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        model = _ref2[0];

    return _publishStatus.default.create({ model: model });
  }

  exports.default = Ember.Helper.helper(publishStatus);
});