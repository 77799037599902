define("copilot/lib/log-info", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var _this = this;

    (0, _icAjax.default)("/log", {
      type: "POST",
      data: {
        level: "info",
        msg: this.msg,
        pathname: this.pathname,
        data: this.data
      },
      tag: "lib.log-info"
    }).then(function () {
      console.log("Successfully logged " + _this.msg);
    }, function (error) {
      console.log("Encountered an error attempting to log " + _this.msg, error);
    });
  };
});