define("copilot/brands/vogue/components/season-result-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNames: ["dashboard-list-item", "clear", "card", "color-white"],

    publishStatus: Ember.computed("model", function () {
      var PublishStatus = Ember.getOwner(this).factoryFor("model:publish-status");
      var model = this.model;
      return PublishStatus.create({ model: model });
    }),

    title: Ember.computed.reads("model.name"),

    actions: {
      toggleAttr: function toggleAttr(attribute) {
        var model = this.model;
        var rePublish = Ember.get(this, "publishStatus.isPublished") && !Ember.get(this, "publishStatus.hasPendingRevision");
        return model.toggleAndSave(attribute, rePublish);
      }
    }
  });
});