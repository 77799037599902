define("copilot/components/time-picker/component", ["exports", "copilot/components/time-picker/template", "moment", "copilot-components/mixins/form-inputs"], function (exports, _template, _moment, _formInputs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * A component to input or select time values.
   *
   * The `time-picker` component uses a jQuery timepicker bower plugin to
   * render a text input field. User can select a time value from a pre-
   * filled dropdown in 30 minute increments. The time format is the
   * following: <hour in 12-hour format>: <minutes with leading zeros> <am/pm>
   *
   * This component can be used by itself, with the following template:
   * ```hbs
   * {{time-picker "localPublishDate" model=model customLabel="Time"}}
   * ```
   *
   * The invocation above will update the value of `localPublishDate` property
   * directly using two-way bindings.
   *
   * This component can be rendered within the `copilot-form` component similarly:
   *
   * ```hbs
   * {{#copilot-form model=model as |f|}}
   *   {{f.time-picker "event.eventDate" customLabel="Time" allowNullTime=true}}
   * {{/copilot-form}}
   * ```
   *
   * @module copilot-components/components/time-picker/component
   * @augments Ember.Component
   * @mixes copilot/mixins/copilot-form-inputs
   * @param {String} field - The string representation of the path to the key whose value
   * represents the date or time on the model. This is a positional param.
   * @param {(Object|Ember.Model)} model - The model whose 'field' attribute the collection
   * lives on. This param is implicitly provided by `copilot-form` when invoked in the context
   * of a `copilot-form` invocation.
   * @param {Boolean} allowNullTime - An optional flag, if true, sets the field to `null` if
   * the field's value is not in a valid time format. Otherwise, it is set to a string.
   * Defaults to false.
   */
  var TimePicker = Ember.Component.extend(_formInputs.default, {
    layout: _template.default,
    classNames: ["time-picker"],
    allowNullTime: false,
    displayFormat: "LT",

    init: function init() {
      this._super.apply(this, arguments);
      var field = this.field;
      Ember.defineProperty(this, "time", Ember.computed.alias("model." + field));
    },


    selectionObserver: Ember.observer("time", function () {
      var timeField = this.timeField;
      var roundUpTime = this.roundUpTime;
      var setTimeFieldBlank = timeField.timepicker("setTime", "");
      var setTimeField = timeField.timepicker("setTime", new Date(roundUpTime));
      timeField && Ember.isPresent(this.time) ? setTimeField : setTimeFieldBlank;
    }),

    roundUpTime: Ember.computed("time", function () {
      var time = this.time;
      var roundUpTime = (0, _moment.default)(time).seconds() > 0 ? (0, _moment.default)(time).seconds(0).add(1, "m") : (0, _moment.default)(time);
      return time ? roundUpTime : "";
    }),

    didInsertElement: function didInsertElement() {
      var timeField = this.$("input");
      var displayFormat = this.displayFormat;

      timeField.timepicker({
        scrollDefault: this.roundUpTime,
        // Use moment time format instead of PHP
        timeFormat: function timeFormat(time) {
          return (0, _moment.default)(time).format(displayFormat);
        }
      });

      timeField.timepicker("setTime", new Date(this.roundUpTime) || new Date());
      timeField.on("change", Ember.run.bind(this, "timeSelected"));

      this.set("timeField", timeField);
    },
    willDestroyElement: function willDestroyElement() {
      var timeField = this.$("input");
      timeField.off("change");
      timeField.timepicker("remove");
    },
    timeSelected: function timeSelected() {
      var format = this.format || "";
      var time = this.time;
      var date = time ? (0, _moment.default)(time).format("YYYY-MM-DD") : (0, _moment.default)(new Date()).format("YYYY-MM-DD");
      var timeField = (0, _moment.default)(this.timeField.timepicker("getTime")).format("HH:mm");
      var newDate = (0, _moment.default)(date + "T" + timeField).format(format);

      if (this.allowNullTime && !(0, _moment.default)(newDate).isValid()) {
        this.set("time", null);
      }
      return this.set("time", newDate);
    }
  });

  TimePicker.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = TimePicker;
});