define("copilot/brands/vogue/components/action-checkbox/component", ["exports", "copilot-components/mixins/generate-input-id"], function (exports, _generateInputId) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_generateInputId.default, {
    tagName: "li",
    classNames: ["checkbox-radio"],
    classNameBindings: ["isDisabled:disabled", "inProgress:inprogress"],
    checked: false,

    inProgress: Ember.computed("inflight.[]", "attr", function () {
      return this.inflight.indexOf(this.attr) > -1;
    }),

    isDisabled: Ember.computed.notEmpty("inflight"),

    init: function init() {
      this._super.apply(this, arguments);
      this.checked = this.value;
    },
    change: function change() {
      this.sendAction("action", this.attr);
    }
  });
});