define("copilot/controllers/error", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    brandConfig: Ember.inject.service(),
    user: Ember.inject.service(),
    router: Ember.inject.service(),
    context: Ember.computed("model", function () {
      return "Brand Code:\t" + this.brandConfig.activeBrand + "\nUser:      \t" + this.user.user.uid + "\nRoute:     \t" + this.router.url + "\nTimestamp:\t" + new Date(Date.now()).toISOString() + "\nUser Agent:\t" + window.navigator.userAgent + "\nStack:\n" + this.model.stack;
    }),

    actions: {
      selectAll: function selectAll(evt) {
        evt.target.select();
      }
    }
  });
});