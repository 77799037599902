define("copilot/utils/intl/missing-message", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = missingMessage;
  function missingMessage(key, locales, options) {
    var message = "Untranslated key encountered";

    this.logger && this.logger.warn(message, { key: key, locales: locales, options: options });

    if (Ember.testing) {
      (false && !(false) && Ember.assert(message + ": key " + key + " not found in locales " + locales));
    }

    return "No translation for " + key + " in " + locales.join(", ");
  }
});