define("copilot/models/advertiser", ["exports", "copilot/models/base", "copilot/models/attr"], function (exports, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr,
      hasMany = Ember.hasMany;
  var str = _attr.default.str;


  var Advertiser = _base.default.extend({
    type: "advertiser",
    name: attr(str),
    url: attr(str),
    photosLogo: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    thumbnail: Ember.computed.reads("_embedded.photosLogo.firstObject.fields"),
    searchTitle: Ember.computed.reads("name")
  });

  Advertiser.reopenClass({
    url: "/api/advertisers"
  });

  exports.default = Advertiser;
});