define("copilot/lib/autocomplete/local-data-source-adapter", ["exports", "node-uuid"], function (exports, _nodeUuid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    search: function search(query) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var results = _this.dataSource || [query.term];
        var result = results.filter(function (result) {
          return result.toLowerCase().indexOf(query.term.toLowerCase()) >= 0;
        });
        resolve(result);
      });
    },
    transformResults: function transformResults(results) {
      var transformedResults = results.map(function (result) {
        return {
          id: _nodeUuid.default.v1(),
          text: result
        };
      });
      return transformedResults;
    },
    transformInitialValue: function transformInitialValue(value) {
      return new Ember.RSVP.Promise(function (resolve) {
        var results = value.map(function (result) {
          return {
            id: _nodeUuid.default.v1(),
            text: result
          };
        });
        resolve(results);
      });
    },
    getValue: function getValue(data) {
      return data.choice.text;
    },
    find: function find(collection, id) {
      var items = this.$("input.multiple-select").select2("data");
      return items.findIndex(function (obj) {
        return obj.id === id;
      });
    },
    remove: function remove(index) {
      this.selection.removeAt(index);
    },
    getInitialValue: function getInitialValue(selection) {
      return selection.join(",");
    }
  };
});