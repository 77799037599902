define("copilot/models/award", ["exports", "copilot/models/embedded", "copilot/models/attr", "ember-model"], function (exports, _embedded, _attr, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var string = _attr.default.str;
  exports.default = _embedded.default.extend({
    type: "award",
    name: (0, _emberModel.attr)(string),
    date: (0, _emberModel.attr)(string),

    validations: {
      name: {
        presence: true
      },
      date: {
        presence: true
      }
    }
  });
});