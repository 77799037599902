define("copilot/components/promo-experiment/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Component.extend({
    experimentsService: Ember.inject.service("experiments"),
    configService: Ember.inject.service("config"),
    activeBrandSlug: Ember.computed.reads("configService.activeBrandSlug"),

    loading: false,

    // A/B_TODO: Need to not show experiment if there was an error attaching to article. (https://cnissues.atlassian.net/browse/FING-1701)

    experiment: Ember.computed("model.experiments.items.firstObject", function () {
      return this.experimentsService.getActiveExperiment(this.model);
    }),

    // options
    showAsset: Ember.computed("experiment.overrideType", {
      get: function get() {
        return this.experimentsService.getShowAsset(this.model);
      },
      set: function set(key, value) {
        return this.experimentsService.setShowAsset(this.model, value);
      }
    }),

    showHedDek: Ember.computed("experiment.overrideType", {
      get: function get() {
        return this.experimentsService.getShowHedDek(this.model);
      },
      set: function set(key, value) {
        return this.experimentsService.setShowHedDek(this.model, value);
      }
    }),

    isDraft: Ember.computed("experiment.{state,overrideType}", function () {
      return this.get("experiment.state") === this.experimentsService.states.DRAFT && this.get("experiment.overrideType") !== this.experimentsService.overrideTypes.NONE;
    }),

    isRunning: Ember.computed("experiment.state", function () {
      return this.get("experiment.state") === this.experimentsService.states.RUNNING;
    }),

    isTerminated: Ember.computed("experiment.state", function () {
      var experimentState = this.get("experiment.state");

      return experimentState === this.experimentsService.states.TERMINATED_CANCELED || experimentState === this.experimentsService.states.TERMINATED_TIMEDOUT || experimentState === this.experimentsService.states.TERMINATED_WINNER;
    }),

    readyToRun: Ember.computed("experiment.readyToRun", {
      get: function get() {
        return this.experimentsService.getReadyToRun(this.model);
      },
      set: function set(key, value) {
        return this.experimentsService.setReadyToRun(this.model, value);
      }
    }),

    variantAIsWinner: Ember.computed.reads("experiment.variantAIsWinner"),

    variantBIsWinner: Ember.computed.reads("experiment.variantBIsWinner"),

    variantAMatchesLiveCanonical: Ember.computed.reads("experiment.variantAMatchesLiveCanonical"),

    variantBMatchesLiveCanonical: Ember.computed.reads("experiment.variantBMatchesLiveCanonical"),

    hasWinner: Ember.computed("variantAIsWinner", "variantBIsWinner", "isTerminated", function () {
      return (this.variantAIsWinner || this.variantBIsWinner) && this.isTerminated;
    }),

    noWinner: Ember.computed("variantAIsWinner", "variantBIsWinner", "isTerminated", function () {
      return !this.variantAIsWinner && !this.variantBIsWinner && this.isTerminated;
    }),

    elapsedTimeInHours: Ember.computed("experiment.{startTime,endTime}", function () {
      var startTime = this.get("experiment.startTime");
      var endTime = this.get("experiment.endTime");
      var elapsedMilliseconds = new Date(endTime) - new Date(startTime);
      var elapsedHours = elapsedMilliseconds / (1000 * 60 * 60);
      return elapsedHours;
    }),

    elapsedTimeWholeHours: Ember.computed("elapsedTimeInHours", function () {
      var wholeHours = Math.floor(this.elapsedTimeInHours);
      return wholeHours;
    }),

    elapsedTimeWholeMinutes: Ember.computed("elapsedTimeInHours", function () {
      var wholeHours = Math.floor(this.elapsedTimeInHours);
      var fractionalMinutes = this.elapsedTimeInHours - wholeHours;
      var minutes = Math.floor(fractionalMinutes * 60);
      return minutes;
    }),

    percentageVariantWonBy: Ember.computed("experiment.percentageVariantWonBy", function () {
      return this.get("experiment.percentageVariantWonBy") || 0;
    }),

    hasReachedMaxRunningExperiments: Ember.computed.reads("experiment.hasReachedMaxRunningExperiments"),

    disableEnablingToggle: Ember.computed("experiment.overrideType", function () {
      // The toggle is disabled when no override type has been selected
      var overrideType = this.get("experiment.overrideType");
      return !overrideType || overrideType === this.experimentsService.overrideTypes.NONE;
    }),

    showWinnerNotPublishedToastWarning: Ember.computed("isTerminated", "variantAIsWinner", "variantBIsWinner", "variantAMatchesLiveCanonical", "variantBMatchesLiveCanonical", function () {
      // Case 1: A winning variant A may be out of sync with the live canonical fields due to changes via API or content migrations.
      // Case 2: A winning variant B will not be automatically published if there are other unpublished edits for the piece of content.
      return this.variantAIsWinner && !this.variantAMatchesLiveCanonical && this.isTerminated || this.variantBIsWinner && !this.variantBMatchesLiveCanonical && this.isTerminated;
    }),

    hasExperiment: Ember.computed("experiment", function () {
      var hasExperiment = !!this.experiment;
      return hasExperiment;
    }),

    showOnboardingPanel: Ember.computed("model.experiments.items", function () {
      var experimentItems = this.get("model.experiments.items");
      return !!experimentItems && experimentItems.length === 0;
    }),

    displayBadgeContainer: Ember.computed("isDraft", "isRunning", "hasWinner", "noWinner", function () {
      return this.isDraft || this.isRunning || this.hasWinner || this.noWinner;
    }),

    shouldShowTerminateConfirmationDialog: Ember.computed("readyToRun", "isRunning", function () {
      return this.readyToRun && this.isRunning;
    }),

    variantAIsCurrentlyLive: Ember.computed("isTerminated", "variantAIsWinner", "variantBIsWinner", "variantAMatchesLiveCanonical", "variantBMatchesLiveCanonical", function () {
      // Case 1: There is no winner and A matches live canonical
      // Case 2: B is the winner but was not able to be published live and A matches live canonical
      return this.isTerminated && !this.variantAIsWinner && !this.variantBIsWinner && this.variantAMatchesLiveCanonical || this.isTerminated && !this.variantAIsWinner && this.variantBIsWinner && this.variantAMatchesLiveCanonical && !this.variantBMatchesLiveCanonical;
    }),

    unpublishedWinnerMatchesDraftRevision: Ember.computed("isTerminated", "variantAIsWinner", "variantBIsWinner", "variantAMatchesLiveCanonical", "variantBMatchesLiveCanonical", "showAsset", "showHedDek", "model.{promoHed,promoDek,photosTout.firstObject.id,photosTout.firstObject.revision}", "experiment.(promoHedA,promoDekA,promoAssetA.id,promoAssetA.revision,promoHedB,promoDekB,promoAssetB.id,promoAssetB.revision)", function () {
      var promoHed = this.get("model.promoHed");
      var promoDek = this.get("model.promoDek");
      var photosToutId = this.get("model.photosTout.firstObject.id");
      var photosToutRevision = this.get("experiment.photosTout.firstObject.revision");

      var promoHedA = this.get("experiment.promoHedA");
      var promoDekA = this.get("experiment.promoDekA");
      var promoAssetAId = this.get("experiment.promoAssetA.id");
      var promoAssetARevision = this.get("experiment.promoAssetA.revision");

      var promoHedB = this.get("experiment.promoHedB");
      var promoDekB = this.get("experiment.promoDekB");
      var promoAssetBId = this.get("experiment.promoAssetB.id");
      var promoAssetBRevision = this.get("experiment.promoAssetB.revision");

      // Case 1: Variant A is the winner but doesn't match live canonical, need to check if Variant A matches draft revision
      // Case 2: Variant B is the winner but doesn't match live canonical, need to check if Variant B matches draft revision
      if (this.isTerminated && this.variantAIsWinner && !this.variantAMatchesLiveCanonical) {
        if (this.showAsset && this.showHedDek) {
          return promoHed === promoHedA && promoDek === promoDekA && photosToutId === promoAssetAId && photosToutRevision === promoAssetARevision;
        } else if (this.showHedDek) {
          return promoHed === promoHedA && promoDek === promoDekA;
        } else if (this.showAsset) {
          return photosToutId === promoAssetAId && photosToutRevision === promoAssetARevision;
        }
      } else if (this.isTerminated && this.variantBIsWinner && !this.variantBMatchesLiveCanonical) {
        if (this.showAsset && this.showHedDek) {
          return promoHed === promoHedB && promoDek === promoDekB && photosToutId === promoAssetBId && photosToutRevision === promoAssetBRevision;
        } else if (this.showHedDek) {
          return promoHed === promoHedB && promoDek === promoDekB;
        } else if (this.showAsset) {
          return photosToutId === promoAssetBId && photosToutRevision === promoAssetBRevision;
        }
      }

      return false;
    }),

    setPromoHedA: Ember.observer("experiment.promoHedA", function () {
      // We need to get these properties using the experimentsService because otherwise the
      // computed properties won't be yet updated: https://guides.emberjs.com/v3.3.0/object-model/observers/#toc_observers-and-asynchrony
      var hasExperiment = !!this.experimentsService.getActiveExperiment(this.model);
      var showHedDek = this.experimentsService.getShowHedDek(this.model);

      // We check for hasExperiment and showHedDek before syncing the canonical value in order
      // to prevent undesired updates as the data model changes. We don't observe on these
      // properties because we don't need to set the canonical value when they change, only
      // when the actual variant A value changes.
      if (hasExperiment && showHedDek) {
        var canonicalValue = this.get("model.promoHed");
        var experimentValue = this.get("experiment.promoHedA");
        if (canonicalValue !== experimentValue) {
          Ember.set(this.model, "promoHed", experimentValue);
        }
      }
    }),

    setPromoDekA: Ember.observer("experiment.promoDekA", function () {
      // We need to get these properties using the experimentsService because otherwise the
      // computed properties won't be yet updated: https://guides.emberjs.com/v3.3.0/object-model/observers/#toc_observers-and-asynchrony
      var hasExperiment = !!this.experimentsService.getActiveExperiment(this.model);
      var showHedDek = this.experimentsService.getShowHedDek(this.model);

      // We check for hasExperiment and showHedDek before syncing the canonical value in order
      // to prevent undesired updates as the data model changes. We don't observe on these
      // properties because we don't need to set the canonical value when they change, only
      // when the actual variant A value changes.
      if (hasExperiment && showHedDek) {
        var canonicalValue = this.get("model.promoDek");
        var experimentValue = this.get("experiment.promoDekA");
        if (canonicalValue !== experimentValue) {
          Ember.set(this.model, "promoDek", experimentValue);
        }
      }
    }),

    setPromoAssetA: Ember.observer("experiment.promoAssetA", function () {
      // We need to get these properties using the experimentsService because otherwise the
      // computed properties won't be yet updated: https://guides.emberjs.com/v3.3.0/object-model/observers/#toc_observers-and-asynchrony
      var hasExperiment = !!this.experimentsService.getActiveExperiment(this.model);
      var showAsset = this.experimentsService.getShowAsset(this.model);

      // We check for hasExperiment and showAsset before syncing the canonical value in order
      // to prevent undesired updates as the data model changes. We don't observe on these
      // properties because we don't need to set the canonical value when they change, only
      // when the actual variant A value changes.
      if (hasExperiment && showAsset) {
        var canonicalValue = this.get("model.photosTout.firstObject");
        var experimentValue = this.get("experiment.promoAssetA");
        if (canonicalValue && !experimentValue) {
          this.model.photosTout.clear();
        } else if (!canonicalValue || canonicalValue.get("meta.modelName") !== experimentValue.get("meta.modelName") || canonicalValue.get("id") !== experimentValue.get("id") || canonicalValue.get("revision") !== experimentValue.get("revision")) {
          this.model.photosTout.setObjects([experimentValue]);
        }
      }
    }),

    actions: {
      onClose: function onClose() {
        this.set("showExperimentSidebar", false);
      },
      toggleEnableExperiment: function toggleEnableExperiment() {
        var currentState = this.get("readyToRun");
        this.set("readyToRun", !currentState);
      },
      submitTerminateConfirmationDialog: function submitTerminateConfirmationDialog() {
        this.set("readyToRun", false);

        // NOTE: The "openPublishDialog" action needs to be defined in the edit route for each content type that supports experiments, for example:
        // - app/routes/articles/index/edit.js
        // - app/routes/galleries/index/edit.js

        // Open publish dialog
        if (this.openPublishDialog) {
          this.openPublishDialog();
        }
      },
      onEditLiveFields: function () {
        var _ref = _asyncToGenerator(function* () {
          this.set("loading", true);

          // To edit canonical / live fields, a terminated experiment must be put in a overridden state.
          yield this.experimentsService.overrideTerminatedExperiment(this.model);

          this.set("loading", false);

          // Close the sidebar
          this.send("onClose");
        });

        function onEditLiveFields() {
          return _ref.apply(this, arguments);
        }

        return onEditLiveFields;
      }(),
      onCreateNewExperiment: function onCreateNewExperiment() {
        this.experimentsService.handleCreateNewExperiment(this.model);
      }
    }
  });
});