define("copilot/components/search/search-box/component", ["exports", "ember-concurrency", "ember-intl"], function (exports, _emberConcurrency, _emberIntl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ["search-box"],
    analytics: Ember.inject.service(),
    intl: Ember.inject.service(),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.element.querySelector("input").focus();
    },


    placeholder: (0, _emberIntl.translationMacro)("components.search.search-box.keywordSearch"),

    getQuickSearchResults: (0, _emberConcurrency.task)(function* (query) {
      if (!query) {
        return null;
      }
      var results = [{}];
      if (this.quickSearch) {
        try {
          var searchResult = yield this.quickSearch(query, this.additionalSearchParams);
          this.set("hasSearchError", false);
          results.push.apply(results, _toConsumableArray(searchResult.hits));
        } catch (err) {
          this.set("hasSearchError", true);
          this.get("logger").error("Error during quick search", err);
          return [];
        }
      }
      return results;
    }),
    actions: {
      search: function search(event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.setQuery(this.query);
      },
      onselect: function onselect(searchResult, index, query) {
        if (index === 0) {
          this.set("query", query);
          return this.setQuery(this.query);
        }

        var quickSearchIndex = index - 1;

        this.analytics.storyEvent("Search Result Selected", {
          result: searchResult,
          resultIndex: quickSearchIndex,
          searchType: "Quick Search"
        });
        this.jumpIntoContent(searchResult, quickSearchIndex, query);
      }
    }
  });
});