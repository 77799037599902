define("copilot/brands/cnt/models/rcalist", ["exports", "ember-model", "copilot/brands/cnt/models/list"], function (exports, _emberModel, _list) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Rcalist = _list.default.extend({
    type: "rcalist",
    region: (0, _emberModel.attr)(),
    year: (0, _emberModel.attr)(),
    touts: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    })
  });

  Rcalist.reopenClass({
    url: "/api/rcalists"
  });

  exports.default = Rcalist;
});