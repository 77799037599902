define("copilot/components/content-references/component", ["exports", "ember-inflector"], function (exports, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    groupedBackReferencesCounts: Ember.computed("model.backReferences", function () {
      var groups = this.get("model.backReferences").reduce(function (accum, ref) {
        var type = ref.get("type");
        accum[type] = accum[type] || [];
        accum[type].pushObject(ref);
        return accum;
      }, {});
      var stringComponents = [];

      for (var group in groups) {
        var count = groups[group].length;

        stringComponents.push((0, _emberInflector.pluralize)(count, group, count));
      }

      return stringComponents.join(", ");
    })
  });
});