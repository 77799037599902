define("copilot/brands/vogue/components/fashion-gallery-switcher/component", ["exports", "copilot/brands/vogue/mixins/existing-gallery-rels", "copilot/mixins/faux-dropdown", "lodash-es"], function (exports, _existingGalleryRels, _fauxDropdown, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_existingGalleryRels.default, _fauxDropdown.default, {
    classNames: ["fashion-gallery-switcher"],

    classNameBindings: ["hasOtherGalleries:faux-dropdown", "dropdownOpen:open"],

    fashionshowModel: Ember.computed.reads("model.fashionshow"),

    hasOtherGalleries: Ember.computed.notEmpty("otherGalleryTypes"),

    galleryType: Ember.computed("model.galleryType", function () {
      var _this = this;

      return this.galleryTypes.find(function (galleryType) {
        return galleryType.value === _this.get("model.galleryType");
      });
    }),

    actions: {
      navigateToGallery: function navigateToGallery(type) {
        var fashionshowModel = this.fashionshowModel;
        var galleryId = fashionshowModel.get(type + ".firstObject.id");

        this.sendAction("action", galleryId);
      }
    },

    otherGalleryTypes: Ember.computed("existingGalleryTypes", function () {
      var currentGalleryType = this.get("model.galleryType");

      return _lodashEs.default.reject(this.existingGalleryTypes, function (type) {
        return type.value === currentGalleryType;
      });
    })
  });
});