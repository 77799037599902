define("copilot/controllers/photos/index", ["exports", "copilot/controllers/base", "lodash-es"], function (exports, _base, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    configService: Ember.inject.service("config"),
    analytics: Ember.inject.service(),

    imageEditorService: Ember.inject.service("image-editor"),

    photoService: Ember.inject.service("photo"),

    contributorsCollection: Ember.computed.alias("contributorsPhotographer.content"),

    image: Ember.computed.alias("model"),

    isEditingVariant: false,

    showingHistory: false,

    masterURLWithNoWebp: Ember.computed("url", function () {
      var url = this.get("model.masterURL");
      var urlForOriginalContent = url + "?format=original";
      return urlForOriginalContent;
    }),

    actions: {
      onUpload: function onUpload(key, media) {
        var ar = this.get("model.aspectRatios").findBy("key", key);
        var newAR = media.get("aspectRatios").findBy("key", key);
        _lodashEs.default.forEach(newAR, function (value, propName) {
          ar.set(propName, value);
        });
        ar.set("modifications", null);
      },
      modified: function modified(updateObj) {
        var aspectRatio = this.get("model.aspectRatios").findBy("key", updateObj.key);
        aspectRatio.set("width", updateObj.modification.width);
        aspectRatio.set("height", updateObj.modification.height);
        aspectRatio.set("modifications", {
          crop: updateObj.modification
        });
      },
      showHistory: function showHistory() {
        this.set("showingHistory", true);
      },
      viewOriginal: function viewOriginal() {
        // Track if editors are viewing the original image
        this.analytics.track("image", "original-image-viewed");
      }
    },

    type: Ember.computed("model", function () {
      return this.get("model.type");
    })
  });
});