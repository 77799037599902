define("copilot/lib/analytics/story", ["exports", "javascript-state-machine"], function (exports, _javascriptStateMachine) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.event = exports.Story = undefined;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // https://www.npmjs.com/package/javascript-state-machine/v/2.4.0

  /**
   * the Story type provides an API for sequential event-based data accumulation, allowing for e.g. analytics tracking
   *  to be implemented without the sometimes-clumsy callback-passing style.
   *
   * A story can be specified at the top level of some user flow, composed of events that are expected to occur during that flow.
   * As long as each place in code where an interesting event happens has a handle to the story, code can simply be written to declare that
   * an event has happened, pass along the relevant data, and proceed about its business without needing to know what's being done with
   * the events.
   *
   * in Copilot, the analytics service provides a globally visible store of Stories, and an API to multiplex events to each story it tracks.
   * for usage of that and this API @see /tests/unit/services/analytics-test.js
   * @param {Object} param0
   * @param {Object[]} param0.events
   * @param {Function} param0.trackingCallback
   */
  function Story(_ref) {
    var _this = this;

    var events = _ref.events,
        trackingCallback = _ref.trackingCallback;

    this._events = []; // keep track of all the events this story listens to

    // `backtracks` is a list of objects describing which events to look for to revert the machine to a given previous state
    var backtracks = [];
    var trackingData = {};
    var dataCollectingCallbacks = {};
    var finalState = void 0,
        finalEvent = void 0;
    var transitions = [];

    // loop over the events, hooking up the forward and backward transitions as we go
    events.forEach(function (_ref2, fromIndex) {
      var event = _ref2.event,
          cancelEvent = _ref2.cancelEvent;

      _this._events.push(event, cancelEvent);
      finalState = fromIndex + 1;
      finalEvent = event; // it doesn't make sense for a final event to have a cancelEvent

      dataCollectingCallbacks["onbefore" + event] = function (event, from, to, data) {
        trackingData[event] = data;
      };

      if (cancelEvent) {
        backtracks.push({ btIndex: "" + fromIndex, btEvent: "" + cancelEvent });
      }

      transitions.push({ name: "" + event, from: "" + fromIndex, to: "" + (fromIndex + 1) },
      // each state has a reflexive transition, so that repeated events can overwrite previous ones without a cancel event
      { name: "" + event, from: "" + (fromIndex + 1), to: "" + (fromIndex + 1) });

      // add a transition for each previous cancelEvent in the story
      // the transition reverts to the state which accepts the event being canceled
      var backtracksFromHere = backtracks.map(function (_ref3) {
        var btIndex = _ref3.btIndex,
            btEvent = _ref3.btEvent;
        return {
          name: btEvent,
          from: "" + fromIndex,
          to: "" + btIndex
        };
      });

      transitions.push.apply(transitions, _toConsumableArray(backtracksFromHere));
    });

    var backtracksFromFinalState = backtracks.map(function (_ref4) {
      var btIndex = _ref4.btIndex,
          btEvent = _ref4.btEvent;
      return {
        name: btEvent,
        from: "" + finalState,
        to: "" + btIndex
      };
    });

    this.machine = _javascriptStateMachine.default.create({
      initial: "0",
      events: [].concat(transitions, _toConsumableArray(backtracksFromFinalState)),
      callbacks: Object.assign({}, dataCollectingCallbacks, _defineProperty({}, "onafter" + finalEvent, function undefined() {
        trackingCallback(Object.values(trackingData).reduce(function (acc, obj) {
          return Object.assign(acc, obj);
        }, {}));
      }))
    });
  }

  // this method is safe for irrelevant events, unlike the underlying state machine implementation
  Story.prototype.send = function (event, data) {
    if (this.machine.can(event)) {
      return this.machine[event](data);
    }
  };

  Story.prototype.getEvents = function () {
    return [].concat(_toConsumableArray(new Set(this._events)));
  };

  /**
   * a convenience function that produces objects suitable for the events list passed into the Story constructor
   *
   * @param {String} event
   * @param {String?} cancelEvent
   */
  function event(event, cancelEvent) {
    return {
      event: "" + event,
      cancelEvent: cancelEvent && "" + cancelEvent
    };
  }

  exports.Story = Story;
  exports.event = event;
  exports.default = Story;
});