define("copilot/models/attr", ["exports", "lodash-es"], function (exports, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SEARCH_FILTER_OBJECT = {
    tags: [],
    types: [],
    channel: [],
    subchannel: [],
    categoryIds: [],
    contributor: [],
    nottags: [],
    contentSource: "",
    issueDate: ""
  };

  /**
   * This simply logs a coercion error
   */
  function log(_ref) {
    var attr = _ref.attr,
        value = _ref.value;

    var printValue = value;

    // convert some values to a string for easy logging.
    if (value === undefined) {
      printValue = "undefined";
    } else if (value === null) {
      printValue = "null";
    }

    var errorMessage = "application is coercing in the " + attr + " attr because it received a(n) " + Ember.typeOf(value) + " with value of '" + printValue.toString() + "'";
    if (window.consoleLoggerService) {
      window.consoleLoggerService.error(errorMessage);
    }
  }

  /**
   * attrDebounceTrackers is meant to namespace the debounced function so it only debounces on the same attr
   * and will not conflict by debouncing different attrs.
   */
  var attrDebounceTrackers = {};

  function logCoercion(attr, value) {
    // Set the namespaced debounce tracker if it doesn't exist.
    if (!attrDebounceTrackers[attr]) {
      attrDebounceTrackers[attr] = { namespace: attr };
    }

    // Get the debounce namespace tracker and use it in the debounce call as the target object
    // so the debounce function listens to only that attr's debounce.
    // This will still interfere if two different properties on the object use the same attr (string, for example)
    // but atleast this will give each attr type a chance to log.
    var attrTracker = attrDebounceTrackers[attr];
    Ember.run.debounce(attrTracker, log, { attr: attr, value: value }, 5000);
  }

  exports.default = {
    price: {
      deserialize: function deserialize(num) {
        if (typeof num !== "number") {
          return;
        }

        return num.toFixed(2);
      }
    },

    boolean: {
      deserialize: function deserialize(bool) {
        return bool === true;
      }
    },

    str: {
      serialize: function serialize(string) {
        var input = string;

        if (!string) {
          return "";
        }

        if (input !== string) {
          logCoercion("str", input);
        }
        return string;
      },
      deserialize: function deserialize(string) {
        if (!string) {
          return "";
        }
        return string;
      }
    },

    array: {
      serialize: function serialize(obj) {
        return obj;
      },
      deserialize: function deserialize(obj) {
        return obj || Ember.A();
      }
    },

    categories: {
      name: "categories",
      serialize: function serialize(input) {
        var returnValue = input || Ember.A();

        if (input !== returnValue) {
          logCoercion("categories", input);
        }

        return returnValue;
      },
      deserialize: function deserialize(obj) {
        return obj || Ember.A();
      }
    },

    obj: {
      serialize: function serialize(input) {
        var returnValue = input || {};

        if (input !== returnValue) {
          logCoercion("obj", input);
        }
        return returnValue;
      },
      deserialize: function deserialize(obj) {
        return obj || {};
      }
    },

    // revision should be renamed to number
    revision: {
      serialize: function serialize(number) {
        var input = number;

        var returnValue = void 0;
        if (!number && number !== 0) {
          returnValue = 0;
        } else {
          returnValue = Number(number);
        }

        if (input !== returnValue) {
          logCoercion("revision", input);
        }

        return returnValue;
      },
      deserialize: function deserialize(number) {
        if (!number && number !== 0) {
          return 0;
        }
        return Number(number);
      }
    },

    date: {
      serialize: function serialize(date) {
        var input = date;
        var returnValue = void 0;

        if (!date) {
          returnValue = null;
        } else if (typeof date === "string") {
          returnValue = new Date(date);
        }

        if (input !== date) {
          logCoercion("date", input);
        }

        return returnValue.toISOString();
      },
      deserialize: function deserialize(string) {
        if (!string) {
          return new Date();
        }
        return new Date(string);
      }
    },

    address: {
      serialize: function serialize(obj) {
        return obj;
      },
      deserialize: function deserialize(obj) {
        var blankAddress = {
          street: "",
          state: "",
          city: "",
          neighborhood: "",
          country: "",
          postalCode: ""
        };
        return obj || blankAddress;
      }
    },

    review: {
      serialize: function serialize(obj) {
        Object.keys(obj).forEach(function (key) {
          Ember.set(obj, key, parseFloat(Ember.get(obj, key)));
        });

        return obj;
      },
      deserialize: function deserialize(obj) {
        var blankReview = {
          performance: 0,
          innovation: 0,
          lookSoundFeel: 0,
          demand: 0
        };
        return obj || blankReview;
      }
    },

    ratingBlock: {
      serialize: function serialize(obj) {
        Object.keys(obj).forEach(function (feature) {
          var rating = Ember.get(obj, feature);
          if (!rating) {
            logCoercion("ratingBlock", rating);
            Ember.set(obj, feature, null);
          } else {
            Ember.set(obj, feature, parseFloat(rating));
          }
        });
        return obj;
      },
      deserialize: function deserialize(obj) {
        var blankRating = {
          overall: null,
          rooms: null,
          service: null,
          food: null,
          location: null,
          design: null,
          activities: null
        };
        return obj || blankRating;
      }
    },

    geo: {
      serialize: function serialize(obj) {
        if (obj) {
          return {
            lat: obj.lat ? parseFloat(obj.lat) : 0,
            lng: obj.lng ? parseFloat(obj.lng) : 0
          };
        }
      },
      deserialize: function deserialize(obj) {
        var pos = {
          lat: 0,
          lng: 0
        };
        return obj || pos;
      }
    },

    entityMetadata: {
      serialize: function serialize(obj) {
        if (obj.users) {
          obj.user = obj.users;
          obj = _lodashEs.default.omit(obj, "users");
        }
        return obj;
      },
      deserialize: function deserialize(obj) {
        if (obj && obj.user) {
          obj.users = _lodashEs.default.isArray(obj.user) ? obj.user : [obj.user];
          obj = _lodashEs.default.omit(obj, "user");
        } else {
          obj = obj || {};
          obj.users = Ember.A();
        }
        return obj;
      }
    },

    additionalFilters: function additionalFilters(path, filterName) {
      return Ember.computed(path, {
        get: function get() {
          var additionalFilters = this.get(path) || [];
          var filter = additionalFilters.findBy("key", filterName);
          if (Ember.isPresent(filter)) {
            return filter.value || [];
          }
          filter = {
            key: filterName,
            value: []
          };
          additionalFilters.pushObject(filter);
          return filter.value;
        },
        set: function set(key, value) {
          var additionalFilters = this.get(path) || [];
          var filter = additionalFilters.findBy("key", filterName);
          if (Ember.isPresent(filter)) {
            additionalFilters.forEach(function (filter) {
              if (filter.key === filterName) {
                filter.value = value;
              }
            });
          } else {
            additionalFilters.pushObject({
              key: filterName,
              value: value
            });
          }
          return value;
        }
      });
    },


    filters: {
      serialize: function serialize(obj) {
        return obj;
      },
      deserialize: function deserialize(obj) {
        var filters = _lodashEs.default.cloneDeep(SEARCH_FILTER_OBJECT);
        if (!obj) {
          return filters;
        }

        return Object.assign({}, filters, obj);
      }
    },

    query: function query() {
      var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _lodashEs.default.cloneDeep(SEARCH_FILTER_OBJECT);

      return {
        serialize: function serialize(obj) {
          Ember.set(obj, "size", Number(Ember.get(obj, "size")));
          return obj;
        },
        deserialize: function deserialize() {
          var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

          var defaultObj = {
            title: "",
            q: "",
            size: 10,
            sort: "publishDate desc",
            filters: filters,
            additionalFilters: []
          };
          var mergedFilters = Object.assign({}, filters, obj.filters);
          return Object.assign({}, defaultObj, obj, {
            filters: mergedFilters
          });
        }
      };
    },


    metadata: {
      serialize: function serialize(obj) {
        return obj;
      },
      deserialize: function deserialize() {
        var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (!obj.publishData) {
          obj.publishData = {};
        }
        return obj;
      }
    },

    seller: {
      serialize: function serialize(obj) {
        return obj;
      },
      deserialize: function deserialize(obj) {
        var seller = {
          name: ""
        };
        return obj || seller;
      }
    }
  };
});