define("copilot/brands/gq/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      preference: "Preference",
      ingredientProducts: "Ingredient Products"
    },
    fieldsetLegends: {
      manufacturer: "Manufacturer"
    }
  };
});