define("copilot/components/concurrent-edit/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    participants: Ember.inject.service(),
    classNames: ["concurrent-edit"],
    open: false,

    _popperTarget: Ember.computed(function () {
      return document.querySelector("#slack-messanger");
    }),

    onOutsideClick: function onOutsideClick() {
      this.set("open", false);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var open = this.get("open");
      var $element = this.$();
      var $target = Ember.$(event.target);

      if (open && !$target.closest($element).length) {
        this.onOutsideClick();
      }
    },
    didRender: function didRender() {
      this.set("clickHandler", Ember.run.bind(this, this.handleOutsideClick));
      Ember.$(document).click(this.get("clickHandler"));
    },
    didInsertElement: function didInsertElement() {
      var room = this.room;
      if (!Ember.get(this, "participants.hasJoined")) {
        this.shouldLeaveRoom = true;
        this.participants.join({
          id: Ember.get(this, "currentUser.id"),
          uid: Ember.get(this, "currentUser.uid"),
          fullName: Ember.get(this, "currentUser.fullName"),
          space: {
            id: room,
            brand: this.brand
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set("open", false);
      Ember.$(document).off("click", this.get("clickHandler"));

      if (this.shouldLeaveRoom) {
        this.participants.leave();
      }
    },


    arrangedUsers: Ember.computed("users.[]", "currentUser", "lockedBy", function () {
      var currentUser = this.currentUser;
      var lockedBy = this.lockedBy;
      var users = (this.users || []).filter(function (user) {
        return Ember.get(user, "uid") !== Ember.get(currentUser, "uid") && Ember.get(user, "uid") !== Ember.get(lockedBy || {}, "uid");
      }).uniqBy("id").sortBy("joinedAt").reverse();

      if (lockedBy && Ember.get(lockedBy, "uid") !== Ember.get(currentUser, "uid")) {
        users.push(lockedBy);
      }

      return users;
    }),

    actions: {
      toggleOpen: function toggleOpen() {
        var open = this.get("open");
        this.set("open", !open);
        this.analytics.track("concurrentEdit", "toggle-open", {
          currentUser: this.currentUser.userName,
          brand: this.brand
        });
      },
      slackMessageAnalytics: function slackMessageAnalytics(user) {
        this.analytics.track("concurrentEdit", "slack-message-analytics", {
          userId: user.id,
          userName: user.uid,
          brand: this.brand
        });
      }
    }
  });
});