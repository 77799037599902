define("copilot/models/curatedsearch", ["exports", "ember-model", "copilot/models/base", "copilot/models/attr"], function (exports, _emberModel, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var additionalFilters = _attr.default.additionalFilters,
      query = _attr.default.query;


  var CuratedSearch = _base.default.extend({
    query: (0, _emberModel.attr)(query()), // query.filters.categoryIds contains category objects, not IDs

    langFilter: additionalFilters("query.additionalFilters", "lang"),

    notCategoryIds: additionalFilters("query.additionalFilters", "notcategoryIds"),

    filterCategories: Ember.computed("query.filters.categoryIds.[]", {
      get: function get() {
        return this.get("query.filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") !== "tags" && category.get("rootSlug") !== "channels";
        });
      },
      set: function set(key, value) {
        var newCategoryIds = this.filterCategoriesTags.concat(value, this.filterCategoriesChannels);
        this.set("query.filters.categoryIds", newCategoryIds);
        return value;
      }
    }),

    filterCategoriesTags: Ember.computed("query.filters.categoryIds.[]", {
      get: function get() {
        return this.get("query.filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") === "tags";
        });
      },
      set: function set(key, value) {
        var notTagCategoryIds = this.get("query.filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") !== "tags";
        });
        var newCategoryIds = notTagCategoryIds.concat(value);
        this.set("query.filters.categoryIds", newCategoryIds);
        return value;
      }
    }),

    filterCategoriesChannels: Ember.computed("query.filters.categoryIds.[]", {
      get: function get() {
        return this.get("query.filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") === "channels";
        });
      },
      set: function set(key, value) {
        var notChannelCategoryIds = this.get("query.filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") !== "channels";
        });
        var newCategoryIds = notChannelCategoryIds.concat(value);
        this.set("query.filters.categoryIds", newCategoryIds);
        return value;
      }
    }),

    excludeCategories: Ember.computed("notCategoryIds.[]", {
      get: function get() {
        return this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") !== "tags" && category.get("rootSlug") !== "channels";
        });
      },
      set: function set(key, value) {
        var newNotCategoryIds = this.excludeCategoriesTags.concat(this.excludeCategoriesChannels, value);
        this.set("notCategoryIds", newNotCategoryIds);
        return value;
      }
    }),

    excludeCategoriesTags: Ember.computed("notCategoryIds.[]", {
      get: function get() {
        return this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") === "tags";
        });
      },
      set: function set(key, value) {
        var notTagCategories = this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") !== "tags";
        });
        var newNotCategoryIds = notTagCategories.concat(value);
        this.set("notCategoryIds", newNotCategoryIds);
        return value;
      }
    }),

    excludeCategoriesChannels: Ember.computed("notCategoryIds.[]", {
      get: function get() {
        return this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") === "channels";
        });
      },
      set: function set(key, value) {
        var notChannelCategories = this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") !== "channels";
        });
        var newNotCategoryIds = notChannelCategories.concat(value);
        this.set("notCategoryIds", newNotCategoryIds);
        return value;
      }
    })
  });

  exports.default = CuratedSearch;
});