define("copilot/components/livestoryupdate-header/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ANIMATION_DURATION = 2000;

  exports.default = Ember.Component.extend({
    classNames: ["livestoryupdate-header"],
    currentURL: Ember.computed(function () {
      return window.location.href;
    }),
    toggleCopyAnimation: function toggleCopyAnimation() {
      this.set("copied", true);
      Ember.run.later(this, function () {
        this.set("copied", false);
      }, ANIMATION_DURATION);
    },

    actions: {
      copySuccess: function copySuccess() {
        this.toggleCopyAnimation();
      }
    }
  });
});