define("copilot/controllers/articles/index", ["exports", "copilot/controllers/base", "copilot/mixins/seo"], function (exports, _base, _seo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _base.default.extend(_seo.default, {
    publish: Ember.inject.service(),
    assetFinderConfig: Ember.computed.reads("brandConfig.afm.article.body"),

    associatedContentImageUrlContent: Ember.computed.reads("brandConfig.article.associatedContent.imageUrl.content"),

    isEditingNotification: false,

    actions: {
      unpublishNotification: function unpublishNotification() {
        var pushnotification = this.get("model.pushnotificationsAppleNews.firstObject");
        return this.publish.unpublish(pushnotification); // Expire a previously scheduled notification
      },
      processNotification: function () {
        var _ref = _asyncToGenerator(function* () {
          var _this = this;

          var pushnotification = this.get("model.pushnotificationsAppleNews.firstObject");
          var queued = pushnotification.get("_embedded.queued");

          pushnotification.validate("publish").then(function () {
            var _ref2 = _asyncToGenerator(function* (isValid) {
              if (isValid) {
                yield _this.saveService.save(pushnotification); // Save pushnotification

                var pubData = {
                  uri: "pushnotifications/" + pushnotification.get("id"),
                  pubDate: pushnotification.get("pubDate")
                };

                yield _this.save(); // Save article

                if (queued) {
                  yield _this.publish.unpublish(pushnotification); // Expire a previously scheduled notification
                }

                yield _this.publish.publish(pushnotification, pubData); // Publish pushnotification
                _this.set("isEditingNotification", false);
              }
            });

            return function (_x) {
              return _ref2.apply(this, arguments);
            };
          }());
        });

        function processNotification() {
          return _ref.apply(this, arguments);
        }

        return processNotification;
      }()
    }
  });
});