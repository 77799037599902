define("copilot/validations/brands/vogue/review", ["exports", "@condenast/cross-check-dsl"], function (exports, _crossCheckDsl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var ReviewValidationBuilder = object({});

  exports.default = ReviewValidationBuilder;
});