define("copilot/brands/tny/routes/cartoons/create", ["exports", "copilot/routes/authenticated-route", "ember-service-methods/inject"], function (exports, _authenticatedRoute, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    uploadAsset: (0, _inject.default)(),

    actions: {
      createCartoon: function createCartoon(file) {
        return this.uploadAsset(file, {}, "cartoon");
      }
    }
  });
});