define("copilot/schemas/brands/lci/venue", ["exports", "@condenast/copilot-brand-overrides/dist/modules/schemas/lci/venue"], function (exports, _venue) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _venue.default;
    }
  });
});