define("copilot/components/create-media/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    assets: null,
    errors: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("assets", []);
      this.set("errors", []);
    },


    actions: {
      onfileadd: function onfileadd(file) {
        var _this = this;

        var assets = Ember.get(this, "assets");
        var response = Ember.get(this, "onfileadd")(file);
        Ember.set(this, "assets", assets.concat([response.model]));

        return response.then(function (model) {
          var assets = Ember.get(_this, "assets").slice();
          var oldModel = assets.findBy("id", Ember.get(model, "id"));
          assets.splice(assets.indexOf(oldModel), 1, model);
          Ember.set(_this, "assets", assets);
        }, function (errors) {
          Ember.set(_this, "errors", errors);
        });
      }
    }
  });
});