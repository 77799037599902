define("copilot/models/bundleconfig", ["exports", "copilot/models/attr", "copilot/models/base"], function (exports, _attr, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr,
      hasMany = Ember.hasMany;


  var Bundleconfig = _base.default.extend({
    type: "bundleconfig",
    id: attr(_attr.default.str),
    label: attr(_attr.default.str),
    value: attr(_attr.default.str),
    subtypes: hasMany("bundleconfig-subtype", {
      embedded: true
    }),
    containers: hasMany("bundleconfig-container", {
      embedded: true
    }),
    templates: attr(_attr.default.array),

    toString: function toString() {
      return "Bundle Configs Model";
    }
  });

  Bundleconfig.reopenClass({
    url: "/api/configs"
  });

  exports.default = Bundleconfig;
});