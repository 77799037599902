define("copilot/services/save", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * @function runBeforeSaveHooks
   * @param {EmberObject} model
   * @return {Promise}
   * @private
   */
  function runBeforeSaveHooks(model) {
    model.get("beforeSaveHooks").forEach(function (func) {
      func.call(model);
    });

    return Promise.resolve();
  }

  exports.default = Ember.Service.extend({
    assign: Ember.inject.service("assign"),
    participants: Ember.inject.service(),

    /**
     * Save a model
     * This is where workflow steps can be found that are required for saving
     * @param {EmberObject} model
     */
    save: function save(model) {
      var _this = this;

      this.assign.assignCollaborators(model);
      var metaData = {};

      if (model.metadata) {
        metaData = {
          author: model.metadata.author,
          revision: model.metadata.revision
        };
      }

      var participant = Ember.get(this.participants, "_participation");

      return runBeforeSaveHooks(model).then(model.save.bind(model)).then(model.afterSaveWorker.bind(model, model.get("isNew"))).then(function () {
        _this.participants.save(Object.assign({}, participant, {
          metaData: metaData
        }));
      }).catch(function (err) {
        Ember.onerror(err);
        throw err;
      });
    }
  });
});