define("copilot/validations/brands/lci/video", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  // Allows to append new rules to core article rules
  var videoValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).andAlso(object({
    videoId: (0, _copilotValidators.required)().on("publish"),
    videoOrigin: (0, _copilotValidators.required)().on("publish")
  })).catch((0, _crossCheckDsl.mutePath)(["body"])).catch((0, _crossCheckDsl.mutePath)(["photosTout"])).catch((0, _crossCheckDsl.mutePath)(["categoriesChannels"])); // Catch errors from core rules and silence any body errors

  // Returns the validation descriptors
  exports.default = videoValidationBuilder;
});