define("copilot/brands/vogue/models/fashionshowevent", ["exports", "ember-model", "copilot/models/base"], function (exports, _emberModel, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FashionShowEventModel = _base.default.extend({
    type: "fashionshowevent",

    eventDate: (0, _emberModel.attr)({
      defaultValue: new Date()
    }),
    address: (0, _emberModel.attr)(),
    instantShow: (0, _emberModel.attr)(Boolean, {
      defaultValue: false
    }),
    livestream: (0, _emberModel.attr)(Boolean, {
      defaultValue: false
    }),
    brand: (0, _emberModel.hasMany)("brand", {
      embedded: true
    }),
    city: (0, _emberModel.hasMany)("city", {
      embedded: true
    }),
    season: (0, _emberModel.hasMany)("season", {
      embedded: true
    })
  });

  FashionShowEventModel.reopenClass({
    url: "/api/fashionshowevents"
  });

  exports.default = FashionShowEventModel;
});