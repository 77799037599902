define("copilot/brands/vogue/controllers/fashiongalleries/photo", ["exports", "copilot/controllers/galleries/child", "copilot/brands/vogue/mixins/vogue-gallery-child"], function (exports, _child, _vogueGalleryChild) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _child.default.extend(_vogueGalleryChild.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set("modelsToSave", ["model"]);
    },

    photoId: Ember.computed.reads("model.id"),
    filename: Ember.computed.reads("model.filename"),
    selected: Ember.computed.reads("fashiongallery.currentSelection")
  });
});