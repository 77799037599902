define("copilot/brands/bon/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      active: "Active",
      "address.street": "Street Name",
      "address.city": "City",
      "address.neighborhood": "Neighborhood",
      "address.state": "State",
      "address.postalCode": "Postal Code",
      "address.country": "Country",
      aggregateRating: "Rating",
      byline: "Byline",
      categories: "Categories",
      cookbooks: "Cookbooks",
      copyrightDisplay: "Copyright Display",
      copyrightYear: "Copyright Year",
      "geo.lat": "Latitude",
      "geo.lng": "Longitude",
      imageSocial: "Social Image",
      ingredientProducts: "Ingredient Products",
      paymentsAccepted: "Payments Accepted",
      phoneNumber: "Phone Number",
      photosBadge: "Badge",
      photosPromoTout: "Promo Tout",
      products: "Products",
      publisher: "Publisher",
      recipeType: "Recipe Type",
      relatedVideo: "Related Video",
      reservationLink: "Reservation Link",
      rotdDate: "Recipe of the Day",
      socialShortUrl: "Short URL",
      subType: "Sub Type",
      taxonomy: "Taxonomy",
      venue: "Venue",
      videosTout: "Promo Video",
      willMakeAgainPct: "Make it again (%)"
    },
    fieldsetLegends: {
      address: "Address",
      contactInformation: "Contact Information",
      highlight: "Highlight",
      highlightsGallery: "Highlights Gallery",
      mainTout: "Promo Image",
      meta: "Meta",
      paymentInformation: "Payment Information",
      readOnlyInformation: "Read-only Information",
      section1: "Section 1",
      section2: "Section 2",
      section3: "Section 3",
      section4: "Section 4",
      section5: "Section 5"
    },
    placeholder: {
      packages: {
        hed: "New Package"
      },
      recipes: {
        issueDate: "YYYY-MM-DD"
      },
      cookbooks: {
        hed: "New Cookbook"
      },
      taxonomies: {
        name: "New Taxonomy"
      },
      venues: {
        hed: "New Venue",
        issueDate: "YYYY-MM-DD"
      }
    }
  };
});