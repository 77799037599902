define("copilot/components/byline-preview/component", ["exports", "copilot/lib/brands"], function (exports, _brands) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["byline-preview"],
    classNameBindings: ["model.bylineOption"],

    displayBrand: Ember.computed("model.displayBrand", function () {
      var displayBrandCode = this.get("model.displayBrand") || this.get("configService.activeCnBrandCode");
      if (displayBrandCode) {
        return _brands.default.byCode(displayBrandCode).human;
      }
    }),

    advertiserLogo: Ember.computed.alias("model.advertiser.firstObject.photosLogo.firstObject"),
    advertiserName: Ember.computed.alias("model.advertiser.firstObject.name")
  });
});