define("copilot/routes/preview", ["exports", "copilot/routes/authenticated-route", "copilot/models/base", "lodash-es"], function (exports, _authenticatedRoute, _base, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var trackedPageTypes = ["gallery", "article"];
  exports.default = _authenticatedRoute.default.extend({
    model: function model(params) {
      var store = this.store;
      var previewModel = store.find(params.type, params.id);
      return _lodashEs.default.extend(previewModel, _base.default);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var modelType = this.modelFor(this.routeName).type;
        if (trackedPageTypes.indexOf(modelType > -1)) {
          window.dataLayer[0].content.pageType = "preview | " + modelType;
        }
      }
    }
  });
});