define("copilot/components/push-notifications/display/component", ["exports", "copilot/lib/copilot-computeds"], function (exports, _copilotComputeds) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var firstMatch = _copilotComputeds.default.firstMatch;


  var PUBLISHED_KEYS = ["notification._embedded.publishHistory", "notification.publishHistory.data.0"];

  var QUEUED_KEYS = ["notification._embedded.queued", "notification.queued.data.0"];

  exports.default = Ember.Component.extend({
    message: Ember.computed.reads("notification.message"),
    isScheduled: Ember.computed.notEmpty("queuedStatus"),
    isPublished: Ember.computed("publishedStatus", function () {
      var publishedStatus = this.publishedStatus;
      return !Ember.isEmpty(publishedStatus) && !publishedStatus.expired;
    }),
    statusText: Ember.computed("isScheduled", "isPublished", function () {
      var isPublished = this.isPublished;
      var isScheduled = this.isScheduled;

      if (isScheduled) {
        return "scheduled";
      }

      if (isPublished) {
        return "published";
      }

      return "draft";
    }),
    queuedStatus: firstMatch.apply(undefined, QUEUED_KEYS),
    publishedStatus: firstMatch.apply(undefined, PUBLISHED_KEYS),
    publishRecord: firstMatch.apply(undefined, QUEUED_KEYS.concat(PUBLISHED_KEYS))
  }).reopenClass({
    positionalParams: ["notification"]
  });
});