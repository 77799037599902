define("copilot/components/nested-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "ul",
    classNames: ["nested-list"],
    classNameBindings: ["isFirstLevel"],
    isFirstLevel: Ember.computed.equal("nestedDepth", 1),
    nestedDepth: 1
  });
});