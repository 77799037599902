define("copilot/services/schema-helpers", ["exports", "copilot/lib/brand-resolver"], function (exports, _brandResolver) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    lookup: function lookup(helperName) {
      var owner = Ember.getOwner(this);
      var brand = (0, _brandResolver.getResolverParentBrand)(this.activeBrand);
      var brandPath = "schema-helper:brands/" + brand + "/" + helperName;
      var companyId = (0, _brandResolver.getResolverCompany)(brand);
      var companyPath = "schema-helper:brands/" + companyId + "/" + helperName;
      var corePath = "schema-helper:" + helperName;
      return owner.lookup(brandPath) || owner.lookup(companyPath) || owner.lookup(corePath);
    }
  });
});