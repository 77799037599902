define("copilot/helpers/list-to-translated-options", ["exports", "copilot-components/helpers/list-to-translated-options"], function (exports, _listToTranslatedOptions) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _listToTranslatedOptions.default;
    }
  });
});