define("copilot/components/navigation-sidebar/dropdown-menu/nav-link/component", ["exports", "copilot/components/navigation-sidebar/dropdown-menu/nav-link/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: ""
  }).reopenClass({
    positionalParams: "params"
  });
});