define("copilot/services/console-logger", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    logger: Ember.inject.service(),

    error: function error() {
      var _logger;

      if (console && console.error) {
        var _console;

        (_console = console).error.apply(_console, arguments);
      }
      (_logger = this.logger).error.apply(_logger, arguments);
    },
    warn: function warn() {
      var _logger2;

      if (console && console.warn) {
        var _console2;

        (_console2 = console).warn.apply(_console2, arguments);
      }
      (_logger2 = this.logger).warn.apply(_logger2, arguments);
    }
  });
});