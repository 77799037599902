define("copilot/components/category-selections/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    roots: Ember.computed("selections", function () {
      return Object.keys(this.selections);
    }),

    filteredSelections: Ember.computed("selections", function () {
      var notHierarchies = this.notHierarchies;
      var selections = this.selections;
      if (!(notHierarchies && notHierarchies.length)) {
        return selections;
      }
      var allowedSelections = {};
      for (var root in selections) {
        var filteredRoot = this.filterCategories(selections[root], notHierarchies);
        if (filteredRoot.length) {
          allowedSelections[root] = filteredRoot;
        }
      }
      return allowedSelections;
    }),

    actions: {
      deselect: function deselect(category) {
        if (category.type !== "category") {
          return;
        }
        var newSelections = Object.assign({}, this.selections);
        var root = category.rootSlug;

        newSelections[root] = newSelections[root].filter(function (x) {
          return x.id !== category.id;
        });

        this.onupdate(newSelections);
      }
    }
  });
});