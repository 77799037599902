define("copilot/validations/brands/vogue/product", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;
  exports.default = (0, _crossCheckDsl.extend)(_copilotCore.ProductValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["categoriesChannels"])).catch((0, _crossCheckDsl.mutePath)(["photosTout"])).catch((0, _crossCheckDsl.mutePath)(["offers"])).andAlso(object({
    offers: (0, _copilotValidators.hasValidChildren)({ childType: "offer" })
  }));
});