define("copilot/instance-initializers/global-flash", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var flashMessages = appInstance.lookup("service:flash-messages");
    window.flashMessages = flashMessages;
  }

  exports.default = {
    name: "global-flash",
    initialize: initialize
  };
});