define("copilot/brands/vogue/models/designer-item", ["exports", "copilot/brands/vogue/models/previous-designer-item"], function (exports, _previousDesignerItem) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  exports.default = _previousDesignerItem.default.extend({
    type: "designer",

    title: attr()
  });
});