define("copilot/services/schema-to-model", ["exports", "@condenast/cross-check-schema", "@condenast/copilot-core", "copilot/models/attr", "ember-model", "copilot/lib/brand-resolver"], function (exports, _crossCheckSchema, _copilotCore, _attr, _emberModel, _brandResolver) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var array = _attr.default.array,
      str = _attr.default.str,
      obj = _attr.default.obj;


  var POLYMORPHIC_TYPES = ["base", "media"];
  var NUMBER_TYPES = ["Integer"];
  var STRING_TYPES = ["SingleLine", "SingleWord", "Text"];

  class ModelFormatter {
    constructor(registry) {
      this.registry = registry;
      this.visitor = _crossCheckSchema.visitor.RecursiveVisitor.build(this);
    }

    record(record) {
      var members = {};
      this.visitor.processDictionary(record, function (item, key) {
        members[key] = item;
      });
      members.type = record.name;
      return members;
    }

    required(inner) {
      return inner;
    }

    generic(entity, descriptor) {
      var item = void 0;
      var type = entity;
      var polymorphic = POLYMORPHIC_TYPES.includes(type);

      if (descriptor.type === "Pointer") {
        var embedded = descriptor.metadata && descriptor.metadata.embedded === "always" ? "always" : true;
        item = (0, _emberModel.belongsTo)(type, {
          embedded: embedded,
          polymorphic: polymorphic
        });
      } else if (descriptor.type === "Iterator") {
        item = (0, _emberModel.hasMany)(type, {
          embedded: true,
          polymorphic: polymorphic
        });
      } else if (descriptor.type === "List") {
        if (entity.type === "Primitive") {
          item = (0, _emberModel.attr)(array);
        } else {
          item = (0, _emberModel.hasMany)(type, {
            embedded: "always"
          });
        }
      }
      return item;
    }

    alias(descriptor) {
      return descriptor.name;
    }

    primitive(descriptor, position) {
      if ((0, _crossCheckSchema.inList)(position)) {
        return descriptor;
      }
      var name = descriptor.name;

      var type = void 0;
      if (STRING_TYPES.includes(name)) {
        type = str;
      } else if (NUMBER_TYPES.includes(name)) {
        type = Number;
      }
      var item = (0, _emberModel.attr)(type);
      item._meta.schemaType = name;
      return item;
    }

    list() {
      var list = (0, _emberModel.attr)(array);
      list._meta.schemaType = "List";
      return list;
    }

    dictionary() {
      return (0, _emberModel.attr)(obj);
    }
  }

  exports.default = Ember.Service.extend({
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.registry = _copilotCore.REGISTRY.clone({
        record: function record(type) {
          if (type.constructor.name === "RecordBuilder") {
            type = type.name;
          }
          var record = _this.schemaFor(type);

          if (record) {
            return {
              dictionary: record.members,
              metadata: record.metadata
            };
          }
        }
      });
      this.schemas = {};
    },
    toModel: function toModel(record) {
      var model = _crossCheckSchema.visitor.recursive(ModelFormatter)(record, this.registry);
      return model;
    },
    schemaFor: function schemaFor(type) {
      if (this.schemas[type]) {
        return this.schemas[type];
      }
      var owner = Ember.getOwner(this);
      var brand = (0, _brandResolver.getResolverParentBrand)(this.activeBrand);
      var companyId = (0, _brandResolver.getResolverCompany)(brand);
      var brandPath = "schema:brands/" + brand + "/" + type;
      var companyPath = "schema:brands/" + companyId + "/" + type;
      var corePath = "schema:" + type;
      var schema = owner.lookup(brandPath) || owner.lookup(companyPath) || owner.lookup(corePath);
      if (schema) {
        this.schemas[type] = schema;
      }
      return schema;
    },
    modelFor: function modelFor(type) {
      var record = this.schemaFor(type);
      if (record && record.members && record.members.members && Object.keys(record.members.members).length) {
        return [this.toModel(record), record.metadata];
      } else {
        return [];
      }
    }
  });
});