define("copilot/brands/vogue/components/fashionshow-result-item/component", ["exports", "copilot/brands/vogue/mixins/existing-gallery-rels"], function (exports, _existingGalleryRels) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_existingGalleryRels.default, {
    tagName: "li",
    classNames: ["card", "color-white", "dashboard-list-item", "clear"],

    publishStatus: Ember.computed("mainModel", function () {
      var PublishStatus = Ember.getOwner(this).factoryFor("model:publish-status");
      var model = this.model;
      return PublishStatus.create({ model: model });
    }),

    fashionshow: Ember.computed.reads("model.content"),

    hasReview: Ember.computed.bool("fashionshow.review.length"),

    reviewId: Ember.computed.reads("fashionshow.review.firstObject.id"),

    brand: Ember.computed.reads("model.brand.firstObject.name"),

    actions: {
      toggleAttr: function toggleAttr(attribute) {
        var model = this.model;
        var rePublish = Ember.get(this, "publishStatus.isPublished") && !Ember.get(this, "publishStatus.hasPendingRevision");
        return model.toggleAndSave(attribute, rePublish);
      }
    }
  });
});