define("copilot/helpers/relative-href-to", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.relativeHrefTo = relativeHrefTo;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy
    var targetRouteName = params.shift(); // the first param is always the target route name
    var lastParam = params[params.length - 1]; // the last param might be queryParams
    var queryParams = void 0;
    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }
    var models = params; // the remainder are the models
    return [targetRouteName, models, queryParams];
  }
  function relativeHrefTo(context, params) {
    var router = Ember.getOwner(context).lookup("service:router");
    var routing = Ember.getOwner(context).lookup("service:-routing");

    var _getParamsForGenerate = getParamsForGenerateURL(params),
        _getParamsForGenerate2 = _toArray(_getParamsForGenerate),
        targetRouteName = _getParamsForGenerate2[0],
        routeParams = _getParamsForGenerate2.slice(1);

    var partialURL = routing.generateURL.apply(routing, [targetRouteName].concat(_toConsumableArray(routeParams)));

    var _router$get$getURL$sp = router.get("location").getURL().split("?"),
        _router$get$getURL$sp2 = _slicedToArray(_router$get$getURL$sp, 1),
        currentURLWithoutSearch = _router$get$getURL$sp2[0];

    var _currentURLWithoutSea = currentURLWithoutSearch.split("#"),
        _currentURLWithoutSea2 = _slicedToArray(_currentURLWithoutSea, 1),
        currentURLWithoutHashOrSearch = _currentURLWithoutSea2[0];

    var href = currentURLWithoutHashOrSearch + "/" + partialURL.split("/").slice(2).join("/");
    return href;
  }

  exports.default = Ember.Helper.extend({
    compute: function compute(params, options) {
      return relativeHrefTo(this, options.params || params);
    }
  });
});