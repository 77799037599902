define("copilot/components/sortable-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNames: ["sortable-item"],
    classNameBindings: ["gridItemClass"],
    attributeBindings: ["data-id"],
    "data-id": Ember.computed.reads("item.id")
  });
});