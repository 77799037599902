define("copilot/models/gallery-item", ["exports", "ember-model"], function (exports, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberModel.Model.extend({
    type: "gallery-item",

    contributorTypeOptionsKey: "contributorTypeOptions.photo",

    id: Ember.computed.reads("embedded.id"),
    filename: Ember.computed.or("embedded.filename", "embedded.photosTout.firstObject.filename"),
    embeddedPhoto: Ember.computed.or("embedded.imageUrl", "embedded._embedded.photosTout.firstObject.fields", "embedded.photosTout.firstObject", "embedded"),

    photo: Ember.computed("embedded", function () {
      return this.get("embedded.aspectRatios") || this.get("embedded._embedded.photosTout.firstObject.fields.aspectRatios");
    }),

    mixedCaptionObserver: Ember.observer("slideCaption", function () {
      this.set("caption", this.slideCaption);
    }),

    // Alias class autogenerates metadata on instance for use
    // in templates, etc.
    autogenerates: Ember.computed.reads("constructor.metadata.autogenerates")
  });
});