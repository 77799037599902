define("copilot/router", ["exports", "ember-inflector", "copilot/config/environment"], function (exports, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    analytics: Ember.inject.service(),
    v2Redirect: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    trackPageView: Ember.on("didTransition", function () {
      this.analytics.page();
    }),

    willTransition: function willTransition(_oldState, _newState, transition) {
      this._super.apply(this, arguments);

      this.v2Redirect.maybeRedirectTransition(transition);
    },


    // We need to both depend on concreteImplementation and location path
    // because the `none` location used in testing does not expose a
    // concreteImplementation while the 'auto' location used in production
    // code can only respond to `getURL` when its concreteImplementation has
    // been assigned.
    intendedPath: Ember.computed("location.{concreteImplementation,path}", "router.initialURL", function () {
      var concreteImplementation = this.get("location.concreteImplementation");
      var locationPath = void 0;
      if (concreteImplementation) {
        locationPath = this.location.getURL();
      } else {
        locationPath = this.get("location.path");
      }
      var initialURL = this.initialURL;
      if (Ember.isPresent(initialURL)) {
        return initialURL; // || locationPath;
      }
      if (Ember.isPresent(locationPath)) {
        return locationPath;
      }
    })
  });

  function setRoute(entity) {
    this.route((0, _emberInflector.pluralize)(entity), {
      resetNamespace: true
    }, function () {
      this.route("create", {
        path: "/create"
      });
      this.route("index", {
        path: "/:" + (0, _emberInflector.singularize)(entity) + "_id"
      });
    });
  }

  Router.map(function () {
    this.route("brand", {
      path: ":brand_code"
    }, function () {
      ["agencies", "cartoons", "channels", "cities", "clips", "contentreferences", "cnevideos", "curatedsearches", "curatedlists", "employees", "fashionshows", "faqs", "homepages", "houses", "musicgroups", "pagetouts", "photos", "reviews", "recipes", "nativerecipes", "runwaymoments", "taxonomies", "teams", "videos"].forEach(function (entity) {
        setRoute.call(this, entity);
      }, this);

      this.route("articles", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        }, function () {
          this.route("index", {
            path: ""
          });
        });
        this.route("index", {
          path: "/:article_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
          this.route("syndicate", {
            path: "/syndicate"
          });
          this.route("diff", {
            path: "/diff"
          });
        });
      });

      this.route("nativearticles", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        }, function () {
          this.route("index", {
            path: ""
          });
        });
        this.route("index", {
          path: "/:nativearticle_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
        });
      });

      this.route("bundles", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("index", {
          path: "/:bundle_id"
        }, function () {
          this.route("details", {
            path: ""
          });
          this.route("section", {
            /*eslint ember/routes-segments-snake-case: 0*/
            path: "/:curationContainerType/:id"
          });
        });
      });

      this.route("galleries", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        }, function () {
          this.route("edit", {
            path: ""
          });
        });
        this.route("index", {
          path: "/:gallery_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
          this.route("child", {
            path: "/:child_type/:child_id"
          });
        });
      });

      this.route("nativegalleries", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        }, function () {
          this.route("edit", {
            path: ""
          });
        });
        this.route("index", {
          path: "/:gallery_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
          this.route("child", {
            path: "/:child_type/:child_id"
          });
        });
      });

      this.route("fashiongalleries", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("index", {
          path: "/:fashiongallery_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
          this.route("photo", {
            path: "/photos/:photo_id"
          });
          this.route("runwaymoment", {
            path: "/runwaymoments/:runwaymoment_id"
          });
          this.route("runwaymoment", {
            path: "/moments/:runwaymoment_id"
          });
        });
      });

      this.route("fashionshows", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("index", {
          path: "/:fashionshow_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
          this.route("reviews", {
            path: "/reviews"
          }, function () {
            this.route("create", {
              path: "/create"
            });
            this.route("index", {
              path: "/:review_id"
            });
          });
        });
      });

      this.route("livestories", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("index", {
          path: "/:livestory_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
          this.route("livestoryupdates", {
            path: "/livestoryupdates"
          }, function () {
            this.route("create", {
              path: "/create"
            });
            this.route("index", {
              path: "/:livestoryupdate_id"
            });
          });
        });
      });

      this.route("seasons", {
        resetNamespace: true
      }, function () {
        this.route("index", {
          path: ""
        });
        this.route("create", {
          path: "/create"
        });
        this.route("edit", {
          path: "/:season_id"
        });
        this.route("fashionshows", {
          path: "/:season_id/fashionshows"
        });
      });

      this.route("preview", {
        resetNamespace: true,
        path: "/preview/:type/:id"
      });

      this.route("products", {
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("index", {
          path: "/:product_id"
        }, function () {
          this.route("edit", {
            path: ""
          });
        });
        this.route("upload", {
          path: "/upload"
        });
      });

      this.route("search", { resetNamespace: true }, function () {
        this.route("index", {
          path: "/"
        });
      });

      this.route("presets", { resetNamespace: true }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("edit", {
          path: "/:id"
        });
      });

      this.route("contentEdit", {
        path: ":content_type",
        resetNamespace: true
      }, function () {
        this.route("create", {
          path: "/create"
        });
        this.route("index", {
          path: "/:id"
        });
      });

      this.route("category-manager", { resetNamespace: true }, function () {
        this.route("category", {
          path: ":category_id"
        }, function () {
          this.route("create");
          this.route("edit");
          this.route("child", {
            path: ":child_category_id/edit"
          });
        });
      });

      this.route("find", { resetNamespace: true });

      this.mount("sitesettings", { resetNamespace: true });

      this.mount("users", { resetNamespace: true });

      this.route("dashboard", { resetNamespace: true });

      this.mount("feature-flags", { resetNamespace: true });
    });

    this.route("not-found", {
      resetNamespace: true,
      path: "/not-found"
    });

    this.route("error", {
      resetNamespace: true,
      path: "/error/:error"
    });

    this.route("index", {
      path: "/"
    });

    this.route("catch-all", {
      path: "*path"
    });
  });

  exports.default = Router;
});