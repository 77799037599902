define("copilot/helpers/is-rich-text-enabled-for-field", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    features: Ember.inject.service(),

    compute: function compute() {
      return true;
    }
  });
});