define("copilot/lib/brand-list", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var resolverConfig = exports.resolverConfig = {
    cn: {
      ad: {},
      admx: {},
      all: {},
      bon: {},
      cnt: {},
      demo: {},
      engineering: {},
      epi: {},
      exp: {},
      glm: {},
      glmmx: {},
      gq: {},
      p4k: {},
      self: {},
      them: {},
      tnv: {},
      tny: {},
      vf: {},
      vogue: {},
      wiredes: {},
      wrd: {}
    },
    cni: {
      adde: {
        resolverCompany: "cn"
      },
      ades: {
        resolverCompany: "cn"
      },
      adfr: {
        resolverCompany: "cn"
      },
      adin: {
        resolverCompany: "cn"
      },
      adit: {
        resolverCompany: "cn"
      },
      adme: {
        resolverCompany: "cn"
      },
      adru: {
        resolverCompany: "cn"
      },
      cntde: {
        resolverBrand: "cnt",
        resolverCompany: "cn"
      },
      cntes: {
        resolverBrand: "cnt",
        resolverCompany: "cn"
      },
      cntin: {
        resolverBrand: "cnt",
        resolverCompany: "cn"
      },
      cntme: {
        resolverCompany: "cn"
      },
      cntuk: {
        resolverBrand: "cnt",
        resolverCompany: "cn"
      },
      glmde: {
        resolverCompany: "cn"
      },
      glmes: {
        resolverCompany: "cn"
      },
      glmru: {
        resolverCompany: "cn"
      },
      glmuk: {
        resolverCompany: "cn"
      },
      gqde: {
        resolverCompany: "cn"
      },
      gqes: {
        resolverCompany: "cn"
      },
      gqfr: {
        resolverCompany: "cn"
      },
      gqin: {
        resolverCompany: "cn"
      },
      gqit: {
        resolverCompany: "cn"
      },
      gqjp: {
        resolverCompany: "cn"
      },
      gqmx: {
        resolverCompany: "cn"
      },
      gqru: {
        resolverCompany: "cn"
      },
      gqtw: {
        resolverCompany: "cn"
      },
      gquk: {
        resolverCompany: "cn"
      },
      hg: {
        resolverCompany: "cn"
      },
      lci: {
        resolverCompany: "cn"
      },
      lciit: {
        resolverCompany: "cn"
      },
      tatler: {
        resolverCompany: "cn"
      },
      tatlerru: {
        resolverCompany: "cn"
      },
      vde: {
        resolverCompany: "cn"
      },
      ves: {
        resolverCompany: "cn"
      },
      vfes: {
        resolverCompany: "cn"
      },
      vffr: {
        resolverCompany: "cn"
      },
      vfit: {
        resolverCompany: "cn"
      },
      vin: {
        resolverCompany: "cn"
      },
      vit: {
        resolverCompany: "cn"
      },
      vjp: {
        resolverCompany: "cn"
      },
      vmx: {
        resolverCompany: "cn"
      },
      vnt: {},
      voguebz: {
        resolverCompany: "cn"
      },
      vpa: {
        resolverCompany: "cn"
      },
      vru: {
        resolverCompany: "cn"
      },
      vtw: {
        resolverCompany: "cn"
      },
      vuk: {
        resolverCompany: "cn"
      },
      woi: {
        resolverCompany: "cn"
      },
      wrdit: {
        resolverCompany: "cn"
      },
      wrdjp: {
        resolverCompany: "cn"
      },
      wrduk: {
        resolverCompany: "cn"
      }
    }
  };

  var companies = exports.companies = {
    cn: Object.keys(resolverConfig.cn),
    cni: Object.keys(resolverConfig.cni)
  };

  exports.default = [].concat(_toConsumableArray(companies.cni), _toConsumableArray(companies.cn));
});