define("copilot/helpers/match-and-highlight", ["exports", "copilot-components/helpers/match-and-highlight"], function (exports, _matchAndHighlight) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _matchAndHighlight.default;
    }
  });
});