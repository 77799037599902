define("copilot/controllers/products/index", ["exports", "copilot/controllers/base", "string"], function (exports, _base, _string) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    search: Ember.inject.service(),
    product: null,

    _populateSanitizedDescription: function _populateSanitizedDescription() {
      var model = this.model,
          product = this.product;

      var featureArray = Ember.get(product, "attributes.Feature");
      var features = featureArray && featureArray.join(" ");

      if (features) {
        var stripped = (0, _string.default)(features).stripTags().s;
        model.set("description", stripped);
      }
      return this;
    },
    _populateOffer: function _populateOffer() {
      var model = this.model,
          product = this.product;

      var price = Ember.get(product, "offer.OfferListing.0.Price.0.Amount.0") / 100;

      var comparisonPrice = Ember.get(product, "offer.OfferListing.0.comparisonPrice.0.Amount.0") || 0;
      comparisonPrice = comparisonPrice > 0 ? comparisonPrice / 100 : comparisonPrice;

      var detailPageURL = Ember.get(product, "detailPageURL");
      var truncatedURL = detailPageURL.split("%3F")[0];
      var merchantID = this.get("configService.config.amazonMerchantID");
      var offer = this.store.modelFor("offer").create({
        seller: {
          name: "Amazon"
        },
        purchaseUri: truncatedURL + "?m=" + merchantID,
        offerType: "purchase",
        price: price,
        comparisonPrice: comparisonPrice,
        currency: Ember.get(product, "currency")
      });
      model.get("offers").pushObject(offer);
      model.notifyPropertyChange("offers");
      return this;
    },
    _populateBrand: function _populateBrand() {
      var _this = this;

      var brandName = this.get("product.attributes.Brand.firstObject");
      this.search.execute({
        types: "brand",
        query: brandName
      }).then(function (data) {
        var brand = data.hits && data.hits[0];
        if (brand) {
          _this.get("model.brand").setObjects([brand]);
        }
      });
    },


    actions: {
      populateFields: function populateFields(product) {
        this.set("product", product);
        this._populateSanitizedDescription()._populateBrand();
      }
    }
  });
});