define("copilot/controllers/dashboard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    config: Ember.inject.service(),

    tabs: [{
      title: "myContent"
    }, {
      title: "allContent"
    }]
  });
});