define("copilot/models/tagpage", ["exports", "copilot/models/base"], function (exports, _base) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = Ember.attr;
    var _Attr = Attr,
        str = _Attr.str;


    var Tagpage = _base.default.extend({
        type: "tagpage",
        name: attr(str),
        searchTitle: Ember.computed("name", function () {
            return this.get("name");
        })
    });

    Tagpage.reopenClass({
        url: "/api/tagpages"
    });

    exports.default = Tagpage;
});