define("copilot/brands/vogue/controllers/reviews/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _base.default.extend({
    save: function () {
      var _ref = _asyncToGenerator(function* () {
        try {
          yield this._super.apply(this, arguments);
          this.saveSuccess(this)(this.model.isNew ? "new" : "");
        } catch (err) {
          this.triggerError();
          throw err;
        }
      });

      function save() {
        return _ref.apply(this, arguments);
      }

      return save;
    }(),


    // Overriding saveSuccess so we can replace route with the index route nested
    // under fashionshows
    saveSuccess: function saveSuccess(controller) {
      return function (status) {
        if (status === "new") {
          controller.replaceRoute("fashionshows.index.reviews.index", controller.model);
          Ember.run.schedule("afterRender", controller, controller.triggerSuccess);
        } else {
          controller.triggerSuccess();
        }
      }.bind(this);
    }
  });
});