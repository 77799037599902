define("copilot/models/simple-string", ["exports", "ember-model", "copilot/models/attr"], function (exports, _emberModel, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;
  exports.default = _emberModel.Model.extend({
    type: "simplestring",
    value: (0, _emberModel.attr)(str)
  });
});