define("copilot/services/offer-products", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Service.extend({
    getProductDetails: function () {
      var _ref = _asyncToGenerator(function* (brandCode, offerUrls, productid, brandName) {
        var _this = this;

        return yield (0, _icAjax.default)("/api/commerce/productdetails/" + brandCode, {
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            affiliateUrls: offerUrls,
            productid: productid,
            brandName: brandName
          })
        }).then(function (result) {
          result = JSON.parse(result);
          return result.response.productDetails;
        }).catch(function (err) {
          Ember.set(_this, "errors", err);
          window.flashMessages.danger("unable to fetch product details", {
            sticky: false,
            timeout: 5000
          });
          return {};
        });
      });

      function getProductDetails(_x, _x2, _x3, _x4) {
        return _ref.apply(this, arguments);
      }

      return getProductDetails;
    }()
  });
});