define("copilot/models/search/adapters/commerceproductcollection", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    page: 1,
    query: "",
    type: "",
    nottypes: null,
    pages: 0,
    totalHits: 0,
    searchError: false,
    archived: false,
    display: "all",
    types: Ember.computed.reads("type"),
    placeholder: "Search...",
    resultsPerPage: 0,
    limit: 20,
    commerceProductCollection: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.contenTypes = "commerceproductcollection";
      this.results = [];
    },


    fetch: (0, _emberConcurrency.task)(function* () {
      var query = this.query;
      var params = this.getProperties("page", "query", "limit");
      var data = Object.assign({
        query: query
      }, params);
      try {
        var results = yield this.commerceProductCollection.execute(data);
        this._populate(results);
      } catch (err) {
        this._handleError(err);
      }
    }),

    setPage: function setPage(page) {
      this.set("page", page);
    },
    setQuery: function setQuery(query) {
      this.set("query", query);
    },
    nextPage: function nextPage() {
      this.incrementProperty("page");
      return this.fetch.perform();
    },
    previousPage: function previousPage() {
      this.decrementProperty("page");
      return this.fetch.perform();
    },
    _clearResults: function _clearResults() {
      this.results.clear();
    },
    _handleError: function _handleError(error) {
      var statusCode = Ember.get(error, "status");
      if (statusCode && statusCode !== 404) {
        this._clearResults();
        this.set("searchError", true);
        throw error;
      } else {
        this.set("searchError", false);
      }
    },
    _populate: function _populate(data) {
      var total = data.totalHits;
      var rows = data.hits.length;
      var results = data.hits;
      this.results.setObjects(results);
      var numPages = Math.ceil(total / rows) || 1;
      this.set("pages", numPages);
      this.set("resultsPerPage", rows);
      this.set("totalHits", total);
      this.set("resultsQuery", data.query);
      this.set("limit", 1);
      return this;
    }
  });
});