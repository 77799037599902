define("copilot/components/content-unpublisher/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    experimentsService: Ember.inject.service("experiments"),

    referencesShowing: false,

    date: Ember.computed(function () {
      var pubDate = this.get("record.pubDate");
      return (0, _moment.default)(pubDate).format("MMMM D, YYYY @ h:mma");
    }),

    experiment: Ember.computed("entityModel.experiments.items.firstObject", function () {
      return this.experimentsService.getActiveExperiment(this.entityModel);
    }),

    isRunning: Ember.computed("experiment.state", function () {
      return this.get("experiment.state") === this.experimentsService.states.RUNNING;
    }),

    actions: {
      submit: function submit() {
        this.submit();
      },
      toggleReferences: function toggleReferences() {
        this.toggleProperty("referencesShowing");
      }
    }
  });
});