define("copilot/brands/bon/models/package", ["exports", "copilot/models/base", "copilot/models/attr"], function (exports, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr,
      hasMany = Ember.hasMany;
  var str = _attr.default.str;


  var Package = _base.default.extend({
    type: "package",
    hed: attr(),
    dek: attr(),
    promoHed: attr(),
    promoDek: attr(),
    seoTitle: attr(str),
    seoDescription: attr(str),
    socialTitle: attr(str),
    socialDescription: attr(str),
    imageSocial: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    mainTout: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    highlight: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    highlightsGallery: hasMany("gallery", {
      embedded: true
    }),
    taxonomy: hasMany("taxonomy", {
      embedded: true
    }),
    section1Hed: attr(str),
    section1Dek: attr(),
    section2Hed: attr(),
    section2Dek: attr(),
    section3Hed: attr(),
    section3Dek: attr(),
    section4Hed: attr(),
    section4Dek: attr(),
    section5Hed: attr(),
    section5Dek: attr(),
    section1List: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    section2List: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    section3List: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    section4List: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    section5List: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    related: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    channel: attr(str),
    subChannel: attr(str),
    photosTout: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    photosPromoTout: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("hed"),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      promoHed: { source: "hed", sanitize: false },
      promoDek: { source: "dek", sanitize: false },
      socialTitle: "hed",
      socialDescription: "dek",
      seoDescription: "dek",
      seoTitle: "hed"
    },

    toJSON: function toJSON(_) {
      var recursionDepth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;

      return this._super(_, recursionDepth);
    }
  });

  Package.reopenClass({
    url: "/api/packages"
  });

  exports.default = Package;
});