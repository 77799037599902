define("copilot/services/import-external-asset", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    analytics: Ember.inject.service(),

    importAsset: function importAsset(asset) {
      var _this = this;

      var data = this.getImportData(asset);

      return (0, _icAjax.default)("/api/import/" + asset.type, {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(data)
      }).then(function (photoData) {
        var PhotoModel = _this.store.createRecord("photo");
        var normalizedData = _this.store.adapterFor("photo").get("serializer").normalize(PhotoModel.constructor, photoData);
        PhotoModel.load(photoData.id, normalizedData);
        _this.analytics.track("external content", "imported", {
          contentType: "photo",
          assetType: asset.type,
          imageType: asset.imageType,
          id: photoData.id,
          sourceId: photoData.sourceId
        });
        return PhotoModel;
      });
    },
    getImportData: function getImportData(asset) {
      if (asset.type === "getty") {
        return {
          sourceId: asset.id,
          height: asset.aspectRatios.master.height
        };
      }
    }
  });
});