define("copilot/schemas/video", ["exports", "@condenast/cross-check-schema"], function (exports, _crossCheckSchema) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _crossCheckSchema.Record)("video", {
    fields: {
      canonicalUrl: _crossCheckSchema.types.SingleLine(),
      contentSource: _crossCheckSchema.types.SingleLine(),
      dek: _crossCheckSchema.types.Text(),
      hed: _crossCheckSchema.types.SingleLine().required("always"),
      ledeCaption: _crossCheckSchema.types.SingleLine(),
      promoHed: _crossCheckSchema.types.SingleLine(),
      rubric: _crossCheckSchema.types.SingleLine(),
      seoTitle: _crossCheckSchema.types.SingleLine(),
      seoDescription: _crossCheckSchema.types.Text(),
      socialTitle: _crossCheckSchema.types.SingleLine(),
      socialDescription: _crossCheckSchema.types.Text(),
      url: _crossCheckSchema.types.SingleLine(),

      contributorsAuthor: _crossCheckSchema.types.hasMany("contributor"),
      contributorsPhotographer: _crossCheckSchema.types.hasMany("contributor"),
      photosLede: _crossCheckSchema.types.hasMany("media"),
      photosSocial: _crossCheckSchema.types.hasMany("media"),
      photosTout: _crossCheckSchema.types.hasMany("media")
    },
    metadata: {
      autogenerates: {
        promoHed: { source: "hed", sanitize: false }
      },
      collectionName: "videos",
      defaultValues: {
        contentSource: "web"
      },
      previewable: true,
      duplicatable: true,
      searchTitlePath: "hed"
    }
  });
});