define("copilot/brands/p4k/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      artwork: "Artwork",
      audiofileAttachments: "Audiofile Attachments",
      audiofileDescription: "Description (Artists, etc.)",
      byArtist: "By Artist",
      disambiguatingDescription: "Disambiguation",
      displayName: "Display Name",
      embedUrl: "Embed URL",
      id: "ID",
      isBestNewMusic: "Is Best New Music",
      isBestNewReissue: "Is Best New Reissue",
      isCompilation: "Compilation",
      isReleaseOf: "Is Release Of",
      "location.name": "Name",
      "location.street": "Street Address",
      "location.city": "City",
      "location.state": "State",
      "location.country": "Country",
      "location.postalCode": "Postal Code",
      musicGroupImage: "Music Group Image",
      outlet: "Outlet",
      photosLogo: "Logo",
      playerImage: "Player Image",
      provider: "Provider",
      publisher: "Publisher",
      rating: "Rating",
      trackName: "Track Name"
    },
    fieldsetLegends: {
      audio: "Audio",
      itemsReviewed: "Items Reviewed",
      location: "Location",
      relatedArtists: "Related Artists",
      thirdPartyMedia: "Third-Party Media"
    }
  };
});