define("copilot/controllers/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(Ember.Evented, {
    appName: "Copilot",

    appUpdater: Ember.inject.service(),
    brand: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    environment: Ember.inject.service(),
    intl: Ember.inject.service(),
    logger: Ember.inject.service(),
    config: Ember.inject.service(),
    router: Ember.inject.service(),
    userService: Ember.inject.service("user"),

    queryParams: ["noRedirect"],

    isCollapsed: false,
    scrollTop: 0,
    isPreview: Ember.computed.equal("currentPath", "brand.preview"),
    isDevelopmentBuild: Ember.computed.reads("environment.isDevelopmentBuild"),
    showBanner: Ember.computed.or("isDevelopmentBuild", "environment.isNotProduction"),
    showTopNav: Ember.computed("currentPath", function () {
      return this.currentPath.indexOf("brand.livestories.index.livestoryupdates") === -1;
    }),
    trainingLink: Ember.computed("config.{config.companyId,region}", function () {
      var companyId = this.config.config.companyId || "cn";
      var region = this.config.region;
      if (companyId === "cn") {
        return "https://docs.google.com/forms/d/e/1FAIpQLSek7SyJQWYz6rt7u2kpqU68GmuYsXxQUV-0zZ8GRzDXmQkZ5g/viewform";
      } else if (region === "eu") {
        return "https://docs.google.com/forms/d/e/1FAIpQLScflCRtf5PyFmeNN8d_mOcCCjPafi5p6Pefu8Dt9JJjQDhs1g/viewform";
      } else {
        // No training for AP at the moment
        return null;
      }
    }),

    supportEmail: Ember.computed("config.config.companyId", function () {
      var companyId = this.config.config.companyId || "cn";
      if (companyId === "cn") {
        return "formation-support@condenast.com";
      } else {
        return "support@condenastint.com";
      }
    }),

    translationsHighlighted: Ember.computed({
      get: function get() {
        return JSON.parse(window.localStorage.getItem("translationsHighlighted"));
      },
      set: function set(_, highlighted) {
        window.localStorage.setItem("translationsHighlighted", highlighted);
        return highlighted;
      }
    }),

    actions: {
      setScrollTop: function setScrollTop(event) {
        if (!this.isDestroyed) {
          this.set("scrollTop", event.target.scrollTop);
        }
      }
    }
  });
});