define("copilot/models/search/adapters/getty", ["exports", "ic-ajax", "ember-concurrency"], function (exports, _icAjax, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    page: 1,
    query: "",
    type: "",
    pages: 0,
    totalHits: 0,
    loading: false,
    searchError: false,
    placeholder: "Search Getty...",

    init: function init() {
      this._super.apply(this, arguments);
      this.results = [];
      this.gettyParams = {};
      this.paginationCache = {};

      this.searchOptions = [{
        name: "imageType",
        options: ["editorial", "creative"],
        value: "editorial"
      }, {
        name: "sortOrder",
        options: ["best_match", "most_popular", "newest", "oldest", "random"],
        value: "most_popular"
      }];
    },


    fetch: (0, _emberConcurrency.task)(function* () {
      var query = this.query;
      var data = Object.assign({
        query: query
      }, this.gettyParams, this.searchOptions.reduce(function (options, option) {
        return (options[option.name] = option.value) && options;
      }, {}));

      if (query.length === 0) {
        this.results.clear();
        return;
      }
      try {
        var results = yield (0, _icAjax.default)("/api/search/getty", {
          method: "GET",
          contentType: "application/json",
          data: data
        });
        this._populate(results);
      } catch (err) {
        this._handleError();
      }
    }),

    setPage: function setPage(page) {
      this.set("page", page);
    },
    setQuery: function setQuery(query) {
      this.set("query", query);
      this.set("gettyParams", {});
      this.setPage(1);
    },
    nextPage: function nextPage() {
      var imagesPerPage = this.get("gettyPagination.count");
      this.set("gettyParams.offset", imagesPerPage * this.page);
      this.incrementProperty("page");
      return this.fetch.perform();
    },
    previousPage: function previousPage() {
      this.decrementProperty("page");
      var imagesPerPage = this.get("gettyPagination.count");
      this.set("gettyParams.offset", imagesPerPage * (this.page - 1));
      return this.fetch.perform();
    },
    _populate: function _populate(data) {
      this.results.setObjects(data.hits);
      this.set("gettyPagination", data.pagination);
      var numPages = Math.ceil(data.pagination.total_count / 50);
      this.set("pages", numPages);
      this.set("totalHits", data.pagination.total_count);
    },
    _handleError: function _handleError() {
      this.results.clear();
      this.set("searchError", true);
    }
  });
});