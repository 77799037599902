define("copilot/brands/vogue/mixins/polaroid", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    configService: Ember.inject.service("config"),

    polaroidDashboardURL: Ember.computed("config.polaroidHost", function () {
      var config = this.get("configService.config");

      // ember cli does not transpile stuff in brands at the moment :( maybe due to custom resolver?
      return window.location.protocol + "//" + config.polaroidHost + "/dashboard";
    }),

    polaroidPrefixURL: Ember.computed("config.polaroidHost", function () {
      var config = this.get("configService.config");

      // ember cli does not transpile stuff in brands at the moment :( maybe due to custom resolver?
      return window.location.protocol + "//" + config.polaroidHost + "/prefixes";
    })
  });
});