define("copilot/components/brand-switcher/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InternationalBrands = {
    Allure: "all",
    "Architectural Digest": "adinternational",
    "Condé Nast Traveller": "cnt",
    "Condé Nast Traveler": "cnt",
    GQ: "gqinternational",
    "La Cucina Italiana": "lci",
    Glamour: "glm",
    Tatler: "tatler",
    Vogue: "vogue",
    "Vanity Fair": "vf",
    WIRED: "wrd",
    Wired: "wrd"
  };

  exports.default = Ember.Component.extend({
    brand: Ember.inject.service(),
    user: Ember.inject.service(),
    isOpen: false,

    features: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    brands: Ember.computed.reads("user.user.brands"),

    brandCodeToOrg: function brandCodeToOrg(brandCode) {
      var hasLogo = null;
      var name = this.configService.getBrandName(brandCode) || "";
      var iconName = brandCode;
      var text = "";

      // Check for international brand overrides
      try {
        require("ember-svg-jar/inlined/" + iconName);
        hasLogo = true;
      } catch (e) {
        hasLogo = false;
      }

      // Walk through markets and separate the
      // logo and market
      if (!hasLogo) {
        for (var key in InternationalBrands) {
          if (name.startsWith(key + " ")) {
            iconName = InternationalBrands[key];
            text = name.slice(key.length + 1);
            hasLogo = true;
            break;
          }
        }
      }

      return {
        hasLogo: hasLogo,
        name: name,
        iconName: iconName,
        text: text,
        code: brandCode
      };
    },


    currentBrand: Ember.computed("activeBrand", function () {
      return this.brandCodeToOrg(this.activeBrand);
    }),

    availableBrands: Ember.computed("brands", "activeBrand", function () {
      var _this = this;

      var activeBrand = this.activeBrand;
      var brands = this.brands;
      if (!brands) {
        return [];
      }
      return brands.map(function (brandCode) {
        return _this.brandCodeToOrg(brandCode);
      }).filter(function (brand) {
        return brand.code !== activeBrand && Ember.isPresent(brand.name);
      }).sortBy("name");
    }),

    willDestroyElement: function willDestroyElement() {
      if (this._clickOutside) {
        document.removeEventListener("click", this._clickOutside);
      }
    },
    open: function open(evt) {
      var _this2 = this;

      this._clickOutside = function (evt) {
        if (_this2.element === evt.target || _this2.element.contains(evt.target)) {
          return;
        }
        _this2.close();
      };
      document.addEventListener("click", this._clickOutside);
      this.set("isOpen", true);
      evt.stopPropagation();
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    }
  });
});