define("copilot/components/content-selector-button/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    actions: {
      findAssets: function findAssets() {
        this.set("isShowingModal", true);

        var track = this.track;
        if (track) {
          track();
        }
      },
      addItems: function addItems(assets) {
        this.set("isShowingModal", false);
        this.addAssets(assets);
      }
    }
  });
});