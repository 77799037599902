define("copilot/brands/tny/models/event", ["exports", "copilot/models/base", "@condenast/copilot-slug"], function (exports, _base, _copilotSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    publishUriHook: Ember.computed("categoriesChannels.firstObject", function () {
      var firstChannel = this.categoriesChannels.firstObject;
      var isGoat = firstChannel && firstChannel.hierarchy && firstChannel.hierarchy.findBy("slug", "goings-on-about-town");

      if (isGoat && !Ember.isEmpty(firstChannel.hierarchy)) {
        var goatSlug = firstChannel.hierarchy.slice(0, -1).reverse().mapBy("slug").slice(0, 2).join("/");
        return (0, _copilotSlug.slugSafe)(goatSlug);
      }
      return "events";
    })
  });
});