define("copilot/brands/p4k/models/third-party-media", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Model.extend({
    type: "third-party-media",
    outlet: Ember.attr(),
    id: Ember.attr()
  });
});