define("copilot/validations/brands/glm/gallery", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var galleryValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.GalleryValidationsDescriptor).andAlso(object({
    categories: (0, _copilotValidators.categoriesMinimum)().on("publish")
  }));

  exports.default = galleryValidationBuilder;
});