define("copilot/brands/vogue/routes/fashiongalleries/index/runwaymoment", ["exports", "copilot/brands/vogue/routes/fashiongalleries/index/child"], function (exports, _child) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _child.default.extend({
    controllerName: "fashiongalleries/runwaymoment",

    // Uniquely for this route we pull in a full runwaymoment model AND a full photo
    // model due to requirement to edit both in the same form. Save likewise needs
    // to persist them both asynchronously (see controller)
    model: function () {
      var _ref = _asyncToGenerator(function* (params) {
        var store = this.store;
        var runwaymoment = yield store.find("runwaymoment", params.runwaymoment_id);

        // manually re-load the photo data so it gets properly normalized
        // by the correct serializer
        var photo = runwaymoment.get("photosTout.firstObject");
        var freshPhoto = yield store.fetchTransient("photo", photo.id);
        var model = {
          runwaymoment: runwaymoment,
          photo: freshPhoto
        };

        return model;
      });

      function model(_x) {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});