define("copilot/components/preview-bar/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ANIMATION_DURATION = 2000;

  exports.default = Ember.Component.extend({
    copied: false,
    intl: Ember.inject.service(),

    alternateView: Ember.computed("isResponsiveView", function () {
      return this.isResponsiveView ? "desktop" : "mobile";
    }),

    // The following computed property is required because for some reason after
    // upgrading to Ember 2.15 the hbs below raised an error in the hbs module
    // transform. This should be put back in the template (and the computed below
    // removed) once this is properly supported.
    // {{t 'toggleView' view=(t (concat 'concerns.previews.' alternateView))}}
    translatedAlternateView: Ember.computed("alternateView", function () {
      var alternateView = this.alternateView;
      return this.intl.t("concerns.previews." + alternateView);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!Ember.isEmpty(this.templates)) {
        var template = this.templates.findBy("name", this.template);
        if (template) {
          this.set("template", template.label);
        }
      }
    },
    toggleCopyAnimation: function toggleCopyAnimation() {
      this.set("copied", true);
      Ember.run.later(this, function () {
        this.set("copied", false);
      }, ANIMATION_DURATION);
    },


    templateObserver: Ember.observer("template", function () {
      window.localStorage.setItem("publish-template:" + this.id, this.template);
    }),

    actions: {
      copySuccess: function copySuccess() {
        this.toggleCopyAnimation();
      }
    }
  });
});