define("copilot/components/type-selector/component", ["exports", "copilot/mixins/faux-dropdown", "lodash-es"], function (exports, _fauxDropdown, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fauxDropdown.default, {
    tagName: "ul",
    classNames: ["type-selector"],
    brandConfig: Ember.inject.service(),

    hasRemainingOptions: Ember.computed.notEmpty("remainingTypeOptions"),

    addContributors: function addContributors() {
      return this.addContributors;
    },


    allTypeOptions: Ember.computed("optionsKey", function () {
      var optionsKey = this.optionsKey;

      return this.get("brandConfig." + optionsKey).map(function (config) {
        return Object.assign({}, { isCollapsed: true }, config);
      });
    }),

    existingTypeOptions: Ember.computed("model", "allTypeOptions", function () {
      var _this = this;

      var allTypeOptions = this.allTypeOptions;

      var filteredSet = allTypeOptions.filter(function (option) {
        return Ember.isPresent(_this.get("model." + option.value)) || option.default;
      });

      filteredSet.set("lastObject.isCollapsed", false);

      return filteredSet;
    }),

    remainingTypeOptions: Ember.computed("existingTypeOptions.[]", function () {
      var allTypeOptions = this.allTypeOptions;

      return _lodashEs.default.xor(allTypeOptions, this.existingTypeOptions);
    }),

    optionFromType: function optionFromType(type) {
      var allTypeOptions = this.allTypeOptions;

      return allTypeOptions.find(function (opt) {
        return opt.value === type;
      });
    },
    collapseAllItems: function collapseAllItems() {
      var existingTypeOptions = this.existingTypeOptions;

      existingTypeOptions.forEach(function (option) {
        Ember.set(option, "isCollapsed", true);
      });
    },
    clearContributorsOfType: function clearContributorsOfType(type) {
      this.get("model." + type).clear();
    },


    actions: {
      addSelectorItem: function addSelectorItem(type) {
        this.collapseAllItems();
        this.existingTypeOptions.pushObject(this.optionFromType(type));
      },
      removeSelectorItem: function removeSelectorItem(type) {
        this.clearContributorsOfType(type);
        this.existingTypeOptions.removeObject(this.optionFromType(type));
      }
    }
  });
});