define("copilot/lib/autocomplete/contributor-adapter", ["exports", "lodash-es", "copilot/lib/autocomplete/base-adapter"], function (exports, _lodashEs, _baseAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _lodashEs.default.extend({}, _baseAdapter.default, {
    transformInitialValue: function transformInitialValue(value) {
      return new Ember.RSVP.Promise(function (resolve) {
        var resultSet = [];
        resultSet = value.map(function (text) {
          return {
            id: text,
            text: text
          };
        });
        resolve(resultSet);
      });
    },
    getInitialValue: function getInitialValue(data) {
      return data.join(",");
    },
    getValue: function getValue(data) {
      return data.choice.text;
    },
    find: function find(data, value) {
      return _lodashEs.default.find(data, function (item) {
        return item === value;
      });
    },
    transformResults: function transformResults(data) {
      var results = [];
      if (!Ember.isArray(data.hits)) {
        return results;
      }
      results = data.hits.map(function (item) {
        return {
          text: item.get("name") || "",
          id: item.get("name")
        };
      });
      return results;
    }
  });
});