define("copilot/brands/vogue/routes/fashionshows/index/reviews/index", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route"], function (exports, _unsavedWarning, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, {
    controllerName: "reviews.index",

    model: function model(params) {
      return this.store.fetchTransient("review", params.review_id);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      var parent = this.modelFor("fashionshows.index");
      model.set("fashionshow", parent.fashionshow);
    }
  });
});