define("copilot/brands/epi/models/course", ["exports", "copilot/models/embedded", "ember-model"], function (exports, _embedded, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _embedded.default.extend({
    type: "course",
    hed: (0, _emberModel.attr)(),
    dek: (0, _emberModel.attr)(),
    recipes: (0, _emberModel.hasMany)("recipe-item", {
      embedded: true
    })
  });
});