define("copilot/components/gallery-item-header/component", ["exports", "copilot/components/card-list/card-item-header/component", "ember-inflector"], function (exports, _component, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      reads = _Ember$computed.reads,
      equal = _Ember$computed.equal;
  exports.default = _component.default.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    isCnevideo: equal("model.embedded.meta.modelName", "cnevideo"),
    contentType: reads("model.embedded.meta.modelName"),
    collectionName: reads("model.embedded.meta.collectionName"),
    editLinkLabel: Ember.computed("contentType", function () {
      var contentType = this.intl.t("contentTypes." + this.contentType, {
        count: 1
      });
      return this.intl.t("components.gallery-item-header.action.edit", {
        contentType: contentType
      });
    }),
    editRoute: Ember.computed("contentType", function () {
      return (0, _emberInflector.pluralize)(this.contentType);
    }),
    hasEditRoute: Ember.computed("contentType", function () {
      var editRoute = this.editRoute;
      return this.router._router.hasRoute(editRoute);
    }),

    photo: reads("model.embeddedPhoto"),
    position: Ember.computed("index", "model.id", "activeIds.[]", function () {
      return this.activeIds ? this.activeIds.indexOf(this.get("model.id")) + 1 : this.index + 1;
    })
  });
});