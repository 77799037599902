define("copilot/schema-helpers/default-currency", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var cntBrandCurrencyMap = {
    cntes: "EUR",
    cntuk: "GBP",
    cntin: "INR"
  };

  var currencyDefault = void 0;

  exports.default = Ember.Object.extend({
    brandConfig: Ember.inject.service(),
    features: Ember.inject.service(),
    configService: Ember.inject.service("config"),

    compute: function compute() {
      if (this.features.get("enableLocalisedOffers")) {
        var countryCodeDefault = this.get("configService.marketCode").toUpperCase();
        var countryCurrencyMapping = this.brandConfig.countryCurrencyMapping;
        currencyDefault = countryCurrencyMapping[countryCodeDefault];
      } else {
        currencyDefault = this.brandConfig.currencyDefault;
        if (cntBrandCurrencyMap[this.brandConfig.brand.activeBrand]) {
          currencyDefault = cntBrandCurrencyMap[this.brandConfig.brand.activeBrand];
        }
      }
      return currencyDefault ? currencyDefault : "USD";
    }
  });
});