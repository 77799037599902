define("copilot/brands/vogue/mixins/multiple-model-controller", ["exports", "ember-inflector", "moment"], function (exports, _emberInflector, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    validation: Ember.inject.service(),

    editRoute: Ember.computed("model.type", function () {
      return (0, _emberInflector.pluralize)(this.get("model.type"));
    }),

    lastModified: Ember.computed("model.fashionshow.modifiedAt", function () {
      var lastModified = this.get("model.fashionshow.modifiedAt");
      if (typeof lastModified !== "undefined") {
        return (0, _moment.default)(lastModified).format("MMMM D, YYYY h:mma");
      }
    }),

    contentTypeConfig: Ember.computed("mainModelPath", function () {
      var mainModelPath = this.mainModelPath;
      var mainModel = this.get(mainModelPath);
      return this.configService.getContentTypeConfig(mainModel);
    }),

    saveSuccess: function saveSuccess(controller) {
      return function (status) {
        if (status === "new") {
          var model = controller.model;
          var route = this.editRoute;
          controller.replaceRoute(route, model);
          Ember.run.schedule("afterRender", controller, controller.triggerSuccess);
        } else {
          controller.triggerSuccess();
        }
      }.bind(this);
    },
    save: function save(done) {
      var _this = this;

      // Write publish URL to metadata
      if (this.publishPathPattern && this.updatePublishData) {
        this.updatePublishData(this.publishPathPattern, this.slug);
      }
      return this.validateForSave().then(function (isValid) {
        if (isValid) {
          _this.triggerProgress();
          return _this.saveModels(_this).then(_this.saveSuccess(_this)).then(done).catch(_this.saveFailure.bind(_this));
        } else {
          _this.triggerValidationError();
        }
      });
    },


    actions: {
      save: function save(done) {
        this.save(done);
      }
    },

    saveFailure: function saveFailure(response) {
      var status = response.status || response.statusCode;
      var statusText = response.statusText || "Server Error";

      if (response.responseJSON) {
        response = response.responseJSON;
      }

      var message = response.message || "An internal server error occurred";
      var errorType = response.errorType || "server-error";

      var mainModelPath = this.mainModelPath;
      this.get(mainModelPath + ".validationErrors").add("", errorType, {
        error: statusText,
        message: message,
        status: status
      });

      this.triggerError();
    }
  });
});