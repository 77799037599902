define("copilot/components/search/search-filters/content-type/component", ["exports", "copilot/components/search/search-filters/base/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    type: "type",
    queryKey: "types",
    configService: Ember.inject.service("config"),
    brandConfig: Ember.inject.service("brandConfig"),

    options: Ember.computed("configService.config.contentTypes.[]", function () {
      var _this = this;

      return this.get("configService.config.contentTypes").map(function (contentType) {
        var translated = _this.intl.t("contentTypes." + contentType.value, {
          count: 1
        });
        return {
          name: (translated || contentType.label).toString(),
          value: contentType.value
        };
      }).reject(function (contentType) {
        return _this.get("brandConfig.excludeSearchFilters").some(function (filterValue) {
          return contentType.value === filterValue;
        });
      }).sortBy("name");
    })
  });
});