define("copilot/validations/brands/p4k/review", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;

  var channelValidatorOptions = {
    parents: ["reviews"]
  };

  var reviewValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ReviewValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["photosTout"])).catch((0, _crossCheckDsl.mutePath)(["itemReviewed"])).andAlso(object({
    itemsReviewed: (0, _copilotValidators.length)({ min: 1 }).andThen((0, _copilotValidators.hasValidChildren)({ childType: "item-reviewed" })).on("publish"),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1, max: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "channels-allowed-minimum" }
      }];
    }).andThen((0, _copilotValidators.channelsAllowed)(channelValidatorOptions))),
    categories: (0, _copilotCore.ifNotFeature)("enableCategoryChannels", object({
      channels: (0, _copilotValidators.length)({ min: 1, max: 1 }).catch(function () {
        return [{
          path: [],
          message: { name: "channels-allowed-minimum" }
        }];
      }).andThen((0, _copilotValidators.channelsAllowed)(channelValidatorOptions))
    }))
  }));

  exports.default = reviewValidationBuilder;
});