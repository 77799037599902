define("copilot/services/import-content-reference", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    getAsset: function getAsset(identifier) {
      return (0, _icAjax.default)("/api/search?types=contentreference&q=identifier:\"" + identifier + "\"", {
        type: "GET",
        contentType: "application/json"
      }).then(function (contentreferenceData) {
        contentreferenceData.constructor.metadata = {
          collectionName: "contentreferences"
        };
        return contentreferenceData;
      });
    },
    saveAsset: function saveAsset(asset) {
      var data = {
        identifier: asset.identifier,
        provider: asset.identifier.split(":")[0],
        hed: asset.name,
        dek: asset.description,
        meta: {
          collectionName: "contentreferences",
          modelName: "contentreference"
        }
      };
      return (0, _icAjax.default)("/api/contentreferences", {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify(data)
      }).then(function (contentreferenceData) {
        contentreferenceData.constructor.metadata = {
          collectionName: "contentreferences"
        };
        asset.set("id", contentreferenceData.id);
        return asset;
      });
    },
    publishAsset: function publishAsset(asset) {
      return (0, _icAjax.default)("/api/contentreferences/" + asset.id + "/publish", {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({
          uri: "contentreferences/" + asset.id,
          pubDate: new Date().toISOString()
        })
      });
    }
  });
});