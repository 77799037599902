define("copilot/brands/vogue/models/gallery", ["exports", "copilot/models/base", "@condenast/copilot-slug"], function (exports, _base, _copilotSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    features: Ember.inject.service(),

    publishUriHook: Ember.computed("categories.channels.firstObject", "categoriesChannels.firstObject", function () {
      var basePath = this.get("features.enableCategoryChannels") ? "categoriesChannels" : "categories.channels";
      var depth = this.get(basePath + ".firstObject.depth");
      var firstDescendantChannelSlug = this.get(basePath + ".firstObject.hierarchy." + (depth - 1) + ".slug");

      if (firstDescendantChannelSlug === "vogueworld") {
        return (0, _copilotSlug.slugSafe)("vogueworld/slideshow");
      }

      return "slideshow";
    })
  });
});