define("copilot/components/search-filter-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    config: Ember.inject.service(),
    classNames: ["search-filters", "span-2"]
  });
});