define("copilot/forms/brands/cnt/hotel", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/cnt/hotel"], function (exports, _hotel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _hotel.default;
    }
  });
});