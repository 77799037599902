define("copilot/schema-helpers/autogen-credit", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    intl: Ember.inject.service(),
    dependentKeys: Object.freeze(["contributors.[]"]),

    // Runs in context of model
    compute: function compute(model) {
      var _this = this;

      var result = model.contributors.reduce(function (acc, contributor) {
        var names = contributor.value.map(function (c) {
          return c.get("text") || c.get("name") || "";
        }).filter(Boolean).map(function (name) {
          return name.trim();
        }).join(", ");
        var credit = _this.intl.t("contributorCredits." + contributor.key.dasherize(), { names: names });
        return acc.concat(credit);
      }, []).join(", ");
      return _copilotMarkdown.default.sanitize(result);
    }
  });
});