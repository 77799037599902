define("copilot/components/create-livestoryupdates-banner/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isScheduled: Ember.computed('liveStartDate', 'liveEndDate', function () {
      return this.liveStartDate && this.liveEndDate;
    }),
    isBefore: Ember.computed('liveStartDate', function () {
      return (0, _moment.default)((0, _moment.default)(new Date())).isBefore(this.liveStartDate);
    }),
    isAfter: Ember.computed('liveEndDate', function () {
      return (0, _moment.default)((0, _moment.default)(new Date())).isAfter(this.liveEndDate);
    }),
    startTime: Ember.computed('isBefore', function () {
      return (0, _moment.default)(this.liveStartDate).format("hh:mm a");
    }),
    startDate: Ember.computed('isBefore', function () {
      return (0, _moment.default)(this.liveStartDate).format("ddd MMM DD YYYY");
    }),
    toastClass: Ember.computed('isBefore', 'isAfter', 'isScheduled', function () {
      if (!this.isScheduled || this.isAfter) {
        return 'toast--warning';
      } else if (this.isBefore) {
        return 'toast--info';
      } else {
        return 'toast--success';
      }
    }),
    allowUpdate: Ember.computed(function () {
      return this.authorizationService.userService.user.permissions.livestoryupdates.actions.update;
    })
  });
});