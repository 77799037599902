define("copilot/routes/auth-proxy", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var userService = this.userService;
      if (userService.get("isAuthenticated")) {
        transition.abort();
        this.transitionTo("search");
      }
    },
    model: function model(params) {
      var url = "/auth/" + params.path;

      return (0, _icAjax.default)(url).catch(function (response) {
        var url = response.jqXHR.responseJSON.redirectUrl;
        if (url) {
          window.location = url;
        } else {
          window.location = "/";
        }
      });
    }
  });
});