define("copilot/components/transcript-uploader/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Component.extend({
    acceptedType: "text/plain",
    hasError: false,
    setErrorPath: function setErrorPath(path) {
      this.set("hasError", true);
      this.set("errorPath", path);
    },


    actions: {
      onfileadd: function () {
        var _ref = _asyncToGenerator(function* (file) {
          this.set("hasError", false);
          if (file.type === this.acceptedType) {
            try {
              var response = yield this.uploadTranscript(file);
              return this.onupload("https://" + response.body.key);
            } catch (err) {
              return this.setErrorPath("upload-error");
            }
          } else {
            return this.setErrorPath("invalid-type");
          }
        });

        function onfileadd(_x) {
          return _ref.apply(this, arguments);
        }

        return onfileadd;
      }()
    }
  });
});