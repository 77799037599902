define("copilot/components/search/search-filters/base/component", ["exports", "ember-component-css/pod-names", "copilot/components/search/search-filters/base/template"], function (exports, _podNames, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ["type", "baseComponentCssClassName"],

    intl: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var type = this.type;
      Ember.defineProperty(this, "selectionsForType", Ember.computed.reads("selections." + type));
    },


    name: Ember.computed("type", "intl.locale", function () {
      var type = this.type;
      return this.intl.t("components.search.filters." + type + "." + type);
    }),

    unselectedOptions: Ember.computed("options.[]", "selectionsForType.[]", "type", function () {
      var selectedOptions = this.selectionsForType || [];
      return this.options.filter(function (option) {
        return !selectedOptions.mapBy("value").includes(option.value);
      });
    }),

    baseComponentCssClassName: _podNames.default["search/search-filters/base"],

    toSelection: function toSelection(value) {
      return this.options.findBy("value", value);
    },
    registerAllOptions: function registerAllOptions() {
      var type = this.type,
          queryKey = this.queryKey,
          replace = this.replace,
          toSelection = this.toSelection;

      var filterConfig = {
        toSelection: toSelection.bind(this),
        queryKey: queryKey,
        replace: replace,
        type: type
      };
      this.registerOptions(type, filterConfig);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.registerAllOptions();
    },


    actions: {
      applyOption: function applyOption() {
        this.onApply.apply(this, arguments);
      }
    }
  });
});