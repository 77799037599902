define("copilot/adapters/category", ["exports", "ic-ajax", "copilot/adapters/application"], function (exports, _icAjax, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    find: function find(record, id) {
      var _this = this;

      var url = new URL(this.buildURL(record.constructor, id), document.baseURI);
      url.searchParams.set("display", "all");

      // For GET requests we always want to get the data
      // Setting this to an empty string prevents 304's
      return (0, _icAjax.raw)(url.href, {
        headers: { "if-none-match": "" }
      }).then(function (result) {
        if (_this.get("features.optimisticLocking")) {
          var etag = result.jqXHR.getResponseHeader("ETag");
          record.etag = etag;
        }
        var data = result.response;
        _this.didFind(record, id, data);
        return record;
      });
    },
    findQuery: function findQuery(klass, records, params) {
      var _this2 = this;

      var url = this.buildURL(klass);

      return this.ajax(url, params).then(function (data) {
        _this2.didFindQuery(klass, records, params, data.categories);
        return Object.assign({}, data, {
          categories: records
        });
      });
    },
    didFindQuery: function didFindQuery(klass, records, params, data) {
      var collectionKey = Ember.get(klass, "collectionKey");
      var dataToLoad = collectionKey ? Ember.get(data, collectionKey) : data;

      var serializer = this.serializer;
      var normalizedData = dataToLoad.map(function (d) {
        return serializer.normalize(klass, d);
      });
      records.load(klass, normalizedData);
    }
  });
});