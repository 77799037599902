define("copilot/components/search-slat-form/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["search-slat-form"],

    componentName: Ember.computed("result", "style", function () {
      var componentName = "search-slat-form/" + this.result.type + "-form";
      var owner = Ember.getOwner(this);
      var lookup = owner.lookup("component-lookup:main");
      var hasComponent = lookup.componentFor(componentName, owner) || lookup.layoutFor(componentName, owner);

      if (!hasComponent) {
        componentName = "search-slat-form/default-form";
      }
      return componentName;
    })
  });
});