define("copilot/forms/brands/tny/cartoon", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/tny/cartoon"], function (exports, _cartoon) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _cartoon.default;
    }
  });
});