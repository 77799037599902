define("copilot/services/logger", ["exports", "copilot/lib/cookie", "ic-ajax"], function (exports, _cookie, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _userProperties: function _userProperties() {
      var user = this.userService;
      var env = _cookie.default.get("env");
      if (user && user.get("isAuthenticated")) {
        return {
          loggedIn: "true",
          brand: user.get("activeBrand"),
          env: env,
          uid: user.get("uid"),
          user: user.get("fullName"),
          email: user.get("email"),
          client_url: window.location.href
        };
      }

      return {
        loggedIn: "false",
        env: env,
        client_url: window.location.href
      };
    },
    _createLogData: function _createLogData(level, message, caught) {
      var props = this._userProperties();
      caught = caught && JSON.parse(JSON.stringify(caught));
      return Object.assign({}, props, {
        level: level,
        msg: message,
        caught: caught
      });
    },
    _sendLogData: function _sendLogData(data) {
      var level = data.level;

      var env = _cookie.default.get("env");
      var debug = env !== "staging" && env !== "production";
      if (debug && level !== "error") {
        /*eslint no-console: 0*/
        console.log(level + ": " + data.msg);
        console.log(data.caught);
      }

      (0, _icAjax.default)("/log", {
        data: data,
        type: "POST"
      });
    },
    info: function info(message, customData) {
      var data = this._createLogData("info", message, customData);
      this._sendLogData(data);
    },
    warn: function warn(message, customData) {
      var data = this._createLogData("warn", message, customData);
      this._sendLogData(data);
    },
    error: function error(message, customData) {
      var data = this._createLogData("error", message, customData);
      this._sendLogData(data);
    }
  });
});