define("copilot/validations/brands/cni/validators/top-level-category", ["exports", "@condenast/cross-check-dsl"], function (exports, _crossCheckDsl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TopLevelCategoryValidator = undefined;


  /**
   * A @condenast/cross-check-dsl validator that verifies that the primary selected
   * category is a top-level category: the selected category must be the direct
   * descendent of a root category. This is done by verifying the 'depth' property
   * @module copilot/brands/cni/validations/validators/top-level-category
   * @augments Ember.Service
   */
  class TopLevelCategoryValidator extends _crossCheckDsl.ValueValidator {
    validate(value) {
      if (!this.env.get(value, "sections.length")) {
        return;
      }

      var depth = this.env.get(value, "sections.firstObject.depth");
      var isTopLevelCategoryFirst = depth === 1;

      if (!isTopLevelCategoryFirst) {
        return { name: "top-level-category-primary" };
      }
    }
  }

  exports.TopLevelCategoryValidator = TopLevelCategoryValidator;
  exports.default = (0, _crossCheckDsl.builderFor)(TopLevelCategoryValidator);
});