define("copilot/brands/cnt/models/specialreport", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str,
      revision = _attr.default.revision,
      array = _attr.default.array;


  var SpecialReport = _base.default.extend({
    type: "specialreport",
    hed: (0, _emberModel.attr)(),
    dek: (0, _emberModel.attr)(),
    channel: (0, _emberModel.attr)(str),
    subChannel: (0, _emberModel.attr)(str),
    tags: (0, _emberModel.attr)(array),
    revision: (0, _emberModel.attr)(revision),
    contributorsAuthor: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    contributorsPhotographer: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    items: (0, _emberModel.hasMany)("list-item", {
      embedded: true
    }),
    searchTitle: Ember.computed.reads("hed")
  });

  SpecialReport.reopenClass({
    url: "/api/specialreports"
  });

  exports.default = SpecialReport;
});