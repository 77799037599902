define("copilot/components/action-notifications/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO: Consider moving this attrMap to config file
  var attrMap = {
    season: {
      true: {
        active: "Season is active!",
        featured: "Season is featured!",
        showCalendar: "Calendar is showing!"
      },
      false: {
        active: "Season is not active.",
        featured: "Season is not featured.",
        showCalendar: "Calendar is not showing."
      }
    },
    fashionshow: {
      true: {
        instantShow: "Fashion show is instant!",
        highlighted: "Fashion show is promoted!",
        showInFeed: "Fashion show is included in feed!",
        showLivestream: "Livestream is showing!"
      },
      false: {
        instantShow: "Fashion show is not instant.",
        highlighted: "Fashion show is not promoted.",
        showInFeed: "Fashion show is included in feed.",
        showLivestream: "Livestream is not showing."
      }
    }
  };

  var ActionNotifications = Ember.Component.extend({
    classNames: ["action-notifications"],
    classNameBindings: ["display:fade-in:fade-out"],
    content: null,
    display: false,

    type: Ember.computed("model.content.type", function () {
      return this.get("model.content.type") || "fashionshow";
    }),

    didInsertElement: function didInsertElement() {
      var model = this.model;
      model.on("success", this, this.displayNotification);
    },
    willDestroyElement: function willDestroyElement() {
      var model = this.model;
      model.off("success", this, this.displayNotification);
    },
    displayNotification: function displayNotification(attribute, value) {
      var type = this.type;
      this.set("content", attrMap[type][value][attribute]);
      this.set("display", true);
      Ember.run.later(this, function () {
        this.set("display", false);
      }, this.constructor.FLASH_DURATION);
    }
  });

  exports.default = ActionNotifications.reopenClass({
    FLASH_DURATION: 1500
  });
});