define("copilot/models/media", ["exports", "ember-model", "copilot/models/base", "copilot/models/attr"], function (exports, _emberModel, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str,
      array = _attr.default.array;


  var VARIANT_SIZES = ["variantXsmall", "variantSmall", "variantMedium", "variantLarge", "variantXlarge"];

  var MediaModel = _base.default.extend({
    type: "media",
    filename: (0, _emberModel.attr)(),
    notes: (0, _emberModel.attr)(),
    tags: (0, _emberModel.attr)(array),
    title: (0, _emberModel.attr)(str),
    syndication: (0, _emberModel.attr)(),
    variantXsmall: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    variantSmall: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    variantMedium: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    variantLarge: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    variantXlarge: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    thumbnail: Ember.computed(function () {
      return this;
    }),
    variants: VARIANT_SIZES,

    variantCount: Ember.computed("variantXsmall.[]", "variantSmall.[]", "variantMedium.[]", "variantLarge.[]", "variantXlarge.[]", function () {
      var _this = this;

      var count = 0;
      VARIANT_SIZES.forEach(function (name) {
        count += _this.get(name + ".length");
      });
      return count;
    }),

    notSaved: Ember.computed.empty("id"),

    searchTitle: Ember.computed("filename", "images.original.filename", function () {
      return this.title || this.filename || this.get("images.original.filename");
    }),

    dirtyObserver: Ember.observer("tags.[]", function (obj, prop) {
      var dirtyProp = prop.split(".")[0];
      var dirtyAttributes = this._dirtyAttributes;

      if (!dirtyAttributes.includes(dirtyProp)) {
        dirtyAttributes.pushObject(dirtyProp);
      }
    })
  });

  exports.default = MediaModel;
});