define("copilot/components/category-picker/selection/row/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    pathToRoot: Ember.computed("category.hierarchy", function () {
      return this.get("category.hierarchy").mapBy("name").reverse().slice(1).join(this.intl.t("category-manager.pathSeparator"));
    })
  });
});