define("copilot/initializers/store-model", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    Ember.debug("Store-Model initializer");
    application.inject("model", "store", "service:store");
    application.inject("serializer", "store", "service:store");
  }

  exports.default = {
    name: "store-model",
    initialize: initialize
  };
});