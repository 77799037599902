define("copilot/helpers/key-down", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var CODE_MAP = {
    27: "esc",
    9: "tab"
  };

  /**
    `{{key-down}}` listens to global keyboard events
    and triggers an event when the given key combo is
    pressed. This is useful when handling keyboard
    shortcuts like `⌘  s` for saving, `⌘  -` for zooming
    out, `⌘  +` for zooming in, and `esc` for cancelling
    an action.
  
    @module copilot/helpers/key-down
    @augments Ember.Helper
    @param {string} keyCombination A space separated key combination
                                   that will trigger the action
    @param {function} action The closure action to trigger when
                             the key combination is pressed
   */
  exports.default = Ember.Helper.extend({
    compute: function compute(_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 2),
          combo = _ref2[0],
          action = _ref2[1];

      var preventDefault = hash.preventDefault;
      if (preventDefault == null) {
        preventDefault = true;
      }
      this.addEventHandler(Ember.run.bind(this, "keydown", combo.split(" "), action), preventDefault);

      return "";
    },
    addEventHandler: function addEventHandler(action) {
      this.removeEventHandler();
      this._keydown = action;
      Ember.$(document).on("keydown", this._keydown);
    },
    removeEventHandler: function removeEventHandler() {
      if (this._keydown) {
        Ember.$(document).off("keydown", this._keydown);
        this._keydown = null;
      }
    },
    keydown: function keydown(combination, action, evt, preventDefault) {
      var keyCode = evt.keyCode || evt.which;
      var isCombo = combination.every(function (key) {
        if (evt.hasOwnProperty(key + "Key")) {
          return evt[key + "Key"];
        } else {
          var keyPressed = CODE_MAP[keyCode] || String.fromCharCode(keyCode);
          return keyPressed === key;
        }
      });

      if (isCombo && action) {
        action();
        if (preventDefault) {
          evt.preventDefault();
          return false;
        }
      }
    },
    destroy: function destroy() {
      this.removeEventHandler();
    }
  });
});