define("copilot/parsers/markdown-preview", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var createFragmentWithCaption = function createFragmentWithCaption(meta, cssClass) {
    var html = "<div class=\"embed\"><span class=\"icon-" + cssClass + "\"></span>" + meta.type + ": " + meta.url;
    if (!Ember.isNone(meta.caption) && meta.caption !== "") {
      html += meta.caption;
    }
    html += "</div>";
    return html;
  };

  var createSocialFragment = function createSocialFragment(meta, cssClass) {
    var html = "<div class=\"embed\"><span class=\"icon-" + cssClass + "\"></span>" + meta.type + ": " + meta.url + "</div>";
    return html;
  };

  exports.default = Ember.Object.extend({
    parse: function parse(text) {
      try {
        return _copilotMarkdown.default.parseConde(text, {
          activity: function activity(meta) {
            return createFragmentWithCaption(meta, "activity");
          },
          article: function article(meta) {
            return createFragmentWithCaption(meta, "article");
          },
          bar: function bar(meta) {
            return createFragmentWithCaption(meta, "bar");
          },
          brand: function brand(meta) {
            return createFragmentWithCaption(meta, "brand");
          },
          callout: function callout(meta) {
            var html = "<div class=\"callout\"><sup>" + meta.name + "</sup>";
            if (!Ember.isNone(meta.body)) {
              html += meta.body;
            }
            html += "</div>";
            return html;
          },
          cartoon: function cartoon(meta) {
            return createFragmentWithCaption(meta, "cartoon");
          },
          clip: function clip(meta) {
            return createFragmentWithCaption(meta, "clip");
          },
          cneembed: function cneembed(meta) {
            return createFragmentWithCaption(meta, "youtube");
          },
          cnevideo: function cnevideo(meta) {
            return createFragmentWithCaption(meta, "youtube");
          },
          curatedlist: function curatedlist(meta) {
            return createFragmentWithCaption(meta, "curatedList");
          },
          destination: function destination(meta) {
            return createFragmentWithCaption(meta, "destination");
          },
          external: function external(meta) {
            return createFragmentWithCaption(meta, "externalReference");
          },
          externallink: function externallink(meta) {
            return createFragmentWithCaption(meta, "externalLink");
          },
          facebook: function facebook(meta) {
            return createSocialFragment(meta, "facebook");
          },
          gallery: function gallery(meta) {
            return createFragmentWithCaption(meta, "gallery");
          },
          golfball: function golfball(meta) {
            return createFragmentWithCaption(meta, "golfBall");
          },
          golfclub: function golfclub(meta) {
            return createFragmentWithCaption(meta, "golfClub");
          },
          hotel: function hotel(meta) {
            return createFragmentWithCaption(meta, "hotel");
          },
          iframe: function iframe(meta) {
            return createFragmentWithCaption(meta, "crop");
          },
          image: function image(meta) {
            return createFragmentWithCaption(meta, "photo");
          },
          instagram: function instagram(meta) {
            return createSocialFragment(meta, "instagram");
          },
          menu: function menu(meta) {
            return createFragmentWithCaption(meta, "menu");
          },
          package: function _package(meta) {
            return createFragmentWithCaption(meta, "mascot");
          },
          pinterest: function pinterest(meta) {
            return createSocialFragment(meta, "pinterest");
          },
          product: function product(meta) {
            return createFragmentWithCaption(meta, "product");
          },
          recipe: function recipe(meta) {
            return createFragmentWithCaption(meta, "mascot");
          },
          restaurant: function restaurant(meta) {
            return createFragmentWithCaption(meta, "restaurant");
          },
          review: function review(meta) {
            return createFragmentWithCaption(meta, "review");
          },
          section: function section(meta) {
            var html = "<section>" + meta.body + "</section>";
            return html;
          },
          ship: function ship(meta) {
            return createFragmentWithCaption(meta, "ship");
          },
          shop: function shop(meta) {
            return createFragmentWithCaption(meta, "shop");
          },
          twitter: function twitter(meta) {
            return createSocialFragment(meta, "twitter");
          },
          video: function video(meta) {
            return createFragmentWithCaption(meta, "play");
          },
          videoPlain: function videoPlain(meta) {
            return createFragmentWithCaption(meta, "play");
          }
        }, []);
      } catch (e) {
        this.consoleLogger.error("Error in parseConde when trying to input " + text, e);
      }
    }
  });
});