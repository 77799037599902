define("copilot/brands/epi/models/menu", ["exports", "copilot/models/base", "copilot/models/attr", "ember-model", "copilot/lib/copilot-computeds"], function (exports, _base, _attr, _emberModel, _copilotComputeds) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var stringByline = _copilotComputeds.default.stringByline;
  var str = _attr.default.str;


  var Menu = _base.default.extend({
    type: "menu",
    hed: (0, _emberModel.attr)(),
    promoHed: (0, _emberModel.attr)(),
    socialTitle: (0, _emberModel.attr)(),
    socialDescription: (0, _emberModel.attr)(str),
    seoTitle: (0, _emberModel.attr)(str),
    seoDescription: (0, _emberModel.attr)(str),
    dek: (0, _emberModel.attr)(),
    promoDek: (0, _emberModel.attr)(),
    primaryChannel: (0, _emberModel.attr)(str),
    amgUserId: (0, _emberModel.attr)(),
    byline: (0, _emberModel.attr)(str),
    menuType: (0, _emberModel.attr)(str, {
      defaultValue: "editorial"
    }),
    courseRecipe: (0, _emberModel.hasMany)("recipe", {
      embedded: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    contributorsAuthor: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    taxonomy: (0, _emberModel.hasMany)("taxonomy", {
      embedded: true
    }),
    imageSocial: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),

    courses: (0, _emberModel.hasMany)("course", {
      embedded: "always"
    }),
    searchTitle: Ember.computed.reads("hed"),

    computedByline: stringByline(),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      promoHed: { source: "hed", sanitize: false },
      promoDek: { source: "dek", sanitize: false },
      socialTitle: "hed",
      socialDescription: "dek",
      seoTitle: "hed",
      seoDescription: "dek",
      imageSocial: "photosTout.firstObject",
      byline: "computedByline"
    }
  });

  Menu.reopenClass({
    url: "/api/menus"
  });

  exports.default = Menu;
});