define("copilot/forms/audiofile", ["exports", "@condenast/copilot-core"], function (exports, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _copilotCore.AudiofileForm;
    }
  });
});