define("copilot/brands/p4k/routes/articles/index/edit", ["exports", "copilot/routes/articles/index/edit", "ember-concurrency", "ic-ajax"], function (exports, _edit, _emberConcurrency, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    config: Ember.inject.service(),
    saveService: Ember.inject.service("save"),

    createAndLinkLongform: (0, _emberConcurrency.task)(function* (model) {
      var _this = this;

      var base = this.get("config.config.forBrand.longformBase");

      var data = yield (0, _icAjax.default)(base + "/api/v1/longform/", {
        crossDomain: true,
        method: "POST",
        beforeSend: function beforeSend(xhr) {
          return xhr.setRequestHeader("Authorization", _this.get("config.config.forBrand.apiClientSecret"));
        },
        data: {
          name: model.get("hed")
        },
        dataType: "json"
      });

      var externalLink = this.store.createRecord("externallink", {
        hed: model.get("hed") + " longform link",
        url: base + "/admin/longform/longform/" + data.id + "/editor/"
      });

      // save the external link
      yield this.saveService.save(externalLink);
      model.get("longformLink").pushObject(externalLink);
    }),

    actions: {
      convertToLongform: function convertToLongform() {
        var _this2 = this;

        return this.createAndLinkLongform.perform(this.currentModel).then(function () {
          _this2.controller.send("save", function () {});
        });
      }
    }
  });
});