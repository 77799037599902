define("copilot/brands/vogue/models/fashionshow", ["exports", "copilot/models/base", "copilot/models/attr", "ic-ajax"], function (exports, _base, _attr, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr,
      hasMany = Ember.hasMany;
  var array = _attr.default.array,
      str = _attr.default.str;


  var FashionShow = _base.default.extend({
    type: "fashionshow",

    promoTitle: attr(str),
    address: attr(),
    atmosphere: hasMany("fashiongallery", {
      embedded: true
    }),
    beauty: hasMany("fashiongallery", {
      embedded: true
    }),
    brand: hasMany("brand", {
      embedded: true
    }),
    city: hasMany("city", {
      embedded: true
    }),
    collection: hasMany("fashiongallery", {
      embedded: true
    }),
    coverage: hasMany("article", {
      embedded: true
    }),
    designer: hasMany("person", {
      embedded: true
    }),
    detail: hasMany("fashiongallery", {
      embedded: true
    }),

    // Had to use polymorphic here becase naming the type `event` confused ember
    // Model and it actually returns a native browser event object for
    // relationshipType! LOL
    event: hasMany("base", {
      embedded: true,
      polymorphic: true
    }),
    frontRow: hasMany("fashiongallery", {
      embedded: true
    }),
    highlighted: attr(Boolean, {
      defaultValue: false
    }),
    instantShow: attr(Boolean, {
      defaultValue: false
    }),
    keywords: attr(array),
    livestream: attr(),
    modaLink: attr(),
    photosLede: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    photosTout: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),
    presale: attr(Boolean),
    review: hasMany("review", {
      embedded: true
    }),
    runway: hasMany("fashiongallery", {
      embedded: true
    }),
    season: hasMany("season", {
      embedded: true
    }),
    showInFeed: attr(Boolean, {
      defaultValue: false
    }),
    showLivestream: attr(Boolean, {
      defaultValue: false
    }),
    title: attr(),
    vogueReviewLinkText: attr(),
    vogueReview: attr(),
    video: hasMany("cnevideo", {
      embedded: true
    }),
    videosLede: hasMany("cnevideo", {
      embedded: true
    }),
    searchTitle: Ember.computed.reads("title"),

    // TODO: Season and Brand Slug can lookup season/brand directly on the hasAnything
    // relations, instead of _data, once the multiple-select component is refactored
    // to no longer mutate model data upon insertion into the DOM
    seasonSlug: Ember.computed("_data.season.firstObject", function () {
      var seasonURI = this.get("_data.season.firstObject._embedded.publishHistory.uri");
      return seasonURI && seasonURI.replace("fashion-shows/", "");
    }),

    brandSlug: Ember.computed("_data.brand.firstObject", function () {
      var brandURI = this.get("_data.brand.firstObject._embedded.publishHistory.uri");
      return brandURI && brandURI.replace("brands/", "");
    }),

    afterSaveWorker: function afterSaveWorker(isNew, result) {
      return new Ember.RSVP.Promise(function (resolve) {
        if (Ember.isNone(result.get("id"))) {
          var error = new Error("create " + this.type + " failed (" + this.get("_data.error") + ")");
          Ember.onerror(error);
        }

        // increment should not be needed here after this issue github.com/ebryn/ember-model/issues/275
        this.set("revision", result.incrementProperty("revision"));

        // We must clear _dirtyAttributes after incrementing 'revision'
        // since a newly-saved model should never be considered dirty
        this.getWithDefault("_dirtyAttributes", []).clear();

        this.set("publishHistory", result.get("publishHistory"));

        if (isNew) {
          this.set("id", result.get("id"));
        }

        resolve(isNew ? "new" : "");
      }.bind(this));
    }
  });

  FashionShow.reopenClass({
    findAll: function findAll(seasonId) {
      var baseUrl = "/api/seasons/:id/fashionshows";
      var url = baseUrl.replace(":id", seasonId);
      return (0, _icAjax.default)(url);
    },

    url: "/api/fashionshows"
  });

  exports.default = FashionShow;
});