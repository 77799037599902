define("copilot/brands/cnt/models/skiresort", ["exports", "copilot/brands/cnt/models/venue"], function (exports, _venue) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;


  var Skiresort = _venue.default.extend({
    type: "skiresort",
    numberOfLifts: attr(Number),
    numberOfRuns: attr(Number)
  });

  Skiresort.reopenClass({
    url: "/api/skiresorts"
  });

  exports.default = Skiresort;
});