define("copilot/helpers/guess-time-zone", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.guessTimeZone = guessTimeZone;
  function guessTimeZone() {
    var zone = _moment.default.tz.guess();
    return _moment.default.tz.zone(zone).abbr(new Date());
  }

  exports.default = Ember.Helper.helper(guessTimeZone);
});