define("copilot/components/hyperlink-editor/component", ["exports", "copilot/mixins/validatable"], function (exports, _validatable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_validatable.default, {
    type: "hyperlink-editor",
    data: null,
    linkUrl: Ember.computed.alias("data.linkUrl"),
    linkText: Ember.computed.alias("data.linkText"),

    actions: {
      submit: function submit() {
        var _this = this;

        this.validate().then(function (isValid) {
          if (isValid) {
            var linkUrl = _this.linkUrl || "";
            var submitAction = _this.submit;
            var callback = _this.get("data.callback");

            var linkText = _this.linkText;
            var noFollow = _this.noFollow;
            var submitData = {
              callback: callback,
              linkText: linkText,
              linkUrl: linkUrl.trim()
            };

            if (noFollow) {
              submitData.attributes = {
                rel: "nofollow"
              };
            }

            submitAction(submitData);
          }
        });
      }
    }
  });
});