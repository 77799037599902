define("copilot/brands/bon/models/venue", ["exports", "copilot/models/base", "copilot/models/attr", "ember-model"], function (exports, _base, _attr, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var address = _attr.default.address,
      array = _attr.default.array,
      geo = _attr.default.geo,
      str = _attr.default.str;


  var Venue = _base.default.extend({
    active: (0, _emberModel.attr)(Boolean, {
      defaultValue: true
    }),
    address: (0, _emberModel.attr)(address),
    body: (0, _emberModel.attr)(str),
    channel: (0, _emberModel.attr)(str),
    contributorsAuthor: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    contributorsPhotographer: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    dek: (0, _emberModel.attr)(),
    email: (0, _emberModel.attr)(array),
    geo: (0, _emberModel.attr)(geo),
    hed: (0, _emberModel.attr)(),
    issueDate: (0, _emberModel.attr)(),
    paymentsAccepted: (0, _emberModel.attr)(array),
    phone: (0, _emberModel.attr)(array),
    photosBadge: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    photosSocial: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    price: (0, _emberModel.attr)(Number),
    promoDek: (0, _emberModel.attr)(),
    promoHed: (0, _emberModel.attr)(),
    related: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    reservationLink: (0, _emberModel.attr)(str),
    seoTitle: (0, _emberModel.attr)(str),
    seoDescription: (0, _emberModel.attr)(str),
    socialDescription: (0, _emberModel.attr)(str),
    socialMedia: (0, _emberModel.hasMany)("social-media", {
      embedded: "always"
    }),
    socialShortUrl: (0, _emberModel.attr)(),
    socialTitle: (0, _emberModel.attr)(str),
    subChannel: (0, _emberModel.attr)(str),
    subType: (0, _emberModel.attr)(),
    taxonomy: (0, _emberModel.hasMany)("taxonomy", {
      embedded: true
    }),
    type: "venue",
    url: (0, _emberModel.attr)(array),
    videosTout: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("hed"),

    embeddableFields: ["body"],

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      promoHed: { source: "hed", sanitize: false },
      promoDek: { source: "dek", sanitize: false },
      socialTitle: "hed",
      socialDescription: "dek",
      seoDescription: "dek",
      seoTitle: "hed"
    }
  });

  Venue.reopenClass({
    url: "/api/venues"
  });

  exports.default = Venue;
});