define("copilot/brands/vogue/models/slim-fashionshow", ["exports", "copilot/brands/vogue/models/slim-model"], function (exports, _slimModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _slimModel.default.extend(Ember.Evented, {
    content: null,
    baseURI: "/api/fashionshows/",

    init: function init() {
      this._super.apply(this, arguments);
      this.requiredAttrs = [];
    },


    eventModel: Ember.computed.reads("content.event.firstObject"),

    // Employing the afterSave hook here in order to ensure that the 'instantShow'
    // property of the event tracks that of the fashionshow
    afterSave: function afterSave(responseJSON) {
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var eventModel = this.eventModel;
        if (eventModel && eventModel.instantShow !== responseJSON.instantShow) {
          var url = "/api/fashionshowevents/" + eventModel.id;
          var data = {
            instantShow: responseJSON.instantShow
          };
          return Ember.$.ajax(url, {
            url: url,
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            type: "PUT",
            tag: "vogue.models.slim-fashionshow"
          }).then(function () {
            resolve(responseJSON);
          }, function (error) {
            reject(error);
          });
        } else {
          resolve(responseJSON);
        }
      }.bind(this));
      return promise;
    }
  });
});