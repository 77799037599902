define("copilot/models/group", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Model.extend({
    _id: null,
    name: null
  });
});