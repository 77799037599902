define("copilot/schemas/brands/wrd/article", ["exports", "@condenast/copilot-brand-overrides/dist/modules/schemas/wrd/article"], function (exports, _article) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _article.default;
    }
  });
});