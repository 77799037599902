define("copilot/models/offer", ["exports", "copilot/models/embedded", "ember-model", "copilot/models/attr"], function (exports, _embedded, _emberModel, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var price = _attr.default.price,
      string = _attr.default.str,
      seller = _attr.default.seller,
      boolean = _attr.default.boolean,
      isOutOfStock = _attr.default.isOutOfStock;
  exports.default = _embedded.default.extend({
    type: "offer",
    offerId: (0, _emberModel.attr)(),
    seller: (0, _emberModel.attr)(seller),
    purchaseUri: (0, _emberModel.attr)(string),
    shortUrl: (0, _emberModel.attr)(),
    offerType: (0, _emberModel.attr)(string, {
      defaultValue: "purchase"
    }),
    price: (0, _emberModel.attr)(price),
    comparisonPrice: (0, _emberModel.attr)(price),
    isOutOfStock: (0, _emberModel.attr)(isOutOfStock),
    autoUpdate: (0, _emberModel.attr)(boolean, {
      defaultValue: false
    }),
    countryCode: (0, _emberModel.attr)(string)
  });
});