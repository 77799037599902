define("copilot/components/ck-editor/component", ["exports", "ember-service-methods/inject", "ember-file-upload/file", "@condenast/ckeditor5-build-condenast", "@condenast/atjson-source-copilot-markdown", "@condenast/atjson-source-verso", "@condenast/atjson-source-autopilot", "@condenast/atjson-renderer-ckeditor", "copilot/lib/copilot-markdown", "@sentry/browser", "copilot/components/ck-editor/redirector-utils"], function (exports, _inject, _file, _ckeditor5BuildCondenast, _atjsonSourceCopilotMarkdown, _atjsonSourceVerso, _atjsonSourceAutopilot, _atjsonRendererCkeditor, _copilotMarkdown, _browser, _redirectorUtils) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var REDIRECTOR_URLS = /^(https?:)?\/\/([a-zA-Z0-9]+\.)?cna\.st\/affiliate-link\//;
  var EXCEPTIONS = [/^(https?:\/\/)?shop-links\.co\//, // Narrativ
  /^(https?:\/\/)?www\.glamour\.com\//, // glamour
  /^(https?:\/\/)?(www\.)?bonsai\.to\// // bonsai
  ];

  function convertInlineMarkdown(markdown) {
    var doc = _atjsonSourceCopilotMarkdown.default.fromRaw(markdown).convertTo(_atjsonSourceVerso.default);
    doc.where({ type: "-offset-paragraph" }).remove();
    return doc;
  }

  // identifier used to lock/unlock the editor in readonly mode
  var LOCK_ID = "lock-id";

  var TRACKING_EVENTS = {
    ROUTE_CHANGE: "route-change",
    BODY_READY: "ckeditor-body-ready",
    READY_FROM_ROUTE: "ckeditor-body-ready-from-route",
    READY_FROM_LOAD: "ckeditor-body-ready-from-load"
  };

  /**
   * Creates an instance of the ckeditor build
   *
   * ```hbs
   * {{ck-editor value=value config=config onChange=onChange}}
   * ```
   *
   * @module copilot/components/ck-editor/component
   * @augments Ember.Component
   * @param {String} value - The initial value of the rich text field as an html string
   * @param {Object} config - The editor configuration object, see https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/configuration.html
   * @param {Boolean} isInline - A flag to indicate whether to restrict the editor build to only allow inline elements
   * @property {Function} onChange - An onChange handler invoked when the content is modified in the editor
   * @property {Function} onEditorInit - A callback invoked when the Editor instance is initialized, called with the editor
   *
   */
  exports.default = Ember.Component.extend({
    classNames: ["ck-editor-container"],
    classNameBindings: ["isFilledBySmartSuggestion:smart-filled", "hasSmartSuggestion:has-smart-suggestion"],
    brand: Ember.computed.alias("brandConfig.activeBrand"),
    configService: Ember.inject.service("config"),
    environment: Ember.inject.service("environment"),
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    photo: Ember.inject.service(),
    consoleLogger: Ember.inject.service(),
    uploadAsset: (0, _inject.default)(),
    analytics: Ember.inject.service(),
    routing: Ember.inject.service("-routing"),
    pim: Ember.inject.service(),

    consumerHostname: Ember.computed.reads("configService.config.hostnames.consumer"),
    mediaHostname: Ember.computed.reads("photo.vulcanUrl"),

    editor: undefined,
    readOnly: Ember.computed({
      get: function get() {
        return this._readOnly;
      },
      set: function set(_, value) {
        if (this.editor) {
          if (value) {
            this.editor.enableReadOnlyMode(LOCK_ID);
          } else {
            this.editor.disableReadOnlyMode(LOCK_ID);
          }
        }
        this._readOnly = value;
        return this._readOnly;
      }
    }),
    shouldUpdateOnSave: Ember.computed("field", function () {
      return this.field === "body" && Ember.isPresent(this.setEditorForBody);
    }),

    linkAutogen: Ember.computed("hasLinkAutogen", function () {
      if (this.hasLinkAutogen) {
        return this.configService.get("brandConfig.ckeditorLinkAutogen");
      }
    }),

    hasLinkAutogen: Ember.computed("field", "features.ckeditorLinkAutogen", "configService.brandConfig.ckeditorLinkAutogen", function () {
      if (this.field !== "body" || !this.features.get("ckeditorLinkAutogen")) {
        return;
      }

      return !!this.configService.get("brandConfig.ckeditorLinkAutogen.url");
    }),
    copilotId: "",

    _generateAtjsonDoc: function _generateAtjsonDoc(value) {
      return this.features.get("verso") ? _atjsonSourceCopilotMarkdown.default.fromRaw(value || "").convertTo(_atjsonSourceVerso.default) : _atjsonSourceAutopilot.default.fromRaw(value || "", {
        brand: this.brand,
        fieldName: this.field
      }).convertTo(_atjsonSourceVerso.default);
    },
    _getButtonsInUse: function _getButtonsInUse() {
      return this.buttons.filter(function (config) {
        return !config.notInUse;
      });
    },
    _getEditorBuild: function _getEditorBuild() {
      var field = (this.field || "").split(".").get("lastObject");
      var type = this.type;
      var typeSpecificConfig = this.get("brandConfig.richTextEditor." + type + "." + field + ".build");
      var fieldConfig = this.get("brandConfig.richTextEditor." + field + ".build");
      var editorType = typeSpecificConfig || fieldConfig;

      if (editorType === "Block" || editorType === "Body") {
        return _ckeditor5BuildCondenast.default.BlockEditor;
      }

      if (editorType === "Inline") {
        return _ckeditor5BuildCondenast.default.InlineEditor;
      }

      if (editorType === "Minimalist") {
        return _ckeditor5BuildCondenast.default.MinimalistEditor;
      }

      var errMessage = "The configured editor build is not recognized. We're defaulting to the Minimalist editor build, but please update the configuration.";

      this.consoleLogger.error(errMessage, {
        type: type,
        field: field,
        editorType: editorType
      });

      _browser.withScope(function (scope) {
        scope.setExtra("content type", type);
        scope.setExtra("field", field);
        scope.setExtra("configured editor build", "" + editorType);

        _browser.captureException(new Error(errMessage));
      });

      return _ckeditor5BuildCondenast.default.MinimalistEditor;
    },
    _filterButtons: function _filterButtons(toolbar) {
      var buttonNames = this._getButtonsInUse().map(function (config) {
        return config.ckeditorButton || config.name;
      });
      var items = toolbar.hasOwnProperty("items") ? toolbar.items : toolbar;

      return items.filter(function (itemName) {
        return buttonNames.includes(itemName);
      });
    },
    _getPlugins: function _getPlugins(EditorBuild) {
      var pluginNames = this._getButtonsInUse().mapBy("ckeditorPlugin").filter(function (pluginName) {
        return !!pluginName;
      });
      return [].concat(_toConsumableArray(EditorBuild.requiredPlugins || []), _toConsumableArray((EditorBuild.optionalPlugins || []).filter(function (plugin) {
        return pluginNames.includes(plugin.pluginName);
      })));
    },
    getCidForGalleries: function getCidForGalleries() {
      var currentURL = this.get("routing.router.currentURL").split(/[?#]/)[0],
          currentURLParams = currentURL.split("/"),
          galleryStrIndex = currentURLParams.indexOf("galleries");

      if (galleryStrIndex !== -1 && currentURLParams.length >= 5) {
        var copilotId = currentURLParams[galleryStrIndex + 1];
        return copilotId;
      } else {
        return this.copilotId;
      }
    },
    _getConfig: function _getConfig(EditorBuild) {
      var _this = this;

      var config = this.config || {
        toolbar: Object.assign({}, Ember.get(EditorBuild, "defaultConfig.toolbar") || {}, {
          items: this._filterButtons(Ember.get(EditorBuild, "defaultConfig.toolbar.items") || [])
        }),
        blockToolbar: Object.assign({}, Ember.get(EditorBuild, "defaultConfig.blockToolbar") || {}, {
          items: this._filterButtons(Ember.get(EditorBuild, "defaultConfig.blockToolbar.items") || [])
        }),
        plugins: this._getPlugins(EditorBuild),
        photo: {
          crops: this.configService.get("config.photo.aspectRatios")
        }
      };

      var showHelperText = true;
      if (this.type === "livestoryupdate" && this.field === "body") {
        showHelperText = false;
      }

      config.bodyStyle = {
        showHelperText: showHelperText
      };

      if (this.configService.config.companyId === "cni" && !this.features.get("versoFrontEnd")) {
        config.copilotEmbed = {
          styles: []
        };

        config.clip = {
          styles: []
        };

        config.photo.styles = [];
        config.photo.enableLinking = true;

        config.product = {
          styles: []
        };

        config.gallery = {
          styles: [],
          displays: []
        };

        config.pullquote = {
          enableCredit: true,
          styles: []
        };
      } else {
        config.pullquote = {
          enableCredit: true,
          styles: ["default", "position-left", "position-right"]
        };
        if (!this.features.get("ckeditorProductSizes")) {
          config.product = {
            styles: ["default", "position-left", "position-right"]
          };
        }
        config.gallery = {
          styles: ["default", "position-left", "position-right"],
          displays: this.features.get("ckeditorGalleryDisplayStyles") ? ["promo", "slideshow"] : []
        };
      }

      var urlExpansions = this.get("brandConfig.richTextEditor.urlExpansions");
      if (urlExpansions) {
        config.embed = {
          urlExpansions: urlExpansions
        };
      }

      var paragraphDecorations = [];
      if (this.features.get("ckeditorDropCap")) {
        paragraphDecorations.push("dropCap");
      }
      if (this.features.get("ckeditorLeadIn")) {
        paragraphDecorations.push("leadIn");
      }
      config.paragraphDecorations = paragraphDecorations;

      config.alignment = this.features.get("ckeditorTextAlignment") ? { options: ["left", "center", "right"] } : {};

      var contentHandler = function () {
        var _ref2 = _asyncToGenerator(function* (_ref) {
          var type = _ref.type,
              id = _ref.id;

          var content = yield _this.store._find(type, id, true);
          if (type === "product") {
            return _this.convertProduct(content);
          }
          if (type === "contentreference") {
            var _ref3 = yield _this.pim.execute({ brandSlug: _this.brand }, content.id),
                _ref3$hits = _slicedToArray(_ref3.hits, 1),
                commerceData = _ref3$hits[0];

            return _this.convertContentReference(commerceData);
          }

          return _this.convertContent(content);
        });

        return function contentHandler(_x) {
          return _ref2.apply(this, arguments);
        };
      }();

      if (config.plugins.find(function (a) {
        return a.pluginName === "ContentSelector";
      })) {
        config.gql = {
          photo: function () {
            var _ref5 = _asyncToGenerator(function* (_ref4) {
              var id = _ref4.id;

              var photo = yield _this.store._find("photo", id, true);
              return _this.convertPhoto(photo);
            });

            function photo(_x2) {
              return _ref5.apply(this, arguments);
            }

            return photo;
          }(),
          clip: function () {
            var _ref7 = _asyncToGenerator(function* (_ref6) {
              var id = _ref6.id;

              var clip = yield _this.store._find("clip", id, true);
              return _this.convertClip(clip);
            });

            function clip(_x3) {
              return _ref7.apply(this, arguments);
            }

            return clip;
          }(),
          content: contentHandler,
          oembed: function () {
            var _ref9 = _asyncToGenerator(function* (_ref8) {
              var url = _ref8.url;

              var response = yield fetch("/api/oembed?url=" + encodeURIComponent(url));
              if (response.ok) {
                return response.json();
              } else {
                throw response.text();
              }
            });

            function oembed(_x4) {
              return _ref9.apply(this, arguments);
            }

            return oembed;
          }()
        };
      }

      if (config.plugins.find(function (a) {
        return a.pluginName === "InternalLink";
      })) {
        config.gql = {
          content: contentHandler
        };
      }

      config.button = Object.assign({
        decorators: {}
      }, EditorBuild.defaultConfig.button || {});

      config.link = Object.assign({
        decorators: {}
      }, EditorBuild.defaultConfig.link || {}, {
        editLinkText: this.features.get("ckeditorNewLinkModal")
      });

      config.link.decorators.emberHrefToIgnore = {
        mode: "editing",
        callback: function callback(href) {
          return href && !(href.startsWith("http") || href.startsWith("//"));
        },
        attributes: {
          "data-href-to-ignore": true
        }
      };

      if (this.hasLinkAutogen) {
        config.link.autogen = this.linkAutogen;
        config.gql = Object.assign({}, config.gql, {
          linkAutogenPatterns: function () {
            var _ref10 = _asyncToGenerator(function* () {
              var response = yield fetch(config.link.autogen.url, {
                headers: {
                  method: "GET",
                  Accept: "application/json"
                }
              });
              if (response.ok) {
                var definitions = yield response.json();

                return definitions.map(function (item) {
                  var slug = item[1];
                  if (slug.startsWith("https:")) {
                    return item;
                  }

                  return [item[0], "https://" + _this.consumerHostname + "/" + config.link.autogen.rootSlug + "/" + slug];
                });
              }
              throw new Error("Could not load pattern definitions");
            });

            function linkAutogenPatterns() {
              return _ref10.apply(this, arguments);
            }

            return linkAutogenPatterns;
          }()
        });
      }

      if (this.features.get("ckeditorAffiliateLinks")) {
        // See: https://github.com/CondeNast-Copilot/copilot/blob/main/app/routes/application.js#L457
        var activeBrand = this.configService.findBrand.byCopilotCode(this.configService.activeBrand);
        var organizationId = activeBrand && activeBrand.organizationGlobalNodeId;
        var redirectorEndpoint = this.configService.config.redirectorEndpoint;

        config.gql = Object.assign({}, config.gql, {
          affiliateLink: function () {
            var _ref12 = _asyncToGenerator(function* (_ref11) {
              var url = _ref11.url,
                  action = _ref11.action;

              var matchRedirectorUrl = url.match(REDIRECTOR_URLS);
              var urlOut = void 0;
              if (action === "DECODE" && matchRedirectorUrl) {
                // Only decode redirector URLs
                var _decodeAffiliateLink = (0, _redirectorUtils.decodeAffiliateLink)(url.replace(matchRedirectorUrl[0], "").replace(/\?.*$/, "")),
                    _decodeAffiliateLink2 = _slicedToArray(_decodeAffiliateLink, 2),
                    offerUrl = _decodeAffiliateLink2[1];

                urlOut = offerUrl;
              } else if (action === "ENCODE" && organizationId) {
                // Only encode if not a redirector URL - double encode
                // OR if domain is NOT flagged as an exception
                var skipAction = EXCEPTIONS.some(function (exceptionRegx) {
                  return url.match(exceptionRegx);
                });
                if (skipAction || matchRedirectorUrl) {
                  urlOut = url;
                } else {
                  var cid = _this.getCidForGalleries();
                  var encodedLink = (0, _redirectorUtils.encodeAffiliateLink)(organizationId, url, cid);
                  urlOut = "" + redirectorEndpoint + encodedLink;
                }
              } else {
                urlOut = url;
              }
              return {
                url: urlOut
              };
            });

            function affiliateLink(_x5) {
              return _ref12.apply(this, arguments);
            }

            return affiliateLink;
          }()
        });
      } else {
        config.gql = Object.assign({}, config.gql, {
          affiliateLink: function () {
            var _ref14 = _asyncToGenerator(function* (_ref13) {
              var url = _ref13.url;

              return {
                url: url
              };
            });

            function affiliateLink(_x6) {
              return _ref14.apply(this, arguments);
            }

            return affiliateLink;
          }()
        });
        config.link.decorators.isAffiliateLink = null;
        config.button.decorators.isAffiliateLink = null;
      }

      return Object.assign({}, config, {
        consumerHostname: this.consumerHostname,
        mediaHostname: this.mediaHostname,
        brand: this.brand
      });
    },
    convertPhoto: function convertPhoto(photo) {
      var credit = photo && photo.credit ? _atjsonSourceCopilotMarkdown.default.fromRaw(photo.credit).convertTo(_atjsonSourceVerso.default) : null;
      if (credit) {
        credit.where(function (a) {
          return a.type === "paragraph";
        }).remove();
      }
      return photo ? {
        __typename: "Photo",
        id: photo.id,
        altText: photo.altText,
        imageUrl: "https://" + this.mediaHostname + "/photos/" + photo.id + "/:aspect_ratio/:modifications/" + photo.filename,
        editUrl: "/" + this.brand + "/photos/" + photo.id,
        caption: photo.caption ? _atjsonSourceCopilotMarkdown.default.fromRaw(photo.caption).convertTo(_atjsonSourceVerso.default) : null,
        doNotCrop: photo.doNotCrop,
        credit: credit
      } : null;
    },
    convertClip: function convertClip(clip) {
      var credit = clip && clip.credit ? _atjsonSourceCopilotMarkdown.default.fromRaw(clip.credit).convertTo(_atjsonSourceVerso.default) : null;
      if (credit) {
        credit.where(function (a) {
          return a.type === "paragraph";
        }).remove();
      }

      return clip ? {
        __typename: "Clip",
        id: clip.id,
        altText: clip.altText,
        imageUrl: "https://" + this.mediaHostname + "/clips/" + clip.posterImage.id + "/:aspect_ratio/:modifications/" + clip.posterImage.filename,
        videoUrl: "https://" + this.mediaHostname + "/clips/" + clip.id + "/:aspect_ratio/:modifications/" + clip.filename,
        editUrl: "/" + this.brand + "/clips/" + clip.id,
        caption: clip.caption ? _atjsonSourceCopilotMarkdown.default.fromRaw(clip.caption).convertTo(_atjsonSourceVerso.default) : null,
        credit: credit
      } : null;
    },
    convertProduct: function convertProduct(product) {
      return product ? {
        __typename: "Product",
        id: product.id,
        type: "product",
        title: convertInlineMarkdown(product.name),
        editUrl: "/" + this.brand + "/products/" + product.id,
        asset: this.convertPhoto(product.thumbnail),
        offers: product.offers.map(function (offer) {
          return {
            sellerName: offer.seller.name,
            price: parseFloat(offer.price),
            comparisonPrice: parseFloat(offer.comparisonPrice),
            currency: offer.currency,
            isOutOfStock: offer.isOutOfStock
          };
        })
      } : null;
    },
    convertContentReference: function convertContentReference(contentReference) {
      return contentReference ? {
        __typename: "Product",
        id: contentReference.id,
        type: "contentReference",
        title: convertInlineMarkdown(contentReference.hed),
        editUrl: contentReference.editUrl,
        imageUrl: contentReference.imageUrl,
        asset: {
          __typename: "Photo",
          id: "",
          altText: contentReference.hed,
          imageUrl: contentReference.imageUrl,
          caption: _atjsonSourceCopilotMarkdown.default.fromRaw(contentReference.hed).convertTo(_atjsonSourceVerso.default)
        },
        offers: contentReference.offers.map(function (offer) {
          return {
            sellerName: offer.seller.name,
            price: parseFloat(offer.price),
            comparisonPrice: parseFloat(offer.comparisonPrice || 0),
            currency: offer.currency
          };
        })
      } : null;
    },
    generateBylines: function generateBylines(model) {
      var contributorKeys = Object.keys(model._embedded).filter(function (key) {
        return key.indexOf("contributors") === 0;
      });
      var byline = [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = contributorKeys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var key = _step.value;

          var contributors = model[key] || [];
          if (contributors.length > 0) {
            byline.push(this.intl.t("contributorCredits." + key.dasherize(), {
              names: contributors.map(function (contributor) {
                return contributor.text || contributor.name;
              }).filter(Boolean).map(function (name) {
                return name.trim();
              }).join(", ")
            }));
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return _copilotMarkdown.default.sanitize(byline.join(", "));
    },
    convertContent: function convertContent(content) {
      return content ? {
        __typename: content.meta.publishable ? "PublishableContent" : "EmbedabbleContent",
        id: content.id,
        type: content.meta.modelName,
        title: convertInlineMarkdown(content.searchTitle),
        editUrl: "/" + this.brand + "/" + content.meta.collectionName + "/" + content.id,
        liveUrl: content._embedded.publishHistory ? "https://" + this.consumerHostname + "/" + content._embedded.publishHistory.uri : null,
        asset: this.convertPhoto(content.thumbnail),
        byline: this.generateBylines(content),
        channels: (content.get("_embedded._categories.channels") || content.get("_embedded._categories.sections") || []).map(function (category) {
          return {
            name: category.name,
            slug: category.slug,
            id: category.id
          };
        })
      } : null;
    },
    _trackEditorReady: function _trackEditorReady() {
      if (this.field === "body") {
        var bodyReady = performance.mark(TRACKING_EVENTS.BODY_READY);

        var _performance$getEntri = performance.getEntriesByName(TRACKING_EVENTS.ROUTE_CHANGE),
            _performance$getEntri2 = _slicedToArray(_performance$getEntri, 1),
            lastRouteTransition = _performance$getEntri2[0];

        if (lastRouteTransition) {
          var durationFromRoute = performance.measure(TRACKING_EVENTS.READY_FROM_ROUTE, {
            start: lastRouteTransition.startTime,
            end: bodyReady.startTime
          }).duration;
          var shouldComputeTimeFromPageLoad = lastRouteTransition.detail && lastRouteTransition.detail.firstPageLoad;
          var durationFromLoad = shouldComputeTimeFromPageLoad ? performance.measure(TRACKING_EVENTS.READY_FROM_LOAD, {
            start: performance.getEntriesByType("navigation")[0].startTime,
            end: bodyReady.startTime
          }).duration : null;
          this.analytics.track("editor", "duration-to-ready", {
            durationFromLoad: durationFromLoad,
            durationFromRoute: durationFromRoute
          });
        }
      }
    },
    _initEditor: function _initEditor() {
      var _this2 = this;

      var EditorBuild = this._getEditorBuild();
      var value = this.value || "";

      var config = this._getConfig(EditorBuild);

      EditorBuild.create(this.element.querySelector(".editor"), config).then(function (editor) {
        _this2.set("editor", editor);
        editor.setData(value);
        if (_this2._readOnly) {
          editor.enableReadOnlyMode(LOCK_ID);
        }
        editor.ui.focusTracker.on("change:isFocused", function (evt, name, isFocused) {
          _this2.toggleFocus("ckeditor", isFocused);
        });

        if (_this2.shouldUpdateOnSave) {
          _this2.setEditorForBody(editor);
        }

        _this2._trackEditorReady();

        if (_this2.onEditorInit && typeof _this2.onEditorInit === "function") {
          _this2.onEditorInit(editor);
        }

        editor.model.document.on("change:data", function () {
          if (!_this2.shouldUpdateOnSave && _this2.onChange) {
            _this2.onChange(_this2.editor.getData(), _this2.currentModelId);
          } else {
            _this2.setUnsavedChanges();
          }

          if (_this2.updateTimespentMetadata) {
            _this2.updateTimespentMetadata();
          }
        });

        editor.on("anchor-link-create", function () {
          if (!_this2._recordingStarted && window.hasOwnProperty("hj")) {
            hj("trigger", "anchor_link_create");
            _this2._recordingStarted = true;
          }
        });
        editor.on("anchor-link-edit", function () {
          if (!_this2._recordingStarted && window.hasOwnProperty("hj")) {
            hj("trigger", "anchor_link_edit");
            _this2._recordingStarted = true;
          }
        });

        editor.on("content-selector-click", function (_event, success, options) {
          _this2.set("isSelectingAssets", true);
          if (options && options.types) {
            _this2.set("assetTypeList", options.types);
          }
          _this2.set("assetCallback", function (assets) {
            assets = Array.isArray(assets) ? assets : [assets];
            var embedData = assets.reduce(function (embeds, asset) {
              var embedData = _this2._getEmbedData(asset);
              if (embedData) {
                embeds.push(embedData);
              }
              return embeds;
            }, []);

            success(embedData);
          });
          return true;
        });

        editor.on("upload", function (_evt, file, success, error) {
          _this2.uploadAsset(_file.default.fromBlob(file, file.name)).then(function (asset) {
            success({
              copilotid: asset.id,
              brand: _this2.brand
            });
          }).catch(function (e) {
            error({
              errorMessage: e.message || e.errorMessage || "Unknown error"
            });
          });
        });
      });
    },
    _getEmbedData: function _getEmbedData(asset) {
      var collection = asset.constructor.metadata && asset.constructor.metadata.collectionName;
      switch (collection) {
        case undefined:
        case null:
        case "cnevideos":
          var url = asset.scriptEmbedUrl.startsWith("//") ? new URL(window.location.protocol + asset.scriptEmbedUrl) : new URL(asset.scriptEmbedUrl);
          return {
            embedType: "cne-embed",
            uri: url.pathname.substring(1)
          };
        case "clips":
          return {
            embedType: "clip",
            copilotid: asset.id
          };
        case "giphy":
          return {
            embedType: "giphy-embed",
            url: asset.embed_url
          };
        case "photos":
          return {
            embedType: "photo",
            copilotid: asset.id
          };
        case "products":
          return {
            embedType: "product",
            copilotid: asset.id
          };
        case "galleries":
          return {
            embedType: "gallery",
            copilotid: asset.id
          };
        case "contentreferences":
          {
            return {
              embedType: "contentreference",
              referenceType: "product",
              copilotid: asset.id,
              uri: "/" + collection + "/" + asset.id
            };
          }
        default:
          return {
            assetType: asset.type,
            copilotid: asset.id,
            copilottype: asset.type,
            embedType: "copilot-embed",
            uri: "/" + collection + "/" + asset.id
          };
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var shouldUpdateEditorData = this.editor && this.currentModelId !== this.modelId;
      if (shouldUpdateEditorData) {
        this.editor.setData(this.value);
        this.set("currentModelId", this.modelId);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._initEditor();

      if (Ember.isPresent(this.setEditorType)) {
        this.setEditorType("ckeditor");
      }

      this.set("currentModelId", this.modelId);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var editor = this.get("editor");
      if (editor) {
        editor.destroy();
      }
      if (Ember.isPresent(this.setEditorType)) {
        this.setEditorType("mdeditor");
      }
      if (this.shouldUpdateOnSave) {
        this.onChange(this.editor.getData(), this.currentModelId);
        this.setEditorForBody(null);
      }
    },


    actions: {
      smartFill: function smartFill() {
        var _this3 = this;

        Ember.run.next(function () {
          if (_this3.isDestroyed) {
            return;
          }
          var atjsonDoc = _this3._generateAtjsonDoc(_this3.smartSuggestion);
          _this3.editor.setData(_atjsonRendererCkeditor.default.render(atjsonDoc));
        });
      },
      generateLinks: function generateLinks() {
        var command = this.editor.commands.get("autogenerateLinks");
        if (command) {
          command.execute();
          this.analytics.track("linkAutogen", "clicked");
        } else {
          this.consoleLogger.warn("Link Autogen is not configured");
        }
      }
    }
  });
});