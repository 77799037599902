define("copilot/components/editor-toggle/component", ["exports", "@atjson/document", "@condenast/atjson-source-copilot-markdown", "@condenast/atjson-source-autopilot", "@condenast/atjson-source-ckeditor-html", "@condenast/atjson-source-verso", "@condenast/atjson-renderer-copilot-markdown", "@condenast/atjson-renderer-autopilot", "@condenast/atjson-renderer-ckeditor", "@sentry/browser"], function (exports, _document, _atjsonSourceCopilotMarkdown, _atjsonSourceAutopilot, _atjsonSourceCkeditorHtml, _atjsonSourceVerso, _atjsonRendererCopilotMarkdown, _atjsonRendererAutopilot, _atjsonRendererCkeditor, _browser) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CKEDITOR = "ckeditor";
  var MDEDITOR = "mdeditor";

  /**
   * This component wraps the logic for toggling between rich text and markdown editing.
   * It doesn't have any presentation logic; instead it yields `| currentEditor setEditor htmlValue updateMarkdownFromHTML |`. For details see the `currentEditor` and `htmlValue` computed properties below, as well as the `setEditor` and `updateMarkdownFromHTML` actions
   *
   * @module copilot/app/components/editor-toggle/component
   * @augments Ember.Component
   * @param {Boolean} disabled - only allow toggling out of mdeditor when false or undefined
   * @param {String} value - the current value of the controlled fields, represented in markdown
   * @param {Function} getStartingEditor - a function to fetch a stored editor state, or undefined/null if no state has been stored
   * @param {Function} setStartingEditor - a function to save the current editor state, called with the new state when toggling between states.
   * @param {Boolean} defaultToRichText - whether the toggle should default to rich text when the result of `getStartingEditor` is undefined
   */

  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    logger: Ember.inject.service(),
    analytics: Ember.inject.service(),
    tagName: "",

    currentEditor: MDEDITOR,

    initialEditor: function initialEditor() {
      var currentEditor = MDEDITOR;
      if (this.canEditInCKEditor) {
        var preferredEditor = this.getStartingEditor();
        var defaultEditor = this.defaultToRichText ? CKEDITOR : MDEDITOR;
        currentEditor = preferredEditor || defaultEditor;
      }

      return this.canToggleToMarkdown ? currentEditor : CKEDITOR;
    },
    generateAtjsonDoc: function generateAtjsonDoc(value) {
      return this.features.get("verso") ? _atjsonSourceCopilotMarkdown.default.fromRaw(value || "").convertTo(_atjsonSourceVerso.default) : _atjsonSourceAutopilot.default.fromRaw(value || "", {
        brand: this.brand,
        fieldName: this.field
      }).convertTo(_atjsonSourceVerso.default);
    },


    ckeditorValue: Ember.computed("currentEditor", "currentModelId", function () {
      if (this.currentEditor === MDEDITOR || !this.canEditInCKEditor) {
        return null;
      }

      var atjsonDoc = this.generateAtjsonDoc(this.value);
      var ckeditorValue = void 0;

      try {
        ckeditorValue = _atjsonRendererCkeditor.default.render(atjsonDoc);
      } catch (e) {
        atjsonDoc.where(function (a) {
          return (0, _document.is)(a, _document.UnknownAnnotation);
        }).remove();
        ckeditorValue = _atjsonRendererCkeditor.default.render(atjsonDoc);
      }

      return ckeditorValue;
    }),

    canEditInCKEditor: Ember.computed("currentEditor", "currentModelId", function () {
      if (this.disabled) {
        return false;
      }
      if (!this.canToggleToMarkdown) {
        return true;
      }

      var atjsonDoc = this.generateAtjsonDoc(this.value);
      var ckeditorValue = void 0;

      try {
        ckeditorValue = _atjsonRendererCkeditor.default.render(atjsonDoc);
      } catch (e) {
        this.logger.error(e.message, e);

        _browser.withScope(function (scope) {
          scope.setExtra("unknown annotations", atjsonDoc.where(function (a) {
            return (0, _document.is)(a, _document.UnknownAnnotation);
          }).toJSON());
          _browser.captureException(e);
        });

        return false;
      }

      var ckeditorDoc = _atjsonSourceCkeditorHtml.default.fromRaw(ckeditorValue).convertTo(_atjsonSourceVerso.default);

      if (!this.features.get("verso")) {
        ckeditorDoc.setMeta({ brand: this.brand, fieldName: this.field });
      }

      if (ckeditorDoc.equals(atjsonDoc)) {
        return true;
      }

      return false;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.currentModelId || this.currentModelId !== this.modelId) {
        this.set("currentEditor", this.initialEditor());
        if (this.updateTrackingData) {
          this.updateTrackingData(this.currentEditor, this.canEditInCKEditor);
        }
        this.set("currentModelId", this.modelId);
        this.doUpdateTrackingData();
      }
    },


    brand: Ember.computed.alias("brandConfig.activeBrand"),

    doUpdateTrackingData: Ember.observer("currentEditor", function () {
      if (this.updateTrackingData) {
        this.updateTrackingData(this.currentEditor, this.canEditInCKEditor);
      }
    }),

    actions: {
      updateMarkdownFromHTML: function updateMarkdownFromHTML(value, modelId) {
        var Renderer = this.features.get("verso") ? _atjsonRendererCopilotMarkdown.default : _atjsonRendererAutopilot.default;

        var versoDoc = _atjsonSourceCkeditorHtml.default.fromRaw(value).convertTo(_atjsonSourceVerso.default).setMeta({ brand: this.brand, fieldName: this.field });
        var markdown = void 0;

        try {
          markdown = Renderer.render(versoDoc).trim();
        } catch (e) {
          this.logger.error(e.message, e);
          _browser.withScope(function (scope) {
            scope.setExtra("unknown annotations", versoDoc.where(function (a) {
              return (0, _document.is)(a, _document.UnknownAnnotation);
            }).toJSON());
            _browser.captureException(e);
          });

          throw e;
        }

        this.inputChange(markdown, modelId);
      },
      updateTrackingData: function updateTrackingData() {
        this.doUpdateTrackingData();
      },
      setEditor: function setEditor(targetEditor, next) {
        if (this.disabled) {
          return next && next();
        }

        if (this.currentEditor === CKEDITOR && targetEditor === MDEDITOR) {
          this.set("currentEditor", MDEDITOR);
        } else if (this.currentEditor === MDEDITOR && targetEditor === CKEDITOR) {
          this.set("currentEditor", CKEDITOR);
        } else {
          // log something? message the user somehow?
        }

        this.analytics.track("editor", "toggled", Object.assign({}, this.trackingData || {}, {
          current: this.currentEditor,
          default: this.defaultToRichText ? CKEDITOR : MDEDITOR,
          canEditInCKEditor: this.canEditInCKEditor
        }));

        this.setStartingEditor(this.currentEditor);

        return next && next();
      }
    }
  });
});