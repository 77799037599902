define("copilot/brands/vogue/routes/fashiongalleries/index", ["exports", "copilot/routes/galleries/index"], function (exports, _index) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    model: function model(params) {
      var _this = this;

      return this.store.find("fashiongallery", params.fashiongallery_id).then(function (fashiongallery) {
        _this.set("_fullGalleryData", Object.assign({}, fashiongallery._data));
        return fashiongallery;
      }).then(this._hydrateFashionshow.bind(this));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      // Get the fullFashionshow for publish and
      this.store.find("fashionshow", model.get("fashionshow.id")).then(function (fashionshow) {
        controller.set("model.fullFashionshow", fashionshow);
      });
    },
    _hydrateFashionshow: function _hydrateFashionshow(fashiongallery) {
      var fullGalleryData = this._fullGalleryData;
      var store = this.store;
      var fashionshow = fashiongallery.get("backReferences").findBy("type", "fashionshow");
      return store.find("fashionshow", fashionshow.get("id")).then(function (fashionshow) {
        fashiongallery.set("fashionshow", fashionshow);
        // Reload the gallery data into the model because it has more deeply
        // hydrated payload than the fashionshow payload had for the gallery,
        // which would have blown away some of the relationships we want to keep
        // reference to, due to the identity map
        fashiongallery.load(fullGalleryData.id, fullGalleryData);
        return fashiongallery;
      });
    },


    actions: {
      transitionToOtherGallery: function transitionToOtherGallery(galleryId) {
        this.transitionTo("fashiongalleries.index.edit", galleryId);
      }
    }
  });
});