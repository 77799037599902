define("copilot/components/publish-data/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var SLUG_PATTERN = ":slug";

  exports.default = Ember.Component.extend({
    classNames: ["publish-data"],
    editPublishUrl: false,

    type: Ember.computed("mainModel", function () {
      if (this.get("mainModel.isNative")) {
        return "native";
      }

      return this.get("mainModel.meta.collectionName");
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.currentOrQueuedPublishRecord) {
        var _currentOrQueuedPubli = this.currentOrQueuedPublishRecord,
            searchable = _currentOrQueuedPubli.searchable,
            template = _currentOrQueuedPubli.template;


        if (this.publishData.searchable === undefined) {
          this.onchange("metadata.publishData.searchable", searchable);
        }
        if (this.publishData.recommendable === undefined) {
          this.onchange("metadata.publishData.recommendable", true);
        }
        if (!this.publishData.template && template) {
          this.onchange("metadata.publishData.template", template);
        }
      }

      if (!this.currentOrQueuedPublishRecord && this.publishData.searchable === undefined) {
        this.onchange("metadata.publishData.searchable", true);
      }
      if (!this.currentOrQueuedPublishRecord && !this.publishData.template && this.defaultTemplate) {
        this.onchange("metadata.publishData.template", this.defaultTemplate);
      }
      if (!this.currentOrQueuedPublishRecord && this.publishData.recommendable === undefined) {
        this.onchange("metadata.publishData.recommendable", true);
      }
    },


    scratchSlug: Ember.computed("slug", {
      get: function get() {
        return this.slug;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    publishMessage: Ember.computed("configService.activeBrand", function () {
      // handles messaging based on verso status
      var configService = this.get("configService");
      var is100PercentVersoBrand = ["all", "glm", "gq", "self", "tnv", "them", "vf", "vogue"];
      var isPartialVersoBrand = ["ad", "bon", "cnt", "epi", "p4k", "tny", "wrd"];

      switch (true) {
        case is100PercentVersoBrand.includes(configService.activeBrand):
          {
            return ".versoBrand";
          }
        case isPartialVersoBrand.includes(configService.activeBrand):
          {
            return ".partialVersoBrand";
          }
        default:
          {
            return "";
          }
      }
    }),

    isContentPublished: Ember.computed.notEmpty("currentOrQueuedPublishRecord"),

    publishUriValidationMessages: Ember.computed("validationMessages", function () {
      return this.validationMessages.filter(function (message) {
        return message[0] === "metadata.publishData.uri";
      }).map(function (message) {
        return message[1];
      });
    }),

    filteredUriValidationMessages: Ember.computed("publishUriValidationMessages", function () {
      return this.publishUriValidationMessages.filter(function (message) {
        return message.name !== "notUnique";
      });
    }),

    publishUriPattern: Ember.computed("publishEnv", "publishPathPattern", function () {
      return "https://" + this.publishEnv + "/" + this.publishPathPattern;
    }),

    hasNoSlug: Ember.computed("publishUriPattern", function () {
      return this.publishPathPattern.indexOf(SLUG_PATTERN) === -1;
    }),

    isUrlLocked: Ember.computed("isContentPublished", "editPublishUrl", function () {
      return this.isContentPublished && !this.editPublishUrl;
    }),

    nonEditableUriPart: Ember.computed("publishUriPattern", function () {
      return this.publishUriPattern.replace(SLUG_PATTERN, "");
    }),

    publishUri: Ember.computed("publishUriPattern", "slug", function () {
      return this.publishUriPattern.replace(SLUG_PATTERN, this.slug);
    }),

    shouldDisplaySlug: Ember.computed("mainModel", function () {
      var copilotCategories = this.get("mainModel.categories.copilot") || [];

      var _copilotCategories$fi = copilotCategories.filter(function (category) {
        return category.slug === "preset";
      }),
          _copilotCategories$fi2 = _slicedToArray(_copilotCategories$fi, 1),
          presetCategory = _copilotCategories$fi2[0];

      return !presetCategory;
    }),

    isNotContributorPage: Ember.computed("mainModel.meta", function () {
      var metadataType = this.get("mainModel.meta.modelName");
      return metadataType !== "contributor";
    }),

    shouldDisplayGlobalDrafts: Ember.computed("mainModel.meta", function () {
      var metadataType = this.get("mainModel.meta.modelName");
      return metadataType === "article" || metadataType === "gallery";
    }),

    isNative: Ember.computed("mainModel.meta", function () {
      var type = this.get("mainModel.type");
      var flag = ["nativearticle", "nativegallery", "nativerecipe"].includes(type);
      return this.get("mainModel.isNative") || flag;
    }),

    isGlobalDraftsDisabled: Ember.computed("mainModel._embedded", function () {
      var isLivePublished = this.get("mainModel._embedded.publishHistory");
      return !!isLivePublished;
    }),

    isContentSyndicated: Ember.computed("mainModel.{syndication}", function () {
      var sourceId = this.get("mainModel.syndication.sourceId");
      return !!sourceId;
    }),

    redirectToGlobalDrafts: Ember.computed("configService.config", function () {
      var brand = this.get("configService.activeBrand");

      var _get = this.get("configService.config"),
          encoreUrl = _get.encoreUrl;

      return encoreUrl + "/globalDrafts?brands=" + brand;
    }),

    actions: {
      updateSlug: function updateSlug() {
        this.set("slug", this.scratchSlug);
      },
      toggleIsGlobalDrafts: function toggleIsGlobalDrafts(value) {
        this.mainModel.set("isGlobalDraft", value);
      }
    }
  });
});