define("copilot/services/upload-asset", ["exports", "ember-concurrency", "ember-inflector"], function (exports, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var MEDIA_MAP = {
    clip: ["avi", "mov", "quicktime", "flv", "mpg", "mp4", "webm", "wmv"],
    photo: ["jpeg", "jpg", "png", "gif"]
  };

  function detectModelName(file) {
    var keys = Object.keys(MEDIA_MAP);
    var type = Ember.get(file, "type").split("/").pop();

    return keys.find(function (key) {
      return MEDIA_MAP[key].indexOf(type) !== -1;
    }) || "photo";
  }

  /**
    Uploads an asset to the API, creating a new photo or clip.
    To use this service, inject it using the `method` helper,
    allowing it to be invoked like a function:
  
    ```js
    import method from 'ember-service-methods/inject';
  
    export default Ember.Route.extend({
      uploadAsset: method(),
  
      actions: {
        upload(model, field, file) {
          return this.uploadAsset(file).then((asset) => {
            model.set(field, asset);
          });
        }
      }
    });
    ```
  
    @function
    @param {File} file The file to upload.
    @param {Object} [attributes] Additional attributes to provide on the asset.
   */
  exports.default = Ember.Service.extend({
    analytics: Ember.inject.service(),

    eventBus: Ember.inject.service(),

    user: Ember.inject.service(),

    store: Ember.inject.service(),

    intl: Ember.inject.service(),

    validation: Ember.inject.service(),

    upload: (0, _emberConcurrency.task)(function* (file, model) {
      try {
        this.eventBus.trigger("progress");

        var response = yield file.upload("/api/" + (0, _emberInflector.pluralize)(model.type), {
          contentType: null,
          data: model.getProperties("filename", "revisionAuthor")
        });

        this.eventBus.trigger("success", {
          message: "File uploaded",
          priority: 3,
          status: "success"
        });
        return response;
      } catch (e) {
        this.eventBus.trigger("error", {
          message: "File upload failed",
          priority: 1,
          status: "error"
        });
        throw e;
      }
    }).enqueue().maxConcurrency(3),

    execute: function execute(file) {
      var _this = this;

      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var modelName = arguments[2];

      modelName = modelName || detectModelName(file);
      var model = this.store.createRecord(modelName, Object.assign({
        revisionAuthor: Ember.get(this, "user.user.fullName"),
        filename: Ember.get(file, "name"),
        title: Ember.get(file, "name"),
        format: Ember.get(file, "type"),
        size: Ember.get(file, "size"),
        isNew: true
      }, attributes));

      if (model.hasOwnProperty("aspectRatios")) {
        model.get("aspectRatios").create({
          key: "master",
          url: URL.createObjectURL(file.blob)
        });
      }

      var uploadPromise = new Ember.RSVP.Promise(function (resolve, reject) {
        var validationService = _this.validation;

        validationService.validate(model, "upload").then(function (isValid) {
          if (isValid) {
            var startTime = new Date().getTime();
            var response = _this.upload.perform(file, model).then(function (response) {
              var timeTookToUpload = new Date().getTime() - startTime;
              var data = response.body;
              var aspectRatios = model.get("aspectRatios");

              // Only process aspectRatios for photos, not clips
              if (aspectRatios.clear) {
                var keys = Object.keys(data.aspectRatios);

                // need to do this as ember-model does not reload hasMany relationships
                // see this: https://cnissues.atlassian.net/browse/COPILOT-3470
                aspectRatios.clear();
                keys.forEach(function (key) {
                  aspectRatios.create(Object.assign({ key: key }, data.aspectRatios[key]));
                });
              }
              model.load(data.id, data);

              _this.analytics.track("content", "uploaded", {
                contentType: modelName,
                mimeType: Ember.get(file, "type"),
                fileSizeInBytes: Ember.get(file, "size"),
                uploadDurationInMilliseconds: timeTookToUpload,
                method: Ember.get(file, "source"),
                uploadLocation: Ember.get(file, "uploadLocation")
              });

              return resolve(model);
            }).catch(function (response) {
              if (file.get("queue")) {
                file.get("queue").remove(file);
              }
              var timeTookToUpload = new Date().getTime() - startTime;
              var context = Ember.get(response, "body.details.0.context");
              var key = Ember.get(context || {}, "key");
              var error = void 0;
              if (key && model.get("type")) {
                error = {
                  code: model.get("type") + "-" + Ember.get(context || {}, "key") + "-error",
                  context: context
                };
              } else {
                error = {
                  code: "server-error",
                  context: {
                    error: response.statusText,
                    message: response.body.message || response.body.statusText,
                    status: response.body.statusCode || response.status
                  }
                };
              }
              error.message = _this.intl.t("validationMessage." + error.code, error.context);
              Ember.get(model, "validationErrors").add("", error.code, error.context);
              _this.analytics.track("error", "uploading", {
                contentType: modelName,
                mimeType: Ember.get(file, "type"),
                fileSizeInBytes: Ember.get(file, "size"),
                uploadDurationInMilliseconds: timeTookToUpload,
                method: Ember.get(file, "source"),
                uploadLocation: Ember.get(file, "uploadLocation"),
                errorMessage: "Asset failed to upload due to " + error.code
              });

              // Validation format is in a [path, message] format
              // Put error in same format as `app/models/validation-error`
              var validationError = {
                name: error.code,
                messageFormat: context
              };
              reject([[null, validationError]]);
            });
            return response;
          } else {
            var errors = Ember.get(model, "validationErrors.allErrors");
            var analyticsErrors = errors.map(function (errorPair) {
              var _errorPair = _slicedToArray(errorPair, 2),
                  attribute = _errorPair[0],
                  error = _errorPair[1];

              var key = "validationMessage." + error.name;
              return attribute + ": " + _this.intl.t(key, error.messageFormat || {});
            }).join("");
            _this.analytics.track("error", "uploading", {
              contentType: modelName,
              mimeType: Ember.get(file, "type"),
              fileSizeInBytes: Ember.get(file, "size"),
              uploadDurationInMilliseconds: 0,
              method: Ember.get(file, "source"),
              errorMessage: analyticsErrors
            });
            return reject(errors);
          }
        });
      });
      uploadPromise.model = model;
      return uploadPromise;
    }
  });
});