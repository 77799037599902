define("copilot/services/content-selector-product-publish", ["exports", "ember-retry/retry", "lodash", "copilot/lib/csv-upload", "ember-file-upload/file"], function (exports, _retry, _lodash, _csvUpload, _file) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var MAX_RETRY_ASSET_SEARCH = 10;
  var TIMEOUT_ASSET_SEARCH = 2000;

  exports.default = Ember.Service.extend({
    configService: Ember.inject.service("config"),
    uploadAsset: Ember.inject.service("uploadAsset"),
    init: function init() {
      var owner = Ember.getOwner(this);
      this._super.apply(this, arguments);
      this.set("csvUploader", _csvUpload.default.create({ owner: owner, type: "product" }));
    },
    publish: function () {
      var _ref = _asyncToGenerator(function* (products) {
        try {
          var productData = yield this.productDetails(products);
          var strippedJson = this.csvUploader.stripWhiteSpace(productData);
          var hydratedModels = yield this.csvUploader._hydrate(strippedJson);
          var modelsToValidate = yield this.csvUploader._createModels(hydratedModels, strippedJson);
          var validationResults = yield this.csvUploader._validateModels(modelsToValidate);
          var allModelsValid = validationResults.every(function (result) {
            return result;
          });

          if (allModelsValid) {
            this.csvUploader.set("validModelsToSave", modelsToValidate);
            return this.csvUploader.save();
          }
        } catch (err) {
          return err;
        }
      });

      function publish(_x) {
        return _ref.apply(this, arguments);
      }

      return publish;
    }(),
    productDetails: function () {
      var _ref2 = _asyncToGenerator(function* (products) {
        var _this = this;

        return new Ember.RSVP.Promise(function () {
          var _ref3 = _asyncToGenerator(function* (resolve, reject) {
            var productDatas = yield Ember.RSVP.Promise.all(products.map(function (product) {
              return _this.getProductData(product);
            }));

            var ids = productDatas.map(function (productData) {
              return productData["Promo Image"].split("/").pop();
            }).filter(Boolean);
            if (!ids.length) {
              return resolve(productDatas);
            }

            return (0, _retry.default)(_this.csvUploader.searchPhoto({ ids: ids }), MAX_RETRY_ASSET_SEARCH, TIMEOUT_ASSET_SEARCH).then(function () {
              return resolve(productDatas);
            }).catch(function (err) {
              Ember.set(_this, "errors", err);
              reject(err);
            });
          });

          return function (_x3, _x4) {
            return _ref3.apply(this, arguments);
          };
        }());
      });

      function productDetails(_x2) {
        return _ref2.apply(this, arguments);
      }

      return productDetails;
    }(),
    uploadImage: function uploadImage(url) {
      var _this2 = this;

      var fileName = /[^/]*$/.exec(url)[0];
      if (url.includes("getproductimage")) {
        var imageUrlPartials = url.split("/image/obj/");
        fileName = imageUrlPartials.pop().split(";").shift() || fileName;
      }
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";
        var that = _this2;
        xhr.onload = function (err) {
          if (this.status === 200) {
            var arrayBuffer = this.response;
            if (arrayBuffer) {
              var byteArray = new Uint8Array(arrayBuffer);
              var file = new File([byteArray], fileName, { type: "image/jpeg" });
              // Service to upload asset
              that.uploadAsset.execute(_file.default.fromBlob(file, fileName)).then(function (asset) {
                var configService = that.configService;
                resolve(configService.config.copilotUrl + "/" + configService.activeBrand + "/photos/" + asset.id);
              });
            }
          } else {
            Ember.set(this, "errors", err);
            reject(err);
          }
        };
        xhr.onerror = function () {
          resolve("");
        };
        xhr.send();
      });
    },
    getProductData: function () {
      var _ref4 = _asyncToGenerator(function* (product) {
        var imageUrl = yield this.uploadImage(product._source.imageUrl);
        return {
          Name: product._source.name,
          Description: Array.isArray(product._source.description) ? product._source.description.join(" ") : product._source.description,
          "Retailer Name": (0, _lodash.get)(product, "_source.offers[0].seller.name", ""),
          "Offer URL": (0, _lodash.get)(product, "_source.offers[0].purchaseUri", ""),
          Price: (0, _lodash.get)(product, "_source.offers[0].price", "").toString(),
          "Sale Price": (0, _lodash.get)(product, "_source.offers[0].comparisonPrice", "").toString(),
          "Promo Image": imageUrl,
          Publish: "true"
          // isOutOfStock: similarProduct.isOutOfStock.toString() // TODO: Enabled isOutOfStock in CSV MAP
        };
      });

      function getProductData(_x5) {
        return _ref4.apply(this, arguments);
      }

      return getProductData;
    }()
  });
});