define("copilot/schema-helpers/autogen-copyright-display", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    intl: Ember.inject.service(),
    dependentKeys: Object.freeze(["copyright", "publisher"]),

    // Runs in context of model
    compute: function compute(model) {
      var copyright = model.copyright,
          publisher = model.publisher;

      if (copyright && publisher) {
        return this.intl.t("copyright", {
          year: _copilotMarkdown.default.sanitize(copyright),
          owner: _copilotMarkdown.default.sanitize(publisher)
        }).string;
      }
    }
  });
});