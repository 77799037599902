define("copilot/components/geo-field/input/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    didRender: function didRender() {
      var lat = this.lat,
          lng = this.lng;

      this._setDisplay(lat, lng);
    },
    _setDisplay: function _setDisplay(lat, lng) {
      var input = this.element.querySelector("input");
      var coordinates = typeof lat === "number" && typeof lng === "number" ? lat + ", " + lng : "";

      if (input.value === coordinates) {
        return;
      }
      input.value = coordinates;
    },
    _parseValue: function _parseValue(displayValue) {
      var _displayValue$split$m = displayValue.split(",").map(function (coord) {
        var number = parseFloat(coord);
        return isNaN(number) ? null : number;
      }).concat([null]),
          _displayValue$split$m2 = _slicedToArray(_displayValue$split$m, 2),
          lat = _displayValue$split$m2[0],
          lng = _displayValue$split$m2[1];

      return { lat: lat, lng: lng };
    },


    errorMessages: Ember.computed("validationMessages.[]", function () {
      if (this.get("validationMessages.length")) {
        return [{ name: "geo" }];
      } else {
        return [];
      }
    }),

    actions: {
      onblur: function onblur(evt) {
        var _parseValue2 = this._parseValue(evt.target.value),
            lat = _parseValue2.lat,
            lng = _parseValue2.lng;

        this._setDisplay(lat, lng);
        this.onchange({ lat: lat, lng: lng });
      }
    }
  });
});