define("copilot/brands/cnt/serializers/specialreport", ["exports", "copilot/serializers/list"], function (exports, _list) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _list.default;
    }
  });
});