define("copilot/routes/authenticated-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    userService: Ember.inject.service("user"),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var userService = this.userService;
      if (!userService.get("isAuthenticated")) {
        transition.abort();
        window.location = "/auth/login";
      }

      var firstPageLoad = transition.router.currentSequence === 1;
      performance.clearMarks("route-change");
      performance.mark("route-change", {
        detail: {
          pathname: location.pathname,
          firstPageLoad: firstPageLoad
        }
      });
    }
  });
});