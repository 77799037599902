define("copilot/brands/p4k/models/musicrelease", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;


  var MusicRelease = _base.default.extend({
    type: "musicrelease",

    name: (0, _emberModel.attr)(str),
    disambiguatingDescription: (0, _emberModel.attr)(str),
    isReleaseOf: (0, _emberModel.hasMany)("musicalbum", {
      embedded: true
    }),
    releaseDate: (0, _emberModel.attr)(),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    publisher: (0, _emberModel.hasMany)("brand", {
      embedded: true
    }),
    thirdPartyMedia: (0, _emberModel.hasMany)("third-party-media", {
      embedded: true
    })
  });

  MusicRelease.reopenClass({
    url: "/api/musicreleases"
  });

  exports.default = MusicRelease;
});