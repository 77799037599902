define("copilot/routes/application", ["exports", "copilot/lib/cookie", "ember-service-methods/inject", "ember-inflector", "ic-ajax", "copilot/mixins/memory-scroll", "copilot/config/environment", "copilot/lib/csv-upload", "@sentry/browser", "@condenast/copilot-slug"], function (exports, _cookie, _inject, _emberInflector, _icAjax, _memoryScroll, _environment, _csvUpload, _browser, _copilotSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var RESET_PATTERNS = ["form:", "template:"];

  var QUICK_SEARCH_FIELDS = ["hed", "title", "name"];
  var QUICK_SEARCH_PARAMS = {
    limit: 4,
    d_o: "and",
    nottypes: "externallink,photo,cnevideo,event,pushnotification,categories",
    sort: "score asc",
    customRank: QUICK_SEARCH_FIELDS.map(function (field) {
      return field + "^5.0";
    }).join(",")
  };

  function collectCategoriesByRoots(categories) {
    var roots = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = categories[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var cat = _step.value;

        if (roots[cat.rootSlug]) {
          roots[cat.rootSlug].push(cat);
        } else {
          roots[cat.rootSlug] = [cat];
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return roots;
  }

  function flattenCategories(roots) {
    var categories = [];
    for (var root in roots) {
      categories.push.apply(categories, _toConsumableArray(roots[root]));
    }

    return categories;
  }

  function flattenToDotKeys(obj) {
    return Object.entries(obj).reduce(function (accum, item) {
      var key = item[0];
      var value = item[1];
      if ((typeof value === "undefined" ? "undefined" : _typeof(value)) === "object") {
        value = flattenToDotKeys(value);
        Object.keys(value).forEach(function (suffix) {
          accum[key + "." + suffix] = value[suffix];
        });
      } else {
        accum[key] = value;
      }
      return accum;
    }, {});
  }

  // if the locale is specified with a region, return the more generic base language.
  function baseLanguage(locale) {
    if (locale.indexOf("-") > 0) {
      return locale.split("-")[0];
    } else {
      return null;
    }
  }

  // Initialize once language setting for all select2 instances.
  function setSelect2Locale(locale) {
    var locales = Ember.$.fn.select2.locales;
    var select2Default = Ember.$.fn.select2.defaults;
    var baseLang = null;
    if (locale in locales) {
      Object.assign(select2Default, locales[locale]);
    } else if (baseLang = baseLanguage(locale)) {
      Object.assign(select2Default, locales[baseLang]);
    }
  }

  // Moment.js has its own set of supported locales
  function remapMomentLocale(locale) {
    var _split = (locale || "").split("-"),
        _split2 = _slicedToArray(_split, 1),
        lang = _split2[0];

    switch (lang) {
      case "de":
        return "de";
      case "en":
        // en-US -> en
        // en*   -> en-gb
        if (locale.toLowerCase() === "en-us") {
          return "en";
        }
        return "en-gb";
      case "es":
        return "es";
      case "fr":
        return "fr";
      case "it":
        return "it";
      case "ru":
        return "ru";
      case "zh":
        // zh-Hant -> zh-tw
        // zh*     -> zh-cn
        if (["zh-hant", "zh-hant-tw"].includes(locale.toLowerCase())) {
          return "zh-tw";
        }
        return "zh-cn";
      case "ja":
        return "ja";
    }
    return locale;
  }

  exports.default = Ember.Route.extend(_memoryScroll.default, {
    analytics: Ember.inject.service(),
    intl: Ember.inject.service(),
    localeMapper: Ember.inject.service(),
    moment: Ember.inject.service(),
    categoryService: Ember.inject.service("category"),
    config: Ember.inject.service(),
    features: Ember.inject.service(),
    brand: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    consoleLogger: Ember.inject.service(),
    router: Ember.inject.service(),
    search: Ember.inject.service(),
    store: Ember.inject.service(),
    activeBrand: Ember.computed.alias("brand.activeBrand"),
    offerProducts: Ember.inject.service(),

    uploadAsset: (0, _inject.default)(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      return this.userService.load().then(function (user) {
        _cookie.default.set({
          "cp.username": user.uid,
          "cp.role": user.role,
          "cp.userid": user._id
        });
        _browser.configureScope(function (scope) {
          scope.setUser({
            id: user._id,
            username: user.uid,
            email: user.email,
            name: user.fullName
          });
          scope.setTag("role", user.role);
          scope.setTag("page_locale", _this.locale);
          scope.setTag("brand", _this.userService.activeBrand);
          var branches = document.querySelector('meta[name="copilot-labels"]');
          if (branches) {
            var parsed = JSON.parse(decodeURIComponent(branches.getAttribute("content")));
            var branch = parsed.current;
            scope.setTag("branch", branch);
          }
        });
        return user;
      }).then(function () {
        return _this._setLocale(_this.locale);
      }).then(function () {
        return _this._ensureBrand(transition);
      }).then(function () {
        return _this._ajaxSetup();
      }).then(function () {
        return _this._setupBrandConfig();
      }).then(function () {
        return _this._setupAnalytics();
      }).then(function () {
        return _this._addBrandTranslations();
      }).catch(function (error) {
        if (error.errorThrown === "Unauthorized") {
          if (!Ember.testing) {
            window.location = "/auth/login";
          }
        } else {
          var errorMessage = (typeof error === "undefined" ? "undefined" : _typeof(error)) === "object" ? JSON.stringify(error) : error;

          var err = new Error("Failed to load user instance (" + errorMessage + ")");
          Ember.onerror(err);
        }
      });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      this._removePreloader();
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (!window.dataLayer) {
        window.dataLayer = [{
          event: "data-layer-loaded",
          content: {
            pageType: null
          }
        }];
      }
    },
    _ajaxSetup: function _ajaxSetup() {
      this.eventBus.ajaxSetup();

      return Ember.RSVP.Promise.resolve();
    },


    locale: Ember.computed(function () {
      // fallback to default language if "userService.user.locale" is empty.
      var testingLocale = Ember.testing ? "en-GB" : null;
      return window.localStorage.getItem("locale") || this.userService.user.locale || testingLocale || _environment.default.i18n.defaultLocale;
    }),

    _ensureBrand: function _ensureBrand(transition) {
      var brands = Ember.get(this, "userService.user.brands");
      var brand = this.activeBrand;
      var brandFromCookie = _cookie.default.get("brand");
      var brandToSwitchTo = void 0;
      if (!brand && brandFromCookie && brands.includes(brandFromCookie)) {
        brandToSwitchTo = brandFromCookie;
      } else if (!brand && Ember.get(this, "userService.isSuperAdmin") && Ember.get(this, "userService.user.defaultBrand")) {
        brandToSwitchTo = Ember.get(this, "userService.user.defaultBrand");
      } else if (!brand || brands && brand && !brands.includes(brand)) {
        brandToSwitchTo = brands[0];
      }
      if (brandToSwitchTo) {
        transition.abort();
        this.set("activeBrand", brandToSwitchTo);
        return this.transitionTo("/" + brandToSwitchTo);
      }
      return Ember.RSVP.Promise.resolve();
    },
    _setupBrandConfig: function _setupBrandConfig() {
      var _this2 = this;

      return this.configService.setup().then(function () {
        return _this2.categoryService.setup();
      });
    },
    _addBrandTranslations: function _addBrandTranslations() {
      var locale = this.get("intl.locale")[0];
      var owner = Ember.getOwner(this);
      var brandTranslations = owner.lookup("locale:" + locale + "/brand-translations") || owner.lookup("locale:en/brand-translations");
      if (brandTranslations) {
        var newTranslations = flattenToDotKeys(brandTranslations);
        this.intl.addTranslations(locale, newTranslations);
      }

      return Ember.RSVP.Promise.resolve();
    },
    _setLocale: function _setLocale(locale) {
      if (locale) {
        this.set("intl.locale", [locale, "en-GB"]);
        // Moment.js uses different locale codes
        this.moment.setLocale(remapMomentLocale(locale));
        setSelect2Locale(locale);
      }
    },
    _removePreloader: function _removePreloader() {
      Ember.$("[data-preloader]").remove();
    },
    _resetContainerAndRegistry: function _resetContainerAndRegistry() {
      var owner = Ember.getOwner(this);
      RESET_PATTERNS.forEach(function (pattern) {
        var matchExpression = new RegExp("^" + pattern);
        var container = owner.__container__;
        var cachedKeys = Object.keys(container.cache);
        var cachedFactoryKeys = Object.keys(container.factoryManagerCache);
        var allKeys = cachedKeys.concat(cachedFactoryKeys).uniq();
        var itemsToReset = allKeys.filter(function (k) {
          return k.match(matchExpression);
        });
        itemsToReset.forEach(function (item) {
          container.reset(item);
          delete owner.__registry__._resolveCache[item];
          delete owner.__registry__.fallback._resolveCache[item];
        });
      });
    },
    _setupAnalytics: function _setupAnalytics() {
      this.analytics.setup();
      this.analytics.identify(this.userService.user);
    },
    getCategorySerializer: function getCategorySerializer(dataSerializer) {
      var serializerName = dataSerializer || "passthrough";
      return Ember.getOwner(this).lookup("serializer:categories/" + serializerName);
    },
    hasRoute: function hasRoute(routeName) {
      return !!Ember.getOwner(this).lookup("route:" + routeName);
    },
    validateRecentSearch: function validateRecentSearch(recentSearch) {
      if ((typeof recentSearch === "undefined" ? "undefined" : _typeof(recentSearch)) !== "object") {
        return false;
      }

      if (recentSearch.query) {
        return true;
      }

      if (recentSearch.id && recentSearch.title && recentSearch.type) {
        return true;
      }

      return false;
    },


    setRecentSearch: function setRecentSearch(recentSearchToAdd) {
      if (!this.validateRecentSearch(recentSearchToAdd)) {
        return;
      }
      var recentSearches = this.getRecentSearch();
      var mergedRecentSearches = recentSearches.filter(function (recentSearch) {
        return recentSearchToAdd.query ? recentSearch.query !== recentSearchToAdd.query : recentSearch.id !== recentSearchToAdd.id;
      });
      mergedRecentSearches.unshift(recentSearchToAdd);
      window.localStorage.setItem("recentSearch:" + this.activeBrand, JSON.stringify(mergedRecentSearches.slice(0, 5)));
    },

    getRecentSearch: function getRecentSearch() {
      var recentSearches = JSON.parse(window.localStorage.getItem("recentSearch:" + this.activeBrand));
      if (recentSearches) {
        var validRecentSearches = recentSearches.filter(this.validateRecentSearch);
        return validRecentSearches.slice(0, 5);
      }
      return [];
    },

    actions: {
      logout: function logout() {
        this.userService.performLogout().finally(function () {
          window.location = "/";
        });
      },
      quickSearch: function quickSearch(query, options) {
        return this.search.execute(Object.assign({}, QUICK_SEARCH_PARAMS, options, {
          query: query
        }));
      },
      mainSearch: function mainSearch(query, options) {
        //if advanced query or query is empty dont add to recent searches
        if (query && !options) {
          this.setRecentSearch({
            query: query
          });
        }

        this.transitionTo("search.index", {
          queryParams: Object.assign({ query: query }, options)
        });
      },
      jumpIntoContent: function jumpIntoContent(searchResult) {
        var title = searchResult.title || searchResult.searchTitle;

        this.setRecentSearch({
          id: searchResult.id,
          type: searchResult.type,
          title: title
        });

        var routeName = void 0;
        var pluralizedType = (0, _emberInflector.pluralize)(searchResult.type);
        var editRoute = pluralizedType + ".edit";
        if (this.hasRoute(editRoute)) {
          routeName = editRoute;
        } else if (this.hasRoute(pluralizedType)) {
          routeName = pluralizedType;
        } else {
          routeName = "contentEdit";
          return this.transitionTo(routeName, pluralizedType, searchResult.id);
        }
        this.transitionTo(routeName, searchResult.id);
      },
      getRecentSearch: function getRecentSearch() {
        return this.getRecentSearch();
      },
      getContentTypeSearch: function getContentTypeSearch() {
        var _this3 = this;

        //TODO
        return this.configService.config.contentTypes.filter(function (contentType) {
          return contentType.discoverable && contentType.discoverable.advancedSearch === true;
        }).map(function (contentType) {
          var translated = _this3.intl.t("contentTypes." + contentType.value, {
            count: 1
          });
          return {
            query: "",
            options: {
              types: contentType.value
            },
            name: (translated || contentType.label).toString()
          };
        }).sortBy("name");
      },
      error: function error(_error) {
        var logger = this.consoleLogger;
        var errorStatus = _error.status;
        var jqXHRStatus = _error.jqXHR && _error.jqXHR.status;
        var statusCode = errorStatus || jqXHRStatus;

        // If response statusCode is 404 or 400, redirect to 404/not-found page. 400s are thrown by the api when a malformed (non mongo)
        // id is requested, we redirect to the 404 page in this case as well.
        if (statusCode && statusCode.toString().match(/404|400/)) {
          logger.warn(this.get("userService.user.id") + " tried to access " + this.get("router.currentURL") + " resulting in a 404");
          this.intermediateTransitionTo("/not-found", "not-found");
        } else {
          Ember.onerror(_error);
          this.intermediateTransitionTo("error", _error);
        }
      },
      upload: function upload(file) {
        return this.uploadAsset(file);
      },
      uploadTranscript: function uploadTranscript(file) {
        var filename = (0, _copilotSlug.default)(file.name.replace(".txt", ""));
        return file.uploadBinary("/api/upload/transcript/" + filename, {});
      },
      bulkCsvUpload: function bulkCsvUpload(file) {
        var type = "product";
        var owner = Ember.getOwner(this);
        this.set("controller.csvUploader", _csvUpload.default.create({ file: file, type: type, owner: owner }));
      },
      getHalTags: function getHalTags(model, field) {
        var _this4 = this;

        var url = "/api/hal/suggest";
        var markdownParser = Ember.getOwner(this).lookup("parser:markdown-hal");
        var data = {
          hed: markdownParser.parse(model.get("hed")),
          dek: markdownParser.parse(model.get("dek")),
          body: markdownParser.parse(model.get("body"))
        };
        return new Ember.RSVP.Promise(function (resolve, reject) {
          (0, _icAjax.default)(url, {
            type: "POST",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data)
          }).then(function (response) {
            var keywordObjects = Ember.get(response, "analyzedContents.firstObject.keywords");
            var keywords = keywordObjects.mapBy("label");
            var existingKeywords = model.get(field) || [];
            var mergedKeywords = existingKeywords.concat(keywords);
            var uniqueKeywords = [].concat(_toConsumableArray(new Set(mergedKeywords)));
            model.set(field, uniqueKeywords);
            resolve();
          }).catch(function (error) {
            _this4.consoleLogger.error("HAL Tag recommendation error", {
              caught: error
            });
            reject();
          });
        });
      },
      setLocale: function setLocale(locale) {
        this.analytics.track("user", "locale", { locale: locale }, {
          label: "locale"
        });
        this._setLocale(locale);
      },
      switchBrand: function switchBrand(brand) {
        var _this5 = this;

        // Transition to default route first in order to trigger component-based
        // unsaved warning prior to setting the new brand (avoids cookie issues)
        var defaultRoute = this.get("brandConfig.defaultRoute");
        this.transitionTo(defaultRoute).then(function () {
          _this5.set("activeBrand", brand);
          if (_environment.default.hotBrandSwitching) {
            _this5._resetContainerAndRegistry();
            _this5._setupBrandConfig().then(function () {
              return _this5._addBrandTranslations();
            }).then(function () {
              // let defaultRoute = this.get('brandConfig.defaultRoute');
              _this5.transitionTo(defaultRoute, brand);
            });
          } else {
            window.location = "/" + brand;
          }
        });
      },
      searchCategories: function () {
        var _ref = _asyncToGenerator(function* (query, term) {
          return yield this.categoryService.search(query, term);
        });

        function searchCategories(_x, _x2) {
          return _ref.apply(this, arguments);
        }

        return searchCategories;
      }(),
      offerProductDetail: function () {
        var _ref2 = _asyncToGenerator(function* (offerData, rootData) {
          var offerUrls = [offerData.get("purchaseUri")];
          var brandCode = rootData.get("configService.activeCnBrandCode");
          var productid = rootData.get("id");
          var activeBrand = rootData.get("configService.activeBrand");
          var brandName = this.configService.getBrandName(activeBrand);
          var offerProductDetail = yield this.offerProducts.getProductDetails(brandCode, offerUrls, productid, brandName);
          return offerProductDetail && offerProductDetail[0];
        });

        function offerProductDetail(_x3, _x4) {
          return _ref2.apply(this, arguments);
        }

        return offerProductDetail;
      }(),
      setCategorySelections: function setCategorySelections(model, fieldName, dataSerializer, notHierarchies, selections) {
        var serializer = this.getCategorySerializer(dataSerializer);

        if (notHierarchies == null || notHierarchies.length === 0) {
          model.set(fieldName, serializer.serialize(selections));
          return;
        }

        var existingCategorySelections = flattenCategories(model.get(fieldName));
        var newCategorySelections = flattenCategories(serializer.serialize(selections));

        // find any currently selected categories that this operation shouldn't effect
        var existingExcludedCategories = notHierarchies.map(function (excludedHierarchy) {
          return existingCategorySelections.filter(function (category) {
            return category.hierarchyString.includes(excludedHierarchy);
          });
        });

        // make sure the operation isn't adding any categories it shouldn't
        var selectedCategoriesMinusExcluded = newCategorySelections.filter(function (category) {
          return !notHierarchies.some(function (excludedHierarchy) {
            return category.hierarchyString.includes(excludedHierarchy);
          });
        });

        var newCategories = collectCategoriesByRoots(selectedCategoriesMinusExcluded.concat.apply(selectedCategoriesMinusExcluded, _toConsumableArray(existingExcludedCategories)));

        model.set(fieldName, newCategories);
      },
      onLayoutCategoryChange: function onLayoutCategoryChange(model, category) {
        function isContentHeader(setting) {
          return setting.hierarchyString.includes("verso-settings/article-settings/ContentHeader/");
        }

        var versoSettings = model.get("categories.verso-settings");
        if (!versoSettings) {
          model.set("categories.verso-settings", [category]);
          return;
        }
        var settingsToKeep = versoSettings.filter(function (setting) {
          return !isContentHeader(setting);
        });
        model.set("categories.verso-settings", [category].concat(_toConsumableArray(settingsToKeep)));
      },
      updateTrackingData: function updateTrackingData(trackedData) {
        var updatedTrackingData = Object.assign({}, this.analytics.timespentMetadata, trackedData);
        return this.analytics.set("timespentMetadata", updatedTrackingData);
      },
      getTagsSuggestions: function getTagsSuggestions(model) {
        var _this6 = this;

        var url = "/api/categories/tags/suggest";
        var markdownParser = Ember.getOwner(this).lookup("parser:markdown-preview");
        // Should use atjson in the future
        var mdToPlainText = function mdToPlainText(mdText) {
          var htmlText = markdownParser.parse(mdText);

          // Retrieve the plain text by using a temporary div element
          var tempDivElement = document.createElement("div");
          tempDivElement.innerHTML = htmlText;
          return tempDivElement.textContent || tempDivElement.innerText || "";
        };

        var configService = Ember.getOwner(this).lookup("service:config");
        var activeBrand = configService.findBrand.byCopilotCode(configService.activeBrand);
        // Currently, prediction models are only available for production organizationIds.
        // We can remove this and use the model._data.organizationId value once we have non-prod models.
        var organizationId = activeBrand && activeBrand.organizationGlobalNodeId;

        // Using model._data here, noticed this is done for `action-bar` component to get alternateLang
        // If there is a better way to get the entire Content API entity, we can switch it...
        var contentEntity = Object.assign({}, model._data, {
          hed: mdToPlainText(model.get("hed")),
          dek: mdToPlainText(model.get("dek")),
          body: mdToPlainText(model.get("body")),
          organizationId: organizationId
        });

        return new Ember.RSVP.Promise(function (resolve, reject) {
          (0, _icAjax.default)(url, {
            type: "POST",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(contentEntity)
          }).then(function (searchResult) {
            var klass = _this6.store.modelFor("category");
            var serializer = Ember.getOwner(_this6).lookup("serializer:category");
            var categories = searchResult.categories.map(function (categoryObj) {
              var normalized = serializer.normalize(klass, categoryObj);
              var category = _this6.categoryService.categoryFactory();
              category.load(normalized.id, normalized);
              return category;
            });
            resolve({ categories: categories });
          }).catch(function (error) {
            _this6.consoleLogger.error("Tag suggestion error", {
              caught: error
            });
            reject(error);
          });
        });
      }
    }
  });
});