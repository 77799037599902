define("copilot/brands/vogue/mixins/vogue-gallery-child", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fashiongallery: Ember.inject.controller("fashiongalleries.index"),
    associatedDetailsGallery: Ember.computed.reads("fashiongallery.associatedDetailsGallery"),
    associatedDetailItems: Ember.computed.reads("associatedDetailsGallery.items"),
    hasAssociatedDetailItems: Ember.computed.notEmpty("associatedDetailItems"),
    activeIds: Ember.computed.reads("fashiongallery.activeIds"),
    allIds: Ember.computed.reads("fashiongallery.allIds"),
    isActive: Ember.computed.reads("galleryItem.isActive"),
    concurrentEditId: Ember.computed.reads("fashiongallery.model.id"),
    _previewModel: Ember.computed.reads("fashiongallery.model"),
    activeBrand: Ember.computed.alias("brand.activeBrand"),
    isSaved: true,

    // The corresponding embedded fashion-gallery-item in the parent
    // fashiongalleries/index controller's `items` array
    galleryItem: Ember.computed("fashiongallery.model.items.@each.id", "id", function () {
      var itemId = this.id;
      var item = this.get("fashiongallery.model.items").find(function (item) {
        return item.get("id") === itemId;
      });
      return item;
    }),

    currentIndex: Ember.computed("id", "activeIds", function () {
      var id = this.id;
      return this.allIds.indexOf(id);
    }),

    previousId: Ember.computed("allIds", "currentIndex", function () {
      var allIds = this.allIds;
      var currentIndex = this.currentIndex;
      return allIds[currentIndex - 1];
    }),

    nextId: Ember.computed("allIds", "currentIndex", function () {
      var allIds = this.allIds;
      var currentIndex = this.currentIndex;
      return allIds[currentIndex + 1];
    }),

    transitionNextOrPrev: function transitionNextOrPrev(nextOrPrev) {
      var id = nextOrPrev === "next" ? this.nextId : this.previousId;
      var mainModelType = this.mainModelType;
      this.transitionToRoute("fashiongalleries.index." + mainModelType, this.get("fashiongallery.model"), id);
    },


    actions: {
      save: function save() {
        this.save();
      },
      cancel: function cancel() {
        if (this.modelsAreDirty) {
          this.set("loading", true);
          var promises = this.modelsToSave.map(function (modelName) {
            var model = this.get(modelName);
            return model.reload().then(function (model) {
              this.set(modelName, model);
            }.bind(this));
          }.bind(this));
          Ember.RSVP.all(promises).then(function () {
            this.set("loading", false);
          }.bind(this));
        }
      },
      toggleActive: function toggleActive() {
        var item = this.galleryItem;
        this.fashiongallery.send("toggleActive", item);
      },
      openChildObject: function openChildObject() {
        var activeBrand = this.activeBrand;
        var id = this.photoId;
        var url = "/" + activeBrand + "/photos/" + id;
        window.open(url);
      },
      next: function next() {
        this.transitionNextOrPrev("next");
      },
      previous: function previous() {
        this.transitionNextOrPrev("previous");
      }
    }
  });
});