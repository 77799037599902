define('copilot/components/ember-native-scrollable', ['exports', 'ember-collection/components/ember-native-scrollable'], function (exports, _emberNativeScrollable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberNativeScrollable.default;
    }
  });
});