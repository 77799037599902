define("copilot/lib/store-extensions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = {
    schemaToModel: Ember.inject.service(),
    brand: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this._modelCache = {};
      this._factoryCache = {};
    },
    baseType: function baseType(metadata) {
      if (metadata) {
        return metadata.alwaysEmbedded ? "embedded" : "base";
      }
      return "unknown";
    },
    find: function find(type, id) {
      var klass = this.modelFor(type);
      var cachedRecord = klass.cachedRecordForId(id, Ember.getOwner(this));
      if (cachedRecord.isLoaded) {
        klass.removeFromCache(id);
      }
      return this._find(type, id, true);
    },
    fetchTransient: function fetchTransient(type, id) {
      var klass = this.modelFor(type);
      var wasTransient = klass.transient;

      klass.reopenClass({
        transient: true
      });
      var fetchPromise = klass.fetch(id);
      klass.reopenClass({
        transient: wasTransient
      });

      return fetchPromise;
    },
    modelFor: function modelFor(type) {
      if (this._modelCache[type]) {
        return this._modelCache[type];
      }
      var model = void 0;

      var _schemaToModel$modelF = this.schemaToModel.modelFor(type),
          _schemaToModel$modelF2 = _slicedToArray(_schemaToModel$modelF, 2),
          schemaAttrs = _schemaToModel$modelF2[0],
          metadata = _schemaToModel$modelF2[1];

      try {
        model = this._super.apply(this, arguments);
      } catch (e) {
        var baseType = this.baseType(metadata);
        model = this._super(baseType);

        if (baseType === "unknown") {
          model.reopenClass({
            adapter: Ember.getOwner(this).lookup("adapter:unknown")
          });
        }
      }
      if (schemaAttrs) {
        model = model.extend(schemaAttrs).reopenClass({
          metadata: metadata,
          url: "/api/" + metadata.collectionName
        });
      }
      this._modelCache[type] = model;
      return model;
    },
    modelFactoryFor: function modelFactoryFor(type) {
      if (this._factoryCache[type]) {
        return this._factoryCache[type];
      }

      var _schemaToModel$modelF3 = this.schemaToModel.modelFor(type),
          _schemaToModel$modelF4 = _slicedToArray(_schemaToModel$modelF3, 2),
          schemaAttrs = _schemaToModel$modelF4[0],
          metadata = _schemaToModel$modelF4[1];

      var baseType = this.baseType(metadata);
      var factory = this._super.apply(this, arguments) || this._super(baseType);
      if (schemaAttrs) {
        // Here we create a "fake" Factory Manager proxy to
        // get around read-only API
        var owner = Ember.getOwner(this);
        factory = {
          class: factory.class.extend(schemaAttrs).reopenClass({
            metadata: metadata,
            url: "/api/" + metadata.collectionName
          }),
          create: function create(props) {
            var instance = this.class.create(props);
            Ember.setOwner(instance, owner);
            return instance;
          }
        };
        Ember.setOwner(factory, owner);
      }
      this._factoryCache[type] = factory;
      return factory;
    }
  };
});