define("copilot/services/experiments", ["exports", "ember-inflector", "ic-ajax"], function (exports, _emberInflector, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var OVERRIDE_TYPES = {
    HED_DEK: "HED_DEK",
    ASSET: "ASSET",
    HED_DEK_ASSET: "HED_DEK_ASSET",
    NONE: "NONE"
  };

  // Possible states for an experiment. Source of truth: https://github.com/CondeNast/content-service/blob/5e157315047ab369e47ddf6891d2f0b87cc6c23e/src/modules/experiment/schema.graphql#L22-L38
  var STATES = {
    DRAFT: "DRAFT",
    RUNNING: "RUNNING",
    TERMINATED_CANCELED: "TERMINATED_CANCELED",
    TERMINATED_CANCELED_OVERRIDE: "TERMINATED_CANCELED_OVERRIDE",
    TERMINATED_TIMEDOUT: "TERMINATED_TIMEDOUT",
    TERMINATED_TIMEDOUT_OVERRIDE: "TERMINATED_TIMEDOUT_OVERRIDE",
    TERMINATED_WINNER: "TERMINATED_WINNER",
    TERMINATED_WINNER_OVERRIDE: "TERMINATED_WINNER_OVERRIDE"
  };

  // Possible error codes for an experiment. Source of truth: https://github.com/CondeNast/content-service/blob/master/src/modules/experiment/utils/error-codes.ts
  var ERROR_CODES = {
    ACTIVE_EXPERIMENT_EXISTS: "ACTIVE_EXPERIMENT_EXISTS",
    INVALID_STATE_TO_UPDATE: "INVALID_STATE_TO_UPDATE",
    INVALID_VARIANT_DATA: "INVALID_VARIANT_DATA",
    INVALID_OVERRIDE_DATA: "INVALID_OVERRIDE_DATA",
    TOO_MANY_RUNNING_EXPERIMENTS: "TOO_MANY_RUNNING_EXPERIMENTS",
    INVALID_STATE_TO_RUN: "INVALID_STATE_TO_RUN",
    INVALID_STATE_TO_TERMINATE: "INVALID_STATE_TO_TERMINATE",
    INVALID_TERMINATION_STATE: "INVALID_TERMINATION_STATE",
    INVALID_STATE_TO_OVERRIDE: "INVALID_STATE_TO_OVERRIDE",
    INVALID_STATE_TO_REMOVE: "INVALID_STATE_TO_REMOVE",
    EXPERIMENT_NOT_FOUND: "EXPERIMENT_NOT_FOUND",
    WASABI_ERROR: "WASABI_ERROR",
    UNEXPECTED_ERROR: "UNEXPECTED_ERROR"
  };

  var ExperimentsService = Ember.Service.extend({
    store: Ember.inject.service(),
    consoleLogger: Ember.inject.service(),
    intl: Ember.inject.service(),
    features: Ember.inject.service(),

    states: STATES,
    overrideTypes: OVERRIDE_TYPES,

    createLocalExperiment: function createLocalExperiment() {
      var newExperiment = this.store.createRecord("experiment", {
        state: this.states.DRAFT,
        readyToRun: false,
        overrideType: this.overrideTypes.NONE
      });

      return newExperiment;
    },
    getActiveExperiment: function getActiveExperiment(model) {
      var currentExperiment = model.get("experiments.items.firstObject");

      // When the current experiment is overridden, we effectively don't have an active experiment...
      if (currentExperiment && (currentExperiment.state === STATES.TERMINATED_CANCELED_OVERRIDE || currentExperiment.state === STATES.TERMINATED_TIMEDOUT_OVERRIDE || currentExperiment.state === STATES.TERMINATED_WINNER_OVERRIDE)) {
        return;
      }

      return currentExperiment;
    },
    insertCurrentExperiment: function insertCurrentExperiment(model, experiment) {
      model.get("experiments.items").insertAt(0, experiment);
    },
    setCurrentExperiment: function setCurrentExperiment(model, experiment) {
      model.get("experiments.items").replace(0, 1, [experiment]);
    },
    removeCurrentExperimentIfLocal: function removeCurrentExperimentIfLocal(model) {
      var activeExperiment = this.getActiveExperiment(model);
      var isLocal = !activeExperiment.id;

      if (isLocal) {
        model.get("experiments.items").removeAt(0);
      }
    },
    handleCreateNewExperiment: function handleCreateNewExperiment(model) {
      // Create a new local experiment
      var newExperiment = this.createLocalExperiment();

      // Insert it as the current / active experiment
      this.insertCurrentExperiment(model, newExperiment);
    },
    getShowAsset: function getShowAsset(model) {
      var activeExperiment = this.getActiveExperiment(model);

      if (activeExperiment) {
        return activeExperiment.get("overrideType") === this.overrideTypes.ASSET || activeExperiment.get("overrideType") === this.overrideTypes.HED_DEK_ASSET;
      }

      return false;
    },
    setShowAsset: function setShowAsset(model, value) {
      var activeExperiment = this.getActiveExperiment(model);

      var createdNewExperiment = false;
      if (!activeExperiment) {
        activeExperiment = this.createLocalExperiment();
        createdNewExperiment = true;
      }

      if (value) {
        // Use the canonical tout as A variant
        activeExperiment.set("promoAssetA", model.get("photosTout.firstObject"));

        if (activeExperiment.overrideType === this.overrideTypes.HED_DEK) {
          activeExperiment.set("overrideType", this.overrideTypes.HED_DEK_ASSET);
        } else if (activeExperiment.overrideType === this.overrideTypes.NONE) {
          activeExperiment.set("overrideType", this.overrideTypes.ASSET);
        }
      } else {
        if (activeExperiment.overrideType === this.overrideTypes.HED_DEK_ASSET) {
          activeExperiment.set("overrideType", this.overrideTypes.HED_DEK);
        } else if (activeExperiment.overrideType === this.overrideTypes.ASSET) {
          activeExperiment.set("overrideType", this.overrideTypes.NONE);
        }

        // Clear B variant
        activeExperiment.set("promoAssetB", null);
      }

      if (createdNewExperiment) {
        this.insertCurrentExperiment(model, activeExperiment);
      }

      // If after setting the value we're in the "NONE" state and the experiment hasn't
      // been persisted, then we can remove this experiment.
      if (activeExperiment.overrideType === this.overrideTypes.NONE) {
        this.removeCurrentExperimentIfLocal(model);
      }

      return this.getShowAsset(model);
    },
    getShowHedDek: function getShowHedDek(model) {
      var activeExperiment = this.getActiveExperiment(model);

      if (activeExperiment) {
        return activeExperiment.get("overrideType") === this.overrideTypes.HED_DEK || activeExperiment.get("overrideType") === this.overrideTypes.HED_DEK_ASSET;
      }

      return false;
    },
    setShowHedDek: function setShowHedDek(model, value) {
      var activeExperiment = this.getActiveExperiment(model);

      var createdNewExperiment = false;
      if (!activeExperiment) {
        activeExperiment = this.createLocalExperiment();
        createdNewExperiment = true;
      }

      if (value) {
        // Use canonical hed/dek as A variant
        activeExperiment.set("promoHedA", model.get("promoHed"));
        activeExperiment.set("promoDekA", model.get("promoDek"));

        if (activeExperiment.overrideType === this.overrideTypes.ASSET) {
          activeExperiment.set("overrideType", this.overrideTypes.HED_DEK_ASSET);
        } else if (activeExperiment.overrideType === this.overrideTypes.NONE) {
          activeExperiment.set("overrideType", this.overrideTypes.HED_DEK);
        }
      } else {
        if (activeExperiment.overrideType === this.overrideTypes.HED_DEK_ASSET) {
          activeExperiment.set("overrideType", this.overrideTypes.ASSET);
        } else if (activeExperiment.overrideType === this.overrideTypes.HED_DEK) {
          activeExperiment.set("overrideType", this.overrideTypes.NONE);
        }

        // Clear B variants
        activeExperiment.set("promoHedB", null);
        activeExperiment.set("promoDekB", null);
      }

      if (createdNewExperiment) {
        this.insertCurrentExperiment(model, activeExperiment);
      }

      // If after setting the value we're in the "NONE" state and the experiment hasn't
      // been persisted, then we can remove this experiment.
      if (activeExperiment.overrideType === this.overrideTypes.NONE) {
        this.removeCurrentExperimentIfLocal(model);
      }

      return this.getShowHedDek(model);
    },
    overrideTerminatedExperiment: function () {
      var _ref = _asyncToGenerator(function* (model) {
        var activeExperiment = this.getActiveExperiment(model);

        var _model$getProperties = model.getProperties(["id", "type"]),
            id = _model$getProperties.id,
            type = _model$getProperties.type;

        var url = "/api/" + (0, _emberInflector.pluralize)(type) + "/" + id + "/experiments/" + activeExperiment.id + "/override-terminated";

        try {
          var data = yield (0, _icAjax.default)(url, {
            type: "POST"
          });

          var overriddenExperiment = this.store.createRecord("experiment");
          overriddenExperiment.load(data.id, data);
          this.setCurrentExperiment(model, overriddenExperiment);
        } catch (err) {
          var response = err.jqXHR && err.jqXHR.responseText ? err.jqXHR.responseText : err.message;
          this.consoleLogger.warn("Error overriding experiment: " + response);

          // If we received an error object, add it to the experiments error array
          if (err.jqXHR && err.jqXHR.responseJSON && err.jqXHR.responseJSON.errors) {
            var experimentsErrors = model.get("experiments.errors");

            var respErrors = err.jqXHR.responseJSON.errors;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = respErrors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var experimentError = _step.value;

                var experimentErrorObject = this.store.createRecord("experiment-error", experimentError);
                experimentsErrors.pushObject(experimentErrorObject);
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }
          }
        }
      });

      function overrideTerminatedExperiment(_x) {
        return _ref.apply(this, arguments);
      }

      return overrideTerminatedExperiment;
    }(),
    getReadyToRun: function getReadyToRun(model) {
      var activeExperiment = this.getActiveExperiment(model);

      if (activeExperiment) {
        return activeExperiment.get("readyToRun");
      }

      return false;
    },
    setReadyToRun: function setReadyToRun(model, value) {
      if (value) {
        model.set("experiments.items.firstObject.readyToRun", true);
      } else {
        model.set("experiments.items.firstObject.readyToRun", false);
      }

      return value;
    },
    getWarningMessages: function getWarningMessages(experimentErrors) {
      var warnings = [];

      if (this.features.isEnabled("enableExperiments") && experimentErrors && experimentErrors.length > 0) {
        // Differentiate between too many experiments running error and other errors
        if (experimentErrors.any(function (error) {
          return error.code === ERROR_CODES.TOO_MANY_RUNNING_EXPERIMENTS;
        })) {
          warnings.push({
            message: this.intl.t("promo-experiment.errors.hasReachedMaxRunningExperiments.message"),
            header: this.intl.t("promo-experiment.errors.hasReachedMaxRunningExperiments.header")
          });
        }
        if (experimentErrors.any(function (error) {
          return error.code !== ERROR_CODES.TOO_MANY_RUNNING_EXPERIMENTS;
        })) {
          warnings.push({
            message: this.intl.t("promo-experiment.errors.generic.message"),
            header: this.intl.t("promo-experiment.errors.generic.header")
          });
        }
      }

      return warnings;
    },
    logExperimentsErrors: function logExperimentsErrors(model) {
      var experimentErrors = model.get("experiments.errors");
      if (this.features.isEnabled("enableExperiments") && experimentErrors && experimentErrors.length > 0) {
        // Log full errors.
        this.consoleLogger.error("Received experiment errors.", experimentErrors);
      }
    }
  });

  exports.default = ExperimentsService;
});