define("copilot/brands/cnt/models/list", ["exports", "copilot/models/base", "copilot/models/attr", "ember-model"], function (exports, _base, _attr, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BaseList = _base.default.extend({
    hed: (0, _emberModel.attr)(),
    dek: (0, _emberModel.attr)(_attr.default.str),
    channel: (0, _emberModel.attr)(_attr.default.str),
    subChannel: (0, _emberModel.attr)(_attr.default.str),
    tags: (0, _emberModel.attr)(_attr.default.array),
    revision: (0, _emberModel.attr)(_attr.default.revision),
    items: (0, _emberModel.hasMany)("list-item", {
      embedded: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("hed"),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      seoDescription: "dek",
      seoTitle: "hed"
    }
  });

  exports.default = BaseList;
});