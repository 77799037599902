define("copilot/routes/curatedlists/index", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route"], function (exports, _unsavedWarning, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, {
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      var defaultCurationSubTypeOption = this.brandConfig.curationTypeOptions.find(function (option) {
        return option.default;
      });
      if (Ember.isEmpty(model.get("curationSubType"))) {
        model.set("curationSubType", defaultCurationSubTypeOption.value);
      }
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        window.dataLayer[0].content.pageType = model.type;
      }
    }
  });
});