define("copilot/components/category-manager/edit/component", ["exports", "ember-concurrency", "copilot/mixins/validatable", "@condenast/copilot-slug"], function (exports, _emberConcurrency, _validatable, _copilotSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var FormModel = Ember.Object.extend(_validatable.default, {
    type: "category",
    name: "",
    slug: "",
    isNew: false,
    computedSlug: Ember.computed("name", "isNew", function () {
      var name = this.get("name");
      var isNew = this.get("isNew");
      if (name && isNew) {
        return (0, _copilotSlug.default)(name, this.slugifyOptions);
      }
    }),
    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      slug: "computedSlug"
    }
  });

  var CombinedName = Ember.Object.extend({
    name: "",
    availableLanguages: [],
    separator: Ember.computed("name", function () {
      var name = this.get('name');
      var found = [];
      // Finds a slash placed right before a part of the given name that consists of non-japanese characters.
      if (/ +\/ +/.test(name) || /\/ +/.test(name) || / +\//.test(name)) {
        found = found.concat(name.match(/( *\/ *)[^亜-熙ぁ-んァ-ヶ]+$/));
      }
      return found.length ? found[1] : "";
    }),
    /**
     * An array containing extracted names from the given category name
     * consisting of the Japanese and other languages' strings. The first
     * element of the result array is expected to be a Japanese string, and the
     * rest is a string in the other languages except Japanese.
     *
     * @property {*[]}
     */
    separatedNames: Ember.computed("name", "separator", function () {
      var separator = this.get("separator");
      var names = separator ? this.get("name").split(separator) : [this.get("name")];
      if (names.length > 2) {
        var rightSide = names.pop();
        var leftSide = [].concat(_toConsumableArray(names));
        names = [leftSide.join(separator), rightSide];
      }
      return names.map(function (name) {
        return name.trim();
      });
    }),
    init: function init() {
      var _this = this;

      this.get('availableLanguages').forEach(function (lang) {
        var names = _this.get('separatedNames');
        var isJapanese = lang.startsWith('ja') || lang.startsWith('jp');
        var name = isJapanese ? names[0] : names[1];
        _this.set(lang, name);
      });
      this._super.apply(this, arguments);
    }
  });

  /**
    The `category-manager/edit` component uses the copilot-form component to
    allow editing or creating a category model. The component is invoked with
    a model and two handlers: onsave, which is called when the save button
    is clicked, and dismiss, which is called when the cancel button is clicked
    and also after a successful call of the onsave handler
  
    ```hbs
    {{category-manager/edit
      model=model
      onsave=(route-action "saveCategory")
      dismiss=(route-action "returnToParent")
      countUsage=(route-action "countUsage")
    }}
    ```
  
    This component can be used inside a dialog-box component which can provide
    the dismiss handler:
  
    ```hbs
    {{dialog-box
      (component "category-manager/edit" model=model onsave=(route-action "saveCategory") countUsage=(route-action "countUsage"))
      ondismiss=(route-action "returnToParent")
    }}
   */
  exports.default = Ember.Component.extend({
    classNames: ["edit"],
    features: Ember.inject.service(),

    /**
      The model to edit. Usually this is an instance of models/category
      @property {(Object|Ember.Model)} model
     */
    model: null,

    /**
      The function to be called when the save button is clicked
      @property {Function} onsave
     */
    onsave: function onsave() {},


    /**
      The function to be called when the cancel button is clicked and also after a successful
      call of the onsave handler
      @property {Function} dismiss
    */
    dismiss: function dismiss() {},


    /**
     * A function to be called to count the number of content items used by a category
     * @property {Function} countUsage
     */
    countUsage: function countUsage() {},


    archived: Ember.computed.reads("model.meta.archived"),
    slugifyOptions: Ember.computed("features.unicodeSlugs", function () {
      if (this.features.get("unicodeSlugs")) {
        return { allowAllLetterCharacters: true };
      }
      return {};
    }),
    shouldBeLocalizable: Ember.computed.reads("features.categoryL10ns"),
    availableLanguages: Ember.computed.reads("configService.config.language.available"),
    defaultLanguage: Ember.computed.reads("configService.config.language.default"),
    useCombinedNaming: Ember.computed.reads("features.categoryCombinedNaming"),
    hasL10ns: Ember.computed.notEmpty("model.l10ns"),
    languagesExist: Ember.computed.notEmpty("availableLanguages"),
    canLocalize: Ember.computed("shouldBeLocalizable", "languagesExist", function () {
      return this.get("shouldBeLocalizable") && this.get("languagesExist");
    }),
    flattenedL10ns: Ember.computed("availableLanguages", "hasL10ns", "model.l10ns", "shouldBeLocalizable", "useCombinedNaming", "defaultLanguage", function () {
      var _this2 = this;

      if (!this.get("shouldBeLocalizable")) {
        return {};
      }
      if (this.get("hasL10ns")) {
        return this.get("availableLanguages").reduce(function (result, lang) {
          var current = _this2.get("model.l10ns").find(function (l10n) {
            return l10n.lang.toLowerCase() === lang.toLowerCase();
          });
          if (current) {
            result[lang] = current.text;
          }
          return result;
        }, {});
      }
      var defaultLang = this.get("defaultLanguage").toLowerCase();
      return this.get("availableLanguages").reduce(function (result, lang) {
        if (_this2.get("useCombinedNaming")) {
          result[lang] = _this2.get("combinedName." + lang);
          return result;
        }
        result[lang] = defaultLang === lang.toLowerCase() ? _this2.get("model.name") : "";
        return result;
      }, {});
    }),
    getCurrentName: function getCurrentName() {
      var _this3 = this;

      if (!this.get("useCombinedNaming")) {
        return this.get("formModel.name");
      }
      return this.get("availableLanguages").reduce(function (combinedName, lang) {
        var text = _this3.get("formModel." + lang);
        if (text) {
          combinedName = combinedName ? combinedName + " / " + text : text;
        }
        return combinedName;
      }, "");
    },
    getCurrentL10ns: function getCurrentL10ns() {
      var _this4 = this;

      return this.get('availableLanguages').reduce(function (l10ns, lang) {
        var textValue = _this4.get("formModel." + lang);
        if (textValue) {
          l10ns.push({ lang: lang, field: 'name', text: textValue });
        }
        return l10ns;
      }, []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var model = this.get("model");
      this.set("formModel", new FormModel(Object.assign({}, model.getProperties("id", "name", "slug", "isNew", "validation"), {
        parent: {
          id: model.get("parent.id")
        },
        slugifyOptions: this.get("slugifyOptions"),
        archived: this.get("archived")
      })));
      if (this.get("shouldBeLocalizable") && this.get("useCombinedNaming")) {
        this.set("combinedName", CombinedName.create({
          name: model.get("name"),
          availableLanguages: this.get("availableLanguages")
        }));
      }
      if (this.get("canLocalize")) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get("availableLanguages")[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var lang = _step.value;

            this.set("formModel." + lang, this.get("flattenedL10ns." + lang));
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },

    breadcrumbs: Ember.computed("model.ancestors", function () {
      return this.get("model.ancestors").slice().reverseObjects();
    }),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        this.set("hasError", false);
        if (this.get("canLocalize") || this.get("hasL10ns")) {
          this.set("formModel.l10ns", this.getCurrentL10ns());
        }
        yield this.onsave(this.get("model"), this.get("formModel"));
        Ember.tryInvoke(this, "dismiss");
      } catch (err) {
        this.set("hasError", true);
        if (err.status >= 400) {
          this.set("formModel.validationErrors", []);
          throw err;
        } else if (err.message) {
          this.set("errorMessage", err.message);
        } else if (err.responseJSON) {
          this.set("errorMessage", err.responseJSON.message);
        }
      }
    }),
    actions: {
      updateCombinedName: function updateCombinedName() {
        Ember.set(this, "formModel.name", this.getCurrentName());
      }
    }
  });
});