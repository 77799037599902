define("copilot/components/search/search-filters/date-range/component", ["exports", "copilot/components/search/search-filters/base/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    type: "date-range",
    queryKey: "dateRange",
    replace: true,
    intl: Ember.inject.service(),

    options: Ember.computed(function () {
      var _this = this;

      return ["past-hour", "past-24-hours", "past-week", "past-month", "past-year", "from-now", "custom"].map(function (option) {
        return {
          name: _this.intl.t("components.search.filters.date-range." + option),
          value: option
        };
      });
    }),

    actions: {
      applyOption: function applyOption(type, option) {
        if (option.value === "custom") {
          this.set("isSelectingDateRange", true);
        } else {
          this.onApply.apply(this, arguments);
        }
      },
      applyDateRange: function applyDateRange(dates) {
        var option = this.options.findBy("value", "custom");
        option.dates = dates;
        this.onApply(this.type, option);
        this.set("isSelectingDateRange", false);
      }
    }
  });
});