define("copilot/brands/vogue/serializers/fashiongallery", ["exports", "lodash-es", "copilot/serializers/application"], function (exports, _lodashEs, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.items = json.items.map(function (item) {
        item.relId = item.embedded.id;
        return _lodashEs.default.omit(item, "tags");
      });

      return json;
    }
  });
});