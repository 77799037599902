define("copilot/brands/vogue/models/runwaymoment", ["exports", "copilot/models/base", "ember-model"], function (exports, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RunwayMoment = _base.default.extend({
    type: "runwaymoment",

    runwayMomentType: Ember.attr(),
    moveItVideo: Ember.attr(),
    modaLink: Ember.attr(),

    isSponsored: (0, _emberModel.hasMany)("isSponsored", {
      embedded: true
    }),
    city: (0, _emberModel.hasMany)("city", {
      embedded: true
    }),
    brand: (0, _emberModel.hasMany)("brand", {
      embedded: true
    }),
    designer: (0, _emberModel.hasMany)("person", {
      embedded: true
    }),
    season: (0, _emberModel.hasMany)("season", {
      embedded: true
    }),

    thumbnail: Ember.computed.reads("photosTout.firstObject"),

    details: (0, _emberModel.hasMany)("photo", {
      embedded: true
    }),

    moveItClip: (0, _emberModel.hasMany)("clip", {
      embedded: true
    }),

    photosTout: Ember.hasMany("media", {
      embedded: true,
      polymorphic: true
    }),

    load: function load(id, hash) {
      this._super(id, hash);
      this.getWithDefault("_dirtyAttributes", []).clear();
    },
    afterSaveWorker: function afterSaveWorker(isNew, result) {
      if (Ember.isNone(result.get("id"))) {
        var error = new Error("create " + this.type + " failed (" + this.get("_data.error") + ")");
        Ember.onerror(error);
      }

      // increment should not be needed here after this issue github.com/ebryn/ember-model/issues/275
      this.set("revision", result.incrementProperty("revision"));

      // We must clear _dirtyAttributes after incrementing 'revision'
      // since a newly-saved model should never be considered dirty
      this.getWithDefault("_dirtyAttributes", []).clear();

      this.set("publishHistory", result.get("publishHistory"));

      if (isNew) {
        this.set("id", result.get("id"));
      }

      return this;
    }
  });

  RunwayMoment.reopenClass({
    url: "/api/runwaymoments"
  });

  exports.default = RunwayMoment;
});