define("copilot/schemas/brands/vogue/season", ["exports", "@condenast/copilot-brand-overrides/dist/modules/schemas/vogue/season"], function (exports, _season) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _season.default;
    }
  });
});