define("copilot/brands/epi/models/pull-quote", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Model.extend({
    quote: Ember.attr(),
    attribution: Ember.attr()
  });
});