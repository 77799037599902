define("copilot/validations/brands/vogue/fashiongallery", ["exports", "@condenast/cross-check-dsl", "copilot/validations/brands/vogue/validators/published"], function (exports, _crossCheckDsl, _published) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var fashiongalleryValidators = object({
    fashionshow: object({
      season: (0, _published.default)().on("publish"),
      brand: (0, _published.default)().on("publish")
    })
  });

  exports.default = (0, _crossCheckDsl.default)(fashiongalleryValidators);
});