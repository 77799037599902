define("copilot/validations/brands/bon/package", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var packageValidators = object({
    hed: (0, _copilotValidators.required)(),
    mainTout: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    section1Hed: (0, _copilotValidators.required)().on("publish"),
    section1List: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    socialTitle: (0, _copilotValidators.required)().on("publish"),
    socialDescription: (0, _copilotValidators.required)().on("publish"),
    seoTitle: (0, _copilotValidators.required)().on("publish"),
    seoDescription: (0, _copilotValidators.required)().on("publish"),
    channel: (0, _copilotValidators.channel)().on("publish"),
    photosTout: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  }).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = (0, _crossCheckDsl.default)(packageValidators);
});