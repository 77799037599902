define("copilot/validations/brands/p4k/article", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;

  var channelValidatorOptions = {
    slugs: ["levels", "news", "thepitch", "pages"],
    parents: ["features", "tv"]
  };

  var articleValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).andAlso((0, _copilotValidators.editorEnabled)().on("publish")).andAlso(object({
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1, max: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "channels-allowed-minimum" }
      }];
    }).andThen((0, _copilotValidators.channelsAllowed)(channelValidatorOptions))),
    categories: (0, _copilotCore.ifNotFeature)("enableCategoryChannels", object({
      channels: (0, _copilotValidators.length)({ min: 1, max: 1 }).catch(function () {
        return [{
          path: [],
          message: { name: "channels-allowed-minimum" }
        }];
      }).andThen((0, _copilotValidators.channelsAllowed)(channelValidatorOptions))
    }))
  }));

  exports.default = articleValidationBuilder;
});