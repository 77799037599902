define("copilot/initializers/service", ["exports", "copilot/services/logger", "copilot/services/user", "copilot/services/authorization", "copilot/services/config", "copilot/services/photo", "copilot/services/image-editor", "copilot/services/websockets", "copilot/services/archive", "copilot/services/console-logger", "copilot/services/validation", "copilot/services/category"], function (exports, _logger, _user, _authorization, _config, _photo, _imageEditor, _websockets, _archive, _consoleLogger, _validation, _category) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    Ember.debug("Service initializer");

    application.register("service:intl", _logger.default, {
      singleton: true
    });
    application.register("service:console-logger", _consoleLogger.default, {
      singleton: true
    });
    application.register("service:logger", _logger.default, {
      singleton: true
    });
    application.register("service:user", _user.default, {
      singleton: true
    });
    application.register("service:authorization", _authorization.default, {
      singleton: true
    });
    application.register("service:config", _config.default, {
      singleton: true
    });
    application.register("service:photo", _photo.default, {
      singleton: true
    });
    application.register("service:image-editor", _imageEditor.default, {
      singleton: true
    });
    application.register("service:archive", _archive.default, {
      singleton: true
    });
    application.register("service:websockets", _websockets.default, {
      singleton: true
    });
    application.register("service:validation", _validation.default, {
      singleton: true
    });
    application.register("service:category", _category.default, {
      singleton: true
    });

    application.inject("controller", "intl", "service:intl");
    application.inject("route", "intl", "service:intl");

    application.inject("controller", "configService", "service:config");
    application.inject("route", "configService", "service:config");
    application.inject("component", "configService", "service:config");
    application.inject("model", "configService", "service:config");

    application.inject("route", "logger", "service:logger");
    application.inject("controller", "logger", "service:logger");
    application.inject("model", "logger", "service:logger");
    application.inject("component", "logger", "service:logger");
    application.inject("parser", "logger", "service:logger");
    application.inject("service:intl", "logger", "service:logger");

    application.inject("route", "consoleLogger", "service:console-logger");
    application.inject("controller", "consoleLogger", "service:console-logger");
    application.inject("model", "consoleLogger", "service:console-logger");
    application.inject("component", "consoleLogger", "service:console-logger");
    application.inject("parser", "consoleLogger", "service:console-logger");

    application.inject("model", "userService", "service:user");
    application.inject("controller", "userService", "service:user");
    application.inject("component:content-collaborators", "userService", "service:user");

    // TODO: the following injection is not working. Find out why. It is
    // being worked around manually in the application route
    application.inject("route", "userService", "service:user");
    application.inject("service:authorization", "userService", "service:user");

    application.inject("component", "authorizationService", "service:authorization");
    application.inject("view", "authorizationService", "service:authorization");
    application.inject("model", "authorizationService", "service:authorization");
    application.inject("controller", "authorizationService", "service:authorization");

    application.inject("controller", "authorizationService", "service:authorization");

    application.inject("service:logger", "userService", "service:user");
    application.inject("service:console-logger", "logger", "service:logger");

    application.inject("component", "store", "service:store");

    application.inject("model", "analytics", "service:analytics");

    application.inject("model", "validation", "service:validation");
    application.inject("service:brand", "router", "router:main");
  }

  exports.default = {
    name: "service",
    initialize: initialize,
    after: "route"
  };
});