define("copilot/services/config", ["exports", "ic-ajax", "copilot/lib/brands", "lodash-es", "copilot/lib/brands-map", "copilot/lib/copilot-computeds", "ember-concurrency"], function (exports, _icAjax, _brands, _lodashEs, _brandsMap, _copilotComputeds, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var arrayToObject = _copilotComputeds.default.arrayToObject;


  function copilotNameBrandsMap() {
    var brandMaps = {};
    _lodashEs.default.forEach(_brandsMap.default, function (brand, code) {
      var currentBrand = _brands.default.byCopilotCode(code);
      if (currentBrand) {
        brandMaps[code] = currentBrand.copilotName;
      }
    });
    return brandMaps;
  }

  function getResolvedBrandName(name) {
    if (!name) {
      throw new Error("missing copilot brand name");
    }
    return name.replace(" - Staging", "");
  }

  var mapBrandToSchema = function mapBrandToSchema(brand) {
    return {
      human: getResolvedBrandName(brand.internalDisplayName || brand.orgnizationName || brand.name),
      protocol: {
        production: "https",
        staging: "https"
      },
      copilotName: brand.metadata.copilotName,
      copilotCode: brand.metadata.copilotCode,
      copilotRegion: brand.metadata.copilotRegion,
      companyId: brand.companyId || brand.metadata.companyId,
      code: brand.shortCode || brand.metadata.code,
      slug: brand.organizationSlug || brand.slug,
      locale: {
        code: brand.locale
      },
      organizationGlobalNodeId: brand.organizationId || brand.id,
      vulcanUrl: brand.metadata.mediaDomain || brand.metadata.vulcanUrl,
      url: brand.publicDomain ? "https://" + brand.publicDomain : brand.url,
      copilotApi: brand.metadata.contentApiDomain || brand.metadata.copilotApi,
      market: brand.market || brand.metadata.market
    };
  };

  exports.default = Ember.Service.extend({
    userService: Ember.inject.service("user"),
    features: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    config: {},
    allBrandsConfigs: [],

    rootCategories: arrayToObject("config.categories.roots", "id"),
    childrenCategories: arrayToObject("config.categories.children", "id"),
    arrangedContentTypes: arrayToObject("config.contentTypes", "value"),
    arrangedBundleTypes: arrayToObject("arrangedContentTypes.bundle.subtypes", "type"),
    arrangedBundleContainers: arrayToObject("arrangedContentTypes.bundle.containers", "value"),

    findBrand: _brands.default,

    channelOptions: Ember.computed("config.channels.[]", function () {
      var options = this.get("config.channels");
      return _lodashEs.default.chain(options).compact().flatten().sortBy("value").value();
    }),

    subChannelOptions: Ember.computed("config.channels.[]", function () {
      var options = this.get("config.channels");
      return _lodashEs.default.chain(options).map(function (channel) {
        return channel.subchannels;
      }).compact().flatten().uniq("value").sortBy("value").value();
    }),

    getAllBrandsConfigs: function getAllBrandsConfigs() {
      var _this = this;

      return (0, _icAjax.default)("/api/configs").then(function (configs) {
        _this.set("allBrandsConfigs", configs);
      });
    },
    load: function load() {
      var _this2 = this;

      var userService = this.userService;
      var brandCode = this.activeBrand;
      var userId = userService.get("user._id");
      var opts = {
        url: "/api/configs",
        data: {
          brandCode: brandCode,
          userId: userId
        }
      };
      return (0, _icAjax.default)(opts).then(function (config) {
        var mappedBrands = config.orgConfig.map(mapBrandToSchema);
        _this2.findBrand.setBrands(mappedBrands);
        _this2.set("config", config);
        _this2.set("config.brandsMap", copilotNameBrandsMap());
      });
    },


    // Sets up both config service and related services
    settingUp: (0, _emberConcurrency.task)(function* () {
      yield this.load();

      this.brandConfig.load();
      this.features.setup(this.get("config.features"));

      if (this.get("features.enableAllBrandsConfigs")) {
        yield this.getAllBrandsConfigs();
      }
    }),

    setup: function setup() {
      return this.settingUp.perform();
    },
    getBrandName: function getBrandName(brandCode) {
      var code = _lodashEs.default.invert(this.get("config.brandsMap"))[brandCode];
      return _brandsMap.default[brandCode] || _brandsMap.default[code];
    },
    getBrandCode: function getBrandCode(brandName) {
      var code = _lodashEs.default.invert(this.get("config.brandsMap"))[brandName];
      return _lodashEs.default.invert(_brandsMap.default)[brandName] || code;
    },
    getBrandServiceName: function getBrandServiceName(brandCode) {
      return this.get("config.brandsMap")[brandCode];
    },
    getAllBrandCodes: function getAllBrandCodes() {
      return _lodashEs.default.keys(_brandsMap.default);
    },


    getAllBrandNames: Ember.computed(function () {
      return _lodashEs.default.values(_brandsMap.default);
    }),

    getHumanNameByBrandCode: function getHumanNameByBrandCode(brandCode) {
      var brand = this.findBrand.byCopilotCode(brandCode);
      if (!brand) {
        return "";
      }
      return brand.human;
    },


    activeCnBrandCode: Ember.computed("activeBrand", function () {
      return this.findBrand.byCopilotCode(this.activeBrand).code;
    }),

    region: Ember.computed("activeBrand", function () {
      return this.findBrand.byCopilotCode(this.activeBrand).copilotRegion;
    }),

    activeBrandSlug: Ember.computed("activeBrand", function () {
      return this.findBrand.byCopilotCode(this.activeBrand).slug;
    }),

    marketCode: Ember.computed("activeBrand", function () {
      return this.findBrand.byCopilotCode(this.activeBrand).market;
    }),

    getContentTypeConfig: function getContentTypeConfig(model, brand) {
      var type = model.type;
      var subtype = model.subtype || model.bundleType;
      if (brand) {
        return this.getContentTypesByBrandCode(brand).findBy("value", type);
      }
      var contentTypeConfig = this.get("config.contentTypes").findBy("value", type);
      if (subtype && contentTypeConfig.subtypes) {
        contentTypeConfig = contentTypeConfig.subtypes.findBy("type", subtype);
      }

      if (contentTypeConfig.publish && !contentTypeConfig.publish.distributionChannels) {
        Ember.set(contentTypeConfig, "publish.distributionChannels", this.config.distributionChannels);
      }

      return contentTypeConfig;
    },
    getConsumerHostnameByBrandCode: function getConsumerHostnameByBrandCode(brandCode) {
      var brandName = this.getHumanNameByBrandCode(brandCode);
      return this.allBrandsConfigs.findBy("name", brandName).hostnames.consumer;
    },


    consumerHostname: Ember.computed.reads("config.hostnames.consumer"),

    getContentTypesByBrandCode: function getContentTypesByBrandCode(brandCode) {
      var brandName = this.getHumanNameByBrandCode(brandCode);
      return this.allBrandsConfigs.findBy("name", brandName).contentTypes;
    },


    publishableTypes: Ember.computed("config.contentTypes.[]", function () {
      return this.get("config.contentTypes").filter(function (type) {
        return !!type.publish || type.value === "bundle";
      }).map(function (type) {
        return type.value;
      });
    }),
    getCopilotV2Url: function getCopilotV2Url() {
      return Ember.getOwner(this).resolveRegistration("config:environment").v2CopilotLocalUrl || this.get("config.copilotV2Url");
    }
  });
});