define("copilot/mixins/gallery-child-route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel() {
      var controllerName = this.controllerName;
      this.controllerFor(controllerName).set("loading", false);
    },

    actions: {
      loading: function loading() {
        var controllerName = this.controllerName;
        this.controllerFor(controllerName).set("loading", true);
      },
      willTransition: function willTransition(transition) {
        var controller = this.controller;

        if (controller.modelsAreDirty) {
          transition.abort();
          this.send("loading");

          controller.save().then(function () {
            var hasErrors = !!controller.get(controller.mainModelPath + ".validationErrors.length");
            if (hasErrors) {
              controller.set("loading", false);
            } else {
              transition.retry();
            }
          }.bind(this));
        }
      }
    }
  });
});