define("copilot/services/brand-config", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    brand: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    load: function load() {
      var configService = this.configService;
      var owner = Ember.getOwner(this);
      var mainConfig = owner.lookup("configuration:main");
      var pageLayoutConfig = owner.lookup("configuration:page-layout");
      var trendingConfig = owner.lookup("configuration:trending");
      this.setProperties(mainConfig);
      this.set("pageLayout", pageLayoutConfig);
      this.set("trending", trendingConfig);
      this.set("envUrl", configService.get("config.hostnames.consumer"));
      this.set("previewHostname", configService.get("config.hostnames.preview"));
    },


    additionalPropertyNamesOptions: Ember.computed("additionalPropertyOptions", function () {
      var optionValues = this.getWithDefault("additionalPropertyOptions", []);

      return Object.keys(optionValues).map(function (label) {
        return { label: label, value: label };
      }).sortBy("label");
    })
  });
});