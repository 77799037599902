define("copilot/brands/vogue/models/fashion-gallery-item", ["exports", "ember-model"], function (exports, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberModel.Model.extend({
    type: "fashion-gallery-item",

    embedded: (0, _emberModel.belongsTo)("base", {
      embedded: true,
      polymorphic: true
    }),
    isActive: (0, _emberModel.attr)(Boolean, {
      defaultValue: true
    }),

    id: Ember.computed.reads("embedded.id"),
    filename: Ember.computed.or("embedded.filename", "embedded.photosTout.firstObject.filename"),
    embeddedPhoto: Ember.computed.or("embedded.imageUrl", "embedded._embedded.photosTout.firstObject.fields", "embedded.photosTout.firstObject", "embedded"),

    editRoute: Ember.computed("embedded.meta.modelName", function () {
      if (this.uploading) {
        return "fashiongalleries.index.photo";
      }
      var modelName = this.get("embedded.meta.modelName") || "photo";
      return "fashiongalleries.index." + modelName;
    })
  });
});