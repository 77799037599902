define("copilot/schema-helpers/autogen-social-description", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["promoDek", "dek"]),

    // Runs in context of model
    compute: function compute(model) {
      return model.promoDek ? model.promoDek : model.dek;
    }
  });
});