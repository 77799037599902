define("copilot/components/dashboard-list/list-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNameBindings: ["isSyndicated:syndicated", "publishStatus.statusText", "publishStatus.hasPendingRevision:edits"],

    brand: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    publishStatus: Ember.computed("model", function () {
      var PublishStatus = Ember.getOwner(this).factoryFor("model:publish-status");
      var model = this.model;
      return PublishStatus.create({ model: model });
    }),

    sourceBrandName: Ember.computed("sourceBrand", function () {
      return this.configService.getBrandName(this.sourceBrand);
    }),

    sourceBrandCode: Ember.computed("sourceBrand", function () {
      return this.configService.getBrandCode(this.sourceBrand);
    }),

    isSyndicated: Ember.computed("sourceBrandCode", "activeBrand", function () {
      var sourceBrand = this.sourceBrandCode;
      var activeBrand = this.activeBrand;
      return sourceBrand && !Ember.isEqual(sourceBrand, activeBrand);
    }),

    displayDate: Ember.computed("type", "model.modifiedAt", "publishDate", function () {
      if (this.type === "modified" || this.type === "syndication") {
        return Ember.get(this, "model.modifiedAt");
      } else {
        return Ember.get(this, "publishStatus.publishDate");
      }
    })
  });
});