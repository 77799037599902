define("copilot/components/offer-autofill/component", ["exports", "ember-service-methods/inject", "ember-file-upload/file"], function (exports, _inject, _file) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Component.extend({
    loading: false,
    uploadAsset: (0, _inject.default)(),
    analytics: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    offerAutofillDomains: Ember.computed.alias("configService.config.commerce.offerAutofillDomains"),

    init: function init() {
      this._super.apply(this, arguments);
      var field = this.field;
      Ember.defineProperty(this, "modelValue", Ember.computed.alias("model." + field));
    },


    showOfferAutofillButton: Ember.computed("modelValue", function () {
      try {
        var offerAutofillDomains = this.offerAutofillDomains;
        var url = new URL(this.modelValue);
        return this.modelValue && offerAutofillDomains && offerAutofillDomains.length > 0 && (offerAutofillDomains.indexOf("*") === 0 || offerAutofillDomains.indexOf(url.host.replace("www.", "")) > -1);
      } catch (e) {
        // catch type error for bad url pattern
        return false;
      }
    }),

    updateImage: function updateImage(url, fileName, rootData) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer";
      var field = rootData.get("photosTout");
      var that = this;
      xhr.onload = function (err) {
        if (this.status === 200) {
          var arrayBuffer = this.response;
          if (arrayBuffer) {
            var byteArray = new Uint8Array(arrayBuffer);
            var file = new File([byteArray], fileName, { type: "image/jpeg" });
            that.uploadAsset(_file.default.fromBlob(file, fileName)).then(function (asset) {
              if (Ember.isArray(field)) {
                field.clear();
                field.pushObject(asset);
              } else {
                Ember.set(rootData, field, asset);
              }
            });
          }
        } else {
          Ember.set(this, "errors", err);
        }
      };
      xhr.send();
    },


    actions: {
      autofillShowHide: function autofillShowHide() {
        this.set("model.autoUpdate", this.showOfferAutofillButton ? true : false);
        !this.showOfferAutofillButton && this.set("model.isOutOfStock", false);
      },
      autofill: function () {
        var _ref = _asyncToGenerator(function* () {
          var offerData = this.get("model"),
              rootData = this.get("mainModel");
          this.set("loading", true);
          var productDetails = yield this.getOfferProductDetails(offerData, rootData);

          if (productDetails && Object.getOwnPropertyNames(productDetails).length > 0) {
            var imageUrl = productDetails["Site Image"];
            var fileName = /[^/]*$/.exec(imageUrl)[0];
            var nameSubstring = "UNTITLED /";
            offerData.set("autoUpdate", false);

            rootData.set("name", rootData.name.toUpperCase().startsWith(nameSubstring) ? productDetails.Name : rootData.name);
            offerData.set("seller.name", productDetails["Retailer Name"]);
            //number fields only accept integer values
            offerData.set("comparisonPrice", offerData.autoUpdate ? undefined : Math.round(productDetails["Sale Price"]));
            offerData.set("price", offerData.autoUpdate && Math.round(productDetails["Sale Price"]) ? Math.round(productDetails["Sale Price"]) : Math.round(productDetails.Price));
            if (!rootData.photosTout.length) {
              this.updateImage(imageUrl, fileName, rootData);
            }
            offerData.set("isOutOfStock", productDetails.isOutOfStock);

            this.analytics.track("offer_autofill", "create product offer on click of offer autofill button", productDetails);
          }
          this.set("loading", false);
        });

        function autofill() {
          return _ref.apply(this, arguments);
        }

        return autofill;
      }()
    }
  });
});