define("copilot/services/validation", ["exports", "@condenast/cross-check-dsl", "copilot/lib/brand-resolver", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _brandResolver, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  class Environment extends _copilotValidators.CopilotEnvironment {
    constructor(owner) {
      super();
      this.owner = owner;
    }

    get(object, key) {
      return Ember.get(object, key);
    }

    asList(value) {
      return value.toArray();
    }

    get activeBrand() {
      var brandService = this.owner.lookup("service:brand");
      return Ember.get(brandService, "activeBrand");
    }

    service(name) {
      // Allow for either featuresService or features
      if (name === "featuresService") {
        name = "features";
      }
      return this.owner.lookup("service:" + name);
    }

    descriptorFor(model, context) {
      var owner = this.owner;
      var type = Ember.get(model, "type");
      var brandService = this.owner.lookup("service:brand");
      var schemaToModel = this.owner.lookup("service:schemaToModel");
      var activeBrand = (0, _brandResolver.getResolverParentBrand)(Ember.get(brandService, "activeBrand"));
      var companyId = (0, _brandResolver.getResolverCompany)(this.activeBrand);
      var brandPath = "brands/" + activeBrand + "/" + type;
      var companyPath = "brands/" + companyId + "/" + type;
      var corePath = type;
      var validationBuilder = owner.lookup("validation:" + brandPath) || owner.lookup("validation:" + companyPath) || owner.lookup("validation:" + corePath);
      var schema = schemaToModel.schemaFor(type);
      var combinedBuilder = void 0;
      if (validationBuilder && schema && Object.keys(schema.members.members).length) {
        var registry = schemaToModel.get("registry");
        var draft = context === "save";
        combinedBuilder = validationBuilder.andAlso(schema.with({ strictKeys: false, draft: draft, registry: registry }).validation());
      } else {
        combinedBuilder = validationBuilder;
      }
      return combinedBuilder && (0, _crossCheckDsl.default)(combinedBuilder);
    }
  }

  /**
   * Adapter to map validation errors to the existing validationErrors model.
   * @param {*} model
   * @param {*} error
   * @param {Set} invalidModels A set to accumulate the invalid models
   * @param {Service} logger An instance of the logger service
   */
  function setValidationErrorsOnModels(model, error, invalidModels, logger) {
    if (error.message.name === "type" && ["string:single-word", "string:single-line"].includes(error.message.details)) {
      logger.warn("model validation failed but we are silencing it", {
        result: error,
        contentType: model.type,
        entityId: model.id
      });
    } else {
      invalidModels.add(model);
      addInvalidValue(model, error);
      model.get("validationErrors").add(error.path.join("."), error.message.name, error.message.details);

      var _ref = error.path || [],
          _ref2 = _toArray(_ref),
          relName = _ref2[0],
          relIndex = _ref2[1],
          path = _ref2.slice(2);

      if (relName && relIndex) {
        var index = parseInt(relIndex, 10);
        if (model.constructor.relationships.has(relName) && !isNaN(index)) {
          var child = model.get(relName).objectAt(index);

          if (child) {
            var childError = Object.assign({}, error, {
              path: path
            });
            setValidationErrorsOnModels(child, childError, invalidModels, logger);
          }
        }
      }
    }

    return invalidModels;
  }

  function addInvalidValue(model, error) {
    var invalidValue = void 0;
    var path = void 0;
    if (["save-duplicate-subchannels", "save-duplicate-templates"].indexOf(error.message.name) > -1) {
      error.path.splice(1, 1);
      path = error.path.join(".").concat(".value");
      invalidValue = model.get(path);
      if (invalidValue) {
        error.message.details = error.message.details || {};
        error.message.details["invalidValue"] = invalidValue;
        error.path = "";
      }
    }
  }

  function clearValidationErrors(model) {
    var clearedModels = [];
    var errors = model.validationErrors;
    if (errors && errors.length) {
      errors.clear();
      clearedModels.push(model);
    }

    var relationships = [].concat(_toConsumableArray((model.constructor.relationships || []).values()));
    return relationships.filter(function (rel) {
      return Ember.get(rel, "options.embedded") === "always";
    }).map(function (rel) {
      var value = model.get(rel.relationshipKey);
      if (value) {
        return Array.isArray(value) ? value : [value];
      } else {
        return [];
      }
    }).map(function (rels) {
      return rels.map(clearValidationErrors);
    }).reduce(function (acc, relChildModels) {
      return acc.concat(relChildModels);
    }, []).reduce(function (acc, relModels) {
      return acc.concat(relModels);
    }, clearedModels);
  }

  /**
   * A service that uses the extendable validations for validating models' properties.
   *
   * @module copilot/services/validation
   * @augments Ember.Service
   */

  exports.default = Ember.Service.extend({
    logger: Ember.inject.service(),
    schemaToModel: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      //We must pass an enviroment with `get` implemented as copilot-validators can be used serverside
      var env = new Environment(Ember.getOwner(this));

      this.set("env", env);
      this.set("validateModelDelegate", _copilotValidators.default);
    },


    /**
     *
     * @param {Model} model
     * @param {string} context Example: 'save' or 'publish'
     */
    validate: function validate(model, context) {
      var _this = this;

      return Ember.run(function () {
        clearValidationErrors(model).forEach(function (clearedModel) {
          clearedModel.notifyPropertyChange("validationErrors");
        });
        var promise = _this.validateModelDelegate(_this.env, model, context).then(function (results) {
          var logger = _this.logger;
          return Ember.run(function () {
            var invalidModels = results.reduce(function (models, error) {
              return setValidationErrorsOnModels(model, error, models, logger);
            }, new Set());

            invalidModels.forEach(function (modelWithErrors) {
              modelWithErrors.notifyPropertyChange("validationErrors");
            });
            return invalidModels.size === 0;
          });
        });
        return promise;
      });
    }
  });
});