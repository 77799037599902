define("copilot/models/person", ["exports", "copilot/models/base", "copilot/lib/copilot-computeds"], function (exports, _base, _copilotComputeds) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var eitherOr = _copilotComputeds.default.eitherOr;


  var NAME_COMPONENTS = ["honorificPrefix", "givenName", "additionalName", "familyName", "honorificSuffix"];

  var Person = _base.default.extend({
    displayName: eitherOr("alternateName", "fullName"),

    fullName: Ember.computed("honorificPrefix", "givenName", "additionalName", "familyName", "honorificSuffix", function () {
      var _this = this;

      return NAME_COMPONENTS.map(function (key) {
        return _this.get(key);
      }).join(" ");
    }),

    searchTitle: Ember.computed("name", "alternateName", "familyName", "givenName", function () {
      var name = this.name;
      var alternateName = this.alternateName;
      var familyName = this.familyName;
      var givenName = this.givenName;
      var constructedName = [givenName, familyName].filter(function (v) {
        return v;
      }).join(" ");
      return name || alternateName || constructedName;
    })
  });

  exports.default = Person;
});