define("copilot/brands/p4k/components/longform-link/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    channelsCategory: Ember.computed("model.{categories.channels.firstObject,categoriesChannels.firstObject}", function () {
      if (this.features.get("enableCategoryChannels")) {
        return this.model.categoriesChannels.firstObject;
      } else if (this.model.categories.channels) {
        return this.model.categories.channels.firstObject;
      }
    }),

    isFeature: Ember.computed.equal("channelsCategory.parent.slug", "features"),
    longformUrl: Ember.computed("model.longformLink.firstObject.url", function () {
      return this.get("model.longformLink.firstObject.url") + "?cp_id=" + this.model.id;
    }),

    actions: {
      convertToLongform: function convertToLongform() {
        var _this = this;

        this.set("isLoading", true);
        this.convertToLongform().finally(function () {
          if (_this.isDestroyed) {
            return;
          }
          _this.set("isLoading", false);
        });
      }
    }
  });
});