define("copilot/helpers/format-markdown", ["exports", "@atjson/renderer-hir", "@atjson/offset-annotations", "@atjson/source-commonmark"], function (exports, _rendererHir, _offsetAnnotations, _sourceCommonmark) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  class HTMLRenderer extends _rendererHir.default {
    *bold() {
      var html = yield;
      return "<strong>" + html.join("") + "</strong>";
    }
    *italic() {
      var html = yield;
      return "<em>" + html.join("") + "</em>";
    }
    *"line-break"() {
      yield;
      return "<br>";
    }
    *paragraph() {
      var html = yield;
      return html;
    }
    *root() {
      var html = yield;
      return html.join("");
    }
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        markdown = _ref2[0];

    var doc = _sourceCommonmark.default.fromRaw(markdown).convertTo(_offsetAnnotations.default);
    doc.where(function (a) {
      return a.type === "paragraph" && a.end < doc.content.length - 1;
    }).update(function (paragraph) {
      doc.addAnnotations(new _offsetAnnotations.LineBreak({
        start: paragraph.end - 1,
        end: paragraph.end
      }));
      doc.removeAnnotation(paragraph);
    });
    return Ember.String.htmlSafe(HTMLRenderer.render(doc));
  });
});