define("copilot/services/authorization", ["exports", "ember-inflector"], function (exports, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    userService: Ember.inject.service("user"),

    isAllowed: function isAllowed(action, type) {
      type = (0, _emberInflector.pluralize)(type);
      var permissions = this.get("userService.user.permissions") || {};
      return !!(permissions[type] && permissions[type].actions[action]);
    }
  });
});