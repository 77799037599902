define("copilot/validations/brands/cnt/rcalist", ["exports", "@condenast/cross-check-dsl", "copilot/validations/brands/cnt/list", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _list, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var rcalistValidators = (0, _crossCheckDsl.extend)(_list.default).catch((0, _crossCheckDsl.mutePath)(["channel"])).andAlso(object({
    year: (0, _copilotValidators.optional)((0, _copilotValidators.year)())
  }));

  exports.default = (0, _crossCheckDsl.default)(rcalistValidators);
});