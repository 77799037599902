define("copilot/adapters/application", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var omitTypes = ["agency", "bundle", "fashionshow", "fashiongallery", "gallery", "season"];

  exports.default = Ember.RESTAdapter.extend({
    features: Ember.inject.service(),

    saveRecord: function saveRecord(record) {
      var _this = this;

      if (omitTypes.find(function (type) {
        return type === record.type;
      })) {
        return this._super.apply(this, arguments);
      } else {
        var primaryKey = Ember.get(record.constructor, "primaryKey");
        var url = this.buildURL(record.constructor, Ember.get(record, primaryKey));
        var etag = record.etag;
        var header = { etag: etag };
        var jsonRecord = this.serializer.serialize(record);

        var options = {
          method: "PUT",
          url: url,
          data: JSON.stringify(jsonRecord),
          contentType: "application/json"
        };

        if (!record._forceSave && this.get("features.optimisticLocking")) {
          options.headers = header;
        }

        return (0, _icAjax.raw)(options).then(function (response) {
          var data = response.response;
          if (_this.get("features.optimisticLocking")) {
            etag = response.jqXHR.getResponseHeader("ETag");
            record.etag = etag;
          }
          _this.didSaveRecord(record, data);
          return record;
        }).catch(function (err) {
          throw err.jqXHR ? err.jqXHR.responseJSON || err.jqXHR : err;
        });
      }
    },

    find: function find(record, id) {
      var _this2 = this;

      var url = this.buildURL(record.constructor, id);
      var omittedType = omitTypes.find(function (type) {
        return type === record.type;
      });
      // For GET requests we always want to get the data
      // Setting this to an empty string prevents 304's
      return (0, _icAjax.raw)(url, {
        headers: { "if-none-match": "" }
      }).then(function (result) {
        if (_this2.get("features.optimisticLocking") && !omittedType) {
          var etag = result.jqXHR.getResponseHeader("ETag");
          record.etag = etag;
        }
        var data = result.response;
        _this2.didFind(record, id, data);
        return record;
      });
    }
  });
});