define("copilot/serializers/categories/passthrough", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Object.extend({
    /**
     * No-op as a default serializer.
     * @param {Object} output
     * @returns {Object}
     */
    serialize: function serialize(output) {
      return output;
    },


    /**
     * No-op as a default deserializer. To match behavior of the `map-to-array` serializer,
     * this will delete property keys if they are empty.
     * @param {Object} input
     * @returns {Promise} resolves to Object immediately.
     */
    deserialize: function deserialize(input) {
      return Object.entries(input).reduce(function (obj, _ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            root = _ref2[0],
            categories = _ref2[1];

        if (!Ember.isEmpty(categories)) {
          obj[root] = categories;
        }
        return obj;
      }, input.constructor());
    }
  });
});