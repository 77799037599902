define("copilot/routes/bundles/index", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route", "copilot/mixins/route-locking", "copilot/utils/bundleRedirect"], function (exports, _unsavedWarning, _authenticatedRoute, _routeLocking, _bundleRedirect) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, _routeLocking.default, {
    features: Ember.inject.service(),
    v2Url: Ember.computed.reads("configService.config.copilotV2Url"),
    currentBrand: Ember.computed.reads("brandConfig.brand.activeBrand"),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      var hasNewContainers = Ember.isPresent(controller.newContainers);
      controller.set("openModal", hasNewContainers);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        if ((0, _bundleRedirect.isV2Bundle)(this.features, model.bundleType)) {
          (0, _bundleRedirect.redirectBundle)(model, this.v2Url, this.currentBrand);
          return;
        }
        window.dataLayer[0].content.pageType = model.type + " | " + model.bundleType;
      }
    }
  });
});