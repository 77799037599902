define("copilot/schema-helpers/brands/vf/publish-uri-hook", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    compute: function compute(model) {
      var hierarchy = model.get("categoriesChannels.firstObject.hierarchy");
      return hierarchy && hierarchy[hierarchy.length - 2].slug;
    }
  });
});