define("copilot/brands/p4k/models/musicalbum", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var array = _attr.default.array,
      str = _attr.default.str;


  var MusicAlbum = _base.default.extend({
    type: "musicalbum",

    // model fields
    name: (0, _emberModel.attr)(str),
    byArtist: (0, _emberModel.hasMany)("musicgroup", {
      embedded: true
    }),
    disambiguatingDescription: (0, _emberModel.attr)(str),
    isCompilation: (0, _emberModel.attr)(Boolean),

    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    tags: (0, _emberModel.attr)(array),

    thirdPartyMedia: (0, _emberModel.hasMany)("third-party-media", {
      embedded: true
    }),
    searchTitle: Ember.computed.reads("name")
  });

  MusicAlbum.reopenClass({
    url: "/api/musicalbums"
  });

  exports.default = MusicAlbum;
});