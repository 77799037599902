define("copilot/lib/autocomplete/user-adapter", ["exports", "lodash-es", "copilot/lib/autocomplete/base-adapter"], function (exports, _lodashEs, _baseAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _lodashEs.default.extend({}, _baseAdapter.default, {
    search: function search(query) {
      return this.users.load().then(function (users) {
        return users.filter(function (user) {
          return user.get("fullName").toLowerCase().indexOf(query.term.toLowerCase()) >= 0;
        });
      });
    },
    transformResults: function transformResults(results) {
      var transformedResults = results.map(function (user) {
        return {
          text: user.get("fullName"),
          id: user.get("id")
        };
      });
      return transformedResults;
    },
    transformInitialValue: function transformInitialValue(value) {
      return new Ember.RSVP.Promise(function (resolve) {
        this.users.load().then(function (users) {
          var resultSet = value.map(function (text) {
            var target = _lodashEs.default.find(users, function (user) {
              return user.get("id") === text;
            });
            if (target) {
              return {
                id: text,
                text: target.get("fullName")
              };
            }
          });
          resolve(_lodashEs.default.compact(resultSet));
        });
      }.bind(this));
    },
    getValue: function getValue(data) {
      return data.val;
    },
    find: function find(collection, userId) {
      return _lodashEs.default.find(collection, function (id) {
        return id === userId;
      });
    },
    getInitialValue: function getInitialValue(selection) {
      return selection.join(",");
    }
  });
});