define("copilot/components/gallery-slide/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    photo: Ember.computed.reads("slide.embeddedPhoto"),

    slides: Ember.computed.reads("gallery.items"),

    slideIndex: Ember.computed("slides", "slide", function () {
      return this.slides.mapBy("id").indexOf(Ember.get(this, "slide.id"));
    }),

    slideNumber: Ember.computed("slideIndex", function () {
      return this.slideIndex + 1;
    }),

    totalSlideNumber: Ember.computed.reads("slides.length"),

    previousSlide: Ember.computed("slides", "slideIndex", function () {
      if (this.slideIndex > 0) {
        return this.slides.objectAt(this.slideIndex - 1);
      }
      return null;
    }),

    nextSlide: Ember.computed("slides", "slideIndex", function () {
      if (this.slideIndex < this.totalSlideNumber - 1) {
        return this.slides.objectAt(this.slideIndex + 1);
      }
      return null;
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.onviewportchange = function () {
        window.requestAnimationFrame(function () {
          _this.positionArrows(_this.element.querySelector(".previous-slide"), _this.element.querySelector(".next-slide"));
        });
      };

      ["DOMMMouseScroll", "mousewheel", "resize"].forEach(function (event) {
        window.addEventListener(event, _this.onviewportchange);
      });

      this.onviewportchange();
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      ["DOMMMouseScroll", "mousewheel", "resize"].forEach(function (event) {
        window.removeEventListener(event, _this2.onviewportchange);
      });
    },
    positionArrows: function positionArrows(previous, next) {
      var element = this.element;

      var _element$getBoundingC = element.getBoundingClientRect(),
          x = _element$getBoundingC.x,
          y = _element$getBoundingC.y,
          width = _element$getBoundingC.width,
          height = _element$getBoundingC.height;

      var arrow = previous.getBoundingClientRect();
      var top = Math.max((document.body.getBoundingClientRect().height - arrow.height) / 2, y + height / 2);
      previous.style.top = top + "px";
      next.style.top = top + "px";
      previous.style.left = x - arrow.width + 15 + "px";
      next.style.left = x + width - 15 + "px";
    }
  });
});