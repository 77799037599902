define("copilot/components/poster-thumbnail/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNames: ["thumbnail"],
    classNameBindings: ["isSelected:selected"],

    isSelected: Ember.computed("selected", function () {
      return this.selected === this.model;
    })
  });
});