define("copilot/components/search-slat/giphy-slat/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function secureUrl(key) {
    return Ember.computed(key, function () {
      return this.get(key).replace(/https?:/gi, "");
    });
  }

  /**
   * A component for the search result for a giphy item.
   *
   * @module components/search-result/giphy-result/component
   * @augments Ember.Component
   * @param {Object} result - result to display
   * @param {array} action-list - actions allowed on the item
   */
  exports.default = Ember.Component.extend({
    gif: secureUrl("result.images.fixed_height_small.url"),
    mp4: secureUrl("result.images.fixed_height_small.mp4"),

    date: Ember.computed("result.create_datetime", function () {
      return (0, _moment.default)(this.get("result.create_datetime"));
    })
  });
});