define("copilot/helpers/is-present", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.localIsPresent = localIsPresent;
  function localIsPresent(params) {
    var value = params[0];
    return Ember.isPresent(value);
  }

  exports.default = Ember.Helper.helper(localIsPresent);
});