define("copilot/models/embed-editor-content", ["exports", "copilot/models/embedded"], function (exports, _embedded) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _embedded.default.extend({
    url: "",
    cerosEmbed: "",
    width: "",
    height: "",
    service: "",
    type: null
  });
});