define("copilot/brands/bon/controllers/venues/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    socialMediaModel: Ember.computed(function () {
      return this.store.modelFor("social-media");
    })
  });
});