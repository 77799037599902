define("copilot/routes/infopages/index", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/seo"], function (exports, _authenticatedRoute, _seo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_seo.default, {
    controllerName: "articles/index",

    setupController: function setupController(controller, model) {
      controller.set("showSidebar", false);
      this._super(controller, model);
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      return this.runSeoScoring(model);
    }
  });
});