define("copilot/validations/brands/cni/nativearticle", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;
  exports.default = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).andAlso(object({
    advertiser: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    campaignUrl: (0, _copilotValidators.url)().on("publish")
  }));
});