define("copilot/components/search-slat/getty-card/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    styles: Ember.computed("size", function () {
      var height = this.size / 5 * 4;
      if (this.size == null) {
        return Ember.String.htmlSafe("");
      }
      return Ember.String.htmlSafe("height: " + height + "px");
    }),
    date: Ember.computed("result.createAt", function () {
      return (0, _moment.default)(this.get("result.createAt"));
    })
  });
});