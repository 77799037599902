define("copilot/brands/cnt/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    contentTypes: {
      activity: "Activity",
      airport: "Airport",
      bar: "Bar",
      hotel: "Hotel",
      restaurant: "Restaurant",
      ship: "Ship",
      shop: "Shop",
      skiresort: "Ski Resort"
    },
    fieldNames: {
      "address.street": "Street Name",
      "address.city": "City",
      "address.neighborhood": "Neighborhood",
      "address.state": "State",
      "address.postalCode": "Postal Code",
      "address.country": "Country",
      affiliateNetwork: "Affiliate Network",
      airportCode: "Airport Code",
      bars: "Bars",
      bookingPartner: "Booking Partner",
      bookingUrl: "Booking Url",
      cabins: "Cabins",
      cabinsWithBalconies: "Cabins With Balconies",
      cafes: "Cafés",
      campaignId: "Campaign ID",
      cheatSheet: "Cheat Sheet",
      city: "City",
      clubs: "Clubs",
      comment: "Comment",
      contributorsAuthor: "Authors",
      country: "Country",
      cruiseLine: "Cruise Line",
      cuisine: "Cuisine",
      currency: "Currency",
      distanceFromDowntownInMiles: "Distance from Downtown in Miles",
      dominantCarrier: "Dominant Carrier",
      editorsPick: "This is an editor's pick",
      endorsements: "Endorsements",
      featuredStays: "Featured Stays",
      featuredEats: "Featured Eats",
      featuredPlays: "Featured Plays",
      featuredShops: "Featured Shops",
      features: "Features",
      "geo.lat": "Latitude",
      "geo.lng": "Longitude",
      foursquarePlacesId: "Foursquare Places ID",
      geoTags: "Geo Tags",
      homeBase: "Home Base",
      hotelSpa: "Hotel Spa",
      independentSpa: "Independent Spa",
      hours: "Hours",
      mileageProgram: "Mileage Program",
      miniGuides: "Mini Guides",
      newsletterType: "Newsletter Type",
      numberOfLifts: "Number of Lifts",
      numberOfRuns: "Number of Runs",
      passengers: "Passengers",
      paymentsAccepted: "Payments Accepted",
      phoneNumber: "Phone Number",
      pinterest: "Pinterest",
      primaryLanguage: "Primary Language",
      rating: "Rating",
      "ratingBlock.activities": "Activities",
      "ratingBlock.design": "Design",
      "ratingBlock.food": "Food",
      "ratingBlock.location": "Location",
      "ratingBlock.overall": "Overall",
      "ratingBlock.rooms": "Rooms",
      "ratingBlock.service": "Service",
      region: "Region",
      reservations: "Reservations",
      restaurants: "Restaurants",
      rooms: "Number of Rooms",
      secondaryType: "Secondary Type",
      seoName: "SEO Name",
      shortBio: "Short Bio",
      shortDescription: "Short Description",
      size: "Size",
      smallestCabin: "Smallest Cabin",
      socialName: "Social Name",
      spaServices: "Spa Services",
      specialAdCode: "Special Ad Code",
      sportsOutdoorsFeatures: "Sports & Outdoors Features",
      starRating: "Star Rating",
      subtype: "Sub Type",
      swimmingPools: "Pools",
      timezone: "Time Zone",
      touts: "Tout Story",
      unicode: "Unicode",
      venue: "Venue",
      year: "Year"
    },
    fieldsetLegends: {
      address: "Address",
      advertisement: "Advertisement",
      contactInformation: "Contact Information",
      featureContent: "Feature Content",
      features: "Features",
      miscellaneous: "Miscellaneous",
      paymentInformation: "Payment Information",
      rating: "Rating",
      sections: "Sections",
      shortLinks: "Short Links"
    },
    placeholder: {
      reviews: {
        hed: "New Review"
      }
    }
  };
});