define("copilot/mixins/faux-dropdown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    dropdownOpen: false,

    init: function init() {
      this._super.apply(this, arguments);
      // Set up unique namespace to distinguish from other components
      // that mix in this mixin
      this.eventNamespace = Ember.guidFor(this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(document).off("click." + this.eventNamespace);
    },


    actions: {
      closeDropdown: function closeDropdown() {
        if (!this.isDestroyed) {
          this.set("dropdownOpen", false);
        }
      },
      toggleDropdown: function toggleDropdown() {
        var _this = this;

        if (!this.disabled) {
          this.toggleProperty("dropdownOpen");
          if (this.dropdownOpen) {
            Ember.$(document).one("click." + this.eventNamespace, function () {
              Ember.run(function () {
                if (_this.isDestroyed) {
                  return;
                }
                _this.send("closeDropdown");
              });
            });
          }
        }
      }
    }
  });
});