define("copilot/routes/livestories/index", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/route-locking", "copilot/mixins/unsaved-warning"], function (exports, _authenticatedRoute, _routeLocking, _unsavedWarning) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, _routeLocking.default, {
    model: function model(params) {
      return this.store.find("livestory", params.livestory_id);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        window.dataLayer[0].content.pageType = model.type;
      }
    }
  });
});