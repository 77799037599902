define("copilot/brands/vogue/controllers/seasons/edit", ["exports", "copilot/controllers/base", "ember-inflector", "copilot/brands/vogue/mixins/polaroid"], function (exports, _base, _emberInflector, _polaroid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _base.default.extend(_polaroid.default, {
    actions: {
      addShow: function () {
        var _ref = _asyncToGenerator(function* () {
          yield this.save();
          this.transitionToRoute("fashionshows.create", {
            queryParams: { season_id: this.model.get("id") }
          });
        });

        function addShow() {
          return _ref.apply(this, arguments);
        }

        return addShow;
      }()
    },

    inProgress: Ember.computed.reads("eventBus.progressing"),

    saveSuccess: function saveSuccess(controller) {
      return function (status) {
        if (status === "new") {
          var model = controller.model;
          var type = model.get("type");
          var route = (0, _emberInflector.pluralize)(type) + ".edit";
          controller.replaceRoute(route, model);
          Ember.run.schedule("afterRender", controller, controller.triggerSuccess);
        } else {
          controller.triggerSuccess();
        }
      }.bind(this);
    }
  });
});