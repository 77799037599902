define("copilot/services/brand", ["exports", "copilot/lib/cookie", "copilot/lib/brands-map"], function (exports, _cookie, _brandsMap) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    eventBus: Ember.inject.service(),

    activeBrand: Ember.computed("router.intendedPath", {
      get: function get() {
        var path = this.get("router.intendedPath") || "";
        var pathParts = path.split("/").filter(function (part) {
          return part !== "";
        });
        var brand = pathParts[0];
        var mappedBrand = _brandsMap.default[brand] ? brand : null;
        if (mappedBrand && mappedBrand !== _cookie.default.get("brand")) {
          _cookie.default.set({ brand: mappedBrand });
        }
        return mappedBrand;
      },
      set: function set(_, brand) {
        this.eventBus.ajaxSetup(brand);
        _cookie.default.set({ brand: brand });
        return brand;
      }
    })
  });
});