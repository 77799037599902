define("copilot/brands/vogue/models/article", ["exports", "copilot/models/base", "@condenast/copilot-slug"], function (exports, _base, _copilotSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    features: Ember.inject.service(),

    publishUriHook: Ember.computed("categories.{channels.firstObject,layout.firstObject}", "categoriesChannels.firstObject", function () {
      var basePath = this.get("features.enableCategoryChannels") ? "categoriesChannels" : "categories.channels";
      var depth = this.get(basePath + ".firstObject.depth");
      var firstDescendantChannelSlug = this.get(basePath + ".firstObject.hierarchy." + (depth - 1) + ".slug");

      if (firstDescendantChannelSlug === "vogueworld") {
        return (0, _copilotSlug.slugSafe)("vogueworld/article");
      }

      var hierarchy = this.get("categories.layout.firstObject.hierarchy");
      var isCuratedGridFancy = hierarchy && hierarchy.findBy("slug", "curated-grid-fancy");
      return isCuratedGridFancy ? "projects" : "article";
    })
  });
});