define("copilot/models/base", ["exports", "copilot/models/attr", "copilot/mixins/embeddable", "copilot/mixins/syndicatable", "copilot/mixins/validatable", "copilot/mixins/localizeable", "copilot/mixins/force-save", "ember-model"], function (exports, _attr, _embeddable, _syndicatable, _validatable, _localizeable, _forceSave, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var obj = _attr.default.obj,
      categories = _attr.default.categories;


  /**
   * The base model from which all other models inherit
   *
   * @module copilot/models/base
   * @augments Ember.Model
   * @mixes copilot/mixins/validatable
   * @mixes copilot/mixins/embeddable
   * @mixes copilot/mixins/publishable
   * @mixes copilot/mixins/relationable
   * @mixes copilot/mixins/syndicatable
   */
  var Base = _emberModel.Model.extend(_embeddable.default, _forceSave.default, _localizeable.default, _syndicatable.default, _validatable.default, {
    // api specific attributes
    id: (0, _emberModel.attr)(),
    publishHistory: (0, _emberModel.attr)(),
    createdAt: (0, _emberModel.attr)(),
    modifiedAt: (0, _emberModel.attr)(),
    revision: (0, _emberModel.attr)(_attr.default.revision),
    revisionAuthor: (0, _emberModel.attr)(),
    entityMetadata: (0, _emberModel.attr)(_attr.default.entityMetadata),
    backReferences: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    _embedded: Ember.computed.reads("_data._embedded"),
    queuedRecord: Ember.computed.reads("_embedded.queued"),
    currentlyPublishedRecord: Ember.computed.reads("_embedded.publishHistory"),
    publishable: Ember.computed.reads("meta.publishable"),
    thumbnail: Ember.computed.reads("_embedded.photosTout.firstObject.fields"),
    _links: (0, _emberModel.attr)(_attr.default.obj),
    meta: Ember.attr(_attr.default.meta),
    metadata: (0, _emberModel.attr)(_attr.default.metadata),
    subtype: null,
    beforeSaveHooks: Ember.A(),
    categories: (0, _emberModel.attr)(obj),
    categoriesTags: (0, _emberModel.attr)(categories),
    categoriesChannels: (0, _emberModel.attr)(categories),

    contributorTypeOptionsKey: Ember.computed("type", function () {
      return "contributorTypeOptions." + this.type;
    }),

    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    currentOrQueuedPublishRecord: Ember.computed.or("queuedRecord", "currentlyPublishedRecord"),
    // Alias class autogenerates metadata on instance for use
    // in templates, etc.
    autogenerates: Ember.computed.reads("constructor.metadata.autogenerates"),
    searchTitle: Ember.computed("constructor.metadata.searchTitlePath", function () {
      var path = Ember.get(this, "constructor.metadata.searchTitlePath");
      return this[path];
    }),

    afterSaveWorker: function afterSaveWorker(isNew, result) {
      if (Ember.isNone(result.get("id"))) {
        var error = new Error("create " + this.type + " failed (" + this.get("_data.error") + ")");
        Ember.onerror(error);
      }
      return isNew ? "new" : "";
    },
    toStringExtension: function toStringExtension() {
      return this.type + ":" + this.id;
    }
  });

  Base.reopenClass({
    polymorphicType: function polymorphicType(record) {
      return record.meta.modelName;
    },
    defaultAttributes: function defaultAttributes(draftName) {
      var possibleRequiredFields = void 0;
      var collectionName = this.metadata && this.metadata.collectionName;
      switch (collectionName) {
        case 'contributors':
          possibleRequiredFields = ["hed", "name"];
          break;
        case 'livestoryupdates':
          possibleRequiredFields = [];
          break;
        case 'redirects':
          possibleRequiredFields = ["target", "statusCode"];
          break;
        default:
          possibleRequiredFields = ["hed", "name", "title"];
      }

      var modelAttributes = Array.from(this.attributes.keys());

      return possibleRequiredFields.reduce(function (attributes, field) {
        if (modelAttributes.indexOf(field) !== -1) {
          if (field === 'statusCode') {
            attributes[field] = 301;
          } else {
            attributes[field] = draftName;
          }
        }
        return attributes;
      }, {});
    },


    duplicatable: Ember.computed.reads("metadata.duplicatable"),
    previewable: Ember.computed.reads("metadata.previewable")
  });

  exports.default = Base;
});