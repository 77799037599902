define("copilot/brands/cnt/models/activity", ["exports", "copilot/brands/cnt/models/venue", "ember-model", "copilot/models/attr"], function (exports, _venue, _emberModel, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  var str = _attr.default.str,
      obj = _attr.default.obj;


  var Activity = _venue.default.extend({
    editorsPick: attr(Boolean),
    endDate: attr(),
    features: attr(obj),
    hours: attr(),
    id: attr(),
    startDate: attr(),
    secondaryType: attr(str),
    subtype: attr(str),
    type: "activity",
    offers: (0, _emberModel.hasMany)("venue-offer", {
      embedded: "always"
    })
  });

  Activity.reopenClass({
    url: "/api/activities"
  });

  exports.default = Activity;
});