define("copilot/schema-helpers/autogen-geo", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Object.extend({
    location: Ember.inject.service(),
    dependentKeys: Object.freeze(["address.{street,streetExtended,city,locality,region,country,postalCode}"]),

    // Runs in context of model
    compute: function () {
      var _ref = _asyncToGenerator(function* (model) {
        var address = model.address;
        if (!address) {
          return this.location.emptyLocation;
        }

        return yield this.location.geocode(address);
      });

      function compute(_x) {
        return _ref.apply(this, arguments);
      }

      return compute;
    }()
  });
});