define("copilot/components/cp-typeahead/component", ["exports", "copilot-components/mixins/form-inputs"], function (exports, _formInputs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CpTypeahead = Ember.Component.extend(_formInputs.default, {
    classNames: ["cp-typeahead"],
    triggerComponent: "cp-typeahead/search-input"
  });

  CpTypeahead.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = CpTypeahead;
});