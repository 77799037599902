define("copilot/components/language-dropdown/language-row/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    eventBus: Ember.inject.service("event-bus"),
    intl: Ember.inject.service(),
    classNames: ["language-row"],

    canBeAdded: Ember.computed("language", function () {
      // Only other languages can be added
      return this.language.type === 'other';
    }),

    isAvailable: Ember.computed("language", function () {
      // Only current language and already translated languages can be archived
      return this.language.type === 'current' || this.language.type === 'translated';
    }),

    actions: {
      goToLanguage: function goToLanguage() {
        if (this.language.url) {
          window.open(this.language.url);
        }
      },
      addLanguage: function addLanguage() {
        var _this = this;

        this.eventBus.trigger("progress");

        var activeBrand = this.model.activeBrand;

        // Translate model
        this.model.translate(this.language.code).then(function (result) {
          // Open translated model page
          if (result.meta.collectionName && result.id) {
            window.open("/" + activeBrand + "/" + result.meta.collectionName + "/" + result.id);
          }

          _this.eventBus.trigger("success", {
            message: _this.intl.t("components.language-dropdown.messages.success"),
            priority: 3,
            status: "success"
          });

          // Reload language dropdown
          _this.reloadLanguages();
        }).catch(function () {
          this.eventBus.trigger("error", {
            message: this.intl.t("components.language-dropdown.messages.error"),
            priority: 1,
            status: "error"
          });
        });
      }
    }

  });
});