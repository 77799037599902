define("copilot/components/bulk-credit-editor/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ["bulk-credit-editor"],

    canEditContributors: false,

    patch: Ember.computed("canEditContributors", function () {
      return this.store.modelFactoryFor("photo").create();
    }),

    actions: {
      submit: function submit(photos, patch) {
        var patchData = patch.toJSON();
        var relationKeys = [].concat(_toConsumableArray(Ember.get(patch, "constructor.relationships") || [])).map(function (relationshipItem) {
          return relationshipItem[0];
        }).filter(function (name) {
          return name.startsWith("contributors");
        });
        var data = [].concat(_toConsumableArray(relationKeys), ["credit"]).reduce(function (acc, key) {
          acc[key] = Ember.get(patchData, key);
          return acc;
        }, {});

        return Ember.get(this, "submit")(photos, Object.assign({}, data, {
          relationKeys: relationKeys
        })).then(Ember.get(this, "dismiss"));
      }
    }
  });
});