define("copilot/mixins/modal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    title: null,
    translucentOverlay: true,

    teardown: Ember.on("willDestroyElement", function () {
      Ember.$("body").off("keyup.modal-dialog");

      // Must add class to support select2 dropdowns appearing properly in modals.
      // TODO: Remove when no longer using select2 v3.x
      Ember.run.later(function () {
        Ember.$("body").removeClass("modal-open");
      });
    })
  });
});