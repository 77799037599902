define("copilot/brands/glm/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fieldNames: {
      photosLede: "Header Image"
    }
  };
});