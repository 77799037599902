define("copilot/services/image-editor", ["exports", "@condenast/vulcan-cropmodes"], function (exports, _vulcanCropmodes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    previews: Ember.A(),
    find: function find(key) {
      var result = void 0;
      this.previews.forEach(function (preview) {
        if (preview.get("model.key") === key) {
          result = preview;
        }
      });
      return result;
    },
    getDefaultCropDimensions: function getDefaultCropDimensions(dimensions, desiredRatio) {
      var mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "TOP";

      var cropDimensions = _vulcanCropmodes.default.calculate(_vulcanCropmodes.default.modes[mode], dimensions, desiredRatio);
      return cropDimensions;
    }
  });
});