define("copilot/controllers/category-manager/category", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["start", "showArchived"],
    start: 0,
    showArchived: false,
    limit: 100,
    total: 0,
    filter: Ember.computed("start", "showArchived", "limit", "total", function () {
      return {
        start: this.get("start"),
        limit: this.get("limit"),
        display: this.get("showArchived") ? "all" : undefined,
        sort: "nameKeyword asc"
      };
    }),
    hierarchies: Ember.computed.reads("model.root.slug")
  });
});