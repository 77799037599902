define("copilot/controllers/reviews/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    // popup in -item-reviewed partial will appear if true
    active: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set("clickHandler", Ember.run.bind(this, this.handleOutsideClick));
      Ember.$(document).click(this.get("clickHandler"));
    },
    willDestroy: function willDestroy() {
      Ember.$(document).off("click", this.get("clickHandler"));
    },
    onOutsideClick: function onOutsideClick() {
      this.set("active", false);
    },


    // close -item-reviewed popup by clicking outside of it
    handleOutsideClick: function handleOutsideClick(event) {
      var active = this.get("active");

      var button = document.querySelector("button.inline-create");
      var $button = Ember.$(button);

      var popper = document.querySelector(".inline-create.popper");
      var $popper = Ember.$(popper);

      var $target = Ember.$(event.target);

      if (active && !$target.closest($popper).length && !$target.closest($button).length) {
        this.onOutsideClick();
      }
    },


    actions: {
      toggleActive: function toggleActive() {
        var active = this.get("active");
        this.set("active", !active);
      }
    }
  });
});