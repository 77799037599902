define("copilot/brands/cnt/models/hotlist", ["exports", "copilot/brands/cnt/models/list", "ember-model"], function (exports, _list, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Hotlist = _list.default.extend({
    type: "hotlist",
    region: (0, _emberModel.attr)(),
    year: (0, _emberModel.attr)(),
    touts: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    })
  });

  Hotlist.reopenClass({
    url: "/api/hotlists",
    metadata: {
      canEditRevisions: true
    }
  });

  exports.default = Hotlist;
});