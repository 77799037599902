define("copilot/schema-helpers/autogen-social-title", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["promoHed", "hed"]),

    // Runs in context of model
    compute: function compute(model) {
      return model.promoHed ? model.promoHed : model.hed;
    }
  });
});