define("copilot/brands/vogue/components/details-selector/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    loading: false,

    momentTout: Ember.computed.reads("moment.photosTout.firstObject"),
    selectedDetails: Ember.computed.filterBy("enabledDetails", "selected", true),
    enabledDetails: Ember.computed.filterBy("details", "disabled", false),

    details: Ember.computed("associatedDetailItems.[]", function () {
      var selectedDetails = this.moment.details.mapBy("id");
      return this.associatedDetailItems.map(function (detail) {
        return Ember.Object.create({
          data: detail.get("embedded"),
          selected: selectedDetails.indexOf(detail.id) !== -1,
          disabled: selectedDetails.indexOf(detail.id) !== -1
        });
      });
    }),

    actions: {
      submit: function submit() {
        var details = this.selectedDetails.map(function (detail) {
          return detail.get("data");
        });
        this.submit(details);
        this.dismiss();
      }
    }
  });
});