define("copilot/routes/search/index", ["exports", "copilot/routes/authenticated-route", "ic-ajax", "ember-service-methods/inject"], function (exports, _authenticatedRoute, _icAjax, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var NEWSLETTERS = "newsletter";

  exports.default = _authenticatedRoute.default.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    category: Ember.inject.service(),
    features: Ember.inject.service(),
    search: (0, _inject.default)(),
    archive: (0, _inject.default)(),
    unarchive: (0, _inject.default)(),

    activeBrand: Ember.computed.reads("brand.activeBrand"),

    queryParams: {
      query: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      types: {
        refreshModel: true
      },
      channel: {
        refreshModel: true
      },
      subchannel: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      display: {
        refreshModel: true
      },
      displayBrand: {
        refreshModel: true
      },
      dateRange: {
        refreshModel: true
      },
      collaborator: {
        refreshModel: true
      },
      contributor: {
        refreshModel: true
      },
      hierarchy: {
        refreshModel: true
      }
    },
    isUrl: function isUrl(query) {
      try {
        new URL(query);
        return true;
      } catch (_) {
        return false;
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);
      this.analytics.storyEvent("Search Opened", {
        searchType: "Copilot Search"
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },
    getSearchResults: function getSearchResults(params) {
      var _this = this;

      var parsedDates = void 0;

      // Temp fix for adding syndicated content to search without filter
      // ref: COPILOT-8926
      if (params.display == null) {
        params.display = "all";
        params.archived = false;
      }

      if (params._dates) {
        var _params$_dates$split = params._dates.split(","),
            _params$_dates$split2 = _slicedToArray(_params$_dates$split, 2),
            from = _params$_dates$split2[0],
            to = _params$_dates$split2[1];

        parsedDates = { from: from, to: to };
      }

      if (!params.archived && this.isUrl(params.query)) {
        // eslint-disable-next-line no-unused-vars
        var _params = params,
            archived = _params.archived,
            rest = _objectWithoutProperties(_params, ["archived"]);

        params = rest;
      }
      {
        return this.search(Object.assign(params, {
          limit: 50,
          dates: this.controllerFor("search.index").get("dates") || parsedDates
        })).catch(function (error) {
          if (error.status === 400) {
            _this.consoleLogger.error("Search API error", {
              caught: error
            });
            return [];
          }
          throw error;
        });
      }
    },
    groupByLoaded: function groupByLoaded(hierarchies) {
      var _this2 = this;

      return hierarchies.split(",").filter(Ember.isPresent).reduce(function (groups, hierarchy) {
        var category = _this2.controllerFor("search.index").categories[hierarchy];
        if (category) {
          groups.loaded.push(category);
        } else {
          groups.unloaded.push(hierarchy);
        }
        return groups;
      }, { loaded: [], unloaded: [] });
    },
    fetchByHierarchies: function fetchByHierarchies(hierarchies) {
      if (hierarchies.length) {
        var slug = hierarchies.map(function (path) {
          return path.split("/").lastObject;
        }).join(",");
        return this.category.search({
          hierarchy: hierarchies.join(","),
          slug: slug
        }).then(function (categories) {
          return categories.filter(function (category) {
            return hierarchies.find(function (hierarchyValue) {
              return hierarchyValue === category.hierarchyString;
            });
          });
        });
      } else {
        return Promise.resolve([]);
      }
    },
    getCategories: function getCategories(params) {
      if (!params.hierarchy) {
        return Promise.resolve([]);
      }

      var _groupByLoaded = this.groupByLoaded(params.hierarchy),
          loaded = _groupByLoaded.loaded,
          unloaded = _groupByLoaded.unloaded;

      return this.fetchByHierarchies(unloaded).then(function (newlyLoaded) {
        return [].concat(_toConsumableArray(loaded), _toConsumableArray(newlyLoaded));
      });
    },
    model: function model(params) {
      return Ember.RSVP.all([this.getSearchResults(params), this.getCategories(params)]);
    },
    setupController: function setupController(controller, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          model = _ref2[0],
          categories = _ref2[1];

      this._super(controller, model);

      //This will only set the timer once when entering the search.index route
      if (!this.isSearching) {
        this.set("startedSearchingAt", new Date());
      }
      controller.updateCategories(categories);
      controller.deserializeQueryParams();
    },
    resetController: function resetController(controller) {
      // Avoid controller reset when route change happened inside of search page.
      if (!this.isSearching) {
        controller.clearQueryParams();
      }
    },
    getResourceRoute: function getResourceRoute(resource) {
      var collectionName = resource.meta.collectionName;
      var editRoute = collectionName + ".edit";
      if (this.router.hasRoute(editRoute)) {
        return [editRoute, resource.id];
      } else if (this.router.hasRoute(collectionName)) {
        return [collectionName, resource.id];
      } else {
        return ["contentEdit", collectionName, resource.id];
      }
    },


    actions: {
      archive: function archive(result) {
        var _this3 = this;

        if (Array.isArray(result)) {
          return Ember.RSVP.all(result.map(function (item) {
            return _this3.archive(item);
          })).then(function () {
            _this3.controller.notifyPropertyChange("selection");
          });
        }
        return this.archive(result).then(function () {
          _this3.controller.notifyPropertyChange("selection");
        });
      },
      restore: function restore(result) {
        var _this4 = this;

        if (Array.isArray(result)) {
          return Ember.RSVP.all(result.map(function (item) {
            return _this4.unarchive(item);
          })).then(function () {
            _this4.controller.notifyPropertyChange("selection");
          });
        }
        return this.unarchive(result).then(function () {
          _this4.controller.notifyPropertyChange("selection");
        });
      },
      duplicate: function duplicate(result) {
        var _this5 = this;

        return (0, _icAjax.default)("/api/duplicate", {
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            href: result.get("_links.self.href")
          })
        }).then(function (data) {
          _this5.transitionTo.apply(_this5, _toConsumableArray(_this5.getResourceRoute(data)));
        });
      },
      preview: function preview(result) {
        var brand = this.brand.activeBrand;
        var type = result.type,
            id = result.id;

        var isNewsletter = (Ember.get(result, "bundleType") || "").toLowerCase().indexOf(NEWSLETTERS) > -1;

        var baseURL = !isNewsletter && this.get("features.v2Preview") && this.get("configService.config.copilotV2Url") || "";
        var url = baseURL + "/" + brand + "/preview/" + type + "/" + id;
        window.open(url, "Preview " + url);
        this.analytics.track("content", "previewed", {
          contentType: type
        });
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        window.dataLayer[0].content.pageType = "search";
      },


      // https://guides.emberjs.com/v2.18.0/routing/preventing-and-retrying-transitions/
      willTransition: function willTransition(transition) {
        this.analytics.storyEvent("Search Closed");

        var targetName = transition.targetName;
        this.set("isSearching", targetName === this.routeName);
      }
    }
  });
});