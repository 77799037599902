define("copilot/components/sortable-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "ul",
    showItems: false,

    axis: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var opts = {
        items: "> .sortable-item",
        distance: 5,
        cancel: ".sort-disabled"
      };

      if (this.axis) {
        opts.axis = this.axis;
      }

      if (this.handle) {
        opts.handle = this.handle;
      }

      if (!this.disableSorting) {
        ["start", "stop"].forEach(function (callback) {
          opts[callback] = Ember.run.bind(_this, callback);
        });

        this.$().sortable(opts);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (!this.disableSorting) {
        try {
          this.$().sortable("destroy");
        } catch (e) {
          // if not initialized, sortable throws an error
        }
      }
    },
    start: function start(event, ui) {
      ui.item.data("oldIndex", ui.item.index());
    },
    stop: function stop(event, ui) {
      var _this2 = this;

      var oldIndex = ui.item.data("oldIndex");
      var newIndex = ui.item.index();
      Ember.run.later(this, function () {
        // Hack to ensure re-rendering after sorting so computed props
        // on components in yielded block are recomputed (e.g. position)
        _this2.toggleProperty("showItems");
      }, 50);
      this.updateSortOrder(oldIndex, newIndex);
    }
  });
});