define("copilot/components/date-range-selector/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),

    _ceilEndDate: function _ceilEndDate() {
      var d = new Date(this.endDate);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      d.setHours(24);
      d.setMilliseconds(d.getMilliseconds() - 1);
      return d.toISOString();
    },
    _floorStartDate: function _floorStartDate() {
      var d = new Date(this.startDate);
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      return d.toISOString();
    },


    actions: {
      closeModal: function closeModal() {
        if (!this._isSubmitting) {
          this.modalController.closeHandler();
        }
        this._isSubmitting = false;
        this.applicationController.send("closeModal");
      },
      submit: function submit() {
        var dates = {
          from: this._floorStartDate(),
          to: this._ceilEndDate()
        };
        this.submit(dates);
      }
    }
  });
});