define("copilot/services/search-analytics", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // @condenast/copilot-analytics:analytics
    analytics: Ember.inject.service(),

    /**
     * Understand how often users are searching with the collaborators selector.
     * @param {Object} props
     * @param {String} props.collaborators - list of collaborators' id
     * @param {String} props.query - search query string
     */
    trackCollaboratorFilter: function trackCollaboratorFilter(_ref) {
      var collaborators = _ref.collaborators,
          query = _ref.query;

      this.analytics.track("search", "executed", {
        searchType: "collaborator-filter",
        query: query,
        collaborator: collaborators
      }, {
        label: "searchType"
      });
    }
  });
});