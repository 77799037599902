define("copilot/services/unarchive", ["exports", "ember-service-methods/inject"], function (exports, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setArchiveStatus: (0, _inject.default)(),

    execute: function execute(model) {
      return this.setArchiveStatus(model, false);
    }
  });
});