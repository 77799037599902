define("copilot/components/dashboard-list/component", ["exports", "ember-service-methods/inject", "lodash-es"], function (exports, _inject, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EXCLUDE_TYPES = "externallink,photo,cnevideo,pushnotification,categories";

  exports.default = Ember.Component.extend({
    classNameBindings: ["type", "gridItemClass"],
    items: null,
    limit: 10,
    refreshInterval: null,
    moreItems: false,

    configService: Ember.inject.service("config"),
    userService: Ember.inject.service("user"),
    intl: Ember.inject.service(),
    userId: Ember.computed.reads("userService.user.id"),
    activeBrandSlug: Ember.computed.reads("configService.activeBrandSlug"),
    isPhotoModule: Ember.computed.equal("type", "photos"),
    search: (0, _inject.default)(),

    // The following computed property is required because for some reason after
    // upgrading to Ember 2.15 the hbs below raised an error in the hbs module
    // transform. This should be put back in the template (and the computed below
    // removed) once this is properly supported.
    // {{t 'currentlyNoItems'
    //   type=(t (concat 'components.dashboard-list.types.' type))}}
    translatedType: Ember.computed("type", function () {
      var type = this.type;
      return this.intl.t("components.dashboard-list.types." + type);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("items", []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.fetchData();
    },


    showMoreLink: Ember.computed("moreItems", "type", function () {
      var type = this.type;
      return type !== "performance" && this.moreItems;
    }),

    listItemComponent: Ember.computed("type", function () {
      var itemComponents = {
        photos: "photo-item"
      };
      var type = this.type;
      var itemComponent = itemComponents[type] || "list-item";

      return "dashboard-list/" + itemComponent;
    }),

    dateRange: Ember.computed("type", function () {
      switch (this.type) {
        case "published":
          return "to-now";
        case "scheduled":
          return "from-now";
        default:
          return null;
      }
    }),

    searchParams: Ember.computed("type", function () {
      var type = this.type;
      var defaultParams = {
        status: null,
        types: null,
        nottypes: null,
        dateRange: null,
        display: null,
        collaborator: ""
      };
      var params = void 0;

      switch (type) {
        case "scheduled":
          params = {
            nottypes: EXCLUDE_TYPES,
            status: "published",
            dateRange: "from-now",
            sort: "publishdate asc"
          };
          break;
        case "modified":
          params = {
            nottypes: EXCLUDE_TYPES,
            status: "draft",
            sort: "revisiondate desc"
          };
          break;
        case "published":
          params = {
            nottypes: EXCLUDE_TYPES,
            status: "published",
            sort: "revisiondate desc"
          };
          break;
        case "syndication":
          params = {
            nottypes: ["external"].concat(EXCLUDE_TYPES),
            display: "issyndicatee",
            sort: "publishdate desc"
          };
          break;
        case "photos":
          // eslint-disable-next-line ember/no-side-effects
          this.set("limit", 14);
          params = {
            types: "photo"
          };
          break;
        default:
          params = {
            nottypes: EXCLUDE_TYPES
          };
          break;
      }

      params.limit = this.limit;

      if (this.filterUser) {
        params.collaborator = this.userId;
      }

      return _lodashEs.default.merge(defaultParams, params);
    }),

    fetchData: function fetchData() {
      var _this = this;

      Ember.run.later(function () {
        _this.set("inProgress", true);

        _this.search(_this.searchParams).then(function (results) {
          return _this.setSearchDataItems(results);
        });
      });
    },
    setSearchDataItems: function setSearchDataItems(data) {
      this.setProperties({
        items: data.hits,
        inProgress: false,
        moreItems: data.totalHits > this.limit
      });
    },


    actions: {
      onfileadd: function onfileadd(file) {
        var _this2 = this;

        return this.onfileadd(file).then(function (photo) {
          _this2.items.unshiftObject(photo);
          return photo;
        });
      }
    }
  });
});