define("copilot/brands/cni/components/seo-fields/component", ["exports", "copilot/components/seo-fields/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    showCanonicalURL: Ember.computed("model.type", function () {
      return ["article", "gallery"].includes(Ember.get(this, "model.type"));
    })
  });
});