define("copilot/serializers/category", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var hash = this._super.apply(this, arguments);
      if (!hash.parent) {
        hash.parent = [];
      } else if (!Array.isArray(hash.parent)) {
        hash.parent = [hash.parent];
      }
      return hash;
    },
    normalize: function normalize(classType, hash) {
      var parentExists = hash.parent && hash.parent.length > 0;
      if (parentExists) {
        var current = hash;
        var parent = current.parent;

        while (parent) {
          if (Array.isArray(parent)) {
            current.parent = parent[0];
          }
          current = current.parent;
          parent = current.parent;
        }
      } else {
        var hierarchy = hash._embedded && hash._embedded.hierarchy.slice() || [];
        var _parent = null;

        while (hierarchy.length > 1) {
          var category = hierarchy.pop().fields;
          category.parent = _parent;
          _parent = category;
        }

        hash.parent = _parent;
      }

      return this._super.apply(this, arguments);
    }
  });
});