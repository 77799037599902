define("copilot/components/column-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["dropdown-columns"],
    itemsPerColumn: null,

    columns: Ember.computed("data", function () {
      var itemsPerColumn = this.itemsPerColumn || 6;
      var data = this.data;
      var newArray = [];

      for (var i = 0; i < data.length; i += itemsPerColumn) {
        newArray.push(data.slice(i, Math.min(i + itemsPerColumn, data.length)));
      }
      return newArray;
    })
  });
});