define("copilot/components/unlock-confirmation-dialog/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["unlock-confirmation-dialog"],
    actions: {
      takeOver: function takeOver() {
        var _this = this;

        this.set("inProgress", true);
        this.takeOver().then(function () {
          _this.dismiss();
        }).finally(function () {
          _this.set("inProgress", false);
        });
      }
    }
  });
});