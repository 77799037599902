define("copilot/serializers/nativegallery", ["exports", "copilot/serializers/gallery"], function (exports, _gallery) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // To create a relationship edge we only need to send the id and meta information
  function getRequiredRelFields(rel) {
    return {
      id: rel.id,
      meta: rel.meta
    };
  }

  exports.default = _gallery.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      var firstPhoto = json.items[0] && json.items[0].embedded;
      // Use the first photo in gallery as tout photo relation if "photosTout" is not specified
      if (Ember.isEmpty(json.photosTout) && firstPhoto) {
        json.photosTout = [getRequiredRelFields(firstPhoto)];
      }
      return json;
    }
  });
});