define("copilot/components/seo-fields/component", ["exports", "lodash-es", "ic-ajax", "ember-concurrency"], function (exports, _lodashEs, _icAjax, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEBOUNCE_MS = 500;

  exports.default = Ember.Component.extend({
    classNames: ["seo-fields"],
    showCanonicalURL: Ember.computed("model.contentSource", function () {
      var sourcesRequiringCanonicalURL = ["external_partner", "licensed_partner", "quanta_magazine"];
      var sourcesExcludingCanonicalURL = ["digital_syndication", "vogue_international"];
      var contentSource = this.get("model.contentSource");
      return sourcesRequiringCanonicalURL.includes(contentSource) && !sourcesExcludingCanonicalURL.includes(contentSource);
    }),
    analytics: Ember.inject.service(),
    intl: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    selectedCategory: null,
    companyId: Ember.computed("configService.config.companyId", function () {
      return this.get("configService.config.companyId") || "cn";
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("selectedCategory", this.get("model.seo.category"));
    },


    search: (0, _emberConcurrency.task)(function* (term) {
      var seoLocale = this.get("configService.config.seo.semRushLocale");
      var nonAPLocale = seoLocale !== "jp" && seoLocale !== "hk";
      if (_lodashEs.default.words(term).length < 2 && nonAPLocale) {
        this.set("noMatchesMessage", this.intl.t("helperText.seoTypeahead.wordThreshold"));
        return [];
      }
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      var results = yield this._performSearch.perform(term);
      var emptySuggestions = _lodashEs.default.isEmpty(results);
      this.set("hasSuggestions", !emptySuggestions);
      if (!emptySuggestions) {
        return results;
      }
      this.set("noMatchesMessage", this.intl.t("helperText.seoTypeahead.noSuggestions"));
      return [];
    }).restartable(),

    _performSearch: (0, _emberConcurrency.task)(function* (term) {
      try {
        var category = this.selectedCategory;
        var url = "/api/seo/" + category + "/" + term;
        var results = yield (0, _icAjax.default)(url);
        return results;
      } catch (e) {
        this.consoleLogger.error("SEO autosuggest", {
          caught: e
        });
      }
    }),

    actions: {
      seoCategoryChanged: function seoCategoryChanged(val) {
        this.set("selectedCategory", val);
        this.set("model.seoKeyword", "");
        var modelSeoField = this.get("model.seo");
        if (modelSeoField) {
          this.set("model.seo.category", val);
          this.set("model.seo.keyword", "");
        }

        // Understand if user has changed the SEO category
        this.analytics.track("seo-category", "changed", {
          value: val,
          contentType: Ember.get(this, "model.type")
        }, {
          label: "value"
        });
      },
      close: function close() {
        this.set("hasSuggestions", false);
      },
      change: function change(term) {
        this.set("model.seoKeyword", Ember.get(term, "keyword") || term);
        var seoField = this.get("model.seo");
        if (seoField) {
          this.set("model.seo.keyword", Ember.get(term, "keyword") || term);
        }
      }
    }
  });
});