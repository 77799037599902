define("copilot/components/category-manager/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    user: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ["category-manager"],
    breadcrumbs: Ember.computed("model", "model.ancestors", function () {
      var ancestors = this.get("model.ancestors");
      var breadcrumbs = ancestors ? ancestors.slice().reverseObjects() : [];
      if (this.user.isSuperAdmin && this.model.id != null) {
        breadcrumbs = [{ id: "roots", name: this.intl.t("category-manager.all-roots") }].concat(_toConsumableArray(breadcrumbs));
      }
      return breadcrumbs;
    })
  });
});