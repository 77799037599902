define("copilot/components/search-slat/-private/thumbnail/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    size: "small",

    imageSize: Ember.computed("size", function () {
      switch (this.size) {
        case "x-small":
          return 48;
        case "small":
          return 64;
        default:
          return 500;
      }
    })
  });
});