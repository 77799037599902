define("copilot/controllers/presets/index", ["exports", "ember-service-methods", "ember-concurrency", "lodash-es", "moment"], function (exports, _emberServiceMethods, _emberConcurrency, _lodashEs, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Controller.extend({
    search: (0, _emberServiceMethods.inject)(),
    queryParams: ["start", "sort", "order"],
    start: 0,
    limit: 20,
    sort: "hed",
    order: "asc",

    filter: Ember.computed("start", function () {
      return {
        start: this.get("start"),
        limit: this.get("limit"),
        hierarchy: "copilot/preset",
        nottypes: "category",
        notcategory: ""
      };
    }),

    searchForPresets: (0, _emberConcurrency.task)(function* () {
      var _this = this;

      var filter = this.get("filter");
      var results = yield this.search(filter);

      var _ref = results || {},
          _ref$totalHits = _ref.totalHits,
          totalHits = _ref$totalHits === undefined ? 0 : _ref$totalHits;

      var possibleRequests = Math.ceil(totalHits / this.limit);
      var requestArray = yield Promise.all(new Array(possibleRequests).fill(0).map(function () {
        var _ref2 = _asyncToGenerator(function* (dontCare, i) {
          var baseFilter = _this.filter;
          var adjustedOptions = { start: i * _this.limit };
          var newFilter = Object.assign({}, baseFilter, adjustedOptions);

          return (yield _this.search(newFilter)).hits;
        });

        return function (_x, _x2) {
          return _ref2.apply(this, arguments);
        };
      }()));
      var flattenAllTheData = requestArray.flat();
      // Sort stuff based an arbitary heading here
      var sortedData = flattenAllTheData.sort(function (preset1, preset2) {
        var isPropADate = _this.sort === "lastModified";
        var sort = isPropADate ? "modifiedAt" : _this.sort;
        var prop1 = preset1.get(sort);
        var prop2 = preset2.get(sort);
        var shouldNegateCompare = _this.order === "asc" ? 1 : -1;
        if (isPropADate) {
          var dateCompare = (0, _moment.default)(prop1).isBefore((0, _moment.default)(prop2));
          var compare = dateCompare ? 1 : -1;
          return compare * shouldNegateCompare;
        } else {
          return prop1.localeCompare(prop2) * shouldNegateCompare;
        }
      });
      var currentPage = Math.floor(this.start / 20);
      var pageResult = (0, _lodashEs.chunk)(sortedData, this.limit)[currentPage];
      var result = {
        presets: pageResult,
        total: totalHits
      };

      return result;
    }).restartable(),

    calculateNewSort: function calculateNewSort(newsort) {
      var currentsort = this.sort;
      var areSortsTheSame = newsort === currentsort;
      var neworder = this.order === "asc" ? "desc" : "asc";

      if (areSortsTheSame) {
        return { sort: newsort, order: neworder };
      }

      return { sort: newsort, order: "asc" };
    },


    actions: {
      fetchPresets: function fetchPresets() {
        this.searchForPresets.perform();
      },
      handlePaginationClick: function handlePaginationClick(newStartValue) {
        this.set("start", newStartValue);
        this.searchForPresets.perform();
      },
      handleTableHeaderClick: function handleTableHeaderClick(sort) {
        var newSort = this.calculateNewSort(sort);
        this.set("sort", newSort.sort);
        this.set("order", newSort.order);
        this.searchForPresets.perform();
      }
    }
  });
});