define("copilot/components/geo-field/component", ["exports", "copilot/components/geo-field/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ["geo-field"]
  });
});