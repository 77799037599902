define("copilot/brands/cni/models/bundle-search", ["exports", "copilot/models/bundle-search", "copilot/models/attr"], function (exports, _bundleSearch, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var additionalFilters = _attr.default.additionalFilters;


  var BundleSearch = _bundleSearch.default.extend({
    // This field has one or more language code used as curated search filters
    lang: additionalFilters("additionalFilters", "lang")
  });

  exports.default = BundleSearch;
});