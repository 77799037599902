define("copilot/brands/p4k/models/musicrecording", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;
  exports.default = _base.default.extend({
    type: "musicrecording",

    // model fields
    name: (0, _emberModel.attr)(str),
    disambiguatingDescription: (0, _emberModel.attr)(str),
    releaseDate: (0, _emberModel.attr)(),
    byArtist: (0, _emberModel.hasMany)("musicgroup", {
      embedded: true
    }),
    publisher: (0, _emberModel.hasMany)("brand", {
      embedded: true
    }),
    thirdPartyMedia: (0, _emberModel.hasMany)("third-party-media", {
      embedded: "always"
    }),
    searchTitle: Ember.computed.reads("name")
  }).reopenClass({
    url: "/api/musicrecordings"
  });
});