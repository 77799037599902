define("copilot/schema-helpers/autogen-serving-size-description", ["exports", "ember-inflector", "copilot/lib/copilot-markdown"], function (exports, _emberInflector, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["servingSizeUnit", "servingSizeRangeFrom", "servingSizeRangeTo"]),
    compute: function compute(model) {
      var rangeFrom = model.servingSizeRangeFrom;
      var rangeTo = model.servingSizeRangeTo;
      var unit = model.servingSizeUnit;
      if (rangeFrom && unit) {
        var compactedRange = [rangeFrom, rangeTo].filter(function (x) {
          return !!x;
        });
        var range = compactedRange.join("–");
        // Singularize unit because there is a pluralized option
        // for each singularized option
        unit = (0, _emberInflector.singularize)(unit);

        var quantityForPluralization = compactedRange[1] || compactedRange[0];
        var formattedUnit = (0, _emberInflector.pluralize)(quantityForPluralization, unit, {
          withoutCount: true
        });

        return _copilotMarkdown.default.sanitize(range + " " + formattedUnit);
      }
    }
  });
});