define("copilot/serializers/product", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.offers = json.offers.map(function (item) {
        if (item.seller.name === "") {
          delete item.seller;
        }
        return item;
      });
      return json;
    }
  });
});