define("copilot/services/user", ["exports", "copilot/lib/cookie", "ic-ajax", "copilot/models/user"], function (exports, _cookie, _icAjax, _user) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    analytics: Ember.inject.service(),
    consoleLogger: Ember.inject.service(),
    brand: Ember.inject.service(),

    user: null,

    isSuperUser: Ember.computed.gt("user.brands.length", 1),
    isSuperAdmin: Ember.computed.equal("user.role", "super-admin"),
    isAdmin: Ember.computed.equal("user.role", "admin"),
    isOktaUser: Ember.computed.equal("user.loginProvider", "okta"),

    isAuthenticated: Ember.computed("user", function () {
      return !!this.user;
    }),

    /**
     * The brand code corresponding to the currently active brand
     *
     * @example
     *   "gd"
     *
     * @type {String}
     */
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    activeBrandId: Ember.computed("activeBrand", function () {
      return this.get("user.brandsMap")[this.activeBrand].id;
    }),

    fullName: Ember.computed.reads("user.fullName"),

    /**
     * Loads the user instance from the API
     *
     * @function
     * @returns {undefined}
     * */
    load: function load() {
      var _this = this;

      if (this.user) {
        return Ember.RSVP.Promise.resolve(this.user);
      }
      return (0, _icAjax.default)("/auth/instance").then(function (user) {
        _this.set("user", _user.default.create(user));
        return user;
      });
    },


    /**
     * Destroys the users session via the API and removes associated cookies
     *
     * @function
     * @returns {undefined}
     * */
    performLogout: function performLogout() {
      return (0, _icAjax.default)("/auth/logout").then(function () {
        _cookie.default.remove("brand", "cp.username", "cp.role", "cp.userid");
      });
    },
    setLocale: function setLocale(locale) {
      this.user.set("locale", locale);
    }
  });
});