define("copilot/services/analytics", ["exports", "copilot/lib/cookie", "copilot/lib/analytics/story", "copilot/lib/analytics/intercom-adapter", "copilot/lib/analytics/segment-adapter"], function (exports, _cookie, _story, _intercomAdapter, _segmentAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _setupStories(analytics) {
    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Search Opened", "Search Closed"), (0, _story.event)("Search Executed"), (0, _story.event)("Search Result In View")],
      trackingCallback: function trackingCallback(_ref) {
        var searchType = _ref.searchType,
            searchQuery = _ref.searchQuery,
            result = _ref.result,
            resultIndex = _ref.resultIndex;

        analytics.track("search result", "in view", {
          "Event Target": searchType,
          "Content ID": Ember.get(result, "id"),
          Query: searchQuery,
          Position: resultIndex + 1
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Publish Started"), (0, _story.event)("Publish Date Changed"), (0, _story.event)("Publish Abandoned")],
      trackingCallback: function trackingCallback(_ref2) {
        var unpublishedRelationships = _ref2.unpublishedRelationships,
            pubDate = _ref2.pubDate,
            unpublishedRelationshipsLeft = _ref2.unpublishedRelationshipsLeft;

        analytics.track("content", "abandoned_publish", {
          "Draft Warnings": unpublishedRelationships,
          "Draft Warnings Left": unpublishedRelationshipsLeft,
          "Publish Date": pubDate
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Publish Started"), (0, _story.event)("Publish Date Changed"), (0, _story.event)("Publish Finished")],
      trackingCallback: function trackingCallback(_ref3) {
        var unpublishedRelationships = _ref3.unpublishedRelationships,
            pubDate = _ref3.pubDate,
            unpublishedRelationshipsLeft = _ref3.unpublishedRelationshipsLeft;

        analytics.track("content", "published", {
          "Draft Warnings": unpublishedRelationships,
          "Draft Warnings Left": unpublishedRelationshipsLeft,
          "Publish Date": pubDate
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Search Opened", "Search Closed"), (0, _story.event)("Search Executed"), (0, _story.event)("Search Result Selected")],
      trackingCallback: function trackingCallback(_ref4) {
        var searchType = _ref4.searchType,
            searchQuery = _ref4.searchQuery,
            result = _ref4.result,
            resultIndex = _ref4.resultIndex;

        analytics.track("search result", "click", {
          "Event Target": searchType,
          "Content ID": Ember.get(result, "id"),
          Query: searchQuery,
          Position: resultIndex + 1
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Search Opened", "Search Closed"), (0, _story.event)("Search Executed")],
      trackingCallback: function trackingCallback(_ref5) {
        var searchType = _ref5.searchType,
            searchQuery = _ref5.searchQuery,
            searchResultCount = _ref5.searchResultCount,
            searchPageSize = _ref5.searchPageSize;

        analytics.track("search", "search", {
          "Event Target": searchType,
          Query: searchQuery,
          "Results Returned": searchResultCount,
          "Pages Returned": Math.ceil(searchResultCount / searchPageSize)
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Search Opened", "Search Closed")],
      trackingCallback: function trackingCallback(_ref6) {
        var searchType = _ref6.searchType;

        analytics.track("search form", "impression", {
          "Event Target": searchType
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Search Opened", "Search Closed"), (0, _story.event)("Search Form Focused")],
      trackingCallback: function trackingCallback(_ref7) {
        var searchType = _ref7.searchType;

        analytics.track("search form", "focus", {
          "Event Target": searchType
        });
      }
    }));

    analytics.trackStory(new _story.Story({
      events: [(0, _story.event)("Photo Card Asset Finder Open", "Search Closed"), (0, _story.event)("Search Result Selected"), (0, _story.event)("Photo Card Asset Rendered")],
      trackingCallback: function trackingCallback(_ref8) {
        var resultIndex = _ref8.resultIndex,
            result = _ref8.result,
            renderedPhoto = _ref8.renderedPhoto;

        var selectedPhoto = result;
        var selectedPhotoIndex = resultIndex;
        if (selectedPhoto.id !== renderedPhoto.id) {
          analytics.track("asset", "mismatch detected", {
            selectedPhotoIndex: selectedPhotoIndex,
            selectedPhoto: selectedPhoto,
            renderedPhoto: renderedPhoto
          });
        }
      }
    }));
  }

  exports.default = Ember.Service.extend({
    logger: Ember.inject.service(),
    features: Ember.inject.service(),
    timespentMetadata: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.eventStories = [];

      _setupStories(this);

      if (Ember.isTesting) {
        return;
      }
    },
    setup: function setup() {
      if (window.analytics) {
        this.segmentAdapter = _segmentAdapter.default;
      }

      if (window.Intercom) {
        this.intercomAdapter = _intercomAdapter.default;
      }
    },
    trackStory: function trackStory(story) {
      this.eventStories.push(story);
    },
    untrackStory: function untrackStory(story) {
      var i = this.eventStories.indexOf(story);
      if (i > -1) {
        this.eventStories.splice(i, 1);
      }
    },
    storyEvent: function storyEvent(event, data) {
      if (!this.registeredEvents.includes(event)) {
        // eslint-disable-next-line no-console
        console.warn("No stories exist for story event '" + event + "'. Is it spelled correctly?");
      }

      this.eventStories.forEach(function (story) {
        story.send(event, data);
      });
    },


    registeredEvents: Ember.computed("eventStories.[]", function () {
      var _ref9;

      return (_ref9 = []).concat.apply(_ref9, _toConsumableArray(this.eventStories.map(function (story) {
        return story.getEvents();
      })));
    }),

    adapters: Ember.computed.collect("segmentAdapter", "intercomAdapter"),

    callAdapters: function callAdapters(method) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this.adapters.forEach(function (adapter) {
        if (adapter) {
          var implementation = adapter[method];
          implementation.call.apply(implementation, [adapter].concat(_toConsumableArray(args)));
        }
      });
    },


    /**
     * Identify the user that is currently signed in
     *
     * @param {Object} user - Required. The user model to identify.
     */
    identify: function identify(user) {
      var payload = {
        username: user.uid,
        firstName: user.firstName,
        lastName: user.lastName,
        name: user.firstName + " " + user.lastName,
        email: user.email,
        role: user.role,
        company: {
          id: _cookie.default.get("brand"),
          name: _cookie.default.get("brand")
        },
        createdAt: user.createdAt
      };

      this.callAdapters("identify", user._id, payload);
    },


    /**
     * Tracks a page change
     */
    page: function page() {
      this.callAdapters("page");
    },


    /**
     * Track an event in analytics. The objectName and action should combine to make an
     * understandable statement. e.g. object actioned, search started, content archived
     *
     * @param {string} objectName - Required. The object on which the event occurred e.g. user
     * @param {string} action - Required. The action that happened, in past tense. e.g. created
     * @param {Object} properties - Supporting properties related to the event e.g. page: 2
     * @param {Object} options - Segment options. See segment documentation
     */
    track: function track(objectName, action) {
      var properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      properties.brand = _cookie.default.get("brand");
      properties.environment = _cookie.default.get("env");
      properties.username = properties.username || _cookie.default.get("cp.username");

      (false && !(objectName && action) && Ember.assert("Pass a noun and verb to collect information on,\nFor example `track(\"user\", \"created\")` or `track(\"photo\", \"uploaded\")`", objectName && action));


      objectName = objectName.toLowerCase();
      action = action.toLowerCase();

      this.callAdapters("track", objectName, action, properties, options);
    }
  });
});