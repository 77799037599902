define("copilot/routes/presets/edit", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/unsaved-warning", "copilot/mixins/route-locking", "copilot/mixins/secure-preset"], function (exports, _authenticatedRoute, _unsavedWarning, _routeLocking, _securePreset) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, _routeLocking.default, _securePreset.default, {
    store: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var requiredPermissions = [_securePreset.presetPermissionActions.update];
      this.redirectIfUserCantUsePresets(transition, requiredPermissions);
    },
    model: function model(params) {
      var presetId = params.id;

      return this.store.find("article", presetId);
    }
  });
});