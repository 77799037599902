define("copilot/validations/brands/lci/venue", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var venueValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.VenueValidationsDescriptor).andAlso(object({
    photosTout: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  })).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  // Returns the validation descriptors
  exports.default = venueValidationBuilder;
});