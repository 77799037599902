define("copilot/validations/user", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  function username() {
    return (0, _copilotValidators.format)(/^[a-z0-9_\-@.]{3,255}$/).catch(function () {
      return [{ path: [], message: { name: "username-format" } }];
    });
  }

  function password() {
    return (0, _copilotValidators.format)(/^(?=.*\d)(?=.*[A-Z])/).catch(function () {
      return [{ path: [], message: { name: "password-validation" } }];
    }).andAlso((0, _copilotValidators.length)({ min: 8 }).catch(function () {
      return [{ path: [], message: { name: "password-length" } }];
    }));
  }

  var userValidators = object({
    firstName: (0, _copilotValidators.required)(),
    lastName: (0, _copilotValidators.required)(),
    userName: (0, _copilotValidators.required)().andThen(username()),
    email: (0, _copilotValidators.required)().andThen((0, _copilotValidators.email)()),
    role: (0, _copilotValidators.required)(),
    newPassword: (0, _copilotValidators.optional)(password())
  }).andAlso((0, _copilotValidators.match)("verifyPassword", "newPassword"));

  exports.default = (0, _crossCheckDsl.default)(userValidators);
});