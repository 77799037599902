define("copilot/routes/galleries/index", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/route-locking", "copilot/mixins/seo"], function (exports, _authenticatedRoute, _routeLocking, _seo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_seo.default, _routeLocking.default, {
    model: function model(params) {
      return this.store.find("gallery", params.gallery_id);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set("selectedControllers", Ember.A());
      if (controller.mediaToSave) {
        controller.mediaToSave.clear();
      }
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      return this.runSeoScoring(model);
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        window.dataLayer[0].content.pageType = model.type;
      }
    }
  });
});