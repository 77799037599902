define("copilot/components/type-selector/selector-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNameBindings: ["isCollapsed:closed"],
    intl: Ember.inject.service(),
    brand: Ember.inject.service(),

    isCollapsed: Ember.computed.alias("type.isCollapsed"),

    // Title in display for this item
    title: Ember.computed("type", function () {
      var type = this.type;
      return this.intl.t("contributorTypes." + type.value);
    }),

    //On document create, the type selector item should be open by default
    init: function init() {
      this._super.apply(this, arguments);
      var hasItems = Ember.isPresent(this.get("model." + this.field));
      this.set("isCollapsed", hasItems);
    },


    actions: {
      toggleCollapse: function toggleCollapse() {
        this.toggleProperty("isCollapsed");
      }
    }
  });
});