define("copilot/routes/catch-all", ["exports", "ember-inflector"], function (exports, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Route.extend({
    config: Ember.inject.service(),
    v2Redirect: Ember.inject.service(),

    keyValueStorage: Ember.inject.service(),

    redirect: function () {
      var _ref = _asyncToGenerator(function* (routeObj) {
        var _this = this;

        var router = this._router._routerMicrolib;
        var currentUrl = this._router.location.getURL();

        var _routeObj$path$split = routeObj.path.split("/"),
            _routeObj$path$split2 = _toArray(_routeObj$path$split),
            brandCode = _routeObj$path$split2[0],
            stack = _routeObj$path$split2.slice(1);

        var breadcrumbs = [];
        var href = "/" + brandCode;
        var segment = void 0;

        while (stack.length) {
          segment = [stack.shift(), stack.shift()];
          href += "/" + segment.join("/");

          var type = (0, _emberInflector.singularize)(segment[0]);
          var config = Ember.get(this, "config.config.contentTypes").findBy("value", type);

          if (config) {
            breadcrumbs.push({
              title: config.label,
              href: href,
              active: href === "/" + routeObj.path
            });
          }
        }

        var url = "/" + brandCode + "/" + segment.join("/");

        var handlers = router.recognizer.recognize(url);
        var leafRoute = handlers[handlers.length - 1];

        // This is close enough to a real Transition object for this service
        this.v2Redirect.maybeRedirectTransition({
          targetName: leafRoute.handler,
          intent: { url: url }
        });

        // Resolve to the search page, since the url doesn't exist
        if (leafRoute.handler === "catch-all") {
          this.keyValueStorage.set("breadcrumbs", []);
          return this.transitionTo("search");
        }

        if (currentUrl !== "/" + routeObj["path"]) {
          yield router.updateURL("/" + routeObj["path"]);
        }
        yield router.handleURL(url);

        Ember.run(function () {
          _this._router.one("willTransition", Ember.run.bind(_this, "willExitRoute"));

          _this.keyValueStorage.set("breadcrumbs", breadcrumbs);
        });
      });

      function redirect(_x) {
        return _ref.apply(this, arguments);
      }

      return redirect;
    }(),
    willExitRoute: function willExitRoute(transition) {
      if (transition.targetName !== "catch-all") {
        this.keyValueStorage.set("breadcrumbs", []);
      }
    }
  });
});