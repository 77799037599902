define("copilot/schema-helpers/categories-subchannel", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    config: Ember.inject.service(),
    features: Ember.inject.service(),
    compute: function compute(model) {
      var categoriesChannelsPath = this.features.get("enableCategoryChannels") ? "categoriesChannels" : "categories.channels";
      var categoriesChannels = model.get(categoriesChannelsPath);
      if (!Ember.isBlank(categoriesChannels)) {
        var firstCategory = categoriesChannels[0];
        var firstCategoryParent = firstCategory.parent;
        var channel = firstCategoryParent.name === "Channels" ? firstCategory.name : firstCategoryParent.name;
        var firstSubchannelCategory = categoriesChannels.find(function (channelCategory) {
          return channelCategory.parent.name === channel;
        });
        if (!Ember.isBlank(firstSubchannelCategory)) {
          return firstSubchannelCategory.name;
        }
      }
    }
  });
});