define("copilot/services/locale-mapper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    environment: Ember.inject.service("environment"),

    getEquivalentLocale: function getEquivalentLocale(inputLocale, inputValue, outputLocale) {
      var foundLocale = this.get("environment.locales").find(function (locale) {
        return locale[inputLocale] === inputValue;
      });
      return foundLocale ? foundLocale[outputLocale] : undefined;
    }
  });
});