define("copilot/validations/brands/vogue/house", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var houseValidators = object({
    name: (0, _copilotValidators.required)()
  });

  exports.default = (0, _crossCheckDsl.default)(houseValidators);
});