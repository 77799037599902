define("copilot/components/search-slat/product-card/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    styles: Ember.computed("size", function () {
      var height = this.size / 5 * 4;
      if (this.size == null) {
        return Ember.String.htmlSafe("");
      }
      return Ember.String.htmlSafe("height: " + height + "px");
    })
  });
});