define("copilot/components/card-list/add-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // Instead of manually creating items and assigning the owner,
  // we should probably use the relationship's create method
  function createItem(obj) {
    var _this = this;

    if (this.itemModel) {
      var itemFactory = this.store.modelFor(this.itemModel);
      var item = itemFactory.create();
      var owner = Ember.getOwner(this);
      Ember.setOwner(item, owner);
      item.set("embedded", obj);
      item.set("subtype", obj.subtype);
      if (item.shouldPreFillHed) {
        item.preFillHed();
      }
      if (Ember.get(item, "constructor.metadata.defaultValues")) {
        Object.entries(item.constructor.metadata.defaultValues).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];

          if (typeof value === "string" && value.charAt(0) === "@") {
            value = _this.schemaHelpers.lookup(value.slice(1)).compute(item);
          }
          item.set(key, value);
        });
      }
      return item;
    }
    return obj;
  }

  exports.default = Ember.Component.extend({
    schemaHelpers: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ["sort-disabled", "new-card"],
    classNameBindings: ["gridItemClass"],
    tagName: "li",
    isShowingModal: false,
    isAdding: true,
    items: null,
    afmContent: null,

    click: function click() {
      this.set("isShowingModal", true);
    },


    label: Ember.computed("field", function () {
      var keyExists = this.intl.exists("components.card-list.add-item." + this.field);
      return keyExists ? this.field : "addContent";
    }),

    actions: {
      addItems: function addItems(items) {
        var _this2 = this;

        if (!items) {
          this.send("closeModal");
        }
        var itemsAfterEmbedded = items.map(function (item) {
          return createItem.call(_this2, item);
        });
        if (!this.isSpecialList && itemsAfterEmbedded.firstObject && itemsAfterEmbedded.firstObject.type === "bundle-curation") {
          {
            this.setProperties({
              items: itemsAfterEmbedded,
              isAdding: false
            });
          }
        } else {
          this.send("closeModal");
          this.addItems(itemsAfterEmbedded);
        }
      },
      closeModal: function closeModal() {
        if (!this.isDestroyed) {
          this.setProperties({
            isShowingModal: false,
            isAdding: true
          });
        }
      }
    }
  });
});