define("copilot/routes/articles/index", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/seo"], function (exports, _authenticatedRoute, _seo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_seo.default, {
    setupController: function setupController(controller, model) {
      controller.set("showSidebar", false);
      this._super(controller, model);
    },
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      return this.runSeoScoring(model);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        window.dataLayer[0].content.pageType = model.type;
      }
    }
  });
});