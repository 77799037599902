define("copilot/models/publish-data", ["exports", "@condenast/copilot-slug", "ember-model", "copilot/mixins/validatable", "moment"], function (exports, _copilotSlug, _emberModel, _validatable, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var WEB_DISTRIBUTION_CHANNEL = "web-owned-and-operated";

  /**
   * A model meant to represent some abstractions around publishing
   * of content items
   *
   * @module copilot/models/publish-data
   * @augments Ember.Model
   * @mixes copilot/mixins/validatable
   */
  exports.default = _emberModel.Model.extend(_validatable.default, {
    type: "publish-data",
    schemaHelpers: Ember.inject.service(),
    mountPoint: Ember.computed.alias("publishConfig.mountPoint"),
    urlPrefix: Ember.computed.alias("publishConfig.urlPrefix"),
    slugAddon: Ember.computed.alias("publishConfig.slugAddon"),
    anchor: Ember.computed.alias("publishConfig.anchor"),
    templates: Ember.computed.alias("publishConfig.templates"),
    softSlug: Ember.computed.alias("publishConfig.softSlug"),
    content: null,
    path: "",
    uri: "",
    slug: "",
    previousSlug: null,
    template: "",
    searchable: true,
    recommendable: true,
    publishDate: null,
    isPublished: false,

    // TODO: This object/class should probably not be a model (it behaves more like
    // a controller. Either way, none of the behaviour should depending on the init
    // hook, because it makes testing difficult and depending on the init hook can
    // easily result in bugs. Overall, we need to convert this to a componenent.
    init: function init() {
      var _this = this;

      this._super();

      var currentDate = new Date().toISOString();

      var lastPublishRecord = this.get("content.currentOrQueuedPublishRecord");
      this.set("isPublished", !!lastPublishRecord);

      if (this.isPublished) {
        this.set("publishDate", currentDate);

        var toBePublishedNext = this.get("content.queuedRecord");
        var currentlyPublished = this.get("content.currentlyPublishedRecord");

        if (currentlyPublished) {
          this.set("publishDate", currentlyPublished.pubDate);
        }

        // If published in the future do not set a date
        if (toBePublishedNext) {
          this.set("publishDate", "");
        }

        if (!this.get("configService.features.publishData")) {
          this.set("searchable", lastPublishRecord.searchable);
          this.set("template", lastPublishRecord.template);

          if (this.webDistributionChannel && this.webDistributionChannel.metadata) {
            this.webDistributionChannel.metadata.recommendable === undefined ? this.set("recommendable", true) : this.set("recommendable", this.webDistributionChannel.metadata.recommendable);
          } else {
            this.set("recommendable", true);
          }

          var currentUri = lastPublishRecord.uri;

          var uriMeta = currentUri.split("/");

          Ember.run.scheduleOnce("sync", this, function () {
            var urlPrefix = _this.get("publishConfig.urlPrefix");
            var uriPrefixLength = urlPrefix ? urlPrefix.split("/").length : 0;
            var uriMapLength = _this._getPathTokenCount() + uriPrefixLength;
            var slug = uriMeta.slice(uriMapLength - 1).join("/");
            var path = uriMeta.slice(0, uriMapLength - 1).join("/");
            _this.setProperties({
              previousSlug: slug,
              slug: slug
            });
            _this.set("path", path);
          });
        }
      } else {
        this.set("publishDate", currentDate);
        Ember.run.scheduleOnce("sync", this, function () {
          _this.setPath();
        });
      }
      if (this.get("publishConfig.templates") && !this.get("configService.features.publishData")) {
        this.setPublishTemplate();
      }
    },
    _getPathTokenCount: function _getPathTokenCount() {
      var _this2 = this;

      var getURIPartCount = function getURIPartCount(str) {
        return str.split("/").length;
      };
      var uriMap = this.get("publishConfig.uriMap");
      var uriMapLength = 0;

      uriMap.forEach(function (uriPart) {
        if (Ember.typeOf(uriPart) === "string" && !Ember.isBlank(_this2.get("content." + uriPart))) {
          uriMapLength += getURIPartCount(uriPart);
        } else if (Ember.typeOf(uriPart) === "object") {
          Object.keys(uriPart).forEach(function (key) {
            uriMapLength += getURIPartCount(uriPart[key]);
          });
        }
      });

      return uriMapLength;
    },
    setPublishTemplate: function setPublishTemplate() {
      var lastPublishRecord = this.get("content.currentOrQueuedPublishRecord");
      var template = lastPublishRecord && lastPublishRecord.template || this.defaultTemplate || this.templates[0] || null;

      var storageTemplateKey = "publish-template:" + this.get("content.id");
      var templateFromStorage = window.localStorage.getItem(storageTemplateKey);
      if (templateFromStorage) {
        template = templateFromStorage;
        window.localStorage.removeItem(storageTemplateKey);
      }

      this.set("template", template ? (0, _copilotSlug.default)(template) : template);
    },


    /**
     * setPath - reads publish-config-related properties and defines path
     */

    setPath: function setPath() {
      var newPath = void 0;
      var uris = [];
      if (this.urlPrefix) {
        uris.push(this.urlPrefix);
      }
      this.pubUriKeys.forEach(function (key) {
        if ((typeof key === "undefined" ? "undefined" : _typeof(key)) === "object") {
          if (Object.keys(key).indexOf("pubDate") > -1) {
            uris.push((0, _moment.default)(this.publishDate).format(key.pubDate));
          }
          if (Object.keys(key).indexOf("string") > -1) {
            uris.push(key.string);
          }
        }

        // ⚠️ This logic is duplicated from the base controller,
        // which handles which properties should(n't) be slugified.
        if (typeof key === "string") {
          var modelValue = this.get("content." + key);

          // Attempt to get the value from schema helper if it doesn't exist
          // directly on a model for things like publishUriHook.
          var schemaHelper = this.schemaHelpers.lookup(key.dasherize());
          var schemaValue = schemaHelper && schemaHelper.compute(this.content);
          var isSlugified = key.match(/slug$/i) || this.softSlug || Ember.isEmpty(modelValue);
          var value = Ember.isEmpty(modelValue) ? schemaValue : modelValue;

          if (value) {
            if (isSlugified) {
              uris.push(value);
            } else {
              uris.push((0, _copilotSlug.default)(value));
            }
          }
        }
      }, this);
      newPath = uris.join("/");
      if (this.anchor) {
        newPath = [newPath, this.anchor].join("#");
      }
      this.set("path", newPath);
      return newPath;
    },


    setUriObserver: Ember.observer("path", "slug", function () {
      if (this.get("configService.features.publishData")) {
        return;
      }
      var _slug = void 0;
      var fullUri = [];
      if (this.path) {
        fullUri.push(this.path);
      }
      _slug = this.getSlug();
      if (_slug !== "" && this.slugAddon && !this.isPublished) {
        _slug += this.slugAddon;
      }
      var hasSlugOrPublished = !this.get("publishConfig.hasNoSlug") || this.isPublished;
      if (hasSlugOrPublished && Ember.isPresent(_slug)) {
        fullUri.push(_slug);
      }
      this.set("uri", fullUri.join("/"));
    }),

    pubUriKeys: Ember.computed("publishConfig.uriMap", function () {
      var channelKey = (this.get("content.channel") || "").toLowerCase();
      var channelSpecificUriKeys = this.get(["publishConfig", "channels", channelKey, "uriMap"].join("."));
      var defaultUriKeys = this.get("publishConfig.uriMap");
      var uriKeys = channelSpecificUriKeys || defaultUriKeys;

      if (Ember.isNone(uriKeys)) {
        return [];
      }
      if (this.get("publishConfig.hasNoSlug")) {
        return uriKeys;
      }
      return uriKeys.slice(0, uriKeys.length - 1);
    }),

    pubSlugKey: Ember.computed("publishConfig.uriMap", function () {
      var uriKeys = this.get("publishConfig.uriMap");
      if (Ember.isNone(uriKeys)) {
        return [];
      }
      return uriKeys[uriKeys.length - 1];
    }),

    getSlug: function getSlug() {
      var isPublished = this.isPublished;
      if (this.softSlug) {
        var slugParts = this.slug.split("/").map(function (slug) {
          if (isPublished) {
            return slug;
          }
          return (0, _copilotSlug.default)(slug);
        });
        return slugParts.filter(Boolean).join("/");
      }

      if (isPublished && this.anchor) {
        return this.slug;
      }

      return (0, _copilotSlug.default)(this.slug);
    },


    computedSlug: Ember.computed("pubSlugKey", "content", "isPublished", function () {
      if (!this.isPublished) {
        var pubSlugPath = "content." + this.pubSlugKey;
        var slug = this.get(pubSlugPath);
        return slug && (0, _copilotSlug.default)(slug);
      }
    }),
    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      slug: "computedSlug"
    },

    webDistributionChannel: Ember.computed("content", function () {
      if (this.content.currentOrQueuedPublishRecord) {
        var distributionChannels = this.content.currentOrQueuedPublishRecord.distributionChannels;

        var webDistributionChannel = distributionChannels.find(function (distributionChannel) {
          return distributionChannel.name === WEB_DISTRIBUTION_CHANNEL;
        });

        return webDistributionChannel;
      }
      return null; // Default webDistributionChannel to null
    })
  });
});