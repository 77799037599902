define("copilot/components/search/filter-ui/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "ul",
    classNames: ["nav-list"],
    intl: Ember.inject.service()
  });
});