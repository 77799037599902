define("copilot/components/embed-editor/component", ["exports", "@atjson/document", "@atjson/source-html"], function (exports, _document, _sourceHtml) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  class CerosEmbed extends _document.ObjectAnnotation {}
  CerosEmbed.type = "ceros";
  CerosEmbed.vendorPrefix = "compass";

  class Iframe extends _document.ObjectAnnotation {}
  Iframe.type = "iframe";
  Iframe.vendorPrefix = "html";

  class Div extends _document.ObjectAnnotation {}
  Div.type = "div";
  Div.vendorPrefix = "html";

  class HTMLSource extends _sourceHtml.default {}
  HTMLSource.schema = [].concat(_toConsumableArray(_sourceHtml.default.schema), [Iframe, Div]);

  class Ceros extends _document.default {}
  Ceros.schema = [CerosEmbed];

  HTMLSource.defineConverterTo(Ceros, function (doc) {
    var containers = doc.where(function (a) {
      return (a.attributes.id || "").match(/^experience-.*$/) && a.attributes["dataset"] && a.attributes["dataset"]["aspectratio"] != null;
    }).as("container");
    var iframeTags = doc.where(function (a) {
      return a.type === "iframe" && a.attributes.class === "ceros-experience";
    }).as("iframes");
    var scriptTags = doc.where({ type: "-html-script" }).as("scripts");

    containers.join(iframeTags, function (container, iframe) {
      return container.start < iframe.start && container.end > iframe.end;
    }).outerJoin(scriptTags, function (_ref, script) {
      var container = _ref.container;

      return script.start > container.end;
    }).update(function (_ref2) {
      var container = _ref2.container,
          iframes = _ref2.iframes,
          scripts = _ref2.scripts;

      iframes.forEach(function (iframe) {
        return doc.removeAnnotation(iframe);
      });
      scripts.forEach(function (script) {
        return doc.removeAnnotation(script);
      });

      var aspectRatio = parseFloat(container.attributes["dataset"]["aspectratio"]);
      var mobileAspectRatio = container.attributes["dataset"]["mobile-aspectratio"];
      var mobileWidth = void 0,
          mobileHeight = void 0;
      if (mobileAspectRatio) {
        mobileAspectRatio = parseFloat(mobileAspectRatio, 10);
        mobileWidth = 100;
        // Copilot-markdown does *not* support floats in embed attributes
        mobileHeight = Math.ceil(100 / mobileAspectRatio);
      }

      doc.replaceAnnotation(container, new CerosEmbed({
        start: container.start,
        end: container.end,
        attributes: {
          width: 100,
          height: 100 / aspectRatio,
          mobileWidth: mobileWidth,
          mobileHeight: mobileHeight,
          url: iframes[0].attributes.src
        }
      }));
    });

    return doc;
  });

  /*----*/

  var EmbedType = Ember.Object.extend({
    editor: null,
    title: null,
    model: null,
    isActive: Ember.computed("editor.type", "type", function () {
      return this.type === this.get("editor.type");
    })
  });

  var embedTypes = [{ type: "iframe", title: "Iframe" }, { type: "social", title: "Social Embed" }, { type: "video-embed", title: "Video" }];

  exports.default = Ember.Component.extend({
    validation: Ember.inject.service(),
    features: Ember.inject.service(),
    type: Ember.computed.alias("model.type"),

    init: function init() {
      this._super.apply(this, arguments);
      this.setModel();
      this.setEmbedTypes();
    },


    componentType: Ember.computed("type", function () {
      var type = this.type;
      if (this.get("data.service")) {
        type = "social";
      }
      return "embed-editor/" + type;
    }),

    setEmbedTypes: function setEmbedTypes() {
      var _this = this;

      var types = embedTypes.map(function (typeObj) {
        var embedType = EmbedType.create(typeObj);
        embedType.set("editor", _this);
        return embedType;
      });

      if (this.features.get("cerosEmbed")) {
        var cerosEmbed = EmbedType.create({ type: "ceros", title: "Ceros" });
        cerosEmbed.set("editor", this);
        types.push(cerosEmbed);
      }

      if (this.features.get("uploadTranscript")) {
        var transcriptEmbed = EmbedType.create({
          type: "audio-embed",
          title: "Audio"
        });
        transcriptEmbed.set("editor", this);
        types.push(transcriptEmbed);
      }

      this.set("embedTypes", types);
    },
    setModel: function setModel() {
      var data = this.data || {};
      var type = data.type || "iframe";
      data.type = type;
      var owner = Ember.getOwner(this);
      var EmbedContentModel = owner.factoryFor("model:embed-editor-content");
      var model = EmbedContentModel.create(data);
      this.set("model", model);
    },


    actions: {
      setType: function setType(type) {
        this.set("type", type);
      },
      submit: function submit() {
        var _this2 = this;

        var model = this.model;
        var validationService = this.validation;

        if (this.type === "ceros" && this.model.cerosEmbed) {
          var parsedData = HTMLSource.fromRaw(model.cerosEmbed).convertTo(Ceros).where(function (a) {
            return a.type === "ceros";
          });
          if (parsedData.annotations.length > 0) {
            var _parsedData$annotatio = parsedData.annotations[0].attributes,
                width = _parsedData$annotatio.width,
                height = _parsedData$annotatio.height,
                mobileWidth = _parsedData$annotatio.mobileWidth,
                mobileHeight = _parsedData$annotatio.mobileHeight,
                url = _parsedData$annotatio.url;

            model.setProperties({
              width: width,
              height: height,
              mobileWidth: mobileWidth,
              mobileHeight: mobileHeight,
              url: url
            });
          }
        }

        validationService.validate(model, "save").then(function (isValid) {
          if (isValid) {
            _this2.submit(model);
          }
        });
      }
    }
  });
});