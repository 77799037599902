define("copilot/brands/vogue/mixins/existing-gallery-rels", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    galleryTypes: Ember.computed.alias("brandConfig.fashionGalleryTypes"),

    existingGalleryRels: Ember.computed("fashionshowModel.{atmosphere,beauty,collection,detail,frontRow,runway}.[]", function () {
      return [this.get("fashionshowModel.atmosphere"), this.get("fashionshowModel.beauty"), this.get("fashionshowModel.collection"), this.get("fashionshowModel.detail"), this.get("fashionshowModel.frontRow"), this.get("fashionshowModel.runway")].filter(Ember.isPresent);
    }),

    existingGalleryTypes: Ember.computed("existingGalleryRels", function () {
      var _this = this;

      var types = this.existingGalleryRels.mapBy("firstObject.galleryType");
      var typeValues = types.map(function (type) {
        return _this.galleryTypes.findBy("value", type);
      });

      if (types.includes("runway")) {
        typeValues.push({
          label: "Runway",
          value: "runway"
        });
      }

      return typeValues.compact();
    })
  });
});