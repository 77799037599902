define("copilot/components/geo-field/map/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LATITUDE_PATTERN = /\{lat\}/g;
  var LONGITUDE_PATTERN = /\{long\}/g;
  var HEIGHT_PATTERN = /\{height\}/g;
  var WIDTH_PATTERN = /\{width\}/g;
  var ZOOM_PATTERN = /\{zoom\}/g;

  function isValidLat(lat) {
    return typeof lat === "number" && Math.abs(lat) <= 90;
  }

  function isValidLong(lng) {
    return typeof lng === "number" && Math.abs(lng) <= 180;
  }

  exports.default = Ember.Component.extend({
    height: "512",
    width: "512",
    zoom: "12",
    mapUrl: Ember.computed("lat", "lng", "height", "width", "zoom", "mapUrlPattern", function () {
      if (!(isValidLat(this.lat) && isValidLong(this.lng) && this.mapUrlPattern)) {
        return "";
      }

      return this.mapUrlPattern.replace(LATITUDE_PATTERN, this.lat).replace(LONGITUDE_PATTERN, this.lng).replace(HEIGHT_PATTERN, this.height).replace(WIDTH_PATTERN, this.width).replace(ZOOM_PATTERN, this.zoom);
    })
  });
});