define("copilot/models/asset-embed", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    caption: null,
    autoplay: false,
    muted: false,
    asset: Ember.computed("media.firstObject", {
      get: function get() {
        var media = this.get("media.firstObject");
        return media && Ember.get(media, "_data");
      },
      set: function set(key, value) {
        return value;
      }
    }),
    title: Ember.computed.oneWay("asset.title"),

    // Different path lookups depending on whether we are wrapping a search
    // result or a newly uploaded item (the `_data` property of an Ember.Model)
    type: Ember.computed("asset.{type,meta.modelName}", function () {
      return this.get("asset.type") || this.get("asset.meta.modelName");
    }),
    thumbnail: Ember.computed("asset.type", function () {
      if (["photo", "cartoon"].indexOf(Ember.get(this, "type")) >= 0) {
        return Ember.get(this, "asset");
      }
      return this.get("asset._embedded.photosTout.firstObject.fields");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.media = [];
    },


    videoThumbnail: Ember.computed.reads("asset.imageUrl"),
    defaultCaption: Ember.computed("asset.{caption,hed,name,title}", function () {
      if (["photo", "cartoon"].indexOf(this.get("asset.type")) >= 0) {
        return this.get("asset.caption");
      } else {
        return this.get("asset.title") || this.get("asset.caption") || this.get("asset.hed") || this.get("asset.name");
      }
    }),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      caption: { source: "defaultCaption", sanitize: false }
    },
    toStringExtension: function toStringExtension() {
      return "Embed Wrapper";
    }
  });
});