define("copilot/lib/autocomplete/content-lang-adapter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function contentLanguages(languageConfig) {
    return languageConfig.available ? languageConfig.available : [languageConfig.default, languageConfig.alternateLang].compact();
  }

  function formatLanguageOptions(values) {
    var _this = this;

    return values.map(function (lang) {
      var systemKey = _this.localeMapper.getEquivalentLocale("contentApi", lang, "system");

      var text = _this.intl.t("languages." + systemKey).toString();
      return {
        id: systemKey,
        text: text
      };
    });
  }

  /**
   * Provides the list of languages in which contents are localized, from brand's configuration
   */
  exports.default = {
    search: function search() {
      // Here we always return the list of languages configured for the brand instead of
      // searching it by name
      return Ember.RSVP.resolve(contentLanguages(this.get("configService.config.language")));
    },

    transformResults: function transformResults(results) {
      return formatLanguageOptions.call(this, results);
    },
    getValue: function getValue(data) {
      var contentApiLang = this.localeMapper.getEquivalentLocale("system", data.val, "contentApi");
      return contentApiLang;
    },
    getInitialValue: function getInitialValue(selection) {
      var _this2 = this;

      var selectionAsSystemLocale = selection.map(function (lang) {
        return _this2.localeMapper.getEquivalentLocale("contentApi", lang, "system");
      });

      return selectionAsSystemLocale.join(",");
    },
    transformInitialValue: function transformInitialValue(selection) {
      return Ember.RSVP.resolve(formatLanguageOptions.call(this, selection));
    }
  };
});