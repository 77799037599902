define("copilot/services/category", ["exports", "ember-service-methods/inject"], function (exports, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var SELF = "self";
  var CONDITIONS = "Conditions";
  var HEALTH_CONDITIONS = "health-conditions/";

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    logger: Ember.inject.service(),
    features: Ember.inject.service(),
    userService: Ember.inject.service("user"),
    archive: (0, _inject.default)(),
    unarchive: (0, _inject.default)(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("roots", []);
      this.set("cache", {});
    },


    configuredRoots: Ember.computed("roots", function () {
      return this.roots.filterBy("config.isRootConfigured");
    }),

    categoryFactory: function categoryFactory(categoryObj) {
      var factory = Ember.getOwner(this).factoryFor("model:category");
      var category = factory.create(categoryObj);
      return category;
    },
    setup: function setup() {
      var _this = this;

      return this.getRoots().then(function (roots) {
        _this.set("roots", roots);
        return roots;
      });
    },
    search: function search(query, term) {
      if (term) {
        query.q = "" + term;
      }

      if (this.features.get("queryWithoutDecay")) {
        query.disableDecay = true;
      }

      if (this.features.get("queryUsingAnd") && !query.d_o) {
        query.d_o = "and";
      }

      return this.store.find("category", query).then(function (data) {
        return data.categories;
      });
    },
    getRoots: function getRoots() {
      var query = {
        isroot: true,
        meta: "childCount"
      };
      return this.store.find("category", query).then(function (data) {
        return data.categories;
      });
    },
    getChildren: function getChildren(category, options) {
      var _this2 = this;

      var key = Ember.get(category, "hierarchyString");
      var parentid = Ember.get(category, "id");

      // Default to fetching fresh data
      options = Object.assign({ fetch: true }, options);
      if (!options.fetch && key && this.cache[key]) {
        return Promise.resolve(this.cache[key]);
      }

      var baseQuery = Object.assign({
        meta: "childCount"
      }, options.paginationMetadata);
      var additionalOptions = parentid ? { parentid: parentid } : { isroot: true };
      var query = Object.assign({}, baseQuery, additionalOptions);
      return this.store.find("category", query).then(function (results) {
        if (key) {
          _this2.cache[key] = results;
        }
        return results;
      });
    },
    getRootChildren: function getRootChildren(rootSlug, options) {
      if (rootSlug) {
        var rootToBrowse = this.roots.find(function (root) {
          return root.get("slug") === rootSlug;
        });
        if (!rootToBrowse) {
          throw new Error("Rootslug (" + rootSlug + ") was passed in, but no root with that slug was found");
        }
        return this.getChildren(rootToBrowse, options);
      } else {
        return this.getRoots();
      }
    },
    find: function find(id) {
      return this.store.find("category", id);
    },
    createChild: function createChild(parent) {
      return this.store.createRecord("category", {
        parent: parent
      });
    },
    save: function () {
      var _ref = _asyncToGenerator(function* (category, changeset) {
        var modified = changeset ? changeset : category;
        var action = void 0;
        var revisionAuthor = this.get("userService.fullName");

        if (changeset) {
          category.setProperties(changeset.getProperties("name", "slug"));
          if (changeset.get("l10ns")) {
            category.set("l10ns", changeset.get("l10ns"));
          }
          category.setProperties({ revisionAuthor: revisionAuthor });
          var didArchivedStatusUpdate = category.get("meta.archived") !== changeset.get("archived");
          if (didArchivedStatusUpdate) {
            var archiveCategory = changeset.get("archived");
            action = archiveCategory ? this.archive : this.unarchive;
          }
        }

        if (modified.archived) {
          if (action) {
            yield action.call(this, category);
          }
          return category.save();
        }

        var isValid = false;
        var isSelf = this.store.brand.activeBrand === SELF;
        var isCondition = category.root.name === CONDITIONS;
        var isHealthCondition = category.slug.startsWith(HEALTH_CONDITIONS);

        if (isSelf && isCondition && isHealthCondition) {
          // bypass validation because Self uses slugs containing a slash
          // (eg, `health-conditions/foo`) under the `Conditions` root
          // in order to configure certain pages, eg:
          // `https://www.self.com/health-conditions/psoriasis`
          //
          // no this is not ideal
          isValid = true;
        } else {
          isValid = yield modified.validate();
        }

        if (isValid) {
          if (action) {
            yield action.call(this, category);
          }
          return category.save();
        }

        return Promise.reject();
      });

      function save(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return save;
    }()
  });
});