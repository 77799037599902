define("copilot/routes/create", ["exports", "copilot/routes/authenticated-route", "ember-service-methods/inject", "ember-inflector"], function (exports, _authenticatedRoute, _inject, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _authenticatedRoute.default.extend({
    user: Ember.inject.service(),
    createItem: (0, _inject.default)(),
    v2Redirect: Ember.inject.service(),
    modelName: function modelName() {
      if (this.routeName === "contentEdit.create") {
        var collectionName = this.paramsFor("contentEdit")["content_type"];
        return (0, _emberInflector.singularize)(collectionName);
      }
      return (0, _emberInflector.singularize)(this.routeName.split(".")[0]);
    },
    beforeModel: function () {
      var _ref = _asyncToGenerator(function* (transition) {
        var shouldContinue = yield this.v2Redirect.maybeRedirectTransition(transition);
        if (!shouldContinue) {
          transition.abort();
        }
      });

      function beforeModel(_x) {
        return _ref.apply(this, arguments);
      }

      return beforeModel;
    }(),
    model: function () {
      var _ref2 = _asyncToGenerator(function* () {
        var modelName = this.modelName();
        var editRouteName = this.editRouteName || (0, _emberInflector.pluralize)(modelName);
        var brand = Ember.get(this, "user.activeBrand");
        var model = yield this.createItem(modelName);
        if (this.routeName === "contentEdit.create") {
          var transition = this.replaceWith("contentEdit", brand, (0, _emberInflector.pluralize)(modelName), model);
          this.v2Redirect.maybeRedirectTransition(transition);
        } else {
          this.replaceWith(editRouteName, brand, model);
        }
        return model;
      });

      function model() {
        return _ref2.apply(this, arguments);
      }

      return model;
    }()
  });
});