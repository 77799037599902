define("copilot/components/global-uploader/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    classNames: ["global-uploader"],

    fileQueue: Ember.inject.service(),
    intl: Ember.inject.service(),

    uploadProgress: Ember.computed("fileQueue.progress", function () {
      return Ember.String.htmlSafe("width: " + Ember.get(this, "fileQueue.progress") + "%");
    }),

    dropzoneName: Ember.computed("fileQueue.queues.@each.active", function () {
      var activeQueue = Ember.get(this, "fileQueue.queues").findBy("active", true);
      if (activeQueue) {
        var _Ember$get$split = Ember.get(activeQueue, "name").split("/"),
            _Ember$get$split2 = _slicedToArray(_Ember$get$split, 3),
            modelName = _Ember$get$split2[0],
            fieldName = _Ember$get$split2[2];

        return fieldName || modelName;
      }
    }),

    // The following computed property is required because for some reason after
    // upgrading to Ember 2.15 the hbs below raised an error in the hbs module
    // transform. This should be put back in the template (and the computed below
    // removed) once this is properly supported.
    // {{!t 'base' field=(t (concat 'components.global-uploader.' dropzoneName))}}
    translatedField: Ember.computed("dropzoneName", function () {
      var dropzoneName = this.dropzoneName;
      return this.intl.t("components.global-uploader." + dropzoneName);
    })
  });
});