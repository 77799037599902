define("copilot/forms/brands/cnt/specialreport", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/cnt/specialreport"], function (exports, _specialreport) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _specialreport.default;
    }
  });
});