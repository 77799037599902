define("copilot/services/fetch-products-and-transform", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Service.extend({
    offerProducts: Ember.inject.service("offer-products"),
    getProductDetails: function () {
      var _ref = _asyncToGenerator(function* (brandSlug, offerUrl) {
        // get products from API
        var similarProducts = yield this.offerProducts.getProductDetails(brandSlug, offerUrl);
        var transformedSimilarProducts = similarProducts.length && similarProducts.map(function (similarProduct) {
          return {
            type: "similar-product",
            _type: "product",
            _source: {
              name: similarProduct.Name,
              productType: "",
              description: similarProduct.Description,
              offers: [{
                currency: "",
                seller: {
                  name: similarProduct["Retailer Name"]
                },
                purchaseUri: similarProduct["Amazon URL"] || similarProduct["Domain URL"],
                offerType: "purchase",
                isOutOfStock: similarProduct.isOutOfStock,
                autoUpdate: false,
                countryCode: "",
                price: similarProduct.Price,
                comparisonPrice: similarProduct["Sale Price"]
              }],
              imageUrl: similarProduct["Site Image"]
            }
          };
        });
        return transformedSimilarProducts;
      });

      function getProductDetails(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return getProductDetails;
    }()
  });
});