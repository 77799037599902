define("copilot/validations/brands/lci/curatedlist", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  // Allows to append new rules to core curatedlist rules
  var curatedlistValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.CuratedlistValidationsDescriptor).andAlso((0, _copilotCore.ifEqual)("curationSubType", "AssetsOnly", object({
    items: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  }))).catch((0, _crossCheckDsl.mutePath)(["categoriesChannels"])); // Catch errors from core rules and silence any body errors

  // Returns the validation descriptors
  exports.default = curatedlistValidationBuilder;
});