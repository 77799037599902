define("copilot/models/redirect", ["exports", "copilot/models/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Redirect = _base.default.extend({
    type: "redirect",
    configService: Ember.inject.service("config"),
    brandConfig: Ember.inject.service(),
    searchTitle: Ember.computed("metadata", function () {
      var displayBrand = Ember.get(this, "displayBrand");
      var configService = Ember.get(this, "configService");
      var envUrl = Ember.get(this, "brandConfig.envUrl");
      var publishEnv = displayBrand ? configService.getConsumerHostnameByBrandCode(displayBrand) : envUrl;
      return this.get("metadata.publishData.uri") || this.get("_embedded.publishHistory.uri") || "https://" + publishEnv;
    })

  });

  Redirect.reopenClass({
    url: "/api/redirects"
  });

  exports.default = Redirect;
});