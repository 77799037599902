define("copilot/components/navigation-sidebar/dropdown-menu/component", ["exports", "copilot/components/navigation-sidebar/dropdown-menu/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: "li",
    classNames: ["nav-item"]
  }).reopenClass({
    positionalParams: ["name"]
  });
});