define("copilot/lib/csv-maps/product", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    fields: [{
      field: "name",
      columnHeading: "Name"
    }, {
      field: "description",
      columnHeading: "Description"
    }, {
      field: "categories",
      columnHeading: "Functional Tag"
    }],
    fieldsToHydrate: ["Promo Image", "Brand"],
    relationships: [{
      field: "offers",
      type: "offer",
      fields: [{
        field: "seller",
        columnHeading: "Retailer Name",
        transform: function transform(name) {
          return { name: name };
        },

        offerCount: 0
      }, {
        field: "offerType",
        columnHeading: "Offer Type",
        offerCount: 0,
        defaultVal: "purchase"
      }, {
        field: "purchaseUri",
        columnHeading: "Offer URL",
        offerCount: 0
      }, {
        field: "price",
        columnHeading: "Price",
        optional: true,
        offerCount: 0
      }, {
        field: "countryCode",
        columnHeading: "Country Code",
        optional: true,
        offerCount: 0
      }, {
        field: "currency",
        columnHeading: "Currency",
        offerCount: 0,
        optional: true,
        defaultVal: "USD"
      }, {
        field: "comparisonPrice",
        columnHeading: "Sale Price",
        optional: true,
        offerCount: 0
      }, {
        field: "seller",
        columnHeading: "Retailer Name 2",
        transform: function transform(name) {
          return { name: name };
        },

        offerCount: 1,
        optional: true
      }, {
        field: "purchaseUri",
        columnHeading: "Offer URL 2",
        offerCount: 1,
        optional: true
      }, {
        field: "price",
        columnHeading: "Price 2",
        optional: true,
        offerCount: 1
      }, {
        field: "countryCode",
        columnHeading: "Country Code 2",
        optional: true,
        offerCount: 1
      }, {
        field: "currency",
        columnHeading: "Currency 2",
        offerCount: 1,
        defaultVal: "USD",
        optional: true
      }, {
        field: "comparisonPrice",
        columnHeading: "Sale Price 2",
        optional: true,
        offerCount: 1
      }, {
        field: "seller",
        columnHeading: "Retailer Name 3",
        transform: function transform(name) {
          return { name: name };
        },

        offerCount: 2,
        optional: true
      }, {
        field: "purchaseUri",
        columnHeading: "Offer URL 3",
        offerCount: 2,
        optional: true
      }, {
        field: "price",
        columnHeading: "Price 3",
        optional: true,
        offerCount: 2
      }, {
        field: "countryCode",
        columnHeading: "Country Code 3",
        optional: true,
        offerCount: 2
      }, {
        field: "currency",
        columnHeading: "Currency 3",
        offerCount: 2,
        defaultVal: "USD",
        optional: true
      }, {
        field: "comparisonPrice",
        columnHeading: "Sale Price 3",
        optional: true,
        offerCount: 2
      }, {
        field: "seller",
        columnHeading: "Retailer Name 4",
        transform: function transform(name) {
          return { name: name };
        },

        offerCount: 3,
        optional: true
      }, {
        field: "purchaseUri",
        columnHeading: "Offer URL 4",
        offerCount: 3,
        optional: true
      }, {
        field: "price",
        columnHeading: "Price 4",
        optional: true,
        offerCount: 3
      }, {
        field: "countryCode",
        columnHeading: "Country Code 4",
        optional: true,
        offerCount: 3
      }, {
        field: "currency",
        columnHeading: "Currency 4",
        offerCount: 3,
        defaultVal: "USD",
        optional: true
      }, {
        field: "comparisonPrice",
        columnHeading: "Sale Price 4",
        optional: true,
        offerCount: 3
      }, {
        field: "seller",
        columnHeading: "Retailer Name 5",
        transform: function transform(name) {
          return { name: name };
        },

        offerCount: 4,
        optional: true
      }, {
        field: "purchaseUri",
        columnHeading: "Offer URL 5",
        offerCount: 4,
        optional: true
      }, {
        field: "price",
        columnHeading: "Price 5",
        optional: true,
        offerCount: 4
      }, {
        field: "countryCode",
        columnHeading: "Country Code 5",
        optional: true,
        offerCount: 4
      }, {
        field: "currency",
        columnHeading: "Currency 5",
        offerCount: 4,
        defaultVal: "USD",
        optional: true
      }, {
        field: "comparisonPrice",
        columnHeading: "Sale Price 5",
        optional: true,
        offerCount: 4
      }, {
        field: "publish",
        columnHeading: "Publish",
        optional: true
      }]
    }, {
      field: "photosTout",
      columnHeading: "Promo Image"
    }, {
      field: "brand",
      columnHeading: "Brand"
    }]
  };
});