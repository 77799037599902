define("copilot/components/ck-editor/redirector-utils", ["exports", "@condenast/cna-st-codec"], function (exports, _cnaStCodec) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.decodeAffiliateLink = decodeAffiliateLink;
  exports.encodeAffiliateLink = encodeAffiliateLink;
  function decodeAffiliateLink(encoded) {
    var decoded = (0, _cnaStCodec.decodeAffiliateParams)(encoded);
    var organizationId = decoded.brand,
        offerUrl = decoded.offerUrl;

    return [organizationId, offerUrl];
  } /**
     * See redirector/lib/apps/offers/utils.js
     */
  function encodeAffiliateLink(organizationId, offerUrl, cid) {
    var paramsToBeEncoded = {
      origin: cid,
      brand: organizationId,
      offerUrl: offerUrl
    };
    var encoded = (0, _cnaStCodec.encodeAffiliateParams)(paramsToBeEncoded);
    return encoded;
  }
});