define("copilot/components/tag-recommendations/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["tag-recommendations"],
    classNameBindings: "loading:spinner-medium",
    loading: false,
    error: null,

    disabled: Ember.computed("model.{body,hed,dek}", function () {
      var hed = this.get("model.hed");
      var dek = this.get("model.dek");
      var body = this.get("model.body");
      var hasHed = hed && hed.length > 0;
      var hasDek = dek && dek.length > 0;
      var hasBody = body && body.length > 0;
      var hasText = hasHed && (hasDek || hasBody);
      return !hasText;
    }),

    actions: {
      generate: function generate() {
        var _this = this;

        this.set("error", null);
        var model = this.model;
        var field = this.field;
        this.set("loading", true);
        this.generate(model, field).then(function () {
          _this.set("loading", false);
        }).catch(function () {
          _this.set("error", _this.intl.t("helperText.tagRecommendations.requestError"));
          _this.set("loading", false);
        });
      }
    }
  });
});