define("copilot/mixins/previewable", ["exports", "url", "@condenast/copilot-slug"], function (exports, _url, _copilotSlug) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NEWSLETTERS = "newsletter";

  exports.default = Ember.Mixin.create({
    configService: Ember.inject.service("config"),
    features: Ember.inject.service(),

    _previewModel: Ember.computed.alias("model"),

    wasPublished: Ember.computed(function () {
      return this.get("_previewModel.publishHistory.data.length") > 0;
    }),

    contentTypeConfig: Ember.computed(function () {
      var model = this._previewModel;
      var displayBrand = this.get("_previewModel.displayBrand");
      return this.configService.getContentTypeConfig(model, displayBrand);
    }),

    contentTypeTemplates: Ember.computed.alias("contentTypeConfig.templates"),

    defaultTemplate: Ember.computed(function () {
      var templates = this.contentTypeTemplates;
      var defaultTemplate = templates ? templates[0] : null;
      return this.get("contentTypeConfig.defaultTemplate") || defaultTemplate;
    }),

    previewTypeConfig: Ember.computed(function () {
      return this.get("contentTypeConfig.preview");
    }),

    uriMap: Ember.computed(function () {
      var map = this.get("previewTypeConfig.uriMap");
      var uriMap = "";
      if (map) {
        map.forEach(function (value) {
          uriMap += (0, _copilotSlug.default)(this.get("_previewModel." + value)) + "/";
        }, this);
      }
      return uriMap;
    }),

    template: Ember.computed("_previewModel.currentOrQueuedPublishRecord.template", "defaultTemplate", function () {
      var defaultTemplate = this.defaultTemplate;
      var currentOrQueuedPublishRecordTemplate = this.get("_previewModel.currentOrQueuedPublishRecord.template");
      return currentOrQueuedPublishRecordTemplate || defaultTemplate;
    }),

    templates: Ember.computed(function () {
      var templates = this.contentTypeTemplates;
      return templates && templates.map(function (label) {
        return {
          value: (0, _copilotSlug.default)(label),
          label: label
        };
      });
    }),

    hasTemplates: Ember.computed("templates", function () {
      return this.get("templates.length") > 0;
    }),

    query: Ember.computed("template", function () {
      var initialParams = {};

      initialParams.status = "draft";
      initialParams.branded = !Ember.isEmpty(this.get("_previewModel.displayBrand"));
      initialParams.template = (0, _copilotSlug.default)(this.template || "");
      initialParams.type = this.get("_previewModel.subtype") || this.get("_previewModel.bundleType");
      initialParams.cb = Math.floor(Math.random() * 1000000 + 1);

      var filteredParams = Object.keys(initialParams).reduce(function (acc, key) {
        var value = initialParams[key];
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      return filteredParams;
    }),

    pathname: Ember.computed("previewTypeConfig.urlPrefix", "_previewModel.id", "template", function () {
      var _this = this;

      var keys = ["previewTypeConfig.mountPoint", "previewTypeConfig.urlPrefix", "uriMap", "_previewModel.id", "previewTypeConfig.urlSuffix"];

      var components = keys.reduce(function (acc, key) {
        var value = _this.get(key);
        if (value) {
          acc.push(value);
        }
        return acc;
      }, []);

      return components.join("/");
    }),

    previewUrl: Ember.computed("pathname", function () {
      var displayBrand = this.get("_previewModel.displayBrand");
      var configService = this.configService;
      var brandConfigHostname = this.get("brandConfig.previewHostname");
      var typeSpecificHostname = this.get("previewTypeConfig.hostname");
      var genericHostname = displayBrand ? configService.getConsumerHostnameByBrandCode(displayBrand) : brandConfigHostname;

      var protocol = document.location.protocol === "https:" ? "https" : "http";
      var host = typeSpecificHostname || genericHostname;
      var pathname = this.pathname;
      var query = this.query;

      return _url.default.format({ protocol: protocol, host: host, pathname: pathname, query: query });
    }),

    openPreview: function openPreview() {
      var brand = this.activeBrand;
      var type = this.get("_previewModel.type");
      var id = this.get("_previewModel.id");
      var isNewsletter = (this.get("_previewModel.bundleType") || "").toLowerCase().indexOf(NEWSLETTERS) > -1;
      var baseURL = !isNewsletter && this.get("features.v2Preview") && this.get("configService.config.copilotV2Url") || "";
      var url = baseURL + "/" + brand + "/preview/" + type + "/" + id;
      window.open(url, "Preview " + url);
      this.get("_previewModel.analytics").track("content", "previewed", {
        contentType: type
      });
    }
  });
});