define("copilot/serializers/bundle", ["exports", "lodash-es", "copilot/serializers/application", "copilot/models/category"], function (exports, _lodashEs, _application, _category) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function moveType(item) {
    item.type = item._type;
    return _lodashEs.default.omit(item, ["_type"]);
  }
  function moveTypeProperty(collection) {
    if (!Array.isArray(collection)) {
      return moveType(collection);
    }

    return collection.map(function (item) {
      return moveType(item);
    });
  }

  function transformCuration(collection) {
    if (Array.isArray(collection)) {
      return collection.map(function (item) {
        item.contentId = item.contentId || item.embedded.id;
        item.contentModel = item.contentModel || item.embedded.meta.modelName;
        delete item["embedded"];
        return item;
      });
    }

    return collection;
  }

  function setPhotosContainers(json) {
    var photosContainers = [];
    json.containers = json.containers.map(function (container) {
      var photo = container.photos[0];
      if (photo) {
        container.photoId = photo.id;
        photosContainers.push(photo);
      } else {
        container.photoId = null;
      }
      delete container["photos"];
      return container;
    });
    json.photosContainers = photosContainers;
    return json;
  }

  exports.default = _application.default.extend({
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    categoryService: Ember.inject.service("category"),
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.containers = json.containers.map(function (container) {
        container = moveTypeProperty(container);
        container.curations = transformCuration(moveTypeProperty(container.curations));
        container.searches = moveTypeProperty(container.searches);
        container.searches.forEach(function (search) {
          var categoryIds = search.filters.categoryIds || [];
          var additionalFilters = search.additionalFilters;
          if (search.categoriesFilterOperator === "and" && categoryIds.length > 0) {
            additionalFilters.push({
              key: "categoryIds_and",
              value: categoryIds.splice(0)
            });
          }

          additionalFilters.forEach(function (filterObject) {
            if (filterObject.key === "notcategoryIds" && search.notCategoriesFilterOperator === "and") {
              filterObject.key = "notcategoryIds_and";
            }
          });
        });
        return container;
      });
      json = setPhotosContainers(json);
      return json;
    },
    normalize: function normalize() {
      var _this = this;

      var data = this._super.apply(this, arguments);
      var categorySerializer = this.store.serializerFor("category");

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = data.containers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var container = _step.value;

          var _loop = function _loop(search) {
            if (!search.filters.categoryIds) {
              search.filters.categoryIds = [];
            }
            var categoryIds = search.filters.categoryIds;
            var additionalFilters = search.additionalFilters || [];

            var categoryIds_and = additionalFilters.find(function (filter) {
              return filter.key === "categoryIds_and";
            });
            var notcategoryIds_and = additionalFilters.find(function (filter) {
              return filter.key === "notcategoryIds_and";
            });
            var notcategoryIds = additionalFilters.find(function (filter) {
              return filter.key === "notcategoryIds";
            });

            if (categoryIds_and) {
              search.categoriesFilterOperator = categoryIds_and.value.length ? "and" : "or";
              categoryIds.push.apply(categoryIds, _toConsumableArray(categoryIds_and.value));
              additionalFilters.splice(additionalFilters.indexOf(categoryIds_and), 1);
            }

            if (notcategoryIds_and) {
              if (!notcategoryIds_and.value.length) {
                search.notCategoriesFilterOperator = "or";
                additionalFilters.splice(additionalFilters.indexOf(notcategoryIds_and), 1);
              } else {
                search.notCategoriesFilterOperator = "and";
                if (notcategoryIds) {
                  var _notcategoryIds_and$v;

                  (_notcategoryIds_and$v = notcategoryIds_and.value).unshift.apply(_notcategoryIds_and$v, _toConsumableArray(notcategoryIds.value));
                  additionalFilters.splice(additionalFilters.indexOf(notcategoryIds, 1));
                }
                notcategoryIds_and.key = "notcategoryIds";
                notcategoryIds = notcategoryIds_and;
              }
            }

            additionalFilters.forEach(function (filterObject) {
              if (notcategoryIds) {
                filterObject.value.forEach(function (category, idx, list) {
                  if (Ember.typeOf(category) === "object") {
                    var categoryObject = categorySerializer.normalize(_category.default, category);
                    var categoryInstance = _this.categoryService.categoryFactory();
                    categoryInstance.load(categoryObject.id, categoryObject);
                    list[idx] = categoryInstance;
                  }
                }, _this);
              }
            });

            categoryIds.forEach(function (category, idx, list) {
              if (Ember.typeOf(category) === "object") {
                var categoryObject = categorySerializer.normalize(_category.default, category);
                var categoryInstance = _this.categoryService.categoryFactory();
                categoryInstance.load(categoryObject.id, categoryObject);
                list[idx] = categoryInstance;
              }
            }, _this);
          };

          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = container.searches[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var search = _step2.value;

              _loop(search);
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }

        // Bundle-containers have `photoId` attribute that represents a pointer
        // to a content item that is actually persisted as a member of the
        // `photosContainers` rel. However we provide the UI for the management
        // of the relationship at the container level... very unfortunate.
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      function hydrateFromPhotoId(container) {
        var photoId = container.photoId;
        var photo = void 0;
        if (photoId) {
          photo = data.photosContainers && data.photosContainers.find(function (photo) {
            return photo.id === photoId;
          });
        }
        if (photo) {
          container.photos = [photo];
        }

        return container;
      }

      function moveTypeToUnderscoreType(item) {
        item._type = item.type;
        delete item["type"];
        return item;
      }

      function moveNestedUnderscoreTypes(item) {
        item.curations = item.curations && item.curations.map(moveTypeToUnderscoreType);
        item.searches = item.searches && item.searches.map(moveTypeToUnderscoreType);
        return item;
      }

      data.containers = data.containers.map(hydrateFromPhotoId).map(moveTypeToUnderscoreType).map(moveNestedUnderscoreTypes);

      return data;
    }
  });
});