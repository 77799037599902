define("copilot/models/search/adapters/giphy", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    page: 1,
    query: "",
    type: "",
    pages: 0,
    totalHits: 0,
    limit: 20,
    loading: false,
    searchError: false,
    placeholder: "Search Giphy...",

    init: function init() {
      this._super.apply(this, arguments);
      this.results = [];
      this.giphyParams = {};
      this.paginationCache = {};
    },


    fetch: (0, _emberConcurrency.task)(function* () {
      var query = this.query;
      var data = this.giphyParams;
      data.query = query;
      data.limit = this.limit;
      if (query.length === 0) {
        this.results.clear();
        return;
      }
      try {
        var results = yield Ember.$.getJSON("/api/search/giphy", data);
        this._populate(results);
      } catch (err) {
        this._handleError();
      }
    }),

    setPage: function setPage(page) {
      this.set("page", page);
    },
    setQuery: function setQuery(query) {
      this.set("query", query);
      this.set("giphyParams", {});
      this.setPage(1);
    },
    nextPage: function nextPage() {
      var gifsPerPage = this.get("giphyPagination.count");
      this.set("giphyParams.offset", gifsPerPage * this.page);
      this.incrementProperty("page");
      return this.fetch.perform();
    },
    previousPage: function previousPage() {
      this.decrementProperty("page");
      var gifsPerPage = this.get("giphyPagination.count");
      this.set("giphyParams.offset", gifsPerPage * (this.page - 1));
      return this.fetch.perform();
    },
    _populate: function _populate(data) {
      var results = data.meta.data.map(function (gif) {
        gif.resultType = "giphy";
        gif.constructor.metadata = {
          collectionName: "giphy"
        };
        return gif;
      });
      this.results.setObjects(results);
      this.set("giphyPagination", data.meta.pagination);
      var numPages = Math.ceil(data.meta.pagination.total_count / this.limit);
      this.set("pages", numPages);
      this.set("totalHits", data.meta.pagination.total_count);
    },
    _handleError: function _handleError() {
      this.results.clear();
      this.set("searchError", true);
    }
  });
});