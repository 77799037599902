define("copilot/brands/cnt/serializers/newsletter", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var LIST_COLLECTIONS = ["featureContentItems", "shortLinksItems"];

  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      LIST_COLLECTIONS.forEach(function (collection) {
        json[collection] = json[collection].map(function (item) {
          item.relId = item.embedded.id;
          return item;
        });
      });
      return json;
    }
  });
});