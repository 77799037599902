define("copilot/schema-helpers/autogen-contextual-dek", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["embedded.dek", "embedded.promoDek"]),

    // Runs in context of model
    compute: function compute(model) {
      var embedded = model.embedded;
      return embedded.promoDek || embedded.dek;
    }
  });
});