define("copilot/brands/vogue/components/fashion-galleries-selector/component", ["exports", "copilot/brands/vogue/mixins/existing-gallery-rels", "copilot/mixins/faux-dropdown", "lodash-es"], function (exports, _existingGalleryRels, _fauxDropdown, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_existingGalleryRels.default, _fauxDropdown.default, {
    tagName: "ul",
    classNames: ["fashion-galleries"],

    fashionshowModel: Ember.computed.reads("model"),
    hasRemainingGalleries: Ember.computed.notEmpty("remainingGalleryTypes"),

    remainingGalleryTypes: Ember.computed("existingGalleryTypes", function () {
      var allGalleryTypes = this.get("brandConfig.fashionGalleryTypes");

      return _lodashEs.default.xor(allGalleryTypes, this.existingGalleryTypes);
    }),

    actions: {
      addExistingGallery: function addExistingGallery(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            gallery = _ref2[0];

        var galleryType = gallery.get("galleryType");
        var relName = galleryType === "runway" ? "collection" : galleryType;
        this.get("model." + relName).setObjects([gallery]);
        this.sendAction("saveFashionshow");
        this.set("isSelectingContent", false);
      },
      findAsset: function findAsset() {
        this.set("isSelectingContent", true);
      },
      createGallery: function createGallery(type) {
        this.sendAction("createGallery", type);
      },
      removeGallery: function removeGallery(type) {
        this.sendAction("removeGallery", type);
      }
    }
  });
});