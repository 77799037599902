define("copilot/mixins/dragdrop-media-upload", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isOver: false,
    isNotOver: Ember.computed.not("isOver"),
    classNameBindings: ["isOver:dragover"],

    init: function init() {
      this._super.apply(this, arguments);
      if (typeof this.onDrop !== "function") {
        var error = new Error('Using App.DragdropImageUpload requires "onDrop" handler implementation');
        Ember.onerror(error);
      }
    },
    dragOver: function dragOver(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.isNotOver) {
        this.drag(e);
      }
    },
    dragLeave: function dragLeave(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.isOver) {
        this.drag(e);
      }
    },
    drag: function drag(e) {
      e.stopPropagation();
      e.preventDefault();

      if (e.type === "dragover") {
        e.dataTransfer.dropEffect = "copy"; // change cursor on dragover
        this.set("isOver", true);
      } else {
        this.set("isOver", false);
      }
    },
    drop: function drop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.drag(e);
      this.onDrop(e.dataTransfer.files);
    }
  });
});