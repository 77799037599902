define("copilot/components/main-search/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var RESULT_TYPES = {
    query: "query",
    advancedQuery: "advancedQuery",
    searchResult: "searchResult",
    searchResultArchived: "searchResultArchived",
    recentQuery: "recentQuery",
    recentSearchResult: "recentSearchResult"
  };

  /**
   * A search component used in the main nav
   *
   * The main-search component collects results from quick search based on its input
   * as well as providing recent search queries and recent selected results, and the
   * ability to browse via a pre-defined canned searches (for example, based on
   * content type)
   *
   * ```hbs
   * {{main-search
   *   quickSearch=(action "quickSearch")
   *   getRecentSearch=(action "getRecentSearch")
   *   getCannedSearch=(action "getContentTypeSearch")
   *   submitQuery=(action "submit")
   *   selectResult=(action "select")
   * }}
   * ```
   * @module components/main-search/component
   * @augments Ember.Component
   * @param {Function} quickSearch A closure action used to get search results
   * based on the current value of the search input
   * @param {Function} getRecentSearch A closure action used to get recent
   * searches. These results should contain either a `query` property, indicating a
   * previous search term, or an `id` and a `type` property, indicating a recent
   * search result item that was selected.
   * @param {Function} getCannedSearch A closure action used to return an array of
   * canned search options. Results should contain a `name`, `query`, and `options` object to
   * be used in the search. Ex: `{ name: "Articles", options: { type: 'article' }, query : ""}`
   * @param {Function} submitQuery A closure action invoked when the component will submit
   * a query term. This may occur when the form is submitted, a recent search term is selected,
   * or a pre-defined advanced search is selected.
   * @param {Function} selectResult A closure action invoked when a result item is selected, which
   * may be a search or recent result
   */

  exports.default = Ember.Component.extend({
    classNameBindings: ["expanded"],
    classNames: ["main-search"],
    analytics: Ember.inject.service(),
    query: "",
    RESULT_TYPES: RESULT_TYPES,
    isUrl: function isUrl(query) {
      try {
        new URL(query);
        return true;
      } catch (_) {
        return false;
      }
    },

    getQuickSearchResults: (0, _emberConcurrency.task)(function* (query) {
      try {
        var results = [{
          resultType: RESULT_TYPES.query,
          item: { query: query, options: { types: null } }
        }];
        if (query === "") {
          var recent = yield this.getRecentSearch();
          var canned = yield this.getCannedSearch();
          if (recent && recent.length) {
            results.push({
              labelKey: "components.main-search.recent",
              name: "recent",
              items: recent.map(function (item) {
                return {
                  resultType: item.query ? RESULT_TYPES.recentQuery : RESULT_TYPES.recentSearchResult,
                  item: item
                };
              })
            });
          }
          if (canned && canned.length) {
            results.push({
              labelKey: "components.main-search.canned",
              name: "canned",
              items: canned.map(function (item) {
                return {
                  resultType: RESULT_TYPES.advancedQuery,
                  item: item
                };
              })
            });
          }
        } else if (this.isUrl(query)) {
          var searchResult = yield this.quickSearch(query, { display: "all" });
          if (searchResult) {
            results.push.apply(results, _toConsumableArray(searchResult.hits.map(function (item) {
              if (item.meta.archived) {
                return {
                  resultType: RESULT_TYPES.searchResultArchived,
                  item: item
                };
              } else {
                return {
                  resultType: RESULT_TYPES.searchResult,
                  item: item
                };
              }
            })));
          }
        } else {
          var _searchResult = yield this.quickSearch(query);
          if (_searchResult) {
            results.push.apply(results, _toConsumableArray(_searchResult.hits.map(function (item) {
              return {
                resultType: RESULT_TYPES.searchResult,
                item: item
              };
            })));
          }
        }
        this.set("hasSearchError", false);
        return results;
      } catch (err) {
        this.set("hasSearchError", true);
        this.get("logger").error("Error during quick search", err);
        return [];
      }
    }),
    submit: (0, _emberConcurrency.task)(function* (query, options) {
      if (this.submitQuery) {
        yield this.submitQuery(query, options);
      }
      this.set("query", "");
    }),
    select: (0, _emberConcurrency.task)(function* (searchResult, index, query) {
      if (index === 0) {
        if (this.submitQuery) {
          yield this.submitQuery(this.query);
        }
      } else {
        if (this.selectResult) {
          var quickSearchIndex = index - 1;
          yield this.selectResult(searchResult, quickSearchIndex, query);
        }
      }
      this.set("query", "");
    }),
    actions: {
      submit: function submit(query, event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        return this.submit.perform(query);
      },
      select: function select(searchResult, index, query) {
        var resultType = searchResult.resultType,
            item = searchResult.item;

        if (!item) {
          return;
        }
        this.analytics.track("main-search", resultType, {
          index: index,
          hasQuery: !!this.query
        });
        switch (resultType) {
          case RESULT_TYPES.query:
            this.submit.perform(this.query);
            this.analytics.track(this.query ? "search" : "component", this.query ? "search" : "click", {
              index: index,
              query: this.query,
              "Event Target": "Copilot Navigation Search"
            });
            return;
          case RESULT_TYPES.advancedQuery:
            this.submit.perform(item.query, item.options);
            this.analytics.track(item.query ? "search" : "component", item.query ? "search" : "click", {
              index: index,
              query: item.query,
              filters: item.options,
              "Event Target": "Copilot Navigation Search"
            });
            return;
          case RESULT_TYPES.recentQuery:
            this.submit.perform(item.query, item.options);
            this.analytics.track("search", "search", {
              index: index,
              query: item.query,
              isRecent: true
            });
            return this.getQuickSearchResults.perform("");
          case RESULT_TYPES.recentSearchResult:
            this.select.perform(item, index, query);
            this.analytics.track("search", "search", {
              index: index,
              isRecent: true
            });
            return this.getQuickSearchResults.perform("");
          case RESULT_TYPES.searchResult:
            this.select.perform(item, index, query);
            this.analytics.track("search", "search", {
              index: index,
              isQuickSearch: true
            });
            return;
          default:
            return;
        }
      }
    }
  });
});