define("copilot/routes/category-manager", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    categoryService: Ember.inject.service("category"),
    user: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (!this.get("user.user.permissions.tagmanager.actions.create")) {
        transition.abort();
        this.transitionToExternal("notfound");
      }
    }
  });
});