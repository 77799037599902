define("copilot/components/image-cropper/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function parseAction(actionStr) {
    var split = void 0;
    if (typeof actionStr === "string") {
      split = actionStr.split(":");
      return split.length === 2 && split[0] === "action" ? split[1] : null;
    }
    return null;
  }

  exports.default = Ember.Component.extend({
    options: null,

    img: null,

    src: null,

    classNames: ["image-cropper-component"],

    _register: Ember.on("init", function () {
      if (this.get("parent")) {
        var cropperName = this.get("cropperName");
        this.get("parent").set(cropperName, this);
      }
    }),

    updateSrc: Ember.observer("src", function () {
      this.img.cropper("replace", this.src);
    }),

    replaceEventHandlers: Ember.on("init", function () {
      var _this = this;

      var options = this.get("options") || {};
      var optionKeys = Object.keys(options);

      optionKeys.forEach(function (key) {
        var callback = void 0;
        var action = parseAction(options[key]);
        if (action) {
          _this.set(action, action);
          options[key] = Ember.run.bind(_this, function (event) {
            this.sendAction(action, event);
          });
        } else if (typeof options[key] === "function") {
          callback = options[key];
          options[key] = Ember.run.bind(_this, function (event) {
            callback(event);
          });
        }
      });

      this.set("options", options);
    }),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, function () {
        _this2.img = _this2.$("img");
        _this2.img.cropper(_this2.get("options"));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$("img").cropper("destroy");
    }
  });
});