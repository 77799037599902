define("copilot/brands/cnt/models/spa", ["exports", "copilot/brands/cnt/models/venue"], function (exports, _venue) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Spa = _venue.default.extend({
    type: "spa"
  });

  Spa.reopenClass({
    url: "/api/spas"
  });

  exports.default = Spa;
});