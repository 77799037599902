define("copilot/helpers/split-rights", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        model = _ref2[0],
        field = _ref2[1];

    var val = void 0;
    var items = void 0;
    if (model) {
      items = model.split(";");
      if (items.length === 3) {
        switch (field) {
          case "Copyright":
            val = items[0];
            break;
          case "Assignment Number":
            val = items[1];
            break;
          case "Rights Usage":
            val = items[2];
            break;
        }
      } else {
        val = "——";
      }
    } else {
      val = "——";
    }

    return val;
  });
});