define("copilot/validations/brands/vogue/event", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "copilot/validations/brands/vogue/validators/event-date"], function (exports, _crossCheckDsl, _copilotValidators, _eventDate) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object,
      isBoolean = _crossCheckDsl.validators.isBoolean;


  var eventValidators = object({
    instantShow: isBoolean().on("publish"),
    livestream: isBoolean().on("publish"),
    brand: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    city: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    season: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    fashionshow: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  }).andAlso((0, _eventDate.default)());

  exports.default = (0, _crossCheckDsl.default)(eventValidators);
});