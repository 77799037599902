define("copilot/brands/cnt/models/review", ["exports", "copilot/models/base", "copilot/models/attr", "ember-model"], function (exports, _base, _attr, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;


  var Review = _base.default.extend({
    type: "review",
    title: (0, _emberModel.attr)(str, {
      defaultValue: "New Review"
    }),
    rating: (0, _emberModel.attr)(),
    comment: (0, _emberModel.attr)(),
    venue: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),
    contributorsAuthor: (0, _emberModel.hasMany)("contributor", {
      embedded: true
    }),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),

    titleGenerator: Ember.observer("venue.firstObject.name", "contributorsAuthor.firstObject.{name,text}", function () {
      var venueName = this.get("venue.firstObject.name");
      var contributorName = this.get("contributorsAuthor.firstObject.name") || this.get("contributorsAuthor.firstObject.text");

      this.set("title", "Review of " + venueName + " by " + contributorName);
    })
  });

  Review.reopenClass({
    url: "/api/reviews"
  });

  exports.default = Review;
});