define("copilot/components/build-switcher/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    environment: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    env: Ember.computed.reads("environment.deployEnvironment"),
    isOpen: false,

    willDestroyElement: function willDestroyElement() {
      if (this._clickOutside) {
        document.removeEventListener("click", this._clickOutside);
      }
    },
    open: function open() {
      var _this = this;

      this._clickOutside = function (evt) {
        if (_this.element === evt.target || _this.element.contains(evt.target)) {
          return;
        }
        _this.close();
      };
      document.addEventListener("click", this._clickOutside);
      this.set("isOpen", true);
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    },


    buildInfo: Ember.computed(function () {
      try {
        var content = document.querySelector('meta[name="copilot-labels"]').getAttribute("content");
        return JSON.parse(decodeURIComponent(content));
      } catch (e) {
        return {
          current: this.defaultBranch,
          labels: []
        };
      }
    }),

    defaultBranch: "main",
    currentBranch: Ember.computed("buildInfo.current", "env", function () {
      var branch = this.buildInfo.current;

      if (branch === this.env) {
        return this.defaultBranch;
      }
      return branch;
    }),

    branches: Ember.computed.reads("buildInfo.labels")
  });
});