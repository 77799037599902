define("copilot/models/bundleconfig-subtype", ["exports", "copilot/models/attr"], function (exports, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  var array = _attr.default.array,
      obj = _attr.default.obj;
  exports.default = Ember.Model.extend({
    type: attr(_attr.default.str),
    containers: attr(array),
    defaultTemplate: attr(_attr.default.str),
    description: attr(_attr.default.str),
    preview: attr(obj),
    publish: attr(obj),
    templates: attr(array),

    toString: function toString() {
      return "Bundle Config Subtype Model";
    }
  });
});