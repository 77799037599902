define("copilot/components/action-bar/component", ["exports", "copilot/lib/cookie", "ember-keyboard", "copilot/components/action-bar/template"], function (exports, _cookie, _emberKeyboard, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var actions = [{
    name: "cancel",
    iconClass: "",
    isCancel: true
  }, {
    name: "save",
    iconClass: "save",
    isSave: true
  }, {
    name: "preview",
    iconClass: "preview",
    isPreviewable: true
  }, {
    name: "viewLive",
    iconClass: "viewlive",
    isLive: true
  }, {
    name: "publish",
    iconClass: "publish",
    isPublish: true
  }, {
    name: "unpublish",
    iconClass: "trash",
    isUnpublish: true
  }, {
    name: "archiveOrRestore",
    iconClass: "archiveOrRestore",
    isArchive: true
  }, {
    name: "duplicate",
    iconClass: "",
    isDuplicate: true
  }];

  var actionMapPairs = actions.map(function (action) {
    return [action.name, action];
  });

  var actionsMap = new Map(actionMapPairs);

  var doesExist = function doesExist(something) {
    return !!something;
  };

  exports.default = Ember.Component.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    tagName: "",

    isConfirmingTakeover: false,

    brand: Ember.inject.service(),
    eventBus: Ember.inject.service("event-bus"),
    publish: Ember.inject.service(),

    // This gets the parent component's controller
    controller: Ember.computed.reads("_targetObject"),
    activeBrand: Ember.computed.reads("brand.activeBrand"),
    isPublishing: Ember.computed.alias("controller.isPublishing"),
    isUnpublishing: Ember.computed.alias("controller.isUnpublishing"),
    showUnsavedDialog: Ember.computed.reads("controller.showUnsavedDialog"),
    inProgress: Ember.computed.reads("eventBus.inAsyncProgress"),
    isPublishedOrQueued: Ember.computed.reads("publishStatus.isPublishedOrQueued"),
    isPublished: Ember.computed.reads("publishStatus.isPublished"),
    modelsAreDirty: Ember.computed.reads("controller.modelsAreDirty"),
    mainModel: Ember.computed.reads("controller.mainModel"),
    showContentOverwriteDialog: Ember.computed.alias("controller.showContentOverwriteDialog"),

    type: Ember.computed("mainModel", function () {
      var mainModel = this.get('mainModel');
      return mainModel.isNative ? 'native' : this.controller.pluralizedType;
    }),

    enableSeoTrigger: Ember.computed("mainModel", "controller.model", function () {
      // Presets need to get cloned thus this meta data must exist on the preset model
      var _filterBy = (this.get("mainModel.categories.copilot") || []).filterBy("slug", "preset"),
          _filterBy2 = _slicedToArray(_filterBy, 1),
          presetCategory = _filterBy2[0];

      var isPreset = !!presetCategory;
      var shouldShowSeoBarForContent = !!Ember.get(this.controller, "model.constructor.metadata.seoScoreable");
      var enableSeo = isPreset ? false : shouldShowSeoBarForContent;

      return enableSeo;
    }),

    enableLanguageDropdown: Ember.computed("mainModel", function () {
      // Presets need to get cloned thus this meta data must exist on the preset model
      var _filterBy3 = (this.get("mainModel.categories.copilot") || []).filterBy("slug", "preset"),
          _filterBy4 = _slicedToArray(_filterBy3, 1),
          presetCategory = _filterBy4[0];

      var isPreset = !!presetCategory;
      var enableLanguageDropdown = isPreset ? false : this.get("mainModel.isLocalizable");

      return enableLanguageDropdown;
    }),

    publishStatus: Ember.computed("mainModel", function () {
      var model = this.mainModel;
      var publishStatus = Ember.getOwner(this).factoryFor("model:publish-status");
      return publishStatus.create({ model: model });
    }),

    disablePreview: Ember.computed("controller.model.displayBrand", function () {
      var attributesMap = this.controller.mainModel.constructor.attributes;
      var hasDisplayBrandAttr = Array.from(attributesMap.keys()).includes("displayBrand");

      return hasDisplayBrandAttr && Ember.isEmpty(this.controller.mainModel.displayBrand);
    }),

    saveOnly: Ember.computed("componentActions", function () {
      return this.get("componentActions.length") === 1 && this.get("componentActions.firstObject.name") === "save";
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("keyboardActivated", true);
      this.set("actionBar", document.getElementById("action-bar"));
    },


    // By default non-publishable types only require the 'save' action
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var mainModelExists = !!this.mainModel;
      var noActionOptions = !this.actionOptions;
      var notPublishable = !this.get("mainModel.meta.publishable");

      if (mainModelExists && noActionOptions && notPublishable) {
        this.set("actionOptions", "save");
      }
    },


    // This generates the array of actions that is iterated through to show the buttons
    componentActions: Ember.computed("actionOptions", "mainModel", function () {
      var actionsWithoutCancelOrArchiveOrDuplicate = actions.filter(function (item) {
        var name = Ember.get(item, "name");
        return name !== "cancel" && name !== "archiveOrRestore" && name !== "duplicate";
      });
      var possibleActionsOptionsToDisplay = (this.actionOptions || "").split(",").filter(doesExist);
      var actionOptionsExists = possibleActionsOptionsToDisplay.length > 0;
      var componentActions = actionOptionsExists ? // Maps return undefined and need filtered
      possibleActionsOptionsToDisplay.map(function (actionString) {
        return actionsMap.get(actionString);
      }).filter(doesExist) : actionsWithoutCancelOrArchiveOrDuplicate;
      var primaryButton = componentActions.findBy("name", "publish") || componentActions.findBy("name", "save");
      if (primaryButton) {
        // eslint-disable-next-line ember/no-side-effects
        Ember.set(primaryButton, "isPrimary", true);
      }

      return componentActions;
    }),

    keyboardSave: Ember.on((0, _emberKeyboard.keyDown)("cmd+KeyS"), function (e) {
      e.preventDefault();
      if (!this.inProgress && this.get("controller.canSave")) {
        this.send("doAction", "save");
      }
    }),

    openApiEndpoint: Ember.on((0, _emberKeyboard.keyDown)("shift+ctrl+Enter"), function () {
      var path = window.location.pathname;
      var pathWithoutBrand = path.split("/").slice(2).join("/");
      var env = _cookie.default.get("env");
      var baseURL = env === "development" ? "http://" + window.location.hostname + ":3000" : window.location.origin;
      window.open(baseURL + "/api/" + pathWithoutBrand);
    }),

    actions: {
      doAction: function doAction(name) {
        if (!this.inProgress) {
          var isPublishedOrQueued = this.isPublishedOrQueued;

          if (name === "unpublish" && !isPublishedOrQueued) {
            return false;
          }
          // This is calling a method on the controller matching name
          this.controller.send(name);
        }
      },
      completePublish: function completePublish(pubData) {
        return this.controller.completePublish(pubData);
      },
      unpublish: function unpublish() {
        this.publish.unpublish(this.mainModel);
        this.controller.set("isUnpublishing", false);
      },
      forceSave: function forceSave() {
        return this.controller.forceSave();
      },
      viewLive: function viewLive(uri) {
        window.open(uri);
      }
    }
  });
});