define("copilot/services/set-archive-status", ["exports", "ember-inflector", "ic-ajax"], function (exports, _emberInflector, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    analytics: Ember.inject.service(),

    consoleLogger: Ember.inject.service(),

    user: Ember.inject.service(),

    execute: function execute(model, archive) {
      var _this = this;

      var id = Ember.get(model, "id");
      var type = Ember.get(model, "type");
      var action = archive ? "archive" : "unarchive";

      var url = "/api/" + (0, _emberInflector.pluralize)(type) + "/" + id + "/" + action;
      return (0, _icAjax.default)(url, {
        type: "POST",
        data: {
          revisionAuthor: Ember.get(this, "user.user.fullName")
        },
        tag: "mixins.archivable." + action
      }).then(function () {
        Ember.set(model, "meta.archived", action === "archive");
        Ember.get(_this, "analytics").track("content", action + "d", {
          contentType: type
        });
      }).catch(function (error) {
        Ember.get(_this, "consoleLogger").error(error);
      });
    }
  });
});