define("copilot/services/users", ["exports", "ic-ajax", "copilot/models/user"], function (exports, _icAjax, _user) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    consoleLogger: Ember.inject.service(),
    brand: Ember.inject.service(),
    activeBrand: Ember.computed.reads("brand.activeBrand"),

    users: Ember.A(),

    load: function load() {
      var url = "/uapi/users/?brand=" + this.activeBrand;

      return new Ember.RSVP.Promise(function (resolve) {
        if (this.get("users.length") > 0) {
          resolve(this.users);
        } else {
          (0, _icAjax.default)(url).then(function (data) {
            var users = data.users.map(function (userData) {
              return _user.default.create(userData);
            });
            this.users.setObjects(users);
            resolve(users);
          }.bind(this), function (error) {
            this.consoleLogger.error("Load users error" + error);
          }.bind(this));
        }
      }.bind(this));
    }
  });
});