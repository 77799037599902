define("copilot/models/category", ["exports", "ember-model", "@condenast/copilot-slug", "copilot/models/attr", "copilot/mixins/validatable"], function (exports, _emberModel, _copilotSlug, _attr, _validatable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var string = _attr.default.str,
      meta = _attr.default.meta,
      array = _attr.default.array;


  var Category = _emberModel.Model.extend(_validatable.default, {
    features: Ember.inject.service(),
    type: "category",
    id: (0, _emberModel.attr)(string),
    name: (0, _emberModel.attr)(string),
    slug: (0, _emberModel.attr)(string),
    l10ns: (0, _emberModel.attr)(array),
    slugifyOptions: Ember.computed("features.unicodeSlugs", function () {
      if (this.features.get("unicodeSlugs")) {
        return { allowAllLetterCharacters: true };
      }
      return {};
    }),
    config: Ember.computed(function () {
      var configService = Ember.getOwner(this).lookup("service:config");
      var categoryConfigs = configService.get("childrenCategories." + this.id);
      var rootConfigs = configService.get("rootCategories." + this.id);
      return Object.assign({
        selectable: true
      }, rootConfigs || categoryConfigs || {}, {
        isRootConfigured: !!rootConfigs
      });
    }),
    childCount: Ember.computed.reads("meta.childCount"),
    hasChildren: Ember.computed("meta.childCount", function () {
      return this.get("meta.childCount") !== 0;
    }),

    meta: (0, _emberModel.attr)(meta),
    revisionAuthor: (0, _emberModel.attr)(string),
    depth: Ember.computed.reads("ancestors.length"),
    hierarchy: Ember.computed("ancestors", function () {
      return [this].concat(_toConsumableArray(this.ancestors));
    }),
    hierarchyString: Ember.computed("hierarchy", function () {
      return this.hierarchy.toArray().reverse().mapBy("slug").join("/");
    }),
    ancestors: Ember.computed("parent.ancestors", function () {
      if (this.parent) {
        return [this.parent].concat(_toConsumableArray(this.get("parent.ancestors")));
      }
      return [];
    }),

    searchHierarchy: Ember.computed("hierarchy", function () {
      return this.hierarchy.map(function (category) {
        return category.get("slug");
      }).reverse().join("/");
    }),

    root: Ember.computed.reads("hierarchy.lastObject"),
    parent: (0, _emberModel.belongsTo)("category", { embedded: true }),

    rootSlug: Ember.computed.reads("root.slug"),

    isRoot: Ember.computed.empty("parent"),
    isHighlighted: false,
    computedSlug: Ember.computed("name", "isNew", function () {
      var name = this.name;
      var isNew = this.isNew;
      if (name && isNew) {
        return (0, _copilotSlug.default)(name, this.slugifyOptions);
      }
    }),
    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      slug: "computedSlug"
    },
    load: function load(id, hash) {
      var cachedRecord = Category.cachedRecordForId(id, Ember.getOwner(this));
      var childCount = cachedRecord && cachedRecord.get("meta.childCount");

      if (childCount) {
        if (hash.meta && hash.meta.childCount === undefined) {
          hash.meta.childCount = childCount;
        }
      }
      this._super.apply(this, arguments);

      // Ember throws an error if a computed property is invalidated multiple times
      // within a single render. To prevent this in the case of ancestors/hierarchy,
      // force the evaluation of the belongsTo relationship here
      this.parent;
    }
  });

  Category.reopenClass({
    url: "/api/categories"
  });

  exports.default = Category;
});