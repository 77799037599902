define("copilot/brands/vogue/models/city", ["exports", "copilot/models/base", "copilot/models/attr"], function (exports, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;


  var City = _base.default.extend({
    type: "city",

    name: Ember.attr(str),
    code: Ember.attr(),
    tzdata: Ember.attr(),
    searchTitle: Ember.computed.reads("name")
  });

  City.reopenClass({
    url: "/api/cities"
  });

  exports.default = City;
});