define("copilot/services/create-item", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    user: Ember.inject.service(),
    config: Ember.inject.service(),
    store: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    features: Ember.inject.service("features"),
    saveService: Ember.inject.service("save"),
    schemaHelpers: Ember.inject.service(),
    currentUser: Ember.computed.reads("user.user"),
    configService: Ember.inject.service("config"),
    draftName: Ember.computed(function () {
      var currentUser = this.currentUser;
      var now = (0, _moment.default)();

      return Ember.get(this, "intl").t("draftContent.untitled") + " / " + Ember.get(currentUser, "fullName") + " / " + now.format("LLL");
    }),
    targetUrl: Ember.computed(function () {
      var displayBrand = Ember.get(this, "displayBrand");
      var configService = Ember.get(this, "configService");
      var envUrl = Ember.get(this, "brandConfig.envUrl");
      var publishEnv = displayBrand ? configService.getConsumerHostnameByBrandCode(displayBrand) : envUrl;
      return "https://" + publishEnv + "/replace-text-with-target-slug";
    }),

    defaultSeoCategory: Ember.computed(function () {
      return this.get("brandConfig.seoCategories").findBy("default", true).value;
    }),
    initModel: function initModel(modelName) {
      var _this = this;

      var modelClass = Ember.get(this, "store").modelFor(modelName);
      var draftName = this.draftName;
      var targetUrl = this.targetUrl;
      var brandConfig = this.brandConfig;
      var config = this.config;
      var defaultAttributes = modelClass.defaultAttributes(modelName === 'redirect' ? targetUrl : draftName, brandConfig, config);
      if (this.get("features.verso")) {
        // writing to both `contentType` and `textMimeType`. This will allow consumer
        // side to phase out `contentType` at some point.
        defaultAttributes.contentType = "text/markdown;variant=conde-nast-us";
        defaultAttributes.textMimeType = "application/vnd.conde-nast-us+json";
      }

      // Also apply default values form schema-derived class metadata
      if (modelClass.metadata && modelClass.metadata.defaultValues) {
        Object.entries(modelClass.metadata.defaultValues).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];

          if (typeof value === "string" && value.charAt(0) === "@") {
            value = _this.schemaHelpers.lookup(value.slice(1)).compute();
          }
          defaultAttributes[key] = value;
        });
      }
      return Ember.get(this, "store").createRecord(modelName, defaultAttributes);
    },
    execute: function () {
      var _ref3 = _asyncToGenerator(function* (modelName) {
        var model = this.initModel(modelName);
        yield this.saveService.save(model);
        return model;
      });

      function execute(_x) {
        return _ref3.apply(this, arguments);
      }

      return execute;
    }()
  });
});