define("copilot/mixins/concurrent-editable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    participants: Ember.inject.service(),
    features: Ember.inject.service(),

    concurrentEditId: Ember.computed.reads("model.id"),

    activeUsers: Ember.computed("concurrentEditId", function () {
      return this.participants.find({
        id: this.concurrentEditId,
        brand: this.activeBrand
      });
    }),

    canLock: Ember.computed.reads("features.contentLocking"),

    isLocked: Ember.computed("canLock", "activeUsers.@each.{role,isThisBrowser}", function () {
      return this.canLock && !(this.activeUsers || []).filterBy("isThisBrowser", true).findBy("role", "editor");
    }),

    lockedBy: Ember.computed("activeUsers.@each.{role,isThisBrowser}", function () {
      var activeUsers = this.activeUsers || [];
      return activeUsers.filter(function (user) {
        return !user.isThisBrowser;
      }).findBy("role", "editor");
    })
  });
});