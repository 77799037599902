define("copilot/components/help-menu/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOpen: false,

    mailto: Ember.computed("supportEmail", "emailBody", function () {
      var body = "User: " + this.emailBody.uid + "\nBrand: " + this.emailBody.brand + "\nUrl: " + this.emailBody.url;
      var encodedBody = body.replace(/ /g, "%20").replace(/\n/gm, "%0D%0A");
      return "mailto:" + this.supportEmail + "?subject=" + encodeURI("Support Issue") + "&body=" + encodedBody;
    }),

    willDestroyElement: function willDestroyElement() {
      if (this._clickOutside) {
        document.removeEventListener("click", this._clickOutside);
      }
    },
    open: function open() {
      var _this = this;

      this._clickOutside = function (evt) {
        if (_this.element === evt.target || _this.element.contains(evt.target)) {
          return;
        }
        _this.close();
      };
      document.addEventListener("click", this._clickOutside);
      this.set("isOpen", true);
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    }
  });
});