define("copilot/brands/vogue/models/person", ["exports", "copilot/models/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Person = _base.default.extend();

  exports.default = Person;
});