define("copilot/helpers/is-allowed-native", ["exports", "copilot-components/helpers/is-allowed-native"], function (exports, _isAllowedNative) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _isAllowedNative.default;
    }
  });
});