define("copilot/brands/cnt/components/activity-features/component", ["exports", "copilot/brands/cnt/components/activity-features/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNameBindings: ["validation.messages:has-error:"],

    optionsPath: Ember.computed("subtypeIsSportsOutdoors", "secondaryTypeIsSpa", function () {
      if (this.subtypeIsSportsOutdoors) {
        return "activity.featureOptions.sportsOutdoors";
      }

      if (this.secondaryTypeIsSpa) {
        return "activity.featureOptions.spa";
      }
      return "";
    }),

    subtypeIsSportsOutdoors: Ember.computed.equal("model.subtype", "Sports-Outdoors"),

    secondaryTypeIsSpa: Ember.computed.equal("model.secondaryType", "spa"),

    shouldDisplayFeatures: Ember.computed("subtypeIsSportsOutdoors", "secondaryTypeIsSpa", function () {
      return this.subtypeIsSportsOutdoors || this.secondaryTypeIsSpa;
    })
  });
});