define("copilot/validations/brands/bon/article", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var articleValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["photosTout"])).andAlso(object({
    contributorsAuthor: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  })).andAlso(object({
    photosLede: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  }).or(object({
    videosLede: (0, _copilotValidators.length)({ min: 1 })
  })).on("publish"));

  exports.default = articleValidationBuilder;
});