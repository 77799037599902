define("copilot/helpers/format-search-phrase-value", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        value = _ref2[0],
        category = _ref2[1],
        _ref2$ = _ref2[2],
        locale = _ref2$ === undefined ? "en-GB" : _ref2$;

    if (!value) {
      return "--";
    }

    // We can receive a number with/without %, eg 18000, 250%, etc
    var isPercentage = value.indexOf("%") > -1;

    // We need to remove the % symbol to work with the number
    if (isPercentage) {
      value = value.replace("%", "");
    }

    value = parseInt(value);

    if (category === "news" && isPercentage && value >= 5000) {
      return "BREAKOUT";
    }

    // Format the number: 18000 -> 18,000
    value = value.toLocaleString(locale);

    if (category === "news") {
      return isPercentage ? "+" + value + "%" : value + " pts";
    }

    return value;
  });
});