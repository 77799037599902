define("copilot/initializers/config", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    Ember.debug("Config intializer");
    application.inject("route", "brandConfig", "service:brand-config");
    application.inject("component", "brandConfig", "service:brand-config");
    application.inject("controller", "brandConfig", "service:brand-config");
    application.inject("view", "brandConfig", "service:brand-config");
    application.inject("model", "brandConfig", "service:brand-config");
  }

  exports.default = {
    name: "brand-config",
    initialize: initialize,
    after: "service"
  };
});