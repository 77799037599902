define("copilot/brands/self/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    contentTypes: {
      itemsReviewed: "Items Reviewed",
      product: "Product"
    },
    fieldsetLegends: {
      appleNewsPushNotification: "Apple News Push Notification",
      itemsReviewed: "Items Reviewed",
      related: "Related Content"
    },
    fieldNames: {
      notificationMessage: "Notification Message",
      product: "Product",
      rating: "Rating",
      rubric: "Rubric (Homepage Kik)",
      slideCaption: "Slide Caption",
      slideBody: "Slide Body",
      cons: "Cons",
      itemsReviewed: {
        one: "Item Reviewed",
        other: "Items Reviewed"
      },
      photosTout: "Site Image",
      photosLede: "Top Image",
      videosLede: "Top Video/Gallery",
      pros: "Pros"
    }
  };
});