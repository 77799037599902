define("copilot/components/search-slat/getty-slat/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    date: Ember.computed("result.createAt", function () {
      return (0, _moment.default)(this.get("result.createAt"));
    })
  });
});