define("copilot/components/search-slat/-private/channel/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    channels: Ember.computed("result._embedded", function () {
      return this.get("result._embedded._categories.channels") || this.get("result._embedded._categories.sections");
    })
  });
});