define("copilot/schema-helpers/brands/p4k/publish-uri-hook", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    features: Ember.inject.service(),
    compute: function compute(model) {
      var enableCategoryChannels = this.features.get("enableCategoryChannels");
      var channelsCategory = enableCategoryChannels ? model.categoriesChannels : model.categories.channels;

      return channelsCategory && channelsCategory.firstObject && channelsCategory.firstObject.hierarchy.slice(0, -1).mapBy("slug").reverse().join("/");
    }
  });
});