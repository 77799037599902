define("copilot/lib/autocomplete/base-adapter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultQuery = {
    view: "edit",
    display: "all",
    archived: "false"
  };

  var queryTypes = {
    default: function _default(q) {
      return {
        query: q.term,
        types: q.type,
        size: q.size || 50,
        status: ["published"]
      };
    },
    brand: function brand(q) {
      return {
        query: q.term,
        types: q.type,
        size: q.size || 50
      };
    },
    city: function city(q) {
      return {
        query: q.term,
        types: q.type,
        size: q.size || 50
      };
    },
    designer: function designer(q) {
      return {
        query: q.term,
        types: q.type,
        size: q.size || 50
      };
    },
    contributor: function contributor(q) {
      return {
        query: q.term,
        size: q.size || 50,
        qt: "contributorLookup",
        status: ["published"]
      };
    },
    nonPublishable: function nonPublishable(q) {
      return {
        query: q.term,
        types: q.type,
        size: q.size || 50
      };
    },
    season: function season(q) {
      return {
        query: q.term,
        types: q.type,
        size: q.size || 50
      };
    }
  };

  exports.default = {
    search: function search(q) {
      var owner = Ember.getOwner(this);
      var searchService = owner.lookup("service:search");
      var nonPublishableTypes = this.get("brandConfig.nonPublishableTypes") || [];
      var queryParams = void 0;

      if (nonPublishableTypes.indexOf(q.type) > -1) {
        queryParams = queryTypes.nonPublishable(q);
      } else {
        queryParams = queryTypes[q.type] ? queryTypes[q.type](q) : queryTypes.default(q);
      }

      return searchService.execute(Object.assign({}, defaultQuery, queryParams)).catch(function (err) {
        // eslint-disable-next-line no-console
        console.warn("Search API response takes too long, custom request was canceled, (" + err + ")");
      });
    },
    remove: function remove(obj) {
      this.selection.removeObject(obj);
    }
  };
});