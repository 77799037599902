define("copilot/models/user", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    _id: "",
    id: Ember.computed.alias("_id"),
    slackUserId: "",
    email: "",
    firstName: "",
    lastName: "",
    permissions: null,
    uid: "",
    locale: "",
    brands: Ember.A(),
    loginProvider: "",
    fullName: Ember.computed("firstName", "lastName", function () {
      return this.firstName + " " + this.lastName;
    })
  });
});