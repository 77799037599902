define("copilot/helpers/format-search-match", ["exports", "@atjson/renderer-hir", "@atjson/offset-annotations", "@atjson/source-commonmark", "@atjson/document"], function (exports, _rendererHir, _offsetAnnotations, _sourceCommonmark, _document) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  class SearchMatch extends _document.InlineAnnotation {}
  SearchMatch.vendorPrefix = "copilot";
  SearchMatch.type = "search-match";

  class SearchMatchSource extends _offsetAnnotations.default {}
  SearchMatchSource.schema = [].concat(_toConsumableArray(_offsetAnnotations.default.schema), [SearchMatch]);

  class HTMLRenderer extends _rendererHir.default {
    *bold() {
      var html = yield;
      return "<strong>" + html.join("") + "</strong>";
    }
    *italic() {
      var html = yield;
      return "<em>" + html.join("") + "</em>";
    }
    *"search-match"() {
      var html = yield;
      return "<span class=\"search-match\">" + html.join("") + "</span>";
    }
    *"line-break"() {
      yield;
      return "<br>";
    }
    *paragraph() {
      var html = yield;
      return html;
    }
    *link(annotation) {
      var html = yield;
      return "<u title=\"" + annotation.attributes.url + "\">" + html.join("") + "</u>";
    }
    *root() {
      var html = yield;
      return html.join("");
    }
  }

  exports.default = Ember.Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        markdown = _ref2[0],
        query = _ref2[1];

    query = query || "";

    var doc = _sourceCommonmark.default.fromRaw(markdown || "").convertTo(SearchMatchSource);
    doc.where(function (a) {
      return a.type === "paragraph" && a.end < doc.content.length - 1;
    }).update(function (paragraph) {
      doc.addAnnotations(new _offsetAnnotations.LineBreak({
        start: paragraph.end - 1,
        end: paragraph.end
      }));
      doc.removeAnnotation(paragraph);
    });

    if (query.length > 0) {
      if (doc.content.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
        var index = doc.content.toLowerCase().indexOf(query.toLowerCase());
        doc.addAnnotations({
          type: "-copilot-search-match",
          start: index,
          end: index + query.length
        });
      } else {
        query.split(" ").forEach(function (word) {
          var index = doc.content.toLowerCase().indexOf(word.toLowerCase());
          while (index !== -1 && word.length > 0) {
            var end = index + word.length;
            doc.addAnnotations({
              type: "-copilot-search-match",
              start: index,
              end: end
            });
            index = doc.content.toLowerCase().indexOf(word.toLowerCase(), end + 1);
          }
        });
      }
    }

    return Ember.String.htmlSafe(HTMLRenderer.render(doc));
  });
});