define("copilot/routes/galleries/index/edit", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route", "ember-service-methods/inject"], function (exports, _unsavedWarning, _authenticatedRoute, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, {
    controllerName: "galleries/index",

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.hideCarousel();
      controller.set("currentSelection", null);
    },


    createSlide: (0, _inject.default)(),

    uploadAsset: (0, _inject.default)(),

    actions: {
      createSlideFromAsset: function createSlideFromAsset(gallery, addTo, asset) {
        return this.createSlide(gallery, asset, { addTo: addTo });
      },
      createSlideFromFile: function createSlideFromFile(gallery, addTo, file) {
        var _this = this;

        return this.uploadAsset(file).then(function (asset) {
          return _this.createSlide(gallery, asset, { addTo: addTo });
        });
      }
    }
  });
});