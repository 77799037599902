define("copilot/brands/epi/models/external", ["exports", "copilot/models/base", "ember-model"], function (exports, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var External = _base.default.extend({
    type: "external",
    hed: (0, _emberModel.attr)(),
    dek: (0, _emberModel.attr)(),
    rubric: (0, _emberModel.attr)(),
    url: (0, _emberModel.attr)(),
    taxonomy: (0, _emberModel.hasMany)("taxonomy", {
      embedded: true
    }),
    source: (0, _emberModel.attr)(),
    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),
    searchTitle: Ember.computed.reads("hed")
  });

  External.reopenClass({
    duplicatable: true,
    url: "/api/externals"
  });

  exports.default = External;
});