define("copilot/components/publish-info/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modifiedAt: Ember.computed("model.modifiedAt", function () {
      return new Date(Date.parse(this.model.modifiedAt));
    }),
    shouldDisplayGlobalDraftsBadge: Ember.computed("model.{syndication,meta,isGlobalDraft,_embedded}", function () {
      var metadataType = this.get("model.meta.modelName");
      var isLivePublished = this.get("model._embedded.publishHistory");
      var isArticleOrGallery = metadataType === "article" || metadataType === "gallery";
      var sourceId = this.get("model.syndication.sourceId");
      return isArticleOrGallery && this.model.isGlobalDraft && !isLivePublished && !sourceId;
    })
  });
});