define("copilot/mixins/secure-preset", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var presetPermissionActions = exports.presetPermissionActions = {
    read: "read",
    create: "create",
    update: "update",
    search: "search"
  };

  exports.default = Ember.Mixin.create({
    features: Ember.inject.service(),
    userService: Ember.inject.service("user"),
    init: function init() {
      this._super.apply(this, arguments);
    },
    redirectIfUserCantUsePresets: function redirectIfUserCantUsePresets(transition) {
      var _this = this;

      var permsToCheck = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      var permissionsExist = permsToCheck.map(function (permAction) {
        return _this.get("userService.user.permissions.presets.actions." + permAction);
      }).every(function (permission) {
        return !!permission;
      });
      var presetsFlagEnabled = this.features.get("presets");
      var canSeeFeature = presetsFlagEnabled && permissionsExist;

      var currentBrand = this.brandConfig.brand.activeBrand;

      if (!canSeeFeature) {
        transition.abort();
        this.transitionTo("dashboard", currentBrand);
      }
    }
  });
});