define("copilot/services/key-value-storage", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // Define storage on service instances, not prototype
    init: function init() {
      this._super.apply(this, arguments);
      this._storage = Object.create(null);
    },


    /**
     * Add a value to the store with the corresponding key.
     * @function
     * @param key
     * @param value
     */
    set: function set(key, value) {
      this._storage[key] = value;
      this.notifyPropertyChange(key);
    },


    /**
     * Fetch a value with the corresponding key
     * @function
     * @param key
     * @returns {*}
     */
    get: function get(key) {
      return this._storage[key];
    }
  });
});