define("copilot/mixins/force-save", ["exports", "copilot/mixins/copilot"], function (exports, _copilot) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _copilot.default.create(
  /** @lends copilot/mixins/force-save */
  {
    beforeSaveHook: function beforeSaveHook() {
      this._dirtyAttributes.pushObject("id");
    }
  });
});