define("copilot/brands/vf/models/article", ["exports", "copilot/models/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    publishUriHook: Ember.computed("categories.channels.firstObject", "channel", function () {
      // This should return the first descendent of the root.
      var depth = this.get("categories.channels.firstObject.depth");
      return this.get("categories.channels.firstObject.hierarchy." + (depth - 1) + ".slug");
    })
  });
});