define("copilot/components/markdown-contextual-editor/component", ["exports", "copilot/models/asset-embed"], function (exports, _assetEmbed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    brand: Ember.inject.service(),
    isCnevideo: Ember.computed.equal("model.asset.type", "cnevideo"),

    videoThumbnail: Ember.computed.reads("model.asset.imageUrl"),

    init: function init() {
      this._super.apply(this, arguments);
      var asset = this.asset;
      var brand = this.get("brand.activeBrand");
      this.model = _assetEmbed.default.create({
        asset: asset,
        brand: brand
      });
    },


    disabled: Ember.computed("uploading", "model.media.[]", function () {
      var uploading = this.uploading;
      var media = this.get("model.media");
      return uploading && Ember.isEmpty(media);
    }),

    actions: {
      submit: function submit() {
        var model = this.model;
        this.submit(model);
      }
    }
  });
});