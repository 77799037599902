define("copilot/validations/brands/wrd/review", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var reviewValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ReviewValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["promoHed"])).catch((0, _crossCheckDsl.mutePath)(["contentSource"])).catch((0, _crossCheckDsl.mutePath)(["photosTout"])).catch((0, _crossCheckDsl.mutePath)(["itemReviewed"])).catch((0, _crossCheckDsl.mutePath)(["seoTitle"])).catch((0, _crossCheckDsl.mutePath)(["socialTitle"])).catch((0, _crossCheckDsl.mutePath)(["awards"])).andAlso(_crossCheckDsl.validators.object({
    canonicalUrl: _crossCheckDsl.validators.isString().andThen((0, _copilotValidators.optional)((0, _copilotValidators.url)()))
  }));

  exports.default = reviewValidationBuilder;
});