define("copilot/components/tag-selector/component", ["exports", "copilot-components/mixins/form-inputs"], function (exports, _formInputs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var TagSelector = Ember.Component.extend(_formInputs.default, {
    classNames: ["tag-selector"],

    categoryService: Ember.inject.service("category"),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var categoriesTags = this.get("model." + this.field);
      this.set("selections", { tags: categoriesTags });
    },
    _updateSelections: function _updateSelections(newSelections) {
      this.model.set(this.field, newSelections.tags);
      this.set("selections", newSelections);
    },


    actions: {
      selectSearchResult: function selectSearchResult(category) {
        this.send("toggleSelection", category, true);
      },
      toggleSelection: function toggleSelection(category, isAddingCategory) {
        if (category.type !== "category") {
          return;
        }
        var newSelections = Object.assign({}, this.selections);
        var root = category.get("rootSlug");
        if (isAddingCategory) {
          newSelections[root] = [].concat(_toConsumableArray(newSelections[root] || []), [category]);
        } else {
          newSelections[root] = newSelections[root].filter(function (x) {
            return x.get("id") !== category.get("id");
          });
        }

        this._updateSelections(newSelections);
      },
      updateSelections: function updateSelections(newSelections) {
        this._updateSelections(newSelections);
      }
    }
  });

  TagSelector.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = TagSelector;
});