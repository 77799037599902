define("copilot/components/bundle-type-selector/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["bundle-type-selector", "page-container"],
    classNameBindings: ["inProgress:spinner-large", "inProgress:spinner-top"],
    attributeBindings: ["data-page-object-selector"],
    "data-page-object-selector": "bundle-type-selector",
    configService: Ember.inject.service("config"),
    selectedBundleType: null,
    bundleTypes: Ember.computed.reads("configService.arrangedBundleTypes"),
    bundleContainers: Ember.computed.reads("configService.arrangedBundleContainers"),
    actions: {
      setSelectedBundle: function setSelectedBundle(selectedBundleType) {
        this.set("selectedBundleType", selectedBundleType);
      }
    }
  });
});