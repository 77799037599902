define("copilot/brands/vogue/components/fashion-galleries-selector-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",

    classNames: ["fashion-gallery"],

    actions: {
      removeGallery: function removeGallery(type) {
        this.sendAction("removeGallery", type);
      }
    },

    gallery: Ember.computed("model", "type.value", function () {
      return this.get("model." + this.get("type.value") + ".firstObject");
    })
  });
});