define('copilot/components/livestoryupdate-feed/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Component.extend({
    tagName: "ul",
    classNames: ["update-feed"],
    loadingNextPage: false,
    showEmptyFeed: Ember.computed('updates.[]', function () {
      return !this.hasMore && Array.isArray(this.updates) && this.updates.every(function (update) {
        return update.showInFeed === false;
      });
    }),
    actions: {
      fetchNextPage: function () {
        var _ref = _asyncToGenerator(function* (livestoryId) {
          this.set('loadingNextPage', true);
          yield this.fetchNextPage(livestoryId);
          this.set('loadingNextPage', false);
        });

        function fetchNextPage(_x) {
          return _ref.apply(this, arguments);
        }

        return fetchNextPage;
      }()
    }
  });
});