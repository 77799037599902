define("copilot/mixins/memory-scroll", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      var scrollPositions = {};
      this._saveScrollPosition = Ember.run.bind(this, "saveScrollPosition", scrollPositions);
      this._applyScrollPosition = Ember.run.bind(this, "applyScrollPosition", scrollPositions);
      this._router.on("willTransition", this._saveScrollPosition);
      this._router.on("didTransition", this._applyScrollPosition);
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);

      this._router.off("willTransition", this._saveScrollPosition);
      this._router.off("didTransition", this._applyScrollPosition);
    },
    saveScrollPosition: function saveScrollPosition(scrollPositions) {
      scrollPositions[this._router.url] = this.get("controller.scrollTop");
    },
    applyScrollPosition: function applyScrollPosition(scrollPositions) {
      var _this = this;

      var scrollTop = scrollPositions[this._router.url] || 0;
      Ember.run.scheduleOnce("afterRender", function () {
        if (_this.isDestroyed || _this.controller.isDestroyed) {
          return;
        }
        _this.set("controller.scrollTop", scrollTop);
      });
    }
  });
});