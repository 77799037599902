define("copilot/components/category-picker/selection/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    rootSlug: Ember.computed.alias("root.slug"),

    actions: {
      sortItems: function sortItems(oldIndex, newIndex) {
        var items = this.selected;
        var item = items.objectAt(oldIndex);
        items.removeAt(oldIndex);
        items.insertAt(newIndex, item);
      },
      remove: function remove(category) {
        this.removeCategory(category);
      }
    }
  });
});