define("copilot/models/pushnotification", ["exports", "ember-model", "copilot/models/base", "copilot/models/attr"], function (exports, _emberModel, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var string = _attr.default.str;
  exports.default = _base.default.extend({
    type: "pushnotification",
    message: (0, _emberModel.attr)(string),
    pubDate: Ember.computed("_embedded.{queued.pubDate,publishHistory.pubDate}", function () {
      var queued = this.get("_embedded.queued.pubDate");
      var published = this.get("_embedded.publishHistory.pubDate");

      return queued || published || new Date().toISOString();
    })
  }).reopenClass({
    url: "/api/pushnotifications"
  });
});