define("copilot/components/search-slat/-private/language/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    translations: Ember.computed("result._embedded", function () {
      // Get brand default language
      var defaultLanguage = this.configService.config.language.default;

      // Get content language
      var contentLanguage = this.result.lang;

      // If languages are different get translations from embedded, otherwise return no translations
      return contentLanguage !== defaultLanguage ? this.get("result._embedded.translations") : '';
    })
  });
});