define("copilot/controllers/preview", ["exports", "copilot/mixins/previewable"], function (exports, _previewable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_previewable.default, {
    isResponsiveView: Ember.computed.not("contentTypeConfig.preview.useDesktopView"),

    analytics: Ember.inject.service(),

    actions: {
      togglePreviewType: function togglePreviewType() {
        var isResponsiveView = this.toggleProperty("isResponsiveView");
        this.analytics.track("content", "preview", {
          viewMode: isResponsiveView ? "mobile" : "desktop"
        }, {
          label: "viewMode"
        });
      }
    }
  });
});