define("copilot/validations/brands/lci/article", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  // Allows to append new rules to core article rules
  var articleValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).andAlso(object({
    //TODO: check lang is in array
    customAdvertisementZone: (0, _copilotValidators.optional)(_crossCheckDsl.validators.isString()).on("publish"),
    categoriesType: (0, _copilotValidators.length)({ min: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "taxonomies-allowed-minimum" }
      }];
    }).andThen((0, _copilotValidators.length)({ max: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "taxonomies-allowed-maximum" }
      }];
    })).on("publish"),
    categoriesLayout: (0, _copilotValidators.length)({ min: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "taxonomies-allowed-minimum" }
      }];
    }).andThen((0, _copilotValidators.length)({ max: 1 }).catch(function () {
      return [{
        path: [],
        message: { name: "taxonomies-allowed-maximum" }
      }];
    })).on("publish")
  }));

  // Returns the validation descriptors
  exports.default = articleValidationBuilder;
});