define("copilot/adapters/article", ["exports", "ic-ajax", "copilot/adapters/application"], function (exports, _icAjax, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    features: Ember.inject.service(),

    find: function find(record, id) {
      var _this = this;

      if (this.get("features.verso")) {
        var url = this.buildURL(record.constructor, id);

        // For GET requests we always want to get the data
        // Setting this to an empty string prevents 304's
        return (0, _icAjax.raw)(url, {
          url: url,
          method: "GET",
          accepts: {
            "*": "application/vnd.conde-nast-us+json"
          },
          headers: {
            "if-none-match": ""
          }
        }).then(function (result) {
          if (_this.get("features.optimisticLocking")) {
            var etag = result.jqXHR.getResponseHeader("ETag");
            record.etag = etag;
          }
          var data = result.response;
          _this.didFind(record, id, data);
          return record;
        });
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});