define("copilot/services/websockets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var mockSocket = Ember.Object.extend(Ember.Evented, {
    emit: function emit() {
      return;
    }
  });

  exports.default = Ember.Service.extend({
    configService: Ember.inject.service("config"),
    host: Ember.computed.alias("configService.config.copilotWSHost"),
    init: function init() {
      this._super.apply(this, arguments);
      this.sockets = Ember.A();
    },
    getConnection: function getConnection(namespace, options) {
      if (Ember.testing) {
        return mockSocket.create();
      }
      var url = namespace ? this.host + "/" + namespace : this.host;
      var socket = this.findSocket(url);

      if (socket && socket.socket.get("isConnected")) {
        return socket.socket;
      } else if (socket && !socket.socket.get("isConnected")) {
        socket.socket.connect();
        return socket.socket;
      }

      var owner = Ember.getOwner(this);
      socket = owner.factoryFor("model:socketio-helper").create();

      this.sockets.pushObject({
        url: url,
        socket: socket
      });

      socket.connect(url, options);
      return socket;
    },
    findSocket: function findSocket(url) {
      return this.sockets.findBy("url", url);
    }
  });
});