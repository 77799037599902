define("copilot/brands/vogue/routes/reviews/create", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    beforeModel: function beforeModel(transition) {
      transition.abort();
      this.transitionTo("/not-found");
    }
  });
});