define("copilot/components/poster-selector/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    thumbnails: Ember.computed.alias("model.thumbnails"),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("posterPreviewImage", this.get("model.posterImage"));
    },


    actions: {
      submit: function submit(poster) {
        this.submit(poster);
        this.dismiss();
      },
      previewPoster: function previewPoster(poster) {
        this.set("posterPreviewImage", poster);
      }
    }
  });
});