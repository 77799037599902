define("copilot/controllers/lists/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  exports.default = _base.default.extend({
    listConfig: alias("brandConfig.list"),
    env: alias("brandConfig.envUrl"),
    getChannel: Ember.computed(function () {
      var getFirstChannel = function () {
        for (var channel in this.brandConfig.channelOptions) {
          return channel;
        }
        return "";
      }.bind(this);
      return this.channel || getFirstChannel();
    }),

    previewUrl: Ember.computed("id", "wasPublished", function () {
      var published = this.wasPublished;
      var id = this.id;
      var host = "http://" + this.brandConfig.previewHostname + "/";
      return published ? host + "vf-hollywood?id=" + id + "&status=draft" : host + id + "&status=draft";
    }),

    nameDisabled: Ember.computed(function () {
      return !Ember.isEmpty(this.name);
    })
  });
});