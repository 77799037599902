define("copilot/serializers/gallery", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.items = json.items.map(function (item) {
        item.relId = item.embedded.id;
        item.embedded = {
          id: item.embedded.id,
          meta: item.embedded.meta,
          revision: item.embedded.revision
        };
        return item;
      });
      return json;
    }
  });
});