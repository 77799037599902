define("copilot/validations/brands/cnt/newsletter", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var newsletterValidationBuilder = object({
    date: (0, _copilotValidators.date)().catch(function () {
      return [{ path: [], message: { name: "newsletter-date" } }];
    })
  }).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = newsletterValidationBuilder;
});