define("copilot/schema-helpers/autogen-string-byline", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    dependentKeys: Object.freeze(["contributorsAuthor.[]"]),

    // Runs in context of model
    compute: function compute(model) {
      var authorsString = void 0;

      var authorNames = model.contributorsAuthor.map(function (author) {
        return Ember.get(author, "name");
      });

      if (authorNames.length) {
        var lastAuthor = authorNames.pop();

        if (Ember.isPresent(authorNames)) {
          authorsString = authorNames.join(", ") + " and " + lastAuthor;
        } else {
          authorsString = lastAuthor;
        }

        return _copilotMarkdown.default.sanitize("By " + authorsString);
      }
    }
  });
});