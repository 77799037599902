define("copilot/brands/wrdit/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    contentTypes: {
      itemsReviewed: "Items Reviewed",
      product: "Product"
    },
    fieldsetLegends: {
      itemsReviewed: "Items Reviewed"
    },
    fieldNames: {
      product: "Product",
      rating: "Rating",
      cons: "TIRED",
      itemsReviewed: {
        one: "Item Reviewed",
        other: "Items Reviewed"
      },
      pros: "WIRED"
    }
  };
});