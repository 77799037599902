define("copilot/brands/cnt/models/hotel", ["exports", "copilot/brands/cnt/models/venue", "ember-model", "copilot/models/attr"], function (exports, _venue, _emberModel, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ratingBlock = _attr.default.ratingBlock;
  var attr = Ember.attr;
  var obj = _attr.default.obj,
      str = _attr.default.str;


  var Hotel = _venue.default.extend({
    editorsPick: attr(Boolean),
    features: attr(obj),
    id: attr(),
    rooms: attr(Number),
    subtype: attr(str),
    type: "hotel",
    unicode: attr(str),
    ratingBlock: attr(ratingBlock),
    offers: (0, _emberModel.hasMany)("venue-offer", {
      embedded: "always"
    }),
    syndication: attr(obj)
  });

  Hotel.reopenClass({
    url: "/api/hotels"
  });

  exports.default = Hotel;
});