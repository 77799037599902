define("copilot/components/push-notifications/form/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["push-notification-form"],

    zoneAbbr: Ember.computed("model.pubDate", function () {
      var zone = _moment.default.tz.guess();
      var date = (0, _moment.default)(this.get("model.pubDate")).format("x");
      return _moment.default.tz.zone(zone).abbr(date);
    })
  });
});