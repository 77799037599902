define("copilot/brands/vogue/models/house", ["exports", "copilot/models/base", "ember-model"], function (exports, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var House = _base.default.extend({
    type: "house",

    name: Ember.attr(),

    brands: (0, _emberModel.hasMany)("brand", {
      embedded: true
    }),
    searchTitle: Ember.computed.reads("name")
  });

  House.reopenClass({
    url: "/api/houses"
  });

  exports.default = House;
});