define("copilot/validations/brands/cnt/bar", ["exports", "@condenast/cross-check-dsl", "copilot/validations/brands/cnt/venue"], function (exports, _crossCheckDsl, _venue) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var barValidators = (0, _crossCheckDsl.extend)(_venue.default);

  exports.default = (0, _crossCheckDsl.default)(barValidators);
});