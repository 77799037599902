define("copilot/validations/brands/epi/article", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var articleValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).andAlso(object({
    socialDescription: (0, _copilotValidators.required)().on("publish")
  })).andAlso((0, _copilotValidators.contributor)().on("publish").catch(function () {
    return [{ path: [], message: { name: "contributor-required-article" } }];
  })).catch((0, _crossCheckDsl.mutePath)(["channel"]));

  exports.default = articleValidationBuilder;
});