define("copilot/components/search-slat-list/component", ["exports", "resize-observer-polyfill"], function (exports, _resizeObserverPolyfill) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function requestIdleCallback(callback) {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(callback);
    } else {
      setTimeout(callback, 0);
    }
  }
  exports.default = Ember.Component.extend({
    classNames: ["search-slat-list"],

    areAllSelected: Ember.computed("results", "selection", function () {
      var selection = this.selection || [];
      return this.results.every(function (result) {
        return selection.indexOf(result) !== -1;
      });
    }),

    areAnySelected: Ember.computed("selection", function () {
      return (this.selection || []).length > 0;
    }),

    currentPage: Ember.computed("page", function () {
      return parseInt(this.page || 1, 10);
    }),

    totalPages: Ember.computed("totalNumberOfResults", "resultsPerPage", function () {
      return Math.ceil(this.totalNumberOfResults / this.resultsPerPage);
    }),

    hasNextPage: Ember.computed("currentPage", "totalPages", function () {
      return this.currentPage < this.totalPages;
    }),

    hasPreviousPage: Ember.computed("currentPage", function () {
      return this.currentPage > 1;
    }),

    init: function init() {
      var _this = this;

      this._super();
      this._results = new WeakMap();
      var root = Ember.testing ? document.querySelector("#ember-testing-container") : null;

      // IntersectionObserver is not supported by the browser
      if (!window.IntersectionObserver) {
        return;
      }

      this._observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          // only fire when the element enters the viewport, not when it exits
          if (entry.isIntersecting) {
            var _results$get = _this._results.get(entry.target),
                result = _results$get.result,
                index = _results$get.index;

            requestIdleCallback(function () {
              Ember.run(function () {
                if (_this.ontrack && !_this.isDestroyed) {
                  _this.ontrack({
                    result: result,
                    resultIndex: index
                  });
                }
              });
            });
          }
        });
      }, { threshold: 0.5, root: root });

      this._resizeObserver = new _resizeObserverPolyfill.default(function () {
        requestIdleCallback(function () {
          Ember.run(function () {
            if (_this.isDestroyed) {
              return;
            }
            var result = _this.element.querySelector(".result");
            if (result) {
              var size = result.getBoundingClientRect().width;
              _this.set("itemSize", size - 2);
            }
          });
        });
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._resizeObserver.observe(this.element);
    },
    willDestroyElement: function willDestroyElement() {
      this._observer.disconnect();
      this._resizeObserver.disconnect();
    },
    observe: function observe(result, index, element) {
      if (!this._results.get(element) && this._observer) {
        this._results.set(element, { result: result, index: index });
        this._observer.observe(element);
      }
    },
    unobserve: function unobserve(element) {
      if (this._observer) {
        this._results.delete(element);
        this._observer.unobserve(element);
      }
    },


    actions: {
      select: function select(item) {
        var selection = (this.selection || []).slice();

        var existingItem = selection.findBy("id", item.id);
        if (existingItem) {
          this.onselectionchange(selection.without(existingItem));
        } else {
          this.onselectionchange(selection.concat(item));
        }
      },
      selectAll: function selectAll() {
        if (this.areAnySelected) {
          this.onselectionchange([]);
        } else {
          this.onselectionchange(this.results);
        }
      },
      nextPage: function nextPage() {
        this.onpagechange(this.currentPage + 1);
      },
      previousPage: function previousPage() {
        this.onpagechange(this.currentPage - 1);
      }
    }
  });
});