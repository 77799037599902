define("copilot/models/bundle-container", ["exports", "copilot/models/embedded", "ember-model"], function (exports, _embedded, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _embedded.default.extend({
    // search: alias('searches.firstObject'),

    // Need to maintain a stub photos hasMany relationship here
    // because it is not actually part of the schema. We use it
    // to enable UI around photos, but in serialization the photo is
    // pushed to the parent's `photosContainer` property while only
    // a pointer (`photoId`) is maintained on the container.
    photos: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    })
  }).reopenClass({
    transient: true
  });
});