define("copilot/routes/category-manager/category/child", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    categoryService: Ember.inject.service("category"),
    model: function model(params) {
      return this.get("categoryService").find(params.child_category_id);
    }
  });
});