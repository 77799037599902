define("copilot/components/toggle-switch/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    disabled: false,
    checked: false
  });
});