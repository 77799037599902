define("copilot/mixins/list-item-properties", ["exports", "ember-model"], function (exports, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    embedded: (0, _emberModel.belongsTo)("base", {
      embedded: true,
      polymorphic: true
    }),

    provisionalTitle: Ember.computed("embedded.{name,title,hed,promoHed}", function () {
      var embedded = this.embedded;
      return Ember.get(embedded, "name") || Ember.get(embedded, "title") || Ember.get(embedded, "promoHed") || Ember.get(embedded, "hed") || "";
    }),

    contextualDek: Ember.computed("embedded.{dek,promoDek}", function () {
      var embedded = this.embedded;
      return Ember.get(embedded, "promoDek") ? Ember.get(embedded, "promoDek") : Ember.get(embedded, "dek");
    }),

    /*eslint ember/avoid-leaking-state-in-ember-objects: 0*/
    autogenerates: {
      hed: "provisionalTitle",
      dek: "contextualDek"
    },

    shouldPreFillHed: false,
    preFillHed: function preFillHed() {
      if (this.shouldPreFillHed) {
        this.set("hed", this.provisionalTitle);
      }
    }
  });
});