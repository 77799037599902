define("copilot/components/user-menu/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    features: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    isOpen: false,

    willDestroyElement: function willDestroyElement() {
      if (this._clickOutside) {
        document.removeEventListener("click", this._clickOutside);
      }
    },
    open: function open() {
      var _this = this;

      this._clickOutside = function (evt) {
        if (_this.element === evt.target || _this.element.contains(evt.target)) {
          return;
        }
        _this.close();
      };
      document.addEventListener("click", this._clickOutside);
      this.set("isOpen", true);
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    }
  });
});