define("copilot/validations/brands/tny/event", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var BASE_ISSUE = "(January|February|March|April|May|June|July|August|September|October|November|December)\\s\\d{1,2}";
  var SINGLE_ISSUE = new RegExp("^" + BASE_ISSUE + ",\\s\\d{4}$");
  var DOUBLE_ISSUE = new RegExp("^" + BASE_ISSUE + "\\s&\\s\\d{1,2},\\s\\d{4}$");
  var DOUBLE_ISSUE_TWO_MONTHS = new RegExp("^" + BASE_ISSUE + "\\s&\\s" + BASE_ISSUE + ",\\s\\d{4}$");
  var DOUBLE_ISSUE_TWO_YEARS = new RegExp("^" + BASE_ISSUE + ",\\s\\d{4}\\s&\\s" + BASE_ISSUE + ",\\s\\d{4}$");

  var eventValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.EventValidationsDescriptor).andAlso(object({
    lastReviewed: (0, _copilotValidators.optional)((0, _copilotValidators.format)(SINGLE_ISSUE).or((0, _copilotValidators.format)(DOUBLE_ISSUE)).or((0, _copilotValidators.format)(DOUBLE_ISSUE_TWO_MONTHS)).or((0, _copilotValidators.format)(DOUBLE_ISSUE_TWO_YEARS)).catch(function () {
      return [{ path: [], message: { name: "invalid", details: null } }];
    }))
  }));

  exports.default = eventValidationBuilder;
});