define("copilot/utils/forms", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.moveToEnd = moveToEnd;
  exports.moveAfter = moveAfter;
  function moveToEnd(form, control) {
    control.remove();
    form.append(control);
  }

  function moveAfter(targetControl, destinationControl) {
    targetControl.remove();
    destinationControl.after(targetControl);
  }
});