define("copilot/routes/content-edit/index", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route", "ember-inflector"], function (exports, _unsavedWarning, _authenticatedRoute, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, {
    beforeModel: function beforeModel(transition) {
      var contentTypeParam = transition.params.contentEdit.content_type;
      var brand = this.brandConfig.activeBrand;
      var collectionName = (0, _emberInflector.pluralize)(contentTypeParam);
      if (contentTypeParam !== collectionName) {
        return this.replaceWith(collectionName + ".index", brand, transition.params["contentEdit.index"].id);
      }

      var contentType = (0, _emberInflector.singularize)(collectionName);
      var isContentTypeSupported = this.configService.arrangedContentTypes[contentType];
      if (!isContentTypeSupported) {
        this.intermediateTransitionTo("/not-found", "not-found");
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },
    model: function model(params) {
      var contentType = (0, _emberInflector.singularize)(this.paramsFor("contentEdit")["content_type"]);
      return this.store.find(contentType, params.id);
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        window.dataLayer[0].content.pageType = model.type;
      }
    }
  });
});