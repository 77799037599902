define("copilot/brands/vogue/controllers/fashiongalleries/index", ["exports", "copilot/controllers/galleries/index"], function (exports, _index) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    itemClassName: "fashion-gallery-item",

    init: function init() {
      this._super.apply(this, arguments);
    },


    associatedDetailsGallery: Ember.computed.reads("model.fullFashionshow.detail.firstObject"),
    fashionshowId: Ember.computed.reads("model.fashionshow.id")
  });
});