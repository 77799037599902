define("copilot/validations/brands/tny/curatedsearch", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var curatedsearchValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.CuratedsearchValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["photosTout"]));

  exports.default = curatedsearchValidationBuilder;
});