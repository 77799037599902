define("copilot/helpers/name-initials", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var name = params[0];
    var initials = "";
    name.replace(/\s+/g, " ").split(" ").forEach(function (word) {
      initials += word.toUpperCase()[0];
    });
    return initials;
  });
});