define("copilot/services/main-model", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),

    activeModel: Ember.computed("router.{currentRouteName,currentURL}", function () {
      var routeName = this.router.currentRouteName;
      var controller = Ember.getOwner(this).lookup("route:" + routeName).controller;

      if (controller) {
        return controller.mainModel || controller.model;
      }
    })
  });
});