define("copilot/components/markdown-override-dialog/component", ["exports", "copilot/models/asset-embed"], function (exports, _assetEmbed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["markdown-override-dialog"],
    brand: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var brand = this.get("brand.activeBrand");
      this.models = this.selection.map(function (asset) {
        return _assetEmbed.default.create({ id: asset.id, asset: asset, brand: brand });
      });
    },


    actions: {
      submit: function submit() {
        this.submit(this.models);
      }
    }
  });
});