define("copilot/validations/brands/epi/cookbook", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var cookbookValidators = object({
    eCommLink: (0, _copilotValidators.optional)((0, _copilotValidators.url)("absolute"))
  }).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = cookbookValidators;
});