define("copilot/instance-initializers/options-for-config", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    appInstance.registerOptionsForType("configuration", {
      singleton: true,
      instantiate: false
    });
    appInstance.registerOptionsForType("locale", {
      singleton: true,
      instantiate: false
    });
    // appInstance.registerOptionsForType('model', {
    //   singleton: false,
    //   instantiate: false
    // });
    appInstance.registerOptions("model:query-builder", {
      singleton: false,
      instantiate: false
    });
  }

  exports.default = {
    name: "options-for-config",
    initialize: initialize
  };
});