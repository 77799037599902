define("copilot/validations/brands/cni/video", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var videoValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).andAlso(object({
    url: (0, _copilotValidators.url)().on("publish")
  })).catch((0, _crossCheckDsl.mutePath)(["body"]));

  exports.default = videoValidationBuilder;
});