define("copilot/serializers/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var CATEGORIES_DASH_REG_EXP = /^categories-.*$/i;
  var CATEGORIES_REG_EXP = /^categories.*$/i;
  var CATEGORIES_FIELD_REG_EXP = /categories-?/;

  /*TODO: ckung 12/13/2016 - this is a hack because belongsTo doesn't work all that
  great. We should change the parent relationship to hasMany and this code can go
  away
  */

  function mapPassThroughKeys(json, regEx) {
    var record = {};
    Object.keys(json).filter(function (key) {
      return !regEx.test(key);
    }).forEach(function (key) {
      return record[key] = json[key];
    });
    return record;
  }

  // For some reason Ember Model classes seem to have
  // more attributes on them that defined within the models,
  // as though the protoypes were sharing all attrs
  function getCategoryAttributes(klass) {
    var categoryAttributes = [];
    // let attributes = Array.from(klass.attributes.keys());
    // return attributes.filter(attr => {
    //   let meta = klass.metaForProperty(attr);
    //   let attrType = meta && meta.type;
    //   return attrType && attrType.name === 'categories';
    // });
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = klass.attributes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var meta = _ref2[1];

        var attrType = meta && meta.type;
        if (attrType && attrType.name === "categories") {
          categoryAttributes.push(key);
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return categoryAttributes;
  }

  function createCategory(factory, serializer) {
    return function (json) {
      var normalizedJSON = serializer.normalize(factory.class, json);
      var category = factory.create();
      category.load(normalizedJSON.id, normalizedJSON);
      return category;
    };
  }

  function normalizeCategoriesField(json, categoryRootsKeys, CategoryFactory, CategorySerializer) {
    return Object.keys(json).filter(function (field) {
      return CATEGORIES_DASH_REG_EXP.test(field);
    }).reduce(function (acc, field) {
      if (!categoryRootsKeys.includes(field)) {
        var categoriesFieldName = field.replace(CATEGORIES_FIELD_REG_EXP, "");
        acc[categoriesFieldName] = json[field].map(createCategory(CategoryFactory, CategorySerializer));
      }
      return acc;
    }, {});
  }

  function normalizeCategories(klass, CategoryFactory, CategorySerializer, json) {
    var normalized = mapPassThroughKeys(json, CATEGORIES_DASH_REG_EXP);
    var categoryRootsKeys = getCategoryAttributes(klass).map(Ember.String.dasherize);

    categoryRootsKeys.forEach(function (categoryKey) {
      var camelizedKey = Ember.String.camelize(categoryKey);
      if (json[categoryKey]) {
        normalized[camelizedKey] = json[categoryKey].map(createCategory(CategoryFactory, CategorySerializer));
      } else {
        normalized[camelizedKey] = [];
      }
    });

    var categoriesProperty = normalizeCategoriesField(json, categoryRootsKeys, CategoryFactory, CategorySerializer);
    if (Object.keys(categoriesProperty).length) {
      normalized.categories = categoriesProperty;
    }

    return normalized;
  }

  function serializeCategories(klass, json, relationKeys) {
    var serialized = mapPassThroughKeys(json, CATEGORIES_REG_EXP);
    var categoryRels = [];
    var categoryAttributes = getCategoryAttributes(klass);

    categoryAttributes.forEach(function (categoryAttribute) {
      if (json[categoryAttribute]) {
        var key = Ember.String.dasherize(categoryAttribute);
        serialized[key] = json[categoryAttribute];
        categoryRels.push(key);
      }
    });

    var categoriesKeys = json.categories && Object.keys(json.categories);
    if (categoriesKeys) {
      serialized.categories = json.categories;
      categoriesKeys.forEach(function (root) {
        serialized["categories-" + root] = json.categories[root];
        categoryRels.push("categories-" + root);
      });
    }
    relationKeys = relationKeys.concat(categoryRels);
    return Object.assign(serialized, { relationKeys: relationKeys });
  }

  function serializeRels(json, relName) {
    var rels = json[relName];
    if (rels != null) {
      json[relName] = rels.map(function (rel) {
        return {
          id: rel.id,
          meta: rel.meta,
          revision: rel.revision
        };
      });
    }

    return json;
  }

  exports.default = Ember.JSONSerializer.extend({
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    normalize: function normalize(klass, json) {
      var CategoryFactory = Ember.getOwner(this).factoryFor("model:category");
      var CategorySerializer = Ember.getOwner(this).lookup("serializer:category");
      var normalizedJSON = normalizeCategories.call(this, klass, CategoryFactory, CategorySerializer, json);
      return normalizedJSON;
    },
    serialize: function serialize(record) {
      var klass = record.constructor;
      var json = this._super.apply(this, arguments);

      // Send array of relationKeys so editorial service knows what attrs
      // to persist as rels for the resource
      var relationKeys = [];
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = klass.relationships[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _ref3 = _step2.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var key = _ref4[0];
          var meta = _ref4[1];

          if (meta.options.embedded !== "always") {
            relationKeys.push(key);
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      json = serializeRels(json, "inline");
      json = serializeRels(json, "backReferences");
      json = serializeRels(json, "related");
      return serializeCategories(klass, json, relationKeys);
    }
  });
});