define("copilot/components/page-layout/layout-preview/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  class NoPreviewImageError extends Error {
    constructor(slug, size) {
      super("There is no category-image-preview configuration for " + slug + " at size " + size);
    }
  }

  exports.default = Ember.Component.extend({
    previewImages: Ember.computed.reads("config.category-image-preview"),

    previewSize: "wide",

    previewImageName: Ember.computed("currentCategory", "previewSize", function () {
      if (!this.currentCategory) {
        return;
      }

      var categorySlug = this.currentCategory.slug;

      var imageName = this.get("previewImages." + categorySlug + "." + this.previewSize);

      if (!imageName) {
        this.onError(new NoPreviewImageError(categorySlug, this.previewSize));
      }

      return imageName;
    })
  });
});