define("copilot/utils/remove-attrs", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (klass) {
    klass.proto();

    for (var _len = arguments.length, attrs = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      attrs[_key - 1] = arguments[_key];
    }

    attrs.forEach(function (attr) {
      return delete klass.prototype[attr];
    });
    klass.superclass._attributes.removeObjects(attrs);
    klass.superclass._relationships && klass.superclass._relationships.removeObjects(attrs);
  };
});