define("copilot/models/bundle-search", ["exports", "copilot/models/embedded", "copilot/models/attr"], function (exports, _embedded, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var additionalFilters = _attr.default.additionalFilters;


  var Search = _embedded.default.extend({
    config: Ember.inject.service(),
    store: Ember.inject.service(),

    defaultLang: Ember.computed.readOnly("config.config.language.default"),

    lang: additionalFilters("additionalFilters", "lang"),

    notCategoryIds: additionalFilters("additionalFilters", "notcategoryIds"),

    primaryCategoryId: additionalFilters("additionalFilters", "primaryCategoryId"),

    filterCategories: Ember.computed("filters.categoryIds.[]", {
      get: function get() {
        return this.get("filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") !== "tags" && category.get("rootSlug") !== "channels";
        });
      },
      set: function set(key, value) {
        var newCategoryIds = this.filterCategoriesTags.concat(this.filterCategoriesChannels, value);
        this.set("filters.categoryIds", newCategoryIds);
        return value;
      }
    }),

    filterCategoriesTags: Ember.computed("filters.categoryIds.[]", {
      get: function get() {
        return this.get("filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") === "tags";
        });
      },
      set: function set(key, value) {
        var notTagCategoryIds = this.get("filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") !== "tags";
        });
        var newCategoryIds = notTagCategoryIds.concat(value);
        this.set("filters.categoryIds", newCategoryIds);
        return value;
      }
    }),

    filterCategoriesChannels: Ember.computed("filters.categoryIds.[]", {
      get: function get() {
        return this.get("filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") === "channels";
        });
      },
      set: function set(key, value) {
        var notChannelCategoryIds = this.get("filters.categoryIds").filter(function (category) {
          return category.get("rootSlug") !== "channels";
        });
        var newCategoryIds = notChannelCategoryIds.concat(value);
        this.set("filters.categoryIds", newCategoryIds);
        if (this.primaryChannelCheck && !Ember.isEmpty(value.firstObject)) {
          this.set("primaryCategoryId", [value.firstObject.id]);
        } else {
          this.primaryCategoryId.clear();
        }
        return value;
      }
    }),

    excludeCategories: Ember.computed("notCategoryIds.[]", {
      get: function get() {
        return this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") !== "tags" && category.get("rootSlug") !== "channels";
        });
      },
      set: function set(key, value) {
        var newNotCategoryIds = this.excludeCategoriesTags.concat(this.excludeCategoriesChannels, value);
        this.set("notCategoryIds", newNotCategoryIds);
        return value;
      }
    }),

    excludeCategoriesTags: Ember.computed("notCategoryIds.[]", {
      get: function get() {
        return this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") === "tags";
        });
      },
      set: function set(key, value) {
        var notTagCategories = this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") !== "tags";
        });
        var newNotCategoryIds = notTagCategories.concat(value);
        this.set("notCategoryIds", newNotCategoryIds);
        return value;
      }
    }),

    excludeCategoriesChannels: Ember.computed("notCategoryIds.[]", {
      get: function get() {
        return this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") === "channels";
        });
      },
      set: function set(key, value) {
        var notChannelCategories = this.notCategoryIds.filter(function (category) {
          return category.get("rootSlug") !== "channels";
        });
        var newNotCategoryIds = notChannelCategories.concat(value);
        this.set("notCategoryIds", newNotCategoryIds);
        return value;
      }
    }),
    primaryChannelCheck: Ember.computed("primaryCategoryId.[]", "filterCategoriesChannels.[]", {
      get: function get() {
        return !!this.primaryCategoryId.firstObject;
      },
      set: function set(key, value) {
        if (value && !Ember.isEmpty(this.filters.categoryIds)) {
          this.set("primaryCategoryId", [this.filterCategoriesChannels.firstObject.id]);
        } else {
          this.primaryCategoryId.clear();
        }
        return value;
      }
    })
  });

  Search.reopenClass({
    computedAttributes: ["lang", "notCategoryIds", "filterCategories", "filterCategoriesTags", "filterCategoriesChannels", "excludeCategories", "excludeCategoriesTags", "excludeCategoriesChannels", "primaryChannelCheck"],
    create: function create(props) {
      var model = this._super.apply(this, arguments);
      var owner = Ember.getOwner(this);
      Ember.setOwner(model, owner);
      if (Ember.isEmpty(props) || !Ember.isEmpty(props) && Ember.isEmpty(props.filters)) {
        var filtersModel = model.store.modelFor("bundle-filters").create(owner.ownerInjection());
        model.set("filters", filtersModel);
      }

      // If the brand has content language configuration, the default language code
      // should be included in the search as initial setup
      // see app/brands/cni/models/bundle-search.js
      if (model.defaultLang) {
        model.set("lang", [model.defaultLang]);
      }

      return model;
    }
  });

  exports.default = Search;
});