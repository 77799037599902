define("copilot/brands/vogue/routes/search/index", ["exports", "copilot/routes/search/index"], function (exports, _index) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    model: function model(params) {
      if (params) {
        params.nottypes = this.brandConfig.excludeSearchFilters.join(",");
      }
      return this._super.apply(this, arguments);
    }
  });
});