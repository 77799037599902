define("copilot/models/publish-status", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    statusText: Ember.computed("isPublishable", "isPublished", "_isQueued", function () {
      if (Ember.get(this, "isPublishable")) {
        if (Ember.get(this, "isPublished")) {
          return "published";
        }
        if (Ember.get(this, "_isQueued")) {
          return "scheduled";
        }
        return "draft";
      }
    }),

    isPublishable: Ember.computed("model.meta.publishable", function () {
      return Ember.get(this, "model.meta.publishable");
    }),

    hasPendingRevision: Ember.computed("model.{revision,_embedded.publishHistory.revision}", function () {
      if (!Ember.get(this, "isPublishedOrQueued")) {
        return;
      }
      var revision = Ember.get(this, "model.revision");
      var revsionInt = revision && parseInt(revision);
      var publishRevision = Ember.get(this, "model._embedded.publishHistory.revision");
      var queuedRevision = Ember.get(this, "model._embedded.queued.revision");
      var compareRevision = Ember.isPresent(publishRevision) ? publishRevision : queuedRevision;

      var compareRevisionInt = Ember.isPresent(compareRevision) && parseInt(compareRevision);
      if (Ember.isPresent(revsionInt) && Ember.isPresent(compareRevisionInt)) {
        return revsionInt > compareRevisionInt;
      }
    }),

    isPublished: Ember.computed("model._embedded.publishHistory.expired", function () {
      return Ember.get(this, "model._embedded.publishHistory.expired") === false;
    }),

    isPublishedOrQueued: Ember.computed.or("isPublished", "_isQueued"),

    publishDate: Ember.computed("isPublished", "_isQueued", function () {
      if (this.isPublished) {
        var pubDate = this.get("model._embedded.publishHistory.pubDate");
        return (0, _moment.default)(pubDate);
      } else if (this._isQueued) {
        var _pubDate = this.get("model._embedded.queued.pubDate");
        return (0, _moment.default)(_pubDate);
      }
    }),

    _isQueued: Ember.computed("model._embedded.queued.expired", function () {
      return Ember.get(this, "model._embedded.queued.expired") === false;
    })
  });
});