define("copilot/validations/brands/lci/contentreference", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  // Create rules to model rules
  var contentreferenceValidators = object({
    hed: (0, _copilotValidators.required)().on("save"),
    identifier: (0, _copilotValidators.required)().on("publish"),
    provider: (0, _copilotValidators.required)().on("publish"),
    lang: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  });

  // Returns the validation descriptors
  exports.default = contentreferenceValidators;
});