define("copilot/controllers/search/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    features: Ember.inject.service(),
    searchAnalytics: Ember.inject.service(),
    analytics: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    brand: Ember.inject.service(),

    queryParams: ["channel", "dateRange", "_dates", "display", "displayBrand", "nottypes", "collaborator", "contributor", "page", "query", "status", "subchannel", "types", "lang", "hierarchy"],

    query: null,
    lang: null,
    page: 1,
    types: null,
    nottypes: "cnevideos,categories,pushnotifications,livestoryupdates",
    status: null,
    channel: null,
    subchannel: null,
    display: null,
    displayBrand: null,
    dateRange: null,
    _dates: null,
    inputQuery: "",
    categories: {},
    collaboratorIds: [],
    contributorIds: [],
    resultsPerPage: 50,
    style: Ember.computed(function () {
      return localStorage.getItem("searchDisplayStyle") || "list";
    }),

    hitsReturned: Ember.computed.alias("model.hits.length"),
    totalHits: Ember.computed.alias("model.totalHits"),

    init: function init() {
      this._super.apply(this, arguments);
      this.selections = Ember.Object.create();
      this.allFilters = Ember.Object.create();
      // Track collaborator change
      this._lastCollaborator = "";
    },


    queryObserver: Ember.observer("query", function () {
      var value = this.query;
      if (value !== null) {
        this.set("inputQuery", value);
      }
    }).on("init"),

    dates: Ember.computed("_dates", {
      get: function get() {
        if (this._dates) {
          var _dates$split = this._dates.split(","),
              _dates$split2 = _slicedToArray(_dates$split, 2),
              from = _dates$split2[0],
              to = _dates$split2[1];

          return { from: from, to: to };
        } else {
          return null;
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set("_dates", value.from + "," + value.to);
        } else {
          this.set("_dates", value);
        }

        return value;
      }
    }),

    filterParams: Ember.computed.filter("queryParams", function (param) {
      return param !== "query" && param !== "page";
    }),

    areAllHidden: Ember.computed("selection.@each.meta", function () {
      var selection = this.selection || [];
      return selection.every(function (result) {
        return result.meta.archived;
      });
    }),

    collaborator: Ember.computed("collaboratorIds.[]", {
      get: function get() {
        var collaboratorIds = this.collaboratorIds;
        return collaboratorIds.join(",");
      },
      set: function set(key, value) {
        var collaboratorIds = value && value.split(",") || [];
        this.set("collaboratorIds", collaboratorIds);
        var collaborators = collaboratorIds.join(",");

        // Track effective usage of collaborator filter
        if (this._lastCollaborator !== collaborators) {
          this.set("_lastCollaborator", collaborators);
          var query = this.query;
          this.searchAnalytics.trackCollaboratorFilter({
            collaborators: collaborators,
            query: query
          });
        }

        return collaborators;
      }
    }),

    contributor: Ember.computed("contributorIds.[]", {
      get: function get() {
        return this.contributorIds.join(",");
      },
      set: function set(key, value) {
        var contributorIds = value && value.split(",") || [];
        this.set("contributorIds", contributorIds);
        var contributors = contributorIds.join(",");
        return contributors;
      }
    }),

    currentPage: Ember.computed("page", function () {
      return parseInt(this.page || 1);
    }),

    totalPages: Ember.computed("totalHits", "resultsPerPage", function () {
      return Math.ceil(this.totalHits / this.resultsPerPage);
    }),

    resultsMetaData: Ember.computed("hitsReturned", "totalHits", "currentPage", "totalPages", function () {
      return this.getProperties("hitsReturned", "totalHits", "currentPage", "totalPages");
    }),

    filterByType: function filterByType(filterType) {
      return this.searchFilters.findBy("type", filterType);
    },
    updateCategories: function updateCategories(categories) {
      var _this = this;

      categories.forEach(function (category) {
        return _this.categories[category.hierarchyString] = category;
      });
    },
    deserializeQueryParams: function deserializeQueryParams() {
      var _this2 = this;

      Object.values(this.allFilters).forEach(function (_ref) {
        var queryKey = _ref.queryKey,
            type = _ref.type,
            toSelection = _ref.toSelection;

        var queryValue = _this2.get(queryKey) || "";
        var paramValue = void 0;

        if (queryKey === "dateRange" && queryValue === "custom" && !_this2.dates) {
          paramValue = [];
          Ember.run.later(_this2, function () {
            _this2.set("dateRange", null);
          });
        } else if (queryKey === "hierarchy") {
          paramValue = queryValue.split(",").map(function (hierarchy) {
            return _this2.categories[hierarchy];
          }).filter(Ember.isPresent);
        } else {
          paramValue = queryValue.split(",").filter(Ember.isPresent);
        }
        _this2.selections.set(type, paramValue.map(toSelection).filter(Ember.isPresent));
      });
      this.initializeInputQuery();
    },
    initializeInputQuery: function initializeInputQuery() {
      if (!this.inputQuery) {
        this.set("inputQuery", this.query || "");
        this._track();
      }
    },


    // To remove all selected filter options when leaving route
    clearQueryParams: function clearQueryParams() {
      this.setProperties({
        categories: {},
        channel: null,
        dateRange: null,
        _dates: null,
        display: null,
        displayBrand: null,
        collaborator: null,
        contributor: null,
        _lastCollaborator: "",
        nottypes: this.nottypes,
        query: null,
        inputQuery: "",
        status: null,
        subchannel: null,
        types: null,
        lang: null,
        hierarchy: null
      });
    },


    // Track effective usage of search filters
    _track: function _track() {
      var selections = this.selections;
      var filters = Object.keys(selections).reduce(function (vals, key) {
        var filterOptionHash = {};
        filterOptionHash[key] = selections[key].mapBy("value").join();
        Object.assign(vals, filterOptionHash);
        return vals;
      }, {});
      var query = { filters: filters, query: this.query };

      this.analytics.storyEvent("Search Executed", {
        searchQuery: query,
        searchResultCount: this.get("model.totalHits"),
        searchPageSize: this.resultsPerPage
      });
    },
    setFilterSelection: function setFilterSelection(filterType, filterOptions, adding) {
      // Coerce single filterOptions to array of options
      filterOptions = Array.isArray(filterOptions) ? filterOptions : [filterOptions];
      var filterConfig = this.allFilters[filterType];
      var selections = this.selections;
      var optionsForType = selections[filterType] || [];
      if (adding) {
        // Some filters (e.g. Channel) only allow one option
        // to be selected at a time
        if (filterConfig.replace) {
          optionsForType = filterOptions;
        } else {
          optionsForType.pushObjects(filterOptions);
        }

        if (filterType === "date-range") {
          var dates = filterOptions.mapBy("dates")[0];
          if (dates) {
            this.set("dates", dates);
          } else {
            this.set("dates", null);
          }
        }

        if (filterType === "categories") {
          this.updateCategories(filterOptions.mapBy("data"));
        }
      } else {
        var options = optionsForType.filter(function (option) {
          return filterOptions.some(function (filterOption) {
            return filterOption.value === option.value;
          });
        });
        optionsForType.removeObjects(options);

        if (filterType === "date-range" && !optionsForType.any(function (option) {
          return option.dates;
        })) {
          this.set("dates", null);
        }
      }
      this.applyOptionsForType(filterConfig, optionsForType);
      this.set("page", null);
      this._track();
    },
    applyOptionsForType: function applyOptionsForType(filterConfig, options) {
      // TODO: make sorting a locale-specific UI concern
      var sortedOptions = options.sortBy("value");
      this.get("selections." + filterConfig.type).setObjects(sortedOptions);
      // Coerce empty values for null so query param is removed
      // from URL
      var queryValue = sortedOptions.mapBy("value").join() || null;
      this.set(filterConfig.queryKey, queryValue);
    },


    actions: {
      setSearchDisplayStyle: function setSearchDisplayStyle(style) {
        localStorage.setItem("searchDisplayStyle", style);
        this.notifyPropertyChange("style");
      },
      setQuery: function setQuery(value) {
        if (Ember.isEmpty(value)) {
          value = null;
        }
        this.set("query", value);

        // nullify page number if changing search query
        this.set("page", null);

        this._track();
      },
      setPage: function setPage(value) {
        this.set("page", value);
      },
      registerFilterOptions: function registerFilterOptions(type, config) {
        this.allFilters.set(type, config);
        if (!this.get("selections." + type)) {
          this.selections.set(type, Ember.A());
        }
      },
      applyOption: function applyOption(filterType, filterOption) {
        this.setFilterSelection(filterType, filterOption, true);
      },
      removeOption: function removeOption(filterType, filterOption) {
        this.setFilterSelection(filterType, filterOption, false);
      }
    }
  });
});