define("copilot/components/slide-carousel/carousel-item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  exports.default = Ember.Component.extend({
    classNames: ["carousel-slide"],
    classNameBindings: ["slide.isActive::inactive", "isSelected:selected"],
    tagName: "li",

    modelName: reads("slide.embedded.meta.modelName"),
    photo: reads("slide.embeddedPhoto"),

    index: Ember.computed("slide", function () {
      return this.get("gallery.items").mapBy("id").indexOf(this.get("slide.id")) + 1;
    }),

    count: reads("gallery.items.length"),

    isSelected: Ember.computed("selected.id", function () {
      return this.get("slide.id") === this.get("selected.id");
    })
  });
});