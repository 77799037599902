define("copilot/mixins/copilot", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    /**
     * Create method that takes an object definition and
     * adds any beforeSaveHooks before clearing it out.
     *
     * @method create
     * @param {obj} obj - mixin object
     * @return {Ember.Mixin} - final Ember.Mixin object
     */
    create: function create(obj) {
      var beforeSaveHook = obj.beforeSaveHook;
      var init = obj.init || Ember.$.noop;
      obj.init = function () {
        this._super.apply(this, arguments);
        init();

        if (Ember.isArray(this.beforeSaveHooks) && beforeSaveHook) {
          //we can do some cool stuff here, like cast the hook into
          //a promise and support async hooks
          this.beforeSaveHooks.addObject(beforeSaveHook);
        }
      };
      obj.beforeSaveHook = undefined;
      return Ember.Mixin.create(obj);
    }
  };
});