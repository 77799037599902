define("copilot/brands/cnt/models/venue-offer", ["exports", "copilot/models/embedded", "ember-model", "copilot/models/attr"], function (exports, _embedded, _emberModel, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var string = _attr.default.str,
      seller = _attr.default.seller;
  exports.default = _embedded.default.extend({
    type: "venue-offer",
    offerId: (0, _emberModel.attr)(),
    seller: (0, _emberModel.attr)(seller),
    purchaseUri: (0, _emberModel.attr)(string),
    shortUrl: (0, _emberModel.attr)()
  });
});