define("copilot/schema-helpers/brands/cni/primary-category", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    config: Ember.inject.service(),

    compute: function compute(model) {
      var categories = model.get("categories.sections");

      if (!(categories && categories.length)) {
        return;
      }
      // If a top-level category is present, we want to use it in the url
      // A top-level category is one that is a direct descendant of a root,
      // so has depth 1
      var primaryCategory = categories.findBy("depth", 1) || categories[0];

      return primaryCategory.get("slug");
    }
  });
});