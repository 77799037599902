define("copilot/helpers/item-title", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.itemTitle = itemTitle;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var PATHS = ["titleOverride", "hed", "name", "title", "promoHed", "images.original.filename", "filename", "section1Hed", "linkText", "question", "embedded.hed", "embedded.name", "embedded.title", "searchTitle"];

  function itemTitle(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        object = _ref2[0];

    var title = void 0;
    for (var i = 0; i < PATHS.length; i++) {
      var val = Ember.get(object, PATHS[i]);
      if (Ember.isPresent(val)) {
        title = val;
        break;
      }
    }

    return Ember.get(object, "type") === "photo" ? title : _copilotMarkdown.default.sanitize(title);
  }

  exports.default = Ember.Helper.helper(itemTitle);
});