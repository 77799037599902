define("copilot/components/page-layout/layout-selector/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hierarchy: Ember.computed.reads("config.toggle-level-hierarchy"),
    intl: Ember.inject.service(),

    shouldRecurse: Ember.computed("levelIndex", "hierarchy", function () {
      var levelIndex = this.levelIndex;
      if (levelIndex === this.hierarchy.length - 1) {
        return false;
      }
      return true;
    }),

    buttonsForLevel: Ember.computed("toggleDecisionTree", "levelLabel", function () {
      var _this = this;

      if (!this.toggleDecisionTree) {
        return;
      }

      var buttons = Object.keys(this.toggleDecisionTree);
      return buttons.filter(function (name) {
        return name !== "--noDisplay";
      }).map(function (name) {
        var button = _this.config["toggle-options"][_this.levelLabel].find(function (button) {
          return button.option === name;
        });
        var icon = button.icon;
        var translation = button.translation ? _this.intl.t("pageLayout.toggles." + button.translation) : null;

        return {
          name: name,
          icon: icon,
          translation: translation
        };
      }).sort(function (lhs, rhs) {
        var options = _this.config["toggle-options"][_this.levelLabel].map(function (button) {
          return button.option;
        });
        if (options.indexOf(lhs.name) > options.indexOf(rhs.name)) {
          return 1;
        } else if (options.indexOf(lhs.name) < options.indexOf(rhs.name)) {
          return -1;
        } else {
          return 0;
        }
      });
    }),

    currentLevelSelection: Ember.computed("currentToggleSelection", "levelLabel", function () {
      if (!this.currentToggleSelection) {
        return;
      }
      return this.currentToggleSelection[this.levelLabel];
    }),

    nextLevelDecisionTree: Ember.computed("currentLevelSelection", "toggleDecisionTree", function () {
      if (!this.currentToggleSelection) {
        return;
      }
      var currentLevelSelection = this.currentToggleSelection[this.levelLabel];
      return this.get("toggleDecisionTree." + (currentLevelSelection === null ? "--noDisplay" : currentLevelSelection));
    }),

    levelLabel: Ember.computed("levelIndex", function () {
      return this.hierarchy[this.levelIndex];
    })
  });
});