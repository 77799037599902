define("copilot/brands/epi/serializers/menu", ["exports", "copilot/serializers/application", "lodash-es"], function (exports, _application, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    _unzipCourses: function _unzipCourses(record) {
      record.get("courseRecipe").clear();
      record.get("courses").forEach(function (course) {
        var recipes = course.get("recipes").map(function (recipe) {
          return Ember.get(recipe, "embedded") || recipe;
        });

        record.get("courseRecipe").pushObjects(recipes);
      });
    },
    _makeStubs: function _makeStubs(json) {
      json.courses.forEach(function (course) {
        var stubbedRecipes = course.recipes.map(function (recipe) {
          return {
            relId: Ember.get(recipe, "embedded.id")
          };
        });

        course.recipes = stubbedRecipes;
      });
    },
    serialize: function serialize(record) {
      //transform to poopy schema
      this._unzipCourses(record);
      var json = this._super.apply(this, arguments);
      this._makeStubs(json);

      json.type = json.menuType;

      return _lodashEs.default.omit(json, "menuType");
    },
    normalize: function normalize(typeClass, hash) {
      hash.courses.forEach(function (course) {
        course.recipes.forEach(function (item) {
          var recipeData = hash.courseRecipe && hash.courseRecipe.find(function (recipe) {
            return recipe.id === item.relId;
          });
          item.embedded = recipeData;
        });
      });

      hash.menuType = hash.type;

      return this._super(typeClass, hash);
    }
  });
});