define("copilot/components/session-expiration/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    expiresAt: null,
    isOktaUser: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._willExpireSoonDidChange = setInterval(Ember.run.bind(this, "notifyPropertyChange", "willExpireSoon"), 60000);
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
      clearInterval(this._willExpireSoonDidChange);
    },


    willExpireSoon: Ember.computed("expiresAt", {
      get: function get() {
        return (0, _moment.default)(Ember.get(this, "expiresAt")).diff((0, _moment.default)(), "days") < 3 && !Ember.get(this, "isOktaUser");
      },
      set: function set(_, value) {
        clearInterval(this._willExpireSoonDidChange);
        return value;
      }
    })
  });
});