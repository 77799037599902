define("copilot/brands/vogue/components/gallery-link-to/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "span",
    classNames: ["gallery-link-to"],
    classNameBindings: ["hasImages", "notPublished"],

    galleryModel: Ember.computed("galleryType.value", "model", function () {
      return this.get("model.content." + this.get("galleryType.value") + ".firstObject");
    }),

    galleryId: Ember.computed("galleryModel", function () {
      return this.get("galleryModel.id");
    }),

    hasImages: Ember.computed.notEmpty("galleryModel.items"),

    notPublished: Ember.computed.not("galleryModel._embedded.publishHistory")
  });
});