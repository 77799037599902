define("copilot/schemas/contentreference", ["exports", "@condenast/copilot-core", "@condenast/cross-check-schema"], function (exports, _copilotCore, _crossCheckSchema) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _copilotCore.ContentReferenceRecord.merge({
    fields: {
      description: _crossCheckSchema.types.Text(),
      offers: _crossCheckSchema.types.List(_copilotCore.OfferRecord),
      editUrl: _crossCheckSchema.types.Text()
    }
  });
});