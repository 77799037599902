define("copilot/forms/brands/tny/event", ["exports", "@condenast/copilot-brand-overrides/dist/modules/forms/tny/event"], function (exports, _event) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _event.default;
    }
  });
});