define("copilot/validations/brands/bon/recipe", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var recipeValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.RecipeValidationsDescriptor).andAlso(object({
    photosTout: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 }).on("publish"))
  }));

  exports.default = recipeValidationBuilder;
});