define("copilot/routes/brand/index", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    brandConfig: Ember.inject.service(),
    defaultRoute: Ember.computed.reads("brandConfig.defaultRoute"),

    redirect: function redirect() {
      var redirectRoute = this.defaultRoute;
      this.transitionTo(redirectRoute);
    }
  });
});