define("copilot/instance-initializers/resolver-brand-service", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    // Give resolver a reference to brand service
    //
    // We do this on the prototype because it appears that during boot multiple
    // instances of the resolver are instantiated for some reason.
    var resolver = appInstance.lookup("resolver:main");
    var brandService = appInstance.lookup("service:brand");
    resolver.constructor.prototype.brandService = brandService;
  }

  exports.default = {
    initialize: initialize
  };
});