define("copilot/brands/vogue/models/previous-designer-item", ["exports", "copilot/mixins/list-item-properties", "copilot/models/embedded"], function (exports, _listItemProperties, _embedded) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;
  exports.default = _embedded.default.extend(_listItemProperties.default, {
    type: "previous-designer",

    startYear: attr(),
    endYear: attr()
  });
});