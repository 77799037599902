define("copilot/components/usage-info/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["usage-info"],
    type: Ember.computed.reads("model.type"),
    showUsageInfo: null
  });
});