define("copilot/routes/presets/create", ["exports", "copilot/routes/authenticated-route", "ember-service-methods/inject", "copilot/mixins/secure-preset"], function (exports, _authenticatedRoute, _inject, _securePreset) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _authenticatedRoute.default.extend(_securePreset.default, {
    user: Ember.inject.service(),
    createPreset: (0, _inject.default)(),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var requiredPermissions = [_securePreset.presetPermissionActions.create];
      this.redirectIfUserCantUsePresets(transition, requiredPermissions);
    },
    model: function () {
      var _ref = _asyncToGenerator(function* () {
        //some subclasses define a custom edit route name
        var brand = Ember.get(this, "user.activeBrand");
        var model = yield this.createPreset();
        this.replaceWith("presets.edit", brand, model);

        return model;
      });

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});