define("copilot/brands/p4k/models/event", ["exports", "copilot/models/attr", "copilot/models/base", "ember-model"], function (exports, _attr, _base, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var array = _attr.default.array,
      obj = _attr.default.obj,
      str = _attr.default.str;


  var Event = _base.default.extend({
    type: "event",

    // model fields
    name: (0, _emberModel.attr)(str),
    description: (0, _emberModel.attr)(str),
    startDate: (0, _emberModel.attr)(),
    endDate: (0, _emberModel.attr)(),
    location: (0, _emberModel.attr)(obj),
    url: (0, _emberModel.attr)(str),

    photosTout: (0, _emberModel.hasMany)("media", {
      embedded: true,
      polymorphic: true
    }),

    tags: (0, _emberModel.attr)(array),

    relatedArtists: (0, _emberModel.hasMany)("musicgroup", {
      embedded: true
    }),
    searchTitle: Ember.computed.reads("name")
  });

  Event.reopenClass({
    url: "/api/events"
  });

  exports.default = Event;
});