define("copilot/controllers/presets/edit", ["exports", "copilot/controllers/base", "copilot/mixins/saveable", "ember-service-methods/inject", "ember-concurrency", "ic-ajax"], function (exports, _base, _saveable, _inject, _emberConcurrency, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _base.default.extend(_saveable.default, {
    archive: (0, _inject.default)(),
    unarchive: (0, _inject.default)(),
    activeBrand: Ember.computed.alias("brand.activeBrand"),

    clonePreset: function () {
      var _ref = _asyncToGenerator(function* (presetId) {
        var basePreset = yield this.store.find("article", presetId);
        // This has the revision id as well and is used for duplication
        var href = Ember.get(basePreset, "_links.self.href");
        var clonedPresetData = yield (0, _icAjax.default)("/api/duplicate", {
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            href: href
          })
        });
        var clonedPreset = yield this.store.find("article", clonedPresetData.id);

        clonedPreset.metadata.publishData = basePreset.metadata.publishData;
        clonedPreset.metadata.seo = basePreset.metadata.seo;

        yield this.saveService.save(clonedPreset);
        var analyticsData = {
          sourcePresetId: presetId,
          newPresetId: clonedPreset.id
        };

        this.analytics.track("content", "clone preset", analyticsData);
        return clonedPreset;
      });

      function clonePreset(_x) {
        return _ref.apply(this, arguments);
      }

      return clonePreset;
    }(),


    duplicate: (0, _emberConcurrency.task)(function* () {
      var presetId = this.get("model.id");
      var newPreset = yield this.clonePreset(presetId);

      window.open("/" + this.activeBrand + "/presets/" + newPreset.id);
    }).drop(),

    actions: {
      archiveOrRestore: function archiveOrRestore() {
        var model = this.mainModel;
        var action = model.meta.archived ? "unarchive" : "archive";
        return this[action](model);
      },
      duplicate: function duplicate() {
        this.duplicate.perform();
      }
    }
  });
});