define("copilot/services/intl", ["exports", "ember-intl"], function (exports, _emberIntl) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberIntl.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.setLocale("en-GB"); // override default locale for unit + integration tests
    },
    t: function t(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // htmlSafe only applies to the translation template itself; the options are always escaped to prevent XSS
      return this._super(key, Object.assign({ htmlSafe: true }, options));
    }
  });
});