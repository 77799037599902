define("copilot/components/category-dropdown/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ["category-dropdown"],

    pathSeparator: Ember.computed(function () {
      return this.intl.t("category-manager.pathSeparator");
    }),

    loadInitial: (0, _emberConcurrency.task)(function* () {
      if (this.initialCategories) {
        return this.initialCategories;
      }

      var initial = yield this.search({
        isroot: true,
        hierarchy: this.roots,
        meta: "childCount"
      });

      // if no roots are specified, default to the configured roots
      if (!this.roots) {
        initial = initial.filterBy("config.isRootConfigured");
        this.set("roots", initial.mapBy("rootSlug").join(","));
      }

      // if we get one root, immediately expand to its children
      if (initial.length === 1) {
        initial = yield this.search({
          parentid: initial.objectAt(0).id,
          meta: "childCount"
        });
      }

      initial = initial.sortBy("name");
      this.set("initialCategories", initial);
      return initial;
    }),

    actions: {
      fetchChildren: function fetchChildren(category) {
        var _this = this;

        return this.search({
          parentid: category.id,
          meta: "childCount"
        }).then(function (categories) {
          var notHierarchies = _this.notHierarchies;
          if (notHierarchies && notHierarchies.length) {
            var allowedCategories = _this.filterCategories(categories, notHierarchies);
            return allowedCategories.sortBy("name");
          }
          return categories.sortBy("name");
        });
      },
      onclear: function onclear() {
        this.set("showSearchResult", false);
      },
      onsearch: function onsearch(term) {
        this.set("showSearchResult", !Ember.isBlank(term));
      },
      selectSearchResult: function selectSearchResult(category) {
        this.send("onclear");
        this.send("toggleSelection", category, true);
      },
      toggleSelection: function toggleSelection(category, isAddingCategory) {
        if (category.type !== "category") {
          return;
        }
        var newSelections = Object.assign({}, this.selections);
        var root = category.get("rootSlug");
        if (isAddingCategory) {
          newSelections[root] = [].concat(_toConsumableArray(newSelections[root] || []), [category]);
        } else {
          newSelections[root] = newSelections[root].filter(function (x) {
            return x.get("id") !== category.get("id");
          });
        }

        this.onupdate(newSelections, { category: category, isAddingCategory: isAddingCategory });
      }
    }
  });
});