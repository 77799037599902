define("copilot/validations/brands/vogue/fashionshow", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "copilot/validations/brands/vogue/validators/published"], function (exports, _crossCheckDsl, _copilotValidators, _published) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object,
      isBoolean = _crossCheckDsl.validators.isBoolean;


  var fashionshowValidators = object({
    instantShow: isBoolean(),
    showLivestream: isBoolean(),
    highlighted: isBoolean(),
    showInFeed: isBoolean(),
    brand: (0, _copilotValidators.length)({ min: 1 }).andThen((0, _published.default)().on("publish")),
    season: (0, _copilotValidators.length)({ min: 1 }).andThen((0, _published.default)().on("publish")),
    city: (0, _copilotValidators.length)({ min: 1 }).on("publish")
  });

  exports.default = (0, _crossCheckDsl.default)(fashionshowValidators);
});