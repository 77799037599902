define("copilot/validations/brands/cnt/hotel", ["exports", "@condenast/cross-check-dsl", "copilot/validations/brands/cnt/venue", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _venue, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var hotelValidators = (0, _crossCheckDsl.extend)(_venue.default).andAlso(object({
    unicode: (0, _copilotValidators.optional)((0, _copilotValidators.unicode)()),
    rooms: (0, _copilotValidators.optional)((0, _copilotValidators.integer)())
  }));

  exports.default = (0, _crossCheckDsl.default)(hotelValidators);
});