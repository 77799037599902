define("copilot/brands/vogue/serializers/season", ["exports", "copilot/serializers/application", "lodash-es"], function (exports, _application, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      hash.annualSeasonValue = hash.annualSeason;
      hash.seasonType = hash.type;
      return hash;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      json.annualSeasonValue = json.annualSeason;
      var combinationFields = [json.annualSeasonValue, json.year];
      json.annualSeasonName = "" + combinationFields.join(" ");
      json.annualSeasonCode = "" + combinationFields.join("-").toLowerCase();
      json.type = json.seasonType;
      return _lodashEs.default.omit(json, ["annualSeasonValue", "seasonType"]);
    }
  });
});