define("copilot/schemas/brands/cni/nativevideo", ["exports", "@condenast/copilot-brand-overrides/dist/modules/schemas/cni/nativevideo"], function (exports, _nativevideo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _nativevideo.default;
    }
  });
});