define("copilot/models/clip", ["exports", "copilot/models/media"], function (exports, _media) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Clip = _media.default.extend({
    contributorTypeOptionsKey: "contributorTypeOptions.photo",

    fileSizeLimit: Ember.computed.reads("configService.config.clip.fileSizeLimit"),
    posterImage: Ember.computed("photosTout.firstObject", "_embedded.photosTout.firstObject.fields", function () {
      return this.get("photosTout.firstObject") || this.get("_embedded.photosTout.firstObject.fields");
    }),

    thumbnail: Ember.computed.reads("posterImage"),

    masterURL: Ember.computed("aspectRatios", function () {
      var master = this.aspectRatios.findBy("key", "master");
      return master.get("url");
    })
  });

  exports.default = Clip;
});