define("copilot/routes/brands/create", ["exports", "copilot/routes/create"], function (exports, _create) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _create.default;
    }
  });
});