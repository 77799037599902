define("copilot/components/callout-editor/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    callout: null,
    options: Ember.computed.alias("brandConfig.md.callouts"),
    actions: {
      submit: function submit() {
        var callout = this.callout;
        if (Ember.isPresent(callout)) {
          this.submit(callout);
        } else {
          this.closeModal();
        }
      }
    }
  });
});