define("copilot/services/create-preset", ["exports", "moment", "lodash-es", "ember-service-methods/inject"], function (exports, _moment, _lodashEs, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var versoMimeAndContentTypes = {
    contentType: "text/markdown;variant=conde-nast-us",
    textMimeType: "application/vnd.conde-nast-us+json"
  };
  var PRESET_HIERARCHY = "copilot/preset";
  var COPILOT_FUNCTIONAL_SLUG = "copilot";

  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    user: Ember.inject.service(),
    config: Ember.inject.service(),
    store: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    features: Ember.inject.service("features"),
    saveService: Ember.inject.service("save"),
    schemaHelpers: Ember.inject.service(),
    currentUser: Ember.computed.reads("user.user"),
    category: Ember.inject.service(),
    search: (0, _inject.default)(),

    generateDraftName: function generateDraftName(fullName) {
      var now = (0, _moment.default)();

      return this.intl.t("draftContent.untitled") + " / " + fullName + " / " + now.format("LLL");
    },
    generateDefaultOptions: function generateDefaultOptions(defaultValues) {
      var _this = this;

      var isComputedValue = function isComputedValue(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            value = _ref2[1];

        return typeof value === "string" && value.charAt(0) === "@";
      };
      // Convert map into an array of key values and split based on computed fields and none computed fields

      var _partition = (0, _lodashEs.partition)((0, _lodashEs.toPairs)(defaultValues), isComputedValue),
          _partition2 = _slicedToArray(_partition, 2),
          entriesThatNeedComputed = _partition2[0],
          nonComputedEntries = _partition2[1];

      var computedEntries = entriesThatNeedComputed.map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            key = _ref4[0],
            value = _ref4[1];

        var newValue = _this.schemaHelpers.lookup(value.slice(1)).compute();

        return [key, newValue];
      });
      var defaultValuePairs = [].concat(_toConsumableArray(computedEntries), _toConsumableArray(nonComputedEntries));
      // Reconsitute the key values into an object
      var defaultOptions = (0, _lodashEs.fromPairs)(defaultValuePairs);

      return defaultOptions;
    },
    generateVersoOptions: function generateVersoOptions() {
      var shouldAddVersoMimeAndContentTypes = [this.get("features.verso")].includes(true);
      var versoOptions = shouldAddVersoMimeAndContentTypes ? versoMimeAndContentTypes : {};

      return versoOptions;
    },
    generatePresetOptions: function () {
      var _ref5 = _asyncToGenerator(function* () {
        // We don't care if there are multiple taxonomies like this.
        // Just need the first one. The code does not distingquish between duplicate
        // taxonomies only that one is assigned.
        var possiblePresetCategories = yield this.category.search({
          hierarchy: PRESET_HIERARCHY
        });
        var presetCategory = possiblePresetCategories.firstObject;
        var preparedCategories = _defineProperty({}, COPILOT_FUNCTIONAL_SLUG, [presetCategory]);

        return {
          categories: preparedCategories
        };
      });

      function generatePresetOptions() {
        return _ref5.apply(this, arguments);
      }

      return generatePresetOptions;
    }(),
    execute: function () {
      var _ref6 = _asyncToGenerator(function* () {
        var store = this.store;
        var usersFullName = this.currentUser.fullName;
        var draftName = this.generateDraftName(usersFullName);
        var brandConfig = this.brandConfig;
        var config = this.config;
        var modelClass = store.modelFor("article");
        var defaultAttributes = modelClass.defaultAttributes(draftName, brandConfig, config);
        var versoOptions = this.generateVersoOptions();
        var metaDataDefaultValues = (0, _lodashEs.get)(modelClass, "metadata.defaultValues", {});
        // Certain schemas have default values saved in the core monorepo
        var defaultValues = this.generateDefaultOptions(metaDataDefaultValues);
        var presetOptions = yield this.generatePresetOptions();
        var adjustedModelAttributes = Object.assign({}, defaultAttributes, versoOptions, defaultValues, presetOptions);
        var model = store.createRecord("article", adjustedModelAttributes);

        yield this.saveService.save(model);

        return model;
      });

      function execute() {
        return _ref6.apply(this, arguments);
      }

      return execute;
    }()
  });
});