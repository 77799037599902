define("copilot/components/category-autocomplete/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var USER_INTERACTION_TIMEOUT_MILLISECONDS = 250;
  var limit = 50;

  function formatSearchResults(category, pathSeparator) {
    var ancestors = category.hierarchy;
    var formattedDisplayLabel = ancestors.mapBy("name").reverse().slice(1).join(pathSeparator);

    category.set("displayLabel", formattedDisplayLabel);
    return category;
  }

  /**
   * @param {String} hierarchies a comma-separated list of hierarchies acceptable to search
   * @param {Object} selected passed the model
   * @param {Action} onchange passed the model to this action
   * @param {Action} search route action
   */
  var CategoryAutocomplete = Ember.Component.extend({
    classNames: ["category-autocomplete"],
    intl: Ember.inject.service(),
    categoryService: Ember.inject.service("category"),
    analytics: Ember.inject.service(),
    analyticsName: "tags-picker",
    isOpen: false,
    isUserInteracting: false,
    timeOpened: null,
    finishInteractionTimeout: null,
    previouslySelected: Ember.A(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.selected.forEach(function (item) {
        formatSearchResults(item, _this.get("intl").t("category-manager.pathSeparator"));
      });
    },
    isNotSelected: function isNotSelected(category) {
      var rootSlug = category.get("rootSlug");
      return !(this.get("selections." + rootSlug) || []).some(function (selection) {
        return selection.get("id") === category.get("id");
      });
    },


    getSearchResults: (0, _emberConcurrency.task)(function* (term) {
      var _this2 = this;

      try {
        var queryParams = {
          hierarchy: this.hierarchies,
          isroot: false,
          limit: limit
        };
        if (this.get("showArchived")) {
          queryParams.display = "all";
        }
        var searchResults = yield this.search(queryParams, term);
        Ember.tryInvoke(this, "onsearch", [term, searchResults]);
        this.set("hasSearchError", false);
        if (searchResults.length) {
          var filteredSearchResults = searchResults.map(function (results) {
            return formatSearchResults(results, _this2.get("intl").t("category-manager.pathSeparator"));
          }).filter(function (category) {
            return _this2.isNotSelected(category);
          });
          if (this.get("showNonSelectable")) {
            return filteredSearchResults;
          } else {
            return filteredSearchResults.filter(function (category) {
              return category.get("config.selectable");
            });
          }
        } else {
          return [];
        }
      } catch (err) {
        this.set("hasSearchError", true);
        this.get("logger").error("Error during category search", err);
      }
    }),

    suggestedTags: Ember.A(),

    updatedList: Ember.computed("suggestedTags.[]", "selected.[]", function () {
      var _this3 = this;

      return [{
        groupName: "Suggested Tags",
        options: this.suggestedTags.filter(function (tag) {
          return !_this3.selected.some(function (item) {
            return item.id === tag.id;
          });
        })
      }];
    }),

    loadTagSuggestions: function loadTagSuggestions() {
      var _this4 = this;

      // Load tag suggestions
      this.set("suggestedTags", [{ loading: true }]);
      this.getTagsSuggestions(this.model).then(function (result) {
        if (_this4.isDestroyed) {
          return;
        }

        if (result && result.categories) {
          // Decorate suggested categories with a suggested flag so we can differentiate
          // And format the results to include displayLabel
          result.categories.forEach(function (category) {
            category.suggested = true;
            formatSearchResults(category, _this4.get("intl").t("category-manager.pathSeparator"));
          });

          _this4.set("suggestedTags", result.categories);
        } else {
          _this4.set("suggestedTags", []);
        }
      }).catch(function (err) {
        if (_this4.isDestroyed) {
          return;
        }

        _this4.get("logger").error("Error getting suggested tags.", err);
        _this4.set("suggestedTags", []);
      });
    },

    clearFinishInteractionTimeout: function clearFinishInteractionTimeout() {
      var finishInteractionTimeout = this.get("finishInteractionTimeout");
      if (finishInteractionTimeout) {
        Ember.run.cancel(finishInteractionTimeout);
        this.set("finishInteractionTimeout", null);
      }
    },

    setFinishInteractionTimeout: function setFinishInteractionTimeout() {
      var _this5 = this;

      var finishInteractionTimeout = this.get("finishInteractionTimeout");
      if (!finishInteractionTimeout) {
        finishInteractionTimeout = Ember.run.later(this, function () {
          if (_this5.isDestroyed) {
            return;
          }

          _this5.finishInteraction();
        }, USER_INTERACTION_TIMEOUT_MILLISECONDS);
        this.set("finishInteractionTimeout", finishInteractionTimeout);
      }
    },

    beginInteraction: function beginInteraction() {
      this.set("isUserInteracting", true);

      // Track time component was opened
      this.set("timeOpened", new Date());

      // Store the tags selected before the user interacts
      var previouslySelected = this.get("selected");
      this.set("previouslySelected", [].concat(_toConsumableArray(previouslySelected)));
    },

    finishInteraction: function finishInteraction() {
      // It was not possible to find a consistent spot that indicated the user had finished
      // interacting with the component. The onClose event can be triggered when the user is
      // still focused in the text field. The onBlur event can be triggered when the suggestion
      // panel is still open. We worked around this by tracking the open state as well as the
      // active element of the component and using a timeout to ensure no more open/close/blur/focus
      // events come through before interaction is finalized.

      var isOpen = this.get("isOpen");
      var element = this.get("element");
      var focusInElement = element.contains(document.activeElement);
      if (!isOpen && !focusInElement) {
        this.trackAnalytics();

        this.set("previouslySelected", []);
        this.set("timeOpened", null);
        this.set("isUserInteracting", false);
      }
    },

    trackAnalytics: function trackAnalytics() {
      var analyticsName = this.get("analyticsName");
      var timeSpentMilliseconds = new Date() - this.get("timeOpened");

      // Pre-existing selected used to filter out from suggested tags (these shouldn't be considered suggested for analytics)
      var preExistingSelectedIds = new Set(this.get("previouslySelected").map(function (tag) {
        return tag.id;
      }));
      var newSuggestedTags = this.get("suggestedTags").filter(function (tag) {
        return !preExistingSelectedIds.has(tag.id);
      });
      var newSelectedTags = this.get("selected").filter(function (tag) {
        return !preExistingSelectedIds.has(tag.id);
      });
      var suggestedTagsCount = newSuggestedTags.length;
      var suggestedTagsSelectedCount = newSelectedTags.filter(function (tag) {
        return tag.suggested;
      }).length;
      var manualTagsSelectedCount = newSelectedTags.filter(function (tag) {
        return !tag.suggested;
      }).length;

      // At closing time we can track analytics about the component usage:
      // - Tags that were suggested
      // - Tags that were selected (suggested)
      // - Tags that were selected (manually)
      // - Time spent interacting with the component
      var metrics = {
        suggestedTagsCount: suggestedTagsCount,
        suggestedTagsSelectedCount: suggestedTagsSelectedCount,
        manualTagsSelectedCount: manualTagsSelectedCount,
        timeSpentMilliseconds: timeSpentMilliseconds
      };

      this.analytics.track(analyticsName, "finished", metrics);
    },

    actions: {
      onOpen: function onOpen() {
        this.set("isOpen", true);

        var isUserInteracting = this.get("isUserInteracting");
        if (!isUserInteracting) {
          this.beginInteraction();
          //this.loadTagSuggestions();
        }

        // Clear timer since user has resumed interaction
        this.clearFinishInteractionTimeout();
      },
      onFocus: function onFocus() {
        // Clear timer since user has resumed interaction
        this.clearFinishInteractionTimeout();
      },
      onClose: function onClose() {
        this.set("isOpen", false);

        this.clearFinishInteractionTimeout();
        this.setFinishInteractionTimeout();
      },
      onBlur: function onBlur() {
        this.clearFinishInteractionTimeout();
        this.setFinishInteractionTimeout();
      },
      onChange: function onChange(options, context) {
        this.onchange(options);
        Ember.set(context, "searchText", "");
        Ember.set(context, "lastSearchedText", "");
      }
    }
  });

  CategoryAutocomplete.reopenClass({
    positionalParams: ["field"]
  });

  exports.default = CategoryAutocomplete;
});