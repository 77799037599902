define("copilot/components/taxonomy-select/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["taxonomy-select"],
    classNameBindings: ["validation.messages.length:has-error:"]
  });
});