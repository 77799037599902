define("copilot/validations/brands/all/review", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var reviewValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ReviewValidationsDescriptor).andAlso(object({
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 })).on("publish")
  }));

  exports.default = reviewValidationBuilder;
});