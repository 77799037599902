define("copilot/components/category-row/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNameBindings: ["isDisabled"],
    isPrimary: Ember.computed.equal("index", 0),
    isDisabled: Ember.computed.reads("model.meta.archived"),
    breadCrumbs: Ember.computed("model", "model.hierarchy", function () {
      return this.get("model.hierarchy").mapBy("name").reverseObjects().join(this.intl.t("category-manager.pathSeparator"));
    })
  });
});