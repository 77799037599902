define("copilot/serializers/runwaymoment", ["exports", "copilot/serializers/application", "lodash-es"], function (exports, _application, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalize: function normalize(classType, hash) {
      this._super.apply(this, arguments);
      hash.runwayMomentType = hash.type;
      delete hash.type;
      return hash;
    },
    serialize: function serialize(record) {
      var json = this._super.apply(this, arguments);

      // check if we have a _rel_ called isSponsored, and serialize correctly if so
      if (record.isSponsored && record.isSponsored.length) {
        json.isSponsored = record.isSponsored.map(function (_ref) {
          var _ref$_data = _ref._data,
              id = _ref$_data.id,
              meta = _ref$_data.meta;
          return {
            id: id,
            meta: meta
          };
        });
      } else {
        json.isSponsored = [];
      }

      if (json.runwayMomentType) {
        json.type = json.runwayMomentType;
        return _lodashEs.default.omit(json, "runwayMomentType");
      }
      return json;
    }
  });
});