define("copilot/brands/cnt/models/airport", ["exports", "copilot/brands/cnt/models/venue"], function (exports, _venue) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = Ember.attr;


  var airport = _venue.default.extend({
    airportCode: attr(),
    distanceFromDowntownInMiles: attr(Number),
    dominantCarrier: attr(),
    type: "airport"
  });

  airport.reopenClass({
    url: "/api/airports"
  });

  exports.default = airport;
});