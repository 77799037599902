define("copilot/mixins/archivable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    userService: Ember.inject.service("user"),

    userFullName: Ember.computed("userService.{firstName,lastName}", function () {
      var user = this.get("userService.user");
      return user.get("firstName") + " " + user.get("lastName");
    }),

    archiveState: Ember.computed("model.meta.archived", function () {
      return this.get("model.meta.archived") ? "unarchive" : "archive";
    }),

    displayArchiveStatus: Ember.computed("model.meta.archived", function () {
      return this.get("model.meta.archived") ? "unarchive" : "archive";
    }),

    setData: function setData(state) {
      var _this = this;

      var archived = state === "unarchive" ? false : true;
      var id = this.get("model.id");
      var type = this.get("model.type");
      var data = {
        revisionAuthor: this.userFullName
      };
      var archiveService = this.archiveService;
      archiveService[state](type, id, data).then(function () {
        _this.set("model.meta.archived", archived);
      }, function (error) {
        _this.consoleLogger.error(error);
      });
    },


    actions: {
      archive: function archive() {
        this.setData("archive");
      },
      unarchive: function unarchive() {
        this.setData("unarchive");
      }
    }
  });
});