define("copilot/initializers/override-ember-keyboard-first-responder", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // Ember-Keyboard overrides all {{input}} and {{textarea}} components so that
  // they register themselves with the keyboard service as the first responder.
  // We do not need this behaviour and it prevents the keyboard events registered
  // in the {{action-bar}} component from being fired.
  function initialize() {
    Ember.TextField.reopen({ makeFirstResponderOnFocusIn: null });
    Ember.TextArea.reopen({ makeFirstResponderOnFocusIn: null });
  }

  exports.default = {
    name: "override-ember-keyboard-first-responder",
    initialize: initialize
  };
});