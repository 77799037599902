define("copilot/services/replace-aspect-ratio", ["exports", "ember-concurrency", "ember-inflector"], function (exports, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    analytics: Ember.inject.service(),

    intl: Ember.inject.service(),

    upload: (0, _emberConcurrency.task)(function* (file, model, aspectRatio) {
      return yield file.upload("/api/" + (0, _emberInflector.pluralize)(model.type) + "/" + Ember.get(model, "id") + "/" + Ember.get(aspectRatio, "key"), {
        method: "PUT",
        contentType: null
      });
    }).enqueue().maxConcurrency(3),

    execute: function execute(file, model, aspectRatio) {
      var _this = this;

      return this.upload.perform(file, model, aspectRatio).then(function (response) {
        var data = response.body.aspectRatios[Ember.get(aspectRatio, "key")];
        aspectRatio.setProperties(data);
        aspectRatio.set("_dirtyProperties", []);

        _this.analytics.track("uploader", "upload");
        return model;
      }).catch(function (response) {
        var context = Ember.get(response, "body.details.0.context");
        var key = Ember.get(context || {}, "key");
        var error = void 0;
        if (key && model.get("type")) {
          error = {
            code: model.get("type") + "-" + Ember.get(context || {}, "key") + "-error",
            context: context
          };
        } else {
          error = {
            code: "server-error",
            context: {
              error: response.statusText,
              message: response.body.message || response.body.statusText,
              status: response.body.statusCode || response.status
            }
          };
        }
        error.message = _this.intl.t("validationMessage." + error.code, error.context);
        _this.analytics.track("uploader", "error");

        throw error.message;
      });
    }
  });
});