define("copilot/models/unknown", ["exports", "ember-model"], function (exports, _emberModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _emberModel.Model.extend({
    logger: Ember.inject.service(),

    filterInjections: function filterInjections(data) {
      return Object.entries(data).reduce(function (props, _ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        if (value && value.toString().match(/^<.*:ember.*>$/)) {
          return props;
        }
        return Object.assign({}, props, _defineProperty({}, key, value));
      }, {});
    },
    init: function init() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this._super.apply(this, arguments);
      this.set("requestedType", data.type);
      var props = this.filterInjections(data);
      this.logger.warn("Tried to init unknown model", { data: props });
    },
    load: function load(id) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._super.apply(this, arguments);
      this.set("requestedType", data.type);
      var props = this.filterInjections(data);
      this.logger.warn("Tried to load unknown model", { id: id, data: props });
    },


    type: "unknown",
    id: (0, _emberModel.attr)(),
    requestedType: (0, _emberModel.attr)()
  });
});