define("copilot/services/participants", ["exports", "node-uuid"], function (exports, _nodeUuid) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var ROUTES_THAT_SUPPORT_CONCURRENT_EDITING = ['livestoryupdates'];

  var visibilitychange = function () {
    if (document.webkitHidden !== undefined) {
      return "webkitvisibilitychange";
    } else if (document.msHidden !== undefined) {
      return "msvisibilitychange";
    } else {
      return "visibilitychange";
    }
  }();

  var isHidden = function () {
    if (document.webkitHidden !== undefined) {
      return "webkitHidden";
    } else if (document.msHidden !== undefined) {
      return "msHidden";
    } else {
      return "hidden";
    }
  }();

  var ParticipantArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin, {
    init: function init() {
      this._super.apply(this, arguments);
      var deferred = Ember.RSVP.defer();
      Ember.set(this, "promise", deferred.promise);
      this.resolve = deferred.resolve;
    }
  });

  var keyForSpace = function keyForSpace(space) {
    if (!supportsConcurrentEditors(space)) {
      return null;
    }
    return [space.brand, space.id, space.scope].compact().join("/");
  };

  var supportsConcurrentEditors = function supportsConcurrentEditors(space) {
    return space.brand && space.id;
  };

  var overrideConcurrentEditorsByURL = function overrideConcurrentEditorsByURL(currentURL) {
    if (currentURL === null) {
      return false;
    }
    return ROUTES_THAT_SUPPORT_CONCURRENT_EDITING.some(function (allowedURL) {
      return currentURL.includes(allowedURL);
    });
  };

  exports.default = Ember.Service.extend({
    websockets: Ember.inject.service(),
    features: Ember.inject.service(),
    router: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this._recordArrays = {};
      this._messages = [];
      Ember.set(this, "_participation", null);

      this.socket = Ember.get(this, "websockets").getConnection("spaces", {
        reconnectionAttempts: 2,
        reconnectionDelay: 1500,
        transports: ["websocket"]
      });

      this.socket.on("change", function (space) {
        var participantList = _this.find(space);
        var participantForThisInstance = space.participants.findBy("uuid", Ember.get(_this, "_participation.uuid"));
        if (participantForThisInstance) {
          participantForThisInstance.isThisBrowser = true;
        }

        if (participantList.get("isPending")) {
          participantList.resolve(space.participants);
        } else {
          participantList.set("content", space.participants);
        }
      });

      this.socket.on("connect", function () {
        _this.flushMessages();
      });

      this.socket.on("reconnect", function () {
        _this.flushMessages();

        var participation = Ember.get(_this, "_participation");
        if (participation) {
          _this.sendMessage("join", participation);
        }
      });

      this._visibilityDidChange = Ember.run.bind(this, "visibilityDidChange");
      document.addEventListener(visibilitychange, this._visibilityDidChange);
    },
    willDestroy: function willDestroy() {
      this.socket.off("change");
      this.socket.off("reconnect");
      this.socket.off("connect");
      this.socket.destroy();

      Ember.set(this, "_participation", null);
      this._recordArrays = {};

      document.removeEventListener(visibilitychange, this._visibilityDidChange);
    },
    sendMessage: function sendMessage(channel, message) {
      this._messages.push([channel, message]);
      this.flushMessages();
    },
    flushMessages: function flushMessages() {
      if (this.socket.socket && this.socket.socket.connected) {
        while (this._messages.length) {
          var _messages$shift = this._messages.shift(),
              _messages$shift2 = _slicedToArray(_messages$shift, 2),
              channel = _messages$shift2[0],
              message = _messages$shift2[1];

          this.socket.emit(channel, message);
        }
      }
    },
    join: function join(participant) {
      if (!supportsConcurrentEditors(participant.space) || overrideConcurrentEditorsByURL(this.router.currentURL)) {
        return [];
      }

      var data = Object.assign({
        uuid: _nodeUuid.default.v1()
      }, participant);

      data.space = Object.assign({
        capabilities: {
          locking: Ember.get(this, "features.contentLocking")
        }
      }, data.space);

      this.sendMessage("join", data);
      Ember.set(this, "_participation", data);
      return this.find(participant.space);
    },
    visibilityDidChange: function visibilityDidChange() {
      var _this2 = this;

      var isVisible = !document[isHidden];
      var participation = Ember.get(this, "_participation");

      if (participation && isVisible) {
        this.find(participation.space, { reload: true }).then(function (participants) {
          if (!participants.findBy("uuid", participation.uuid)) {
            _this2.sendMessage("join", participation);
          }
        });
      }
    },
    lock: function lock() {
      this.sendMessage("lock");
    },
    save: function save(data) {
      this.sendMessage("save", data);
    },
    onSave: function onSave(callback) {
      this.socket.on("save", callback);
    },
    offSave: function offSave(callback) {
      this.socket.off("save", callback);
    },
    leave: function leave() {
      this._messages = [];
      if (!Ember.get(this, "_participation")) {
        return;
      }

      this._recordArrays[keyForSpace(this._participation.space)] = null;
      Ember.set(this, "_participation", null);
      this.sendMessage("leave");
    },


    hasJoined: Ember.computed.bool("_participation"),

    find: function find(space) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!supportsConcurrentEditors(space)) {
        return [];
      }

      var key = keyForSpace(space);
      if (!this._recordArrays[key]) {
        this._recordArrays[key] = ParticipantArray.create();
      }

      if (options.reload) {
        this.sendMessage("reload", this._participation.space);
      }

      return this._recordArrays[key];
    }
  });
});