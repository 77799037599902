define("copilot/serializers/curatedsearch", ["exports", "copilot/serializers/application", "copilot/models/category"], function (exports, _application, _category) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _application.default.extend({
    categoryService: Ember.inject.service("category"),
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    serialize: function serialize() {
      var data = this._super.apply(this, arguments);
      var query = data.query;
      var categoryIds = query.filters.categoryIds;
      var additionalFilters = query.additionalFilters;
      if (query.categoriesFilterOperator === "and" && categoryIds.length > 0) {
        additionalFilters.push({
          key: "categoryIds_and",
          value: categoryIds.splice(0)
        });
      }

      additionalFilters.forEach(function (filterObject) {
        if (filterObject.key === "notcategoryIds" && query.notCategoriesFilterOperator === "and") {
          filterObject.key = 'notcategoryIds_and';
        }
      });

      return data;
    },
    normalize: function normalize() {
      var _this = this;

      var data = this._super.apply(this, arguments);
      var categorySerializer = this.store.serializerFor("category");
      var query = data.query;
      var categoryIds = query.filters.categoryIds || [];
      var additionalFilters = query.additionalFilters || [];

      var categoryIds_and = additionalFilters.find(function (filter) {
        return filter.key === "categoryIds_and";
      });
      var notcategoryIds_and = additionalFilters.find(function (filter) {
        return filter.key === "notcategoryIds_and";
      });
      var notcategoryIds = additionalFilters.find(function (filter) {
        return filter.key === "notcategoryIds";
      });

      if (categoryIds_and) {
        query.categoriesFilterOperator = categoryIds_and.value.length ? "and" : "or";
        categoryIds.push.apply(categoryIds, _toConsumableArray(categoryIds_and.value));
        additionalFilters.splice(additionalFilters.indexOf(categoryIds_and), 1);
      }

      if (notcategoryIds_and) {
        if (!notcategoryIds_and.value.length) {
          query.notCategoriesFilterOperator = "or";
          additionalFilters.splice(additionalFilters.indexOf(notcategoryIds_and), 1);
        } else {
          query.notCategoriesFilterOperator = "and";
          if (notcategoryIds) {
            var _notcategoryIds_and$v;

            (_notcategoryIds_and$v = notcategoryIds_and.value).unshift.apply(_notcategoryIds_and$v, _toConsumableArray(notcategoryIds.value));
            additionalFilters.splice(additionalFilters.indexOf(notcategoryIds, 1));
          }
          notcategoryIds_and.key = "notcategoryIds";
          notcategoryIds = notcategoryIds_and;
        }
      }

      additionalFilters.forEach(function (filterObject) {
        if (notcategoryIds) {
          filterObject.value.forEach(function (category, idx, list) {
            if (Ember.typeOf(category) === "object") {
              var categoryObject = categorySerializer.normalize(_category.default, category);
              var categoryInstance = _this.categoryService.categoryFactory();
              categoryInstance.load(categoryObject.id, categoryObject);
              list[idx] = categoryInstance;
            }
          }, _this);
        }
      });

      categoryIds.forEach(function (category, idx, list) {
        if (Ember.typeOf(category) === "object") {
          var categoryObject = categorySerializer.normalize(_category.default, category);
          var categoryInstance = _this.categoryService.categoryFactory();
          categoryInstance.load(categoryObject.id, categoryObject);
          list[idx] = categoryInstance;
        }
      }, this);

      return data;
    }
  });
});