define("copilot/validations/brands/cnt/venue", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var venueValidators = object({
    name: (0, _copilotValidators.required)(),
    subtype: (0, _copilotValidators.required)(),
    url: (0, _copilotValidators.array)((0, _copilotValidators.optional)((0, _copilotValidators.url)())),
    email: (0, _copilotValidators.array)((0, _copilotValidators.optional)((0, _copilotValidators.email)())),
    geo: (0, _copilotValidators.geo)(),
    description: (0, _copilotValidators.optional)((0, _copilotValidators.markdownLinks)()),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 }).on("publish"))
  }).andAlso((0, _copilotValidators.inlineAllPresent)()).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = (0, _crossCheckDsl.default)(venueValidators);
});