define("copilot/components/language-dropdown/component", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["language-dropdown"],

    loadInitial: (0, _emberConcurrency.task)(function* () {
      var collection = this.model.meta.collectionName;
      var brand = this.model.activeBrand;
      var translatedLanguages = [];
      var otherLanguages = [];

      // Read available languages from config
      var brandAvailableLanguages = this.configService.config.language.available;

      // Get current model language
      var currentModelLanguage = this.model.lang;

      // Retrieve translations from tsugu
      var translations = yield this._retrieveTranslations.perform();

      // Add current model language
      translatedLanguages.push({
        'code': currentModelLanguage,
        'type': 'current'
      });

      // Loop through available languages
      brandAvailableLanguages.forEach(function (availableLanguage) {
        var translatedItem = null;

        // Search if a translation is available
        if (translations.length > 0) {
          translatedItem = translations.filter(function (item) {
            return item.lang === availableLanguage;
          });
        }

        // Check if content is already translated
        if (translatedItem && 0 !== translatedItem.length) {
          translatedLanguages.push({
            'code': availableLanguage,
            'type': 'translated',
            'url': "/" + brand + "/" + collection + "/" + translatedItem[0].id
          });
        } else if (availableLanguage !== currentModelLanguage) {
          // Other translations
          otherLanguages.push({
            'code': availableLanguage,
            'type': 'other'
          });
        }
      });

      // Sort translatedLanguages by type (current language first)
      translatedLanguages.sort(function (item1, item2) {
        return item1.type >= item2.type ? 1 : -1;
      });

      // Set languages arrays
      this.set("translatedLanguages", translatedLanguages);
      this.set("otherLanguages", otherLanguages);

      // Set available languages array
      this.set("availableLanguages", brandAvailableLanguages);
      yield brandAvailableLanguages;
    }),

    /**
     * Retrieve model translations (also draft contents)
     */
    _retrieveTranslations: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.model.translations();
      } catch (err) {
        this.set("hasError", true);
        this.get("logger").error("Error during translations retrieval", err);
      }
    })

  });
});