define("copilot/components/search-slat/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ["styleName", "archived"],

    styleName: Ember.computed("style", function () {
      return "search-" + this.style;
    }),

    /**
     * One of `slat`, `card`, or `autocomplete`
     */
    style: "slat",

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.startTracking) {
        this.startTracking(this.element);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.endTracking) {
        this.endTracking(this.element);
      }
    },


    componentName: Ember.computed("result", "style", function () {
      var componentName = "search-slat/" + this.result.type + "-" + this.style.split(" ")[0];
      if (this.result.provider === "commerce-tools") {
        componentName = "search-slat/commerceproduct-" + this.style.split(" ")[0];
      }
      var owner = Ember.getOwner(this);
      var lookup = owner.lookup("component-lookup:main");
      var hasComponent = lookup.componentFor(componentName, owner) || lookup.layoutFor(componentName, owner);
      if (!hasComponent) {
        componentName = "search-slat/default-" + this.style.split(" ")[0];
      }
      return componentName;
    })
  });
});