define("copilot/lib/autocomplete/contributor-filter-adapter", ["exports", "lodash-es", "copilot/lib/autocomplete/base-adapter"], function (exports, _lodashEs, _baseAdapter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function modelToObjectTransform(model) {
    return {
      id: model.get("id"),
      text: model.get("searchTitle")
    };
  }

  exports.default = _lodashEs.default.extend({}, _baseAdapter.default, {
    transformInitialValue: function () {
      var _ref = _asyncToGenerator(function* (value) {
        var search = Ember.getOwner(this).lookup("service:search");
        var contributorSearch = yield search.execute({
          types: this.type,
          id: value
        });
        return contributorSearch.hits.map(modelToObjectTransform);
      });

      function transformInitialValue(_x) {
        return _ref.apply(this, arguments);
      }

      return transformInitialValue;
    }(),
    getInitialValue: function getInitialValue(selection) {
      return selection.join(",");
    },
    getValue: function getValue(data) {
      return data.choice.id;
    },
    transformResults: function transformResults(data) {
      if (data) {
        if (!Ember.isArray(data.hits)) {
          return [];
        }
        return data.hits.map(modelToObjectTransform);
      }
    }
  });
});