define("copilot/components/publish-status-badge/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /** Template params */
    distributionName: null,
    publishedAt: null,
    publishedRevision: null,
    lastRevision: null,
    metadata: null,
    showTooltip: true,

    tagName: "",

    isAppleNewsPremium: Ember.computed("distributionName", "metadata.isPaid", function () {
      return this.distributionName === "apple-news" && this.metadata.isPaid;
    }),

    hasChanges: Ember.computed("lastRevision", "publishedRevision", function () {
      return this.lastRevision !== this.publishedRevision;
    })
  });
});