define("copilot/utils/model", ["exports", "lodash-es"], function (exports, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    hasObject: function hasObject(array, item) {
      return _lodashEs.default.find(array, function (obj) {
        return Ember.get(obj, "id") === Ember.get(item, "id") && Ember.get(obj, "meta.modelName") === Ember.get(item, "meta.modelName");
      });
    },


    RegEx: {
      espcapeExpChars: function espcapeExpChars(str) {
        var specials = ["-", "[", "]", "/", "{", "}", "(", ")", "*", "+", "?", ".", "\\", "^", "$", "|"];
        var regex = new RegExp("[" + specials.join("\\") + "]", "g");
        return str.replace(regex, "\\$&");
      },


      url: /^http[s]?:\/\/[-a-zA-Z0-9@%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,

      // regex that allows absolute http(s) URLs OR relative URLs with a leading slash
      absOrRelUrl: /^(http[s]?:\/\/[-a-zA-Z0-9@%._+~#=]{2,256}\.[a-z]{2,63}|\/)\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,

      email: /(.+)@(.+){2,}\.(.+){2,}/,

      slug: /^[a-z0-9-]+$/,

      softSlug: /^[a-z0-9-/]+$/,

      unicode: /^\d{6}$/,

      geo: /^([-+]?\d+([.]\d+)?)$/,

      year: /^\d{4}$/,

      numberOrPercentage: /\d+%?$/,

      validMdLink: /\[([^[]+)\]\(([^)]+)\)/g
    },

    isValidAppleUrl: function isValidAppleUrl(url) {
      var validUrl = /(\()http(s)?:\/\/\.?[a-z0-9]+([-.]{1}[a-z0-9-]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?\)$/gm;
      return validUrl.test(url);
    },


    // TODO: Why is this called displayTitle? It's more like
    // displayType? Sean O'hara 20160523
    displayTitle: function displayTitle(type) {
      var modelLabelMap = {
        curatedlist: "Curated List",
        curatedsearch: "Curated Search",
        externallink: "External Link",
        fashiongallery: "Fashion Gallery",
        fashionshow: "Fashion Show",
        goldlist: "Gold List",
        golfball: "Golf Ball",
        golfclub: "Golf Club",
        hotlist: "Hot List",
        newsletter: "Newsletter",
        rcalist: "RCA List",
        runwaymoment: "Runway Moment",
        skiresort: "Ski Resort",
        specialreport: "Special Report"
      };

      return modelLabelMap[type] || _lodashEs.default.capitalize(type);
    }
  };
});