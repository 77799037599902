define("copilot/components/edit-variants/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userService: Ember.inject.service("user"),
    variants: Ember.computed.reads("model.variants")
  });
});