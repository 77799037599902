define("copilot/components/variants-wrapper/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    isEditing: true,
    variantToEdit: null,
    store: Ember.inject.service(),
    photoService: Ember.inject.service("photo"),
    saveService: Ember.inject.service("save"),

    exludePrimaryId: Ember.computed("model", function () {
      return [this.get("model.id")];
    }),

    actions: {
      assignItem: function assignItem(_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            searchResult = _ref2[0];

        var changeset = this.changeset;
        var variantToEdit = this.variantToEdit;
        changeset.set(variantToEdit, [searchResult]);
        this.setProperties({
          variantToEdit: null,
          isEditing: true
        });
      },
      search: function search(field) {
        this.set("isEditing", false);
        this.set("variantToEdit", field);
      },
      dismiss: function dismiss() {
        this.changeset.rollback();
        this.ondismiss();
      },
      submit: function submit() {
        this.changeset.execute();
        this.saveService.save(this.model);
        this.ondismiss();
      }
    }
  });
});