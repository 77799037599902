define("copilot/components/livestoryupdate-feed/livestoryupdate-feed-item/component", ["exports", "@atjson/renderer-html", "@condenast/atjson-source-copilot-markdown", "@condenast/atjson-source-verso", "moment"], function (exports, _rendererHtml, _atjsonSourceCopilotMarkdown, _atjsonSourceVerso, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var EMBED_TYPES = ["embed", "iframe-embed", "gallery-embed", "photo-embed", "clip-embed"];
  class CopilotHTMLRenderer extends _rendererHtml.default {
    *Button(_ref) {
      var _ref$attributes = _ref.attributes,
          url = _ref$attributes.url,
          title = _ref$attributes.title;

      return "<cn-button url=" + url + ">" + title + "</cn-button>";
    }
    *CneEmbed(_ref2) {
      var cneId = _ref2.attributes.cneId;

      return "<cne-embed uri=\"script/video/" + cneId + ".js?autoplay=1&muted=1\"></cne-embed>";
    }
    *ClipEmbed(_ref3) {
      var _ref3$attributes = _ref3.attributes,
          videoUrl = _ref3$attributes.videoUrl,
          altText = _ref3$attributes.altText,
          credit = _ref3$attributes.credit,
          caption = _ref3$attributes.caption,
          imageUrl = _ref3$attributes.imageUrl;

      return "<cn-clip image-url=\"" + imageUrl + "\" video-url=\"" + videoUrl + "\" alt=\"" + altText + "\" credit=\"" + credit + "\">" + (caption ? "<cn-caption>" + CopilotHTMLRenderer.render(this.getSlice(caption)) + "</cn-caption>" : "") + "</cn-clip>";
    }
    *Embed(_ref4) {
      var _ref4$attributes = _ref4.attributes,
          type = _ref4$attributes.type,
          title = _ref4$attributes.title,
          imageUrl = _ref4$attributes.imageUrl;

      return this.EmbedTemplate({
        type: type,
        title: title,
        imageUrl: imageUrl
      });
    }
    *FacebookEmbed(_ref5) {
      var url = _ref5.attributes.url;

      return "<facebook-embed url=\"" + url + "\"></facebook-embed>";
    }
    *GalleryEmbed(_ref6) {
      var _ref6$attributes = _ref6.attributes,
          type = _ref6$attributes.type,
          title = _ref6$attributes.title,
          imageUrl = _ref6$attributes.imageUrl;

      return this.EmbedTemplate({
        type: type,
        title: title,
        imageUrl: imageUrl
      });
    }
    *GiphyEmbed(_ref7) {
      var url = _ref7.attributes.url;

      return "<giphy-embed url=" + url + "></giphy-embed>";
    }
    *InstagramEmbed(_ref8) {
      var url = _ref8.attributes.url;

      return "<instagram-embed url=\"" + url + "\"></instagram-embed>";
    }
    *PhotoEmbed(_ref9) {
      var _ref9$attributes = _ref9.attributes,
          altText = _ref9$attributes.altText,
          imageUrl = _ref9$attributes.imageUrl,
          caption = _ref9$attributes.caption,
          credit = _ref9$attributes.credit;

      return "<cn-photo image-url=\"" + imageUrl + "\" alt=\"" + altText + "\" credit=\"" + credit + "\">" + (caption ? "<cn-caption>" + CopilotHTMLRenderer.render(this.getSlice(caption)) + "</cn-caption>" : "") + "</cn-photo>";
    }
    *PinterestEmbed(_ref10) {
      var url = _ref10.attributes.url;

      return "<pinterest-embed url=\"" + url + "\"></pinterest-embed>";
    }
    *TiktokEmbed(_ref11) {
      var url = _ref11.attributes.url;

      return "<tiktok-embed url=\"" + url + "\"></twitter-embed>";
    }
    *TwitterEmbed(_ref12) {
      var url = _ref12.attributes.url;

      return "<twitter-embed url=\"" + url + "\"></twitter-embed>";
    }
    *VideoEmbed(_ref13) {
      var url = _ref13.attributes.url;

      return "<video-embed url=\"" + url + "\"></video-embed>";
    }
    *BlueskyEmbed(_ref14) {
      var _ref14$attributes = _ref14.attributes,
          url = _ref14$attributes.url,
          uri = _ref14$attributes.uri,
          cid = _ref14$attributes.cid;

      return "<blueesky-embed url=\"" + url + "\" uri=\"" + uri + "\" cid=\"" + cid + "\"></bluesky-embed>";
    }
    *MastodonEmbed(_ref15) {
      var url = _ref15.attributes.url;

      return "<mastodon-embed url=\"" + url + "\"></mastdon-embed>";
    }
    *IframeEmbed(_ref16) {
      var url = _ref16.attributes.url;

      return "<iframe-embed url=\"" + url + "\"></iframe-embed>";
    }

    EmbedTemplate(embed) {
      if (embed.imageUrl) {
        return "<copilot-embed\n        title=\"" + embed.title + "\"\n        channel=\"" + embed.type + "\"\n        image-url=\"" + embed.imageUrl + "\"\n      ></copilot-embed>";
      } else {
        return "<copilot-embed\n        title=\"" + embed.title + "\"\n        channel=\"" + embed.type + "\"\n      ></copilot-embed>";
      }
    }
  }

  exports.default = Ember.Component.extend({
    tagName: "li",
    classNames: ["update-feed-item"],
    photo: Ember.inject.service(),
    logger: Ember.inject.service(),
    mediaHostname: Ember.computed.reads("photo.vulcanUrl"),
    isOpen: false,
    router: Ember.inject.service(),
    firstContributorsName: Ember.computed("update._embedded.contributorsAuthor.firstObject", function () {
      return Ember.get(this.update, "_embedded.contributorsAuthor.firstObject.fields.name");
    }),
    createdAt: Ember.computed("update", function () {
      return (0, _moment.default)(this.update.createdAt).format("ddd MMM DD YYYY hh:mm a");
    }),

    contributorFullnames: Ember.computed("update._embedded.contributorsAuthor", function () {
      return this.update._embedded.contributorsAuthor.map(function (contributorFields) {
        return contributorFields.fields.name;
      });
    }),

    didReceiveAttrs: function () {
      var _ref17 = _asyncToGenerator(function* () {
        var _this = this;

        this._super.apply(this, arguments);
        this.set("loading", true);
        var hydrateEmbedPromises = [];
        var doc = _atjsonSourceCopilotMarkdown.default.fromRaw(this.update.body).convertTo(_atjsonSourceVerso.default);
        var embeds = doc.where(function (a) {
          return EMBED_TYPES.indexOf(a.type) > -1;
        });
        embeds.annotations.forEach(function (embed) {
          var type = embed.attributes.type || embed.type;
          return hydrateEmbedPromises.push(_this.store.find(type.replace("-embed", ""), embed.attributes.id));
        });
        var hydratedEmbedData = yield Promise.all(hydrateEmbedPromises);
        hydratedEmbedData.forEach(function (embedData) {
          var embedAnnotation = embeds.annotations.find(function (embed) {
            return embed.attributes.id === embedData.id;
          });
          embedAnnotation.attributes.title = _rendererHtml.default.render(_atjsonSourceCopilotMarkdown.default.fromRaw(embedData.searchTitle).convertTo(_atjsonSourceVerso.default));
          var thumbnail = embedData.thumbnail;
          if (thumbnail) {
            var imageUrl = "https://" + _this.mediaHostname + "/photos/" + thumbnail.id + "/:aspect_ratio/:modifications/" + thumbnail.filename;
            embedAnnotation.attributes.altText = thumbnail.altText;
            embedAnnotation.attributes.imageUrl = imageUrl;
          }

          if (embedAnnotation.type === "photo-embed") {
            if (embedData.credit) {
              var credit = _atjsonSourceCopilotMarkdown.default.fromRaw(embedData.credit).convertTo(_atjsonSourceVerso.default);
              credit.where(function (a) {
                return a.type === "paragraph";
              }).remove();
              embedAnnotation.attributes.credit = CopilotHTMLRenderer.render(credit);
            }
          }

          if (embedAnnotation.type === "clip-embed") {
            if (embedData.credit) {
              var _credit = _atjsonSourceCopilotMarkdown.default.fromRaw(embedData.credit).convertTo(_atjsonSourceVerso.default);
              _credit.where(function (a) {
                return a.type === "paragraph";
              }).remove();
              embedAnnotation.attributes.credit = CopilotHTMLRenderer.render(_credit);
            }
            embedAnnotation.attributes.videoUrl = "https://" + _this.mediaHostname + "/clips/" + embedData.id + "/:aspect_ratio/:modifications/" + embedData.filename;
          }

          if (!embedAnnotation.attributes.type) {
            embedAnnotation.attributes.type = embedAnnotation.type.replace("-embed", "");
          }
        });
        this.set("bodyContent", Ember.String.htmlSafe(CopilotHTMLRenderer.render(doc, this)));
        this.set("loading", false);
      });

      function didReceiveAttrs() {
        return _ref17.apply(this, arguments);
      }

      return didReceiveAttrs;
    }(),
    open: function open(evt) {
      var _this2 = this;

      this._clickOutside = function (evt) {
        if (_this2.element === evt.target) {
          return;
        }
        _this2.close();
      };
      document.addEventListener("click", this._clickOutside);
      this.set("isOpen", true);
      evt.stopPropagation();
    },
    close: function close() {
      document.removeEventListener("click", this._clickOutside);
      this.set("isOpen", false);
      this._clickOutside = null;
    },
    willDestroyElement: function willDestroyElement() {
      this.close();
    },


    actions: {
      transitionToEdit: function transitionToEdit() {
        this.router.transitionTo("livestories.index.livestoryupdates.index", this.update.id);
      },
      delete: function () {
        var _ref18 = _asyncToGenerator(function* (update) {
          this.close();
          this.set("loading", true);
          try {
            yield Ember.tryInvoke(this, "delete", [update]);
          } catch (e) {
            this.logger.error(e.message, e);
            this.set("loading", false);
          }
        });

        function _delete(_x) {
          return _ref18.apply(this, arguments);
        }

        return _delete;
      }()
    }
  });
});