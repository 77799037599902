define("copilot/validations/brands/epi/menu", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var menuValidators = object({
    hed: (0, _copilotValidators.required)(),
    byline: (0, _copilotValidators.required)().on("publish"),
    courses: (0, _copilotValidators.length)({ min: 1 }).andThen((0, _copilotValidators.hasValidChildren)({ childType: "course" })).on("publish"),
    seoTitle: (0, _copilotValidators.required)().on("publish"),
    seoDescription: (0, _copilotValidators.required)().on("publish"),
    socialDescription: (0, _copilotValidators.required)().on("publish"),
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 }).on("publish"))
  }).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = (0, _crossCheckDsl.default)(menuValidators);
});