define("copilot/models/search/adapters/copilot", ["exports", "ember-service-methods/inject", "ember-concurrency"], function (exports, _inject, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Object.extend({
    page: 1,
    query: "",
    type: "",
    nottypes: null,
    pages: 0,
    totalHits: 0,
    searchError: false,
    archived: false,
    display: "all",
    types: Ember.computed.reads("type"),
    placeholder: "Search...",
    resultsPerPage: 0,
    limit: 20,
    consoleLogger: Ember.inject.service(),
    fetchAndTransform: Ember.inject.service("fetch-products-and-transform"),
    config: Ember.inject.service(),
    search: (0, _inject.default)(),
    features: Ember.inject.service(),
    user: Ember.inject.service(),
    offerAutofillDomains: Ember.computed.alias("config.config.commerce.offerAutofillDomains"),
    brandSlug: Ember.computed.reads("config.activeCnBrandCode"),

    searchSimilarProducts: Ember.computed("query", "types", function () {
      try {
        var offerAutofillDomain = this.offerAutofillDomains;

        var _ref = new URL(this.query),
            host = _ref.host;

        var type = this.types;
        return type === 'product' && this.features.get("enableSimilarProductsSearch") && (offerAutofillDomain.indexOf("*") === 0 || offerAutofillDomain.indexOf(host.replace("www.", "")) > -1);
      } catch (err) {
        return false;
      }
    }),
    status: Ember.computed("type", function () {
      var featureService = Ember.getOwner(this).lookup("service:features");
      var type = this.type;
      var publishable = this.get("config.publishableTypes").includes(type);
      var showOnlyPublishedContent = publishable && !featureService.get("draftEmbeds");
      return showOnlyPublishedContent ? "published" : null;
    }),

    notid: Ember.computed("excludeIds", function () {
      if (this.excludeIds) {
        return this.excludeIds.join(",");
      }
    }),

    searchOptions: Ember.computed("type", function () {
      var searchOptions = [];
      var enableLinkStack = this.get("features.enableLinkStack");
      if (["curatedlist", "list"].includes(this.type) && enableLinkStack) {
        searchOptions.push({
          name: "curationSubType",
          options: ["AssetsOnly", "LinkList", "LinkBannerMarquee", "LinkBannerMultipleLinks", "LinkBannerVisual"]
        });
      }
      return searchOptions;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.results = [];
    },


    fetch: (0, _emberConcurrency.task)(function* () {
      var params = this.getProperties("page", "query", "types", "limit", "nottypes", "status", "notid", "display", "archived", "searchOptions", "brandSlug");

      var additionalSearchOptions = this.searchOptions.reduce(function (options, option) {
        return (options[option.name] = option.value) && options;
      }, {});
      var queryParams = Object.assign({}, params, additionalSearchOptions);
      try {
        var queryString = queryParams.query;
        if (this.isNonCopilotUrl(queryString) && queryParams.types === 'product') {
          queryParams.query = this.transformQueryString(queryString);
        }

        var data = yield this.search(queryParams);

        var enableSimilarProductsSearch = data.hits && data.hits.length === 0 && this.isNonCopilotUrl(queryString) && this.searchSimilarProducts;

        if (enableSimilarProductsSearch) {
          var similarProductDetails = this.searchSimilarProducts ? yield this.fetchAndTransform.getProductDetails(this.brandSlug, [queryString]) : [];
          var hasSimilarProduct = similarProductDetails ? true : false;

          data.hits = similarProductDetails;
          data.totalHits = similarProductDetails.length;
          data.hits.length = similarProductDetails.length;
          data.hasSimilarProduct = hasSimilarProduct;
        }

        this._populate(data);
      } catch (err) {
        this._handleError(err);
      }
    }),

    setPage: function setPage(page) {
      this.set("page", page);
    },
    setQuery: function setQuery(query) {
      this.set("query", query);
    },
    nextPage: function nextPage() {
      this.incrementProperty("page");
      return this.fetch.perform();
    },
    previousPage: function previousPage() {
      this.decrementProperty("page");
      return this.fetch.perform();
    },
    _clearResults: function _clearResults() {
      this.results.clear();
    },
    _handleError: function _handleError(error) {
      var statusCode = Ember.get(error, "status");
      if (statusCode && statusCode !== 404) {
        this._clearResults();
        this.set("searchError", true);
        throw error;
      } else {
        this.set("searchError", false);
      }
      this.consoleLogger.error(error);
    },
    isNonCopilotUrl: function isNonCopilotUrl(value) {
      try {
        var url = new URL(value);
        if (url && url.origin !== 'null' && url.hostname.indexOf('copilot.condenast.io') < 0) {
          return true;
        }
      } catch (error) {
        return false;
      }
    },
    transformQueryString: function transformQueryString(queryString) {
      try {
        var amazonDomains = ["www.amazon.com", "www.amzn.to", "www.amazon.co.uk"];
        var url = new URL(queryString);
        var urlString = url.toString().split("?")[0];
        if (amazonDomains.includes(url.hostname)) {
          var amazonId = urlString.split('/').filter(function (purl) {
            return new RegExp('^[A-Za-z0-9]{10}$').exec(purl);
          })[0];
          return "offers.purchaseUri:\"" + amazonId + "\"";
        } else {
          return "offers.purchaseUri:\"" + urlString + "\"";
        }
      } catch (error) {
        return queryString.query;
      }
    },
    _populate: function () {
      var _ref2 = _asyncToGenerator(function* (data) {
        var hasSimilarProduct = data.hasSimilarProduct;
        delete data.hasSimilarProduct;
        var total = data.totalHits;
        var rows = data.hits.length;
        var results = data.hits;
        this.results.setObjects(results);
        var numPages = Math.ceil(total / rows) || 0;
        this.set("pages", numPages);
        this.set("resultsPerPage", rows);
        this.set("totalHits", total);
        this.set("resultsQuery", data.query);
        this.set("hasSimilarProduct", hasSimilarProduct);
        return this;
      });

      function _populate(_x) {
        return _ref2.apply(this, arguments);
      }

      return _populate;
    }()
  });
});