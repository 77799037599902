define("copilot/components/browser-support-warning/component", ["exports", "copilot/components/browser-support-warning/template"], function (exports, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var USER_AGENT = navigator.userAgent;
  var CHROME_REGEX = /Chrome\/(\d*)/;
  var FIREFOX_REGEX = /Firefox\/(\d*)/;
  var SAFARI_REGEX = /Version\/(\d*)/;
  var MOBILE_REGEX = /Mobile/;
  var MINIMUM_SUPPORTED_VERSIONS = {
    chrome: 58,
    firefox: 54,
    safari: 9,
    ie: null
  };

  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ["browser-warning"],
    showWarning: false,

    init: function init() {
      this._super.apply(this, arguments);
      var isMobile = USER_AGENT.match(MOBILE_REGEX);
      var isSupportedChrome = this.checkSupport(MINIMUM_SUPPORTED_VERSIONS.chrome, CHROME_REGEX);
      var isSupportedFirefox = this.checkSupport(MINIMUM_SUPPORTED_VERSIONS.firefox, FIREFOX_REGEX);
      var isSupportedSafari = this.checkSupport(MINIMUM_SUPPORTED_VERSIONS.safari, SAFARI_REGEX);

      var showWarning = isMobile || !(isSupportedChrome || isSupportedFirefox || isSupportedSafari);

      this.set("showWarning", showWarning);
    },
    checkSupport: function checkSupport(minimumVersion, regex) {
      var browser = USER_AGENT.match(regex);

      return browser && parseInt(browser[1]) >= minimumVersion;
    }
  });
});