define("copilot/routes/clips/create", ["exports", "copilot/routes/authenticated-route", "ember-service-methods/inject"], function (exports, _authenticatedRoute, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    uploadAsset: (0, _inject.default)(),

    actions: {
      createClip: function createClip(file) {
        return this.uploadAsset(file);
      }
    }
  });
});