define("copilot/controllers/clips/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    showingHistory: false,
    isEditingVariant: false,
    isSelectingPoster: false,
    analytics: Ember.inject.service(),
    photoService: Ember.inject.service("photo"),
    image: Ember.computed.alias("model.posterImage"),

    masterURLWithNoWebp: Ember.computed("url", function () {
      var url = this.photoService.getMasterURL(this.model);
      var urlForOriginalContent = url + "?format=original";
      return urlForOriginalContent;
    }),

    disableVariants: Ember.computed("model._embedded.photosTout.[]", function () {
      return Ember.isEmpty(this.get("model._embedded.photosTout"));
    }),

    actions: {
      showHistory: function showHistory() {
        this.set("showingHistory", true);
      },
      setPosterOnClip: function setPosterOnClip(poster) {
        this.get("model.photosTout").setObjects([poster]);
      },
      viewOriginal: function viewOriginal() {
        // Track if editors are viewing the original clip
        this.analytics.track("clip", "original-clip-viewed");
      }
    },
    type: Ember.computed("model", function () {
      return this.get("model.type");
    }),

    isClip: Ember.computed.equal("type", "clip")
  });
});