define("copilot/services/search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var BEGINNING_TRAILING_SLASHES = /^\/+|\/+$/g;
  var COPILOT_ID = /^[A-Fa-f0-9]{24}$/;
  var HOMEPAGE_PATH = "homepage";

  function extractUri(url, _ref) {
    var brandHostname = _ref.brandHostname;

    var domain = url && domainFromHost(url.hostname);
    var brandDomain = domainFromHost(brandHostname);

    if (domain && domain === brandDomain) {
      var uri = url.pathname.replace(BEGINNING_TRAILING_SLASHES, "") || HOMEPAGE_PATH;
      // Identifiers for fashion show reviews include hashes :/
      if (url.hash) {
        uri += url.hash;
      }
      return { uri: uri };
    }
  }

  function extractCopilotId(value, url, _ref2) {
    var copilotHostname = _ref2.copilotHostname;

    if (COPILOT_ID.test(value)) {
      return { id: value };
    }

    var domain = url && domainFromHost(url.hostname);
    var copilotDomain = domainFromHost(copilotHostname);

    if (domain && domain === copilotDomain) {
      var lastPath = url && url.pathname.replace(BEGINNING_TRAILING_SLASHES, "").split("/").pop();
      if (COPILOT_ID.test(lastPath)) {
        return { id: lastPath };
      }
    }
  }

  function domainFromHost(hostname) {
    return hostname && hostname.split(".").slice(-2).join(".");
  }

  function transformQuery(value, params) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var url = void 0;
    try {
      url = new URL(value);
    } catch (ex) {
      // do nothing
    }

    return extractUri(url, options) || extractCopilotId(value, url, options) || { q: value };
  }

  function transformChannels(value) {
    return value ? {
      hierarchy: value.split(",").map(function (cat) {
        return "channels/" + cat;
      }).join(",")
    } : {};
  }

  function transformDates(value, params) {
    var _ref3;

    if (!value) {
      return {};
    }

    var datePrefix = params.dateParam || "publishdate_";
    var to = value.to,
        from = value.from;

    return _ref3 = {}, _defineProperty(_ref3, datePrefix + "from", typeof from === "function" ? from() : from), _defineProperty(_ref3, datePrefix + "to", typeof to === "function" ? to() : to), _ref3;
  }

  function dateFun(offset, dayFloor) {
    return function () {
      var d = new Date(Date.now() + offset);
      if (dayFloor) {
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
      }
      return d.toISOString();
    };
  }

  var noOffset = dateFun(0);

  var DATE_RANGES = {
    "to-now": {
      from: "*",
      to: "NOW"
    },
    "from-now": {
      from: "NOW",
      to: "*"
    },
    "past-hour": {
      from: dateFun(1000 * 60 * 60 * -1),
      to: noOffset
    },
    "past-24-hours": {
      from: dateFun(1000 * 60 * 60 * 24 * -1),
      to: noOffset
    },
    "past-week": {
      from: dateFun(1000 * 60 * 60 * 24 * 7 * -1, true),
      to: noOffset
    },
    "past-month": {
      from: dateFun(1000 * 60 * 60 * 24 * 30 * -1, true),
      to: noOffset
    },
    "past-year": {
      from: dateFun(1000 * 60 * 60 * 24 * 365 * -1, true),
      to: noOffset
    }
  };

  function transformDateRange(value, params) {
    var range = DATE_RANGES[value];
    return range ? transformDates(range, params) : {};
  }

  function transformCollaborator(value) {
    return value ? { privilege_user: value } : {};
  }

  function transformContributor(value) {
    return value ? { relid: value } : {};
  }

  function transformLivestoryId(value) {
    return value ? { livestoryid: value } : {};
  }

  function transformCreatedDateFrom(value) {
    return value ? { createddate_from: value } : {};
  }

  // Map Ember query params to params need for copilot api request
  var QUERY_MAPPING = {
    query: transformQuery,
    channel: transformChannels,
    collaborator: transformCollaborator,
    contributor: transformContributor,
    dateParam: function dateParam() {
      return {};
    },
    _dates: function _dates() {
      return {};
    },
    livestoryid: transformLivestoryId,
    createddate_from: transformCreatedDateFrom,
    dateRange: transformDateRange,
    dates: transformDates,
    subchannel: transformChannels
  };

  var BASE_URL_PARAMS = {
    limit: 20,
    notcategory: "copilot/preset",
    view: "edit"
  };

  function buildQuery(params, options) {
    return Object.entries(params).filter(function (_ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
          value = _ref5[1];

      return !Ember.isNone(value);
    }).map(function (_ref6) {
      var _ref7 = _slicedToArray(_ref6, 2),
          key = _ref7[0],
          value = _ref7[1];

      var transform = QUERY_MAPPING[key];
      return transform && transform(value, params, options) || _defineProperty({}, key, value);
    }).reduce(function (acc, param) {
      return Object.assign({}, acc, param);
    }, Object.assign({}, BASE_URL_PARAMS));
  }

  function buildQueryString(query) {
    return Object.entries(query).map(function (_ref9) {
      var _ref10 = _slicedToArray(_ref9, 2),
          key = _ref10[0],
          value = _ref10[1];

      return key + "=" + encodeURIComponent(value);
    }).join("&");
  }

  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    brand: Ember.inject.service(),
    config: Ember.inject.service(),
    logger: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    baseUrl: "/api/search",

    brandHostname: Ember.computed.reads("config.config.hostnames.consumer"),
    copilotHostname: Ember.computed("config.config.copilotUrl", function () {
      var copilotUrl = void 0;
      try {
        copilotUrl = new URL(this.config.config.copilotUrl);
      } catch (ex) {
        // Do nothing
      }
      return copilotUrl && copilotUrl.hostname;
    }),

    _parseResponse: function _parseResponse(response, query) {
      var _this = this;

      var hits = response.hits.hits.filter(function (hit) {
        if (hit.error) {
          _this.logger.error("Bad search result", {
            caught: hit.error
          });
          return false;
        } else {
          return true;
        }
      });

      var mappedHits = hits.map(function (item) {
        var result = item._source;
        result.type = item._type;
        result.status = item._status;
        result.brand = _this.activeBrand;
        result.publishHistory = item._source.publishHistory || [];
        result.metadata = item.metadata;
        var model = _this.store.createRecord(result.type);
        var normalizedData = _this.store.adapterFor(model.type).get("serializer").normalize(model.constructor, result);
        model.load(result.id, normalizedData);
        return model;
      }).filter(function (model) {
        return model.type !== "unknown";
      });

      return {
        hits: mappedHits,
        took: response.took,
        totalHits: response.hits.total,
        start: response.start,
        query: query
      };
    },
    execute: function execute() {
      var _this2 = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this.features.get("queryUsingAnd") && params.d_o == null) {
        params.d_o = "and";
      }
      var query = buildQuery(params, this);
      return this.ajax.request(this.baseUrl + "?" + buildQueryString(query)).then(function (results) {
        return _this2._parseResponse(results, query);
      });
    },
    uniqueFields: function uniqueFields(modelSearchFields, model) {
      if (!modelSearchFields || !modelSearchFields.length) {
        return Ember.RSVP.resolve(true);
      }

      var query = {};

      modelSearchFields.forEach(function (field) {
        query[field.searchField] = Ember.get(model, field.modelField);
      });

      if (this.features.get("queryUsingAnd") && query.d_o == null) {
        query.d_o = "and";
      }

      return this.count(Object.assign({}, query, {
        notid: model.id
      })).then(function (count) {
        return count === 0;
      });
    },
    count: function count(params) {
      var options = { brandHostname: this.brandHostname };
      if (this.features.get("queryUsingAnd") && params.d_o == null) {
        params.d_o = "and";
      }
      var query = buildQuery(Object.assign({ limit: 0 }, params), options);
      return this.ajax.request(this.baseUrl + "?" + buildQueryString(query)).then(function (searchResult) {
        if (searchResult) {
          return searchResult.hits.total;
        }

        throw new Error("Could not get total from search request");
      });
    }
  });
});