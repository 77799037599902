define("copilot/mixins/validatable", ["exports", "copilot/models/validation-errors"], function (exports, _validationErrors) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    validation: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.validationErrors) {
        this.validationErrors = _validationErrors.default.create();
      }
    },


    isValid: Ember.computed.equal("validationErrors.length", 0),
    /**
     * Validates current model for a given context
     * @param {String} context save, publish, syndicate
     * @returns {Promise}
     */
    validate: function validate(context) {
      return this.validation.validate(this, context);
    }
  });
});