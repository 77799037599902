define("copilot/components/category-picker/component", ["exports", "ember-concurrency", "copilot-components/mixins/form-inputs"], function (exports, _emberConcurrency, _formInputs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEBOUNCE_MS = 500;
  var limit = 20;

  function handleError(error) {
    this.setProperties({
      roots: [],
      firstLevel: [],
      secondLevel: []
    });
    this.consoleLogger.error("Category level query", {
      caught: error
    });
    if (error.jqXHR && error.jqXHR.status === 401) {
      throw error.jqXHR;
    }
  }

  function fetchChildren(category) {
    return this.categoryService.getChildren(category).then(function (categoriesData) {
      var categories = categoriesData.categories;
      return categories.map(function (childCategory) {
        childCategory.set("parent", category);
        return childCategory;
      }).sortBy("name");
    }).catch(handleError.bind(this));
  }

  function fetchRoots() {
    var categories = this.get("categoryService.roots");
    var roots = categories.filterBy("config.isRootConfigured");

    this.set("roots", roots);
    setRootArrays.call(this);
  }

  function setRootArrays() {
    var roots = this.roots;
    var selected = this.selected;
    roots.forEach(function (root) {
      var rootSlug = root.get("slug");
      if (!selected.hasOwnProperty(rootSlug)) {
        selected[rootSlug] = [];
      }
    });
  }

  function findChildrenCategories(arr, category) {
    return arr.filterBy("id", category.get("id"));
  }

  function setModelProperty() {
    var serializedSelected = this.serializer.serialize(this.selected);
    this.set("model." + this.field, serializedSelected);
  }

  function addCategoryToRoot(root, category) {
    root.pushObject(category);
    setModelProperty.call(this);
  }

  function removeCategoryFromRoot(root, category) {
    var categoryChildren = findChildrenCategories(root, category) || [];
    root.removeObjects(categoryChildren.concat(category));

    setModelProperty.call(this);
  }

  // Reset the component, called from didInitAttrs upon model changed:
  // - Ensure `selected` value is updated
  // - Reset the firstLevel and secondLevel categories emtpy state
  // - Remove any highlighting of roots
  function resetComponentState() {
    var _this = this;

    Ember.run.later(function () {
      ["firstLevel", "secondLevel"].forEach(function (level) {
        if (Ember.isPresent(_this.get(level))) {
          _this.get(level).clear();
        }
      });
      var roots = _this.roots;
      roots && roots.forEach(function (root) {
        root.set("isHighlighted", false);
      });
    });
    var deserializedSelected = this.serializer.deserialize(this.get("model." + this.field));

    this.set("selected", deserializedSelected);
    if (this.roots) {
      setRootArrays.call(this);
    }
  }

  /**
   * A `{{category-picker}}` component displays a heirarchical list of categories
   * and allows a user to select or de-select categories to be included in a property on a model
   *
   * ```hbs
   * {{category-picker model=model field="categories" dataSerializer='map-to-array'}}
   * ```
   *
   * @module copilot/components/category-picker/component
   * @augments Ember.Component
   * @mixes copilot/mixins/copilot-form-inputs
   * @param {String} field - The attribute on the model to store selections
   * @param {(Object|Ember.Model)} model - The model whose 'field' on which the `field` attribute exists
   * @param {String} dataSerializer - An optional value that corresponds to a serializer for transforming the value
   * to meet the data structure of the model's attribute.
   * Default: 'passthrough'.
   * Example: 'map-to-array' will allow the component to work with a model whose attribute stores an array of categories.
   *
   */
  exports.default = Ember.Component.extend(_formInputs.default, {
    intl: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    consoleLogger: Ember.inject.service(),
    categoryService: Ember.inject.service("category"),
    classNames: ["category-picker"],
    rootSortDefinition: Object.freeze(["config.minSelected:desc", "name:asc"]),
    sortedRoots: Ember.computed.sort("roots", "rootSortDefinition"),

    init: function init() {
      this._super.apply(this, arguments);
      var serializerName = this.dataSerializer || "passthrough";
      this.serializer = Ember.getOwner(this).lookup("serializer:categories/" + serializerName);

      // convert initial state into appropriate structure for category picker.
      var deserializedSelected = this.serializer.deserialize(this.get("model." + this.field));

      this.set("selected", deserializedSelected);
      fetchRoots.call(this);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      resetComponentState.call(this);
    },
    transformCategoryResults: function transformCategoryResults(categories) {
      var _this2 = this;

      return categories.map(function (result) {
        var hierarchy = result.get("hierarchy");
        var pathSeparator = _this2.intl.t("category-manager.pathSeparator");
        var displayLabel = hierarchy.mapBy("name").reverse().join(pathSeparator);
        result.set("displayLabel", displayLabel);

        return result;
      }).filter(function (cat) {
        return cat.get("config.selectable");
      });
    },


    search: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      var configuredRoots = this.roots.map(function (root) {
        return root.get("slug");
      });
      var results = yield this.categoryService.search({ hierarchy: configuredRoots.join(","), limit: limit }, term);
      var transformedResults = this.transformCategoryResults.call(this, results);
      var hasSuggestions = Ember.isPresent(transformedResults);
      this.set("hasSuggestions", hasSuggestions);
      if (hasSuggestions) {
        return transformedResults;
      }
      this.set("noMatchesMessage", this.intl.t("helperText.categoryPicker.noResults"));
      return [];
    }).restartable(),

    actions: {
      reload: function reload() {
        fetchRoots.call(this);
      },
      toggleSelection: function toggleSelection(category) {
        if (category.type !== "category") {
          return;
        }
        var selectedRoot = this.get("selected." + category.get("rootSlug"));

        if (selectedRoot.mapBy("id").includes(category.get("id"))) {
          removeCategoryFromRoot.call(this, selectedRoot, category);
        } else {
          addCategoryToRoot.call(this, selectedRoot, category);
        }
      },
      close: function close(select) {
        var input = document.querySelector("#ember-power-select-typeahead-input-" + select.uniqueId);
        input.value = "";
        select.actions.search("");
      },
      fetch: function fetch(category) {
        if (category.get("isRoot")) {
          this.set("secondLevel", null);
        } else if (!category.get("parent.isRoot")) {
          // category is in the second level and its children can't be displayed
          return Promise.resolve([]);
        }

        return fetchChildren.call(this, category).then(function (children) {
          return children.sortBy("name");
        });
      }
    }
  });
});