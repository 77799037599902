define("copilot/mixins/embeddable", ["exports", "ember-model", "copilot/mixins/copilot", "copilot/lib/copilot-markdown", "ember-inflector", "@sentry/browser"], function (exports, _emberModel, _copilot, _copilotMarkdown, _emberInflector, _browser) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IGNORE_TYPES = ["twitter", "instagram", "facebook", "pinterest", "iframe", "section", "callout", "cneplaylist", "video", "cneembed"];
  var REGEX_STRING = /^\/(\w+)\/(\w+)$/;
  var BLOCK_LEVEL_SEPARATOR = "\n\n";

  /**
   * Manage the inline relationship derived from embedded objects in md-textarea
   * @mixin copilot/mixins/embeddable
   *
   */

  exports.default = _copilot.default.create(
  /** @lends copilot/mixins/embeddable */
  {
    intl: Ember.inject.service(),

    inline: (0, _emberModel.hasMany)("base", {
      embedded: true,
      polymorphic: true
    }),

    beforeSaveHook: function beforeSaveHook() {
      this.pruneInline();
    },


    /**
     @property {array} The names of fields which may contain text with embeddable
     * items, to be extracted and appended to the inlines array property.
     * models that utalize this mixin should override with the appropriate fields.
     */
    embeddableFields: [],

    embeddedItems: [],

    /**
     * Get the single embedded value for all of the embeddable fields
     * @returns {string|*}
     * @private
     */
    getEmbeddedText: function getEmbeddedText() {
      var _this = this;

      var fields = Ember.get(this, "constructor.metadata.embeddableFields") || this.embeddableFields || [];
      var textArr = Ember.A();

      fields.forEach(function (field) {
        var tokens = field.split(".");
        _this._getNestedText(_this, tokens, textArr);
      });

      // Must join items with a block level separator to avoid concatenating
      // items with inline-level elements directly with block-level elements and
      // vice-versa.
      return textArr.compact().join(BLOCK_LEVEL_SEPARATOR);
    },
    _getNestedText: function _getNestedText(model, tokens, textArr) {
      var _this2 = this;

      var prop = tokens[0];
      if (tokens.length > 1) {
        Ember.get(model, prop).forEach(function (item) {
          var newTokens = tokens.slice(1);
          _this2._getNestedText(item, newTokens, textArr);
        });
      } else {
        if (prop === "[]") {
          textArr.push(model);
        } else {
          textArr.push(Ember.get(model, prop));
        }
      }
    },


    /**
     * Generate embedded object from a url
     * @param {String} url
     * @returns {Object|null}
     * @private
     */
    setEmbeddedItem: function setEmbeddedItem(url) {
      var embedded = {};
      var match = [];
      var regex = new RegExp(REGEX_STRING);
      match = regex.exec(url);

      if (match && match.length > 1) {
        var embed = this.embeddedItems.findBy("id", match[2]);
        if (embed) {
          return null;
        } else {
          embedded = {
            resource: (0, _emberInflector.singularize)(match[1]),
            id: match[2],
            url: url
          };
          return embedded;
        }
      }
      return null;
    },


    /**
     * Remove embedded items from array of inline items.
     * @private
     */
    pruneInline: function pruneInline() {
      var ids = this.embeddedItems.mapBy("id");

      var objectsToRemove = Ember.A();
      this.inline.forEach(function (data) {
        if (!ids.includes(Ember.get(data, "id"))) {
          objectsToRemove.pushObject(data);
        }
      }, this);

      this.inline.removeObjects(objectsToRemove);
    },


    /**
     * Validate whether an item already exists by type and ID
     * @param item
     * @private
     */
    validateItemExists: function validateItemExists(item) {
      // Use private find which bypasses our custom `find` which
      // unloads items from the cache, which we don't want here
      // By default `_find` is synchronous (doesn't wait for fetch)
      // and accepts a boolean as the third to override that and fetch
      // asynchronousely
      return this.store._find((0, _emberInflector.singularize)(item.resource), item.id, true);
    },


    /**
     * Remove items that do not have a URL
     * @param items
     * @private
     */
    removeDeletedItems: function removeDeletedItems(items) {
      var newEmbeds = this.embeddedItems.filter(function (obj) {
        return items.findBy("url", obj.url);
      });

      this.set("embeddedItems", newEmbeds);
    },


    /**
     * @return {Promise}
     */
    setInlines: function setInlines() {
      var _this3 = this;

      var def = Ember.RSVP.defer();
      var text = this.getEmbeddedText();

      this.insertInlineEmbed(text).then(function (contents) {
        contents.forEach(function (item) {
          var inline = _this3.inline;
          if (Ember.get(item, "createdAt") && !inline.mapBy("id").includes(item.get("id"))) {
            inline.pushObject(item);
          }
        });
        def.resolve(contents);
      }).catch(function (error) {
        error = error.responseJSON ? error : error.jqXHR;
        var intl = _this3.intl;
        var statusCode = error.responseJSON && error.responseJSON.status;

        if (statusCode === 404) {
          error.responseJSON.message = intl.t("validationMessage.inline-all-present");
          _browser.captureException(error);
        } else {
          error.responseJSON.message = intl.t("helperText.requestError");
        }

        def.reject(error);
      });

      return def.promise;
    },


    /**
     * @param text
     * @return {Promise}
     */
    insertInlineEmbed: function insertInlineEmbed(text) {
      var res = void 0;
      var defferedValidations = [];

      var allowedItems = this._getAllowedItems(text);
      _browser.addBreadcrumb({
        category: "save",
        message: "Adding inline rels: " + allowedItems.map(function (item) {
          return item.url;
        }).join(', '),
        level: _browser.Severity.Info
      });

      defferedValidations = allowedItems.map(function (item) {
        res = this.setEmbeddedItem(item.url);
        if (res) {
          this.embeddedItems.push(res);
        } else {
          return;
        }
        return this.validateItemExists(res);
      }, this).compact();
      return Ember.RSVP.all(defferedValidations);
    },


    /**
     * @param text
     * @returns {Array}
     * @private
     */
    _getAllowedItems: function _getAllowedItems(text) {
      var items = _copilotMarkdown.default.getEmbeds(text);

      // TODO: move this brand check to an extended mixin for the brand.
      if (this.activeBrand === "epi") {
        IGNORE_TYPES.removeObject("video");
      }

      var allowedItems = items.filter(function (item) {
        return IGNORE_TYPES.indexOf(item.type.toLowerCase()) < 0;
      });
      this.removeDeletedItems(allowedItems);
      return allowedItems;
    }
  });
});