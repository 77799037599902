define("copilot/validations/brands/cni/article", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "copilot/validations/brands/cni/validators/top-level-category", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _topLevelCategory, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var articleValidationBuilder = (0, _crossCheckDsl.extend)(_copilotCore.ArticleValidationsDescriptor).catch((0, _crossCheckDsl.mutePath)(["body"])).andAlso(object({
    body: (0, _copilotValidators.optional)((0, _copilotValidators.markdownLinks)({ relativeUrl: true })).andThen((0, _copilotValidators.required)().on("publish")),
    categories: (0, _topLevelCategory.default)().on("publish")
  }));

  exports.default = articleValidationBuilder;
});