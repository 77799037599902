define("copilot/controllers/bundles/index", ["exports", "copilot/controllers/base", "copilot/lib/lookup-container-config-property", "copilot/models/validation-errors", "copilot/utils/bundleRedirect"], function (exports, _base, _lookupContainerConfigProperty, _validationErrors, _bundleRedirect) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function getContainerConfigsToAdd() {
    var newContainers = this.newContainers;
    var allContainerConfigurations = this.allContainerConfigurations;
    return newContainers.map(function (addedContainer) {
      return allContainerConfigurations[addedContainer];
    });
  }

  function getContainerConfigurations() {
    var bundleTypeContainers = this.bundleTypeContainers;
    var allContainerConfigurations = this.allContainerConfigurations;
    return bundleTypeContainers.map(function (containerValue) {
      return allContainerConfigurations[containerValue];
    });
  }

  function createContainers() {
    var _this = this;

    var containerConfigurations = getContainerConfigurations.call(this);

    if (!Ember.isEmpty(this.id)) {
      return;
    }

    var containers = [];

    containerConfigurations.forEach(function (container) {
      var c = createBundleContainer.call(_this, container);
      containers.pushObject(c);
    });
    this.get("model.containers").setObjects(containers);
  }

  function addContainer(containerToAdd) {
    var containers = this.get("model.containers");

    if (containerToAdd) {
      var createdContainer = createBundleContainer.call(this, containerToAdd);
      containers.pushObject(createdContainer);
    }
  }
  function updateContainers() {
    var _this2 = this;

    var newContainerConfigurations = getContainerConfigsToAdd.call(this);
    var containers = this.get("model.containers");

    if (newContainerConfigurations) {
      newContainerConfigurations.forEach(function (containerConfiguration) {
        var createdContainer = createBundleContainer.call(_this2, containerConfiguration);
        containers.pushObject(createdContainer);
      });
    }
  }
  function setDefaultValues(model) {
    Object.entries(model.constructor.metadata.defaultValues).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      if (Ember.isEmpty(model[key])) {
        model.set(key, value);
      }
    });
  }
  var createBundleContainer = function createBundleContainer(container) {
    var klass = this.store.modelFor("bundle-container");
    var c = klass.create({
      curationContainerType: Ember.get(container, "value"),
      itemLimit: Ember.get(container, "items.itemLimit")
    });
    Ember.setOwner(c, Ember.getOwner(this));
    var arrangedBundleContainers = this.get("configService.arrangedBundleContainers");
    var curationType = (0, _lookupContainerConfigProperty.default)(c, arrangedBundleContainers, "curationType");
    if (["search", "multi-search"].includes(curationType)) {
      c.get("searches").pushObject(createBundleSearchModel.call(this));
    }
    setDefaultValues(c);
    c.curations.forEach(setDefaultValues);
    c.searches.forEach(setDefaultValues);

    return c;
  };

  var createBundleSearchModel = function createBundleSearchModel() {
    var owner = Ember.getOwner(this);
    var model = this.store.modelFor("bundle-search").create(owner.ownerInjection());
    return model;
  };

  exports.default = _base.default.extend({
    configService: Ember.inject.service("config"),
    features: Ember.inject.service(),
    v2Url: Ember.computed.reads("configService.config.copilotV2Url"),
    currentBrand: Ember.computed.reads("brandConfig.brand.activeBrand"),
    allContainerConfigurations: Ember.computed.reads("configService.arrangedBundleContainers"),
    existingContainerTypes: Ember.computed.mapBy("model.containers", "curationContainerType"),
    newContainers: Ember.computed("existingContainerTypes.[]", "bundleTypeContainers.[]", function () {
      var existingContainerTypesClone = this.existingContainerTypes.slice(0);
      var bundleTypeContainers = this.bundleTypeContainers;
      var containersToAdd = [];
      bundleTypeContainers.forEach(function (containerTypeName) {
        var containerNameIndex = existingContainerTypesClone.indexOf(containerTypeName);
        if (containerNameIndex > -1) {
          existingContainerTypesClone.splice(containerNameIndex, 1);
        } else {
          containersToAdd.push(containerTypeName);
        }
      });
      return containersToAdd;
    }),
    containersToAdd: Ember.computed("configService.config.contentTypes.[]", function () {
      var contentTypes = this.get("configService.config.contentTypes");
      var containers = contentTypes.find(function (o) {
        return o.value === "bundle";
      }).containers;
      var combinedContainers = [].concat(_toConsumableArray(new Set([].concat(_toConsumableArray(this.bundleTypeContainers), _toConsumableArray(this.existingContainerTypes)))));
      return containers.filter(function (el) {
        return combinedContainers.includes(el.value);
      }) || [];
    }),
    isVersoBundle: Ember.computed("configService.config.contentTypes.[]", function () {
      var bundleSubtype = this.model.bundleType.toLowerCase();
      var test = /verso|newsletter|app/.test(bundleSubtype);
      return test;
    }),

    showConfirmationModal: false,
    bundleContainerToDelete: null,

    sortableContainers: Ember.computed.reads("features.sortBundleContainers"),

    bundleTypeContainers: Ember.computed("model.bundleType", "configService.arrangedBundleTypes", function () {
      var bundleType = this.get("model.bundleType");
      var arrangedBundleTypes = this.get("configService.arrangedBundleTypes");
      return arrangedBundleTypes[bundleType].containers;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.eventBus.on("success", this, "stopProgress");
      this.eventBus.on("error", this, "stopProgress");
      this.beforeValidationHooks.pushObject("clearChildValidation");
    },
    clearChildValidation: function clearChildValidation() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this3.get("model.containers").forEach(function (container) {
          container.set("validationErrors", _validationErrors.default.create());
        });
        resolve();
      });
    },
    stopProgress: function stopProgress() {
      this.set("inProgress", false);
    },
    save: function () {
      var _ref3 = _asyncToGenerator(function* () {
        yield this._super.apply(this, arguments);
        this.stopProgress();
        var id = this.model.id;
        if (id && this.router.currentRouteName === "bundles.create") {
          this.transitionToRoute("bundles.index", id);
        }
      });

      function save() {
        return _ref3.apply(this, arguments);
      }

      return save;
    }(),
    saveAddContainer: function () {
      var _ref4 = _asyncToGenerator(function* () {
        yield this.save.apply(this, arguments);
        this.stopProgress();
        // We need to this in order to reload the hasMany Relationships
        this.model.revert();
        var newlyCreatedContainerID = this.model.get("containers.content.lastObject").id;
        this.transitionToRoute("bundles.index.section", newlyCreatedContainerID);
      });

      function saveAddContainer() {
        return _ref4.apply(this, arguments);
      }

      return saveAddContainer;
    }(),
    saveDeleteContainer: function () {
      var _ref5 = _asyncToGenerator(function* () {
        yield this.save.apply(this, arguments);
        this.stopProgress();
        var routeRecognizer = this.router._routerMicrolib.recognizer;
        var routeHandlers = routeRecognizer.recognize(this.router.currentURL);
        var leafRoute = routeHandlers[routeHandlers.length - 1];
        if (leafRoute.handler === "bundles.index.section") {
          var currentContainerId = leafRoute.params.id;
          var obj = this.model.get("containers").content.find(function (o) {
            return o.id === currentContainerId;
          });
          if (!obj) {
            this.transitionToRoute("bundles.index", this.model.id);
          }
        }
      });

      function saveDeleteContainer() {
        return _ref5.apply(this, arguments);
      }

      return saveDeleteContainer;
    }(),


    actions: {
      createBundle: function createBundle(bundleType) {
        var model = this.model;
        model.set("bundleType", Ember.get(bundleType, "type"));
        if ((0, _bundleRedirect.isV2Bundle)(this.features, model.bundleType)) {
          (0, _bundleRedirect.redirectBundle)(model, this.v2Url, this.currentBrand);
          return;
        } else {
          this.set("inProgress", true);
          model.set("isNative", !!Ember.get(bundleType, "isNative"));
          createContainers.call(this);
          this.send("save");
        }
      },
      closeModal: function closeModal() {
        this.set("openModal", false);
      },
      sortItems: function sortItems(oldIndex, newIndex) {
        var items = this.get("model.containers");
        var item = items.objectAt(oldIndex);
        items.removeAt(oldIndex);
        items.insertAt(newIndex, item);
      },
      submit: function submit() {
        updateContainers.call(this);
        this.set("openModal", false);
        this.send("save");
      },
      onSelectContainer: function onSelectContainer(container) {
        addContainer.call(this, container);
        this.saveAddContainer();
      },
      deleteContainer: function deleteContainer() {
        var bundleContainerToDelete = this.get("bundleContainerToDelete");
        if (bundleContainerToDelete) {
          var items = this.get("model.containers");
          var bundleContainerIndex = items.indexOf(bundleContainerToDelete);
          items.removeAt(bundleContainerIndex);
          this.saveDeleteContainer();
          this.set("showConfirmationModal", false);
        }
      },
      showConfirmationModal: function showConfirmationModal(bundleContainer) {
        this.set("bundleContainerToDelete", bundleContainer);
        this.set("showConfirmationModal", true);
      }
    }
  });
});