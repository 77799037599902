define("copilot/models/photo", ["exports", "copilot/models/media"], function (exports, _media) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Photo = _media.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.defineContributors();
    },
    defineContributors: function defineContributors() {
      var contributorRelationships = [].concat(_toConsumableArray(this.constructor.relationships.keys())).filter(function (key) {
        return key.startsWith("contributors");
      });

      Ember.defineProperty(this, "contributors", Ember.computed.apply(this, [].concat(_toConsumableArray(contributorRelationships.map(function (key) {
        return key + ".[]";
      })), [function () {
        var _this = this;

        return contributorRelationships.map(function (key) {
          return { key: key, value: _this.get(key) };
        }).filter(function (relationship) {
          return relationship.value.get("length") > 0;
        });
      }])));
    },

    exif: Ember.computed("_data.exif", function () {
      return (this._data.exif || []).reduce(function (tags, _ref) {
        var tagName = _ref.tagName,
            tagValue = _ref.tagValue,
            tagGroup = _ref.tagGroup;

        tags[tagGroup] = tags[tagGroup] || {};
        tags[tagGroup][tagName] = tagValue;
        return tags;
      }, {});
    }),
    fileSizeLimit: Ember.computed.reads("configService.config.photo.fileSizeLimit"),
    masterURL: Ember.computed("aspectRatios", function () {
      // two cases here. when the photo is saved and has an id build a vulcan url
      // if not, the photo is being created, and the url is a data uri
      return this.id ? Ember.getOwner(this).lookup("service:photo").getMasterURL(this) : this.get("aspectRatios.firstObject.url");
    })
  });

  exports.default = Photo;
});