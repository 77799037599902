define("copilot/brands/vogue/routes/seasons/fashionshows", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    model: function model(params) {
      var baseUrl = "/api/seasons/:id/fashionshows/dashboard";
      var url = baseUrl.replace(":id", params.season_id);
      return Ember.$.getJSON(url).then(function (data) {
        var owner = Ember.getOwner(this);
        var SlimFashionshow = owner.factoryFor("model:slim-fashionshow");
        var mappedFashionshows = data.fashionshows.map(function (show) {
          return SlimFashionshow.create({
            content: show._source,
            inflight: Ember.A()
          });
        });
        data.fashionshows = mappedFashionshows;
        return data;
      }.bind(this));
    }
  });
});