define("copilot/components/search-slat/-private/out-of-stock-indicator/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showTooltip: true,
    tagName: ""
  });
});