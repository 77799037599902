define("copilot/services/assign", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    userService: Ember.inject.service("user"),

    /**
     * Set revision author field with the current user's name
     * @param {Object} model
     * @param {boolean} isSyndicating
     */
    assignCollaborators: function assignCollaborators(model, isSyndicating) {
      var user = this.userService.get("user");

      model.set("revisionAuthor", user.get("fullName"));
      model.set("metadata.author", user.get("fullName"));

      if (!isSyndicating) {
        var users = model.get("entityMetadata.users");

        users.pushObject(user.get("id"));
        users = users.uniq();

        model.set("entityMetadata.users", users);
        model.set("entityMetadata.brand", model.get("activeBrand"));
      }
    }
  });
});