define("copilot/brands/vin/models/offer", ["exports", "ember-model", "copilot/models/attr", "copilot/models/offer"], function (exports, _emberModel, _attr, _offer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var string = _attr.default.str;
  exports.default = _offer.default.extend({
    currency: (0, _emberModel.attr)(string, {
      defaultValue: "INR"
    })
  });
});