define("copilot/components/double-clickable/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    doubleClick: function doubleClick() {
      this.action();
    }
  });
});