define("copilot/helpers/auto-fill", ["exports", "copilot/lib/copilot-markdown"], function (exports, _copilotMarkdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    schemaHelpers: Ember.inject.service(),

    content: null,

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          model = _ref2[0],
          config = _ref2[1];

      if (config == null) {
        return undefined;
      }

      this.setProperties({
        model: model,
        path: config.source ? config.source : config
      });
      this.autoFillDidChange();

      var value = this.content;
      if (config.sanitize !== false && value != null) {
        value = _copilotMarkdown.default.sanitize(value);
      }
      return value;
    },


    autoFillDidChange: Ember.observer("path", function () {
      var path = this.path;
      if (path) {
        Ember.defineProperty(this, "content", Ember.computed.reads("model." + path));
      } else {
        Ember.defineProperty(this, "content", null);
      }
      this.contentDidChange();
    }),

    contentDidChange: Ember.observer("content", function () {
      this.recompute();
    })
  });
});