define("copilot/lib/cookie", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = {
    get: function get(cookieKey) {
      var cookiePairs = document.cookie.split(";");
      var pair = cookiePairs.map(function (pairString) {
        return pairString.trim().split("=");
      }).find(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            key = _ref2[0];

        return key === cookieKey;
      });
      return pair && pair[1];
    },
    set: function set(hash) {
      // This is an object
      Object.keys(hash).forEach(function (key) {
        var value = hash[key];
        document.cookie = key + "=" + value + "; path=/; max-age=31536000;";
      });
    },
    remove: function remove() {
      var date = new Date();
      date.setMilliseconds(date.getMilliseconds() - 86400);

      for (var _len = arguments.length, keys = Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      keys.forEach(function (key) {
        document.cookie = key + "=; path=/; expires=" + date.toUTCString() + ";";
      });
    }
  };
});