define("copilot/controllers/articles/syndicate", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    configService: Ember.inject.service("config"),
    validation: Ember.inject.service(),
    syndicate: Ember.inject.service(),
    assetFinderConfig: Ember.computed.reads("brandConfig.afm.article.body"),
    associatedContentImageUrlContent: Ember.computed.reads("brandConfig.article.associatedContent.imageUrl.content"),
    brands: Ember.computed.reads("userService.user.brands"),
    currentUser: Ember.computed.reads("userService.user.fullName"),

    loading: false,

    validate: function validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var model = _this.model;
        var validationService = _this.validation;

        validationService.validate(model, "syndicate").then(function (isValid) {
          if (isValid) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    triggerSuccess: function triggerSuccess() {
      this.get("applicationController.eventBus").trigger("success", {
        status: "success",
        message: "Content syndicated",
        priority: 3
      });
    },


    actions: {
      syndicate: function syndicate(targetBrand) {
        var _this2 = this;

        this.set("loading", true);
        this.triggerProgress();
        var model = this.model;
        this.set("targetBrand", targetBrand);
        this.validate().then(function () {
          return _this2.syndicate.syndicate(model, {
            targetBrand: _this2.targetBrand
          });
        }, function () {
          _this2.triggerValidationError();
        }).then(function () {
          _this2.set("loading", false);
          _this2.triggerSuccess();
          _this2.store.find("article", _this2.get("model.id")).then(function (updatedModel) {
            _this2.set("model.syndication", updatedModel.get("syndication"));
            _this2.set("model.publishHistory", updatedModel.get("publishHistory"));
          });
        }).catch(function (err) {
          _this2.set("loading", false);
          _this2.saveFailure(err);
        });
      }
    }
  });
});