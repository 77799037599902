define("copilot/schemas/brands/bon/additional-property", ["exports", "@condenast/copilot-brand-overrides/dist/modules/schemas/bon/additional-property"], function (exports, _additionalProperty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return _additionalProperty.default;
    }
  });
});