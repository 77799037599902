define("copilot/models/list-item", ["exports", "copilot/models/embedded", "ember-model", "copilot/mixins/list-item-properties", "copilot/models/attr"], function (exports, _embedded, _emberModel, _listItemProperties, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;
  exports.default = _embedded.default.extend(_listItemProperties.default, {
    shouldPreFillHed: true,
    hed: (0, _emberModel.attr)(str),
    dek: (0, _emberModel.attr)(),
    rubric: (0, _emberModel.attr)(),
    type: "list-item"
  });
});