define("copilot/initializers/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    Ember.debug("Route App Initializer");

    // Inject router into resource-link-to, nav-create-items and search result components
    // application.inject('component:main-nav', 'router', 'router:main');
    application.inject("component:resource-link-to", "router", "router:main");
    // application.inject('component:card-item', 'router', 'router:main');
    // application.inject('component:gallery-item-header', 'router', 'router:main');
    application.inject("controller", "router", "router:main");
  }

  exports.default = {
    name: "route",
    initialize: initialize,
    after: "store-model"
  };
});