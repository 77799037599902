define("copilot/components/search-slat/-private/curation-subtype/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    curatedSubType: Ember.computed("result", function () {
      switch (this.result.curationSubType) {
        case "LinkList":
          return "Link List";
        case "AssetsOnly":
          return "Default";
        case "LinkBannerMarquee":
          return "Link Banner - Marquee";
        case "LinkBannerMultipleLinks":
          return "Link Banner - Multiple Links";
        case "LinkBannerVisual":
          return "Visual Link banner";
        default:
          return "Default";
      }
    })
  });
});