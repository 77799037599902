define("copilot/brands/cnt/models/ship", ["exports", "copilot/brands/cnt/models/venue", "copilot/models/attr"], function (exports, _venue, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ratingBlock = _attr.default.ratingBlock;
  var attr = Ember.attr;
  var obj = _attr.default.obj,
      str = _attr.default.str;


  var Ship = _venue.default.extend({
    bars: attr(Number),
    cabins: attr(Number),
    cabinsWithBalconies: attr(Number),
    cafes: attr(Number),
    clubs: attr(Number),
    cruiseLine: attr(str),
    features: attr(obj),
    passengers: attr(Number),
    restaurants: attr(Number),
    size: attr(str),
    smallestCabin: attr(Number),
    subtype: attr(str),
    swimmingPools: attr(Number),
    type: "ship",
    unicode: attr(),
    ratingBlock: attr(ratingBlock)
  });

  Ship.reopenClass({
    url: "/api/ships"
  });

  exports.default = Ship;
});