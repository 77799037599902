define("copilot/routes/bundles/index/section", ["exports", "copilot/routes/authenticated-route", "copilot/lib/lookup-container-config-property"], function (exports, _authenticatedRoute, _lookupContainerConfigProperty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    model: function model(params) {
      var bundle = this.modelFor("bundles.index");
      var containers = bundle.get("containers");
      var containerModel = containers.toArray().find(function (container) {
        return container.get("id") === params.id;
      });
      return containerModel;
    },
    renderTemplate: function renderTemplate(controller, model) {
      var arrangedBundleContainers = this.get("configService.arrangedBundleContainers");
      var curationType = (0, _lookupContainerConfigProperty.default)(model, arrangedBundleContainers, "curationType");
      this.render("bundles/index/" + curationType, {
        controller: "bundles/index/section"
      });
    }
  });
});