define("copilot/validations/brands/vf/external", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var externalValidators = object({
    hed: (0, _copilotValidators.required)(),
    url: (0, _copilotValidators.required)().andThen((0, _copilotValidators.url)("absolute", "leading-slash").catch(function () {
      return [{ path: [], message: { name: "url-absolute-leading-slash" } }];
    }))
  });

  exports.default = (0, _crossCheckDsl.default)(externalValidators);
});