define("copilot/brands/epi/controllers/taxonomies/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    uriMapConfig: Ember.computed(function () {
      var contentTypeConfig = this.configService.getContentTypeConfig(this.model);
      return contentTypeConfig.publish.uriMap;
    }),

    isCategoryUriPart: Ember.computed("model.publishHistory.data.length", function () {
      return this.uriMapConfig.includes("category") && this.get("model.publishHistory.data.length") > 0;
    })
  });
});