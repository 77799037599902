define("copilot/components/image-on-demand/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["image-on-demand", "thumb"],

    photoService: Ember.inject.service("photo"),

    actions: {
      revokeObjectURL: function revokeObjectURL(evt) {
        Ember.run(function () {
          var src = evt.target.src;

          if (/^blob:/.test(src)) {
            URL.revokeObjectURL(src);
          }
        });
      }
    },

    aspectRatio: "master",

    vulcanString: function vulcanString(id, filename, type) {
      var sanitizedFilename = encodeURI(filename);
      return this.photoService.getImageURL({
        width: this.width,
        height: this.height,
        aspectRatio: this.aspectRatio,
        filename: sanitizedFilename,
        id: id,
        type: type || this.get("model.type")
      });
    },
    getVulcanImageUrl: function getVulcanImageUrl(model) {
      if (model && Ember.get(model, "type") === "photo" || model.aspectRatios) {
        var id = Ember.get(model, "id");
        var filename = Ember.get(model, "filename");
        // @todo when is this case valid? all photos should have an id and filename
        if (Ember.isEmpty(filename) || Ember.isEmpty(id)) {
          return Ember.get(model, "aspectRatios.master.url") || Ember.get(model, "aspectRatios.firstObject.url") || null;
        }
        return this.vulcanString(id, filename, Ember.get(model, "meta.modelName"));
      } else {
        return null;
      }
    },


    hasModel: Ember.computed("model", function () {
      return !!this.model;
    }),

    externalImageUrl: Ember.computed("imageUrl", "width", "height", function () {
      if (!this.imageUrl) {
        return null;
      }

      var _ref = new URL(this.imageUrl),
          origin = _ref.origin,
          pathname = _ref.pathname,
          searchParams = _ref.searchParams;

      var imageClass = "class=" + searchParams.get("class");
      var width = "width=" + (this.width || searchParams.get("width"));
      var height = "width=" + (this.height || searchParams.get("height"));
      return "" + origin + pathname + "?" + imageClass + "&" + width + "&" + height;
    }),

    imageUrl: Ember.computed("model.{id,filename}", "width", "height", function () {
      var model = this.model;
      if (!model) {
        return "";
      }

      return this.get("model.imageUrl") || this.getVulcanImageUrl(model);
    })
  });
});