define("copilot/routes/index", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    redirect: function redirect() {
      (false && !(false) && Ember.assert("This route should be unreachable!"));
    }
  });
});