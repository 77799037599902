define("copilot/brands/vogue/routes/seasons/index", ["exports", "ember-service-methods/inject", "copilot/routes/authenticated-route"], function (exports, _inject, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    search: (0, _inject.default)(),

    model: function model() {
      var owner = Ember.getOwner(this);

      // TODO [2018-02-24]: Now that parital updates are available and the query-builder yields
      // firt-class Ember.Model (soon to be Ember Data) models, consider employing
      // them instead of the `slim-model` implementation here, which really stradles
      // the roles of both models and components
      return this.search({
        types: "season",
        dateRange: "past-year",
        dateParam: "revisiondate_",
        limit: 200
      }).then(function (data) {
        var slimSeason = owner.factoryFor("model:slim-season");
        var mappedHits = data.hits.map(function (hit) {
          return slimSeason.create({
            content: Ember.get(hit, "_data"),
            inflight: Ember.A()
          });
        });
        data.hits = mappedHits;
        return data;
      });
    }
  });
});