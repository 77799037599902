define("copilot/validations/brands/cnt/destination", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators", "@condenast/copilot-core"], function (exports, _crossCheckDsl, _copilotValidators, _copilotCore) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object;


  var destinationValidators = object({
    name: (0, _copilotValidators.required)(),
    description: (0, _copilotValidators.optional)((0, _copilotValidators.markdownLinks)()).andThen((0, _copilotValidators.required)().on("publish")),
    country: (0, _copilotValidators.required)().on("publish"),
    city: (0, _copilotValidators.required)().on("publish"),
    primaryLanguage: (0, _copilotValidators.required)().on("publish"),
    timezone: (0, _copilotValidators.required)().on("publish"),
    currency: (0, _copilotValidators.required)().on("publish"),
    photosTout: (0, _copilotValidators.length)({ min: 1 }).on("publish"),
    geo: (0, _copilotValidators.geo)()
  }).andAlso((0, _copilotValidators.inlineAllPresent)()).andAlso((0, _copilotCore.ifFeature)("publishData", (0, _copilotValidators.metadata)().on("publish")));

  exports.default = (0, _crossCheckDsl.default)(destinationValidators);
});