define("copilot/models/embedded", ["exports", "ember-model", "copilot/mixins/validatable"], function (exports, _emberModel, _validatable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberModel.Model.extend(_validatable.default, {
    autogenerates: Ember.computed.reads("constructor.metadata.autogenerates"),
    toStringExtension: function toStringExtension() {
      return this.type + ":" + this.id;
    }
  });
});