define("copilot/mixins/localizeable", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),

    /**
     * This is resource is isLocalizable if it's model has "lang" attribute defined.
     */
    isLocalizable: Ember.computed("lang", function () {
      // Intentionally check the descriptor instead of value of it
      return !!this.lang;
    }),

    /**
     * Translate this entity with a new lang copy of it through the `/api/translate` endpoint.
     */
    translate: function translate(lang) {
      (false && !("the lang is required.") && Ember.assert(lang, "the lang is required."));


      var collectionName = this.get("meta.collectionName");
      var id = this.id;
      return (0, _icAjax.default)("/api/" + collectionName + "/" + id + "/translate", {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({
          targetLang: lang
        })
      });
    },


    /**
     * Translations for this entity through the `/api/translations` endpoint.
     */
    translations: function translations() {
      var draft = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var collectionName = this.get("meta.collectionName");
      var id = this.id;
      return (0, _icAjax.default)("/api/" + collectionName + "/" + id + "/translations?draft=" + draft, {
        type: "GET",
        contentType: "application/json"
      });
    }
  });
});