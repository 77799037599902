define("copilot/adapters/photo", ["exports", "ic-ajax", "copilot/adapters/application"], function (exports, _icAjax, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    find: function find(record, id) {
      var _this = this;

      var url = this.buildURL(record.constructor, id);

      // For GET requests we always want to get the data
      // Setting this to an empty string prevents 304's
      return (0, _icAjax.raw)(url, {
        headers: { "if-none-match": "" },
        shouldOmitExif: false
      }).then(function (result) {
        if (_this.get("features.optimisticLocking")) {
          var etag = result.jqXHR.getResponseHeader("ETag");
          record.etag = etag;
        }
        var data = result.response;
        _this.didFind(record, id, data);
        return record;
      });
    }
  });
});