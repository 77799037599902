define("copilot/mixins/unsaved-warning", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var ignoreTransitionsBetween = ["bundles", "galleries", "livestories"];

  exports.default = Ember.Mixin.create({
    analytics: Ember.inject.service(),
    transition: null,
    isUserAction: false,

    setupController: function setupController() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.$(window).on("beforeunload.unsaved-warning", Ember.run.bind(this, function () {
        var dirtyAttributes = Ember.get(_this, "controller.model._dirtyAttributes");
        var isUnsaved = dirtyAttributes && dirtyAttributes.length;

        if (isUnsaved) {
          Ember.get(_this, "analytics").track("unsavedChangesDialog", "triggered", {
            trigger: "beforeunload",
            contentType: Ember.get(_this, "controller.model.type")
          });

          return true;
        }
      }));
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        Ember.$(window).off("beforeunload.unsaved-warning");
      }
    },
    ignoreUnsavedChanges: function ignoreUnsavedChanges(transition, currentPath) {
      var targetPathArray = transition.targetName.split(".");
      var transitionFrom = currentPath.split(".")[1];
      var transitionTo = targetPathArray[0];
      var isCreating = targetPathArray[targetPathArray.length - 1] === "create";

      if (transitionFrom === transitionTo && !isCreating) {
        return ignoreTransitionsBetween.includes(transitionTo);
      }

      return false;
    },


    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);
        var currentPath = this._router.currentPath;
        var dirtyAttributes = Ember.get(this, "controller.model._dirtyAttributes");
        if (Ember.isPresent(dirtyAttributes) && !Ember.get(this, "isUserAction") && !this.ignoreUnsavedChanges(transition, currentPath)) {
          Ember.set(this, "transition", transition);
          transition.abort();
          this.controller.set("showUnsavedDialog", true);

          Ember.get(this, "analytics").track("unsavedChangesDialog", "triggered", {
            trigger: "willTransition",
            contentType: Ember.get(this, "controller.model.type")
          });
        } else if (Ember.get(this, "isUserAction")) {
          Ember.set(this, "isUserAction", false);
        }
      },
      onsave: function () {
        var _ref = _asyncToGenerator(function* () {
          var transition = Ember.get(this, "transition");
          Ember.set(this, "isUserAction", true);
          this.controller.set("showUnsavedDialog", false);
          yield this.controller.send("save");
          transition.retry();

          Ember.get(this, "analytics").track("unsavedChangesDialog", "saved", {
            contentType: Ember.get(this, "controller.model.type")
          });
        });

        function onsave() {
          return _ref.apply(this, arguments);
        }

        return onsave;
      }(),
      oncancel: function oncancel() {
        var transition = Ember.get(this, "transition");
        Ember.set(this, "isUserAction", true);
        this.controller.set("showUnsavedDialog", false);
        transition.retry();

        Ember.get(this, "analytics").track("unsavedChangesDialog", "cancelled", {
          contentType: Ember.get(this, "controller.model.type")
        });
      }
    }
  });
});