define("copilot/components/contributor-fields/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "fieldset",
    classNames: ["contributor-fields"],
    isPhotoLike: Ember.computed.alias("model.constructor.metadata.isPhotoLike"),
    optionsKey: Ember.computed("model.contributorTypeOptionsKey", function () {
      var contributorTypeOptionsKey = this.get("model.contributorTypeOptionsKey");
      if (this.get("brandConfig." + contributorTypeOptionsKey)) {
        return contributorTypeOptionsKey;
      }
      return "contributorTypeOptions.default";
    })
  });
});