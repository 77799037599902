define("copilot/brands/cnt/models/rcalists-comment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Model.extend({
    comment: Ember.attr(),
    source: Ember.attr(),
    location: Ember.attr()
  });
});