define("copilot/components/category-dropdown/category-row/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["category-row"],

    didRender: function didRender() {
      this.$().find(".check-box").on("click", function (event) {
        return event.stopPropagation();
      });
    },


    selectable: Ember.computed("category", function () {
      return this.get("category.config.selectable");
    }),

    selected: Ember.computed("selections", function () {
      var rootSlug = this.get("category.rootSlug");
      return (this.get("selections." + rootSlug) || []).isAny("id", this.get("category.id"));
    })
  });
});