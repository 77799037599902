define("copilot/components/publish-dialog/component", ["exports", "lodash-es", "@condenast/copilot-slug", "moment"], function (exports, _lodashEs, _copilotSlug, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function handleErrorResponse(response) {
    var statusText = response.statusText || "Server Error";
    var responseJSON = response.responseJSON;
    var message = responseJSON && responseJSON.message || "An internal server error occurred";
    var errorType = responseJSON && responseJSON.errorType || "server-error";
    Ember.get(this, "publishModel.validationErrors").add("", errorType, {
      error: statusText,
      message: message,
      status: status
    });
  }

  function setQuickDate(pubDateOptions) {
    var currentDate = pubDateOptions.filterBy("status", "now")[0];
    var currentlyPublished = pubDateOptions.filterBy("status", "currentlyPublished")[0];
    var scheduledOptions = pubDateOptions.filterBy("status", "scheduled");
    if (scheduledOptions.length >= 1) {
      Ember.set(this, "quickDate", "");
    } else if (currentlyPublished) {
      Ember.set(this, "quickDate", currentlyPublished.label);
    } else {
      Ember.set(this, "quickDate", currentDate.label);
    }
  }

  var WEB_DISTRIBUTION_CHANNEL = "web-owned-and-operated";
  var APPLE_DISTRIBUTION_CHANNEL = "apple-news";
  var FACEBOOK_NEWS_DISTRIBUTION_CHANNEL = "facebook-news";
  var MOBILE_APP_DISTRIBUTION_CHANNEL = "mobile-app";

  /**
   * A `{{publish-dialog}}` component is used to publish a piece of content with
   * a set of fields. This should only be invoked by the `{{dialog-box}}` component.
   *
   * ```hbs
   * {{dialog-box (component 'publish-dialog'
   *   model=mainModel
   *   publish=(action 'publish'))
   * ondismiss=(action 'closeModal')}}
   * ```
   *
   * @module copilot/components/publish-dialog/component
   * @augments Ember.Component
   * @param {(Object|Ember.Model)} model - The model whose controller it lives on
   * @param {Function} publish - A closure action to send the content's publish
   * data to the publish endpoint
   *
   */
  exports.default = Ember.Component.extend({
    classNames: ["publish-dialog"],
    classNameBindings: ["isPublishing:spinner-medium:"],
    isPublishing: false,
    quickDate: null,
    displayBrand: Ember.computed.alias("publishModel.content.displayBrand"),
    analytics: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service("user"),
    isSuperAdmin: Ember.computed.alias("userService.isSuperAdmin"),
    publishUri: Ember.computed.alias("publishModel.uri"),
    hasBeenPublishedBefore: Ember.computed.alias("publishModel.isPublished"),
    softSlug: Ember.computed.alias("publishModel.softSlug"),
    searchable: Ember.computed.alias("publishModel.searchable"),
    recommendable: Ember.computed.alias("publishModel.recommendable"),
    isBrandedContent: Ember.computed.notEmpty("model.displayBrand"),
    configService: Ember.inject.service("config"),
    brandConfig: Ember.inject.service(),
    publishService: Ember.inject.service("publish"),
    experimentsService: Ember.inject.service("experiments"),
    features: Ember.inject.service(),

    publishAppleNewsConfig: Ember.computed.reads("contentTypeConfig.publish.distributionChannels." + APPLE_DISTRIBUTION_CHANNEL),
    publishFacebookNewsConfig: Ember.computed.reads("contentTypeConfig.publish.distributionChannels." + FACEBOOK_NEWS_DISTRIBUTION_CHANNEL),
    publishMobileAppConfig: Ember.computed.reads("contentTypeConfig.publish.distributionChannels." + MOBILE_APP_DISTRIBUTION_CHANNEL),
    publishWebConfig: Ember.computed.reads("contentTypeConfig.publish.distributionChannels." + WEB_DISTRIBUTION_CHANNEL),

    publishUrl: Ember.computed("publishEnv", "publishMountPoint", "publishUri", function () {
      return "https://" + this.publishEnv + "/" + this.publishMountPoint + this.publishUri;
    }),

    publishDataPublishUrl: Ember.computed("publishEnv", function () {
      return "https://" + this.publishEnv + "/" + this.model.metadata.publishData.uri;
    }),

    brandDomain: Ember.computed("publishEnv", function () {
      return "https://" + this.publishEnv + "/";
    }),

    publishEnv: Ember.computed("brandConfig.envUrl", "publishModel.displayBrand", function () {
      var displayBrand = Ember.get(this, "displayBrand");
      var configService = Ember.get(this, "configService");
      var envUrl = Ember.get(this, "brandConfig.envUrl");

      return displayBrand ? configService.getConsumerHostnameByBrandCode(displayBrand) : envUrl;
    }),

    experiment: Ember.computed("model.experiments.items.firstObject", function () {
      return this.experimentsService.getActiveExperiment(this.model);
    }),

    isDraft: Ember.computed("experiment.{state,overrideType}", function () {
      return this.get("experiment.state") === this.experimentsService.states.DRAFT && this.get("experiment.overrideType") !== this.experimentsService.overrideTypes.NONE;
    }),

    isRunning: Ember.computed("experiment.state", function () {
      return this.get("experiment.state") === this.experimentsService.states.RUNNING;
    }),

    readyToRun: Ember.computed.reads("experiment.readyToRun"),

    init: function init() {
      this._super.apply(this, arguments);
      setQuickDate.call(this, Ember.get(this, "pubDateOptions"));
      this.setPublishModel();
      this.analytics.storyEvent("Publish Started", {
        unpublishedRelationships: this.unpublishedRelationships.length
      });
      if (this.distributionChannels) {
        var distributionChannelAppleNews = this.distributionChannels.findBy("name", APPLE_DISTRIBUTION_CHANNEL);
        var distributionChannelFacebookNews = this.distributionChannels.findBy("name", FACEBOOK_NEWS_DISTRIBUTION_CHANNEL);
        var distributionChannelMobileApp = this.distributionChannels.findBy("name", MOBILE_APP_DISTRIBUTION_CHANNEL);

        this.set("publishToWeb", this.distributionChannels.some(function (channel) {
          return channel.name === WEB_DISTRIBUTION_CHANNEL;
        }));
        this.set("publishToAppleNews", distributionChannelAppleNews != null);
        this.set("publishToAppleNewsPremium", this.publishToAppleNews && distributionChannelAppleNews.metadata.isPaid);
        this.set("publishToFacebookNews", distributionChannelFacebookNews != null);
        this.set("publishToMobileApp", distributionChannelMobileApp != null);
        this.set("showMore", !this.isDefaultDistributionChannels);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (!this.isPublishing) {
        this.analytics.storyEvent("Publish Abandoned", {
          unpublishedRelationshipsLeft: this.unpublishedRelationships.length
        });
      }
    },
    setPublishModel: function setPublishModel() {
      var owner = Ember.getOwner(this);
      var PublishDataFactory = owner.factoryFor("model:publish-data");
      var publishDataProps = {
        content: Ember.get(this, "model"),
        publishConfig: this.contentTypeConfig.publish,
        templates: this.contentTypeConfig.templates || [],
        defaultTemplate: this.contentTypeConfig.defaultTemplate
      };
      if (this.get("features.publishData")) {
        var _model$metadata$publi = this.model.metadata.publishData,
            template = _model$metadata$publi.template,
            searchable = _model$metadata$publi.searchable,
            recommendable = _model$metadata$publi.recommendable,
            uri = _model$metadata$publi.uri;

        publishDataProps.uri = uri;
        publishDataProps.template = template;
        publishDataProps.searchable = searchable;
        publishDataProps.recommendable = recommendable; //set recommendable in the web dist channel if it exists
      }
      var publishData = PublishDataFactory.create(publishDataProps);
      Ember.set(this, "publishModel", publishData);
    },
    unpublishSameDateRevisions: function unpublishSameDateRevisions(data) {
      var _this = this;

      var history = data.publishHistory.data;
      var revisions = history.filter(function (record) {
        var scheduled = (0, _moment.default)(record.pubDate).isAfter((0, _moment.default)(new Date()));
        return scheduled && record.pubDate === data.localPublishDate;
      });

      return Ember.RSVP.Promise.all(revisions.map(function (record) {
        return _this.publishService.unpublish(data.model, record);
      }));
    },


    unpublishedRelationships: Ember.computed("model.inline.@each.publishable", "publishModel.publishDate", function () {
      var _this2 = this;

      var scheduledPublishDate = (0, _moment.default)(this.publishModel.publishDate);
      var allRelationships = [];

      var relationshipDefinitions = this.model.constructor.relationships;
      var relationshipKeys = Array.from(relationshipDefinitions ? relationshipDefinitions.keys() : []);
      relationshipKeys.forEach(function (key) {
        var relationship = relationshipDefinitions.get(key);
        // Skip backReferences, which contains a list of models that
        // reference this one
        if (key !== "backReferences") {
          if (relationship.kind === "hasMany") {
            allRelationships.push.apply(allRelationships, _toConsumableArray(_this2.model.get(key).toArray()));
          } else if (relationship.kind === "hasOne") {
            allRelationships.push(_this2.model.get(key));
          }
        }
      });

      return allRelationships.filterBy("publishable", true).filter(function (relationship) {
        if (relationship.currentlyPublishedRecord == null) {
          if (relationship.currentOrQueuedPublishRecord) {
            // Only include relationships that are scheduled to be published
            // _after_ the scheduled date
            var publishDate = (0, _moment.default)(relationship.currentOrQueuedPublishRecord.pubDate);
            if (publishDate.isAfter(scheduledPublishDate)) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });
    }),

    publishMountPoint: Ember.computed("publishModel.mountPoint", function () {
      var mountPoint = "";
      if (Ember.get(this, "publishModel.mountPoint")) {
        mountPoint = Ember.get(this, "publishModel.mountPoint") + "/";
      }
      return mountPoint;
    }),

    localPublishDate: Ember.computed("publishModel.publishDate", {
      get: function get() {
        return Ember.get(this, "publishModel.publishDate");
      },
      set: function set(key, date) {
        date = date && date.fromQuickDate ? date.value : date;
        date = (0, _moment.default)(date).toISOString();
        Ember.set(this, "publishModel.publishDate", date);
        Ember.set(this, "quickDate", null);
        this.analytics.storyEvent("Publish Date Changed", {
          pubDate: date
        });
        return date;
      }
    }),

    publishAt: Ember.computed("localPublishDate", function () {
      return new Date(Date.parse(this.localPublishDate));
    }),

    lastPublishedAt: Ember.computed("model.currentlyPublishedRecord.pubDate", function () {
      var publishedRecord = this.model.currentlyPublishedRecord;
      if (publishedRecord) {
        var lastPublishedAt = (0, _moment.default)(publishedRecord.pubDate).seconds(0);
        return lastPublishedAt.toDate();
      }
      return new Date();
    }),

    zoneAbbr: Ember.computed("publishModel.publishDate", function () {
      var zone = _moment.default.tz.guess();
      var date = (0, _moment.default)(Ember.get(this, "publishModel.publishDate")).format("x");
      return _moment.default.tz.zone(zone).abbr(date);
    }),

    localPublishDateObserver: Ember.observer("localPublishDate", function () {
      var _this3 = this;

      Ember.run.scheduleOnce("sync", this, function () {
        if (!Ember.get(_this3, "hasBeenPublishedBefore")) {
          Ember.get(_this3, "publishModel").setPath();
        }
      });
    }),

    templates: Ember.computed("publishModel.templates", function () {
      var templates = Ember.get(this, "publishModel.templates");
      return _lodashEs.default.sortBy(templates && templates.map(function (label) {
        return {
          value: (0, _copilotSlug.default)(label),
          label: label
        };
      }), "name");
    }),

    dateToSelectOption: function dateToSelectOption(date, status) {
      var intl = Ember.get(this, "intl");
      var roundedDate = (0, _moment.default)(date).seconds() > 0 ? (0, _moment.default)(date).seconds(0).add(1, "m") : date;
      var momentDate = (0, _moment.default)(roundedDate).format("MM/DD/YYYY @ h:mma");
      var timeZone = _moment.default.tz.zone(_moment.default.tz.guess()).abbr((0, _moment.default)(date).format("x"));
      var label = intl.t("helperText.contentPublisher." + status, {
        date: momentDate,
        timeZone: timeZone
      }).string;
      return {
        date: date,
        label: label,
        status: status,
        value: label
      };
    },


    pubDateOptions: Ember.computed("model.{publishHistory,currentlyPublishedRecord}", function () {
      var _this4 = this;

      var formattedOptions = [];

      // Current time is first option;
      var now = this.dateToSelectOption((0, _moment.default)(new Date()).toISOString(), "now");
      formattedOptions.push(now);

      var currentlyPublishedRecord = this.get("model.currentlyPublishedRecord");
      if (currentlyPublishedRecord) {
        formattedOptions.push(this.dateToSelectOption(currentlyPublishedRecord.pubDate, "currentlyPublished"));
      }

      // Currently scheduled revisions
      var scheduledOptions = (this.get("model.publishHistory.data") || []).filter(function (item) {
        return !item.expired && (0, _moment.default)(item.pubDate).isAfter((0, _moment.default)(new Date()));
      }).map(function (item) {
        return _this4.dateToSelectOption(item.pubDate, "scheduled");
      }).sortBy("date");

      return [].concat(formattedOptions, _toConsumableArray(scheduledOptions));
    }),

    getDistributionChannels: function getDistributionChannels() {
      var distributionChannels = [];
      if (this.publishToWeb) {
        distributionChannels.push({
          name: WEB_DISTRIBUTION_CHANNEL,
          metadata: {
            searchable: this.searchable,
            recommendable: this.recommendable
          }
        });
      }
      if (this.publishToAppleNews) {
        distributionChannels.push({
          name: APPLE_DISTRIBUTION_CHANNEL,
          metadata: {
            isPaid: this.publishToAppleNewsPremium
          }
        });
      }
      if (this.publishToFacebookNews) {
        distributionChannels.push({
          name: FACEBOOK_NEWS_DISTRIBUTION_CHANNEL,
          metadata: {}
        });
      }
      if (this.publishToMobileApp) {
        distributionChannels.push({
          name: MOBILE_APP_DISTRIBUTION_CHANNEL,
          metadata: {}
        });
      }
      return distributionChannels;
    },


    isDefaultDistributionChannels: Ember.computed("distributionChannels.[]", function () {
      var defaultDistributionChannels = this.contentTypeConfig.publish.distributionChannels.default;
      var distributionChannels = this.getDistributionChannels();

      if (distributionChannels.length !== defaultDistributionChannels.length) {
        return false;
      }
      return defaultDistributionChannels.every(function (_ref) {
        var name = _ref.name,
            metadata = _ref.metadata;

        var channel = distributionChannels.find(function (channel) {
          return channel.name === name;
        });
        return channel && Object.entries(metadata).every(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
              key = _ref3[0],
              value = _ref3[1];

          return channel.metadata && channel.metadata[key] === value;
        });
      });
    }),

    actions: {
      updateDate: function updateDate(value) {
        var selection = Ember.get(this, "pubDateOptions").filterBy("label", value)[0];
        if (selection) {
          Ember.set(this, "localPublishDate", {
            value: selection.date,
            fromQuickDate: true
          });
          this.analytics.storyEvent("Publish Date Changed", {
            pubDate: selection.date
          });
        } else {
          Ember.set(this, "localPublishDate", { value: "", fromQuickDate: true });
        }
      },
      correctSlug: function correctSlug() {
        Ember.set(this, "publishModel.slug", Ember.get(this, "publishModel").getSlug());
      },
      submit: function submit() {
        var _this5 = this;

        var publishModel = this.publishModel;
        publishModel.set("distributionChannels", this.getDistributionChannels());
        if (!this.features.get("publishData") && !this.isSuperAdmin) {
          var slug = publishModel.getSlug();
          publishModel.set("slug", slug);
        }
        var user = this.get("userService.user");
        var pubData = {
          uri: Ember.get(publishModel, "uri"),
          pubDate: Ember.get(this, "localPublishDate"),
          revisionAuthor: user.get("firstName") + " " + user.get("lastName"),
          searchable: publishModel.get("searchable"),
          recommendable: publishModel.get("recommendable"),
          distributionChannels: publishModel.get("distributionChannels")
        };

        if (publishModel.get("template")) {
          pubData.template = (0, _copilotSlug.default)(publishModel.get("template"));
        }

        publishModel.validate().then(function (isValid) {
          if (isValid) {
            Ember.set(_this5, "isPublishing", true);
            var localPublishDate = Ember.get(_this5, "localPublishDate");
            var isScheduled = (0, _moment.default)(localPublishDate).isAfter((0, _moment.default)(new Date()));
            var model = Ember.get(_this5, "model");
            var publishHistory = Ember.get(model, "publishHistory");

            return Ember.RSVP.Promise.resolve().then(function () {
              if (isScheduled && publishHistory) {
                return _this5.unpublishSameDateRevisions({
                  model: model,
                  localPublishDate: localPublishDate,
                  publishHistory: publishHistory
                });
              }
            }).then(function () {
              _this5.analytics.storyEvent("Publish Finished", {
                unpublishedRelationshipsLeft: _this5.unpublishedRelationships.length
              });
              return _this5.publish(pubData);
            }).then(function (error) {
              if (error) {
                handleErrorResponse.call(_this5, error);
              } else {
                Ember.get(_this5, "dismiss")();
              }
            }).catch(function (response) {
              handleErrorResponse.call(_this5, response);
            }).finally(function () {
              Ember.set(_this5, "isPublishing", false);
            });
          } else {
            _this5.set("showMore", true);
          }
        });
      },
      updatePublishToWeb: function updatePublishToWeb(value) {
        this.set("publishToWeb", value);
        if (!this.get("publishAppleNewsConfig.webAndPremiumAllowed") && this.publishToAppleNewsPremium && value) {
          this.set("publishToAppleNewsPremium", false);
        }
      },
      updatePublishToAppleNewsPremium: function updatePublishToAppleNewsPremium(appleNewsType) {
        if (appleNewsType === "free") {
          this.set("publishToAppleNewsPremium", false);
        } else if (appleNewsType === "premium") {
          this.set("publishToAppleNewsPremium", true);
          if (!this.get("publishAppleNewsConfig.webAndPremiumAllowed") && this.publishToWeb) {
            this.set("publishToWeb", false);
          }
        }
      }
    }
  });
});