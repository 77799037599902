define("copilot/components/preset-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this.get("fetchPresets")();
    },


    actions: {
      handlePaginationClick: function handlePaginationClick(value) {
        this.handlePaginationClick(value);
      },
      handleTableHeaderClick: function handleTableHeaderClick(value) {
        this.handleTableHeaderClick(value);
      }
    }
  });
});