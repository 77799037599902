define("copilot/components/pagination-mini/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["pagination-mini", "pagination"],

    hasPages: Ember.computed("pages", function () {
      return this.pages > 0;
    }),

    nextDisabled: Ember.computed("page", "pages", function () {
      return this.page >= this.pages;
    }),

    previousDisabled: Ember.computed("page", "pages", function () {
      return this.page === 1;
    })
  });
});