define("copilot/components/content-selector/component", ["exports", "resize-observer-polyfill", "ember-concurrency", "lodash-es", "ember-retry/retry", "copilot/lib/copilot-computeds"], function (exports, _resizeObserverPolyfill, _emberConcurrency, _lodashEs, _retry, _copilotComputeds) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var equalProperties = _copilotComputeds.default.equalProperties;
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      reads = _Ember$computed.reads,
      equal = _Ember$computed.equal,
      notEmpty = _Ember$computed.notEmpty;


  var SEARCH_TYPE = "Asset Finder";

  var EXTERNAL_TYPES = ["getty", "giphy", "commerceproduct", "commerceproductcollection"];

  var _isExternal = function _isExternal(type) {
    return EXTERNAL_TYPES.indexOf(type) > -1;
  };

  var TYPE_MAP = {
    externallink: "External Link",
    cnevideo: "CNÉ Video",
    skiresort: "Ski Resort",
    getty: "Getty Images",
    commerceproduct: "Commerce Product",
    commerceproductcollection: "Product Collection"
  };

  var assetType = Ember.Object.extend({
    type: null,
    parent: null,
    title: Ember.computed("type", function () {
      var type = this.type;
      return TYPE_MAP[type] || _lodashEs.default.capitalize(type);
    }),
    isActive: equalProperties("type", "parent.type")
  });

  function requestIdleCallback(callback) {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(callback);
    } else {
      setTimeout(callback, 0);
    }
  }

  /**
   * `{{content-selector}}` is used for searching and selecting content from
   * different content types or external sources. This should only be invoked
   * by the `{{take-over}}` component.
   *
   * ```hbs
   * {{take-over (component 'content-selector'
   *   afmConfig="components.thumbnailUpload"
   *   submit=(action 'assignItem'))
   *   ondismiss=(action (mut isShowingModal) false)}}
   * ```
   *
   * @module copilot/components/content-selector/component
   * @augments Ember.Component
   * @param {String} afmConfigType - The path under which the correct configuration for the content selector resides in the brand config
   * @param {function} submit - An action to add the selected item(s) to the underlying field
   * @param {string[]} excludeIds - An optional array of id's of already added content from search
   */

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    brandConfig: Ember.inject.service(),
    intl: Ember.inject.service(),
    analytics: Ember.inject.service(),
    configService: Ember.inject.service("config"),
    features: Ember.inject.service(),
    importExternalAsset: Ember.inject.service(),
    contentSelectorPublish: Ember.inject.service("content-selector-product-publish"),
    importContentReference: Ember.inject.service(),
    /**
     * @private
     * @property {Object} keyValueStorage
     * used to store and retrieve a saved query and a saved type
     * for each URL that the content-selector is used on
     */
    keyValueStorage: Ember.inject.service(),

    /**
     * @private
     * @property {null|string} currentObjectKey
     * the string that the content-selector uses as the key to store the query and type
     */
    currentObjectKey: null,
    classNames: ["asset-simple", "asset", "new-modal"],
    config: null,
    searchError: reads("adapter.searchError"),
    type: alias("adapter.type"),
    limit: reads("config.limitSelection"),
    contentTypeConfigs: reads("configService.config.contentTypes"),
    query: reads("adapter.query"),
    page: reads("adapter.page"),
    pages: reads("adapter.pages"),
    totalResults: reads("adapter.totalHits"),
    searchOptions: reads("adapter.searchOptions"),
    placeholder: reads("adapter.placeholder"),
    results: reads("adapter.results"),
    hasSimilarProduct: reads("adapter.hasSimilarProduct"),
    resultsQuery: reads("adapter.resultsQuery"),
    task: reads("adapter.fetch"),
    hasExternalTypes: notEmpty("externalTypes"),
    hasAssetTypes: notEmpty("assetTypes"),
    searchParams: reads("adapter.searchParams"),
    resultsPerPage: reads("adapter.resultsPerPage"),
    isCnevideo: equal("type", "cnevideo"),
    isGiphy: equal("type", "giphy"),
    isGetty: equal("type", "getty"),
    searchService: Ember.inject.service("search"),
    isPublishing: false,
    /**
      Stub out `{{take-over}}`'s dismiss API.
      @private
     */
    dismiss: function dismiss() {},
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var afmConfig = this.afmConfig;
      var configSource = this.contentTypeConfig || this.get("brandConfig.afm");
      this.config = Ember.get(configSource, afmConfig);

      // Set the current URL as the key during initialization
      this.set("currentObjectKey", window.location.pathname);

      var type = this.config.defaultType;

      var retrievedType = this.keyValueStorage.get(this.currentObjectKey + "-type");
      var types = this.externalTypes.concat(this.assetTypes);
      var retrievedTypeIsAllowed = !!types.find(function (assetType) {
        return assetType.type === retrievedType;
      });

      if (retrievedTypeIsAllowed) {
        type = retrievedType;
      }

      var adapterType = "copilot";
      if (_isExternal(type)) {
        adapterType = type;
      }

      var owner = Ember.getOwner(this);
      var factory = owner.factoryFor("model:search/adapters/" + adapterType);
      var adapter = factory.create();
      adapter.set("excludeIds", this.excludeIds);
      adapter.set("limit", 100);

      this._populateQuery(adapter);

      this.adapter = adapter;
      if (this.selectedAssets == null) {
        this.selectedAssets = [];
      }
      this.q = null;
      this.set("type", type || null);
      this._fetchResults.perform();
      this.set("startDate", new Date());
      this.set("assetItemsDisplayStyle", type === "getty" ? "grid" : "list");

      this._setupIntersectionObserver();

      this.analytics.storyEvent("Search Opened", { searchType: SEARCH_TYPE });

      this._resizeObserver = new _resizeObserverPolyfill.default(function () {
        requestIdleCallback(function () {
          Ember.run(function () {
            if (_this.isDestroyed) {
              return;
            }
            var result = _this.element.querySelector(".result");
            if (result) {
              var size = result.getBoundingClientRect().width;
              _this.set("itemSize", size - 2);
            }
          });
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.analytics.storyEvent("Search Closed");
      this._super.apply(this, arguments);
      this._resizeObserver.disconnect();
    },
    willRender: function willRender() {
      this._resizeObserver.disconnect();
    },
    didRender: function didRender() {
      var _this2 = this;

      // defers logic until the elements have registered so that the resize happens effectively
      setTimeout(function () {
        Ember.run(function () {
          if (_this2.isDestroyed) {
            return;
          }
          _this2._resizeObserver.observe(_this2.element);
        });
      }, 0);
    },
    _setupIntersectionObserver: function _setupIntersectionObserver() {
      var _this3 = this;

      this._resultsWeakMap = new WeakMap();
      var root = Ember.testing ? document.querySelector("#ember-testing-container") : null;

      // IntersectionObserver is not supported by the browser
      if (!window.IntersectionObserver) {
        return;
      }

      this._observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          // only fire when the element enters the viewport, not when it exits
          if (entry.isIntersecting) {
            var _resultsWeakMap$get = _this3._resultsWeakMap.get(entry.target),
                result = _resultsWeakMap$get.result,
                index = _resultsWeakMap$get.index;

            requestIdleCallback(function () {
              Ember.run(function () {
                if (!_this3.isDestroyed) {
                  _this3.analytics.storyEvent("Search Result In View", {
                    result: result,
                    resultIndex: index
                  });
                }
              });
            });
          }
        });
      }, { threshold: 0.5, root: root });
    },
    _getAssetTypes: function _getAssetTypes(configPath) {
      var _this4 = this;

      var types = this.get(configPath) || [];
      return types.map(function (type) {
        return assetType.create({
          type: type,
          parent: _this4
        });
      });
    },


    contentTypeValues: Ember.computed("contentTypeConfigs", function () {
      if (!Array.isArray(this.contentTypeConfigs)) {
        return [];
      }

      return this.contentTypeConfigs.map(function (contentTypeConfig) {
        return contentTypeConfig.value;
      });
    }),

    queryOptions: Ember.computed("query", "type", function () {
      var query = this.query;
      var type = this.type;

      return { query: query, type: type };
    }),

    searchSimilarProduct: reads("adapter.searchSimilarProducts"),

    enablePublishAsset: Ember.computed("hasSimilarProduct", "results", function () {
      return this.hasSimilarProduct ? this.results && this.results.length > 0 : 0;
    }),

    isExternal: Ember.computed("type", function () {
      return EXTERNAL_TYPES.includes(this.type);
    }),
    // Function to retry
    searchProductByUrl: function searchProductByUrl(url) {
      url = this.transformQueryString(url);
      var that = this;
      return function () {
        return new Ember.RSVP.Promise(function (searchResolve, searchReject) {
          that.searchService.execute({
            limit: 100,
            q: "" + url,
            types: "product",
            status: "published",
            display: "all",
            archived: false
          }).then(function (searchData) {
            if (searchData.hits.length > 0) {
              searchResolve();
              return;
            }
            searchReject(new Error("Error on searching item"));
          }).catch(searchReject);
        });
      };
    },


    doneWithPublish: Ember.observer("publishingTasks.@each.publishingCompleted", function () {
      var that = this;
      var publishingTasks = this.publishingTasks || [];
      var completedTasks = publishingTasks.filter(function (task) {
        return task.publishingCompleted;
      });
      if (completedTasks.length === publishingTasks.length) {
        (0, _retry.default)(that.searchProductByUrl(that.query), 10, 2000).then(function () {
          that.set("selectedAssets", []);
          that.set("isPublishing", false);
          that.searchWithQuery(that.query);
        }).catch(function (err) {
          return err;
        });
      }
    }),

    transformQueryString: function transformQueryString(queryString) {
      try {
        var amazonDomains = ["www.amazon.com", "www.amzn.to", "www.amazon.co.uk"];
        var url = new URL(queryString);
        var urlString = url.toString().split("?")[0];
        if (amazonDomains.includes(url.hostname)) {
          var amazonId = urlString.split("/").filter(function (purl) {
            return new RegExp("^[A-Za-z0-9]{10}$").exec(purl);
          })[0];
          return "offers.purchaseUri:\"" + amazonId + "\"";
        } else {
          return "offers.purchaseUri:\"" + urlString + "\"";
        }
      } catch (error) {
        return queryString.query;
      }
    },


    isSaving: Ember.computed("isPublishing", function () {
      return this.isPublishing;
    }),

    excludeQuickSearchTypesConfig: Ember.computed(function () {
      return {
        types: this.assetTypes.mapBy("type").join(","),
        nottypes: undefined
      };
    }),

    assetTypes: Ember.computed("config.assetTypeList", "assetTypeList", function () {
      var _this5 = this;

      var types = this._getAssetTypes("config.assetTypeList");
      if (this.assetTypeList && this.assetTypeList.length) {
        types = types.filter(function (assetType) {
          return _this5.assetTypeList.includes(assetType.type);
        });
      }
      return types.filter(function (assetType) {
        return _this5.contentTypeValues.includes(assetType.type);
      });
    }),

    externalTypes: Ember.computed("config.externalTypeList", "assetTypeList", function () {
      var _this6 = this;

      var types = this._getAssetTypes("config.externalTypeList");
      var enableCommerceProduct = this.get("features.enableCommerceProduct");

      if (!enableCommerceProduct) {
        types = types.rejectBy("type", "commerceproduct");
      }

      if (this.assetTypeList && this.assetTypeList.length) {
        types = types.filter(function (assetType) {
          return _this6.assetTypeList.includes(assetType.type);
        });
      }
      return types;
    }),

    emptyExternalQuery: Ember.computed("type", "query", function () {
      var type = this.type;
      var query = this.query;
      if (_isExternal(type) && Ember.isBlank(query) && type !== "commerceproduct" && type !== "commerceproductcollection") {
        return type;
      }
    }),

    searchPrompt: Ember.computed("placeholder", function () {
      return this.intl.t("placeholder.contentSelector." + Ember.String.camelize(this.placeholder || "search"));
    }),

    /**
     * Retrieve any saved query from storage service and set the query on the adapter.
     * @param adapter
     * @private
     */
    _populateQuery: function _populateQuery(adapter) {
      var retrievedQuery = this.keyValueStorage.get(this.currentObjectKey + "-query") || "";
      adapter.set("query", retrievedQuery);
    },


    _fetchResults: (0, _emberConcurrency.task)(function* () {
      Ember.tryInvoke(this, "scrollToTop");
      yield this.get("adapter.fetch").perform();

      var adapter = this.adapter;
      var hitsTotal = adapter.resultsPerPage * adapter.pages;
      this.analytics.storyEvent("Search Executed", {
        searchQuery: this.queryOptions,
        searchResultsCount: hitsTotal,
        searchPageSize: adapter.resultsPerPage
      });
    }),
    _save: (0, _emberConcurrency.task)(function* (model) {
      return yield model.save();
    }).enqueue().maxConcurrency(10),
    _applyQuery: function _applyQuery(options) {
      var query = options.query;
      var trimmedQuery = query.trim();

      this.keyValueStorage.set(this.currentObjectKey + "-query", trimmedQuery);

      this.adapter.setQuery(trimmedQuery);
      this._fetchResults.perform();
    },
    search: function search(event) {
      event.preventDefault();
      event.stopPropagation();
      this._applyQuery({ query: this.query });
    },
    searchWithQuery: function searchWithQuery(query) {
      var options = { query: query };
      this._applyQuery(options);
    },


    actions: {
      observe: function observe(result, index, element) {
        if (!this._resultsWeakMap.get(element) && this._observer) {
          this._resultsWeakMap.set(element, { result: result, index: index });
          this._observer.observe(element);
        }
      },
      unobserve: function unobserve(element) {
        if (this._observer) {
          this._resultsWeakMap.delete(element);
          this._observer.unobserve(element);
        }
      },
      trackFocus: function trackFocus() {
        this.analytics.storyEvent("Search Form Focused");
      },
      setType: function setType(type) {
        if (!this._recordingStarted && window.hasOwnProperty("hj")) {
          hj("trigger", "afm_" + type + "_selection");
          this._recordingStarted = true;
        }
        var adapterType = "copilot";
        if (_isExternal(type)) {
          adapterType = type;
        }
        var owner = Ember.getOwner(this);
        var adapterModel = owner.factoryFor("model:search/adapters/" + adapterType);
        var adapter = adapterModel.create();
        adapter.set("excludeIds", this.excludeIds);
        adapter.set("limit", 100);

        this._populateQuery(adapter);

        this.set("adapter", adapter);
        this.set("type", type);
        this.set("assetItemsDisplayStyle", type === "getty" ? "grid" : "list");
        this.keyValueStorage.set(this.currentObjectKey + "-type", type);
        this.adapter.setPage(1);
        this._fetchResults.perform();
      },
      selectAsset: function selectAsset(asset) {
        var selectedAssets = this.selectedAssets;
        var selectedAsset = asset.id ? selectedAssets.findBy("id", asset.id) : selectedAssets.findBy("identifier", asset.identifier);
        if (this.limit) {
          if (selectedAsset) {
            selectedAssets.removeObject(selectedAsset);
            return;
          }
          if (this.limit === 1) {
            this.analytics.storyEvent("Search Result Selected", {
              result: asset,
              resultIndex: this.results.indexOf(asset)
            });
            selectedAssets.pushObject(asset);
            this.send("submit");
            return;
          }
          if (this.excludeIds) {
            if (this.excludeIds.length + selectedAssets.length < this.limit) {
              selectedAssets.pushObject(asset);
            }
          }
          if (!this.excludeIds) {
            if (selectedAssets.length < this.limit) {
              selectedAssets.pushObject(asset);
            }
          }
        } else {
          if (!selectedAsset) {
            selectedAssets.pushObject(asset);
          } else {
            selectedAssets.removeObject(selectedAsset);
          }
        }
      },
      nextPage: function nextPage() {
        this.adapter.nextPage();
        Ember.tryInvoke(this, "scrollToTop");
      },
      previousPage: function previousPage() {
        this.adapter.previousPage();
        Ember.tryInvoke(this, "scrollToTop");
      },
      noop: function noop() {},
      submit: function () {
        var _ref = _asyncToGenerator(function* () {
          var _this7 = this;

          var selectedAssets = this.selectedAssets;
          var promises = selectedAssets.map(function () {
            var _ref2 = _asyncToGenerator(function* (asset, index) {
              if (asset.type === "getty") {
                var numberInArray = index + 1 + " of " + selectedAssets.length;
                _this7.analytics.track("Getty", "submit", {
                  numberSelected: selectedAssets.length,
                  gettyId: asset.id,
                  imageType: asset.imageType,
                  assetNumber: numberInArray
                });
              }
              if (asset.type === "getty") {
                return _this7.importExternalAsset.importAsset(asset);
              }
              if ((asset.provider === "commerce-tools" || asset.provider === "commerce-tools-collection") && asset.meta.collectionName === "contentreferences") {
                asset.set("hed", "");

                var _ref3 = yield _this7.importContentReference.getAsset(asset.identifier),
                    hits = _ref3.hits.hits;

                if (hits.length && hits[0]._source) {
                  asset.set("id", hits[0]._source.id);
                  return asset;
                }
                var contentRef = yield _this7.importContentReference.saveAsset(asset);
                _this7.importContentReference.publishAsset(contentRef);
                return contentRef;
              }

              return asset;
            });

            return function (_x, _x2) {
              return _ref2.apply(this, arguments);
            };
          }());
          selectedAssets = yield Promise.all(promises);
          this.submit(selectedAssets);
        });

        function submit() {
          return _ref.apply(this, arguments);
        }

        return submit;
      }(),
      setSearchOption: function setSearchOption(name, value) {
        var searchOption = this.searchOptions.findBy("name", name);
        if (searchOption) {
          searchOption.value = value; // set the value on the correct searchOption in the array on the adapter
        }
        this.searchWithQuery(this.query);
      },
      publishAsset: function () {
        var _ref4 = _asyncToGenerator(function* () {
          this.set("isPublishing", true);
          var selectedAssets = this.selectedAssets;
          var publishingTasks = yield this.contentSelectorPublish.publish(selectedAssets, this.type);
          this.set("publishingTasks", publishingTasks);
        });

        function publishAsset() {
          return _ref4.apply(this, arguments);
        }

        return publishAsset;
      }()
    }
  });
});