define("copilot/brands/vogue/routes/fashionshows/create", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    controllerName: "fashionshows.index",
    viewName: "fashionshows.index",
    templateName: "fashionshows.index.edit",

    queryParams: {
      season_id: {
        refreshModel: false
      }
    },

    model: function model(params) {
      var fashionshow = this.store.createRecord("fashionshow");
      var eventInstance = this.store.createRecord("fashionshowevent");
      if (params.season_id) {
        return this.store.find("season", params.season_id).then(function (season) {
          fashionshow.get("season").pushObject(season);
          return {
            fashionshow: fashionshow,
            event: eventInstance
          };
        });
      }
      return {
        fashionshow: fashionshow,
        event: eventInstance
      };
    }
  });
});