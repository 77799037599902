define("copilot/components/gallery-create-media/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isAppendingSlides: true,

    warnings: Ember.computed(function () {
      return [];
    }),

    addSlidesTo: Ember.computed("isAppendingSlides", function () {
      return this.isAppendingSlides ? "end" : "beginning";
    }),

    store: Ember.inject.service(),

    analytics: Ember.inject.service(),
    // https://github.com/tim-evans/ember-file-upload#access-to-the-global-list-of-uploading-files
    fileQueue: Ember.inject.service(),
    photoService: Ember.inject.service("photo"),

    actions: {
      onfileadd: function onfileadd(model, addSlidesTo, file) {
        var _this = this;

        this.warnings.clear();
        var num = this.fileQueue.get("files.length");
        return this.onfileadd(model, addSlidesTo, file).then(function () {
          // Is the upload has fully completed (handle multi-upload)
          // https://github.com/tim-evans/ember-file-upload#access-to-the-global-list-of-uploading-files
          if (_this.fileQueue.get("progress") === 0) {
            // To see how many images are uploaded users' machine at the point of creating a gallery
            _this.analytics.track("galleries", "added", {
              source: "file",
              num: num
            }, {
              label: "source",
              value: "num"
            });
          }
        }).catch(function (error) {
          _this.warnings.addObject({
            message: error[1]
          });
        });
      },
      onassetsadd: function onassetsadd(model, addSlidesTo, searchResults) {
        var _this2 = this;

        this.warnings.clear();
        return Ember.RSVP.all(searchResults.map(function (asset) {
          return _this2.onassetadd(model, addSlidesTo, asset).catch(function (error) {
            _this2.warnings.addObject(error);
          });
        })).then(function () {
          // To see how many images are uploaded from existing assets at the point of creating a gallery
          _this2.analytics.track("galleries", "added", {
            source: "asset",
            num: searchResults.length
          }, {
            label: "source",
            value: "num"
          });
        });
      }
    }
  });
});