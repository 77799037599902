define("copilot/brands/vogue/models/slim-season", ["exports", "copilot/brands/vogue/models/slim-model"], function (exports, _slimModel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _slimModel.default.extend(Ember.Evented, {
    content: null,
    baseURI: "/api/seasons/",
    init: function init() {
      this._super.apply(this, arguments);
      this.requiredAttrs = ["name", "year", "type", "code"];
    }
  });
});