define("copilot/validations/brands/ad/bundle", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-core", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotCore, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var object = _crossCheckDsl.validators.object;
  exports.default = (0, _crossCheckDsl.extend)(_copilotCore.BundleValidationsDescriptor).andAlso(object({
    categoriesChannels: (0, _copilotCore.ifFeature)("enableCategoryChannels", (0, _copilotValidators.length)({ min: 1 })).on("publish"),
    isNative: (0, _copilotValidators.isFalse)()
  }).or(object({
    isNative: (0, _copilotValidators.isTrue)()
  })).catch(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        _ref2$0$message$detai = _ref2[0].message.details,
        details = _ref2$0$message$detai === undefined ? [] : _ref2$0$message$detai;

    return (0, _crossCheckDsl.mutePath)(["isNative"])(details.flatten());
  }));
});