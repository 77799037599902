define("copilot/helpers/syndication-source", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    configService: Ember.inject.service("config"),
    brand: Ember.inject.service(),
    syndicationSource: Ember.computed.reads("model.content.syndication.source"),

    activeBrand: Ember.computed.reads("brand.activeBrand"),

    syndicationSourceAbv: Ember.computed("syndicationSource", function () {
      var syndicationSource = this.syndicationSource;
      return this.configService.getBrandCode(syndicationSource) || "";
    }),

    showSyndicationSource: Ember.computed("syndicationSourceAbv", "activeBrand", function () {
      var syndicationSourceAbv = this.syndicationSourceAbv;
      var activeBrand = this.activeBrand;
      return Ember.isPresent(syndicationSourceAbv) && Ember.isPresent(syndicationSourceAbv) && syndicationSourceAbv !== activeBrand;
    }),

    displaySyndicationSource: Ember.computed("model", "showSyndicationSource", function () {
      var brand = this.configService.getBrandName(this.syndicationSource);
      return this.showSyndicationSource ? " - " + brand : "";
    }),

    compute: function compute() {
      var syndicationSource = this.displaySyndicationSource;
      return syndicationSource;
    }
  });
});