define("copilot/brands/vogue/models/fashiongallery", ["exports", "copilot/models/base", "copilot/brands/vogue/mixins/vogue-slug", "lodash-es"], function (exports, _base, _vogueSlug, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var galleryTypeMap = {
    frontRow: "front-row",
    detail: "details"
  };

  var FashionGallery = _base.default.extend(_vogueSlug.default, {
    type: "fashiongallery",
    title: Ember.attr(),
    galleryType: Ember.attr(),
    items: Ember.hasMany("fashion-gallery-item", {
      embedded: true
    }),

    photosTout: Ember.hasMany("media", {
      embedded: true,
      polymorphic: true
    }),

    fashionshow: null,

    slideshowString: "slideshow",

    hasRunwayMoments: Ember.computed(function () {
      return _lodashEs.default.includes(["collection", "runway"], this.galleryType);
    }),

    // A mapping is required because for some gallery types we want to provide uri
    // segments that differ from the slugified value of the galleryType property
    // (frontRow and detail)
    mappedGalleryType: Ember.computed("galleryType", function () {
      var galleryType = this.galleryType;
      return galleryTypeMap[galleryType] || galleryType;
    }),

    searchTitle: Ember.computed("title", function () {
      var title = this.title;
      if (title) {
        var titleParts = title.split(" - ");
        var galleryType = titleParts[titleParts.length - 1];
        titleParts[titleParts.length - 1] = galleryType.capitalize();
        return titleParts.join(" - ");
      }
    })
  });

  FashionGallery.reopenClass({
    url: "/api/fashiongalleries"
  });

  exports.default = FashionGallery;
});