define("copilot/validations/brands/vogue/season", ["exports", "@condenast/cross-check-dsl", "@condenast/copilot-validators"], function (exports, _crossCheckDsl, _copilotValidators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var object = _crossCheckDsl.validators.object,
      isBoolean = _crossCheckDsl.validators.isBoolean;


  var seasonValidators = object({
    name: (0, _copilotValidators.required)(),
    year: (0, _copilotValidators.year)(),
    annualSeason: (0, _copilotValidators.required)(),
    seasonType: (0, _copilotValidators.required)(),
    active: isBoolean().on("publish"),
    featured: isBoolean().on("publish"),
    showCalendar: isBoolean().on("publish"),
    code: (0, _copilotValidators.required)().catch(function () {
      return [{ path: [], message: { name: "blank" } }];
    })
  });

  exports.default = (0, _crossCheckDsl.default)(seasonValidators);
});