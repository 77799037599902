define("copilot/routes/nativegalleries/index", ["exports", "copilot/routes/galleries/index"], function (exports, _index) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend({
    controllerName: "galleries/index",

    model: function model(params) {
      return this.store.find("nativegallery", params.gallery_id);
    }
  });
});