define("copilot/brands/vogue/serializers/brand", ["exports", "copilot/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      ["designers", "previousDesigners"].forEach(function (prop) {
        json[prop] = json[prop].map(function (item) {
          return Object.assign({}, item, { relId: item.embedded.id });
        });
      });
      return json;
    }
  });
});