define("copilot/lib/brands-map", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BRANDS_MAP = {
    ad: "Architectural Digest",
    adde: "Architectural Digest Germany",
    ades: "Architectural Digest España",
    adfr: "Architectural Digest France",
    adin: "Architectural Digest India",
    adit: "Architectural Digest Italia",
    adme: "Architectural Digest Middle East",
    admx: "Architectural Digest México y Latinoamérica",
    adru: "Architectural Digest Russia",
    all: "Allure",
    bon: "Bon Appétit",
    cnt: "Condé Nast Traveler",
    cntde: "Condé Nast Traveler Germany",
    cntes: "Condé Nast Traveler España",
    cntin: "Condé Nast Traveler India",
    cntme: "Condé Nast Traveller ME",
    cntuk: "Condé Nast Traveler UK",
    demo: "Demo",
    engineering: "Condé Nast Technology",
    epi: "Epicurious",
    exp: "Experience Platform",
    glm: "Glamour",
    glmde: "Glamour Germany",
    glmes: "Glamour España",
    glmmx: "Glamour México y Latinoamérica",
    glmru: "Glamour Russia",
    glmuk: "Glamour UK",
    gq: "GQ",
    gqde: "GQ Germany",
    gqes: "GQ España",
    gqfr: "GQ France",
    gqin: "GQ India",
    gqit: "GQ Italia",
    gqjp: "GQ Japan",
    gqmx: "GQ México y Latinoamérica",
    gqru: "GQ Russia",
    gqtw: "GQ Taiwan",
    gquk: "GQ UK",
    hg: "House and Garden",
    lci: "La Cucina Italiana",
    lciit: "La Cucina Italiana Italy",
    p4k: "Pitchfork",
    self: "SELF",
    tatler: "Tatler",
    tatlerru: "Tatler Russia",
    them: "Them",
    tnv: "Teen Vogue",
    tny: "The New Yorker",
    vde: "Vogue Germany",
    ves: "Vogue España",
    vf: "Vanity Fair",
    vfes: "Vanity Fair España",
    vffr: "Vanity Fair France",
    vfit: "Vanity Fair Italia",
    vin: "Vogue India",
    vit: "Vogue Italia",
    vjp: "Vogue Japan",
    vmx: "Vogue México y Latinoamérica",
    vnt: "Vogue Global Network",
    vogue: "Vogue",
    voguebz: "Vogue Business",
    vpa: "Vogue France",
    vru: "Vogue Russia",
    vtw: "Vogue Taiwan",
    vuk: "Vogue UK",
    wiredes: "Wired en Español",
    woi: "World Of Interiors",
    wrd: "Wired",
    wrdit: "Wired Italia",
    wrdjp: "Wired Japan",
    wrduk: "Wired UK"
  };

  exports.default = BRANDS_MAP;
});