define("copilot/serializers/photo", ["exports", "lodash-es", "@condenast/vulcan-cropmodes", "copilot/serializers/application"], function (exports, _lodashEs, _vulcanCropmodes, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // gets a default aspect ratio object for the desired aspect ratio
  function getDefaultAspectRatioObject(master, aspectRatioKey) {
    var mode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "TOP";

    var aspectRatio = {};
    var defaultDimensions = _vulcanCropmodes.default.calculate(_vulcanCropmodes.default.modes[mode], master, aspectRatioKey);
    aspectRatio.width = defaultDimensions.width;
    aspectRatio.height = defaultDimensions.height;
    aspectRatio.override = false;
    aspectRatio.modifications = {};
    aspectRatio.modifications.crop = defaultDimensions;
    return aspectRatio;
  }

  function transformIntoAspectRatiosObject(obj) {
    if (obj.aspectRatios) {
      obj.aspectRatios = obj.aspectRatios.reduce(function (result, aspectRatio) {
        var key = aspectRatio.key;
        var omit = ["key"];
        if (aspectRatio.key === "master") {
          omit.push("modifications");
          omit.push("override");
        } else if (!aspectRatio.override) {
          omit.push("format");
        }
        if (aspectRatio.url === "") {
          omit.push("url");
        }
        omit.forEach(function (key) {
          return delete aspectRatio[key];
        });
        result[key] = aspectRatio;

        return result;
      }, {});
    }
    return obj;
  }

  exports.default = _application.default.extend({
    configService: Ember.inject.service("config"),
    normalize: function normalize() {
      var superHash = this._super.apply(this, arguments);
      var normalizedHash = this.transformAspectRatios(superHash, "array");
      return normalizedHash;
    },
    serialize: function serialize(record) {
      var _dirtyAttributes = record.get("_dirtyAttributes");
      if (record.categories) {
        _dirtyAttributes.pushObject("categories");
      }
      var json = this._super.apply(this, arguments);

      // Hack to fix bug where photo models that were moments inside of fasiongalleries weren't properly normalizing
      if (!_dirtyAttributes.includes("aspectRatios")) {
        json = _lodashEs.default.omit(json, "aspectRatios");
      }
      json = this.transformAspectRatios(json, "object");

      return json;
    },
    transformAspectRatios: function transformAspectRatios(obj, format, type) {
      var result = void 0;
      switch (format) {
        case "object":
          result = transformIntoAspectRatiosObject.call(this, obj);
          break;
        case "array":
          result = this.transformIntoAspectRatiosArray(obj, type);
          break;
        default:
          result = obj;
      }
      return result;
    },
    transformIntoAspectRatiosArray: function transformIntoAspectRatiosArray(obj) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "photo";

      if (obj.aspectRatios && !Array.isArray(obj.aspectRatios)) {
        var config = this.get("configService.config." + type + ".aspectRatios") || [];
        var aspectRatios = obj.aspectRatios;
        var master = obj.aspectRatios.master;

        var crops = [];
        config.forEach(function (aspectRatioKey) {
          var imageAspectRatio = _lodashEs.default.cloneDeep(aspectRatios[aspectRatioKey] || {});
          var hasCropData = imageAspectRatio.modifications && imageAspectRatio.modifications.crop;
          var isOverride = imageAspectRatio.override;
          if (!isOverride && !hasCropData) {
            imageAspectRatio = getDefaultAspectRatioObject(master, aspectRatioKey, obj.cropMode);
          }
          imageAspectRatio.key = aspectRatioKey;
          crops.push(imageAspectRatio);
        });
        crops.push(Object.assign({
          key: "master"
        }, obj.aspectRatios.master));
        obj.aspectRatios = crops;
      }
      return obj;
    }
  });
});