define("copilot/helpers/serialized-categories", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          model = _ref2[0],
          path = _ref2[1],
          serializerName = _ref2[2];

      this.set("model", model);
      this.set("path", path);

      var serializer = Ember.getOwner(this).lookup("serializer:categories/" + (serializerName || "passthrough"));
      this.set("serializer", serializer);

      return this.content;
    },


    pathDidChange: Ember.observer("path", function () {
      if (Ember.isEmpty(this.path)) {
        return Ember.defineProperty(this, "content", []);
      }

      Ember.defineProperty(this, "content", Ember.computed("model." + this.path + ".[]", function () {
        return this.serializer.deserialize(this.model.get(this.path));
      }));
    }),

    contentDidChange: Ember.observer("content", function () {
      this.recompute();
    })
  });
});