define("copilot/components/card-item/component", ["exports", "ember-inflector"], function (exports, _emberInflector) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var EXTERNAL_TYPES = ["contentreference"];

  exports.default = Ember.Component.extend({
    classNames: ["card-item"],
    router: Ember.inject.service(),

    openInNewTab: true,

    videoThumbnail: Ember.computed.reads("relModel.imageUrl"),
    photo: Ember.computed.reads("model.embeddedPhoto"),

    didReceiveAttrs: function didReceiveAttrs() {
      var currentModel = this.model;
      // Set `index` on the property with current index from sorting
      // for model types that require it (currently only `bundle-curation`)
      if (currentModel && Ember.get(currentModel, "constructor.metadata.requiresSortIndex")) {
        currentModel.set("index", this.index);
      }

      if (this.type === "contentreference") {
        this.model.set("subtype", this.get("model._data.embedded.subType"));
      }
    },


    allowEditing: Ember.computed("type", "isEditable", function () {
      return this.type !== "cnevideo" || this.isEditable ? true : false;
    }),

    relModel: Ember.computed("model", "model.embedded", function () {
      return this.get("model.embedded") || this.model;
    }),

    pluralizedType: Ember.computed("modelName", function () {
      var modelName = this.modelName;
      return (0, _emberInflector.pluralize)(modelName);
    }),

    editRoute: Ember.computed("pluralizedType", function () {
      if (this.type === "contentreference") {
        return this.get("model.editUrl") || Ember.get(this, "model.embedded.editUrl");
      }
      return this.pluralizedType + ".index";
    }),

    hasEditRoute: Ember.computed("editRoute", function () {
      var editRoute = this.editRoute;
      var router = this.router;
      return router._router.hasRoute(editRoute);
    }),

    image: Ember.computed.reads("relModel.thumbnail"),

    isImageLoading: Ember.computed("image", function () {
      return this.image && !Ember.get(this.image, "id");
    }),

    type: Ember.computed("relModel.meta.modelName", function () {
      return this.get("relModel.meta.modelName") || "photo";
    }),

    modelName: Ember.computed("model", function () {
      return Ember.get(this, "model.embedded.meta.modelName") || Ember.get(this, "model.meta.modelName");
    }),

    modelId: Ember.computed("model.id", function () {
      return Ember.get(this, "model.embedded.id") || Ember.get(this, "model.id");
    }),

    imageUrl: Ember.computed("model.embedded.imageUrl", "model.imageUrl", function () {
      return Ember.get(this, "model.embedded.imageUrl") || Ember.get(this, "model.imageUrl");
    }),

    isExternal: Ember.computed("type", function () {
      return EXTERNAL_TYPES.includes(this.type);
    }),

    actions: {
      submitEdits: function submitEdits(newItem) {
        var model = this.model;
        Ember.setProperties(model, newItem);
      },
      closeModal: function closeModal() {
        this.set("isShowingModal", false);
      }
    }
  });
});