define("copilot/mixins/syndicatable", ["exports", "lodash-es"], function (exports, _lodashEs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // TODO: this could be placed on the particular models that are meant to be syndicatable.
  // Or rather this mixin should not be added to the base model, but only the models that are syndicatable.
  var SYNDICATABLE_TYPES = ["article"];

  /**
   * Mixin that allows an object to be syndicated
   * @mixin copilot/mixins/syndicatable
   * @augments Ember.Mixin
   */
  exports.default = Ember.Mixin.create(
  /** @lends copilot/mixins/syndicatable */
  {
    configService: Ember.inject.service("config"),

    isSyndicated: Ember.computed(function () {
      var brand = this.get("_data.brand") || this.configService.activeBrand;
      return this.syndication && !this.isSyndicator(brand) && this.syndicateeRecord(brand);
    }),
    syndicatorBrand: Ember.computed.reads("syndication.source"),
    /**
     * Determine if the brand code is the same as from where this object has been syndicated from
     * @param {String} brand
     * @returns {boolean}
     */
    isSyndicator: function isSyndicator(brand) {
      var configService = this.configService;
      return brand === this.get("syndication.source") || brand === configService.getBrandCode(this.get("syndication.source"));
    },


    /**
     * Find the destination(s) to which the object was syndicated
     * @param {String} brand
     * @returns {Array|null}
     */
    syndicateeRecord: function syndicateeRecord(brand) {
      var configService = this.configService;
      var brandServiceName = configService.getBrandServiceName(brand);
      if (this.get("syndication.targets")) {
        return _lodashEs.default.find(this.get("syndication.targets"), {
          brand: brandServiceName
        });
      }
      return null;
    },


    /**
     * Whether this object can be syndicated
     * TODO: remove this because it will be unnecessary once we are no longer storing a list of syndicatable types in this mixin.
     * @return {Boolean}
     */
    canSyndicate: Ember.computed("model.type", function () {
      var brand = this.brand;
      if (_lodashEs.default.includes(SYNDICATABLE_TYPES, this.type)) {
        return this.isSyndicator(brand.get("activeBrand")) || !this.syndication;
      }
      return false;
    })
  });
});