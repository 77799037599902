define("copilot/components/flash-message/component", ["exports", "ember-cli-flash/components/flash-message", "copilot/components/flash-message/template"], function (exports, _flashMessage, _template) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _flashMessage.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    heading: Ember.computed("flash.type", function () {
      var type = this.get("flash.type");
      return type === "danger" ? "error" : Ember.String.camelize(type);
    }),

    // Override upstream click with noop
    click: function click() {
      return true;
    },


    actions: {
      close: function close() {
        this._destroyFlashMessage();
      }
    }
  });
});