define("copilot/components/search/search-filters/categories/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    type: Ember.computed("roots", function () {
      var hasChannels = this.roots.split(",").any(function (rootName) {
        return rootName === "channels";
      });
      if (hasChannels) {
        return "channel";
      } else {
        return "categories";
      }
    }),

    queryKey: "hierarchy",

    intl: Ember.inject.service(),
    category: Ember.inject.service(),

    categories: Ember.computed.mapBy("selections.categories", "data"),

    name: Ember.computed("type", "intl.locale", function () {
      var type = this.type;
      return this.intl.t("components.search.filters." + type + "." + type);
    }),

    toSelection: function toSelection(category) {
      return {
        name: category.hierarchy.mapBy("name").reverseObjects().join(this.intl.t("category-manager.pathSeparator")),
        value: category.hierarchyString,
        data: category
      };
    },
    registerAllOptions: function registerAllOptions() {
      var type = this.type,
          queryKey = this.queryKey,
          replace = this.replace,
          toSelection = this.toSelection;

      var filterConfig = {
        toSelection: toSelection.bind(this),
        queryKey: queryKey,
        replace: replace,
        type: type
      };
      this.registerOptions(type, filterConfig);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.registerAllOptions();
    },


    // Find the appropriate root for channels, since
    // Rocket sites have channels configured under `sections`
    computedRoots: Ember.computed("roots", "category.roots", function () {
      var _this = this;

      return this.roots.split(",").map(function (rootSlug) {
        if (rootSlug === "channels") {
          if (!_this.category.roots.find(function (root) {
            return root.slug === "channels";
          }) && _this.category.roots.find(function (root) {
            return root.slug === "sections";
          })) {
            return "sections";
          }
        }
        return rootSlug;
      }).join(",");
    }),

    actions: {
      applyOption: function applyOption(_, _ref) {
        var category = _ref.category,
            isAddingCategory = _ref.isAddingCategory;

        var selection = this.toSelection(category);
        if (isAddingCategory) {
          this.onApply(this.type, selection);
        } else {
          this.removeOption(this.type, selection);
        }
      }
    }
  });
});