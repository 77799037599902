define("copilot/services/commerce-product-collection", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function buildQueryString(query) {
    return Object.entries(query).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return key + "=" + encodeURIComponent(value);
    }).join("&");
  }

  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    brand: Ember.inject.service(),
    config: Ember.inject.service(),
    logger: Ember.inject.service(),
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    baseUrl: "/api/commerce/product-collections",

    _parseResponse: function _parseResponse(response, query) {
      var _this = this;

      var hits = response.hits.filter(function (hit) {
        if (hit.error) {
          _this.logger.error("Bad search result", {
            caught: hit.error
          });
          return false;
        }
        return true;
      });

      var mappedHits = hits.map(function (item) {
        item.brand = _this.get("brand.activeBrand");
        var model = _this.store.createRecord(item.type);
        model.set("subtype", item.subType);
        var normalizedData = _this.store.adapterFor(model.subtype).get("serializer").normalize(model.constructor, {
          identifier: item.identifier,
          provider: item.provider,
          brand: item.tenant,
          id: item.id,
          productIds: item.productIds,
          hed: item.hed,
          meta: item.meta,
          modifiedAt: item.modifiedAt
        });
        model.load(item.id, normalizedData);
        return model;
      });

      return {
        hits: mappedHits,
        totalHits: response.stats.total,
        start: response.start,
        query: query
      };
    },
    execute: function execute() {
      var _this2 = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

      if (id !== "") {
        return this.ajax.request(this.baseUrl + "/" + id + "?" + buildQueryString(params)).then(function (results) {
          return _this2._parseResponse(results, params);
        });
      }
      return this.ajax.request(this.baseUrl + "?" + buildQueryString(params)).then(function (results) {
        return _this2._parseResponse(results, params);
      });
    }
  });
});