define("copilot/routes/articles/index/edit", ["exports", "copilot/mixins/unsaved-warning", "copilot/routes/authenticated-route", "copilot/mixins/route-locking", "copilot/lib/xml-import"], function (exports, _unsavedWarning, _authenticatedRoute, _routeLocking, _xmlImport) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, _routeLocking.default, {
    controllerName: "articles/index",
    afterModel: function afterModel(model) {
      var pushnotificationsAppleNews = model.get("pushnotificationsAppleNews");

      if (Ember.isEmpty(pushnotificationsAppleNews)) {
        var pushnotification = this.store.createRecord("pushnotification");
        pushnotificationsAppleNews.pushObject(pushnotification);
      }
      //setting the default value to model since we had removed it from the schema
      var modelSeoCategoryValue = model.get("seo.category");
      if (!modelSeoCategoryValue) {
        var defaultCategory = this.get("brandConfig.seoCategories").findBy("default", true);
        if (defaultCategory) {
          defaultCategory = defaultCategory.value;
        } else {
          defaultCategory = undefined;
        }
        model.set("seo.category", defaultCategory);
      }
      //clean up _dirtyAttributes, a newly loaded model should not be dirty
      var dirtyAttrs = model.get("_dirtyAttributes").filter(function (attr) {
        return attr !== "pushnotificationsAppleNews";
      });
      model.set("_dirtyAttributes", dirtyAttrs);
      return this._super.apply(this, arguments);
    },

    actions: {
      importPrism: function importPrism(file) {
        this.set("controller.xmlImporter", _xmlImport.default.create({ file: file }));
      },


      // NOTE: the "openPublishDialog" action does an implicit save before it opens the publish dialog
      openPublishDialog: function openPublishDialog() {
        this.controller.send("publish");
      }
    }
  });
});