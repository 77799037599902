define("copilot/components/ember-collection", ["exports", "ember-collection/components/ember-collection"], function (exports, _emberCollection) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCollection.default.extend({
    _needsRevalidate: function _needsRevalidate() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this._super();
    }
  });
});