define("copilot/brands/tny/locales/en/brand-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    contentTypes: {
      cartoon: "Cartoon"
    }
  };
});