define("copilot/routes/photos/index", ["exports", "copilot/routes/authenticated-route", "copilot/mixins/unsaved-warning", "ember-service-methods/inject"], function (exports, _authenticatedRoute, _unsavedWarning, _inject) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend(_unsavedWarning.default, {
    photoService: Ember.inject.service("photo"),

    replaceAspectRatio: (0, _inject.default)(),

    // if the brand is using vulcan, there might be the case in which
    // the master aspect ratio is missing the width and or height. if
    // that is the case we need to get the info from the image itself.
    // /tce what is the case where this happens?
    //      can we fix this on the server,
    //      or patch this in the photo upload process?
    fixMaster: function fixMaster(model) {
      var master = model.get("aspectRatios").findBy("key", "master");
      return new Ember.RSVP.Promise(function (resolve) {
        if (Ember.get(master, "width") && Ember.get(master, "height")) {
          resolve(master);
        } else {
          var url = this.photoService.getMasterURL(model);
          Ember.$("<img src=" + url + " />").load(function () {
            Ember.set(master, "height", this.height);
            Ember.set(master, "width", this.width);
            resolve(model);
          });
        }
      }.bind(this));
    },
    afterModel: function afterModel(model, transition) {
      var validReaperPhoto = !!(!Ember.isEmpty(model.get("aspectRatios")) && model.get("aspectRatios").findBy("key", "master"));
      this._super(model, transition);

      if (!validReaperPhoto) {
        // we need an error page :( , for now using a modal
        this.flashMessages.danger("There seems to be an error with the photo.");
        this.consoleLogger.error("No master aspect ratio found. Impossible to continue.", {
          type: "photo",
          id: Ember.get(model, "id"),
          filename: Ember.get(model, "filename")
        });
        this.transitionTo("search");
      }

      return this.fixMaster(model);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      window.dataLayer[0].content.pageType = null;
    },


    actions: {
      replaceAspectRatio: function replaceAspectRatio(model, aspectRatio, file) {
        return this.replaceAspectRatio(file, model, aspectRatio);
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        var model = this.modelFor(this.routeName);
        window.dataLayer[0].content.pageType = model.type;
      }
    }
  });
});