define("copilot/brands/vogue/routes/reviews/index", ["exports", "copilot/routes/authenticated-route"], function (exports, _authenticatedRoute) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _authenticatedRoute.default.extend({
    search: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = Ember.get(transition.params, "reviews.index");
      var reviewId = params && params.review_id;
      if (reviewId) {
        return this.search.execute({ types: "fashionshow", relid: reviewId }).then(function (results) {
          if (results && results.hits) {
            var fashionshow = results.hits[0];
            if (fashionshow) {
              _this.transitionTo("fashionshows.index.reviews.index", fashionshow.id, reviewId);
            } else {
              transition.abort();
              _this.transitionTo("/not-found");
            }
          }
        });
      }
      transition.abort();
      this.transitionTo("/not-found");
    }
  });
});