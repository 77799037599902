define("copilot/brands/vogue/models/season", ["exports", "copilot/models/base", "copilot/models/attr"], function (exports, _base, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var str = _attr.default.str;
  var attr = Ember.attr,
      hasMany = Ember.hasMany;


  var Season = _base.default.extend({
    type: "season",
    annualSeasonValue: "annualSeason",

    name: attr(str),
    code: attr(),
    year: attr(Number, {
      defaultValue: new Date().getFullYear()
    }),
    annualSeason: attr(),
    seasonType: attr(),
    active: attr(Boolean, {
      defaultValue: false
    }),
    featured: attr(Boolean, {
      defaultValue: false
    }),
    showCalendar: attr(Boolean, {
      defaultValue: false
    }),

    photosTout: hasMany("media", {
      embedded: true,
      polymorphic: true
    }),

    searchTitle: Ember.computed.reads("name"),

    load: function load(id, hash) {
      this._super(id, hash);
      this.getWithDefault("_dirtyAttributes", []).clear();
    }
  });

  Season.reopenClass({
    url: "/api/seasons",
    defaultAttributes: function defaultAttributes(draftName, brandConfig) {
      var seasonType = Ember.get(brandConfig, "season.typeOptions.lastObject");
      var annualSeason = Ember.get(brandConfig, "season.annualSeasonOptions.firstObject");
      if (seasonType.value) {
        seasonType = seasonType.value;
      }

      return {
        name: draftName,
        seasonType: seasonType,
        annualSeason: annualSeason
      };
    }
  });

  exports.default = Season;
});