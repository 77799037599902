define("copilot/components/file-dropzone", ["exports", "ember-file-upload/components/file-dropzone/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    allowUploadsFromWebsites: false,

    ondragenter: function ondragenter() {
      Ember.set(this, "queue.active", true);
    },
    ondragleave: function ondragleave() {
      Ember.set(this, "queue.active", false);
    },
    ondrop: function ondrop() {
      Ember.set(this, "queue.active", false);
    }
  });
});