define("copilot/controllers/recipes/index", ["exports", "copilot/controllers/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RecipesIndexController = _base.default.extend({
    showReadOnlyInfo: Ember.computed("model", function () {
      var props = ["willMakeAgainPct", "aggregateRating", "legacyId", "amgUserId"];
      var readOnlyProperties = this.model.getProperties(props);
      return Object.keys(readOnlyProperties).map(function (key) {
        return readOnlyProperties[key];
      }).some(function (value) {
        return !!value;
      });
    })
  });

  exports.default = RecipesIndexController;
});